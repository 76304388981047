import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { W700servicesService } from '../services/w700services.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { JobpropertiesComponent } from '../jobproperties/jobproperties.component';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';
import { formatDate, FormatWidth, getLocaleDateTimeFormat } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare const copyToClipboard: any;

@Component({
  selector: 'app-createjob',
  templateUrl: './createjob.component.html',
  styleUrls: ['./createjob.component.scss']
})

export class CreatejobComponent implements OnInit {
  @Input() inputDataInModalComponent: any;
  notfounddata: boolean;

  constructor(private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private w700service: W700servicesService,
    private _fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private service: CommonService,
    private ngxUiLoaderService: NgxUiLoaderService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.reactiveFormapp = _fb.group({ appname: '' });
    this.reactiveFormope = _fb.group({ operationname: '' });

  }
  datedisplay = new Date();
  deploymentkey: string = '';
  reactiveFormapp: FormGroup;
  reactiveFormope: FormGroup;
  pagerolepermisions: any = [];

  displaydatediv: boolean = false;
  createddate: string = '';
  modifieddate: string = '';
  createdby: string = '';
  modifiedby: string = '';
  oldnotes: string = '';
  frequencytype: string = '';

  frequencytypelist = [
    // { data: 'select', name: 'Select Type' },
    { data: 'S', name: 'Seconds' },
    { data: 'M', name: 'Minutes' },
    { data: 'H', name: 'Hours' },
    { data: 'D', name: 'Daily' }
  ];
  statustaggel: boolean = true;

  JobId: any = 0;
  JobName: string = '';
  JobPrefix: string = '';
  ClassName: string = '';
  notes: string = '';
  frequency: string = '';
  selectedapplicationname: string = '';

  async ngOnInit() {


    if (await this.service.checksession() == true) {
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'w700').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });

      if (this.pagerolepermisions != undefined) {
        this.deploymentkey = this.inputDataInModalComponent.deploymentkey;
        if (this.inputDataInModalComponent.dataitem !== '') {
        
         let namefreq = this.frequencytypelist.find(va => va.data ==  this.inputDataInModalComponent.dataitem["FrequencyType"]).name
         if(namefreq !== undefined || namefreq !== null){
          this.inputDataInModalComponent.dataitem["FrequencyType"] =namefreq['name']
         }
         
          this.displaydatediv = true;
          this.JobId = this.inputDataInModalComponent.dataitem["JobId"];
          this.JobName = this.inputDataInModalComponent.dataitem["JobName"];
          this.JobPrefix = this.inputDataInModalComponent.dataitem["JobPrefix"];
          this.selectedapplicationid = this.inputDataInModalComponent.dataitem["ApplicationId"];
          this.selectedoperationvalue = this.inputDataInModalComponent.dataitem["ClassName"];
          this.oldoperationvalue = this.selectedoperationvalue;
          this.statustaggel = this.inputDataInModalComponent.dataitem["Status"];
          this.oldnotes = this.inputDataInModalComponent.dataitem["Notes"];
          this.selectedapplicationname = this.inputDataInModalComponent.dataitem["Name"];
          this.reactiveFormapp.patchValue({
            appname: this.selectedapplicationname
          });
          this.reactiveFormope.patchValue({
            operationname: this.inputDataInModalComponent.dataitem["OperationValue"]
          });
          this.frequencytype = this.inputDataInModalComponent.dataitem["FrequencyType"];
          this.frequency =this.inputDataInModalComponent.dataitem["Frequency"];
          //this.frequency = await this.frequencreversecalculate(this.inputDataInModalComponent.dataitem["Frequency"]);
          this.createddate = this.inputDataInModalComponent.dataitem["Createddate"];
          this.modifieddate = this.inputDataInModalComponent.dataitem["Modifieddate"];
          this.createdby = this.inputDataInModalComponent.dataitem["modifiedby"];
          this.modifiedby = this.inputDataInModalComponent.dataitem["modifiedby"];
        }

      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  frequencreversecalculate(freqinminuts) {
    if (this.frequencytype == 'H') {
      return Number(freqinminuts) / 60;
    }
    else if (this.frequencytype == 'D') {
      var hours = freqinminuts / 60;
      var rhours = hours / 24;

      return Number(rhours);
    }
    else if (this.frequencytype == 'S') {
      return Number(this.frequency) * 60;
    }
    else {
      return Number(freqinminuts)
    }
  }

  displaymessageclass: string = '';
  displaymessageflag = false;
  displaymessage: string = '';

  closetpopup() {
    this.activeModal.close();
  }
  selectedapplicationid: string = '';

  isLoadingapp: boolean = false;
  ApplicationNameList: any = [];
  keywordsearch = 'Name';
  async getapplication(event: any) {
    var val = event;
    if (val != '') {
      this.isLoadingapp = true;

      var apiname = "api/W700Jobs/GetW700Applications?name=" + val;
      this.w700service.getapi(apiname,this.deploymentkey).subscribe({
        next: data => {
          this.ApplicationNameList = data as any[];

          this.isLoadingapp = false;
          if(data.length==0){
            this.notfounddata=true;
          }
          else{
            this.notfounddata=false;
          }
        },
        error: error => {
          this.isLoadingapp = false;
          this.notfounddata=true;
        }
      });
    }
  }

  selectedapplication(item) {
    this.selectedapplicationid = item.ApplicationId;
    this.selectedapplicationname = item.Name;
  }
  searchapplicationCleared(event) {
    this.isLoadingapp = false;
    this.ApplicationNameList = [];
    this.selectedapplicationid = '';
    this.notfounddata=false;
  }



  // async checkalert() {
  //   if (this.oldoperationvalue !== '') {
  //     if (this.oldoperationvalue !== this.selectedoperationvalue) {
  //       this.displaymessageclass = "btn alert-danger "
  //       this.displaymessage = 'Operation changed. All the configured properties will be deleted on save';
  //       return false;
  //     }
  //   }
  //   return true;
  // }



  frequenccalculate() {
    if (this.frequencytype == 'H') {
      return Number(this.frequency) * 60;
    }
    else if (this.frequencytype == 'D') {
      return Number(this.frequency) * 24 * 60;
    }
    else if (this.frequencytype == 'S') {
      return Math.floor(Number(this.frequency) % 3600 / 60);
    }
    else {
      return Number(this.frequency)
    }
  }

  getnotes() {
    const formatdatetime = getLocaleDateTimeFormat(this.locale, FormatWidth.Long);
    const date = formatDate(this.datedisplay, 'MMMM d, y', this.locale)
    const time = formatDate(this.datedisplay, 'HH:mm', this.locale)
    var finaldate = formatdatetime
      .replace("'", "")
      .replace("'", "")
      .replace('{1}', date)
      .replace('{0}', time);


    if (this.notes == '') {
      if (this.oldnotes == '') {
        return '';
      }
      else {
        return this.oldnotes;
      }
    }
    else {
      var username = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
      if (this.oldnotes == '') {
        return username + ' ' + finaldate + ': ' + this.notes;
      }
      else {
        return username + ' ' + finaldate + ': ' + this.notes + ' <br> ' + this.oldnotes;
      }

    }
  }

  async savejob() {
    //validations
    var returnvalue = await this.validationchecks();
    if (returnvalue == true) {

      const savejob = {
        "JobId": this.JobId,
        "JobName": this.JobName,
        "JobPrefix": "",
        "ApplicationId": this.selectedapplicationid,
        "ClassName": this.selectedoperationvalue,
        "FrequencyType": this.frequencytype['data'],
        "Frequency": this.frequency,
        "status": this.statustaggel == true ? true : false,
        "CreatedBy": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'] ,
        "LastUpdatedBy": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'] ,
        "Notes": await this.getnotes(),
        "DeploymentKey": this.deploymentkey
      };
      return
      this.ngxUiLoaderService.start();
      var responseroutedata = await this.w700service.postapi('api/W700Jobs/SaveW700Jobs', savejob,this.deploymentkey);
      if (responseroutedata['status'] && responseroutedata['statusText']) {
        this.ngxUiLoaderService.stop();
        this.displaymessageclass = "btn alert-danger "
        this.displaymessage = 'Failure to save Job ' + responseroutedata['statusText'];
        this.FadeOutmainpageMsg();
      }
      else {
        if (responseroutedata['JobId'] == -1) {
          this.ngxUiLoaderService.stop();
          this.displaymessageclass = "btn alert-warning "
          this.displaymessage = 'Job name already exist ! ';
          this.FadeOutmainpageMsg();
        }
        else {
          this.displaymessageclass = "btn alert-success "
          this.displaymessage = 'Job saved successfully ';
          this.FadeOutmainpageMsg();
          this.JobId = responseroutedata['JobId'];
          responseroutedata["Name"] = this.selectedapplicationname;
          responseroutedata["OperationValue"] =this.selectedoperationkey;
          this.ngxUiLoaderService.stop();
          this.openpropertiesJobpopup(responseroutedata);
        }
      }
    }
    else {
      this.FadeOutmainpageMsg();
    }

  }
  FadeOutmainpageMsg() {
    this.displaymessageflag = true;
    setTimeout(() => {
      this.displaymessageflag = false;
    }, 20000);
  }


  async validationchecks() {
    if (this.selectedapplicationid == "") {
      this.toastr.error( 'Select Application', "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

      // this.displaymessageclass = "btn alert-danger "
      // this.displaymessage = ;
      return false;
    }
    else if (this.JobName == "") {
      this.toastr.error( 'Job Name is mandatory', "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

      // this.displaymessageclass = "btn alert-danger "
      // this.displaymessage = 'Job Name is mandatory';
      return false;
    }
    else if (this.JobName.includes(' ')) {
      this.toastr.error( "Job Name should not contain whitespace", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

      // this.displaymessageclass = "btn alert-danger "
      // this.displaymessage = "Job Name should't contains whitespace";
      return false;
    }
    else if (this.JobName.startsWith(' ')) {
      this.toastr.error("Job Name should not start with whitespace", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

      // this.displaymessageclass = "btn alert-danger "
      // this.displaymessage = "Job Name should't start with whitespace";
      return false;
    }
    else if (this.JobName.endsWith(' ')) {
      this.toastr.error("Job Name should not end with whitespace", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

      // this.displaymessageclass = "btn alert-danger "
      // this.displaymessage = "Job Name should't ends with whitespace";
      return false;
    }
    else if (this.selectedoperationvalue == "") {
      this.toastr.error('Select Operation', "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

      // this.displaymessageclass = "btn alert-danger "
      // this.displaymessage = 'Select Operation';
      return false;
    }
    else if (this.frequencytype == "select") {
      this.toastr.error('Select Frequency type', "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

      // this.displaymessageclass = "btn alert-danger "
      // this.displaymessage = 'Select Frequency type';
      return false;
    }
    else if (this.frequency == "") {
      this.toastr.error('Enter Frequency ', "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

      // this.displaymessageclass = "btn alert-danger "
      // this.displaymessage = 'Enter Frequency ';
      return false;
    }
    else {
      return true;
    }
  }

  OparationsList: any = [];
  keywordsearchoperation: string = 'Key';
  keywordsearchfrequencykey: string = 'name';
  isLoadingope: boolean = false;
  selectedoperationkey: string = '';
  selectedoperationvalue: string = '';
  oldoperationvalue: string = '';
  async getOperations(event: any) {
    var val = event;
    if (val != '') {
      this.isLoadingope = true;

      var apiname = "api/W700Jobs/GetW700Operations?name=" + val ;
      this.w700service.getapi(apiname,this.deploymentkey).subscribe({
        next: data => {
          this.OparationsList = data as any[];

          this.isLoadingope = false;
          if(data.length==0){
            this.notfounddata=true;
          }
          else{
            this.notfounddata=false;
          }
        },
        error: error => {
          this.isLoadingope = false;
          this.notfounddata=true;
        }
      });
    }
  }

  selectfrequency(event){

    this.frequencytype = event.data;

  }
  searchFrequencyCleared(event){

    this.frequencytype = "";

  }

  selectedOperation(item) {

    if (this.oldoperationvalue !== '') {
      if (this.oldoperationvalue !== item.Value) {
        this.displaymessageclass = "btn alert-warning "
        this.displaymessage = 'Operation changed. All the configured properties will be deleted on save';
        this.FadeOutmainpageMsg();
      }
    }

    this.selectedoperationvalue = item.Value;
    this.selectedoperationkey = item.Key;
  }

  searchOperationCleared(event) {
    this.isLoadingope = false;
    this.OparationsList = [];
    this.selectedoperationkey = '';
    this.selectedoperationvalue = '';
    this.notfounddata=false;
  }
  dataToModal: any;
  openpropertiesJobpopup(item) {

    this.dataToModal = {
      deploymentkey: this.deploymentkey,
      dataitem: item
    }
    const modalRef = this.modalService.open(JobpropertiesComponent,
      { windowClass: "myCustomModalClassProperties", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
    modalRef.result.then(() => {
      this.autoclosetemplate();
    });

  }

  autoclosetemplate() {
    setTimeout(() => {
      this.activeModal.close();
    }, 3000);
  }

  copyToClip(event: any) {
    if (event != '') {

      copyToClipboard(event);

      this.displaymessage = 'Copied';
      this.displaymessageclass = "btn alert-success ";
      this.FadeOutmainpageMsg();
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  onchangeFrequency(event) {
    if (this.frequencytype == 's') {
      if (Number(event) <= 59) {
        this.displaymessage = 'Frequency type selected sec';
        this.displaymessageclass = "btn alert-success ";
        this.FadeOutmainpageMsg();
      }
    }
  }

}

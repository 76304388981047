import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

import More from 'highcharts/highcharts-more';
More(Highcharts);
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
// Initialize exporting module.
Exporting(Highcharts);
import Data from 'highcharts/modules/data';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
// Initialize Data module.
Data(Highcharts);

@Component({
  selector: 'app-metricshighchart',
  templateUrl: './metricshighchart.component.html',
  styleUrls: ['./metricshighchart.component.scss']
})
export class MetricshighchartComponent implements OnInit {

  constructor(private ngxUiLoaderService: NgxUiLoaderService,
    public service: CommonService, private toastr: ToastrService) { }

  async ngOnInit() {
    if (await this.service.checksession() == true) {

      //Mention today mean the array will have today date 

      for (var i = 0; i <= 6; i++) {
        var date = new Date();
        var last = new Date(date.getTime() - (i * 24 * 60 * 60 * 1000));
        // var day = last.getDate();
        // var month = last.getMonth() + 1;
        var day = ('0' + last.getDate()).slice(-2);
        var month = ('0' + (last.getMonth() + 1)).slice(-2);

        var year = last.getFullYear();
        const fulld = (day + '-' + month + '-' + year) // Format date as you like
        this.GetDays.push(fulld);
      }


      await this.getmetricsdata();
    }
  }

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: any;

  messagestatuslist: any = [];
  messagecountbystatuslist: any = [];
  messagecountsbymsgtypelist: any = [];
  metricjson: any = [];
  drilldownjson: any = [];


  GetDays: any = [];


  async getmetricsdata() {
    this.ngxUiLoaderService.start();

    this.service.getmetricapiurl('api/Metrics/GetMetrics').subscribe({
      next: response => {
        if (response["messagestatuslist"] && response["messagecountbystatuslist"] && response["messagecountsbymsgtypelist"]) {
          this.messagestatuslist = response["messagestatuslist"];
          this.messagecountbystatuslist = response["messagecountbystatuslist"] as any[];
          this.messagecountsbymsgtypelist = response["messagecountsbymsgtypelist"];
          this.messagestatuslist.forEach(element => {
            this.metricjson.push(
              {
                name: this.capitalizeFirstLetter(element.messagestagestatus), //message status (Creates)
                color: element.color,           // color (#fFF)
                data: this.getlistdata(element.id) // list array STATUS ID 
              }
            );
          });

          // let unique = [...new Set(this.messagecountbystatuslist.sort().map(item => item.transactiondate))];
          this.GetDays.forEach(ele => {
            this.drilldownjson.push({
              name: ele,  //drilldown name 
              id: ele,   //drilldown id
              data: this.getdrilldropdowndata(ele) // ddrilldata array
            });
          });

          this.chartOptions = {
            credits: {
              enabled: false
            },
            chart: {
              type: 'column'
            },
            title: {
              text: 'Transactions per day'
            },
            xAxis: {
              type: 'category'
            },
            yAxis: {
              title: {
                text: 'Messages count'
              }
            },
            plotOptions: {
              column: {
                stacking: 'normal',
              },
              series: {
                borderWidth: 0,
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                    if (this.y > 100)
                      return this.y;
                    else
                      return '';
                  }
                },
                // pointStart: 2010,
                // events: {
                //   mouseOver: function() {
                //     color = (colors[this.index]).replace('0.5', '1');
                //     colorIndex = this.index;
                //     this.chart.series[this.index].update({
                //       color: color
                //     });
                //   },
                //   mouseOut: function() {
                //     this.chart.series[this.index].update({
                //       color: colors[this.index]
                //     });
                //   }
                // }
              }
            },
            accessibility: {
              announceNewData: {
                enabled: true
              }
            },
            legend: {
              align: 'center',
              x: -30,
              verticalAlign: 'top',
              y: 25,
              floating: true,
              backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
              borderColor: '#CCC',
              borderWidth: 1,
              shadow: false,
            },
            // tooltip: {
            //   headerFormat: '<b>{point.name}</b><br/>',
            //   pointFormat: 'Total: {point.stackTotal} <br/> {series.name}: {point.y}'
            // },
            tooltip: {
              useHTML: true,
              formatter: function () {
                let total = 0;
                let s = '';
                this.points.forEach(function (point, index) {
                  s += '<span style="color:' + point.color + ';margin-right:2px">\u25CF</span> <span style="color: #000">' + point.series.name + ': <b>' + point.y + ' ' + '</b><br>';
                  total += point.y;
                });
                s = '<b>' + this.points[0].key + ':(' + total + ')</b><br/>' + s;
                return s;
              },
              shared: true
            },
            series: this.metricjson,
            drilldown: {
              breadcrumbs: {
                position: {
                  align: 'right'
                },
                showFullPath: false,
                format: 'Go to Main'
              },
              series: this.drilldownjson
            }
          };

          this.ngxUiLoaderService.stop();
        }
      },
      error: error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Failure to load Metrics", '', {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.ngxUiLoaderService.stop();
      }
    });
  }

  getlistdata(status: any) {
    let drilldropdata = [];

    this.GetDays.forEach(eledates => {
      drilldropdata.push({
        name: eledates,
        y: this.messagecountbystatuslist.filter(x => x.status == status && x.transactiondate == eledates).length > 0 ? this.messagecountbystatuslist.filter(x => x.status == status && x.transactiondate == eledates)[0].messagecount : 0,
        drilldown: eledates
      });
    });
    return drilldropdata;
  }

  getdrilldropdowndata(trdate: any) {
    let drilldropdata = [];
    const filterdat = this.messagecountsbymsgtypelist.filter(x => x.transactiondate == trdate);
    // this.sortArraybyObjValue(filterdat, 'messagecount').forEach(element => {
    //   //   drilldropdata.push([element.messagetype, element.messagecount]);
    //   drilldropdata.push({
    //     id: element.status,
    //     name: element.messagetype,
    //     y: element.messagecount,
    //     color: element.color
    //   });
    // });
    if (filterdat.length > 0) {
      let map = filterdat.reduce((prev, next) => {
        if (next.messagetype in prev) {
          prev[next.messagetype].messagecount += next.messagecount;
        } else {
          prev[next.messagetype] = next;
        }
        return prev;
      }, {});
      let result = Object.keys(map).map(messagetype => map[messagetype]);
      this.sortArraybyObjValue(result, 'messagecount').forEach(element => {
        //   drilldropdata.push([element.messagetype, element.messagecount]);
        drilldropdata.push({
          id: element.status,
          name: element.messagetype,
          y: element.messagecount,
          color: "rgb(124, 181, 236)"
        });
      });
    }


    return drilldropdata;
  }


  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  sortArraybyObjValue(arr, value) {
    return arr.sort((a, b) => {
      return b[value] - a[value];
    })
  }


}

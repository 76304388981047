import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';

import {
  MonacoEditorConstructionOptions,
  MonacoStandaloneCodeEditor,
} from "@materia-ui/ngx-monaco-editor";
import { W700servicesService } from '../services/w700services.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDatepickerInputEvent } from '@angular/material';
import { DiffResults } from '../compare/compare.component';

@Component({
  selector: 'app-filescompare',
  templateUrl: './filescompare.component.html',
  styleUrls: ['./filescompare.component.scss']
})
export class FilescompareComponent implements OnInit {

  @ViewChild('filemodal', { static: false }) filemodal: TemplateRef<any>;
  today= new Date();
  formatType: string = "plaintext";

  editorOptions: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: true,
    wordWrap: "on",
    minimap: {
      enabled: false
    }
  };

  editor: MonacoStandaloneCodeEditor;
  notfounddata: boolean;

  editorInit(editor: MonacoStandaloneCodeEditor) {
    this.editor = editor;
    editor.onDidChangeCursorPosition(() => {
      if (this.selectedmirthcontent.startsWith("<") || this.selectedhubcontent.startsWith("<")) {
        this.formatType = "xml";
      } else if (this.selectedmirthcontent.startsWith("{") || this.selectedhubcontent.startsWith("{")) {
        this.formatType = "json";
      }
      else {
        this.formatType = "hl7";
      }
      this.editorOptions = { ...this.editorOptions, language: this.formatType };
    });
  }
  reactiveFormroute: FormGroup;

  constructor(public service: CommonService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private w700service: W700servicesService,
    private router: Router,
    private _fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.reactiveFormroute = _fb.group({ routename: '' });
    this.minFromDate = new Date(1900, 0, 1);
    this.maxFromDate = new Date();

    this.minToDate = new Date(1900, 0, 1);
    this.maxToDate = new Date();
  }


  filecontents: any = '';
  matchfilesCount: number;
  mismatchfilesCount: number;
  filenotfoundCount: number;
  hubftpdetails = <any>[];
  unmatchedfiles: any = '';
  filesnotfound: any = '';
  routename: any;
  deploymentKey: string;
  fromcomparedate: Date;
  tocomparedate: Date;
  handlername: string;
  filedetailshow: boolean;
  filesnotfoundCount: number;
  filenotfoundshow: boolean;

  
  async ngOnInit() {
    
  }

  keywordsearch = "displayname";
  isLoadingroutes: boolean = false;
  RouteNameList: any = [];

  getRouteName(event: any) {
    var val = event;
    if (val) {
      this.isLoadingroutes = true;
      var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=2";;
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.RouteNameList = data as any[];
          this.RouteNameList.map(obj => obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}`);
          this.isLoadingroutes = false;
          if(data.length==0){
            this.notfounddata=true;
          }
          else{
            this.notfounddata=false;
          }
        },
        error: error => {
          this.isLoadingroutes = false;
          this.notfounddata=true;
        }
      });
    }
  }

  selectedrouteId: string = "";
  selectedrouteDisplayName: string = "";
  selectedrouteUniqueID: Number = 0;
  selectedRoute(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
    this.selectedrouteUniqueID = item.UniqueID;
  }

  searchRouteNameCleared(event) {
    this.isLoadingroutes = false;
    this.RouteNameList = [];
    this.selectedrouteId = '';
    this.selectedrouteDisplayName = '';
    this.selectedrouteUniqueID = 0;
    this.notfounddata=false;
  }

  async GetCompareFIles() {

    let validate = await this.validatedata();
    if (validate) {

      const inputObject = {
        "DeploymentKey": this.deploymentKey,
        "MirthHandler": this.handlername,
        "PipelineID": this.selectedrouteUniqueID,
        "FromCompareDate": this.fromcomparedate + ' 00:00:00',
        "ToCompareDate": this.tocomparedate + ' 23:59:59',
      }

      this.ngxUiLoaderService.start();
      let responsehub = await this.service.searchpostapi('api/Routes/GetComparedInterfaceDetails', inputObject);
      if (responsehub['status'] && responsehub['statusText']) {
        this.ngxUiLoaderService.stop();
      }
      else {
        this.ngxUiLoaderService.stop();
        await this.cleardata();
        this.filecontents = responsehub as any[];
        this.filedetailshow = true;
        this.matchfilesCount = this.filecontents.filter(e => e.IsMatching == true).length;
        this.mismatchfilesCount = this.filecontents.filter(e => e.IsMatching == false).length;
        this.filenotfoundCount = this.filecontents.filter(e => e.FileExists == false).length;
        this.unmatchedfiles = this.filecontents.filter(e => e.IsMatching == false && e.FileExists == true).slice(0, 5);
        this.filesnotfoundCount = this.filecontents.filter(e => e.FileExists == false).length;
        this.filenotfoundshow = false;
        if (this.filesnotfoundCount >= 1) {
          this.filenotfoundshow = true;
          this.filesnotfound = this.filecontents.filter(e => e.FileExists == false).slice(0, 5);
        }
      }
    }
  }

  async cleardata() {
    this.filecontents = '';
    this.filedetailshow = false;
    this.matchfilesCount = null;
    this.mismatchfilesCount = null;
    this.filenotfoundCount = null;
    this.unmatchedfiles = null;
    this.filesnotfoundCount = null;
  }
  async validatedata() {

    if (!this.selectedrouteUniqueID) {
      this.toastr.warning('Pipeline is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }

    if (!this.handlername) {
      this.toastr.warning('Handler is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }


    if (!this.fromcomparedate) {
      this.toastr.error("From date is mandatory ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return false;
    }

    if (!this.tocomparedate) {
      this.toastr.error("To date is mandatory ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return false;
    }

    if (!this.deploymentKey) {
      this.toastr.error("Deployment Key is mandatory ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return false;
    }
 

    return true;
  }

  getstringRoute (value){
    let names=value.split("(")
    value = `${names[0]} <br>(${names[1]}`
  return value
  }

  selectedmirthcontent: any = '';
  selectedhubcontent: any = '';
  filecontent: string = "";
  selectedmirthfile: string = "";
  selectedhubfile: string = "";

  async open(content, object, mirthfile, object1, hubfile) {
    this.selectedmirthcontent = object;
    this.selectedmirthfile = mirthfile;
    this.selectedhubcontent = object1;
    this.selectedhubfile = hubfile;
    this.modalService.open(content, { backdrop: 'static', windowClass: "mymodalcontent" }).result.then((result) => {
    }, (reason) => {
    });
  }


  closepopup(c) {
    c('close modal');
  }

  reset() {
    this.deploymentKey = "";
    this.handlername = "";
    this.selectedrouteUniqueID = 0;
    this.fromcomparedate = new Date();
    this.tocomparedate=new Date();
    this.reactiveFormroute.reset();
    this.filecontents = '';
    this.filedetailshow = false;
    this.matchfilesCount = null;
    this.mismatchfilesCount = null;
    this.filenotfoundCount = null;
    this.unmatchedfiles = null;
    this.filesnotfoundCount = null;
    this.RouteNameList = [];
  }
  minFromDate: Date;
  maxFromDate: Date | null;
  minToDate: Date | null;
  maxToDate: Date;
  fromDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    ////console.log(`${type}: ${event.value}`);
    this.minToDate = event.value;

    if (event.value !== null) {
      this.maxToDate = new Date(
        event!.value.getFullYear(),
        event!.value.getMonth(),
        event!.value.getDate() + 30
      );
    }
  }

  toDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.maxFromDate = event.value;

    if (event.value !== null) {
      this.minFromDate = new Date(
        event!.value.getFullYear(),
        event!.value.getMonth(),
        event!.value.getDate() - 30
      );
    }
  }
 
  onCompareResults(diffResults: DiffResults) {
    // //console.log('diffResults', diffResults);
  }

}

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MonacoEditorLoaderService } from '@materia-ui/ngx-monaco-editor';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';

import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Drilldown from 'highcharts/modules/drilldown';
More(Highcharts);
Drilldown(Highcharts);
// Load the exporting module.
import Data from 'highcharts/modules/data';
import Exporting from 'highcharts/modules/exporting';
import { VarulesComponent } from 'src/app/varules/varules.component';
import { forEach } from 'jszip';
import { error } from 'selenium-webdriver';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
// Initialize exporting module.
Exporting(Highcharts);
// Initialize Data module.
Data(Highcharts);
declare var $;
declare const copyToClipboard: any;


@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss']
})
export class InterfaceComponent implements OnInit {

  public reactiveFormroute: FormGroup;
  public reactiveFormpa:FormGroup;
  public reactiveFormmsgtype:FormGroup;
  public reactiveFormdirection:FormGroup;
  public reactiveFormvendor:FormGroup
  public reactiveFormentity:FormGroup
  public reactiveFormse:FormGroup
  reactiveFormProject:FormGroup
  public reactiveFormde:FormGroup
  public showexportbutton: boolean = false;

  keywordsearch = 'displayname';
  keywordsearchdir='name'
  selectedIndexTab=0;
  keywordsearchvendar = "name";
  keywordsearcha = "LocationName";
  keywordsearchProj = "projectName";
  RouteNameListPipeline:any[]=[];
  isLoadingResult:boolean
  notfounddata:boolean
isadvancefilter:boolean

interfacedetails={}
Highcharts: typeof Highcharts = Highcharts;
  selectedRoutGUID: any;
  selectedRouteName: any;
  category: any;
//Details
showSourceTarget: boolean
selectedSeqName: string;

  //datavalidation
  InterfaceValidationHistory: any = [];
  InterfaceMessageDataByMessageid: any = [];
  dataSources: any[];
  displayedColumns: string[] = ['pipeline', 'generatedby', 'generatedon', 'status'];
  currentItem = 'Televi;sion';
  validatedDataResponse: any;
  callingComponent:any='';
  maxItemsToShow = 4;
  tripfilter = {
    PipelineName: '',
    Fromdate: '',
    Todate: '',
    Generated_By: '',
    Generated_On: '',
    Status: ''
  }
  cardfilter = {
    Accession: '',
    Order: '',
    DateofBirth: '',
    PatientName: '',
    MessageId: ''
  }

  //datavalidation
  @ViewChild('ValidationContent', { static: false }) ValidationContent: any;

  // @ViewChild('reValidationPopup', { static: false }) reValidationPopup: any;

  [x: string]: any;
  config = {
    displayKey: 'lookupvalue', //if objects array passed which key to be displayed defaults to description
    search: true,
    height: '50%',
    width: '80%',
    // width: '1202'px,
    /* height: 507px; */
  }
  showInterfaceData: boolean = false;
  filteredInterfaceRevalidationData: any = [];
  searchtextData:string='';
  selectedFromDate: any;
  pipelineGUID: any;
  pipelineName: any;
  backupPipelineName: any;
  id: any;
  Pipeline: any;
  PipelineGUID: any;
  backupPipelineGUID: any;
  backupPipeline: any;
  piplineguid: any;
  responseLength: any;
  selectedToDate: any;
  today: any = new Date();
  pipeline: string;
  generatedby: string;
  Generatedon: number;
  Status: string;
  statusMessage: string;
  reponseList: any = [];
  reponseListBackup: any = [];
  Validationcomponent: any = {};
  validationItem: any = {};
  FilertData = [];
  interfacePipelineDisplayname: any = '';
  backupInterfacePipelineDisplayname
  inputDataInModalComponent: any = [];
  selectedCardIndex: number = 0;
  searchData: any;
  modalReference = null;  

  constructor(
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private _fb: FormBuilder,
    public service: CommonService,
    private activeModal: NgbActiveModal,
    private router: Router,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private monacoLoaderService: MonacoEditorLoaderService
  ) {
    this.reactiveFormroute = this._fb.group({ routename: '' });
    this.reactiveFormpa = _fb.group({ paname: '' });
    this.reactiveFormmsgtype = this._fb.group({ msgname: '' });
    this.reactiveFormdirection= this._fb.group({ direction: '' });
    this.reactiveFormvendor = _fb.group({ vendorname: '' });

    if (this.vendorname) {
      this.reactiveFormvendor.patchValue({
        vendorname: this.vendorname
      })
    }
    this.reactiveFormentity = _fb.group({ entityname: '' });
    this.reactiveFormse = _fb.group({ sename: '' });
    this.reactiveFormde = _fb.group({ dename: '' });
    this.reactiveFormProject = _fb.group({ projectName: '' });
  }
todaydate:any
  ngOnInit() {
    this.isselect=''
    this.issearched=false
    this.ishide=true
   this.createDate();
    this.getMessagetypelist('msgtype');
    this.directionlist=[
      {'name':'Inbound'},{'name':'Outbound'}
    ]
    this.getVendornames();
    this.showSourceTarget = true;
  }
  //Datavalidation
  pipelineguid(event: any) {
    
    this.pipelineGUID = event.target.value;
    this.pipeline = event.target.value;
  }
  createDate(){
    let today = new Date();
    let dd;
    let mm;
    if (today.getDate() < 10) {
      dd = '0' + (today.getDate()).toString();
   }
   else{
    dd=(today.getDate()).toString();
   }

   if ((today.getMonth() + 1) < 10) {
      mm = '0' + (today.getMonth() + 1).toString();
   }
    this.todaydate=(today.getFullYear()).toString()+'-'+mm+'-'+dd;
  }

  getRouteName(event: any) {
    var val = event;
    if (val) {
      if (Number(val.length) + Number(1) > 3) {
        this.isLoadingResult = true;
        var url = "api/Routes/GetByDisplayName?name=" + val + "&version=0";
        this.service.getapiurl(url).subscribe({
          next: data => {
            this.isLoadingResult = false;
            this.RouteNameListPipeline = data;
            this.RouteNameListPipeline.map(obj => obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}`);
            this.selectedrouteDisplayName = val;
            if (data.length == 0) {
              this.notfounddata = true;
            } else {
              this.notfounddata = false
            }
          },
          error: error => {
            this.isLoadingResult = false;
            this.notfounddata = false
            this.toastr.error("No result found", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
        });
      }
    }

  }
  selectedrouteId:string=""
  selectedrouteDisplayName:string
  selectedinterfaceEvent(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
    this.getpipelinelist(true,false);
  }
  buildpiplinetitle(name, id) {
    if (name !== undefined && name != '') {
      if (name.includes("(")) {
        return name
      } else {
        let fullname = name + '(' + id + ')';
        if (id) {
          return fullname;
        }
        else {
          return
        }
      }
    }

  }
  async searchRouteNameCleared(event) {
    this.pagenumber=1
    this.status=true
    this.isChecked=false
    this.issearched=false
    this.interfacelist=[]
    this.RouteNameListPipeline=[]
    this.interfacedetails={}
    this.selectedrouteId=''
    this.selectedrouteDisplayName=''
    if (this.selectedrouteId !== "") {
      this.reactiveFormroute.patchValue({
        routename: ''
      });
    }
  }
  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]}`
    return value
  }

  failurereason:any[]=[];
  isdate:boolean
  reason:string=''
  count:string=''
  fromdatepop:string=''
  todatepop:string=''
  respopdate(value){
    if(value.includes('/'))
    {
      value =value.split("/")
      return value[2]+"-"+value[0]+"-"+value[1]
    }
    else{
      value=value.split('-');
      return value[2]+"-"+value[1]+"-"+value[0]
    }
  }
  selecteddate:any
  failurereasonBackup :any
  failurerespopup(value:any,isdate:boolean,failurerespopup){
    this.isdate=isdate;
    this.ngxUiLoaderService.start();
    this.reason=value['FailureReason'];
    this.count=isdate?"":value['Count']
    this.failurereason=[];
    if(isdate){
      this.fromdatepop=this.respopdate(value);
      this.todatepop=this.respopdate(value)
    }
    else{
      this.fromdatepop=this.respopdate(this.category[0]);
      this.todatepop=this.respopdate(this.category[this.category.length-1])
      value=null
    }
    this.selecteddate=value;
    let obj={
      "UniqueID":this.isselect,
      "Allfailurecount":0,
      "DatewiseFailure":isdate?1:0,
      "SelectedDate":value,
      "FailureReason": isdate?null:this.reason
    }
      this.service.GetDatewiseFailureReasonList(obj).subscribe((data) =>{
        if(data['status'].toLowerCase()=='success')
        {
          this.failurereason=JSON.parse(data['responsebody']);
          this.failurereasonBackup = this.failurereason;
        }
        else{
          this.toastr.error("No data found", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        this.ngxUiLoaderService.stop();
      }),error=>{
        this.toastr.error("No data found", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.ngxUiLoaderService.stop();
      }
  this.modalService
  .open(failurerespopup, {
    windowClass: "failuremsgpop",
    backdrop: "static",
    keyboard: false,
  })
  .result.then((result) => {});
  }
  closepopup(c){
    c('close modal');
    this.isheaderchecked=false
     this.failurereason=[];
  }
   // to close Datavalidation popup screen
   closepopupdata(content:any) {
    
    this.showInterfaceData = false;
    this.modalService.dismissAll(content)
    this.searchtextData = '';
    this.getFilter();
    this.searchData = '';
    this.getFilterDetails();
    //this.getFilter();
    //this.getFilterDetails();
    this.selectedFromDate = '';
    this.selectedToDate = '';
  }

  isheaderchecked:boolean
  headerAction(event){
this.isheaderchecked=event['checked'];
this.failurereason.forEach(V=>{
  V['ischecked']=event['checked'];
})
  }
  rowAction(mid:any,event){
    this.failurereason.forEach(V=>{
      if(V['MessageRefernceId']==mid){
        V['ischecked']=event['checked'];
      }
    })
  }
  fromdate:any=""
  todate:any=""
  status:boolean=true
  direction:any="Inbound"
  interfacelist:any[]=[]
  issearched:boolean
  directionCtrl = new FormControl("");
  response:any

async  getpipelinelist(issearch,isscroll){
    if(!isscroll){
      this.ngxUiLoaderService.start();
      this.interfacelist=[]
      this.isselect=''
      this.issearched=issearch
    }
    this.interfacedetails=[]
    if(this.fromdate!=''&&this.todate==''){
      this.toastr.warning("Unable to search", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.ngxUiLoaderService.stop();
    }
    else{
      let obj ={}
      if(this.selectedrouteId!=''){
        obj={
          "id": this.selectedrouteId,
        }
      }
      else{
       obj ={
        "messagetype": this.selectedmessagetype,
        "id": this.selectedrouteId,
        "PAID": this.selectedProcessorAgentguid,
        "sourceagentid": "",
        "SEGUID": this.selectedSourceEntityguid,
        "destinationagentid": "",
        "DEGUID": this.selectedTargetEntityguid,
        "vendor": this.vendorid,
        "entityid": "",
        "pagenumber": this.pagenumber,
        "PageSize":15,
        "userType": (this.isChecked) ? 2 : '',
        "type":'not',
        "projectId":this.selectedprojId,
        "assignedUserId": (this.isChecked) ? JSON.parse(sessionStorage.getItem('rolewithmenus'))[0].uniqueId : '',
        "status":this.status?"active":"passive",
        "FromDate": this.fromdate,
        "ToDate":this.todate,
        "Direction":this.selecteddirectiontype,
        "DateType":this.fromdate!=''&&this.todate!=''?this.dateoption:''
      }
    }
      var apiname = 'api/Routes/GetSearchDetails';
      this.response = await this.service.searchpostapi(apiname, obj);

      if(this.response.length==0){
        this.toastr.error("No data found", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      } else{
        isscroll? this.response.forEach(element => {
          this.interfacelist.push(element)
        }):this.interfacelist= this.response as any[]

        this.totalrecords = parseInt(this.response[0].totalpipelinecount.toString());
      }
      this.ngxUiLoaderService.stop();
  }
  }
  datesplit(value){
    if(value !== undefined){
      value = value.split(" ")[0]
      if(value.includes("-"))
      {
        value =value.split("-")
      return value[1]+"/"+value[0]+"/"+value[2]
      }
      else if(value.includes("/")){
        value =value.split("/")
      return value[1]+"/"+value[0]+"/"+value[2]
      }
    }
  }
  chartOptions={}
  pipeLineDetails={}
  isselect:string=''
  sourcedeploy:string=''
  targetdeploy:string=''
  sourcetype:string=''
  targettype:string=''
  sequence:any[]=[]
  connseqdatalist:any[]=[]
  mode:string=''
  getpipelinedetails(interfaceData:any,value){
    if(value=='home'){
    this.DiagnosticList =[]
    this.connseqdatalist=[]
    this.seqdataarr=[]
    this.isselect=interfaceData.UniqueID
    this.selectedRoutGUID=interfaceData.id;
    this.selectedRouteName=interfaceData.displayname;
    this.sourcedeploy=interfaceData['SEDEPLOYMENTKEY'];
    this.targetdeploy=interfaceData['DEDEPLOYMENTKEY'];
    this.sourcetype=interfaceData['sourcetype'];
    this.targettype=interfaceData['targettype'];
    this.mode=interfaceData['mode']
    this.ngxUiLoaderService.start();
  }

    this.service.GetPipeleDetailsByUniqueID(interfaceData.UniqueID).subscribe((data) =>{
      if(data['status'].toLowerCase()=='success' && (data['statusmessage']==null||data['statusmessage'].toLowerCase()!='no data found'))
      {
        this.ishide=false
        this.interfacedetails=JSON.parse(data['responsebody']);
        this.pipeLineDetails=this.interfacedetails['pipeLineDetails'][0];
        this.interfacedetails['VAConnectivityList']['sequenceDataList']!=null&&this.interfacedetails['VAConnectivityList']['sequenceDataList'][0]!=undefined?this.sequence= JSON.parse(this.interfacedetails['VAConnectivityList']['sequenceDataList'][0]['SequenceData']):this.sequence=[];
        this.getpendingfiles(this.selectedRoutGUID);
        if(this.sequence.length>0){
          let obj={}

          this.sequence.forEach(V=>{

            if((V['TYPENAME'].toLowerCase()).includes('job')){
              let backupSeq = JSON.parse(JSON.stringify(this.interfacedetails['VAConnectivityList']['VBJobsType']))
              if(this.connseqdatalist.length>0)
              {
                this.connseqdatalist.forEach(X=>{
                  backupSeq = backupSeq.filter(element=> element['TYPENAME']!== X['Name']);
              })
              }
              if(backupSeq.length>0)
              {
                let flag=1;
                backupSeq.forEach(X=>{

                  if(X['Name']==V['TYPENAME']){
                    if(flag){
                      obj={
                        'Name':'Description',
                        'Value':X['Description']
                      }
                      X['Data'].push(obj)
                    }
                    flag=0
                    obj={
                      'Name':V['TYPENAME'],
                      'Data':X['Data']
                    }
                    this.connseqdatalist.push(obj)
                  }
                });
              }
            }
            else{
              let backupSeq = JSON.parse(JSON.stringify(this.interfacedetails['VAConnectivityList']['ConnectivityType']))
              if(this.connseqdatalist.length>0)
              {
                this.connseqdatalist.forEach(X=>{
                  backupSeq = backupSeq.filter(element=> element['TYPENAME']!== X['Name']);
              })
              }
              if(backupSeq.length>0)
              {
                let flag=1;
              backupSeq.forEach(X => {
                if(X['Name']==V['TYPENAME']){
                  if(flag){
                    obj={
                      'Name':'Description',
                      'Value':X['Description']
                    }
                    X['Data'].push(obj)
                  }
                  flag=0
                  obj={
                    'Name':V['TYPENAME'],
                    'Data':X['Data']
                  }
                  this.connseqdatalist.push(obj)
                }
              });
            }

            }


          })
        }
        if(this.connseqdatalist.length==0)
        {
          this.sequence=[]
        }
        this.category=[]
        this.interfacedetails['DateWiseFailureCount'].forEach(Element =>{
          this.category.push(Element['Date'])
        });
        let fail=[]
        this.interfacedetails['DateWiseFailureCount'].forEach(Element =>{
          fail.push(parseInt(Element['StatusCount']['FailureCcount']))
        });
        let ignore=[]
        this.interfacedetails['DateWiseFailureCount'].forEach(Element =>{
          ignore.push(parseInt(Element['StatusCount']['IgnoreCount']))
        });
        let success=[]
        this.interfacedetails['DateWiseFailureCount'].forEach(Element =>{
          success.push(parseInt(Element['StatusCount']['SuccessCount']))
        });
        let resolved=[]
        this.interfacedetails['DateWiseFailureCount'].forEach(Element =>{
          resolved.push(parseInt(Element['StatusCount']['ResolvedCount']))
        });
        this.chartOptions={
          chart: {
            type: 'column',
          },
          title: {
            text: ''
          },
          exporting: {
            enabled: false
          },
          xAxis: {

            categories: this.category,
            labels: {
              style: {
                  color: 'black',
                  fontSize:'9px'
              }
          },
            crosshair: true
          },
          yAxis: {
            title: {
              text: ''
            }
          },
          tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            },
          },
          series: [{
            name: 'Failure',
            data: fail

          }, {
            name: 'Ignored',
            data: ignore

          }, {
            name: 'Success',
            data: success

          }, {
            name: 'Resolved',
            data: resolved
          }],
          credits: {
            enabled: false
        },
        }

        this.connseqdatalist.length>0?this.getseqdata(this.connseqdatalist[0]['Name']):'';
      }
      else{
        this.toastr.error("Unable to fetch pipeline details", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      this.ngxUiLoaderService.stop();
    }),error=>{
      this.toastr.error("Unable to fetch pipeline details", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.ngxUiLoaderService.stop();
    }
  }
  copyToClip(event: any, message) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + 'Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    
  }
  
  copyToClips(event: any, message: string) {
    
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
  }
  reprocessdata(c)
  {
    this.ngxUiLoaderService.start();
    let checkedids=""
    this.failurereason.forEach(V=>{
      if(V['ischecked']==true)
      {
        checkedids+=V['MessageRefernceId']+','
      }
    })
    checkedids!=''?checkedids.slice(0, -1):checkedids;
    this.service.reprocessBymsgID(checkedids.substring(0,checkedids.length-1)).subscribe(data =>{
      if(data['status'].toLowerCase()=='success')
      {
        this.toastr.success("done successfully", "Reprocess", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.getpipelinedetails({'UniqueID':this.isselect},'pop');
        this.closepopup(c);
      }
      else{
        this.toastr.error("Failed", "Reprocess", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      this.ngxUiLoaderService.stop();
    })
  }

  base64data:string
  reportexport(value:string)
  {
    this.ngxUiLoaderService.start()
    let reporttype=''
    value!=''?reporttype='default':this.isdate?reporttype='datewise':reporttype='failurewise'
    let obj={"routeID": this.pipeLineDetails['Id'],
    "direction": this.pipeLineDetails['Direction'],
    "displayname": this.pipeLineDetails['DisplayName'],
    "messageType": this.pipeLineDetails['Messagetype'],
    "lab": (this.pipeLineDetails['Direction'].toLowerCase()).includes('Inbound')?this.pipeLineDetails['DestinationEntity']:this.pipeLineDetails['SourceEntityName'],
    "vendor": this.pipeLineDetails['VendorName'],
    "connectivity": "",
    "user": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
    "reportType": reporttype,
    "failureDate": this.selecteddate,
    "failureReason": this.reason,
    "uniqueId":this.isselect
    }

    this.service.ciisreportexport(obj).subscribe(data =>{
      if(data['status']!=null&&data['status'].toLowerCase()=='success')
      {
        this.base64data='data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+data['responsecontent'];
        this.downloadfile();
      }
      else{
        this.toastr.error("Unable to export report", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      this.ngxUiLoaderService.stop();
    }),error=>{
      this.toastr.error("Unable to export report", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.ngxUiLoaderService.stop();
    }
  }
  downloadfile()
  {
const source = this.base64data;
      const link = document.createElement("a");
      link.href = source;
      link.download = 'InterfaceHistory_'+this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      link.click();
  }
  ishide:boolean=true
  backtolist(){
    this.interfacedetails=[]
    this.ishide=true
  }
  isChecked:boolean
  myinterface(event:any)
  {
    this.isChecked=event.checked;
  }
  isLoadingpa: boolean = false;
  ProcessorAgentNameList:any[]=[]
  ProcessorList:any[]=[]
  async getProcessorAgentNames(event: any) {
    this.isLoadingpa = true;
    var apiname = "api/Agents/GetByDisplayName?name=" + '&agentid=0&type=Processor&filterflag=false';

      await this.service.getapiurl(apiname).subscribe({
        next: async data => {
          this.ProcessorAgentNameList=data
          this.ProcessorAgentNameList.map(obj => { obj.name = obj.displayname, obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}` });
          this.ProcessorList = this.ProcessorAgentNameList;
          this.isLoadingpa = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            if (this.ProcessorList.length > 0) {
              this.ProcessorList = this.service.transformAlphabetically(this.ProcessorList, 'displayname');
            }
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingpa = false;
          this.notfounddata = true;
        }
      });
  }
  selectedProcessorAgentguid:string=''
  selectedProcessorAgentName:string=''
  selectedProcessorAgents(item) {
    this.selectedProcessorAgentguid = item.id;
    this.selectedProcessorAgentName = item.displayname;
  }
  searchProcessorAgentCleared(event) {
    this.isLoadingpa = false;
    // this.ProcessorAgentNameList = this.ProcessorList;
    this.ProcessorAgentNameList = [];
    this.selectedProcessorAgentguid = '';
    this.notfounddata = false;
  }
  selectedmessagetype: string = '';
  selectedmessagetypeevent(item) {
    this.selectedmessagetype = item.displayname;
  }
  selecteddirectiontype:string=''
  selecteddirectiontypeevent(item) {
    this.selecteddirectiontype = item.name;
  }
  Clearemessagetype(event) {
    this.selectedmessagetype = '';
    // this.messageTypeList = this.messageTypeAllList;
    this.messageTypeList = [];
  }
  Cleardirectiontype(event) {
    this.selecteddirectiontype = '';
    // this.messageTypeList = this.messageTypeAllList;
    this.directionlist = [];
  }
  isLoadingmessagetype: boolean = false;
  messageTypeAllList: any = [];
  messageTypeList:any=[];
  directionlist:any[]=[];

  getMessagetypelist(event) {
    this.isLoadingmessagetype = true;
    if (event !== 'msgtype') {
      if (event == undefined) {
        this.messageTypeList = this.messageTypeAllList;
      }
      else {
        if (this.messageTypeList.length == 0) {
          this.messageTypeList = this.messageTypeAllList;
        }
        this.messageTypeList = this.messageTypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageTypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingmessagetype = false;
    }
    else {

      this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: data => {
          data.forEach(element => {
            this.messageTypeAllList.push({ displayname: element });
          });
          this.messageTypeList = this.messageTypeAllList;

          this.isLoadingmessagetype = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingmessagetype = false;
          this.notfounddata = true;
        }
      });
    }

  }
  reset() {
    this.pagenumber=1
    this.interfacelist=[]
    this.reactiveFormdirection.reset();
    this.isChecked=false
    this.status=true
    this.fromdate=''
    this.todate=''
    this.reactiveFormProject.reset();
    this.isadvancefilter=false;
    this.showexportbutton=false;
    this.reactiveFormroute.reset();
    this.reactiveFormpa.reset();
    this.reactiveFormde.reset();
    this.reactiveFormse.reset();
    this.selectedProcessorAgentguid = "";
    this.selectedrouteId = "";
    this.selectedmessagetype = '';
    this.selectedSourceEntityguid = "";
    this.selectedTargetEntityguid = "";
    this.selectedEntityguid = "";
    this.vendorname = "";
    this.reactiveFormvendor.reset();
    this.reactiveFormvendor.setValue({ "vendorname": '' });
    this.Vendornamelist = this.VendornameBackuplist;
    this.ProcessorAgentNameList = this.ProcessorList;
    this.reactiveFormpa.setValue({ "paname": '' });
    this.reactiveFormmsgtype.reset();
    this.reactiveFormmsgtype.setValue({ "msgname": '' });
    this.reactiveFormdirection.setValue({ "direction": '' });
    this.pagenumber = 0;
    if (!this.showSourceTarget) {
      this.showSourceTarget = true;
    }
    this.messageTypeList = this.messageTypeAllList;
    this.reactiveFormentity.reset();
    this.selectedEntityName = '';
    this.totalrecords = 0;
    this.selectedprojId ='';
  }
  isLoadingVendorName: Boolean = false;
  Vendornamelist:any[]=[]
  VendornameBackuplist:any[]=[]
  vendorname:string=''
  getVendornames() {
    this.ngxUiLoaderService.start();
    var url = "api/Vendor/GetVendorName";
    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        this.Vendornamelist=data
        this.Vendornamelist = this.service.transformAlphabetically(this.Vendornamelist, 'name');
        this.VendornameBackuplist = this.Vendornamelist;
        this.ngxUiLoaderService.stop();
      }
      else {
        this.ngxUiLoaderService.stop();
      }
    })
  }
  vendorid:string=''
  selectedVendor(item) {
    this.vendorname = item.name;
    this.vendorid=item.Id
  }
  isLoadingvendor:boolean
  searchVendorCleared() {
    this.isLoadingvendor = false;
    this.vendorname = "";
    this.Vendornamelist = this.VendornameBackuplist;
    this.reactiveFormvendor.setValue({ "vendorname": '' });
  }
  getVendorName(event) {
    this.isLoadingvendor = false;
    if (this.Vendornamelist.length > 0) {
      this.filtervendorlist(event);
    }
    else {
      this.Vendornamelist = this.VendornameBackuplist;
      this.filtervendorlist(event);
    }
  }
  filtervendorlist(event) {
    this.Vendornamelist = this.Vendornamelist.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
    if (this.Vendornamelist.length == 0) {
      this.notfounddata = true;

    } else {
      this.notfounddata = false;
    }
    this.isLoadingvendor = false;
  }
  selectedEntityguid:string
  selectedEntityName:string
  selectedEntity(item) {
    this.selectedEntityguid = item.LocationGUID;
    this.selectedEntityName = item.LocationName;
  }
  isLoadingentity:boolean
  EntityNameList:any[]=[]
  searchEntityCleared() {
    this.isLoadingentity = false;
    this.EntityNameList = [];
    this.selectedEntityguid = '';
    this.notfounddata = false;
  }
  async getEntityNames(event: any) {

    var valuesa = event;
    if (valuesa != '') {

      this.isLoadingentity = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingentity = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.EntityNameList = response;
        this.EntityNameList = this.service.transformAlphabetically(this.EntityNameList, 'LocationName');
        this.EntityNameList.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedEntityguid = '';
        this.isLoadingentity = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      }
    }

  }
  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }
  buildtitle(Obj: any) {
    if (typeof (Obj) == "object" && Obj != null) {
      let name = `(${Obj.Type}):${Obj.LocationName}
      ${Obj.Address1}
      `
      return name;
    }
  }
  selectedSourceEntityguid:string=''
  selectedSourceEntityName:string=''
  selectedSourceEntity(item) {
    this.selectedSourceEntityguid = item.LocationGUID;
    this.selectedSourceEntityName = item.LocationName;
  }
  isLoadingse:boolean
  SourceEntityNameList:any[]=[]
  searchSourceEntityCleared(event) {
    this.isLoadingse = false;
    this.SourceEntityNameList = [];
    this.selectedSourceEntityguid = '';
    this.notfounddata = false;
  }
  async getSourceEntityNames(event: any) {

    var valuesa = event;
    if (valuesa != '') {

      this.isLoadingse = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingse = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.SourceEntityNameList = response;
        this.SourceEntityNameList = this.service.transformAlphabetically(this.SourceEntityNameList, 'LocationName');
        this.SourceEntityNameList.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedSourceEntityguid = '';
        this.isLoadingse = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      }
    }

  }
  selectedTargetEntityguid:string=''
  selectedTargetEntityName:string=''
  selectedTargetEntity(item) {
    this.selectedTargetEntityguid = item.LocationGUID;
    this.selectedTargetEntityName = item.LocationName;
  }
  isLoadingde:boolean
  TargetEntityNameList:any[]=[]
  searchTargetEntityCleared(event) {
    this.isLoadingde = false;
    this.TargetEntityNameList = [];
    this.selectedTargetEntityguid = '';
    this.notfounddata = false;

  }
  async getTargetEntityNames(event: any) {
    var valueta = event;
    if (valueta != '') {

      this.isLoadingde = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueta;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingde = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.TargetEntityNameList = response as any[];
        this.TargetEntityNameList = this.service.transformAlphabetically(this.TargetEntityNameList, 'LocationName');
        this.TargetEntityNameList.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedTargetEntityguid = '';
        this.isLoadingde = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }

      }
    }
  }
  selectedprojId:string=''
  selectedProjName:string=''
  pagenumber:any=1
  totalrecords:Number
  dateoption:string="Modified"
  selectedProject(item){
    this.selectedprojId = item.id,
    this.selectedProjName = item.projectName
    this.pagenumber = 1;
    this.totalrecords = 0;
  }
  isLoadingProjects:boolean
  notfoundProj:boolean
  ProjectNames=[]
  getProjectNames(event : any){

    var val=event;
    if(val){
      this.isLoadingProjects = true;
      // var apiname = "api/Projects/GetProjectNames?searchStr="+ val ;
      this.service.getProjList(val).subscribe(data=>{
        if(data.statusmessage.length == 0 || data.statusmessage == "No data found"){
          this.notfoundProj = true;
          this.isLoadingProjects =false;
        }else if(data.statusmessage.length>0){
          this.ProjectNames = JSON.parse(data.statusmessage);
          this.ProjectNames.find(obj=> obj.projectName = `${obj.projectName} ${'(' + obj.projectId + ')'}`);
          this.isLoadingProjects =false;
        //   if(filterd.length>0){
        //   for(let i=0;i<filterd.length;i++){
        //   this.reactiveFormProject.patchValue({projectName:filterd[i].projectName})
        //   }
        // }
        }
        else{
          this.notfoundProj = false;
          this.isLoadingProjects =false;
        }
    },error=>{
      this.isLoadingProjects = false;
      this.notfoundProj = true;
    })
  }
  }

  searchProjectCleared(event) {
    this.isLoadingProjects = false;
    this.ProjectNames = [];
    this.selectedProjName = '';
    this.selectedprojId = '';
    this.notfoundProj = false;
  }
  onScroll($event) {
    let count = 0;
    let isvalid = true;
    count = (parseInt(this.totalrecords.toString()) / 15);
    let exactvalue = Math.floor(count);
    let result = (count - exactvalue) !== 0;
    if (result && ((exactvalue + 1) == this.pagenumber)) {
      isvalid = false;
    }
    else if (this.pagenumber == count) {
      isvalid = false;
    }

    if (isvalid) {
      this.ngxUiLoaderService.start();
      this.pagenumber += 1;
      this.getpipelinelist(true,true)
    }
  }
  onUp() {

  }
  openvarulepopup() {
    var dpkeyname = '';
    if ((this.pipeLineDetails['Direction'].toLowerCase()).includes('inbound')) {
      dpkeyname = this.targetdeploy;
    }
    else {
      dpkeyname = this.sourcedeploy;
    }

    let dataToruleModal = {
      routename: this.pipeLineDetails['DisplayName'],
      islocked: this.pipeLineDetails['Islocked']!=null?this.pipeLineDetails['Islocked']:false,
      deploymentkey: dpkeyname
    };
    if (this.sourcetype.toLowerCase() == 'organization') {
      dataToruleModal["LabOrgId"] = this.pipeLineDetails['SourceEntityId'];
    }
    else if (this.sourcetype.toLowerCase() == 'account') {
      dataToruleModal["LabId"] = this.pipeLineDetails['SourceEntityId'];
    }

    if (this.targettype.toLowerCase() == 'organization') {
      dataToruleModal["OrdFacOrgId"] = this.pipeLineDetails['DestinationEntityId'];
    }
    else if (this.targettype.toLowerCase() == 'account') {
      dataToruleModal["OrdFacilityId"] = this.pipeLineDetails['DestinationEntityId'];
    }
    else if (this.targettype.toLowerCase() == 'facility') {
      dataToruleModal["OrdFacilityId"] = this.pipeLineDetails['DestinationEntityId'];
    }
    const modalRef = this.modalService.open(VarulesComponent,
      { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = dataToruleModal;
    modalRef.result.then(() => { });
  }
  pendingfilecount:number=0
  pendingfiledate:any=''
  getpendingfiles(rid){
    this.pendingfiledate=this.datesplit((this.datepipe.transform(new Date(), 'dd-MM-yyyy')).toString());
    this.service.pendingfiles(rid).subscribe(data=>{
      if(data.length>0){
        let obj= data.forEach(V => {
          if(V['Header'].toLowerCase().includes('ftp')||V['Header'].toLowerCase().includes('vpn'))
          {
            V['LableValue'].forEach(X => {
              if((X['labelName'].toLowerCase())=='pendingfile')
              {
                this.pendingfilecount=X['count'];
              }
            });
          }
        });
      }
    })
  }
  checkStatus(connectivity: any) {
    if (connectivity !== undefined) {
      let val = connectivity.LableValue.filter(
        (value) => value.status === false
      );
      if (val.length > 0) {
        connectivity["status"] = "Failed";
      } else {
        connectivity["status"] = "Success";
      }
    }
  }
  DiagnosticList:any[]=[]
  getDiagnostic() {
    this.DiagnosticList =[]
    this.ngxUiLoaderService.start();
    this.service.getDaignostics(this.selectedRoutGUID).subscribe(
      (data) => {
        this.ngxUiLoaderService.stop();
        if (data) {
          data.forEach((element) => {
            this.checkStatus(element);
          });
          this.DiagnosticList = data;
          let notfound=[];
        let backupSeq = JSON.parse(JSON.stringify(this.sequence))
          this.DiagnosticList.forEach(X=>{
            backupSeq = backupSeq.filter(V=> V['TYPENAME']!== X['Header']);
        })
          if(backupSeq.length>0)
          {
            backupSeq.forEach(V=>{
              let obj= {
                "Header": V['TYPENAME'],
                "SequenceNumber": V['ID'],
                "LableValue": [],
                "status": "default"
            }
            this.DiagnosticList.splice(V['ID']-1,0,obj)
            })
          }

          this.connseqdatalist.forEach(V=>{
            this.DiagnosticList.forEach(X=>{
              if(V['Name']==X['Header']){
                V['status']=X['status']
              }
            });
          })
        }
      },
      (error) => {
        console.error();
        this.ngxUiLoaderService.stop();
        this.toastr.error(error.statusText, "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    );
  }
  seqdataarr:any[]=[]
  getseqdata(value:string)
  {
    this.selectedSeqName=value
    this.seqdataarr=this.connseqdatalist.find(V=>V['Name']==value);
  }


  async exportreport()
  {
    ;
   var searchData ={
      "messagetype": this.selectedmessagetype,
      "id": this.selectedrouteId,
      "PAID": this.selectedProcessorAgentguid,
      "sourceagentid": "",
      "SEGUID": this.selectedSourceEntityguid,
      "destinationagentid": "",
      "DEGUID": this.selectedTargetEntityguid,
      "vendor": this.vendorid,
      "entityid": "",
      "pagenumber":0,
      "PageSize":0,
      "userType": (this.isChecked) ? 2 : '',
      "type":'not',
      "projectId":this.selectedprojId,
      "assignedUserId": (this.isChecked) ? JSON.parse(sessionStorage.getItem('rolewithmenus'))[0].uniqueId : '',
      "status":this.status?"active":"passive",
      "FromDate": this.fromdate,
      "ToDate":this.todate,
      "Direction":this.selecteddirectiontype,
      "DateType":this.fromdate!=''&&this.todate!=''?this.dateoption:''
    };
    var apiname = 'api/Routes/GetSearchDetails';
    this.ngxUiLoaderService.start();

    let response = await this.service.searchpostapi(apiname, searchData);

    const fileName = 'PipelineList.xlsx';
    this.exportToExcel(response, fileName);
    this.ngxUiLoaderService.stop();
  }

  exportToExcel(items: any[], fileName: string): void {

    const selectedItems = items.map(item => {
      return {
        "Pipeline Name": item.displayname,
        "GUID": item.id,
        "Source Entity": item.SENAME,
        "Destination Entity": item.DENAME,
        "Source Agent": item.SourceAgent,
        "Destination Agent": item.DestinationAgent,
        "Processor Type": item.PANAME,
        "Message Type": item.messagetype,
        "Direction": item.Direction,
       "Mode": item.mode,
        "Connectivity": item.Connectivity,
        "Vendor": item.vendor,
        "Created Date ": item.CreatedDate,
        "Go-Live Date": item.GoLiveDate,
        "Last Transaction Date": item.LastTransactionDate,
        "Status": item.status
      };
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedItems);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob: Blob = new Blob([excelBuffer], { type: 'application/xml' });
    //saveAs(blob, fileName);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

  }
  
  getSearchbyAccessionorOrderNumber(event)
  {
    let searchtext = event.target.value.toLowerCase();
    if(searchtext !== ''){
      this.failurereason = this.failurereasonBackup.filter(s=>s.OrderNumber.toLowerCase().includes(searchtext) || s.AccessionNumber.toLowerCase().includes(searchtext));
    }
    else{
      this.failurereason = JSON.parse(JSON.stringify(this.failurereasonBackup));
    }
  }

   //The below method will be called when the user clicks on the data validation button under drop down
   OnValidation(content: any, data: any) {
    this.getInterfaceValidationHistoryInfo();
    this.pipelineName = data['displayname'];
    this.pipelineGUID = data.id;
    this.interfacePipelineDisplayname = this.pipelineName + "(" + this.pipelineGUID + ")";
    this.backupInterfacePipelineDisplayname = this.interfacePipelineDisplayname;
    const modalRef = this.modalService.open(content,
      { windowClass: "ValidationPopupScreen", backdrop: 'static', keyboard: false });
    modalRef.result.then(() => { });
  }

  //The below method will fetch the record from ValidationMaster table and stores the data into InterfaceValidationHistory
  getInterfaceValidationHistoryInfo() {
    this.ngxUiLoaderService.start();
    this.service.GetInterfaceValidationHistory().subscribe((data: any) => {
      console.log(data);
      this.ngxUiLoaderService.stop();
      this.InterfaceValidationHistory = data;
      for (let i = 0; i < this.InterfaceValidationHistory.length; i++) {
        this.InterfaceValidationHistory[i].FromDate = this.InterfaceValidationHistory[i].FromDate.split(" ")[0];
        this.InterfaceValidationHistory[i].ToDate = this.InterfaceValidationHistory[i].ToDate.split(" ")[0];
      }
      this.InterfaceValidationHistory.sort((a, b) => {
        if (a.Status === 'In-Progress' && b.Status !== 'In-Progress') {
          return -1; // a comes before b
        } else if (a.Status !== 'In-Progress' && b.Status === 'In-Progress') {
          return 1; // a comes after b
        } else {
          return 0; // preserve original order
        }
      });

      this.searchtextData = this.pipelineName;
      this.getFilter();
    }, error => {
      this.toastr.error("Unable to fetch pipeline details", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.InterfaceValidationHistory=[]
      console.error(error);
      this.ngxUiLoaderService.stop();
    });
  }

  checkValidationMasterExists(reValidation){
    let filtereddata:any;
    
    if(this.InterfaceValidationHistory[0].FromDate.includes("/")){
      filtereddata = this.InterfaceValidationHistory.filter(va => va.PipelineName == this.pipelineName && 
        va.PipelineGUID == this.pipelineGUID 
        && va.FromDate == (this.datepipe.transform(this.selectedFromDate, 'MM/dd/yyyy')).toString().replace(/\b0/g, '')  
        && va.ToDate == (this.datepipe.transform(this.selectedToDate, 'MM/dd/yyyy')).toString().replace(/\b0/g, '')  
        && va.Status == 'In-Progress');
    }else{
      filtereddata = this.InterfaceValidationHistory.filter(va => va.PipelineName == this.pipelineName && 
        va.PipelineGUID == this.pipelineGUID 
        && va.FromDate == (this.datepipe.transform(this.selectedFromDate, 'dd-MM-yyyy')).toString()     
        && va.ToDate == (this.datepipe.transform(this.selectedToDate, 'dd-MM-yyyy')).toString() 
        && va.Status == 'In-Progress');
    }
    if(filtereddata.length > 0)
    {
      this.toastr.success("Data Validation is In-Progress for the selected date range", " ", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
     return false;
    }
    else {
      if(this.InterfaceValidationHistory[0].FromDate.includes("/")){
        filtereddata = this.InterfaceValidationHistory.filter(va => va.PipelineName == this.pipelineName && 
          va.PipelineGUID == this.pipelineGUID 
          && va.FromDate == (this.datepipe.transform(this.selectedFromDate, 'MM/dd/yyyy')).toString().replace(/\b0/g, '')  
          && va.ToDate == (this.datepipe.transform(this.selectedToDate, 'MM/dd/yyyy')).toString().replace(/\b0/g, '')  
          && va.Status == 'completed');
      }else{
        filtereddata = this.InterfaceValidationHistory.filter(va => va.PipelineName == this.pipelineName &&
          va.PipelineGUID == this.pipelineGUID
          && va.FromDate == (this.datepipe.transform(this.selectedFromDate, 'dd-MM-yyyy')).toString()
          && va.ToDate == (this.datepipe.transform(this.selectedToDate, 'dd-MM-yyyy')).toString()
          && va.Status == 'completed');
      }
      if (filtereddata.length > 0) {
        //calling revalidation method
        this.filteredInterfaceRevalidationData = filtereddata;
        this.reValidation(reValidation);
        return false;
      } else
        return true;
    }
  }
  isDateRangeValid(): boolean {
    
    let isactie=false;
    let today = new Date()
    if  ( today >= this.selectedFromDate && today >= this.selectedToDate){
      if( this.selectedFromDate <= this.selectedToDate ){
        return true
      }
    }
    return isactie
  }
  //sends the selected interface information to the ValidationMaster table and then to topic
  sendDataForDataValidation(reValidation) {
    let requestBody;
    if (this.calculateDiff() < 7) {
      if (this.checkValidationMasterExists(reValidation)) {
        requestBody = {
          PipelineGUID: this.pipelineGUID,
          PipelineName: this.pipelineName,
          FromDate: this.selectedFromDate == undefined ? '' : this.datepipe.transform(this.selectedFromDate, 'yyyy-MM-dd') + ' 00:00:00',
          ToDate: this.selectedToDate == undefined ? '' : this.datepipe.transform(this.selectedToDate, 'yyyy-MM-dd') + ' 23:59:59',
          Generated_By: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
          Status: 'In-Progress'
        };
        this.service.InsertInterfaceValidationHistory(requestBody).subscribe((ReturnResponse: any) => {
          if (ReturnResponse.status == "Success") {
            requestBody = {
              fromDate: this.selectedFromDate == undefined ? '' : this.datepipe.transform(this.selectedFromDate, 'yyyy-MM-dd') + ' 00:00:00',
              toDate: this.selectedToDate == undefined ? '' : this.datepipe.transform(this.selectedToDate, 'yyyy-MM-dd') + ' 23:59:59',
              pipelineGUID: this.pipelineGUID,
            };
            this.sendDataForDataValidationTopic(requestBody);
            this.toastr.success("Successfully sent interface information for Data Validation, it might take few minutes to process the validation.", " ", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          } else {
            console.log("sendDataForDataValidation() returnResponse from validationmaster insertion:: " + JSON.stringify(ReturnResponse));
          }
        }, (error) => {
          console.error("sendDataForDataValidation() error occured :: " + error);
          this.toastr.error(error, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        });
      }
    }
    else {
      this.toastr.error("Date range should not exceed 7 days", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  sendDataForDataValidationTopic(requestBody:any){
    this.ngxUiLoaderService.start();
    this.service.sendToTopic(requestBody).subscribe((response: any) => {
      this.ngxUiLoaderService.stop();
      console.log(response);
      this.getInterfaceValidationHistoryInfo();
    });
  }

  calculateDiff() {
    
    let todayDate = new Date(this.selectedToDate);
    let sentOnDate = new Date(this.selectedFromDate);
    sentOnDate.setDate(sentOnDate.getDate());
    let differenceInTime = todayDate.getTime() - sentOnDate.getTime();
    // To calculate the no. of days between two dates
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  }

  reValidation(reValidation)
  {
    
    this.modalReference = this.modalService.open(reValidation,
      { windowClass: "ReValidationPopupScreen", backdrop: 'static', keyboard: false });
      this.modalReference.result.then(() => { });
  }

  //Checks the status of the validationmaster entry
  isCompletedStatus(status: string): boolean {
    return status === 'completed';
  }

  //onClick of each validationmaster row entry the below method will get called and fetches record from the entitysearch table
  displayInterfaceValidationList(item: any) {
    
   
    // this.backupPipelineName = item['PipelineName'];
    // this.backupPipelineGUID = item.PipelineGUID;
    this.pipelineName = item['PipelineName'];
    this.pipelineGUID = item.PipelineGUID;
    // this.fromdate=item.FromDate;
    // this.todate=item.toDate;
    
    
    this.interfacePipelineDisplayname = '';
    setTimeout(() => {
      this.interfacePipelineDisplayname = item.PipelineName + "(" + item.PipelineGUID + ")";
    })
    debugger

    this.getResponseDatabyMessageIdInfo(item);
  }

  transformDate(value:any){
if(value !== undefined){
  let dates = value.split("-");
  if(dates.length>0){
    value = dates[1]+'-'+dates[0]+'-'+dates[2]
  }
  return value;

}
  }

  //Fetches record from entitysearch table against pipelineguid
  getResponseDatabyMessageIdInfo(item:any) {
    let fromDate;
    let toDate;
    this.ngxUiLoaderService.start();
    this.callingComponent = 'Interface'
    if (item.FromDate.includes("/")) {
      fromDate=this.datepipe.transform(item.FromDate, 'yyyy-MM-dd');
      toDate=this.datepipe.transform(item.ToDate, 'yyyy-MM-dd');  
    }
    else{
      fromDate= this.transformDate(item.FromDate);
      toDate=this.transformDate(item.ToDate);
    }
    this.service.GetDatabyMessageIdInfo(item.PipelineGUID,fromDate+ ' 00:00:00',toDate  + ' 23:59:59').subscribe((result: any) => {
      console.log(result);
      this.ngxUiLoaderService.stop();
      if (result.length > 0) {
    
        this.showInterfaceData = true; 
     
        setTimeout(() => {
          this.interfacePipelineDisplayname =  this.pipelineName + "(" + this.pipelineGUID + ")";
        })
        for (let i = 0; i < result.length; i++) {
         let ovj={
          "Accession": null,
          "Order": null,
          "DateofBirth":null,
          "PatientName": null,
          "MessageId":null
      }
          result[i] = Object.assign(ovj, result[i]);
          
        }
        this.dataSources = result;
        console.log("datasource value is " +JSON.stringify(this.dataSources));
        this.selectedCard(this.dataSources[0], 0);
        this.responseLength = result.length;
     
      }
      else {
        this.ngxUiLoaderService.stop();
        this.toastr.error("No messages were found for data validation", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }, (error) => {
      this.ngxUiLoaderService.stop();
    });
  }

  //Calls GetInterfaceMessageDataByMessageId method and displays the validation data of particular message
  selectedCard(item: any, index: number) {
    this.selectedCardIndex = index;
    this.GetInterfaceMessageDataByMessageId(item);
  }


  //For a particular selected message gets the data validation response from messagevalidation table and shows the difference
  GetInterfaceMessageDataByMessageId(item) {
  // let messageid = '7d955b6a-29a9-4720-b908-7be90e24e3e8';//'3220a460-473c-4c80-bca2-b1a02b0074af'' f05917c0-8b33-4bdd-ae5e-6be711224ae4''3840fe3b-e1ca-4dce-bdc2-27f7b33704c8''809f2980-913e-48c7-a7db-21de9882be74';//'880bf041-acbd-42d7-b0ac-663c7d755fff';
    let messageid= item.MessageId;
    this.ngxUiLoaderService.start();
    this.service.GetMessageDataByMessageId(messageid).subscribe((result: any) => {
      this.ngxUiLoaderService.stop();
      console.log(result); 
      if(result[0] !== undefined){
        if(result[0].StatusMessage !==''){
          this.validatedDataResponse = JSON.parse(result[0].StatusMessage);
        }else{
          this.validatedDataResponse=[];
        }
      }
      else{
        this.validatedDataResponse=[];
      }
    });
  }

  //The below method is used for search of interface validation history data
  getFilter() {
    this.tripfilter.PipelineName = this.searchtextData;
    this.tripfilter.Fromdate = this.searchtextData;
    this.tripfilter.Todate = this.searchtextData;
    this.tripfilter.Generated_By = this.searchtextData;
    this.tripfilter.Generated_On = this.searchtextData;
    this.tripfilter.Status = this.searchtextData;
  }

  //The below method is used for search of  selectedcard data(Accession#,Order#,patientname)
  getFilterDetails()
  {
        this.cardfilter.Accession=this.searchData;
        this.cardfilter.Order=this.searchData;
        this.cardfilter.PatientName=this.searchData;
        this.cardfilter.DateofBirth=this.searchData;
        //this.cardfilter.RequisitionNumber=this.searchData;
        this.cardfilter.MessageId=this.searchData;
  }

  //The below method will be called when clicked on the back button, and loads the data
  loadInterfaceDataValidationScreen() {
    this.ngxUiLoaderService.start();
    this.showInterfaceData = false;
    
    this.interfacePipelineDisplayname = this.backupInterfacePipelineDisplayname;
    this.responseLength = 0;
    this.searchtextData = '';
    this.getFilter();
    this.getFilterDetails();
    this.ngxUiLoaderService.stop();
  }

  performRevalidation() {
    let requestBody;
    let fromdate;
    let todate;
    fromdate= this.selectedFromDate == undefined ? '' : this.datepipe.transform(this.selectedFromDate, 'yyyy-MM-dd') + ' 00:00:00';
    todate= this.selectedToDate == undefined ? '' : this.datepipe.transform(this.selectedToDate, 'yyyy-MM-dd') + ' 23:59:59';
    this.ngxUiLoaderService.start();
    this.service.DeleteInterfaceValidatedMessages(this.pipelineGUID,fromdate,todate)
.pipe(
    // Handle first message
    map((message) => requestBody = {
      "Id": this.filteredInterfaceRevalidationData[0].Id,
      "PipelineGUID": this.filteredInterfaceRevalidationData[0].PipelineGUID,
      "Generated_By": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
      "Generated_On" : new Date().toString(),
      "Status": "In-Progress"
    })

    // Trigger second call and handle data
    , mergeMap(() => this.service.UpdateValidationMaster(requestBody).pipe(catchError(() => of('There was an error but I fixed it!')))) 
    , map((message) => requestBody = {
      fromDate: this.selectedFromDate == undefined ? '' : this.datepipe.transform(this.selectedFromDate, 'yyyy-MM-dd') + ' 00:00:00',
      toDate: this.selectedToDate == undefined ? '' : this.datepipe.transform(this.selectedToDate, 'yyyy-MM-dd') + ' 23:59:59',
      pipelineGUID: this.pipelineGUID,
    })

    // Trigger third call and handle data
    , mergeMap(() => this.service.sendToTopic(requestBody))
    , map((message) => console.log(message))
).subscribe(data =>{
  console.log('Chain executed successfully. Good job :)')
  this.toastr.success("Successfully sent interface information for Data Validation, it might take few minutes to process the validation.", " ", {
    timeOut: 4000,
    positionClass: 'toast-bottom-right'
})
this.modalReference.close();
this.getInterfaceValidationHistoryInfo();
this.ngxUiLoaderService.stop();

  },error=>{
    this.ngxUiLoaderService.stop();
    console.error(error);
  })
}

  dismissRevalidation(content:any){
    this.modalReference.close();
    this.filteredInterfaceRevalidationData =[];
  }

  changeDateFormat(givendate) {
    if (givendate.includes("-")) {
      let datearray = givendate.split("-")
      if (datearray.length > 0) {
        return datearray[1] + '-' + datearray[0] + '-' + datearray[2]
      }
    } else {
      return givendate;
    }
  }


}

import { Component, EventEmitter, Input, OnInit, Output ,ViewChild,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup,FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import beautify from "xml-beautifier";
declare const copyToClipboard: any;
declare var $
import { COMMA, ENTER, V, X } from "@angular/cdk/keycodes";


import { SelectionModel } from '@angular/cdk/collections';


import { AppConfigService } from '../configurations/app-config.service';
import { ToastrService } from 'ngx-toastr';
import { MonacoEditorConstructionOptions, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
import { async } from '@angular/core/testing';
import { error } from 'console';
import { DatePipe } from '@angular/common';
import { isString } from 'util';
import { empty, of } from 'rxjs';
import { data } from 'highcharts';
import { DataValidationComponent } from 'src/app/Model/data-validation/data-validation.component';
import { mergeMap } from 'rxjs/operators';
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
  selector: 'app-hubmessages',
  templateUrl: './hubmessages.component.html',
  styleUrls: ['./hubmessages.component.scss']
})
export class HubmessagesComponent implements OnInit {
  panelOpenState;
  messageFullData = []
  msgfieldlist: any = [];
  @ViewChild('msgfieldInput', { static: true }) msgfieldInput: ElementRef<HTMLInputElement>;
  msgfieldctrl = new FormControl('');
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input() messageData;
  @Input() case360;
  @Output() case360search = new EventEmitter<string>();
  archivedbcheck: boolean = false;
  usernamedisplay: string;
  isLoadingroutes: boolean = false;
  isLoadingTarget: boolean = false;
  isLoadingProcesor: boolean = false;
  isLoadingsource: boolean = false;
  cardHeight: Number = window.innerHeight - 275;
  stagemodalheight: Number = window.innerHeight - 52;
  stagemodalheighttextarea: Number = window.innerHeight - 183;

  tablediv: boolean = false;
  statusmessageMainflg: boolean = false;
  keywordsearch = "displayname";

  RouteNameList: any = [];
  SourceAccountList: any = [];
  TargetAccountList = <any>[];
  ProcessorList = <any>[];
  messageList = <any>[];
  messagestageList = <any>[];
  messageTypeList = <any>[];


  selectedmessagetype: string = '';
  selectedrouteId: string = "";
  selectedrouteDisplayName: string = "";


  selectedsourceaccountid: string = '';
  selectedsourceaccountName: string = '';
  selectedtargetaccountid = "";
  selectedtargetaccountName = "";
  selectedprocessagentid = "";
  selectedprocessdisplayname = "";

  reactiveFormtarget: FormGroup;
  reactiveFormprocess: FormGroup;
  reactiveFormsource: FormGroup;
  reactiveFormroute: FormGroup;

  reactiveFormmessageid: FormGroup;
  reactiveFortransactionid: FormGroup;
  reactiveForentityfield: FormGroup;
  reactiveFormentity: FormGroup;
  selectedmessagestatustype: string = 'ALL'

  reactiveFormmsgtype: FormGroup;
  reactiveFormmsgstatus: FormGroup;
  isAddedForReprocess: boolean;
  screenHeight: number;
  notfounddata: boolean;
  message_iot_data: any = {};
  arhivecheckbackup: boolean;
  fileidarray: any[] = [];

  logIdList = <any>[];
  caseIdList = <any>[];
  handlerList = <any>[];
  statusMirth = <any>[];
  createdDate = <any>[];
  processingDate = <any>[];

  MirthFitlerForm: FormGroup;

  isLoadinghandler: boolean;
  isLoadingcaseid: boolean;
  isLoadinglogid: boolean;
  isLoadingstatusMirth: boolean;
  isLoadingCreatedDate: boolean;
  isLoadingProcessingDate: boolean;

  keywordsearchcaseid = "caseid";
  keywordsearchhandler = "handler";
  keywordsearchLogID = "LogId";
  keywordsearchstatusmirth = "status";
  keywordsearchcreatedDate = "createddate";
  keywordsearchprocessingDate = "processingdate";





  notfounddata1: boolean;
  notfounddata2: boolean;
  notfounddata3: boolean;
  notfounddata4: boolean;
  notfounddata5: boolean;

  SuccessMessageList: any[] = [];
  CreatedMessageList: any[] = [];
  failureMessageList: any[] = [];
  AutohealingMessageList: any[] = [];
  ProcessingMessageList: any[] = [];
  IgnoreMessageList: any[] = [];
  OtherMessageList: any[] = [];


  isContainsMultiplefileid: boolean;
  messageiotreprocess: boolean;
  downloadbuttonenable: boolean = true;
  backupinputdata: any;
  msgObjforiot: any;
  today = new Date();
  isEngine: any = "HUB";
  isFormView: boolean;
  selectedIndexTab: any = 0;
  mirthStatusList = [
    { displayname: "All" },
    { displayname: "New" },
    { displayname: "Success" },
    { displayname: "Failure" },
    { displayname: "Processing" },
    { displayname: "Ignore" },
    { displayname: "Others" },
  ]
  selecteditemscount: any;
  showSourceTarget: boolean = true;
  isLoadingentity: boolean = false;
  EntityNameList = <any>[];
  selectedEntityguid: string = '';
  selectedEntityName: string = '';
  messageMirthList: any[];
  messagestageMirthList: any[];
  totalmessagecountMirth: any;
  invalidlogID: boolean;
  invalidcaseid: boolean;
  isvalidSPLchar: boolean;
  reactiveFormparentmessageid: FormGroup;
  backupcase360message: any;

  constructor(private router: Router,
    private service: CommonService,
    private _fb: FormBuilder,
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal,
    private datePipe: DatePipe
  ) {
    this.getProcessorName('pipeline');
    this.reactiveFormsource = _fb.group({ name: '' });
    this.reactiveFormroute = _fb.group({ routename: '' });
    this.reactiveFormtarget = _fb.group({ namet: '' });
    this.reactiveFormprocess = _fb.group({ namep: '' });
    this.reactiveFormmessageid = _fb.group({ namemessageid: '' });
    this.reactiveFortransactionid = _fb.group({ nametransactionid: '' });
    this.reactiveForentityfield = _fb.group({ nameentityfieldid: '' });
    this.reactiveFormmsgtype = this._fb.group({ msgname: '' });
    this.reactiveFormmsgstatus = this._fb.group({ msgstatusname: '' });
    this.reactiveFormentity = this._fb.group({ entityname: '' });
    this.reactiveFormparentmessageid = this._fb.group({ parentmsgid: '' });
  }
  userrole: string = '';
  pagerolepermisions: any = [];
  isSearched: boolean = false;
  ngOnChanges() {

    this.messageList = this.messageData
    if (this.case360 != null || this.case360 != undefined) {

      if (this.case360.routeid !== undefined) {
        this.selectedrouteId = this.case360.routeid
        this.transactionidvalue = this.case360.transaction
        this.SearchMessages();
      } else {
        this.rolepermission()
        this.messageList = JSON.parse(JSON.stringify(this.case360.hubmessagedata))
        this.getMessagestatuslist('msgstatus');

      }
    }

  }



  showallcaseinboundmessage() {
    this.isSearched = true;
    this.isEngine = 'HUB'
    this.messageFullData = this.messageStatusAllList
    this.messageFullData.forEach((ele) => {
      ele.selector = new SelectionModel<any>(true, []);
    });
    this.backupcase360message = JSON.parse(JSON.stringify(this.case360.hubmessagedata))
    this.messageFullData[this.selectedIndexTab].messageList = JSON.parse(JSON.stringify(this.case360.hubmessagedata));
    this.messageFullData[this.selectedIndexTab].messagestageList = this.case360.hubmessagestage;
    this.messageFullData[this.selectedIndexTab].totalmessagecount = this.case360.hubmessagedata.length;
  }

  async ngOnInit() {
    this.setDateFilter()
    var inputdata = JSON.parse(sessionStorage.getItem('hubmessagedata'));
    if (inputdata != null) {

      sessionStorage.removeItem('hubmessagedata');
      this.selectedrouteId = inputdata.id;

      if (inputdata.status) {
        this.selectedmessagestatustype = inputdata.status;

        this.reactiveFormmsgstatus.patchValue({
          msgstatusname: this.selectedmessagestatustype
        });
        this.fromdate = this.datePipe.transform(this.getPreviousDay(new Date()), 'yyyy-MM-dd');
        this.todate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      }
      this.getMessagestatuslist('msgstatus');


      this.getRouteDetails();
      setTimeout(() => {
        this.SearchMessages();
      }, 1000);


    }
    this.MirthFitlerForm = this._fb.group({
      handler: "",
      LogId: "",
      caseid: "",
      status: "",
      createddate: "",
      processingdate: "",
      deployment: ""
    })

    // this.messageList = this.messageData.data;
    if (this.service.messagesData !== "") {
      this.messageData = JSON.parse(this.service.messagesData)
      this.isFormView = true
      this.isEngine = this.messageData['engine'];
      if (this.isEngine === "HUB") {
        this.reactiveFormroute.patchValue({
          routename: this.messageData['name']
        })
        this.selectedrouteId = this.messageData['name'];
        this.SearchMessages();
      } else {
        this.messageFullData = JSON.parse(JSON.stringify(this.mirthStatusList));

        this.messageFullData.forEach((ele) => {
          ele.selector = new SelectionModel<any>(true, []);
        });
        this.MirthFitlerForm.patchValue({
          handler: this.messageData.name,
        })
        this.messageFullData[this.selectedIndexTab].messageList = this.messageData.MessageDetails;
        // this.messageFullData[this.selectedIndexTab].messagestageList = response.hubmessagestage as any[];
        this.messageFullData[this.selectedIndexTab].totalmessagecount = this.messageData.MessageDetails.length;
        // this.messageStatusList = this.mirthStatusList;
        // this.messageStatusList.forEach( eleem =>{
        //   eleem['messageList'] =[];
        //   eleem['messagestageList'] =[];
        //   eleem['totalmessagecount'] =0;
        // })


        // this.messageList = this.messageData.MessageDetails
        this.isEngine = this.messageData['engine'];
        this.reactiveFormroute.patchValue({
          routename: this.messageData['name']
        })
        return
      }
      // this.selectedrouteDisplayName = item.displayname;
    }
    //  this.messageData.interfacename

    this.screenHeight = window.innerHeight
    if (await this.service.checksession() == true) {
      this.rolepermission()
      // const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuDisplayName == 'MESSAGES').MenuId;
      // const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      // actionitems.forEach(element => {
      //   this.pagerolepermisions[element.MenuName] = true;
      // });

      this.isAddedForReprocess = false;
      //this.GetMessageTypes();
      // this.GetMessageStatus();
      this.getMessagetypelist('msgtype');
      this.reactiveFormmsgtype.patchValue(this.messageTypeAllList);
      this.getMessagestatuslist('msgstatus');
      this.reactiveFormmsgstatus.patchValue(this.messageStatusAllList);
    }
  }


  rolepermission() {
    const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuDisplayName == 'MESSAGES').MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
    actionitems.forEach(element => {
      this.pagerolepermisions[element.MenuName] = true;
    });
  }
  ngAfterViewInit() {
    if (this.case360 != null || this.case360 != undefined) {
      this.fromdate = '';
      this.todate = '';
    }
  }
  setDateFilter() {
    var curr = new Date; // get current date
    var first = curr.getDay(); // First day is the day of the month - the day of the week
    var last = curr.getDate(); // last day is the first day + 6
    first = last - curr.getDay();
    ////console.log(curr, first, last);
    first = last - 2;
    let firstday = new Date(curr.setDate(first));
    let lastd = new Date()
    let lastday = new Date(lastd.setDate(last));
    let firstdate, lastdate, firstmonth, lastmonth
    firstmonth = firstday.getMonth() + 1 < 10 ? "0" + (firstday.getMonth() + 1) : firstday.getMonth() + 1;
    lastmonth = lastday.getMonth() + 1 < 10 ? "0" + (lastday.getMonth() + 1) : lastday.getMonth() + 1;
    firstdate = firstday.getDate() < 10 ? "0" + firstday.getDate() : firstday.getDate();
    lastdate = lastday.getDate() < 10 ? "0" + lastday.getDate() : lastday.getDate();

    this.fromdate = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    // this.todate = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    this.todate = `${lastday.getFullYear()}-${lastmonth}-${lastdate}`;

    ////console.log(this.datefilter,this.enddtfilter)
  }
  // CreatedMessageList
  FilterCreatedMessage() {
    let createmessge = this.messageList.filter(va => va.status === 'New' ? 'progress_card' : '')
    this.getmirthListData(createmessge)
  }

  isAllSelectedCreated() {
    const numSelected = this.createdSelection.selected.length;
    const numRows = this.CreatedMessageList.length;
    return numSelected === numRows;
  }

  createdSelection = new SelectionModel<any>(true, []);
  createdreprocess() {
    if (this.isEngine !== 'HUB') {
      this.mirthReprocessSelected(this.createdSelection.selected, 'Created')
    }
    else {
      this.reprocessSelected(this.createdSelection.selected, 'Created')
    }
  }

  masterToggleCreated() {
    this.isAllSelectedCreated() ?
      this.createdSelection.clear() :
      this.CreatedMessageList.forEach(row => this.createdSelection.select(row));
  }
  //----------------------------------------------------------------------------

  // SuccessMessageList
  FilterSuccessMessage() {
    if (this.messageData.MessageDetails.length > 0) {
      let successlist = this.messageData.MessageDetails.filter(va => va.status === '11' || va.status.toLowerCase() === 'success' || va.status.toLowerCase() === 'generated' ||
        va.status.toLowerCase() === 'generated-warnings' || va.status.toLowerCase() === 'generated with warnings' ||
        va.status.toLowerCase() === 'processed' || va.status.toLowerCase() === 'processed-warnings' ||
        va.status.toLowerCase() === 'attached' || va.status.toLowerCase() === 'order complete' ||
        va.status.toLowerCase() === 're-directed' || va.status.toLowerCase() === 'requisition attached' ||
        va.status.toLowerCase() === 'requisition-processed' || va.status.toLowerCase() === 'adt complete' ||
        va.status.toLowerCase() === 'adt processed with warning')
      this.getmirthListData(successlist)
    } else {
      {
        this.getmirthListData([])
      }
    }
  }

  filterdata360(status, index) {

    this.messageFullData.forEach((ele) => {
      ele.selector = new SelectionModel<any>(true, []);
    });
    switch (status) {
      case 'completed':
        this.messageFullData[index].messageList = this.backupcase360message.filter(va => va.status == '11')
        this.messageFullData[index].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[this.selectedIndexTab].totalmessagecount = this.messageFullData[index].messageList.length;
        break;
      case 'created':
        this.messageFullData[index].messageList = this.backupcase360message.filter(va => va.status == '10')
        this.messageFullData[index].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[this.selectedIndexTab].totalmessagecount = this.messageFullData[index].messageList.length;
        break;
      case 'failed':
        this.messageFullData[index].messageList = this.backupcase360message.filter(va => va.status == '12')
        this.messageFullData[index].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[this.selectedIndexTab].totalmessagecount = this.messageFullData[index].messageList.length;

        break;
      case 'autoHealing':
        this.messageFullData[index].messageList = this.backupcase360message.filter(va => va.status == '15')
        this.messageFullData[index].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[this.selectedIndexTab].totalmessagecount = this.messageFullData[index].messageList.length;

        break;
      case 'resolved':
        this.messageFullData[index].messageList = this.backupcase360message.filter(va => va.status == '13')
        this.messageFullData[index].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[this.selectedIndexTab].totalmessagecount = this.messageFullData[index].messageList.length;

        break;
      case "ignored":
        this.messageFullData[index].messageList = this.backupcase360message.filter(va => va.status == '14')
        this.messageFullData[index].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[this.selectedIndexTab].totalmessagecount = this.messageFullData[index].messageList.length;

        break;
      default:
        this.messageFullData[index].messageList = this.backupcase360message
        this.messageFullData[index].messagestageList = this.case360.hubmessagestage;
        this.messageFullData[this.selectedIndexTab].totalmessagecount = this.messageFullData[index].messageList.length;

        break;
    }
  }

  isAllSelectedSuccess() {
    const numSelected = this.successSelection.selected.length;
    const numRows = this.SuccessMessageList.length;
    return numSelected === numRows;
  }

  successSelection = new SelectionModel<any>(true, []);
  successreprocess() {
    if (this.isEngine !== 'HUB') {
      this.mirthReprocessSelected(this.successSelection.selected, 'Success')
    }
    else {
      this.reprocessSelected(this.successSelection.selected, 'Success')
    }
  }
  masterToggleSuccess() {
    this.isAllSelectedSuccess() ?
      this.successSelection.clear() :
      this.SuccessMessageList.forEach(row => this.successSelection.select(row));
  }
  //----------------------------------------------------------------------------

  // FailureMessageList
  FilterFailureMessage() {
    let failuremessage = this.messageData.MessageDetails.filter(va => va.status === '12' || va.status.toLowerCase() === 'failed' || va.status.toLowerCase() === 'failure' ||
      va.status.toLowerCase() === 'error' || va.status.toLowerCase() === 'order failed' || va.status.toLowerCase() === 'failed to parse' ||
      va.status.toLowerCase() === 'message api failure' || va.status.toLowerCase() === 'requisition failed' ||
      va.status.toLowerCase() === 'adt failed')
    this.getmirthListData(failuremessage)
  }

  isAllSelectedFailure() {
    const numSelected = this.failureSelection.selected.length;
    const numRows = this.failureMessageList.length;
    return numSelected === numRows;
  }

  failureSelection = new SelectionModel<any>(true, []);
  failurereprocess() {
    if (this.isEngine !== 'HUB') {
      this.mirthReprocessSelected(this.failureSelection.selected, 'Failure')
    }
    else {
      this.reprocessSelected(this.failureSelection.selected, 'Failure')
    }
  }

  masterTogglefailure() {
    this.isAllSelectedFailure() ?
      this.failureSelection.clear() :
      this.failureMessageList.forEach(row => this.failureSelection.select(row));
  }
  //----------------------------------------------------------------------------

  // AutoHealingMessageList
  FilterAutohealingMessage() {
    this.AutohealingMessageList = this.messageList.filter(va => va.status === '15')
  }

  isAllSelectedAutohealing() {
    const numSelected = this.AutohealingSelection.selected.length;
    const numRows = this.AutohealingMessageList.length;
    return numSelected === numRows;
  }

  AutohealingSelection = new SelectionModel<any>(true, []);
  Autohealingreprocess() {
    if (this.isEngine !== 'HUB') {
      this.mirthReprocessSelected(this.AutohealingSelection.selected, 'Auto-Healing')
    }
    else {
      this.reprocessSelected(this.AutohealingSelection.selected, 'Auto-Healing')
    }
  }

  masterToggleAutohealing() {
    this.isAllSelectedAutohealing() ?
      this.AutohealingSelection.clear() :
      this.AutohealingMessageList.forEach(row => this.AutohealingSelection.select(row));
  }
  //----------------------------------------------------------------------------

  // ProcessingMessageList
  FilterProcessingMessage() {
    let processlist = this.messageData.MessageDetails.filter(va => va.status.toLowerCase() === 'message posted' ||
      va.status.toLowerCase() === 'processing' || va.status.toLowerCase() === 'waiting')
    this.getmirthListData(processlist)
  }

  isAllSelectedProcessing() {
    const numSelected = this.ProcessingSelection.selected.length;
    const numRows = this.ProcessingMessageList.length;
    return numSelected === numRows;
  }

  ProcessingSelection = new SelectionModel<any>(true, []);
  Processingreprocess() {
    if (this.isEngine !== 'HUB') {
      this.mirthReprocessSelected(this.ProcessingSelection.selected, 'Processing')
    }
    else {
      this.reprocessSelected(this.ProcessingSelection.selected, 'Processing')
    }
  }

  masterToggleProcessing() {
    this.isAllSelectedProcessing() ?
      this.ProcessingSelection.clear() :
      this.ProcessingMessageList.forEach(row => this.ProcessingSelection.select(row));
  }
  //----------------------------------------------------------------------------

  // IgnoreMessageList
  FilterIgnoreMessage() {
    let ignorelist = this.messageData.MessageDetails.filter(va => va.status.toLowerCase() === 'ignore' ||
      va.status.toLowerCase() === 'ignored' || va.status.toLowerCase() === 'skipped' ||
      va.status.toLowerCase() === 'nobarcodescanned' || va.status.toLowerCase() === 'requisition ignored' ||
      va.status.toLowerCase() === 'adt ignored' || va.status.toLowerCase() === 'invalid')
    this.getmirthListData(ignorelist)
  }

  isAllSelectedIgnore() {
    const numSelected = this.IgnoreSelection.selected.length;
    const numRows = this.IgnoreMessageList.length;
    return numSelected === numRows;
  }

  IgnoreSelection = new SelectionModel<any>(true, []);
  Ignorereprocess() {
    if (this.isEngine !== 'HUB') {
      this.mirthReprocessSelected(this.IgnoreSelection.selected, 'Ignored')
    }
    else {
      this.reprocessSelected(this.IgnoreSelection.selected, 'Ignored')
    }
  }

  masterToggleIgnore() {
    this.isAllSelectedIgnore() ?
      this.IgnoreSelection.clear() :
      this.IgnoreMessageList.forEach(row => this.IgnoreSelection.select(row));
  }
  //----------------------------------------------------------------------------

  // OtherMessageList
  FilterOtherMessage() {
    let otherlist = this.messageData.MessageDetails.filter(va => va.status.toLowerCase() === 'test' ||
      va.status.toLowerCase() === 'bulkfile')
    this.getmirthListData(otherlist)
  }

  isAllSelectedOther() {
    const numSelected = this.OtherSelection.selected.length;
    const numRows = this.OtherMessageList.length;
    return numSelected === numRows;
  }

  OtherSelection = new SelectionModel<any>(true, []);
  Otherreprocess() {
    if (this.isEngine !== 'HUB') {
      this.mirthReprocessSelected(this.OtherSelection.selected, 'Others')
    }
    else {
      this.reprocessSelected(this.OtherSelection.selected, 'Others')
    }
  }

  masterToggleOther() {
    this.isAllSelectedOther() ?
      this.OtherSelection.clear() :
      this.OtherMessageList.forEach(row => this.OtherSelection.select(row));
  }
  //----------------------------------------------------------------------------

  fileids: string = ''
  filename: string = ''

  getmessage_iot(value, modalmessageiot) {
    ;
    this.messageuniqueid = value.id;
    this.ngxUiLoaderService.start();
    this.msgObjforiot = value;
    this.service.message_IOTData(this.msgObjforiot["id"], this.arhivecheckbackup).subscribe(data => {
      if (data) {
        this.message_iot_data = data

        var itemsa = this.message_iot_data["TransformedData"]
        this.message_iot_data['title'] = this.msgObjforiot["messageid"]
        if (this.message_iot_data["InputData"]) {
          if (this.service.IsJsonString(this.message_iot_data["InputData"])) {
            this.message_iot_data["InputData"] = JSON.stringify(JSON.parse(this.message_iot_data["InputData"]), null, 2);
          }
          else if (this.IsValidXML(this.message_iot_data["InputData"])) {
            this.message_iot_data["InputData"] = beautify(this.message_iot_data["InputData"]);
          }
        }
        if (this.message_iot_data["TransformedData"]) {
          if (this.service.IsJsonString(this.message_iot_data["TransformedData"])) {
            let transformparse = JSON.parse(this.message_iot_data["TransformedData"]);
            this.fileidarray = transformparse;
            this.message_iot_data["TransformedData"] = JSON.stringify(transformparse, null, 2);
          }
          else if (this.IsValidXML(this.message_iot_data["TransformedData"])) {
            this.message_iot_data["TransformedData"] = beautify(this.message_iot_data["TransformedData"]);
          }
        }
        if (this.service.IsJsonString(this.message_iot_data["TransformedData"]) && this.message_iot_data["TransformedData"] !== null) {
          this.downloadbuttonenable = true;
        }
        else {
          this.downloadbuttonenable = false;
        }
        this.ngxUiLoaderService.stop()
        this.modalService.open(modalmessageiot, { windowClass: "messageiot", backdrop: 'static', keyboard: false }).result.then((result) => { });
      }
    }, error => {
      this.ngxUiLoaderService.stop()
      this.toastr.error(error.statusText, "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    })
  }
  selectedRoute(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
    this.SearchMessages();
  }

  /* download changes start*/
  downloadfilebyFileid() {
    this.ngxUiLoaderService.start();
    if (this.fileidarray.length > 0) {
      for (var i = 0; i < this.fileidarray.length; i++) {
        this.downloadcontents(this.fileidarray[i].fileid, this.fileidarray[i].filename)
      }
    }
    else {
      //this.downloadcontents(fileId);
    }
    this.ngxUiLoaderService.stop();
  }

  downloadcontents(fileids, filename) {
    let jsondata = {

      fileid: fileids,
      contextId: this.messageuniqueid,
      createdBy: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    };
    this.service.GetFileContentByFileID(jsondata).subscribe((response: any) => {
      if (response.filecontent != null) {
        filename = filename == '' ? response.filename : filename;
        if (filename == '') {
          filename = response.fileid + response.fileformat;
        }

        this.downloadFiles(response, filename);
        this.ngxUiLoaderService.stop();
      }
      else {
        this.ngxUiLoaderService.stop();
      }

    });
  }
  downloadFiles(data: any, filename: any) {
    if (!filename.includes("."))
    {
      filename = filename + "." + data.fileformat;   
    }
    var decodedContent = null;
    if (data.fileformat == "pdf") {
      decodedContent = 'data:application/pdf;base64,' + data.filecontent;
      const link = document.createElement('a');
      link.href = decodedContent;
      link.download = filename;
      link.click();
    }
    else if (data.fileformat !== null) {
      decodedContent = data.filecontent;

      let contype = 'application/octet-stream';
      const file = new window.Blob([decodedContent], { type: contype });
      const downloadAncher = document.createElement("a");
      const fileURL = URL.createObjectURL(file);
      downloadAncher.href = fileURL;
      downloadAncher.download = filename;
      downloadAncher.click();
    }


  }
  /* download changes end*/


  getRouteNames(event: any) {
    var val = event;
    if (val) {
      this.isLoadingroutes = true;
      var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=0";
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.RouteNameList = data as any[];
          this.RouteNameList.map(obj => obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}`);
          this.isLoadingroutes = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingroutes = false;
          this.notfounddata = true;
        }
      });
    }
  }
  searchRouteNameCleared(event) {
    this.isLoadingroutes = false;
    this.RouteNameList = [];
    this.selectedrouteId = '';
    this.selectedrouteDisplayName = '';
    this.notfounddata = false;
  }

  keywordaccount = "LocationName";

  async getSourceAccountName(event: any) {
    var valuesa = event;
    this.selectedsourceaccountid = '';
    if (valuesa != '') {

      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;

      this.isLoadingsource = true;
      let response = await this.service.getapi(createSearch);

      if (response['status'] && response['statusText']) {
        this.isLoadingsource = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.notfounddata = true;
      } else {
        this.SourceAccountList = response as any[];
        this.SourceAccountList.map(obj => obj.LocationName = `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
        this.selectedsourceaccountid = '';
        this.isLoadingsource = false;
        if (response.length == 0) {
          this.notfounddata = true;
        }
        else {
          this.notfounddata = false;
        }
      }
    }
  }
  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]}`
    return value
  }
  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }

  selectedsourceagentEvent(item) //source agent selected Event
  {
    this.selectedsourceaccountid = item.LocationGUID;
    this.selectedsourceaccountName = item.LocationName;
  }

  searchSourceNameCleared(event) {
    this.isLoadingsource = false;
    this.SourceAccountList = [];
    this.selectedsourceaccountid = '';
    this.selectedsourceaccountName = '';
    this.notfounddata = false;
  }

  searchTargetNameCleared(event) {
    this.isLoadingTarget = false;
    this.TargetAccountList = [];
    this.selectedtargetaccountid = '';
    this.selectedtargetaccountName = '';
    this.notfounddata = false;
  }
  searchProcesorNameCleared(event) {
    this.isLoadingProcesor = false;
    this.ProcessorList = this.ProcessorAllList;
    this.selectedprocessagentid = '';
    this.selectedprocessdisplayname = '';
    this.notfounddata = false;

  }

  async getTargetAccountName(event: any) {
    var valueta = event;
    this.selectedtargetaccountid = '';
    if (valueta != '') {

      this.isLoadingTarget = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueta;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingTarget = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.notfounddata = true;
      } else {
        this.TargetAccountList = response as any[];
        this.TargetAccountList.map(obj => obj.LocationName = `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
        this.selectedtargetaccountid = '';
        this.isLoadingTarget = false;
        if (response.length == 0) {
          this.notfounddata = true;
        }
        else {
          this.notfounddata = false;
        }
      }
    }
  }

  ProcessorAllList = <any>[];
  async getProcessorName(event: any) {

    this.selectedprocessagentid = '';
    this.isLoadingProcesor = true;
    var apiname = '';
    if (event !== 'pipeline') {
      apiname = "api/Agents/GetByDisplayName?name=" + event + '&agentid=0&type=Processor&filterflag=true';
      this.ProcessorList = this.ProcessorAllList.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
      if (this.ProcessorList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingProcesor = false;
    }
    else {
      apiname = "api/Agents/GetByDisplayName?name=" + '&agentid=0&type=Processor&filterflag=false';

      await this.service.getapiurl(apiname).subscribe({
        next: async data => {
          this.ProcessorList = data as any[];
          this.ProcessorList.map(obj => { obj.name = obj.displayname, obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}` });
          if (this.ProcessorList.length > 0) {
            this.ProcessorList = this.service.transformAlphabetically(this.ProcessorList, 'displayname');
          }
          this.ProcessorAllList = this.ProcessorList;
          // this.selectedprocessagentid = '';
          this.isLoadingProcesor = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingProcesor = false;
          this.notfounddata = true;
        }
      });
    }
  }

  selectedtargetagentEvent(item) //target agent selected Event
  {
    this.selectedtargetaccountid = item.LocationGUID;
    this.selectedtargetaccountName = item.locationName;

  }

  selectedProcessEvent(item) //Processor selected Event
  {
    this.selectedprocessagentid = item.id;
    this.selectedprocessdisplayname = item.displayname;

  }

  isUUID(uuid) {
    let s;

    s = uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }

  fromdate: any;
  todate: any;
  totalmessagecount: string = '0';
  displayedColumns: string[] = ['messagetype', 'messageid', 'transactionid', 'createddate', 'options'];
  dataSource = this.messageList;
  transactionidvalue: string = '';

  // check360message(case360){
  //   if( ){
  //   return  'col-md-3 message_btns pt-2 text-center'
  //   }else{
  //     return  'col-md-4 message_btns pt-2 text-center'
  //   }
  // }
  onTabChanged(value) {
    this.isSearched = true;
    if (value.index !== -1) {
      this.selectedIndexTab = value.index;
      if (this.case360 !== undefined && this.case360.hubmessagedata !== undefined && this.case360.hubmessagedata.length > 0) {
        this.filterdata360(this.messageStatusList[value.index].displayname, value.index)
      } else if (this.isEngine == "HUB" && this.case360 == undefined || this.case360.routeid !== undefined) {
        this.selectedmessagestatustype = this.messageStatusList[value.index].displayname;
        this.SearchMessages()
      } else {
        this.messageList = this.messageData.MessageDetails
        this.selectedmessagestatustype = this.mirthStatusList[value.index].displayname;
        this.isEngine = this.messageData['engine'];
        switch (this.selectedmessagestatustype) {
          case "All":
            this.getmirthListData(this.messageData.MessageDetails);
            break;
          case "New":
            this.FilterCreatedMessage()
            break;
          case "Success":
            this.FilterSuccessMessage()
            break;
          case "Failure":
            this.FilterFailureMessage()
            break;
          case "Processing":
            this.FilterProcessingMessage()
            break;
          case "Ignore":
            this.FilterIgnoreMessage()
            break;
          case "Others":
            this.FilterOtherMessage()
            break;
          default:
            this.getmirthListData(this.messageData.MessageDetails);
            break;
        }
      }
    }


  }

  getmirthListData(value) {
    this.messageFullData[this.selectedIndexTab].messageList = value;
    this.messageFullData[this.selectedIndexTab].totalmessagecount = value.length;
  }
  SearchMessages() {

    this.selection.clear();
    this.messageList = [];
    this.messagestageList = [];
    this.tablediv = false;
    // this.messageFullData = []
    if (this.selectedrouteId == '' && this.reactiveFormroute.get('routename').value != '' && this.reactiveFormroute.get('routename').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormroute.get('routename').value);
      if (returnvalue == true) {
        this.selectedrouteId = this.reactiveFormroute.get('routename').value;
      }
      else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return;
      }
    }
    if (this.selectedsourceaccountid == '' && this.reactiveFormsource.get('name').value != '' && this.reactiveFormsource.get('name').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormsource.get('name').value);
      if (returnvalue == true) {
        this.selectedsourceaccountid = this.reactiveFormsource.get('name').value;
      }
      else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return;
      }
    }
    if (this.selectedtargetaccountid == '' && this.reactiveFormtarget.get('namet').value != '' && this.reactiveFormtarget.get('namet').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormtarget.get('namet').value);
      if (returnvalue == true) {
        this.selectedtargetaccountid = this.reactiveFormtarget.get('namet').value;
      }
      else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return;
      }
    }
    if (this.selectedprocessagentid == '' && this.reactiveFormprocess.get('namep').value != '' && this.reactiveFormprocess.get('namep').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormprocess.get('namep').value);
      if (returnvalue == true) {
        this.selectedprocessagentid = this.reactiveFormprocess.get('namep').value;
      }
      else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return;
      }
    }
    if (this.fromdate == '' && this.todate == '') {
      this.fromdate = undefined;
      this.todate = undefined;
    }

    if ((this.fromdate && !this.todate) || (!this.fromdate && this.todate) || (this.fromdate == '' && this.todate == '')) {
      this.toastr.error("Select From date & To date ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }
    if (this.fromdate > this.todate) {
      this.toastr.error("From date should be  equal  or  less than To date ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }
    let entitykeyvalue=''
    if(this.msgfieldlist.length>0)
    {
     entitykeyvalue= this.msgfieldlist.reduce((acc,current)=>{
 acc= acc+current.msgvalue+','
 return acc
      },'')
      entitykeyvalue=entitykeyvalue.slice(0, -1);
    }
    else if(this.msgfieldctrl.value!='' && this.msgfieldctrl.value!=null){
      this.toastr.warning("Invalid Value in Message Field", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }
    var searchdata = {
      "routedefinitionid": this.selectedrouteId,
      "sourceentityid": this.selectedsourceaccountid,
      "destinationentityid": this.selectedtargetaccountid,
      "messagetype": this.selectedmessagetype,
      "processoragentid": this.selectedprocessagentid,
      "messageid": this.reactiveFormmessageid.get('namemessageid').value,
      "transactionid": this.transactionidvalue,
      "entitykeyvalue": entitykeyvalue,
      "filtermessagestage": this.filtermsgtaggel == true ? "ALL" : "HOLD",
      "status": this.selectedmessagestatustype == '' ? "ALL" : this.selectedmessagestatustype,
      "archivedbcheck": this.archivedbcheck,
      "FromDate": this.fromdate == undefined ? '' : this.fromdate + ' 00:00:00',
      "ToDate": this.todate == undefined ? '' : this.todate + ' 23:59:59',
      "entityid": this.selectedEntityguid,
      "parentmessageid": this.reactiveFormparentmessageid.get('parentmsgid').value
    }
    this.ngxUiLoaderService.start();
    var apiname = 'api/HubMessage/GetSearchDetails';
    this.service.searchpostapi(apiname, searchdata).then(response => {

      if (response['status'] && response['statusText']) {
        this.ngxUiLoaderService.stop();
        this.arhivecheckbackup = false;
        this.tablediv = false;
        this.toastr.error("Something Went Wrong!!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {



        this.ngxUiLoaderService.stop();
        this.isSearched = true;
        this.isEngine = 'HUB'
        this.arhivecheckbackup = searchdata.archivedbcheck;
        this.messageFullData = this.messageStatusList;
        this.selectedIndexTab = this.messageStatusList.findIndex(va => va.displayname === this.selectedmessagestatustype)
        this.messageFullData.forEach((ele) => {
          ele.selector = new SelectionModel<any>(true, []);
        });
        if (this.selectedIndexTab !== -1) {
          this.messageFullData[this.selectedIndexTab].messageList = response.hubmessagedata as any[];
          this.messageFullData[this.selectedIndexTab].messagestageList = response.hubmessagestage as any[];
          this.messageFullData[this.selectedIndexTab].totalmessagecount = response.messagecount;
        }


        this.messageList = response.hubmessagedata as any[];
        this.messagestageList = response.hubmessagestage as any[];
        this.totalmessagecount = response.messagecount;
        let messagesublist = [];
        messagesublist = this.messagestageList;
        this.messagestageList = [];
        if (this.messageList.length != 0) {
          this.tablediv = true;
          this.messageList.forEach(data => {
            messagesublist.forEach(ele => {
              if (ele.stagedisplay != '' && ele.messagereferenceid === data.id && ele.stagedisplay != 'A') {
                this.messagestageList.push(ele);
              }
            })
          });
        }
        else {
          this.tablediv = false;
          this.toastr.warning("No results found!!", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      }
    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    })
  }



  InputData: Object;
  OutputData: Object;

  OutputJson: string;
  OutputJsonResponsebody: string;
  fileidValue: string;
  filecontents: any;
  InputDataJson: any[];
  transId: string;
  hash: string;
  senderid: string;
  filecheck: boolean = false;
  displaystage: string = '';
  fileidslist: any = [];
  fileidslisttodownload: any = [];
  stageid: string = '';
  inputmediatypedisplay: boolean = true;
  outputmediatypedisplay: boolean = true;
  messageuniqueid: number = 0;
  openstagereprocess(content, dataitem, messageidcurrent, sync) {

    if ((dataitem.stagedisplay == 'T' || dataitem.stagedisplay == 'D' || dataitem.stagedisplay == 'V') && (sync == "false" || sync == "0")) {
      this.reprocessbtn = true;
    }
    else {
      this.reprocessbtn = false;
    }


    this.stageid = dataitem.id;
    this.displaystage = dataitem.stagename;
    this.displaymessageid = messageidcurrent;

    this.ngxUiLoaderService.start();

    var apiname = "api/HubMessage/GetMessageLogDataById?messagelogid=" + dataitem.id + "&archiveflag=" + this.archivedbcheck;
    this.service.getapiurl(apiname).subscribe({
      next: async data => {
        this.fileidarray = [];
        this.isContainsMultiplefileid = false;
        this.messageuniqueid = data['id'];
        try {
          this.InputData = JSON.parse(data['stageinput']);
        } catch (e) {
          this.InputData = null;
        }

        try {
          this.OutputData = JSON.parse(data['outputdata']);
        } catch (e) {
          let xmlDocument;
          try {
            xmlDocument = (new DOMParser()).parseFromString(data['outputdata'], 'text/xml');
            this.OutputData = beautify(data['outputdata']);
          }
          catch {
            this.OutputData = data['outputdata'];
          }
        }

        this.filecontents = null;
        if (this.OutputData !== null) {
          if (dataitem.stagedisplay == 'ACK') {
            this.fileOutputcheck = false;
          }

          if (Array.isArray(this.OutputData)) {
            if (this.OutputData.length > 0) {
              if (dataitem.stagedisplay == 'ACK') {
                this.fileOutputcheck = false;
              }
              this.isContainsMultiplefileid = true;
              this.fileidarray = this.OutputData;
            }
          }
          else if (this.OutputData['fileid'] != null) {
            this.fileOutputcheck = true;
            this.fileidOutputValue = this.OutputData['fileid'];
          }
          else {
            this.fileOutputcheck = false;
          }
        }

        if (this.InputData != null) {
          this.transId = this.InputData['transactionid'];
          this.hash = this.InputData['hash'];
          this.senderid = this.InputData['senderagentid'];

          this.fileidInputValue = this.InputData["fileid"];
          if (this.fileidInputValue != null) {
            this.fileInputcheck = true;
          }
          else {
            this.fileInputcheck = false;
          }
        }
        else {
          this.fileInputcheck = false;
        }

        if (this.InputData == null && this.OutputData == null) {
          this.ngxUiLoaderService.stop();
          this.toastr.warning("There is no input and response for " + dataitem.stagename + " stage ", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          if (dataitem.stagedisplay == 'H' && Number(data["releaseflag"]) < 1) {
            this.releasebtn = true;
            this.dataview = true;
          }
          else if (dataitem.stagedisplay == 'H') {
            this.dataview = true;
            this.releasebtn = false;
          }
          else {
            this.releasebtn = false;
            this.dataview = false;
          }
          if ((this.fileInputcheck && this.fileOutputcheck && dataitem.stagedisplay == 'H') || (this.fileidarray.length > 1)) {
            this.fileidslist = [];
            // this.fileidInputValue="MjAyMS8xMC82L3NhbXBsZXBkZjEucGRmXzFmZjEyYjU0LTdlNzAtNGI4MC04ODgzLTVjYWZlMDVhOTdmMA==";
            // this.fileidOutputValue="MjAyMS8xMC82L3NhbXBsZXBkZjMucGRmXzQ0ODZmZmY4LTMyYTYtNGI0Ni1hZTJlLTgwMzNjMmE4N2Q1NQ=="
            if (this.fileidarray.length > 1) {
              this.fileidarray.forEach(element => {
                if (element.fileid) {
                  this.fileidslist.push(element.fileid);
                  this.fileOutputcheck = true;
                }
              });
              if (this.fileInputcheck) {
                this.fileidslist.push(this.fileidInputValue);
              }
            }
            else {
              this.fileidslist.push(this.fileidInputValue);
              this.fileidslist.push(this.fileidOutputValue);
            }

            if (this.fileidslist.length > 0)
            {
               let createdby = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
            let response = await this.service.getapi("api/Routes/GetFileDataMulti?fileID=" + this.fileidslist + "&contextId=" + this.messageuniqueid + "&createdBy=" + createdby);
            if (response['status'] && response['statusText']) {
              this.ngxUiLoaderService.stop();
              this.toastr.error('Failure to download file', "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
            }
            else {
              this.ngxUiLoaderService.stop();
              var res = response as any[];
              res.forEach(element => {
                if (element["fileformat"] == "pdf") {
                  this.ngxUiLoaderService.stop();
                  if (element["fileid"] == this.fileidInputValue) {
                    this.inputfilecontent = this._base64ToArrayBuffer(element["filecontent"]);
                    this.inputmediatypedisplay = false;
                  }
                  else {
                    this.outputfilecontent = this._base64ToArrayBuffer(element["filecontent"]);
                    this.outputmediatypedisplay = false;
                  }
                }
                else {
                  var myblob = new Blob([element["filecontent"]], { type: 'text/plain' });
                  const outurl = URL.createObjectURL(myblob);
                  fetch(outurl)
                    .then(res => res.text())
                    .then(datatext => {
                      this.ngxUiLoaderService.stop();
                      if (element["fileid"] == this.fileidInputValue) {
                        this.inputfilecontent = datatext;
                        this.inputmediatypedisplay = true;
                      }
                      else {
                        this.outputfilecontent = datatext;
                        this.outputmediatypedisplay = true;
                      }
                    })
                }
              });
              this.modalService.open(content, { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false }).result.then((result) => { });
            }
            }
            else {
            
                this.ngxUiLoaderService.stop();
                this.modalService.open(content, { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false }).result.then((result) => { });  
            }

           
          }
          else {
            this.ngxUiLoaderService.stop();
            this.modalService.open(content, { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false }).result.then((result) => { });
          }
        }

      },

      error: error => {
        this.ngxUiLoaderService.stop();

      }
    });
  }


  closemessageiotpopup(c) {
    this.editorOptions1.readOnly = true;
    this.messageiotreprocess = false;
    this.backupinputdata = "";
    c('close modal');
  }
  closestagepopup(c) {
    c('close modal');
  }


  async reprocesstage() {
    this.ngxUiLoaderService.start();
    var postinput = {
      "objStageContent": this.InputData,
      "modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
      "stageid": this.stageid
    }

    let response = await this.service.reprocessStageapi("api/HubMessage/StageReprocess", postinput)
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Stage reprocess failed", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      this.SearchMessages();
      this.ngxUiLoaderService.stop();
      this.toastr.success("Stage reprocess success", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.autoclosetemplate();
    }
  }


  autoclosetemplate() {
    setTimeout(() => {
      this.modalService.dismissAll();
    }, 3000);
  }

  copyToClip(event: any, message: string) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
  }

  GetMessageTypes() {
    var apiname = "api/Lookup/GetMessageType?lookuptype=messagetype";
    this.service.getapiurl(apiname).subscribe({
      next: data => {
        this.messageTypeList = data as any[];
      },
    });
  }

  messageStatusList: any = [];
  GetMessageStatus() {
    var apiname = "api/Lookup/GetMessageType?lookuptype=messagestatus";
    this.service.getapiurl(apiname).subscribe({
      next: data => {
        this.messageStatusList = data as any[];
      },
    });
  }



  reset() {
    this.msgfieldInput.nativeElement.value=null
    this.msgfieldctrl.setValue('');
    this.msgfieldlist=[];
    this.reactiveFormroute.reset();
    this.reactiveFormsource.reset();
    this.reactiveFormroute.reset();
    this.reactiveFormtarget.reset();
    this.reactiveFormprocess.reset();
    this.selectedrouteId = "";
    this.selectedrouteDisplayName = '';
    this.selectedmessagetype = '';
    this.reactiveFormmsgtype.reset();
    this.selectedsourceaccountid = '';
    this.stageid = '';
    this.selectedsourceaccountName = '';
    this.selectedtargetaccountid = '';
    this.selectedtargetaccountName = '';
    this.selectedprocessagentid = '';
    this.selectedprocessdisplayname = '';
    this.reactiveFormmessageid.reset();
    this.selection.clear();
    this.messageList = [];
    this.messagestageList = [];
    this.tablediv = false;
    this.reactiveForentityfield.reset();
    this.reactiveFortransactionid.reset();
    this.filtermsgtaggel = true;
    this.selectedmessagestatustype = 'ALL';
    this.archivedbcheck = false;
    this.ProcessorList = this.ProcessorAllList;
    this.reactiveFormprocess.setValue({ "namep": '' });
    this.messageTypeList = this.messageTypeAllList;
    this.reactiveFormmsgtype.setValue({ "msgname": '' });
    this.reactiveFormmsgstatus.reset();
    this.messageStatusList = this.messageStatusAllList;
    this.reactiveFormmsgstatus.setValue({ "msgstatusname": '' });
    this.fromdate = null;
    this.todate = null;
    this.reactiveFormprocess.patchValue(this.ProcessorList);
    this.reactiveFormmsgtype.patchValue(this.messageTypeList);
    this.reactiveFormmsgstatus.patchValue(this.messageStatusList);
    this.transactionidvalue = '';
    this.isFormView = false;
    this.reactiveFormentity.reset();
    this.isSearched = false;
    this.showSourceTarget = true;
    this.service.messagesData = '';
    this.isEngine = "HUB";
    this.EntityNameList = [];
    this.selectedEntityguid = '';
    this.selectedEntityName = '';
    this.reactiveFormparentmessageid.reset();
  }

  sourcearray: any[]; destinationarray: any[]; processorarray: any[];

  getRouteDetails() {

    if (this.selectedrouteId != '') {
      var url = "api/Routes/GetRouteList?id=" + this.selectedrouteId;
      this.ngxUiLoaderService.start();
      this.service.getapiurl(url).subscribe({
        next: data => {
          this.ngxUiLoaderService.stop();
          if (this.selectedrouteId != "") {
            this.selectedrouteDisplayName = data['displayname'];
            this.reactiveFormroute.patchValue({
              routename: this.selectedrouteDisplayName
            });
          }


          if (data['SENAME'] != '') {
            this.selectedsourceaccountid = data['SEGUID'];
            this.selectedsourceaccountName = data['SENAME'];

            this.reactiveFormsource.patchValue({
              name: this.selectedsourceaccountName
            });
          }

          if (data['DENAME'] != '') {
            this.selectedtargetaccountid = data['DEGUID'];
            this.selectedtargetaccountName = data['DENAME'];
            this.reactiveFormtarget.patchValue({
              namet: this.selectedtargetaccountName
            });
          }

          if (data['PANAME'] != '') {

            this.selectedprocessagentid = data['PAID'];
            this.selectedprocessdisplayname = data['PANAME'];
            this.reactiveFormprocess.patchValue({
              namep: this.selectedprocessdisplayname + '  ' + "(" + this.selectedprocessagentid + ")"
            });

          }
          this.selectedmessagetype = data['messagetype'];

        },
        error: error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error('Unable to fetch Pipeline details with ID:' + this.selectedrouteId, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      });
    }

  }
  fileOutputcheck: boolean = false;

  downloadfile(fileId, iscontainsmultiplefileid) {

    this.ngxUiLoaderService.start();
    if (iscontainsmultiplefileid && this.fileidarray.length > 0) {
      for (var i = 0; i < this.fileidarray.length; i++) {
        this.downloadcontents(this.fileidarray[i].fileid, this.fileidarray[i].filename)
      }
    }
    else {
      this.downloadcontents(fileId, "");
    }
    this.ngxUiLoaderService.stop();
  }
  downloadcontent(fileid, filenameatstage) {
    var apiname = "api/Routes/GetFileData?fileID=" + fileid + "&contextId=" + this.messageuniqueid + "&createdby=" + JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];

    (this.service.getapiurlcontent(apiname)).subscribe({
      next: data123 => {
        ;
        this.ngxUiLoaderService.stop();
        if (data123["statusmessage"] != "") {
          var fileDetails = JSON.parse(data123["statusmessage"]);
          let filename = "";

          if (filenameatstage !== "") {
            filename = filenameatstage;
          }
          else if (fileDetails.hasOwnProperty("filename")) {
            filename = fileDetails.filename;
          }

          if (filename == "") {
            filename = atob(fileid);
          }

          if (typeof (fileDetails) == "object" && fileDetails.hasOwnProperty("contenttype")) {
            let contentType = fileDetails.contenttype;

            switch (contentType) {
              case "application/xml": filename += ".xml";
                break;
              case "application/json": filename += ".js";
                break;
              case "application/pdf;base64": filename;
                break;
              default: filename += ".txt";
                break;
            }
          }
          if (filename != "" && filename.indexOf('.') == -1) {
            filename += '.txt';
          }
          var xmlDocument;
          try {
            xmlDocument = (new DOMParser()).parseFromString(data123.responsebody, 'text/xml');
          }
          catch {
            xmlDocument = "";
          }
          if (xmlDocument) {
            data123.responsebody = beautify(data123.responsebody);
          }
          const file = new window.Blob([data123.responsebody], { type: 'application/octet-stream' });
          const downloadAncher = document.createElement("a");
          const fileURL = URL.createObjectURL(file);
          downloadAncher.href = fileURL;
          downloadAncher.download = filename;
          downloadAncher.click();

        }
      },
      error: error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Failure to download file', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    });
  }

  MessageTraceData = <any>[];
  displaymessageid: string = '';
  GetTraceData(mymodalstagelogs, transactionid, traceid, messageid) {
    this.displaymessageid = messageid;
    this.ngxUiLoaderService.start();
    var url = "api/HubMessage/GetTraceData?txnid=" + transactionid + "&traceid=" + traceid;
    this.service.getapiurl(url).subscribe({
      next: data => {
        if (data.length > 0) {
          this.MessageTraceData = data as any;
          if (mymodalstagelogs != '') {
            this.modalService.open(mymodalstagelogs, { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false }).result.then((result) => { });
          }
          else {
            this.downloadFile(this.MessageTraceData, this.MessageTraceData[0]["traceid"]);
          }
        } else {
          this.toastr.warning('There is no logs in this Message ID :' + " " + this.displaymessageid, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        this.ngxUiLoaderService.stop();
      },
      error: error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Failure to download logs', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    });



  }



  ConvertToCSV(objArray, headerList) {

    let items = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let separator = ',';
    const columns = Object.keys(items[0]).join(separator);
    const body = items.map(item =>
      Object.values(item).join(separator)
    ).join('\n');

    return columns + '\n' + body;
  }
  copyToClips(event: any, message: string) {

    copyToClipboard(event);
    this.toastr.success(message + " " + "Copied", "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    })

  }

  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, ['agentid', 'messageid', 'method', 'routedefinitionid', 'messagetype', 'senderagentid', 'sourceentityid', 'destinationentityid',
      'destinationagentid', 'response', 'createddate', 'fileid', 'invokedurl', 'stage', 'hash', 'response', 'processingparam', 'status', 'processingtime']);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  download() {

    this.ngxUiLoaderService.start();
    this.downloadFile(this.MessageTraceData, this.MessageTraceData[0]["traceid"]);
    this.ngxUiLoaderService.stop();
  }

  reprocessbtn: boolean;
  releasebtn: boolean = false;
  dataview: boolean = false;

  selection = new SelectionModel<any>(true, []);

  checkeddata: any = [];
  messagereprocessdata: any = [];

  transactionid: any = [];
  senderagentid: any = [];

  messagesResolve(value) {
    if (value.selected.length > 0) {
      this.checkeddata = value.selected;
      var dataResolve = {
        "id": this.checkeddata.map(function (a) { return a.id; }).join(','),
        "modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      }
      ////console.log(dataResolve);
      this.ngxUiLoaderService.start();
      this.service.hubmessageResolve(dataResolve.id, dataResolve.modifiedby).subscribe(response => {

        if (response['status'] && response['statusText']) {
          this.ngxUiLoaderService.stop();
          this.toastr.error('Resolve failed', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          this.ngxUiLoaderService.stop();
          if (response['status'] == "failure") {
            this.toastr.error(response['statusmessage'], "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
          else {
            this.toastr.success('Resolved successfully', "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            if (this.case360 !== undefined && this.case360.hubmessagedata !== undefined && this.case360.hubmessagedata.length > 0) {
              this.case360search.emit('')
            } else {
              this.SearchMessages();
            }
            // this.SearchMessages();
            // this.autoclosetemplate();
            //this.resetReprocess(indenty)
          }
        }
      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Something Went Wrong!', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      });
    }
  }


  reprocessSelected(value, indenty) {

    if (value.selected.length > 0) {
      this.checkeddata = value.selected;
      this.messagereprocessdata = [];

      this.checkeddata.forEach(element => {
        var dataCons = {
          "id": element.id,
          "transactionid": element.transactionid,
          "sourceagentapikey": element.sourceagentapikey,
          "sourceagentid": element.sourceagentid,
          "sourceentityid": element.sourceentityid,
          "destinationentityid": element.destinationentityid,
          "messagetype": element.messagetype,
          "mode": element.mode,
          "messageid": element.messageid,
          "parentmessageid": element.parentmessageid,
          "format": element.format,
          "fileid": element.messagepayloadreference,
          "routedefinitionid": element.routedefinitionid,
          "verifyroute": "true",
          "modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
          "parentfileid": element.parentfileid,
          "parentfilename": element.parentfilename
        }
        this.messagereprocessdata.push(dataCons);
      });

      this.ngxUiLoaderService.start();
      var apiname = "api/HubMessage/BatchMessageReprocess"
      this.service.searchpostapi(apiname, this.messagereprocessdata).then(response => {



        if (response['status'] && response['statusText']) {
          this.ngxUiLoaderService.stop();
          this.toastr.error('Reprocess failed', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          this.ngxUiLoaderService.stop();
          if (response['status'] == "failure") {
            this.toastr.error(response['statusmessage'], "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
          else {
            if (this.case360 !== undefined && this.case360.hubmessagedata !== undefined && this.case360.hubmessagedata.length > 0) {
              this.case360search.emit('')
            } else {
              this.SearchMessages();
            }

            this.toastr.success('Reprocessed successfully', "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            // this.autoclosetemplate();
            this.resetReprocess(indenty)
          }
        }
      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Something Went Wrong!', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      });
      // this.selection.clear();
      // this.SearchMessages();
    }
  }



  mirthReprocessSelected(value, indenty) {
    if (value) {
      this.checkeddata = value.selected;
      this.messagereprocessdata = [];

      this.checkeddata.forEach(element => {
        var dataCons = {
          "logId": element.LogId,
          "statusmessage": "",
          // element.MessageStatus
          "status": element.status
        }
        this.messagereprocessdata.push(dataCons);
      });

      this.ngxUiLoaderService.start();
      this.service.reprocessapi(this.messagereprocessdata).subscribe(response => {
        if (response['status'] && response['statusText']) {
          this.ngxUiLoaderService.stop();
          this.toastr.error('Reprocess failed', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          this.ngxUiLoaderService.stop();
          // this.messageStatusList.forEach((elem) => {
          //   elem.selector = new SelectionModel<any>(true, []);
          // });
          // this.messageStatusList[this.selectedIndexTab].messageMirthList =  response.mirthMessagedata as any[];
          // this.messageStatusList[this.selectedIndexTab].messagestageMirthList = response.mirthMessagestage as any[];
          // this.messageStatusList[this.selectedIndexTab].totalmessagecountMirth =  response.messagecountMirth;
          // this.messageMirthList = response.mirthMessagedata as any[];
          // this.messagestageMirthList = response.mirthMessagestage as any[];
          // this.totalmessagecountMirth = response.messagecountMirth;

          if (response['status'] == "failure") {
            this.toastr.error(response['statusmessage'], "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
          else {
            this.SearchMessages();
            this.toastr.success('Reprocessed successfully', "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            // this.autoclosetemplate();
            this.resetReprocess(indenty)
          }
        }
      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Something Went Wrong!', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      });
      // this.selection.clear();
      // this.SearchMessages();
    }
  }



  resetReprocess(value) {
    switch (value) {
      case "Created":
        this.createdSelection.clear()
        break;

      case "Success":
        this.successSelection.clear()
        break;

      case "Failure":
        this.failureSelection.clear()
        break;

      case "Auto-Healing":
        this.AutohealingSelection.clear()
        break;

      case "Ignored":
        this.IgnoreSelection.clear()
        break;

      case "Processing":
        this.ProcessingSelection.clear()
        break;

      case "Others":
        this.OtherSelection.clear()
        break;
    }
  }

  isAllSelected(selector, array) {
    const numSelected = selector.selected.length;
    const numRows = array.length;
    return numSelected === numRows;
  }

  masterToggle(selector, array) {
    this.isAllSelected(selector, array) ?
      selector.clear() :
      array.forEach(row => selector.select(row));
    this.selecteditemscount = selector.selected.length;
  }
  // isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.messageList.length;
  //   return numSelected === numRows;
  // }


  // masterToggle() {
  //   this.isAllSelected() ?
  //     this.selection.clear() :
  //     this.messageList.forEach(row => this.selection.select(row));
  // }


  onSelectAll(event) {
    this.isAddedForReprocess = true;
    const checked = event.checked;
    this.messageList.forEach(item => item.selected = checked);
  }

  fileidOutputValue: any;
  fileidInputValue: any;
  fileInputcheck: boolean;

  checkboxLabel(selector, array, row?: any) {

    if (!row) {
      let ui = `${this.isAllSelected(selector, array) ? 'deselect' : 'select'} all`;
      this.selecteditemscount = selector.selected.length;
      return ui;
    }
    let ui = `${selector.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    this.selecteditemscount = selector.selected.length;
    return ui
  }

  //   checkboxLabelMirth(selector, array, row?: any) {
  //     if (!row) {
  //       let ui = `${this.isAllSelected(selector, array) ? 'deselect' : 'select'} all`;
  //       this.selecteditemscount = selector.selected.length;
  //       return ui;
  //     }
  //     let ui = `${selector.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  //     this.selecteditemscount = selector.selected.length;
  //     return ui
  //   }


  // messageMirthList
  // checkboxLabel(row?: any) {
  //   if (!row) {
  //     return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
  //   }
  //   let ui = `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  //   return ui
  // }

  stagestitle(value) {
    return `${value.stagename}
${value.createddate}`
  }
  async releasemessage() {
    this.ngxUiLoaderService.start();
    let response = await this.service.getapi("api/HubMessage/ReleaseHoldMessage?messageid=" + this.displaymessageid)
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Unable to release the Message", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    }
    else {
      this.autoclosetemplate();
      this.SearchMessages();
      this.ngxUiLoaderService.stopAll();
      this.toastr.success("Message released successfully", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

    }
  }


  outputfilecontent: any;
  inputfilecontent: any;
  mediatypedisplay: boolean = true;
  formatType: string = "plaintext";

  editorOptions: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };
  editor: MonacoStandaloneCodeEditor;

  editorInit(data, editor: MonacoStandaloneCodeEditor, type) {
    this.editor = editor;
    let formatType;
    editor.onDidChangeCursorPosition(() => {
      if (this.IsValidXML(data)) {
        formatType = "xml";
      }
      else if (this.service.IsJsonString(data)) {
        formatType = "json";
      }
      else {
        formatType = "hl7";
      }
      if (type == "input") {
        this.editorOptions1 = { ...this.editorOptions1, language: formatType }
      }
      else {
        this.editorOptions = { ...this.editorOptions, language: formatType }
      }
    });
  }


  editorOptions1: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };


  _base64ToArrayBuffer(base64) {

    var binary_string = base64.replace(/\\n/g, '');
    binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }


  filtermsgtaggel: boolean = true;

  editPipeline(item) {
    sessionStorage.setItem('pipelineid', JSON.stringify(item.routedefinitionid));
    this.router.navigate(['/home/createpipeline']);
  }
  buildtitle(Obj: any) {
    if (typeof (Obj) == "object" && Obj != null) {
      let name = `(${Obj.Type}):${Obj.LocationName}
      ${Obj.Address1}
      `
      return name;
    }
  }
  formatXml(xml: any) {
    var formatted = "";
    var reg = /(>)(<)(\/*)/g;
    xml = xml.replace(reg, "$1\r\n$2$3");
    var pad = 0;
    xml.split("\r\n").map((node: any, index: number) => {
      var indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad != 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      var padding = "";
      for (var i = 0; i < pad; i++) {
        padding += "  ";
      }

      formatted += padding + node + "\r\n";
      pad += indent;
    });
    return formatted;
  }
  editmessageiot(data) {
    this.messageiotreprocess = true;
    this.backupinputdata = data;
    this.editorOptions1.readOnly = false;
  }
  resetmessageiot() {
    this.messageiotreprocess = false;
    this.message_iot_data["InputData"] = this.backupinputdata;
    this.editorOptions1.readOnly = true;
  }
  reprocessmessageiot(c) {
    var dataCons = {
      "id": this.msgObjforiot.id,
      "transactionid": this.msgObjforiot.transactionid,
      "sourceagentapikey": this.msgObjforiot.sourceagentapikey,
      "sourceagentid": this.msgObjforiot.sourceagentid,
      "sourceentityid": this.msgObjforiot.sourceentityid,
      "destinationentityid": this.msgObjforiot.destinationentityid,
      "messagetype": this.msgObjforiot.messagetype,
      "mode": this.msgObjforiot.mode,
      "messageid": this.msgObjforiot.messageid,
      "parentmessageid": this.msgObjforiot.parentmessageid,
      "format": this.msgObjforiot.format,
      "fileid": this.msgObjforiot.messagepayloadreference,
      "routedefinitionid": this.msgObjforiot.routedefinitionid,
      "verifyroute": "true",
      "filecontent": this.message_iot_data["InputData"],
      "modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    }
    this.ngxUiLoaderService.start();
    var apiname = "api/HubMessage/ReprocessFileContent"
    this.service.postapi(apiname, dataCons).then(response => {
      if (response['status'] && response['statusText']) {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Reprocess failed', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        this.ngxUiLoaderService.stop();
        if (response['status'] == "failure") {
          this.toastr.error(response['statusmessage'], "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          this.SearchMessages();
          this.closemessageiotpopup(c);
          this.toastr.success('Reprocessed successfully', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          // this.autoclosetemplate();
        }
      }
    }), error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error('Reprocess failed', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    };
  }

  isLoadingstage: boolean = false;
  selectedmessagetypeevent(item) {
    this.selectedmessagetype = item.displayname;
  }

  Clearemessagetype(event) {
    this.selectedmessagetype = '';
    this.messageTypeList = this.messageTypeAllList;
  }
  messageTypeAllList: any = [];
  async getMessagetypelist(event: any) {

    this.isLoadingstage = true;
    if (event !== 'msgtype') {
      if (event == undefined) {
        this.messageTypeList = this.messageTypeAllList;
      }
      else {
        if (this.messageTypeList.length == 0) {
          this.messageTypeList = this.messageTypeAllList;
        }
        this.messageTypeList = this.messageTypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageTypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingstage = false;
    }
    else {

      await this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: async data => {
          data.forEach(element => {
            this.messageTypeAllList.push({ displayname: element });
          });
          this.messageTypeList = this.messageTypeAllList;

          this.isLoadingstage = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingstage = false;
          this.notfounddata = true;
        }
      });
    }

  }


  isLoadingstatus: boolean = false;
  selectedmessagestatusevent(item) {
    this.selectedmessagestatustype = item.displayname;
  }

  Clearemessagestatus(event) {
    this.selectedmessagestatustype = 'All';
  }
  messageStatusAllList: any = [];
  getMessagestatuslist(event: any) {
    this.isLoadingstage = true;
    if (event !== 'msgstatus') {
      if (event == undefined) {
        this.messageStatusList = this.messageStatusAllList;
      }
      else {
        this.messageStatusList = this.messageStatusAllList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageStatusList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingstage = false;
    }
    else {

      this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagestatus").subscribe(data => {
        this.messageStatusAllList = []
        data.forEach(element => {
          this.messageStatusAllList.push({ displayname: element });
        });
        this.messageStatusAllList.unshift({ displayname: "ALL" });
        this.messageStatusList = JSON.parse(JSON.stringify(this.messageStatusAllList));
        this.messageStatusList.forEach(eleem => {
          eleem['messageList'] = [];
          eleem['messagestageList'] = [];
          eleem['totalmessagecount'] = 0;
        })
        this.isLoadingstage = false;
        if (this.case360 !== undefined && this.case360.hubmessagedata !== undefined && this.case360.hubmessagedata.length > 0) {
          this.showallcaseinboundmessage()
        }
        if (data.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      }),
        error => {
          this.isLoadingstage = false;
          this.notfounddata = true;
        }
    };

  }


  exportreport() {
    let csvData;
    let filename = 'data.csv';
    const data = [];

    if (this.selection.selected.length > 0) {
      this.checkeddata = this.selection.selected;
      this.checkeddata.forEach(element => {
        data.push({
          Messageid: element.messageid,
          Transactionid: element.transactionid,
          RoutedefinitionId: element.routedefinitionid,
          Routename: element.routename,
          Sourceagentid: element.sourceagentid,
          Destinationagentid: element.destinationagentid,
          Sourceentityid: element.sourceentityid,
          Destinationentityid: element.destinationentityid,
          MessageType: element.messagetype,
          Createddate: element.createddate,
          Mode: element.mode
        })
      });
    }
    else {
      this.messageList.forEach(element => {
        data.push({
          Messageid: element.messageid,
          Transactionid: element.transactionid,
          RoutedefinitionId: element.routedefinitionid,
          Routename: element.routename,
          Sourceagentid: element.sourceagentid,
          Destinationagentid: element.destinationagentid,
          Sourceentityid: element.sourceentityid,
          Destinationentityid: element.destinationentityid,
          MessageType: element.messagetype,
          Createddate: element.createddate,
          Mode: element.mode
        })
      });
    }
    if (data.length > 0) {

      csvData = this.ConvertToCSVmessagelist(
        data,
        ['Messageid', 'Transactionid', 'RoutedefinitionId', 'Routename', 'Sourceagentid', 'Destinationagentid', 'Sourceentityid', 'Destinationentityid', 'MessageType', 'Createddate', 'Mode']);

      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);

      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename);
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
    else {
      this.toastr.warning("There is no data !", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  ConvertToCSVmessagelist(objArray, headerscus) {

    let items = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let separator = ',';
    const columns = Object.keys(items[0]).join(separator);
    const body = items.map(item =>
      Object.values(item).join(separator)
    ).join('\n');
    if (headerscus == '') {
      return columns + '\n' + body;
    }
    else {
      return headerscus + '\n' + body;
    }

  }

  CheckValidationStauts(data) {
    if (data.status == '11' &&( data.messagetype.includes("bar") || data.messagetype.includes("dft") || data.messagetype.includes("order") || data.messagetype.includes("orders") || data.messagetype.includes("result") || data.messagetype.includes("vcdft"))) {
      return true;
    }
    else {
      return false;
    }
  }

  OnValidation(item) {
    this.ngxUiLoaderService.start();
    this.service.GetMessageDetailsByID(item.messageid).pipe(
      mergeMap(data => {
        if (data.length > 0) {
          let obj = {
            CaseId: data[0].CaseGUID.toString(),
            MessageID: item.messageid,
            Deployment: data[0].Deployment,
            Direction: data[0].Direction,
            MessageType: item.messagetype
          }
          console.log("Input params to data validation api is " + JSON.stringify([obj]))
          return this.service.dataValidationapi([obj]);
        } else {
          // Return an observable with default or no data
          return of(null);
        }
      })
    ).subscribe(
      ResponseData => {
        this.ngxUiLoaderService.stop();
        if (ResponseData.length > 0) {
          const modalRef = this.modalService.open(DataValidationComponent, {
            windowClass: "Mode_Boot",
            backdrop: "static",
            keyboard: false,
          });
          modalRef.componentInstance.inputDataInModalComponent = ResponseData
          modalRef.result.then((result) => { })
        } else {
          this.ngxUiLoaderService.stop();
          this.toastr.warning("No data found", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      },
      (error) => {
        this.toastr.warning("Error occured while calling GetMessageDetailsByID api", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.ngxUiLoaderService.stop();
      }
    );
  }

  IsValidXML(data) {
    let xmlDocument: any;
    let isValid = false;
    try {
      xmlDocument = (new DOMParser()).parseFromString(data, 'text/xml');
      isValid = true;
    }
    catch {
      xmlDocument = "";
      isValid = false;
    }
    return isValid;
  }
  async getEntityNames(event: any) {

    var valuesa = event;
    if (valuesa != '') {

      this.isLoadingentity = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingentity = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.EntityNameList = response;
        this.EntityNameList = this.service.transformAlphabetically(this.EntityNameList, 'LocationName');
        this.EntityNameList.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedEntityguid = '';
        this.isLoadingentity = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      }
    }

  }
  selectedEntity(item) {
    this.selectedEntityguid = item.LocationGUID;
    this.selectedEntityName = item.LocationName;
  }
  searchEntityCleared() {
    this.isLoadingentity = false;
    this.EntityNameList = [];
    this.selectedEntityguid = '';
    this.notfounddata = false;
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.service.messagesData = "";
  }

  checkvalid(value) {

    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (value !== 0 && value !== "_" && value !== "%" && value !== "+" && value !== "-" && !format.test(value)) {
      this.isvalidSPLchar = false
    } else {
      this.isvalidSPLchar = true
    }
  }


  onSearchMirth() {
    // let obj=this.MirthFitlerForm.value;
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (this.MirthFitlerForm.value.caseid !== "" && format.test(this.MirthFitlerForm.value.caseid)) {
      this.checkvalid(this.MirthFitlerForm.value.caseid)
    } else {
      this.isvalidSPLchar = false
    }

    let newobj = {
      handler: this.MirthFitlerForm.value.handler ? this.MirthFitlerForm.value.handler : '',
      LogId: this.MirthFitlerForm.value.LogId ? this.MirthFitlerForm.value.LogId : '',
      status: this.MirthFitlerForm.value.status ? this.MirthFitlerForm.value.status : '',
      caseid: this.MirthFitlerForm.value.caseid !== '' ? this.MirthFitlerForm.value.caseid : 0,
      createddate: this.MirthFitlerForm.value.createddate ? this.MirthFitlerForm.value.createddate : '',
      processingdate: this.MirthFitlerForm.value.processingdate ? this.MirthFitlerForm.value.processingdate : '',
      deployment: this.messageData.deployment
    }
    newobj.createddate = this.datePipe.transform(newobj.createddate, 'yyyy-MM-dd')
    newobj.processingdate = this.datePipe.transform(newobj.processingdate, 'yyyy-MM-dd')



    this.ngxUiLoaderService.start();
    // var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    this.service.getMirthSearch(newobj).subscribe(data => {

      if (this.invalidlogID == true && format.test(this.MirthFitlerForm.value.LogId)) {

        this.toastr.error("Invaild LogID", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.ngxUiLoaderService.stop();
        // this.messageFullData = [];
        setTimeout(() => {
          this.selectedIndexTab = 0;
          this.messageData.MessageDetails = [];
          this.messageFullData[this.selectedIndexTab].messageList = [];
          this.messageFullData[this.selectedIndexTab].totalmessagecount = 0;
        });

      }

      if (this.isvalidSPLchar) {

        this.toastr.error("Invalid CaseID", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.ngxUiLoaderService.stop();
        // this.messageFullData = [];
        setTimeout(() => {
          this.selectedIndexTab = 0;
          this.messageData.MessageDetails = [];
          this.messageFullData[this.selectedIndexTab].messageList = [];
          this.messageFullData[this.selectedIndexTab].totalmessagecount = 0;
        });
        // this.messageData.MessageDetails=[]
        return
      }

      if (data !== null) {
        this.ngxUiLoaderService.stop();
        // this.getsaved(data);
        // this.messageFullData =[]
        this.selectedIndexTab = 0;
        this.messageData.MessageDetails = data;

        // this.messageFullData.forEach((ele) => {
        //   ele.selector = new SelectionModel<any>(true, []);
        // });
        this.messageFullData[this.selectedIndexTab].messageList = data;
        this.messageFullData[this.selectedIndexTab].totalmessagecount = data.length;
        this.isEngine = this.messageData['engine'];

      }
    }, error => {
      this.ngxUiLoaderService.stop();
    })
  }
  // getsaved(data: any) {
  //   if (data?data.length > 0: false) {
  //     this.userList = data
  //     this.dataSource = new MatTableDataSource(data);
  //     if (this.usersession.pageIndexNumber !== 0) {
  //       this.paginator.pageIndex = this.usersession.pageIndexNumber
  //     }
  //     this.dataSource.paginator = this.paginator;
  //     this.obs = this.dataSource.connect();
  //     this.usersession.totalLength = this.dataSource.data.length;
  //     this.usersession.backInterface = [];
  //     this.interService.changeMessage("true")
  //   }else{
  //     this.userList =[]
  //     this.dataSource = new MatTableDataSource(data);
  //     this.paginator.pageIndex = 0;
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.data.length=0;
  //     this.obs = this.dataSource.connect();
  //   }
  // }


  getMirthData(column: any, event: any) {
    this.isLoadinghandler = false;
    this.isLoadinglogid = false;
    this.isLoadingcaseid = false;
    this.isLoadingstatusMirth = false;
    this.isLoadingCreatedDate = false;
    this.isLoadingProcessingDate = false;


    this.service.getMirthFilters(column, event).subscribe(data => {

      if (column == 'handler') {
        this.isLoadinghandler = true;
        if (data.length == 0) {
          this.handlerList = [];
          this.isLoadinghandler = false;
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
          this.isLoadinghandler = false;
          this.handlerList = []
          this.handlerList = data
        }
      }

      else if (column == 'LogId') {
        this.isLoadinglogid = true;
        if (data.length == 0 && data.length == '') {
          this.logIdList = [];
          this.isLoadinglogid = false;
          this.notfounddata1 = true;
          this.invalidlogID = true;
          // this.invalidcaseid=false;
        } else {
          this.invalidlogID = false;
          // this.invalidcaseid=false;
          this.notfounddata1 = false;
          this.isLoadinglogid = false;
          this.logIdList = []
          this.logIdList = data
        }
      }

      else if (column == 'caseid') {
        this.isLoadingcaseid = true;
        if (data.length == 0 && data.length == '') {

          this.caseIdList = [];
          this.isLoadingcaseid = false;
          this.notfounddata2 = true;
          this.invalidcaseid = true;
          // this.invalidlogID=false;
        } else {
          // this.invalidlogID=false;
          this.invalidcaseid = false;
          this.notfounddata2 = false;
          this.isLoadingcaseid = false;
          this.caseIdList = []
          this.caseIdList = data
        }
      }

      else if (column == 'status') {
        this.isLoadingstatusMirth = true;
        if (data.length == 0) {
          this.statusMirth = [];
          this.isLoadingstatusMirth = false;
          this.notfounddata3 = true;
        } else {
          this.notfounddata3 = false;
          this.isLoadingstatusMirth = false;
          this.statusMirth = []
          this.statusMirth = data
        }
      }

      else if (column == 'createddate') {
        this.isLoadingCreatedDate = true;
        if (data.length == 0) {
          this.createdDate = [];
          this.isLoadingCreatedDate = false;
          this.notfounddata4 = true;
        } else {
          this.notfounddata4 = false;
          this.isLoadingCreatedDate = false;
          this.createdDate = []
          this.createdDate = data
        }
      }

      else if (column == 'processingdate') {
        this.isLoadingProcessingDate = true;
        if (data.length == 0) {
          this.processingDate = [];
          this.isLoadingProcessingDate = false;
          this.notfounddata5 = true;
        } else {
          this.notfounddata5 = false;
          this.isLoadingProcessingDate = false;
          this.processingDate = []
          this.processingDate = data
        }
      }
    }, error => {
      this.isLoadinghandler = false;
      this.notfounddata = true;
      this.notfounddata1 = true;
      this.notfounddata2 = true;
      this.notfounddata3 = true;
      this.notfounddata4 = true;
      this.notfounddata5 = true;
      switch (column) {
        case 'caseid':
          this.invalidcaseid = true
          break;
        case 'LogId':
          this.invalidlogID = true
          break;

        default:
          break;
      }
    })

  }
  getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }
  searchHandlerCleared(event) {
    this.isLoadinghandler = false;
    this.handlerList = [];
    this.notfounddata = false;
  }
  searchLogIdCleared(event) {
    this.isLoadinghandler = false;
    this.logIdList = [];
    this.notfounddata1 = false;
    this.invalidlogID = false;
  }
  searchCaseIdCleared(event) {
    this.isLoadinghandler = false;
    this.caseIdList = [];
    this.notfounddata2 = false;
    this.invalidcaseid = false;
  }
  searchStatusMirthCleared(event) {
    this.isLoadinghandler = false;
    this.statusMirth = [];
    this.notfounddata3 = false;
  }
  searchCreatedDateCleared(event) {
    this.isLoadinghandler = false;
    this.createdDate = [];
    this.notfounddata4 = false;
  }
  searchProcessingDateCleared(event) {
    this.isLoadinghandler = false;
    this.processingDate = [];
    this.notfounddata5 = false;
  }
  removemsgfieldvalue(tolist: any): void {
    const index = this.msgfieldlist.indexOf(tolist);

    if (index >= 0) {
      this.msgfieldlist.splice(index, 1);
    }
    this.msgfieldInput.nativeElement.focus();
  }
  addmsgvalue(event: MatChipInputEvent): void {
    if(event.value !==''){
      const input = event.input;
      const value = event.value;
      let valuelist=[]
        if ((value || '').trim()) {
          if(value.includes('|')){
            valuelist=value.split('|')
            if(valuelist.length<=5)
            {
              valuelist.forEach(X=>{
                if(X!=''){
                  this.msgfieldlist.push({ msgvalue: X.trim() });
                }
              })
          }else{
            this.toastr.warning("Message Field accepts max 5 values", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
            return;
          }
            this.msgfieldctrl.setValue('')
            this.msgfieldInput.nativeElement.value=null
          }
          else{
            this.msgfieldlist.push({ msgvalue: value.trim() });
            this.msgfieldctrl.setValue('')
            this.msgfieldInput.nativeElement.value=null
          }
        }
        this.msgfieldInput.nativeElement.focus();
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder,  FormControl,  FormGroup,  Validators} from "@angular/forms";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from "src/app/services/common.service";
import { ToastrService } from "ngx-toastr";
import { NewauditComponent } from '../newaudit/newaudit.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
interface ContactType {
  viewValue: string;
  value: string;
}
@Component({
  selector: "app-contactmodule",
  templateUrl: "./contactmodule.component.html",
  styleUrls: ["./contactmodule.component.scss"],
})
export class ContactmoduleComponent implements OnInit {
  keywordContname = "name";
 reactiveFormContacts: FormGroup;
  selectedContact: string = "";
  Contactnamelist: any[];
  ContactnameBackuplist: any;
  isLoadingcontact: boolean;
  notfounddata: boolean;
  Contactlist = [];
  userEdit: boolean;
  isEdit: boolean = false;
  contactform: FormGroup;
  submitted: boolean;
  enablerestapicontrol: boolean;
  selectedcontacttype = [];
  contactcntrl = new FormControl();
  ContractDetails: string;
  ApiValue: string;
  Conttypes: ContactType[] = [
    { value: "lab", viewValue: "LAB" },
    { value: "facility", viewValue: "FACILITY" },
    { value: "vendor", viewValue: "VENDOR" },
  ];

  constructor(private _fb: FormBuilder,  private ngxUiLoaderService: NgxUiLoaderService,public service: CommonService,    private toastr: ToastrService,private modalService: NgbModal, ) {
    this.reactiveFormContacts = this._fb.group({ contactPerson: "" });
  }

  ngOnInit() {
    this.selectedContact = ""
    this.contactform = this._fb.group({
      contactid: "",
      contactPerson: ["", Validators.required],
      locationAddress: ["", Validators.required],
      contactType: ["", Validators.required],
      entity: ["", Validators.required],
      contactNumber: ["", Validators.required],
      emailAddress: ["", Validators.required],
      displayname: "",
      modifierid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
    });
  }
  selectedContactDetails(item) {
    if(item !== undefined){
      this.selectedContact = item.contactPerson;
    }
  }

  searchContactCleared() {
    this.selectedContact = "";
    this.Contactnamelist = this.ContactnameBackuplist;
    this.reactiveFormContacts.patchValue({ contactPerson: "" });
  }

  getContactName(column: any, event: any) {
    if(column){
      this.isLoadingcontact = false;
      this.service.getContactNames(column, event).subscribe((data) => {
        if (column == "contactPerson") {
          this.isLoadingcontact = true;
          if (data.length == 0) {
            this.Contactnamelist = [];
            this.isLoadingcontact = false;
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
            this.isLoadingcontact = false;
            this.Contactnamelist = [];
            this.Contactnamelist = data;
          }
        }
      }),
        (error) => {
          this.isLoadingcontact = false;
          this.notfounddata = true;
        };
    }
  }

  getContactSeacrh() {
    this.isEdit = false;
    this.Contactlist = [];
    this.ngxUiLoaderService.start();
    var contactObj = {
      contactPerson: this.reactiveFormContacts.get("contactPerson").value,
    };
    this.service.getContacts(contactObj).subscribe(
      (data) => {
        this.ngxUiLoaderService.stop();
        if (data) {
          this.Contactlist = data as any[];

          if (this.Contactlist.length == 0) {
            this.toastr.warning("No results found!!", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          }
        }
      },
      (error) => {
        this.ngxUiLoaderService.stop();
      }
    );
  }

  createcontactTypeList(value) {
    if (value.contactType.includes(",")) {
      value.contactType = value.contactType.split(",");
    } else {
      value.contactType = [value.contactType];
    }
    return value.contactType;
  }

  resetFields() {
    this.isEdit = false;
    this.selectedContact = "";
    this.reactiveFormContacts.reset();
    this.Contactnamelist = this.ContactnameBackuplist;
    this.reactiveFormContacts.setValue({ contactPerson: "" });
    this.Contactlist = [];
  }

  EditContact(i: any) {
    this.userEdit = true;
    this.isEdit = !this.isEdit;
    this.Contactlist.forEach((va) => {
      va["isEditSelected"] = true;
    });
    this.Contactlist[i].isEditSelected = false;
    this.contactform.patchValue({
      contactid: this.Contactlist[i].contactid,
      contactPerson: this.Contactlist[i].contactPerson,
      contactNumber: this.Contactlist[i].contactNumber,
      emailAddress: this.Contactlist[i].emailAddress,
      locationAddress: this.Contactlist[i].locationAddress,
      contactType: this.Contactlist[i].ContactType,
      entity: this.Contactlist[i].Entity,
      modifierid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    });
  }

  CloseContactEdit() {
    this.isEdit = !this.isEdit;
    this.Contactlist.forEach((va) => {
      va["isEditSelected"] = false;
    });
  }

  ContactObje: any;
  savecontact() {
    this.submitted = true;
    if (this.userEdit) {
      this.ngxUiLoaderService.start();
      this.service.updateContactModule(this.contactform.value)
        .subscribe((data) => {
          if (data) {
            this.ngxUiLoaderService.stop();
            this.toastr.success("Contact Updated Successfully", "Success", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
            this.isEdit = false;
            this.getContactSeacrh();
          } else {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Failed to Update Contact", "Failed", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
          }
        }),
        (error) => {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Failed to Update Contact", "Failed", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        };
    } else {
      this.ngxUiLoaderService.start();
      const inputs = {
        contactNumber: this.contactform.value.contactNumber,
        contactPerson: this.contactform.value.contactPerson,
        contactType: this.contactform.value.contactType,
        emailAddress: this.contactform.value.emailAddress,
        entity: this.contactform.value.entity,
        locationAddress: this.contactform.value.locationAddress,
        modifierid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
      };
      this.service.addNewContact(inputs).subscribe((data) => {
        if (data) {
          this.ngxUiLoaderService.stop();
          this.toastr.success("Contact Created Successfully ", "Success", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
          this.isEdit = false;
          this.getContactSeacrh();
        } else {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Failed to Create Contact", "Failed", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
      }),
        (error) => {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Request Failed", "Failed", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        };
    }
  }

  addContact() {
    this.userEdit = false;
    this.isEdit = true;
    this.contactform.reset();
    this.Contactlist.forEach((va) => {
      va["isEditSelected"] = true;
    });
  }
  openAuditPopUp(id:any) {
    if (id != '') {
      const auditDataToModal = {
        id:id,
        context: "contact",
        type: "ID",
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };
      const modalRef = this.modalService.open(NewauditComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(() => { });
    }

  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileViewExplorerComponent } from 'src/app/file-view-explorer/file-view-explorer.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-caseinfo',
  templateUrl: './caseinfo.component.html',
  styleUrls: ['./caseinfo.component.scss']
})
export class CaseinfoComponent  {
  caseInfoDetails:object = {};
  patienInfo:object = {};
  OrderDetails:object = {};

@Input() caseInfoData={}
selectedIndexTab = 0;
  constructor(
    private service: CommonService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal
  ) {

   }

  ngOnChanges(){
    if(this.caseInfoData !== undefined){
    this.caseInfoDetails = this.caseInfoData['Case'];
    console.log(this.caseInfoDetails)
    this.patienInfo = this.caseInfoData['Case'].Patient
    this.OrderDetails = this.caseInfoData['Case'].OrderDetails
    if(this.caseInfoDetails['QualityAttributesDetails'] !== undefined?this.caseInfoDetails['QualityAttributesDetails'].QualityAttribute:false){
      let attribute = this.caseInfoDetails['QualityAttributesDetails'].QualityAttribute.filter(va =>va.Category !=="Flags")
      this.caseInfoDetails['QualityAttributesList']=attribute
      let flags = this.caseInfoDetails['QualityAttributesDetails'].QualityAttribute.filter(va =>va.Category ==="Flags")
      this.caseInfoDetails['Flags']=flags
    }
    if(this.caseInfoDetails['ServiceProvider'] !== undefined?this.caseInfoDetails['ServiceProvider']:false){

      if(Array.isArray(this.caseInfoDetails['ServiceProvider'])){
        let labs= this.caseInfoDetails['ServiceProvider'].filter(va =>va.Location.AccountType=== "Laboratory")
        if(labs.length>0){
          labs.length>0?this.caseInfoDetails['laboratory'] = labs[0].Location:this.caseInfoDetails['laboratory']=''
         }
         let sendout = this.caseInfoDetails['ServiceProvider'].filter(va =>va.Location.AccountType === "Send-out Laboratory")
         sendout.length>0?  this.caseInfoDetails['sendOut'] = sendout[0].Location:  this.caseInfoDetails['sendOut']=''
         let sendin = this.caseInfoDetails['ServiceProvider'].filter(va =>va.Location.AccountType === "Send-in Laboratory" ||va.Location.AccountType=== "Send In Lab")
         sendin.length>0?  this.caseInfoDetails['sendIn'] = sendin[0].Location:  this.caseInfoDetails['sendIn']=''
      }else{
        if(this.caseInfoDetails['ServiceProvider'].Location.AccountType !== undefined && this.caseInfoDetails['ServiceProvider'].Location.AccountType === "Laboratory" ){
          this.caseInfoDetails['laboratory'] = this.caseInfoDetails['ServiceProvider'].Location
        }else{
          this.caseInfoDetails['laboratory'] ='';
        }
        if(this.caseInfoDetails['ServiceProvider'].Location.AccountType !== undefined && this.caseInfoDetails['ServiceProvider'].Location.AccountType === "Send-out Laboratory" ){
          this.caseInfoDetails['sendOut'] = this.caseInfoDetails['ServiceProvider'].Location
        }else{
          this.caseInfoDetails['sendOut'] ='';
        }
        if(this.caseInfoDetails['ServiceProvider'].Location.AccountType !== undefined && this.caseInfoDetails['ServiceProvider'].Location.AccountType === "Send-in Laboratory" ){
          this.caseInfoDetails['sendIn'] = this.caseInfoDetails['ServiceProvider'].Location
        }else{
          this.caseInfoDetails['sendIn'] ='';
        }
      }
    }
    //  if(this.caseInfoDetails['ServiceProvider'].Location.AccountType !== undefined && this.caseInfoDetails['ServiceProvider'].Location.AccountType !== undefined){
    //     this.caseInfoDetails['laboratory'] = this.caseInfoDetails['ServiceProvider'].Location
    //    }else if(this.caseInfoDetails['ServiceProvider'].length>0){
    //     let labs= this.caseInfoDetails['ServiceProvider'].filter(va =>va.Location.AccountType=== "Laboratory")
    //     if(labs.length>0){
    //       labs.length>0?this.caseInfoDetails['laboratory'] = labs[0].Location:this.caseInfoDetails['laboratory']=''
    //      }
    //      let sendout = this.caseInfoDetails['ServiceProvider'].filter(va =>va.Location.AccountType === "Send-out Laboratory")
    //      sendout.length>0?  this.caseInfoDetails['sendOut'] = sendout[0].Location:  this.caseInfoDetails['sendOut']=''
    //      let sendin = this.caseInfoDetails['ServiceProvider'].filter(va =>va.Location.AccountType === "Send-in Laboratory" ||va.Location.AccountType=== "Send In Lab")
    //      sendin.length>0?  this.caseInfoDetails['sendIn'] = sendin[0].Location:  this.caseInfoDetails['sendIn']=''
    //    }
    //   }

      if(this.caseInfoDetails['OrderDetails']!== undefined?this.caseInfoDetails['OrderDetails'].Order.ICDCodes !=='':false){
        this.caseInfoDetails['OrderDetails'].Order['ICDCodeslist']=this.caseInfoDetails['OrderDetails'].Order.ICDCodes.split(',')
      }
      if(this.caseInfoDetails['SubscriberDetails']!== undefined?this.caseInfoDetails['SubscriberDetails'].Subscriber:false){
        if(Array.isArray(this.caseInfoDetails['SubscriberDetails'].Subscriber)){
          this.caseInfoDetails['primarySubscriber']=this.caseInfoDetails['SubscriberDetails'].Subscriber.find(va => va.ResponsibilityCode =="P")
          this.caseInfoDetails['secondarySubscriber']=this.caseInfoDetails['SubscriberDetails'].Subscriber.find(va => va.ResponsibilityCode =="S")
          this.caseInfoDetails['tertairySubscriber']=this.caseInfoDetails['SubscriberDetails'].Subscriber.find(va => va.ResponsibilityCode =="T")
        }else{
          if(this.caseInfoDetails['SubscriberDetails'].Subscriber.ResponsibilityCode =="P"){
            this.caseInfoDetails['primarySubscriber'] = this.caseInfoDetails['SubscriberDetails'].Subscriber
          }
          if(this.caseInfoDetails['SubscriberDetails'].Subscriber.ResponsibilityCode =="S"){
            this.caseInfoDetails['secondarySubscriber'] = this.caseInfoDetails['SubscriberDetails'].Subscriber
          }
          if(this.caseInfoDetails['SubscriberDetails'].Subscriber.ResponsibilityCode =="T"){
            this.caseInfoDetails['tertairySubscriber'] = this.caseInfoDetails['SubscriberDetails'].Subscriber
          }
        }
      }
    }

  }

  ngOnInit() {
  }
  moreInfo(modalrefid,tabindex) {

    this.modalService
      .open(modalrefid, {
        windowClass: "viewstages",
        backdrop: "static",
        keyboard: false,
      })
      .result.then((result) => {});
      this.selectedIndexTab = tabindex;
}
closemoreinfopopup(c) {
  c("close modal");
}

viewCaseFile(report){
  let location
if(this.caseInfoDetails['ServiceProvider'].length>0){
  location= this.caseInfoDetails['ServiceProvider'].find(value => value.Location.AccountType=="Laboratory")
  if(location !== undefined){
    location = location.Location
  }
}else{
  location= this.caseInfoDetails['ServiceProvider'].Location
}
  let obj=
    {
      "RequestID": report.File.PresentationData,
      "Type": report.File.Category,
      "RequestGUID": report.File.FileGUID,
      "LabOrgGUID":location.OrganizationGUID,
      "CaseGUID": this.caseInfoDetails['CaseGUID'],
      "DeploymentsKey":this.caseInfoData['DeploymentKey']
    }
    this.ngxUiLoaderService.start()
    this.service.viewCases360DXReport(obj).subscribe(data=>{
      this.ngxUiLoaderService.stop()
      if(data.StatusMessage.toLowerCase() !== "failed"){
        if(data['FileContent'] !== null){

          'data:application/pdf;base64,'+data['FileContent']
          let namemodal={
            name:report.ReportName,
            fullfile:data['FileContent']
          }
          const modalRef = this.modalService.open(FileViewExplorerComponent
            , {
            windowClass: "myCustomModalViewfile",
            backdrop: "static",
            keyboard: false
          });
          modalRef.componentInstance.fileData =namemodal ;
          modalRef.result.then(() => { });
              }else{
                this.ngxUiLoaderService.stop();
                this.toastr.warning('Data not Found', "Alert", {
                  positionClass: "toast-bottom-right",
                  timeOut: 4000,
                });
              }
      }else{
        this.ngxUiLoaderService.stop();
        this.toastr.error(data.FileContent, "Failed", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }

    },error=>{
      this.ngxUiLoaderService.stop()
    })
}
}

import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';




@Component({
  selector: 'app-mappingversionapprove',
  templateUrl: './mappingversionapprove.component.html',
  styleUrls: ['./mappingversionapprove.component.scss']
})


export class MappingversionapproveComponent implements OnInit {

  constructor(private service:CommonService) { }
  dataSource:any[]=[];
  ngOnInit() {
    let apiUrl="api/Routes/GetRoutePendingCoreConfig?status=";
    this.service.getapiurl(apiUrl).subscribe({
      next: data => {
        this.dataSource=data as any[];
      },
      error: error => {
        
      }
    })
  }
  displayedColumns: string[] = ['demo-position', 'demo-name'];
 

}


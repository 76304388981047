import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConfigService } from '../configurations/app-config.service';
import { CommonService } from '../services/common.service';
declare const copyToClipboard: any;

@Component({
  selector: 'app-vbjobsdisplay',
  templateUrl: './vbjobsdisplay.component.html',
  styleUrls: ['./vbjobsdisplay.component.scss']
})
export class VbjobsdisplayComponent implements OnInit {
  @Input() inputDataInModalComponent: any;

  constructor(private ngxUiLoaderService: NgxUiLoaderService,
    public service: CommonService,
    private activeModal: NgbActiveModal,
    private _fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService) { }

  pagerolepermisions: any = [];
  devicejobdetails:any=[]
  async ngOnInit() {
    if (await this.service.checksession() == true) {
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'createpipeline').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });
      if (this.pagerolepermisions != undefined) {
        this.routename = this.inputDataInModalComponent.routename;
        if(this.inputDataInModalComponent.JobType=='Device'){
        this.jobdetailslist(this.inputDataInModalComponent.data);
        }
        else{
          this.getvbjobslist();
        }
      }
    }
  }

  vbagentid: string;
  seguid: string;
  deguid: string;
  VBjoblistMappedtoRoute: any[] = [];
  VBjoblist: any[] = [];
  routename: string;
  routevbjobnotfound: boolean = false;
  async getvbjobslist() {
    this.ngxUiLoaderService.start();

    const PostFormData = {
      VBAgentID: this.inputDataInModalComponent.vbagentid,
      JobName: this.inputDataInModalComponent.routename,
      SourceEntity: this.inputDataInModalComponent.sourceaccountid,
      DestinationEntity: this.inputDataInModalComponent.targetaccountid,
      MessageType: this.inputDataInModalComponent.messagetype,
      SourcePath: '',
      JobType: this.inputDataInModalComponent.JobType,
      RouteDefinitionId: this.inputDataInModalComponent.routeid
    }
    let response = await this.service.postapi("api/VBJobs/GetVbFileJob", PostFormData);
    if (response['status'] && response['statusText']) {
      this.toastr.error("Something Went Wrong!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.ngxUiLoaderService.stop();
    } else {
      this.VBjoblistMappedtoRoute = [];
      this.VBjoblist = [];
      if (response.status == "Success" && this.inputDataInModalComponent.JobType == 'FileWatchAndUpload') {
        const resultsresponse = JSON.parse(response.responsebody);
        this.VBjoblistMappedtoRoute = resultsresponse.filter(x => x.RouteDefinitionId == this.inputDataInModalComponent.routeid);
        if (this.VBjoblistMappedtoRoute.length <= 0) {
          this.VBjoblistMappedtoRoute = resultsresponse.filter(x => x.JobID == this.inputDataInModalComponent.jobidmapped);
        }

        if (this.VBjoblistMappedtoRoute != undefined || this.VBjoblistMappedtoRoute.length > 0) {
          this.routevbjobnotfound = true;
        }
        else {
          this.routevbjobnotfound = false;
        }

        resultsresponse.forEach((element, index) => {
          if (element.JobID == this.inputDataInModalComponent.jobidmapped) {
            resultsresponse.splice(index, 1);
          }
        });

        this.VBjoblist = resultsresponse.filter(x => x.SourceEntity == this.inputDataInModalComponent.sourceaccountid &&
          x.DestinationEntity == this.inputDataInModalComponent.targetaccountid &&
          x.VBAgentID == this.inputDataInModalComponent.vbagentid &&
          x.MessageType == this.inputDataInModalComponent.messagetype);
      }
      else if (response.status == "Success" && this.inputDataInModalComponent.JobType == 'FTPFileDownload') {

      }
      this.ngxUiLoaderService.stop();
    }
  }


  currentlyOpenedItemIndex = -1;

  setOpened(itemIndex) {
    this.currentlyOpenedItemIndex = itemIndex;
  }

  setClosed(itemIndex) {
    if (this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }

  copyToClips(event: any, message: string) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
  }

  closetpopup() {
    this.activeModal.close();
  }

  async getJobName() {
    const PostFormData = {
      VBAgentID: this.inputDataInModalComponent.vbagentid,
      JobName: '',
      SourceEntity: this.inputDataInModalComponent.sourceaccountid,
      DestinationEntity: this.inputDataInModalComponent.targetaccountid,
      MessageType: this.inputDataInModalComponent.messagetype,
      SourcePath: ''
    }
    return await this.service.postapi("api/VBJobs/GetVbFileJob", PostFormData);
  }
  jobname:any=''
  jobdetailslist(data){
    this.devicejobdetails=[];
    if(typeof data=='object'){
      for(let [key,value] of Object.entries(data)){
        let obj={}
        if(key=='JobName'){
          this.jobname=[value]
        }
        if(key!='ConnectivityInfo'){
         obj={
          name:key,
          value:value
        }
        this.devicejobdetails.push(obj);
      }else{
        obj={
          name:'RemoteIP',
          value:value['RemoteIP']
        }
        this.devicejobdetails.push(obj);
        obj={
          name:'RemotePort',
          value:value['RemotePort']
        }
        this.devicejobdetails.push(obj);
      }
      }
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SearchPipelineComponent } from './search-pipeline/search-pipeline.component';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule, MatNativeDateModule, MatIconModule, MatSidenavModule,
  MatListModule, MatToolbarModule, MatCardModule, MatInputModule
} from '@angular/material';

import { HomepageComponent } from './homepage/homepage.component';
import { RoutesComponent } from './routes/routes.component';
import { HubmessagesComponent } from './hubmessages/hubmessages.component';
import { TryitoutmainComponent } from './tryitoutmain/tryitoutmain.component';
import { SaveagentsComponent } from './agents/saveagents/saveagents.component';
import { AgentlistComponent } from './agents/agentlist/agentlist.component';
import { W700jobslistComponent } from './w700jobslist/w700jobslist.component';
import { FilescompareComponent } from './filescompare/filescompare.component';
import { CompendiumComponent } from './compendium/compendium.component'
import { BulkmessagereprocessComponent } from './bulkmessagereprocess/bulkmessagereprocess.component';
import { MappingversionapproveComponent } from './mappingversionapprove/mappingversionapprove.component';
import { AuthGuard } from './services/auth.guard';
import { TemplateComponent } from './template/template.component';
import { UserroleComponent } from './userrole/userrole.component';
import { AgentComponent } from './agents/agent/agent.component';
import { VendorlistComponent } from './vendor/vendorlist/vendorlist.component';
import { MetricshighchartComponent } from './metricshighchart/metricshighchart.component';
import { MetricpipelinelistComponent } from './metricpipelinelist/metricpipelinelist.component';
import { ErrormessagelistComponent } from './errormessage/errormessagelist.component';
import { DashboardComponent } from './CIIS/dashboard/dashboard.component';
import { ViewComponent } from './CIIS/view/view.component';
import {  RouteService, RouteServiceform, } from './services/RouteService.service';
import { RequestFormComponent } from './CIIS/request-form/request-form.component';
import { MessageComponent } from './message/message.component';
import { BadmessageComponent } from './badmessage/badmessage.component';
import { ProductagentComponent } from './productagent/productagent.component';
import { CacheComponent } from './cache/cache.component';
import { JobComponent } from './job/job.component';
import { ContactmoduleComponent } from './contactmodule/contactmodule.component';
import { ManageMenusComponent } from './CIIS/manage-menus/manage-menus.component';
import { LogsComponent } from './logs/logs.component';
import { CaseflowComponent } from './caseflow/caseflow.component';
import { PatienthistoryComponent } from './patienthistory/patienthistory.component';
import { Case360Component } from './case360/case360.component';
import { ImportService } from './services/importResolver.service';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { InterfaceComponent } from './CIIS/interface/interface.component';
export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full', },
  { path: 'login', component: LoginComponent },
  {
    path: 'home', component: HomepageComponent,
    children: [
      { path: ' ', redirectTo: 'tryitout', pathMatch: 'full' },
      { path: 'createpipeline', component: SearchPipelineComponent, canActivate: [AuthGuard] },
      { path: 'pipelinelist', component: RoutesComponent, canActivate: [AuthGuard] },
      { path: 'messages', component: HubmessagesComponent, canActivate: [AuthGuard] },
      { path: 'tryitout', component: TryitoutmainComponent, canActivate: [AuthGuard] },
      { path: 'agent', component: SaveagentsComponent, canActivate: [AuthGuard] },
      { path: 'agentlist', component: AgentlistComponent, canActivate: [AuthGuard] },
      { path: 'bulkreprocess', component: BulkmessagereprocessComponent, canActivate: [AuthGuard] },
      { path: 'mappingversion', component: MappingversionapproveComponent, canActivate: [AuthGuard] },
      { path: 'w700', component: W700jobslistComponent, canActivate: [AuthGuard] },
      { path: 'filescompare', component: FilescompareComponent, canActivate: [AuthGuard] },
      { path: 'compendium', component: CompendiumComponent, canActivate: [AuthGuard] },
      { path: 'template', component: TemplateComponent, canActivate: [AuthGuard] },
      { path: 'userrole', component: UserroleComponent, canActivate: [AuthGuard] },
      { path: 'agents', component:  AgentComponent, canActivate: [AuthGuard] },
      { path: 'vendor', component:  VendorlistComponent, canActivate: [AuthGuard] },
      { path: 'metricstransctions', component:  MetricshighchartComponent, canActivate: [AuthGuard] },
      { path: 'metricspipeline', component:  MetricpipelinelistComponent, canActivate: [AuthGuard] },
      { path: 'errormessagelist', component:  ErrormessagelistComponent, canActivate: [AuthGuard] },
      { path: 'badmessage', component:  BadmessageComponent, canActivate: [AuthGuard] },
      { path: 'productagent', component:  ProductagentComponent, canActivate: [AuthGuard] },
      { path: 'cache', component:  CacheComponent, canActivate: [AuthGuard] },
      {path:'job',component:JobComponent,canActivate:[AuthGuard]},
      { path: 'interface', component: InterfaceComponent,canActivate:[AuthGuard] },
      { path: 'view/:id', component: ViewComponent,resolve:{cres:RouteService}  },
      { path: 'request-form/:formid', component: ViewComponent,resolve:{formres:RouteServiceform},canActivate:[AuthGuard] },
      { path: 'interfacerequestform', component: RequestFormComponent, canActivate: [AuthGuard] },
      { path:  'message', component: MessageComponent, canActivate:[AuthGuard]},
      { path: 'cache', component:  CacheComponent, canActivate: [AuthGuard] },
      {path:'job',component:JobComponent,canActivate:[AuthGuard]},
      {path:'contacts',component:ContactmoduleComponent, canActivate:[AuthGuard]},
      {path:'managemenu',component:ManageMenusComponent, canActivate:[AuthGuard]},
      {path:'logs', component:LogsComponent, canActivate:[AuthGuard]},
      {path:'case360flow', component:Case360Component, canActivate:[AuthGuard]},
      {path:'patient/:id', component: PatienthistoryComponent,resolve:{cres:ImportService}  },
      {path:'caseflow', component:CaseflowComponent, canActivate:[AuthGuard]},
      // {path:'diagnosis', component:DiagnosisComponent, canActivate:[AuthGuard]},

    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatInputModule
  ],
  exports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatInputModule
  ]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../configurations/app-config.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  Url: string;
  token: string;
  protected UserLogin: string = AppConfigService.settings.UserLogin;
  protected webapiURL: string = AppConfigService.settings.WebApiURL;
  headers = { withCredentials: true };

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
  }


  urlSafe: SafeResourceUrl;
  Login(UserName) {

    if (UserName.includes('starmark.com')) {
      UserName = UserName.replace('starmark.com', 'starmarkit.com')
    }
    var url = this.UserLogin['fusionauth'] + 'authorize?client_id=' + this.UserLogin['clientid'] + '&response_type=code&redirect_uri=' + this.webapiURL + 'api%2Foauth-callback&scope=offline_access&login_hint=' + UserName + '&state=';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    window.location.href = url;
  }

  // Login() {
  //   var redirecturldomain = '';
  //   if (this.UserLogin['client'].includes("localhost")) {
  //     redirecturldomain = 'localhost';
  //   }

  //   var url = this.UserLogin['fusionauth'] + 'authorize?client_id=' + this.UserLogin['clientid'] + '&response_type=code&redirect_uri=' + this.webapiURL + 'api%2Foauth-callback&scope=offline_access&login_hint=&state=' + redirecturldomain;
  //   this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);

  //   window.location.href = url;
  // }

  logout() {

    this.http.get(this.webapiURL + 'api/logout', this.headers).subscribe(data => {
      sessionStorage.removeItem('sessionObjectlogin');
      sessionStorage.removeItem('rolewithmenus');
      sessionStorage.removeItem('rolepermissions');
      sessionStorage.removeItem('SelectedUserRole');
      sessionStorage.removeItem('selectedRole');
      window.location.href = this.UserLogin['fusionauth'] + "logout?client_id=" + this.UserLogin['clientid'] + "&post_logout_redirect_uri=" + this.UserLogin['client'] + "%23/login";
    });

  }

  RefreshToken() {
    // return  this.http.get<any>(this.webapiURL +urlterm,this.headers)
    return this.http.get<any>(this.webapiURL + 'api/gettoken', this.headers)
  }

  getrolesdata(urlterm) {
    return this.http.get<any>(this.webapiURL + urlterm, this.headers)
  }

  getUserInfo() {
    return this.http.get<any>(this.webapiURL + 'api/user', this.headers)
  }


   clearcookies() {
   return  this.http.get(this.webapiURL + 'api/logout', this.headers);

   }


}


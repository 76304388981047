import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-tryitoutmain',
  templateUrl: './tryitoutmain.component.html',
  styleUrls: ['./tryitoutmain.component.scss']
})
export class TryitoutmainComponent implements OnInit {

  reactiveFormroutegbl: FormGroup;
  constructor(private router: Router,
    private _fb: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService,
    private service: CommonService) {

    this.reactiveFormroutegbl = _fb.group({ routenamegbl: '' });
  }
  usernamedisplay: string;
  inputdata: any;
  userrole: string = '';
  pagerolepermisions: any = ''
  async ngOnInit() {
    if (await this.service.checksession() == true) {
      this.usernamedisplay = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['FormattedDisplayName'];
      this.userrole = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['role'];

      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'tryitout').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });

      if (this.pagerolepermisions == undefined) {
        this.router.navigate(['/login']);
      }
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  isLoadingroutesgbl: boolean = false;
  RouteNameListgbl: any = [];
  selectedrouteIdgbl: string = '';
  selectedrouteDisplayNamegbl: string = '';
  keywordsearchgbl: string = 'displayname';

  getRouteNamegbl(event: any) {
    var val = event;
    this.isLoadingroutesgbl = true;
    var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=0";;
    this.service.getapiurl(apiname).subscribe({
      next: data => {
        this.RouteNameListgbl = data as any[];
        this.isLoadingroutesgbl = false;
      },
      error: error => {
        this.isLoadingroutesgbl = false;
      }
    });
  }

  selectedRoutegbl(item) {
    this.selectedrouteIdgbl = item.id;
    this.selectedrouteDisplayNamegbl = item.displayname;
    this.getRouteDetails();
  }
  searchRouteNameClearedgbl(event) {
    this.isLoadingroutesgbl = false;
    this.RouteNameListgbl = [];
    this.selectedrouteIdgbl = '';
    this.selectedrouteDisplayNamegbl = '';
  }

  enableAccessionSearch: boolean = false;
  fileUploadFTP: boolean = false;

  async getRouteDetails() {
    if (this.selectedrouteIdgbl != "") {
      var url = "api/Routes/get?id=" + this.selectedrouteIdgbl;
      this.ngxUiLoaderService.start();
      this.service.getapiurl(url).subscribe({
        next: async (data) => {
          let response = await this.getFileConfig(data["id"], data["PAID"]);
          if (response != "") {
            var jsonData = JSON.parse(response["json"]);
            var vaconfigdata = jsonData.processorconfig["vaconfig"];
            var interfacetype = jsonData.processorconfig["interfacetype"];
            if (interfacetype === "Inbound") {
              this.fileUploadFTP = true;
            }
            else if (interfacetype === "Outbound" || interfacetype === "EllkayResultOutbound") {
              this.enableAccessionSearch = true;
            }

            this.inputdata = {
              RouteIdtry: data["id"],
              ProcessorIdtry: data["PAID"],
              ProcessorDisplayNametry: data["PANAME"],
              RouteDisplayNametry: data["displayname"],
              Processorapikey: data["PAAPIKEY"],
              Popupflag: true,
              MappedXmlTemp: "",
              isFileUpload: vaconfigdata.isfileupload == 0 ? false : true,
              isfiledownload: vaconfigdata.isfiledownload == 0 ? false : true,
              interfacetype: interfacetype,
              enableAccessionSearch: this.enableAccessionSearch,
              fileUploadFTP: this.fileUploadFTP,
              sourcedeploymentkey: data["SEDEPLOYMENTKEY"],
              targetdeploymentkey: data["DEDEPLOYMENTKEY"],
              sourceaccounttype: data["sourcetype"],
              targetaccounttype: data["targettype"],
              seguid: data["SEGUID"],
              deguid: data["DEGUID"]
            };
          }

          this.ngxUiLoaderService.stop();
        },
        error: error => {
          this.ngxUiLoaderService.stop();
        }
      });
    }
  }
  async getFileConfig(routeid: string, ProcessorId: string) {
    var dataPost = {
      agentid: ProcessorId,
      guids: routeid,
    };

    return await this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost);

    //
  }
  logout() {
    sessionStorage.removeItem('sessionObjectlogin');
    this.router.navigate(['/login']);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { routes } from 'src/app/app-routing.module';
import { CommonService } from 'src/app/services/common.service';
import { ContactComponent } from '../contact/contact.component';
import { SavevendorComponent } from '../Savevendor/savevendor.component';
import { NewauditComponent } from '../../newaudit/newaudit.component';


@Component({
  selector: 'app-vendorlist',
  templateUrl: './vendorlist.component.html',
  styleUrls: ['./vendorlist.component.scss']
})
export class VendorlistComponent implements OnInit {
  keywordaccount = "LocationName";
  keywordvendor="name";
  public reactiveFormAccount: FormGroup;
  public reactiveFormvendor:FormGroup;
  entityprimaryid: any;
  AccountList: any;
  isLoadingAccount: boolean = false;
  isLoadingvendor:boolean=false;
  notfounddata: boolean;
  Vendornamelist: any;
  selectedvendor: string = "";
  Vendorlist: any;
  cardHeight: Number = window.innerHeight - 190;
  VendornameBackuplist: any;
  constructor(private toastr: ToastrService, private _fb: FormBuilder,
    public service: CommonService, private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal, private router: Router,

    ) {
    this.reactiveFormAccount = this._fb.group({ account: '' });
    this.reactiveFormvendor=this._fb.group({vendorname:''})
  }
  pagerolepermisions: any = [];
  ngOnInit() {
    this.getVendorname();
    const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'vendor').MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
    actionitems.forEach(element => {
      this.pagerolepermisions[element.MenuName] = true;
    });
  }
  getVendorname() {
    this.ngxUiLoaderService.start();
    var url = "api/Vendor/GetVendorName";
    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        this.Vendornamelist = data as any[];
        this.Vendornamelist=this.service.transformAlphabetically(this.Vendornamelist,'name');
        this.VendornameBackuplist=this.Vendornamelist;
        this.ngxUiLoaderService.stop();
      }
      else {
        this.ngxUiLoaderService.stop();
      }
    })
  }
  //source agent selected Event
  selectedsourceaccountEvent(item)
  {
    this.entityprimaryid = item.entityid;
  }

  reset() {
    this.reactiveFormAccount.reset();
    this.entityprimaryid = "";
    this.selectedvendor = "";
    this.reactiveFormvendor.reset();
    this.Vendornamelist=this.VendornameBackuplist;
    this.reactiveFormvendor.setValue({ "vendorname": '' });
    this.Vendorlist=[];
  }
  getAccountName(value) {
    if (value != '') {
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + value;
      this.isLoadingAccount = true;
      this.service.getapi(createSearch).then(response => {
        if (response['status'] && response['statusText']) {
          this.isLoadingAccount = false;
          this.toastr.error("Unable to fetch Source Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          if (response.length > 0) {
            this.AccountList = response as any[];
            this.AccountList =this.service.transformAlphabetically(this.AccountList,'LocationName');
            this.AccountList.map(obj => obj.LocationName = `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }
          if (response.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.entityprimaryid = '';
          this.isLoadingAccount = false;
        }
      });
    }
  }
  selectedaccountEvent(item) {
    this.entityprimaryid = item.LocationGUID;
  }
  searchNameCleared() {
    this.entityprimaryid = "";
    this.AccountList = [];
  }
  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }
  getVendorRoutes(Item, pipelinecount) {
    if (pipelinecount == 0) {
      this.toastr.warning("No pipeline associated for the vendor : " + Item.name, "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      var data = {
        "vendorname": Item.name,
        "vendorid": Item.Id,
        "entityid": this.entityprimaryid,
      };
      sessionStorage.setItem("VendorData", JSON.stringify(data));
      this.router.navigate(['/home/pipelinelist']);
    }

  }
  getVendorSearch() {
    this.Vendorlist=[];
    if(!this.selectedvendor && this.reactiveFormvendor.get('vendorname').value){
      this.toastr.warning("No results found!!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else if(!this.entityprimaryid &&this.reactiveFormAccount.get('account').value){
      this.toastr.warning("No results found!!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else{
    this.ngxUiLoaderService.start();
    var vendorObj = {
      vendorname: this.selectedvendor?this.selectedvendor:'',
      entityid: this.entityprimaryid ? this.entityprimaryid : ''
    };
    var apiname = "api/Vendor/GetVendorSearch";
    this.service.postapi(apiname, vendorObj).then(data => {
      if (data) {
        this.Vendorlist = data as any[];
        if (this.Vendorlist.length > 0) {
          this.Vendorlist.forEach(ele => {
            if (ele["connectivity"]) {
              ele["connectivityinfo"] = JSON.parse(ele["connectivity"]);
              ele["connectivityinfo"] = this.createconnectivityList(ele["connectivityinfo"] );
            }

          })
        }
        this.ngxUiLoaderService.stop();
        if (this.Vendorlist.length == 0) {
          this.toastr.warning("No results found!!", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      }
    });
  }
  }
  openvendor(data) {
    let obj={
      data:data,
      vendornamelist:this.VendornameBackuplist,
      component:'Vendor',
      Type:data?"Edit":"Add",
      modalRef: null
    }
    const modalRef = this.modalService.open(SavevendorComponent,
      { windowClass: "saveVendormodal", backdrop: 'static', keyboard: false });
      obj.modalRef = modalRef;
    modalRef.componentInstance.inputDataInModalComponent = obj;
    modalRef.result.then((data) => {
      if (data == 'save') {
      this.selectedvendor='';
      this.entityprimaryid='';
      this.getVendorSearch();
      }
    });
  }
  openvendorContact(item:any) {
    this.ngxUiLoaderService.start()
    this.service.viewContact(item).subscribe(data=>
      {
        if(data.contactperson!==null){
          const modalRef = this.modalService.open(ContactComponent,
            { windowClass: "VendorContactmodal", backdrop: 'static', keyboard: false });
          modalRef.componentInstance.ContactList = data
          modalRef.componentInstance.isVendorid = item
          modalRef.result.then((data) => {
          
          });
        }
      this.ngxUiLoaderService.stop()
      },error=>{
        this.ngxUiLoaderService.stop();
      this.toastr.error('Request Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
      })

      

  }
  download(array,id) {

    if (array.length > 0) {
      this.ngxUiLoaderService.start();
      let createdby=JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];   
      var apiname = `api/Routes/GetFileContent?contextId=${id}&createdBy=${createdby}`; 
      this.service.postapi(apiname, array).then(response => {
        let data = response as any[];
        for (var i = 0; i < data.length; i++) {
          const source = `data:${JSON.parse(data[i].statusmessage).contenttype};base64,${data[i]["responsebody"]
            }`;
          const link = document.createElement("a");
          link.href = source;
          link.download = JSON.parse(data[i].statusmessage).filename;
          link.click();
        }
        this.ngxUiLoaderService.stop();
      });
    }
  }
  selectedVendor(item){
    this.selectedvendor=item.name;
  }
  searchVendorCleared(){
    this.selectedvendor="";
    this.Vendornamelist=this.VendornameBackuplist;
    this.reactiveFormvendor.setValue({ "vendorname": '' });
  }
getVendorName(event){
  this.isLoadingvendor = false;
  if (this.Vendornamelist.length > 0) {
    this.filtervendorlist(event);
  }
  else{
    this.Vendornamelist=this.VendornameBackuplist;
    this.filtervendorlist(event);
  }
}
filtervendorlist(event){
  this.Vendornamelist = this.Vendornamelist.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
    if (this.Vendornamelist.length == 0) {
      this.notfounddata = true;

    } else {
      this.notfounddata = false;
    }
    this.isLoadingvendor = false;
}
openAuditPopUp(uniqueid: any, vendorname: any) {

  if (uniqueid != '' && vendorname != '') {
    const auditDataToModal = {
      id: uniqueid,
      title: vendorname,
      context:"Vendor",
      guid: '' ,
      type:"Name",
      userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    };

    const modalRef = this.modalService.open(NewauditComponent,
      { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
    modalRef.result.then(data => {

    });
  }

}
createconnectivityList(value){
 if( value.connectivitytype.includes(",")){
  value.connectivitytype =  value.connectivitytype.split(",")
 }else{
  value.connectivitytype = [value.connectivitytype]
 }

return value.connectivitytype;
}
}

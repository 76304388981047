import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "../services/common.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-case360",
  templateUrl: "./case360.component.html",
  styleUrls: ["./case360.component.scss"],
})
export class Case360Component implements OnInit {
  searchParameterInfo: boolean = true;
  searchcaseText: any = "";
  searchDeployement: any;
  caseDataList = [];
  caseDataListBackup = {};
  caseDataListCard: unknown = [];
  Case360FormGroup: FormGroup = this._formBuilder.group({
    searchvalue: ["", Validators.required],
  });
  caseInfo: any;
  caseInfoDetails: any;
  requisitionNumberData: object = {};
  CaseList: any[] = [];
  showDetailsCase: boolean = false;
  encryptedurl: any = '';
  encryptflag: boolean = false;
  auditData: any;
  isEnablesso: any;
  encryptagentid: string = '';

  constructor(
    public service: CommonService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {


    this.route.queryParams
      .subscribe(params => {
        if (params.Input != undefined && params.Input != '') {
          this.encryptedurl = params.Input
          this.encryptflag = true;
          this.encryptagentid=params.EncryptID
          this.service.updatessoStatus(true)
        }
      }
      );
  }
  ngOnInit(
  ) {
    if (this.encryptedurl !== '' && this.encryptedurl != undefined && this.encryptedurl !== '{}') {
      this.searchCase360()
    }
  }
  clearCaseFlow() {
    this.Case360FormGroup.patchValue({
      searchvalue: "",
    });
  }

  //  Search Methods starts
  searchCase360() {
    this.CaseList = [];

    if (this.encryptedurl !== '' && this.encryptedurl != undefined && this.encryptedurl !== '{}') {
      this.searchcaseText = this.encryptedurl;
      this.service.updatessoStatus(true)
    } else if (this.searchcaseText !== "" && this.Case360FormGroup.valid) {
        let str = this.searchcaseText.split(":");
        // if (str.length < 0 ) {
          if (str.length < 3) {
            this.toastr.warning(
              "Invalid Search",
              "Search values should be Three parameters.",
              {
                positionClass: "toast-bottom-right",
                timeOut: 4000,
              }
            );
            return;
          }
          else if(str.length >3) {
            this.toastr.warning(
              "Invalid Search",
              "Search values shouldn't be more than three parameters.",
              {
                positionClass: "toast-bottom-right",
                timeOut: 4000,
              }
            );
            return;
          }else if(str[str.length -1] ===''){
            this.toastr.warning(
              "Invalid Search",
              "Search values should be  three parameters.",
              {
                positionClass: "toast-bottom-right",
                timeOut: 4000,
              }
            );
            return;
          }
        // }else{
        //   this.toastr.warning(
        //     "Invalid Search",
        //     "Search values should be Three parameters.",
        //     {
        //       positionClass: "toast-bottom-right",
        //       timeOut: 4000,
        //     }
        //   );
        //   return;
        // }
      } else {
        this.toastr.warning("Required fields are empty", "Search", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
        return
      }


    this.ngxUiLoaderService.start();
    const obj = {
      "lSearch": this.searchcaseText,
      "Encrypt": this.encryptflag,
      "AgentId":this.encryptagentid
    }
    this.service.case360info(obj).subscribe(
      (data) => {
        this.ngxUiLoaderService.stop();
        if (data.length > 0) {
          this.searchParameterInfo = false;
          data.forEach(element => {
            if(element.casedata !== '') {
            element['caseInfoDetails'] = JSON.parse(element.casedata).Case;
            }
          })

          this.CaseList = data;
          if (data[0].ssoLaunch != undefined && data[0].ssoLaunch) {
            this.showCaseData(data[0]);
            var sessionObject1 = {
              userdata: {
                FormattedDisplayName: data[0].userId,
                userid: data[0].userId,
              }
            }
            sessionStorage.setItem('sessionObjectlogin', JSON.stringify(sessionObject1));
          }

          //console.log(data)
          // if (data.casedata != null && data.casedata !== '') {
          // }
        } else {
          this.searchParameterInfo = true;
          this.caseInfo = "";
          this.caseInfoDetails = "";
          // this.searchParameterInfo=true;
          this.requisitionNumberData = {};
          this.toastr.warning(
            "Case data could not be found. Please try again with different input.",
            "Alert",
            {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            }
          );
          this.searchDeployement = "";
        }
      },
      (error) => {
        this.ngxUiLoaderService.stop();
        this.toastr.error(error.statusText, "Failed", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
        this.searchDeployement = "";
      }
    );

  }
  resetform() {
    this.searchcaseText = "";
    this.Case360FormGroup.reset();
    this.searchParameterInfo = true;
    this.caseInfoDetails = "";
    this.CaseList = [];

  }

  showCaseData(data) {
    this.showDetailsCase = true
    this.caseInfo = JSON.parse(data.casedata);
    this.caseInfoDetails = this.caseInfo["Case"];
    this.caseInfo = {
      ...this.caseInfo,
      DeploymentKey: data.deploymentKey,
    };
    this.auditData = {deploymentKey:data.deploymentKey,CaseGUID:this.caseInfoDetails.CaseGUID}

    this.caseInfoDetails = {
      ...this.caseInfoDetails,
      DeploymentKey: data.deploymentKey,
    };
    let obj = {
      ReqNumber: data.ReqNumber,
      DeploymentKey: data.deploymentKey,
      caseid: data.caseid,
      // "laborgid":data.laborgid,
      // "labaccountid":data.labaccountid
    };
    this.requisitionNumberData = obj;
    this.caseDataList;
  }

  getlabName(caseInfoDetails) {
    if(caseInfoDetails !== undefined) {
    if (Array.isArray(caseInfoDetails['ServiceProvider'])) {
      let labs = caseInfoDetails['ServiceProvider'].filter(va => va.Location.AccountType === "Laboratory")
      if (labs.length > 0) {
        return labs[0].Location.DisplayName;
      }
    } else {
      if (caseInfoDetails['ServiceProvider'].Location.AccountType !== undefined &&
        caseInfoDetails['ServiceProvider'].Location.AccountType === "Laboratory") {
        return caseInfoDetails['ServiceProvider'].Location.DisplayName;
      }
    }
  }
}
}

interface CaseInfo {
  CaseType: string;
  Services: string;
  AccessionNumber: string;
}

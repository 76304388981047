import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-file-view-explorer',
  templateUrl: './file-view-explorer.component.html',
  styleUrls: ['./file-view-explorer.component.scss']
})
export class FileViewExplorerComponent implements OnInit {
  pdfSrc:any;
  @Input()fileData;
  @Input() messageData;
  Name: any;
  constructor(
    private activeModal: NgbActiveModal,
    ) {

  }

  ngOnInit() {
    this.Name = this.fileData.name
this.pdfSrc = this._base64ToArrayBuffer(this.fileData.fullfile)

  }

closetpopup() {
  this.activeModal.close();
}

_base64ToArrayBuffer(base64) {
  var binary_string = base64.replace(/\\n/g, '');
  binary_string =  window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array( len );
  for (var i = 0; i < len; i++)        {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

}

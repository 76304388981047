import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { routes } from 'src/app/app-routing.module';
import { AuditdetailsComponent } from 'src/app/auditdetails/auditdetails.component';
import { CommonService } from 'src/app/services/common.service';
import { SavevendorComponent } from '../Savevendor/savevendor.component';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
isVendorid:number=0;
  ContactList = []
  isEdit: boolean = false;
  keywordaccount = "LocationName";
  keywordvendor = "name";
  public reactiveFormAccount: FormGroup;
  public reactiveFormvendor: FormGroup;
  contactform: FormGroup;
  entityprimaryid: any;
  AccountList: any;
  isLoadingAccount: boolean = false;
  isLoadingvendor: boolean = false;
  notfounddata: boolean;
  Vendornamelist: any;
  selectedvendor: string = "";
  Vendorlist: any;
  cardHeight: Number = window.innerHeight - 190;
  VendornameBackuplist: any;
  userEdit: boolean;
  submitted: any;
  constructor(private toastr: ToastrService, private _fb: FormBuilder,
    public service: CommonService, private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal, private router: Router,
    private activeModal: NgbActiveModal  ) {
    this.reactiveFormAccount = this._fb.group({ account: '' });
    this.reactiveFormvendor = this._fb.group({ vendorname: '' })
  }
  pagerolepermisions: any = [];
  ngOnInit() {
    this.contactform = this._fb.group({
      contactid:'',
      vendorid:'',
      contactperson: ['', Validators.required],
      contactnumber: ['',Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      emailAddress: ['', Validators.required],
      locationAddress: ['', Validators.required],
      status:'',
      type: ['', Validators.required]
    })
    this.ContactList.forEach(va => {
      va['isEditSelected'] = false;
    })
    // this.getVendorname();
    const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'vendor').MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
    actionitems.forEach(element => {
      this.pagerolepermisions[element.MenuName] = true;
    });
  }

  get f(){
    return this.contactform.controls;
  }

  EditContact(i: any) {
    this.userEdit = true;
    this.isEdit = !this.isEdit;
    this.ContactList.forEach(va => {
      va['isEditSelected'] = true;
    })
    this.ContactList[i].isEditSelected = false;
    this.contactform.patchValue({
      contactid: this.ContactList[i].contactid,
      vendorid:this.ContactList[i].vendorid,
      contactperson: this.ContactList[i].contactperson,
      contactnumber: this.ContactList[i].contactnumber,
      emailAddress: this.ContactList[i].emailAddress,
      locationAddress: this.ContactList[i].locationAddress,
      status:this.ContactList[i].status,
      type: this.ContactList[i].type
    })

  }
  CloseContactEdit() {
    this.isEdit = !this.isEdit;
    this.ContactList.forEach(va => {
      va['isEditSelected'] = false;
    })
  }

  addContact() {
    this.userEdit = false;
    this.isEdit = true;
    this.contactform.reset()
    this.ContactList.forEach(va => {
      va['isEditSelected'] = true;
    })
  }



  getVendorname() {
    this.ngxUiLoaderService.start();
    var url = "api/Vendor/GetVendorName";
    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        this.Vendornamelist = data as any[];
        this.Vendornamelist = this.service.transformAlphabetically(this.Vendornamelist, 'name');
        this.VendornameBackuplist = this.Vendornamelist;
        this.ngxUiLoaderService.stop();
      }
      else {
        this.ngxUiLoaderService.stop();
      }
    })
  }
  //source agent selected Event
  selectedsourceaccountEvent(item) {
    this.entityprimaryid = item.entityid;
  }

  reset() {
    this.reactiveFormAccount.reset();
    this.entityprimaryid = "";
    this.selectedvendor = "";
    this.reactiveFormvendor.reset();
    this.Vendornamelist = this.VendornameBackuplist;
    this.Vendorlist = [];
  }
  getAccountName(value) {
    if (value != '') {
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + value;
      this.isLoadingAccount = true;
      this.service.getapi(createSearch).then(response => {
        if (response['status'] && response['statusText']) {
          this.isLoadingAccount = false;
          this.toastr.error("Unable to fetch Source Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          if (response.length > 0) {
            this.AccountList = response as any[];
            this.AccountList = this.service.transformAlphabetically(this.AccountList, 'LocationName');
            this.AccountList.map(obj => obj.LocationName = `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }
          if (response.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.entityprimaryid = '';
          this.isLoadingAccount = false;
        }
      });
    }
  }
  selectedaccountEvent(item) {
    this.entityprimaryid = item.LocationGUID;
  }
  searchNameCleared() {
    this.entityprimaryid = "";
    this.AccountList = [];
  }
  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }
  getVendorRoutes(name, pipelinecount) {
    if (pipelinecount == 0) {
      this.toastr.warning("No pipeline associated for the vendor : " + name, "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      var data = {
        "vendorname": name,
        "entityid": this.entityprimaryid,
      };
      sessionStorage.setItem("VendorData", JSON.stringify(data));
      this.router.navigate(['/home/pipelinelist']);
    }

  }
  vendorObj:any
  getVendorSearch() {
    this.Vendorlist = [];
    if (!this.selectedvendor && this.reactiveFormvendor.get('vendorname').value) {
      this.toastr.warning("No results found!!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else if (!this.entityprimaryid && this.reactiveFormAccount.get('account').value) {
      this.toastr.warning("No results found!!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      this.ngxUiLoaderService.start();
       this.vendorObj = {
        vendorname: this.selectedvendor ? this.selectedvendor : '',
        entityid: this.entityprimaryid ? this.entityprimaryid : ''
      };
      var apiname = "api/Vendor/GetVendorSearch";
      this.service.postapi(apiname, this.vendorObj).then(data => {
        if (data) {
          this.Vendorlist = data as any[];
          if (this.Vendorlist.length > 0) {
            this.Vendorlist.forEach(ele => {
              if (ele["connectivity"]) {
                ele["connectivityinfo"] = JSON.parse(ele["connectivity"]);
                ele["connectivityinfo"] = this.createconnectivityList(ele["connectivityinfo"]);
              }

            })
          }
          this.ngxUiLoaderService.stop();
          if (this.Vendorlist.length == 0) {
            this.toastr.warning("No results found!!", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
        }
      });
    }
  }
  openvendor(data) {
    let obj = {
      data: data,
      vendornamelist: this.VendornameBackuplist,
      component: 'Vendor',
      Type: data ? "Edit" : "Add"
    }
    const modalRef = this.modalService.open(SavevendorComponent,
      { windowClass: "saveVendormodal", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = obj;
    modalRef.result.then((data) => {
      if (data == 'save') {
        this.selectedvendor = '';
        this.entityprimaryid = '';
        this.getVendorSearch();
      }
    });
  }

  savecontact() {
 // //console.log(this.contactform.value)

 this.submitted = true;
 if(this.userEdit){
   this.ngxUiLoaderService.start()
   this.service.updateContact(this.contactform.value).subscribe(data=>{
     if(data){
       this.ngxUiLoaderService.stop()
       this.toastr.success('Contact Updated Successfully', 'Success', { positionClass: 'toast-bottom-right', timeOut: 4000 })
       this.service.viewContact(this.vendorObj.vendorname).subscribe(data =>{
         this.ContactList = data
         this.isEdit = false;
       })
     }else{
       this.ngxUiLoaderService.stop()
       this.toastr.error('Failed to Update Contact', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 })
     }
   }),error=>{
     this.ngxUiLoaderService.stop()
     this.toastr.error('Failed to Update Contact', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 })
   }
 }else{
   this.ngxUiLoaderService.start()
   this.contactform.value.vendorid =this.isVendorid
   this.contactform.value.contactid =""
   this.contactform.value.status =""

   this.service.createContact(this.contactform.value).subscribe(data=>{
     if(data){
       this.ngxUiLoaderService.stop()
       this.toastr.success('Updated Successfully', 'Success', { positionClass: 'toast-bottom-right', timeOut: 4000 })
       this.service.viewContact(this.vendorObj.vendorname).subscribe(data =>{
         this.ContactList = data
       })
     }else{
       this.ngxUiLoaderService.stop()
       this.toastr.error('Update Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 })
     }
   }),error=>{
     this.ngxUiLoaderService.stop()
     this.toastr.error('Request Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 })
   }
 }



  }

  download(array) {
    if (array.length > 0) {
      this.ngxUiLoaderService.start();
      var apiname = "api/Routes/GetFileContent";
      this.service.postapi(apiname, array).then(response => {
        let data = response as any[];
        for (var i = 0; i < data.length; i++) {
          const source = `data:${JSON.parse(data[i].statusmessage).contenttype};base64,${data[i]["responsebody"]
            }`;
          const link = document.createElement("a");
          link.href = source;
          link.download = JSON.parse(data[i].statusmessage).filename;
          link.click();
        }
        this.ngxUiLoaderService.stop();
      });
    }
  }

  selectedVendor(item) {
    this.selectedvendor = item.name;
  }

  searchVendorCleared() {
    this.selectedvendor = "";
    this.Vendornamelist = this.VendornameBackuplist;
    this.reactiveFormvendor.setValue({ "vendorname": '' });
  }
  getVendorName(event) {
    this.isLoadingvendor = false;
    if (this.Vendornamelist.length > 0) {
      this.filtervendorlist(event);
    }
    else {
      this.Vendornamelist = this.VendornameBackuplist;
      this.filtervendorlist(event);
    }
  }
  filtervendorlist(event) {
    this.Vendornamelist = this.Vendornamelist.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
    if (this.Vendornamelist.length == 0) {
      this.notfounddata = true;

    } else {
      this.notfounddata = false;
    }
    this.isLoadingvendor = false;
  }
  openAuditPopUp(uniqueid: any, vendorname: any) {

    if (uniqueid != '' && vendorname != '') {
      const auditDataToModal = {
        id: uniqueid,
        title: "Vendor",
        context: "Vendor",
        guid: vendorname,
        type: "Name",
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };

      const modalRef = this.modalService.open(AuditdetailsComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(data => {

      });
    }

  }
  createconnectivityList(value) {
    if (value.connectivitytype.includes(",")) {
      value.connectivitytype = value.connectivitytype.split(",")
    } else {
      value.connectivitytype = [value.connectivitytype]
    }

    return value.connectivitytype;
  }
  closevendorpopup() {

    this.activeModal.close();
  }
}

import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MonacoEditorConstructionOptions, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import beautify from "xml-beautifier";
declare const copyToClipboard: any;

@Component({
  selector: 'app-badmessage',
  templateUrl: './badmessage.component.html',
  styleUrls: ['./badmessage.component.scss']
})
export class BadmessageComponent implements OnInit {

  constructor(private router: Router,
    private service: CommonService,
    private _fb: FormBuilder,
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal) {
    this.reactiveFormsourceagent = _fb.group({ saname: '' });
    this.reactiveFormmsgtype = this._fb.group({ msgname: '' });
    this.reactiveFormde = _fb.group({ dename: '' });
    this.reactiveFormse = _fb.group({ sename: '' });
    this.reactiveFormentity = _fb.group({ entityname: '' });
    this.reactiveFormformatfilter = this._fb.group({ formatfilter: '' });
    this.reactiveFormmsgtypereprocess = this._fb.group({ msgnamereprocess: '' });
    this.reactiveFormsereprocess = _fb.group({ senamereprocess: '' });
    this.reactiveFormdereprocess = _fb.group({ denamereprocess: '' });
    this.reactiveFormformatfilterreprocess = this._fb.group({ formatfilterreprocess: '' });
    this.reactiveFormsourceagentreprocess = _fb.group({ sanamereprocess: '' });
  }
  cardHeight: Number = window.innerHeight - 250;
  reactiveFormsourceagent: FormGroup;
  SourceagentList: any = [];
  keywordagent: string = 'displayname';
  selectedsourceagentid: string = '';
  selectedsourceagentname: string = '';
  isLoadingsourceagent: boolean = false;
  notfounddata: boolean;
  transactionidvalue: string = '';


  pagerolepermisions: any = [];
  async ngOnInit() {
    this.setDateFilter()
    if (await this.service.checksession() == true) {
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'badmessage').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });
      this.showSourceTarget = true;

      await this.getMessagetypelist('msgtype');
      this.reactiveFormmsgtype.patchValue(this.messageTypeAllList);

      await this.getMessagetypelistreprocess('msgtype');
      this.reactiveFormmsgtypereprocess.patchValue(this.messageTypeAllListreprocess);
    }
    else {
      this.router.navigate(['/login']);
    }
  }
  setDateFilter() {
    var curr = new Date; // get current date
    var first = curr.getDay(); // First day is the day of the month - the day of the week
    var last = curr.getDate(); // last day is the first day + 6
    first = last - curr.getDay();
    ////console.log(curr, first, last);
      first = last - 2;
      let firstday = new Date(curr.setDate(first));
    let lastd = new Date()
    let lastday = new Date(lastd.setDate(last));
    let firstdate,lastdate,firstmonth,lastmonth
    firstmonth = firstday.getMonth() + 1 < 10 ? "0"+(firstday.getMonth() + 1):firstday.getMonth() + 1;
    lastmonth = lastday.getMonth() + 1 < 10 ? "0"+(lastday.getMonth() + 1):lastday.getMonth() + 1;
    firstdate = firstday.getDate() < 10 ? "0"+firstday.getDate():firstday.getDate();
    lastdate = lastday.getDate() < 10 ? "0"+lastday.getDate():lastday.getDate();

    this.fromdate = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    // this.todate = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    this.todate = `${lastday.getFullYear()}-${lastmonth}-${lastdate}`;

    ////console.log(this.datefilter,this.enddtfilter)
  }
  selectedsourceagentEvent(item) //source agent selected Event
  {
    this.selectedsourceagentid = item.id;
    this.selectedsourceagentname = item.displayname;
  }

  async sgetSourceAgent(event: any) {
    var valuesa = event;
    this.selectedsourceagentid = '';
    if (valuesa != '') {
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;
      this.isLoadingsourceagent = true;
      let response = await this.service.getapi(createSearch);

      if (response['status'] && response['statusText']) {
        this.isLoadingsourceagent = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.notfounddata = true;
      } else {
        this.SourceagentList = response as any[];
        this.SourceagentList.map(obj => obj.LocationName = `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
        this.selectedsourceagentid = '';
        this.isLoadingsourceagent = false;
        if (response.length == 0) {
          this.notfounddata = true;
        }
        else {
          this.notfounddata = false;
        }
      }
    }
  }

  getSourceAgent(event: any) {
    var val = event;
    if (val != '') {
      this.isLoadingsourceagent = true;
      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=agent&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.SourceagentList = data as any[];
          this.SourceagentList = this.service.transformAlphabetically(this.SourceagentList, 'displayname');
          this.SourceagentList.map(obj => obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}`);
          this.isLoadingsourceagent = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingsourceagent = false;
          this.notfounddata = true;
        }
      });
    }
  }

  searchSourceagentCleared(event) {
    this.isLoadingsourceagent = false;
    this.SourceagentList = [];
    this.selectedsourceagentid = '';
    this.selectedsourceagentname == '';
    this.notfounddata = false;
  }

  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }

  buildtitle(Obj: any) {
    if (typeof (Obj) == "object" && Obj != null) {
      let name = `(${Obj.Type}):${Obj.LocationName}
      ${Obj.Address1}
      `
      return name;
    }
  }
  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]}`
    return value
  }
  reactiveFormmsgtypereprocess: FormGroup;
  reactiveFormmsgtype: FormGroup;
  messageTypeList = <any>[];
  keywordsearch = "displayname";
  keywordsearchreprocess = "displayname";
  isLoadingmsgtype: boolean = false;
  selectedmessagetype: string = '';

  selectedmessagetypeevent(item) {
    this.selectedmessagetype = item.displayname;
  }
  Clearemessagetype(event) {
    this.selectedmessagetype = '';
    this.messageTypeList = this.messageTypeAllList;
  }
  messageTypeAllList: any = [];
  async getMessagetypelist(event: any) {

    this.isLoadingmsgtype = true;
    if (event !== 'msgtype') {
      if (event == undefined) {
        this.messageTypeList = this.messageTypeAllList;
      }
      else {
        if (this.messageTypeList.length == 0) {
          this.messageTypeList = this.messageTypeAllList;
        }
        this.messageTypeList = this.messageTypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageTypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingmsgtype = false;
    }
    else {

      await this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: async data => {
          data.forEach(element => {
            this.messageTypeAllList.push({ displayname: element });
          });
          this.messageTypeList = this.messageTypeAllList;

          this.isLoadingmsgtype = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingmsgtype = false;
          this.notfounddata = true;
        }
      });
    }

  }

  fromdate: any;
  todate: any;
  today = new Date();



  totalmessagecount: string = '0';
  BadmessageList = <any>[];
  tablediv: boolean = false;
  SearchBadMessages() {

    this.BadmessageList = [];
    this.tablediv = false;
    if (this.showSourceTarget) {
      if (this.selectedEntityguid == '' && this.reactiveFormentity.get('entityname').value != '' && this.reactiveFormentity.get('entityname').value != null) {
        let returnvalue = this.isUUID(this.reactiveFormentity.get('entityname').value);
        if (returnvalue == true) {
          this.selectedEntityguid = this.reactiveFormentity.get('entityname').value;
        }
        else {
          this.toastr.warning("Please select valid Entity", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          return;
        }
      }
    }
    else {
      if (this.selectedSourceEntityguid == '' && this.reactiveFormse.get('sename').value != '' && this.reactiveFormse.get('sename').value != null) {
        let returnvalue = this.isUUID(this.reactiveFormse.get('sename').value);
        if (returnvalue == true) {
          this.selectedSourceEntityguid = this.reactiveFormse.get('sename').value;
        }
        else {
          this.toastr.warning("Please select valid Source Entity", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          return;
        }
      }

      if (this.selectedTargetEntityguid == '' && this.reactiveFormde.get('dename').value != '' && this.reactiveFormde.get('dename').value != null) {
        let returnvalue = this.isUUID(this.reactiveFormde.get('dename').value);
        if (returnvalue == true) {
          this.selectedTargetEntityguid = this.reactiveFormde.get('dename').value;
        }
        else {
          this.toastr.warning("Please select valid Target Entity", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          return;
        }
      }
    }

    if (this.selectedsourceagentid == '' && this.reactiveFormsourceagent.get('saname').value != '' && this.reactiveFormsourceagent.get('saname').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormsourceagent.get('saname').value);
      if (returnvalue == true) {
        this.selectedsourceagentid = this.reactiveFormsourceagent.get('saname').value;
      }
      else {
        this.toastr.warning("Please select valid Source Agent", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return;
      }
    }

    if (this.fromdate == '' && this.todate == '') {
      this.fromdate = undefined;
      this.todate = undefined;
    }

    if ((this.fromdate && !this.todate) || (!this.fromdate && this.todate)) {
      this.toastr.error("Select From date & To date ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    if (this.fromdate > this.todate) {
      this.toastr.error("From date should be  equal  or  less than To date ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    var searchdata = {
      "senderagentid": this.selectedsourceagentid,
      "sourceentityid": this.showSourceTarget ? this.selectedEntityguid : this.selectedSourceEntityguid,
      "destinationentityid": this.showSourceTarget && this.selectedEntityguid !== '' ? "-1" : this.selectedTargetEntityguid,
      "messagetype": this.selectedmessagetype,
      "transactionid": this.transactionidvalue,
      "FromDate": this.fromdate == undefined ? '' : this.fromdate + ' 00:00:00',
      "ToDate": this.todate == undefined ? '' : this.todate + ' 23:59:59',
      "format": this.formatfilter
    }
    var apiname = 'api/Badmessage/SearchBadMessages';
    this.ngxUiLoaderService.start();
    this.service.searchpostapi(apiname, searchdata).then(response => {
      if (response['status'] && response['statusText']) {
        this.ngxUiLoaderService.stop();
        this.tablediv = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      }
      else {
        this.ngxUiLoaderService.stop();
        this.BadmessageList = response as any[];
        if (this.BadmessageList.length > 0) {
          this.totalmessagecount = response[0]["totalcount"];
          this.tablediv = true;
        }
        else {
          this.toastr.warning("No results found!!", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      }
    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    })

  }

  reset() {
    this.reactiveFormsourceagent.reset();
    this.selectedsourceagentid = '';
    this.selectedsourceagentname = '';
    this.reactiveFormmsgtype.reset();
    this.selectedmessagetype = '';
    this.fromdate = null;
    this.todate = null;
    this.tablediv = false;
    this.totalmessagecount = '';
    this.BadmessageList = [];
    this.messageTypeList = this.messageTypeAllList;

    this.reactiveFormmsgtype.setValue({ "msgname": '' });
    this.reactiveFormmsgtype.patchValue(this.messageTypeList);
    this.transactionidvalue = '';
    this.reactiveFormformatfilter.reset();
    this.formatfilter = '';
    this.messageTypeListreprocess = this.messageTypeAllListreprocess;
    this.reactiveFormmsgtypereprocess.reset();
    this.reactiveFormmsgtypereprocess.setValue({ "msgnamereprocess": '' });
    this.reactiveFormmsgtypereprocess.patchValue(this.messageTypeListreprocess);
    this.selection.clear();
    if (!this.showSourceTarget) {
      this.showSourceTarget = true;
    }

    this.selectedEntityguid = '';
    this.reactiveFormentity.reset();
    this.reactiveFormentity.setValue({ "entityname": '' });

    this.selectedSourceEntityguid = '';
    this.selectedTargetEntityguid = '';
    this.reactiveFormse.reset();
    this.reactiveFormde.reset();
    this.reactiveFormse.setValue({ "sename": '' });
    this.reactiveFormde.setValue({ "dename": '' });

  }

  async clearpopupvalues() {
    this.transaction = '';
    this.hash = '';
    this.msginputData = '';
    this.badmsgcreateddate = '';
    this.reactiveFormmsgtypereprocess.reset();
    this.selectedmessagetypereprocess = '';
    this.reactiveFormmsgtypereprocess.setValue({ "msgnamereprocess": '' });
    this.reactiveFormsereprocess.reset();
    this.selectedSourceEntityguidreprocess = '';
    this.reactiveFormsereprocess.setValue({ "senamereprocess": '' });
    this.reactiveFormdereprocess.reset();
    this.selectedTargetEntityguidreprocess = '';
    this.reactiveFormdereprocess.setValue({ "denamereprocess": '' });
    this.reactiveFormformatfilterreprocess.reset();
    this.formatfilterreprocess = '';
    this.reactiveFormformatfilterreprocess.setValue({ "formatfilterreprocess": '' });
  }

  transaction: string = '';
  hash: string = '';
  msginputData: any;
  badmsgcreateddate: any;
  async openbadmessagereprocess(dataitem, cntname) {
    this.ngxUiLoaderService.start();
    await this.clearpopupvalues();
    if (dataitem.createddate) {
      this.badmsgcreateddate = dataitem.createddate;
    }
    if (dataitem.transactionid) {
      this.transaction = dataitem.transactionid;
    }
    if (dataitem.hash) {
      this.hash = dataitem.hash;
    }
    if (dataitem.senderagentid) {
      this.selectedsourceagentidreprocess = dataitem.senderagentid;
      this.reactiveFormsourceagentreprocess.patchValue({
        sanamereprocess: dataitem.senderagentid
      });
    }
    if (dataitem.messagetype) {
      this.selectedmessagetypereprocess = dataitem.messagetype;
      this.reactiveFormmsgtypereprocess.patchValue({
        msgnamereprocess: dataitem.messagetype
      });
    }
    if (dataitem.sourceentityid) {
      this.selectedSourceEntityguidreprocess = dataitem.sourceentityid;
      this.reactiveFormsereprocess.patchValue({
        senamereprocess: dataitem.sourceentityid
      });
    }
    if (dataitem.destinationentityid) {
      this.selectedTargetEntityguidreprocess = dataitem.destinationentityid;
      this.reactiveFormdereprocess.patchValue({
        denamereprocess: dataitem.destinationentityid
      });
    }
    if (dataitem.format) {
      this.formatfilterreprocess = dataitem.format;
      this.reactiveFormformatfilterreprocess.patchValue({
        formatfilterreprocess: dataitem.format
      });
    }
    if (dataitem.messagebody) {
      this.msginputData = dataitem.messagebody;
    }

    this.modalService.open(cntname, { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false }).result.then((result) => { });
    this.ngxUiLoaderService.stop();
    let fotmattype = "plaintext";
    if (this.IsValidXML(this.msginputData)) {
      fotmattype = "xml";
    }
    else if (this.service.IsJsonString(this.msginputData)) {
      fotmattype = "json";
    }
    else {
      fotmattype = "hl7";
    }
    this.editorOptions = { ...this.editorOptions, language: fotmattype }
  }

  badmsgreprocess(c) {
    if (this.transaction == "") {
      this.toastr.warning('Transaction is mandatory.', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return;
    }
    if (this.hash == "") {
      this.toastr.warning('Hash is mandatory.', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return;
    }
    if (this.selectedsourceagentidreprocess == "") {
      this.toastr.warning('Sender Agent is mandatory.', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return;
    }
    if (this.selectedmessagetypereprocess == "") {
      this.toastr.warning('Message Type is mandatory.', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return;
    }
    if (this.selectedSourceEntityguidreprocess == "") {
      this.toastr.warning('Source Entity is mandatory.', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return;
    }
    if (this.selectedTargetEntityguidreprocess == "") {
      this.toastr.warning('Target Entity is mandatory.', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return;
    }
    if (this.formatfilterreprocess == "") {
      this.toastr.warning('Message Format is mandatory.', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return;
    }
    if (this.msginputData == "") {
      this.toastr.warning('Input Data is mandatory.', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return;
    }
    this.messagereprocessdata = [];
    var reprocessdata = {
      "transactionid": this.transaction,
      "hash": this.hash,
      "senderagentid": this.selectedsourceagentidreprocess,
      "messagetype": this.selectedmessagetypereprocess,
      "sourceentityid": this.selectedSourceEntityguidreprocess,
      "destinationentityid": this.selectedTargetEntityguidreprocess,
      "format": this.formatfilterreprocess,
      "messagebody": this.msginputData,
      "id":this.idview
    }
    this.messagereprocessdata.push(reprocessdata);
    var apiname = 'api/Badmessage/ReprocessBadMessage';
    this.ngxUiLoaderService.start();
    this.service.searchpostapi(apiname, this.messagereprocessdata).then(response => {
      if (response['status'] && response['statusText']) {
        this.ngxUiLoaderService.stop();
        this.tablediv = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        this.ngxUiLoaderService.stop();
        this.SearchBadMessages();
        this.closebadmessagepopup(c);
        this.toastr.success('Reprocessed successfully', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    });

  }

  async closebadmessagepopup(c) {
    await this.clearpopupvalues();
    c('close modal');
  }

  formatType: string = "plaintext";
  editorOptions: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: false,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };

  editor: MonacoStandaloneCodeEditor;
  editorInit(data, editor: MonacoStandaloneCodeEditor) {
    this.editor = editor;
    let formatType;
    editor.onDidChangeCursorPosition(() => {
      if (this.IsValidXML(data)) {
        formatType = "xml";
      }
      else if (this.service.IsJsonString(data)) {
        formatType = "json";
      }
      else {
        formatType = "hl7";
      }
      this.editorOptions = { ...this.editorOptions, language: formatType }
    });
  }


  IsValidXML(data) {
    let xmlDocument: any;
    let isValid = false;
    try {
      xmlDocument = (new DOMParser()).parseFromString(data, 'text/xml');
      isValid = true;
    }
    catch {
      xmlDocument = "";
      isValid = false;
    }
    return isValid;
  }

  showSourceTarget: boolean = false;
  reactiveFormentity: FormGroup;
  EntityNameList: any = [];
  keywordsearcha = "LocationName";
  keywordsearchareprocess = "LocationName";
  isLoadingentity: boolean = false;
  selectedEntityguid: string = "";
  selectedEntityName: string = "";

  selectedEntity(item) {
    this.selectedEntityguid = item.LocationGUID;
    this.selectedEntityName = item.LocationName;
  }
  searchEntityCleared() {
    this.isLoadingentity = false;
    this.EntityNameList = [];
    this.selectedEntityguid = '';
    this.notfounddata = false;
  }

  async getEntityNames(event: any) {
    var valuesa = event;
    if (valuesa != '') {
      this.isLoadingentity = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;
      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingentity = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.EntityNameList = response;
        this.EntityNameList = this.service.transformAlphabetically(this.EntityNameList, 'LocationName');
        this.EntityNameList.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedEntityguid = '';
        this.isLoadingentity = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      }
    }
  }

  reactiveFormde: FormGroup;
  reactiveFormse: FormGroup;
  SourceEntityNameList: any = [];
  isLoadingse: boolean = false;
  selectedSourceEntityguid: string = "";
  selectedSourceEntityName: string = "";

  selectedSourceEntity(item) {
    this.selectedSourceEntityguid = item.LocationGUID;
    this.selectedSourceEntityName = item.LocationName;
  }

  searchSourceEntityCleared(event) {
    this.isLoadingse = false;
    this.SourceEntityNameList = [];
    this.selectedSourceEntityguid = '';
    this.notfounddata = false;
  }

  async getSourceEntityNames(event: any) {

    var valuesa = event;
    if (valuesa != '') {

      this.isLoadingse = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingse = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.SourceEntityNameList = response;
        this.SourceEntityNameList = this.service.transformAlphabetically(this.SourceEntityNameList, 'LocationName');
        this.SourceEntityNameList.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedSourceEntityguid = '';
        this.isLoadingse = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      }
    }

  }
  TargetEntityNameList: any = [];
  selectedTargetEntityguid: string = "";
  selectedTargetEntityName: string = "";
  isLoadingde: boolean = false;

  selectedTargetEntity(item) {
    this.selectedTargetEntityguid = item.LocationGUID;
    this.selectedTargetEntityName = item.LocationName;
  }
  searchTargetEntityCleared(event) {
    this.isLoadingde = false;
    this.TargetEntityNameList = [];
    this.selectedTargetEntityguid = '';
    this.notfounddata = false;
  }

  async getTargetEntityNames(event: any) {
    var valueta = event;
    if (valueta != '') {

      this.isLoadingde = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueta;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingde = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.TargetEntityNameList = response as any[];
        this.TargetEntityNameList = this.service.transformAlphabetically(this.TargetEntityNameList, 'LocationName');
        this.TargetEntityNameList.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedTargetEntityguid = '';
        this.isLoadingde = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }

      }
    }
  }


  isUUID(uuid) {
    let s;
    s = uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }

  keyword: string = 'name';
  reactiveFormformatfilter: FormGroup;
  public dropdownList = [
    {
      name: '.hl7',
    },
    {
      name: '.xml',
    },
    {
      name: '.json',
    },
    {
      name: '.dot',
    },
    {
      name: '.pdf',
    },
    {
      name: '.csv',
    }
  ];

  onFocused(e) {
    this.dropdownList = [
      {
        name: '.hl7',
      },
      {
        name: '.xml',
      },
      {
        name: '.json',
      },
      {
        name: '.dot',
      },
      {
        name: '.pdf',
      }, {
        name: '.csv',
      }
    ];


  }
  formatfilter: string = '';
  async selectEvent(item) {
    this.formatfilter = item.name;
  }

  checkboxLabel(row?: any) {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    let ui = `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    return ui
  }

  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.BadmessageList.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.BadmessageList.forEach(row => this.selection.select(row));
  }

  checkeddata: any = [];

  exportreport() {
    let csvData;
    let filename = 'data.csv';
    const data = [];

    if (this.selection.selected.length > 0) {
      this.checkeddata = this.selection.selected;
      this.checkeddata.forEach(element => {
        data.push({
          Id: element.id,
          Transactionid: element.transactionid,
          Sourceagentid: element.senderagentid,
          Sourceentityid: element.sourceentityid,
          Destinationentityid: element.destinationentityid,
          MessageType: element.messagetype,
          Createddate: element.createddate,
          Format: element.format,
          Reason: element.badmessagereason
        })
      });
    }
    else {
      this.BadmessageList.forEach(element => {
        data.push({
          Id: element.id,
          Transactionid: element.transactionid,
          Sourceagentid: element.senderagentid,
          Sourceentityid: element.sourceentityid,
          Destinationentityid: element.destinationentityid,
          MessageType: element.messagetype,
          Createddate: element.createddate,
          Format: element.format,
          Reason: element.badmessagereason
        })
      });
    }
    if (data.length > 0) {

      csvData = this.ConvertToCSVmessagelist(
        data,
        ['ID', 'Transactionid', 'Sourceagentid', 'Sourceentityid', 'Destinationentityid', 'MessageType', 'Createddate', 'Format', 'Reason']);

      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);

      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename);
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
    else {
      this.toastr.warning("There is no data!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  ConvertToCSVmessagelist(objArray, headerscus) {
    let items = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let separator = ',';
    const columns = Object.keys(items[0]).join(separator);
    const body = items.map(item =>
      Object.values(item).join(separator)
    ).join('\n');
    if (headerscus == '') {
      return columns + '\n' + body;
    }
    else {
      return headerscus + '\n' + body;
    }
  }

  copyToClip(event: any, message: string) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
  }

  isLoadingmsgtypereprocess: boolean = false;
  messageTypeListreprocess: any = [];
  messageTypeAllListreprocess: any = [];
  async getMessagetypelistreprocess(event: any) {

    this.isLoadingmsgtypereprocess = true;
    if (event !== 'msgtype') {
      if (event == undefined) {
        this.messageTypeListreprocess = this.messageTypeAllListreprocess;
      }
      else {
        if (this.messageTypeListreprocess.length == 0) {
          this.messageTypeListreprocess = this.messageTypeAllListreprocess;
        }
        this.messageTypeListreprocess = this.messageTypeListreprocess.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageTypeListreprocess.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingmsgtypereprocess = false;
    }
    else {
      await this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: async data => {
          data.forEach(element => {
            this.messageTypeAllListreprocess.push({ displayname: element });
          });
          this.messageTypeListreprocess = this.messageTypeAllListreprocess;
          this.isLoadingmsgtypereprocess = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingmsgtypereprocess = false;
          this.notfounddata = true;
        }
      });
    }
  }

  selectedmessagetypereprocess: string = '';
  selectedmessagetypeeventreprocess(item) {
    this.selectedmessagetypereprocess = item.displayname;
  }

  Clearemessagetypereprocess(event) {
    this.selectedmessagetypereprocess = '';
    this.messageTypeListreprocess = this.messageTypeAllListreprocess;
  }


  reactiveFormsereprocess: FormGroup;
  SourceEntityNameListreprocess: any = [];
  isLoadingsereprocess: boolean = false;
  selectedSourceEntityguidreprocess: string = "";
  selectedSourceEntityNamereprocess: string = "";

  selectedSourceEntityreprocess(item) {
    this.selectedSourceEntityguidreprocess = item.LocationGUID;
    this.selectedSourceEntityNamereprocess = item.LocationName;
  }

  searchSourceEntityClearedreprocess(event) {
    this.isLoadingsereprocess = false;
    this.SourceEntityNameListreprocess = [];
    this.selectedSourceEntityguidreprocess = '';
    this.notfounddata = false;
  }

  async getSourceEntityNamesreprocess(event: any) {
    var valuesa = event;
    if (valuesa != '') {
      this.isLoadingsereprocess = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;
      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingsereprocess = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.SourceEntityNameListreprocess = response;
        this.SourceEntityNameListreprocess = this.service.transformAlphabetically(this.SourceEntityNameListreprocess, 'LocationName');
        this.SourceEntityNameListreprocess.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedSourceEntityguidreprocess = '';
        this.isLoadingsereprocess = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      }
    }
  }

  reactiveFormdereprocess: FormGroup;
  TargetEntityNameListreprocess: any = [];
  selectedTargetEntityguidreprocess: string = "";
  selectedTargetEntityNamereprocess: string = "";
  isLoadingdereprocess: boolean = false;

  selectedTargetEntityreprocess(item) {
    this.selectedTargetEntityguidreprocess = item.LocationGUID;
    this.selectedTargetEntityNamereprocess = item.LocationName;
  }

  searchTargetEntityClearedreprocess(event) {
    this.isLoadingdereprocess = false;
    this.TargetEntityNameListreprocess = [];
    this.selectedTargetEntityguidreprocess = '';
    this.notfounddata = false;
  }

  async getTargetEntityNamesreprocess(event: any) {
    var valueta = event;
    if (valueta != '') {
      this.isLoadingdereprocess = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueta;
      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingdereprocess = false;
        this.toastr.error("Something Went Wrong", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.TargetEntityNameListreprocess = response as any[];
        this.TargetEntityNameListreprocess = this.service.transformAlphabetically(this.TargetEntityNameListreprocess, 'LocationName');
        this.TargetEntityNameListreprocess.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedTargetEntityguidreprocess = '';
        this.isLoadingdereprocess = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      }
    }
  }

  reactiveFormformatfilterreprocess: FormGroup;
  public dropdownListreprocess = [
    {
      name: '.hl7',
    },
    {
      name: '.xml',
    },
    {
      name: '.json',
    },
    {
      name: '.dot',
    },
    {
      name: '.pdf',
    },
    {
      name: '.csv',
    }
  ];

  onFocusedreprocess(e) {
    this.dropdownListreprocess = [
      {
        name: '.hl7',
      },
      {
        name: '.xml',
      },
      {
        name: '.json',
      },
      {
        name: '.dot',
      },
      {
        name: '.pdf',
      }, {
        name: '.csv',
      }
    ];


  }

  formatfilterreprocess: string = '';
  async selectEventreprocess(item) {
    this.formatfilterreprocess = item.name;
  }

  clearedstatus(item) {
    this.formatfilter = '';
  }

  clearedstatusreprocess(item) {
    this.formatfilterreprocess = '';
  }

  reactiveFormsourceagentreprocess: FormGroup;
  SourceagentListreprocess: any = [];
  keywordagentreprocess: string = 'displayname';
  selectedsourceagentidreprocess: string = '';
  selectedsourceagentnamereprocess: string = '';
  isLoadingsourceagentreprocess: boolean = false;

  selectedsourceagentEventreprocess(item) //source agent selected Event
  {
    this.selectedsourceagentidreprocess = item.id;
    this.selectedsourceagentnamereprocess = item.displayname;
  }

  async getSourceAgentreprocess(event: any) {

    var val = event;
    if (val != '') {
      this.isLoadingsourceagentreprocess = true;
      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=agent&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.SourceagentListreprocess = data as any[];
          this.SourceagentListreprocess = this.service.transformAlphabetically(this.SourceagentListreprocess, 'displayname');
          this.SourceagentListreprocess.map(obj => obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}`);
          this.isLoadingsourceagentreprocess = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingsourceagentreprocess = false;
          this.notfounddata = true;
        }
      });
    }

  }

  searchSourceagentClearedreprocess(event) {
    this.isLoadingsourceagentreprocess = false;
    this.SourceagentListreprocess = [];
    this.selectedsourceagentidreprocess = '';
    this.selectedsourceagentnamereprocess == '';
    this.notfounddata = false;
  }
  messagereprocessdata: any = [];
  reprocessSelected() {
    if (this.selection.selected.length > 0) {
      this.checkeddata = this.selection.selected;
      this.messagereprocessdata = [];

      this.checkeddata.forEach(element => {
        var dataCons = {
          "transactionid": element.transactionid,
          "hash": element.hash,
          "senderagentid": element.senderagentid,
          "messagetype": element.messagetype,
          "sourceentityid": element.sourceentityid,
          "destinationentityid": element.destinationentityid,
          "format": element.format,
          "messagebody": element.messagebody,
          "id":element.id
        }
        this.messagereprocessdata.push(dataCons);
      });

      this.ngxUiLoaderService.start();
      var apiname = 'api/Badmessage/ReprocessBadMessage';
      this.ngxUiLoaderService.start();
      this.service.searchpostapi(apiname, this.messagereprocessdata).then(response => {
        if (response['status'] && response['statusText']) {
          this.ngxUiLoaderService.stop();
          this.tablediv = false;
          this.toastr.error("Something Went Wrong!", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          this.ngxUiLoaderService.stop();
          this.selection.clear();
          this.SearchBadMessages();
          this.toastr.success('Reprocessed successfully', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Something Went Wrong!!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      })
    }
  }

  Transactionview: string = '';
  Hashview: string = '';
  sendaragentview: string = '';
  sourceentityview: string = '';
  targententityview: string = '';
  idview: string = '';
  messagetypeview: string = '';
  messageformatview: string = '';
  msginputDataview: any;
  msgheadersDataview: any;
  clearpopupviewvalues() {
    this.Transactionview = '';
    this.Hashview = '';
    this.sendaragentview = '';
    this.sourceentityview = '';
    this.targententityview = '';
    this.messagetypeview = '';
    this.messageformatview = '';
    this.msginputDataview = '';
    this.msgheadersDataview = '';
  }
  openreprocesspopup(cntname) {
    this.openbadmessagereprocess(this.holddataitemvalue, cntname)
  }
  holddataitemvalue: any;
  async viewbadmessagereprocess(dataitem, cntview) {
    this.ngxUiLoaderService.start();
    this.holddataitemvalue = '';
    this.holddataitemvalue = dataitem;
    await this.clearpopupviewvalues();
    if (dataitem.header) {
      this.msgheadersDataview =dataitem.header;
    }

    if (dataitem.transactionid) {
      this.Transactionview = dataitem.transactionid;
    }
    if (dataitem.hash) {
      this.Hashview = dataitem.hash;
    }
    if (dataitem.senderagentid) {
      this.sendaragentview = dataitem.senderagentid;
    }
    if (dataitem.messagetype) {
      this.messagetypeview = dataitem.messagetype;
    }
    if (dataitem.sourceentityid) {
      this.sourceentityview = dataitem.sourceentityid;
    }
    if (dataitem.destinationentityid) {
      this.targententityview = dataitem.destinationentityid;
    }
    if (dataitem.format) {
      this.messageformatview = dataitem.format;
    }
    if (dataitem.messagebody) {
      this.msginputDataview =beautify(dataitem.messagebody);
    }
    if (dataitem.id) {
      this.idview =dataitem.id;
    }

    this.modalService.open(cntview, { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false }).result.then((result) => { });
    this.ngxUiLoaderService.stop();
    let fotmattype = "plaintext";
    if (this.IsValidXML(this.msginputDataview)) {
      fotmattype = "xml";
    }
    else if (this.service.IsJsonString(this.msginputDataview)) {
      fotmattype = "json";
    }
    else {
      fotmattype = "hl7";
    }
    this.editorOptionsview = { ...this.editorOptionsview, language: fotmattype }
  }

  editorOptionsview: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };

  editorInitview(data, editor: MonacoStandaloneCodeEditor) {
    this.editor = editor;
    let formatType;
    editor.onDidChangeCursorPosition(() => {
      if (this.IsValidXML(data)) {
        formatType = "xml";
      }
      else if (this.service.IsJsonString(data)) {
        formatType = "json";
      }
      else {
        formatType = "hl7";
      }
      this.editorOptionsview = { ...this.editorOptionsview, language: formatType }
    });
  }

  editorOptionsheadersview: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: "json",
    roundedSelection: true,
    autoIndent: "full",
    readOnly: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };

  editorInitheadersview(data, editor: MonacoStandaloneCodeEditor) {
    this.editor = editor;
    editor.onDidChangeCursorPosition(() => {
      this.editorOptionsheadersview = { ...this.editorOptionsheadersview, language: "json" }
    });
  }
}

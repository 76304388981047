import { Component, OnInit } from "@angular/core";
import { CommonService } from "../services/common.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
declare const copyToClipboard: any;
declare var $
@Component({
  selector: "app-diagnosis",
  templateUrl: "./diagnosis.component.html",
  styleUrls: ["./diagnosis.component.scss"],
})
export class DiagnosisComponent implements OnInit {
  DiagnosticList: any[] = [];
routeGuid ="";
DisplaynameRoute=''
  constructor(
    private commonService: CommonService,
    private ngxuiloader: NgxUiLoaderService,
    private activeModalService:NgbActiveModal,
    private toastr: ToastrService,
  ) {}
  ngOnInit() {
    this.getDiagnostic();
  }
  checkStatus(connectivity: any) {
    if (connectivity !== undefined) {
      let val = connectivity.LableValue.filter(
        (value) => value.status === false
      );
      if (val.length > 0) {
        connectivity["status"] = "Failed";
      } else {
        connectivity["status"] = "Success";
      }
    }
  }

  getDiagnostic() {
    this.ngxuiloader.start();
    this.commonService.getDaignostics(this.routeGuid).subscribe(data => {
this.ngxuiloader.stop()
        if (data) {
          data.forEach((element) => {
            this.checkStatus(element);
          });
          this.DiagnosticList = data;
        }
      },
      error => {
        console.error();
        this.ngxuiloader.stop();
        this.toastr.error(error.statusText, "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    );
  }

  closedestfolpopup(){
    this.activeModalService.close();
  }

  copyToClip(event: any, message: string) {
    if (event != "" && event != undefined) {
      copyToClipboard(event);
      this.toastr.success(message, "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }
}

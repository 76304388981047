import { Injectable } from '@angular/core';
import * as configdata from "../../assets/config/config.json"

@Injectable()
export class AppConfigService {
    static settings: IAppConfig;

    xml: any;
    xmlrespose: any;
    dataarray: any;

    constructor() {
        AppConfigService.settings = <IAppConfig>configdata['default'];
    }

    load() {
        return AppConfigService.settings = <IAppConfig>configdata['default'];
    }
}
export interface IAppConfig {
  UserLogin: any,
  WebApiURL: any,
  RouteNameSuggest: any,
}

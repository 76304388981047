import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultGlobalConfig, ToastrService } from 'ngx-toastr';
import { Observable, AsyncSubject } from 'rxjs';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { of as observableOf } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { UserSession } from 'src/app/services/user.session';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InterfacemappingComponent } from 'src/app/interfacemapping/interfacemapping.component';
import { VarulesComponent } from 'src/app/varules/varules.component';
import { MessageComponent } from 'src/app/message/message.component';
import { FileViewExplorerComponent } from 'src/app/file-view-explorer/file-view-explorer.component';
import { isNull } from 'util';
import { data } from 'highcharts';
import { CommonService } from 'src/app/services/common.service';
declare const copyToClipboard: any;
declare var $;

export interface SelectedFiles {
  name: string;
  file: any;
  base64?: string;
}
@Component({
  selector: 'app-view-button',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})


export class ViewComponent implements OnInit {


  panelOpenState = false;

  treeControl = new FlatTreeControl<TreeNode>(
    node => node.level,
    node => node.expandable,
  );
  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

  treeFlattener: MatTreeFlattener<FileNode, TreeNode>;

  FTPFilesList: MatTreeFlatDataSource<FileNode, TreeNode>;


  @ViewChild(MatPaginator, { static: false }) paginatorMat!: MatPaginator;
  Obese!: Observable<any>;
  // dataSource = new MatTableDataSource<any[]>([]);
  base64output : string;
  interfaceSequence: any[] = [];
  jobstatus:any;
  isReadMore = false;
  handlerData: any[] = [];
  rulesData: any[] = [];
  transaction: any[] = [];
  HubView:any={};
  interfaceDetails: any;
  foldersList: any[] = [];
  Interface!: Observable<any>;
  descriptionArrayList: any[] = [];
  activeNode: any;
  searchForm: FormGroup = this._formBuilder.group({
    search: '',
  })


  handleData =
    [
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "10954",
        "lookupId": "33474",
        "id": 2
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "10912",
        "lookupId": "44534",
        "id": 3
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "10959",
        "lookupId": "44537",
        "id": 4
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "PDX5325",
        "lookupId": "44540",
        "id": 5
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "PDX5495",
        "lookupId": "44845",
        "id": 6
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "PDX5730",
        "lookupId": "45374",
        "id": 7
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "PDX5735",
        "lookupId": "45375",
        "id": 8
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "PDX5736",
        "lookupId": "45376",
        "id": 9
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "PDX5729",
        "lookupId": "45377",
        "id": 10
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "PDX5732",
        "lookupId": "45378",
        "id": 11
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "PDX5731",
        "lookupId": "45381",
        "id": 12
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "PDX5728",
        "lookupId": "45382",
        "id": 13
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "11533",
        "lookupId": "46639",
        "id": 14
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "11533",
        "lookupId": "21155",
        "id": 15
      },
      {
        "handler": "P42GSU-Results-Outbound",
        "lookupType": "LocationCodeid",
        "lookupValue": "PDX31",
        "lookupId": "26191",
        "id": 552
      }
    ]

  Auditarr =[]
    // [
    //   {
    //     "Date": Date.now(),
    //     "ModifiedBy": "Pavan potadar",
    //     'character': '',
    //     "Changes": " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
    //   },
    //   {
    //     "Date": Date.now(),
    //     "ModifiedBy": "Aleem Nasiyathulla",
    //     'character': '',
    //     "Changes": " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
    //   },
    //   {
    //     "Date": Date.now(),
    //     "ModifiedBy": "Aishwarya Narayanan",
    //     'character': '',
    //     "Changes": " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
    //   },
    //   {
    //     "Date": Date.now(),
    //     "ModifiedBy": "Jyothiswaroop",
    //     'character': '',
    //     "Changes": " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
    //   },
    // ]

  searchBar: any;
  screenHeightView: number = 0;
  screenHeightViewaudit: number = 0;
  screenHeightViewE: number = 0;
  nameid:any;
  fileexplorerheight: number = 0;
  childfiles: any = []
  Engine:any;
  auditinterface: any;
  src :any;
  backupfolders: any;
  filehistory: any = [];
  isCard: boolean = true;
  listViewHieght: number = 0;
  cardViewHieght: number = 0;
  isNOfolder: boolean = false;
  sequence: any = []
  constructor(private actr: ActivatedRoute, private modalService: NgbModal, private usersession: UserSession, private ngxuiloader: NgxUiLoaderService, private toastr: ToastrService, private commonService: CommonService, private _formBuilder: FormBuilder, private router: Router) {
    this.treeFlattener = new MatTreeFlattener<FileNode, TreeNode>(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);
    this.FTPFilesList = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  gettransaction() {
    // this.ngxuiloader.start()
    if(this.interfaceDetails.Engine.toUpperCase().includes('HUB'))
    {
      this.nameid=this.interfaceDetails['InterfaceGroupId']
      this.Engine='HUB';
      this.commonService.messagesData = JSON.stringify( {
        name: this.nameid,
         engine:this.Engine,
         deployment: this.interfaceDetails.Deployment
       })
       this.router.navigate(['/home/messages']);
    }
    else
    {
      this.gettransaction_data(this.interfaceDetails.InterfaceName,'mirth',this.interfaceDetails.Deployment)
      // this.nameid=this.interfaceDetails.InterfaceName;
      // this.Engine='mirth';

    }


  }

  getHubViewDetails(value) {

    this.ngxuiloader.start()
    this.commonService.getHubView(value).subscribe(data =>{
      this.ngxuiloader.stop()
      if(data && data.CORECONFIG !== null ){

        this.HubView = JSON.parse(data.CORECONFIG);
       // //console.log(this.HubView)
      }
    },error =>{
    this.ngxuiloader.stop()
    this.toastr.error('Request Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
    })
  }
  gettransaction_data(name,engine,deployment){
    this.ngxuiloader.start()
      this.commonService.getTransactions(name,engine,deployment ).subscribe(data =>{
        this.ngxuiloader.stop()
        if(data.length>0){

  // this.messageList = data;
  let obj={
    name:name,
    MessageDetails :data,
    engine:engine,
    deployment:deployment
  }
  this.commonService.messagesData =JSON.stringify(obj);
  this.router.navigate(['/home/messages']);
          // const modalRef = this.modalService.open(MessageComponent, {
          //   windowClass: "myCustomModalClass",
          //   backdrop: "static",
          //   keyboard: false
          // });

          // modalRef.componentInstance.messageData =modaldata;
          // modalRef.result.then(() => { });
        }else{
          this.ngxuiloader.stop()
        this.toastr.warning('No data found', 'Alert!', { positionClass: 'toast-bottom-right', timeOut: 4000 });
        }
    },erro =>{
      this.ngxuiloader.stop();
      this.toastr.error('Request Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
    })
    }
  ngOnInit() {
    this.screenHeightView = window.innerHeight - 228;
    this.screenHeightViewE = window.innerHeight;
    this.screenHeightViewaudit = window.innerHeight - 125;
    this.fileexplorerheight = window.innerHeight - 40;
    this.listViewHieght = window.innerHeight - 204;
    this.cardViewHieght = window.innerHeight - 168;
    this.commonService.messagesData ='';
    this.actr.data.pipe(
      map((res) => res)
    ).subscribe(data => {
      if (data['cres'].VAInterfaceModels.InterfaceName !== null) {
        this.interfaceDetails = data['cres'].VAInterfaceModels;
        this.sequence = data['cres'].VAInterfaceModels.Sequence;
        // this.foldersList = data['cres'].InterfaceDetails.Folders;
        this.auditpopup(data['cres'].VAInterfaceModels);
      this.getHubViewDetails(data['cres'].VAInterfaceModels.InterfaceGroupId);
      // this.getHubViewDetails('F0FFA7C9-4CB9-4C2B-AEC7-7EF252B90E0A');
        this.interfaceSequence = this.getsequence(data['cres'], data['cres'].VAInterfaceModels.Sequence);
        this.jobstatus=data['cres'].JobsRunningStatus;
        if (this.interfaceSequence !== undefined) {
          this.interfaceSequence.forEach(dat => dat['isReadMore']= false);
        }
        this.descriptionArrayList = this.usersession.description;
      } else {
        this.toastr.warning("Interface Data is Empty", '', { positionClass: 'toast-bottom-right', timeOut: 2000 });
        this.back();
      }
    },error =>{
      this.ngxuiloader.stop()
      this.toastr.error('Request Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
    })

  }
  auditpopup(obj: any) {

    this.auditinterface = obj.InterfaceName;
    // this.commonService.getAudit(obj.InterfaceIdentifier, obj.Engine, obj.Deployment).subscribe(audit => {
    //   if (audit) {
    //     this.Auditarr = audit;
    //     this.Auditarr.forEach(ele => {
    //       ele['character'] = this.getInitials(ele.UserName.split("@")[0]).toUpperCase()
    //     })
    //   }
    // }
    this.commonService.getHubAudit(obj.InterfaceId).subscribe(audit => {
      if (audit) {
        this.Auditarr = audit;
        this.Auditarr.forEach(ele => {
          ele['character'] = this.getInitials(ele.UserName.split("@")[0]).toUpperCase()
        })
      }
    },error =>{
      this.ngxuiloader.stop()
      this.toastr.error('Request Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
    })

  }
  mappings(InterfaceName: string) {
    this.ngxuiloader.start()
    this.commonService.getHandlesData(InterfaceName).subscribe(data => {
      this.ngxuiloader.stop()
      if (data) {
        this.handlerData = data
        // this.isNOfolder = false;
      }
    }, error =>{
      this.ngxuiloader.stop()
      this.toastr.error('Request Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
    })
  }

  jobinfo()
  {
    return ` Message        : ${this.jobstatus.Message}
       JobLastRanTime : ${this.jobstatus.JobLastRunTime}
       NextJobRunTime : ${this.jobstatus.NextJobRunTime}
       Note           : ${this.jobstatus.Note} `
  }

  rules(InterfaceName: string) {

  }

  //////////////
  openExplorer() {
    this.ngxuiloader.start()
    // this.isNOfolder = true;
    this.commonService.getFolders(this.interfaceDetails['InterfaceId']).subscribe(data => {
      this.ngxuiloader.stop()
      if (data) {
        // this.isNOfolder = false;

        this.FTPFilesList.data = this.createGuid([data]);
        this.getFolderdata()
      }
    }, error => {
      this.toastr.warning('No data found', 'Alert!', { positionClass: 'toast-bottom-right', timeOut: 4000 });
      // this.isNOfolder = true;
      this.ngxuiloader.stop()

    })
  }
  getFolderdata() {
    $("#exampleModalRight").appendTo('body').modal('show');
    this.filediv(this.FTPFilesList.data[0])
  }



  public dataToModal: any;
  openinterfacemapping(content) {
    this.ngxuiloader.start()
    this.commonService.getHandlesData(this.interfaceDetails.InterfaceName).subscribe(data => {
      this.ngxuiloader.stop()
      if (data.length>0) {
        // this.isNOfolder = false;
        let obj={
          data: data,
          name:this.interfaceDetails.InterfaceName,
          isflag:"ciis"
        }
        const modalRef = this.modalService.open(InterfacemappingComponent, {
          windowClass: "myCustomModalClass",
          backdrop: "static",
          keyboard: false
        });
        modalRef.componentInstance.inputDataInModalComponent =  obj ;
        modalRef.result.then(() => { });
      }else{
        this.toastr.warning('No data found', 'Alert!', { positionClass: 'toast-bottom-right', timeOut: 4000 });
      }
    },error =>{
      this.ngxuiloader.stop()
      this.toastr.error('Request Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
    })
  }


  createGuid(data: any) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].foldername !== undefined) {
        data[i]["guid"] = uuidv4();
        data[i].expanded = false;
        if (data[i].subfolders.length > 0) {
          this.createGuid(data[i].subfolders)
        }
      }
    }
    return data
  }

  folderclick(filesData: any) {
    let files = JSON.parse(JSON.stringify(filesData))
    files.level = files.level === 0 ? 1 : files.level
    let index = files.level - 1
    this.filehistory.splice(index, this.filehistory.length - index);
    this.filehistory[index] = files
    if (files.subfolders !== undefined && files.filelists.length > 0) {
      this.childfiles = [...files.subfolders, ...files.filelists]
    } else if (files.subfolders == undefined && files.filelists.length > 0) {
      this.childfiles = [...files.filelists]
    } else if (files.subfolders !== undefined && files.filelists.length == 0) {
      this.childfiles = [...files.subfolders]
    } else {
      this.childfiles = []
    }
    if (this.childfiles !== undefined) {
      this.backupfolders = JSON.parse(JSON.stringify(this.childfiles))
    }
  }

  openvarulepopup() {
    var dpkeyname = '';
    this.ngxuiloader.start()
    this.commonService.getRulesData(this.interfaceDetails.InterfaceName).subscribe(data => {
      this.ngxuiloader.stop()
      if (data.length>0) {
        let obj={
          data:data,
          name:this.interfaceDetails.InterfaceName,
          isflag:'ciis'
        }
        this.rulesData = data

        const modalRef = this.modalService.open(VarulesComponent,
          { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
        modalRef.componentInstance.inputDataInModalComponent = obj;
        modalRef.result.then(() => { });
      }else{
      this.toastr.warning('No data found', 'Alert!', { positionClass: 'toast-bottom-right', timeOut: 4000 });
      }
    },error =>{
      this.ngxuiloader.stop()
      this.toastr.error('Request Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
    })
    // if (this.interfacetype == "Inbound") {
    //   dpkeyname = this.targetdeployementkey;
    // }
    // else {
    //   dpkeyname = this.sourcedeployementkey;
    // }

    // let dataToruleModal = {
    //   routename: this.selectedrouteDisplayName,
    //   deploymentkey: dpkeyname
    // };
    // if (this.sourcetypedisplay == 'O') {
    //   dataToruleModal["LabOrgId"] = this.selectedsourceaccountid;
    // }
    // else if (this.sourcetypedisplay == 'A') {
    //   dataToruleModal["LabId"] = this.selectedsourceaccountid;
    // }

    // if (this.targettypedisplay == 'O') {
    //   dataToruleModal["OrdFacOrgId"] = this.selectedtargetaccountid;
    // }
    // else if (this.targettypedisplay == 'A') {
    //   dataToruleModal["OrdFacilityId"]  = this.selectedtargetaccountid;
    // }
    // else if (this.targettypedisplay == 'F') {
    //   dataToruleModal["OrdFacilityId"]  = this.selectedtargetaccountid;
    // }

  }

  searchfile(arr: any, searchinput: any) {
    for (var i of arr) {
      if (i.foldername.includes(searchinput)) {
        return this.filediv(i);
      }
    }
    if (i) {
      this.searchfile(i.subfolders, searchinput);
    }
  }
  // fileview()
  // {
  //   $("#exampleModalFile").modal('show');
  // }
 newobjs =[]
  getdateArray(filedata:any,fdate:any ,tdate:any){

    if(filedata){
       let newarray=[];
      if(filedata.filelists.length>0){
        newarray = filedata.filelists.filter(va =>
          va.lastModifiedDate.split("T")[0] >= fdate && tdate >= va.lastModifiedDate.split("T")[0] );
      }
      this.newobjs = [...newarray,...this.newobjs]
      ////console.log(this.newobjs);
      if(filedata.subfolders){
        this.getdateArray(filedata.subfolders[0],fdate,tdate);
      }
    }
    return this.newobjs;
  }
  filediv(filesData: any) {
    let paths = this.getAncestors(this.FTPFilesList.data, filesData.guid);
    // //console.log(paths);
    // if(filesData.filename)
    // {
    //   this.src=filesData.fullname;
    //   //console.log(this.src);
    //   this.fileview();
    // }
    if(paths.length>0){
      let files = paths[paths.length - 1];
      this.treeControl.collapseAll()
      for (let k = 0; k < paths.length; k++) {
        let indd = this.treeControl.dataNodes.findIndex(va => va.guid === paths[k].guid)
        this.treeControl.expand(this.treeControl.dataNodes[indd])
      }

      let index = files.level;
      this.filehistory.splice(index, this.filehistory.length - index);
      this.filehistory = paths
      if (files.subfolders !== undefined && files.filelists.length > 0) {
        this.childfiles = [...files.subfolders, ...files.filelists]
      } else if (files.subfolders == undefined && files.filelists.length > 0) {
        this.childfiles = [...files.filelists]
      } else if (files.subfolders !== undefined && files.filelists.length == 0) {
        this.childfiles = [...files.subfolders]
      } else {
        this.childfiles = []
      }
      if (this.childfiles !== undefined) {
        this.backupfolders = JSON.parse(JSON.stringify(this.childfiles))
      }
    }

  }
  getAncestors(array: any, name: any) {
    if (typeof array != "undefined") {
      for (let i = 0; i < array.length; i++) {
        if (array[i].guid === name) {
          return [array[i]];
        }
        let a: any = this.getAncestors(array[i].subfolders, name);
        if (a !== null) {
          a.unshift(array[i]);
          return a;
        }
      }
    }
    return null;
  }
downloadFile(value:any){

  let path = value.fullname.split("/");
  if(path.length>0){
    let index = path.length-1
    path.splice(index,1)
   path= path.join("/")
  }
  let obj ={
     "path":path+"/",
     "interfaceid":this.interfaceDetails.InterfaceId,
     "filename":value.filename
    }

    this.ngxuiloader.start()
  this.commonService.viewFile(obj).subscribe(data =>{
    if(data.fullfile !== null){


this.pdfSrc = data.fullfile;
let namemodal={
  name:data.filename,
  fullfile:data.fullfile
}
const modalRef = this.modalService.open(FileViewExplorerComponent
  , {
  windowClass: "myCustomModalViewfile",
  backdrop: "static",
  keyboard: false
});
modalRef.componentInstance.fileData =namemodal ;
modalRef.result.then(() => { });
this.ngxuiloader.stop()

    }else{
      this.ngxuiloader.stop()

    }
  },error=>{
    this.ngxuiloader.stop()
  })
}

  backbtn(index: any) {
    let findindex = this.backupfolders.findIndex((x: { name: any; }) => x.name === index);
    this.childfiles = this.backupfolders
  }
  //////////////
  getInitials(name: string) {
    const hasTokens = name.indexOf(' ') !== -1
    return name.substring(0, hasTokens ? 1 : 2) + (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
  }

  getChildren(node: FileNode) {
    return observableOf(node.subfolders);
  }
  back() {
    this.usersession.clicked = "View";
    this.router.navigate(['/home/interface']);
  }
  hasChild(index: number, node: TreeNode) {
    return node.expandable;
  }

  /** Transform the data to something the tree can read. */
  transformer(node: FileNode, level: number) {
    return {
      foldername: node.foldername,
      type: node.type,
      level: level,
      expandable: !!node.subfolders,
      size: node.size,
      lastmodifieddate: node.lastmodifieddate,
      subfolders: node.subfolders,
      filelists: node.filelists,
      fullpath: node.folderpath,
      folderpath: node.folderpath,
      fileCountShow: node.fileCountShow,
      guid: node.guid,
    };
  }

  /** Get the level of the node */
  getLevel(node: TreeNode) {
    return node.level;
  }

  /** Return whether the node is expanded or not. */
  isExpandable(node: TreeNode) {
    return node.expandable;
  };

  filesListShow(s: any) {
    // this.selectedfolderfilelists =[];
    s.filelists.forEach((element: { foldername: any; level: any; }) => {
      element.foldername = s.foldername;
      element.level = s.level;
      // this.selectedfolderfilelists.push(element);
    });
  }
  onSubmit() {
    this.searchfile(this.FTPFilesList.data, this.searchForm.value.search);
  }

  // Reusable methods
  getsequence(totaldata: any, sequencedata: any) {
    if (sequencedata !== null) {
      let newarray = sequencedata.reduce((acc: any, current: any) => {

        // if (current.TYPENAME == "VA-HUB") {
        //   let obj = {
        //     Name: "VA-HUB",
        //     Type: "VAConnectivity",
        //     Details:
        //       [
        //         {
        //           "Name": "ProcessorAgentid",
        //           "Value": this.interfaceDetails['ProcessorAgentid']
        //         },
        //         {
        //           "Name": "SourceAgent",
        //           "Value": this.interfaceDetails['SourceAgent']
        //         },
        //         {
        //           "Name": "DestinationAgent",
        //           "Value": this.interfaceDetails['DestinationAgent']
        //         }
        //       ]
        //   }
        //   acc.push(obj);
        // }
        if (current.TYPENAME == "VA") {
          let obj = {
            Name: "VA",
            Type: "VAConnectivity",
            Details:
              [
                {
                  "Name": "Deployment",
                  "Value": this.interfaceDetails['Deployment']
                },
                {
                  "Name": "InterfaceGroupId",
                  "Value": this.interfaceDetails['InterfaceGroupId']
                },
                {
                  "Name": "Version",
                  "Value": this.interfaceDetails['Version']
                },
              ]
          }

          acc.push(obj);
        }
        if (current.TYPENAME.includes("Job")) {
          if (Array.isArray(totaldata.InterfaceJobs)) {
            for (let i = 0; i < totaldata.InterfaceJobs.length; i++) {
              if (totaldata.InterfaceJobs[i].type == current.TYPENAME) {
                totaldata.InterfaceJobs[i]['ConnectType'] = "Job";
                totaldata.InterfaceJobs[i]['Details'] = this.createDetails(totaldata.InterfaceJobs[i].Parameters);
                if (acc !== undefined) {
                  let xist = acc.filter((va: { type: any; JobName: any; JobName_temp : any}) => va.type === current.TYPENAME && va.JobName === totaldata.InterfaceJobs[i].JobName && va.JobName_temp === totaldata.InterfaceJobs[i].Name_temp);
                  if (xist.length == 0) {
                    acc.push(totaldata.InterfaceJobs[i])
                  }
                } else {
                  let arrays = []
                  arrays.push(totaldata.InterfaceJobs[i])
                  acc = arrays
                }

              }
            }
            return acc;
          }
        }
        else {
          if (Array.isArray(totaldata.Connectivity.Connectivity)) {
            for (let j = 0; j < totaldata.Connectivity.Connectivity.length; j++) {

              if (totaldata.Connectivity.Connectivity[j].Type == current.TYPENAME) {
                totaldata.Connectivity.Connectivity[j]['ConnectType'] = "Connectivity"
                if (acc !== undefined) {
                  // totaldata.Connectivity.Connectivity[j]=this.commonService.transformAlphabetically(totaldata.Connectivity.Connectivity[j],'Name_temp');
                  let xist = acc.filter((va: { Type: any; Name: any; Name_Temp : any }) => va.Type === current.TYPENAME && va.Name === totaldata.Connectivity.Connectivity[j].Name && va.Name_Temp === totaldata.Connectivity.Connectivity[j].Name_temp);
                  if (xist.length == 0) {
                    acc.push(totaldata.Connectivity.Connectivity[j])
                  }
                } else {
                  acc.push(totaldata.Connectivity.Connectivity[j])
                  let arraysj = []
                  arraysj.push(totaldata.Connectivity.Connectivity[j])
                  acc = arraysj
                }
              }
            }
            return acc;
          }
        }
      }, [])
      return newarray;
    }
  }
  showExplorer(indenty: any) {
    if (indenty.includes("path") || indenty.includes("Dir")) {
      return true;
    } else {
      return false;
    }
  }

  units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  niceBytes(x: any) {
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + this.units[l]);
  }
  HideGuid(val: Object) {
    if (val) {
      let result = false;
      for (let [key, value] of Object.entries(val)) {
        if (value !== '') {
          return result = false;
        } else {
          result = true;
        }
      }
      return result;
    } else {
      return false;
    }
  }
  selfilter:any
  datefilter :any
  enddtfilter :any
  searchtext:any
  selPageNum:any
  isTabStatus:any
  selectedparams:any
  selectedOpt:string="Any"
  setDate(val) {
    var curr = new Date; // get current date
    var first = curr.getDay(); // First day is the day of the month - the day of the week
    var last = curr.getDate(); // last day is the first day + 6
    first = last - curr.getDay();
    ////console.log(curr, first, last);
    if (val === '0') {
      first = curr.getDate();
      this.selfilter = "Any"
      this.selectedOpt="Any"
      this.datefilter = "";
      this.enddtfilter = "";
      ////console.log(this.datefilter, this.enddtfilter);
      return
    }
    if(val==='1')
    {
      first = last - 2;
      this.selectedOpt="Last 2 Days"
      this.selfilter = "Last 2 Days"
    }
    if (val === '2') {
      first = last - curr.getDay();
      this.selectedOpt="This week"
      this.selfilter = "This week"
    }
    if (val === '3') {
      first = 1;
      this.selectedOpt="This Month"
      this.selfilter = "This Month"
    }
    if (val === '4') {
      first = first - 7;
      this.selectedOpt="Last week"
      this.selfilter = "Last week"
    }
    if (val === '5') {
      first = last - 7;
      this.selectedOpt="Last 7 Days"
      this.selfilter = "Last 7 Days"
    } else if (val === '6') {
      first = first - 30;
      this.selectedOpt="Last 30 Days"
      this.selfilter = "Last 30 Days"
    }
    if (val === '7') {
      first = first - 30;
      let firstday = new Date(curr.setDate(first));
      firstday = new Date(curr.setDate(1));
      let lastd = new Date()
      lastd.setDate(0)
      this.datefilter = `${firstday.getFullYear()}-${firstday.getMonth() + 1}-${firstday.getDate()}`;
      this.enddtfilter = `${lastd.getFullYear()}-${lastd.getMonth() + 1}-${lastd.getDate()}`;
      ////console.log(this.datefilter,this.enddtfilter)
      this.selectedOpt="Last Month"
      this.selfilter = "Last Month";
      return
    }
    let firstday = new Date(curr.setDate(first));
    let lastd = new Date()
    let lastday = new Date(lastd.setDate(last));
    let firstdate,lastdate,firstmonth,lastmonth
    firstmonth = firstday.getMonth() + 1 < 10 ? "0"+(firstday.getMonth() + 1):firstday.getMonth() + 1;
    lastmonth = lastday.getMonth() + 1 < 10 ? "0"+(lastday.getMonth() + 1):lastday.getMonth() + 1;
    firstdate = firstday.getDate() < 10 ? "0"+firstday.getDate():firstday.getDate();
    lastdate = lastday.getDate() < 10 ? "0"+lastday.getDate():lastday.getDate();

    this.datefilter = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    this.enddtfilter = `${lastday.getFullYear()}-${lastmonth}-${lastdate}`;
    this.getdateArray(this.FTPFilesList.data[0], this.datefilter,this.enddtfilter);
    ////console.log(this.datefilter,this.enddtfilter)
  }
  createDetails(data: Object) {
    let parameter = [];
    for (let [key, value] of Object.entries(data)) {
      let obj = {
        "Name_temp": key,
        "Value": value
      }
      parameter.push(obj)
    }
    return parameter
  }
  showSuccess(id: string) {
    this.toastr.success('Copied To ClipBoard', id, { positionClass: 'toast-bottom-right', timeOut: 4000 });
  }
  copyToClip(event: any, id) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success('Copied To ClipBoard', id, { positionClass: 'toast-bottom-right', timeOut: 4000 });
    }
  }
  conndesc(check: string) {
    if (this.descriptionArrayList !== undefined) {
      if (this.descriptionArrayList.length > 0) {
        let values = this.descriptionArrayList.find((va: any) => va.ConnectivityType === check);
        if (values !== undefined) {
          return ` Connectivity :  ${check}
          Description : ${values['Description']}`
        }
        else {
          return `${check}`
        }
      } else {
        return "";
      }
    } else {
      return '';
    }
  }

  showimg(value){
    if(value !== undefined){
      return value.split(".")[1]
    }
  }
}


export interface FileNode {
  fileCountShow: any;
  foldername: string;
  folderpath: string;
  level: number;
  type: string;
  size: any;
  lastmodifieddate: any;
  subfolders: FileNode[];
  filelists: FileTreeNode[],
  guid: string
}

/** Flat node with expandable and level information */
export interface TreeNode {
  foldername: string;
  level: number;
  expandable: boolean;
  filelists: FileTreeNode[],
  subfolders: FileNode[],
  fileCountShow: number,
  folderpath: string,
  guid: string,
}

/** Flat node with expandable and level information */
export interface FileTreeNode {
  name: string;
  fullName: string;
  type: number;
  size: string;
  lastmodifieddate: string
}

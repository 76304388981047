import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
declare const copyToClipboard: any;

@Component({
  selector: 'app-bulkmessagereprocess',
  templateUrl: './bulkmessagereprocess.component.html',
  styleUrls: ['./bulkmessagereprocess.component.scss']
})
export class BulkmessagereprocessComponent implements OnInit {

  cardHeight: Number = window.innerHeight - 226;
  notfounddata: boolean;
  constructor(
    private service: CommonService,
    private router: Router,
    private _fb: FormBuilder, private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal,
  ) {
    this.reactiveFormroute = _fb.group({ routename: '' });
    this.reactiveFormsa = this._fb.group({ saname: '' });
    this.reactiveFormmsgstage = this._fb.group({ msgname: '' });
    this.reactiveFormpa = _fb.group({ paname: '' });
    this.reactiveFortransactionid = _fb.group({ nametransactionid: '' });
    this.reactiveFormstatustype = _fb.group({ statusname: '' });
    this.reactiveFormreason = _fb.group({ badmsg: '' });
    this.MessageStagesList = this.MessageStagesAllList;
    this.ProcessorAgentNameList = this.ProcessorList;
  }
  reactiveFortransactionid: FormGroup;
  reactiveFormsa: FormGroup;
  reactiveFormroute: FormGroup;
  pagerolepermisions: any = [];
  screenHeight: number;
  today = new Date();
  async ngOnInit() {
    this.screenHeight = window.innerHeight

    if (await this.service.checksession() == true) {
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'bulkreprocess').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });
      if (this.pagerolepermisions == undefined) {
        this.router.navigate(['/login']);
      }
      this.GetBadMessageList('badmsg');
      this.getMessagestagelist('msgstage');
      this.getProcessorAgentNames('pipeline');
      this.reactiveFormmsgstage.patchValue(this.MessageStagesList);
      this.reactiveFormreason.patchValue(this.badmessageAllList);
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  badmessageAllList:any=[];
  GetBadMessageList(event) {
    if (event !== 'badmsg' && this.badmessageAllList.length>0) {

      this.badmessageList = this.badmessageAllList.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
      if (this.badmessageList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingpa = false;
    }
    else {

      var apiname = "api/HubMessage/GetBadMessageReasons";
      this.service.getapiurl(apiname).subscribe({
        next: data => { 
          data.forEach(element => {
            this.badmessageAllList.push({ name: element })
          });
          this.badmessageAllList=this.service.transformAlphabetically( this.badmessageAllList,'name');
          this.badmessageList=this.badmessageAllList;
        },
        error: error => {

        }
      });
    }
  }

  keywordsearch = "displayname";
  RouteNameList: any = [];
  isLoadingroutes: boolean = false;
  selectedrouteId: string = "";
  selectedRow: any;
  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]}`
    return value
  }
  getRouteNames(event: any) {
    var val = event;
    if (val) {
      this.isLoadingroutes = true;
      var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=0";
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.RouteNameList = data as any[];
          this.RouteNameList.map(obj => obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}`);
          this.isLoadingroutes = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingroutes = false;
          this.notfounddata = true;
        }
      });
    }
  }
  searchRouteNameCleared(event) {
    this.isLoadingroutes = false;
    this.RouteNameList = [];
    this.selectedrouteId = '';
    this.notfounddata = false;
  }
  selectedRoute(item) {
    this.selectedrouteId = item.id;
  }

  statustype: string = '';
  fromdate: any;
  todate: any;
  messageList: any = [];
  async SearchMessages() {
    this.selection.clear();
    this.messageList = [];

    if (this.statustype == '') {
      this.toastr.error("Status is mandatory ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    if (this.selectedrouteId == '' && this.reactiveFormroute.get('routename').value != '' && this.reactiveFormroute.get('routename').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormroute.get('routename').value);
      if (returnvalue == true) {
        this.selectedrouteId = this.reactiveFormroute.get('routename').value;
      }
      else {
        this.toastr.error("No results found!!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        return;
      }
    }

    if (this.sourceagentid == '' && this.reactiveFormsa.get('saname').value != '' && this.reactiveFormsa.get('saname').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormsa.get('saname').value);
      if (returnvalue == true) {
        this.sourceagentid = this.reactiveFormsa.get('saname').value;
      }
      else {
        this.toastr.error("No results found!!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        return;
      }
    }

    if (!this.fromdate || this.fromdate=='') {
      this.toastr.error("From date is mandatory ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    if (!this.todate || this.todate=='') {
      this.toastr.error("To date is mandatory ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    if (this.isChecked) {
      if (this.reasontype == '') {
        this.toastr.error("Reason is mandatory ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        return;
      }
    }

    if (this.fromdate > this.todate) {
      this.toastr.error("From date should be  equal  or  less than To date ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    var searchinput = {
      "RoutID": this.selectedrouteId,
      "FromDate": this.fromdate + ' 00:00:00',
      "ToDate": this.todate + ' 23:59:59',
      "Status": this.statustype,
      "Badmessage": this.isChecked,
      "Failurereson": this.reasontype,
      "SourceAgent": this.sourceagentid,
      "TransactionIdslist": this.reactiveFortransactionid.get("nametransactionid").value,
      "Messagestage": this.selectedmessagestage,
      "ProcessorAgent": this.selectedProcessorAgentguid
    }

    var apiname = 'api/HubMessage/GetFailedMessage';
    this.ngxUiLoaderService.start();
    let response = await this.service.searchpostapi(apiname, searchinput);
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    }
    else {
      this.ngxUiLoaderService.stop();
      this.messageList = JSON.parse(response.responsebody) as any[];
      if (this.messageList.length < 1) {
        if (response['status'] == "0") {
          this.toastr.error(response['statusmessage'], "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        } else {
          this.toastr.warning("No results found!!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        }
      }
    }
  }
  isUUID(uuid) {
    let s;
    s = uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }

  selection = new SelectionModel<any>(true, []);
  checkboxLabel(row?: any) {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    let ui = `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    return ui;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.messageList.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.messageList.forEach(row => this.selection.select(row));
  }

  isChecked: any = false;
  reasontype: any = 'select';
  keywordsearchsa: string = 'displayname';
  SourceagentNameList: any = [];
  isLoadingsa: boolean = false;
  sourceagentid: string = '';
  getSourceagentNames(event: any) {
    var val = event;
    if (val != '') {
      this.isLoadingsa = true;
      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=agent&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.SourceagentNameList = data as any[];
          this.SourceagentNameList=this.service.transformAlphabetically(this.SourceagentNameList,'displayname');
          this.SourceagentNameList.map(obj => obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}`);
          this.isLoadingsa = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingsa = false;
          this.notfounddata = true;
        }
      });
    }
  }

  selectedSourceagent(item) {
    this.sourceagentid = item.id;
  }

  searchSourceagnetCleared(item) {
    this.isLoadingsa = false;
    this.SourceagentNameList = [];
    this.sourceagentid = '';
    this.notfounddata = false;
  }

  async reset() {
    this.reactiveFormsa.reset();
    this.reactiveFormroute.reset();
    this.selectedrouteId = "";
    this.selection.clear();
    this.messageList = [];
    this.fromdate = null;
    this.todate = null;
    this.reasontype = '';
    this.statustype = '';
    this.isChecked = false;
    this.reactiveFortransactionid.reset();
    this.reactiveFormreason.reset();
    this.statustypelist = this.statustypelist;
    this.TransactionIdslist = '';
    this.reactiveFormpa.reset();
    this.reactiveFormmsgstage.reset();
    this.reactiveFormstatustype.reset();
    this.ProcessorAgentNameList = this.ProcessorList;
    this.reactiveFormpa.setValue({ "paname": '' });
    this.MessageStagesList = this.MessageStagesAllList;
    this.reactiveFormmsgstage.setValue({ "msgname": '' });
    this.reactiveFormpa.patchValue(this.ProcessorAgentNameList);
    this.reactiveFormreason.setValue({ "badmsg": '' });
    this.reactiveFormreason.patchValue(this.badmessageAllList);
  }

  onFocused(e) {
    this.statustypelist = [
      {
        id: 12,
        name: 'Failed',
      },
      {
        id: 10,
        name: 'Created',
      },
      {
        id: -1,
        name: 'Bad Messages',
      },
      {
        id: 15,
        name: 'Auto Messages',
      }
    ];

  }
  onbadmsgFocused(e) {
    // this.badmessageList = [
    //   {
    //     name: 'Initial File Upload Failed',
    //   },
    //   {
    //     name: 'ROUTE NOT RESOLVED',
    //   },
    //   {
    //     name: 'getProcessorurl FAILED',
    //   }
    // ];

  }
  checkeddata: any = [];
  reprocessdata: any = [];

  async reprocessSelected() {
    if (this.selection.selected.length > 0) {
      this.checkeddata = this.selection.selected;
      this.reprocessdata = [];

      this.checkeddata.forEach(async element => {
        var dataCons = {
          "RoutID": this.isChecked == false ? element.id : '',
          "SourceAgent": this.isChecked == true ? element.id : '',
          "FromDate": this.fromdate + ' 00:00:00',
          "ToDate": this.todate + ' 23:59:59',
          "Status": this.statustype,
          "Badmessage": this.isChecked,
          "UniqueID": element.UniqueID,
          "Failurereson": this.reasontype,
          "Modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
        }
        this.reprocessdata.push(dataCons);
      });

      this.ngxUiLoaderService.start();
      var apiname = "api/HubMessage/ReprocessBulk"
      let response = await this.service.searchpostapi(apiname, this.reprocessdata);

      if (response['status'] && response['statusText']) {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Reprocess failed', "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      }
      else {
        this.ngxUiLoaderService.stop();
        this.toastr.success('Reprocessed successfully', "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        await this.SearchMessages();
      }
    }
  }

  copyToClip(event: any, message: string) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", { timeOut: 4000, positionClass: 'toast-bottom-right' })
    }
  }

  auditlist: any = [];
  async viewauditdetails(content) {

    if (this.selectedrouteId == '' && this.reactiveFormroute.get('routename').value != '' && this.reactiveFormroute.get('routename').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormroute.get('routename').value);
      if (returnvalue == true) {
        this.selectedrouteId = this.reactiveFormroute.get('routename').value;
      }
      else {
        this.toastr.error("No results found!!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        return;
      }
    }

    if (this.sourceagentid == '' && this.reactiveFormsa.get('saname').value != '' && this.reactiveFormsa.get('saname').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormsa.get('saname').value);
      if (returnvalue == true) {
        this.sourceagentid = this.reactiveFormsa.get('saname').value;
      }
      else {
        this.toastr.error("No results found!!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        return;
      }
    }

    if (!this.fromdate || this.fromdate=='') {
      this.toastr.error("From date is mandatory ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    if (!this.todate || this.todate=='') {
      this.toastr.error("To date is mandatory ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }


    const auditsearchinput = {
      "RoutID": this.selectedrouteId,
      "FromDate": this.fromdate + ' 00:00:00',
      "ToDate": this.todate + ' 23:59:59',
      "Status": this.statustype,
      "Badmessage": this.isChecked,
      "Failurereson": this.reasontype,
      "SourceAgent": this.sourceagentid
    }

    var responseconfig = await this.service.postapi('api/HubMessage/GetAuditBulkReprocess', auditsearchinput);
    if (responseconfig['status'] && responseconfig['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error('Failure to get bulk reprocess audits ' + responseconfig['statusText'], "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {

      this.auditlist = responseconfig as any[];
      if (this.auditlist.length > 0) {
        await this.downloadaudits();
      }
      else {
        this.toastr.warning('Bulk reprocess audits are not found', "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      }
    }
  }

  closepopup(c) {
    c('close modal');
  }

  ConvertToCSV(objArray, headerList) {

    let items = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let separator = ',';
    const columns = Object.keys(items[0]).join(separator);
    const body = items.map(item =>
      Object.values(item).join(separator)
    ).join('\n');

    return headerList + '\n' + body;
  }


  downloadaudits() {
    let csvData = '';
    if (this.isChecked) {
      this.auditlist.forEach(element => {
        delete element.Pipelineguid;
        delete element.PipelineName;
        delete element.Messageid;
      });
      csvData = this.ConvertToCSV(this.auditlist, ['AgentId', 'AgentName', 'TransactionId',
        'ReprocessedDate', 'ReprocessedBy','Context']);
    }
    else {
      csvData = this.ConvertToCSV(this.auditlist, ['PipelineId', 'PipelineName', 'AgentId', 'AgentName', 'MessageId', 'TransactionId',
        'ReprocessedDate', 'ReprocessedBy','Context']);
    }


    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", new Date() + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  getStatusmessage() {
    if (this.statustype == '-1') {
      this.isChecked = true;
    }
    else {
      this.isChecked = false;
    }

    this.reactiveFormsa.reset();
    this.reactiveFormroute.reset();
    this.selectedrouteId = "";
    this.selection.clear();
    this.messageList = [];
    this.fromdate = null;
    this.todate = null;
    this.reasontype = '';
  }

  MessageStagesList: any = [];
  MessageStagesAllList: any = [];
  isLoadingstage: boolean = false;
  reactiveFormmsgstage: FormGroup;
  selectedmessagestage: string = "";
  selectedmessagestageevent(item) {
    this.selectedmessagestage = item.displayname;
  }
  Clearemessagestage(event) {

  }

  async getMessagestagelist(event: any) {

    this.isLoadingstage = true;
    if (event !== 'msgstage') {
      if (event == undefined) {
        this.MessageStagesList = this.MessageStagesAllList;
      }
      else {
        this.MessageStagesList = this.MessageStagesAllList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.MessageStagesList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingstage = false;
    }
    else {

      await this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagestage").subscribe({
        next: async data => {
          data.forEach(element => {
            this.MessageStagesAllList.push({ displayname: element });
          });
          this.MessageStagesList = this.MessageStagesAllList;
          this.isLoadingstage = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingstage = false;
          this.notfounddata = true;
        }
      });
    }

  }

  reactiveFormpa: FormGroup;
  ProcessorAgentNameList: any = [];
  selectedProcessorAgentguid: string = "";
  selectedProcessorAgentName: string = "";
  isLoadingpa: boolean = false;
  ProcessorList: any = [];

  selectedProcessorAgents(item) {
    this.selectedProcessorAgentguid = item.id;
    this.selectedProcessorAgentName = item.displayname;
  }
  searchProcessorAgentCleared(event) {
    this.isLoadingpa = false;
    this.ProcessorAgentNameList = this.ProcessorList;
    this.selectedProcessorAgentguid = '';
    this.notfounddata = false;
  }
  async getProcessorAgentNames(event: any) {

    this.isLoadingpa = true;
    var apiname = '';
    if (event !== 'pipeline') {

      this.ProcessorAgentNameList = this.ProcessorList.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
      if (this.ProcessorAgentNameList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingpa = false;
    }
    else {
      apiname = "api/Agents/GetByDisplayName?name=" + '&agentid=0&type=Processor&filterflag=false';

      await this.service.getapiurl(apiname).subscribe({
        next: async data => {
          this.ProcessorAgentNameList = data as any[];
          this.ProcessorAgentNameList.map(obj => { obj.name = obj.displayname, obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}` });
          this.ProcessorList = this.ProcessorAgentNameList;
          if(this.ProcessorList.length>0){
            this.ProcessorList=this.service.transformAlphabetically(this.ProcessorList,'displayname');
          }
          this.isLoadingpa = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingpa = false;
          this.notfounddata = true;
        }
      });
    }

  }
  TransactionIdslist: string = '';

  exportreport(dataitem) {
    let csvData;
    let filename = 'data.csv';
    if (dataitem == '') {
      csvData = this.ConvertToCSVmessagelist(this.messageList, '');
    }
    else {
      if (this.isChecked) {
        const data = [];
        dataitem.forEach(element => {
          data.push({
            Sourceagentid: element.sourceagentid,
            Transactionid: element.transactionid,
            Sourceentityid: element.sourceentityid,
            Destinationentityid: element.destinationentityid,
            MessageType: element.messagetype,
            Createddate: element.createddate
          })
        });
        csvData = this.ConvertToCSVmessagelist(data, ['Sourceagentid', 'Transactionid', 'Sourceentityid',
          'Destinationentityid', 'MessageType', 'Createddate']);
        filename = data[0].Sourceagentid + '_data.csv';
      }
      else {
        const data = [];
        dataitem.forEach(element => {
          data.push({
            Messageid: element.messageid,
            Transactionid: element.transactionid,
            Pipeline: element.displayname,
            Sourceagentid: element.sourceagentid,
            destinationagentid: element.destinationagentid,
            Sourceentityid: element.sourceentityid,
            Destinationentityid: element.destinationentityid,
            MessageType: element.messagetype,
            Createddate: element.createddate
          })
        });
        csvData = this.ConvertToCSVmessagelist(data, ['Messageid', 'Transactionid', 'Pipeline', 'Sourceagentid', 'destinationagentid',
          'Sourceentityid', 'Destinationentityid', 'MessageType', 'Createddate']);
        filename = data[0].Pipeline + '_data.csv';
      }

    }

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  ConvertToCSVmessagelist(objArray, headerscus) {

    let items = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let separator = ',';
    const columns = Object.keys(items[0]).join(separator);
    const body = items.map(item =>
      Object.values(item).join(separator)
    ).join('\n');
    if (headerscus == '') {
      return columns + '\n' + body;
    }
    else {
      return headerscus + '\n' + body;
    }

  }

  async exportrowdata(dataitem) {
    var searchinput = {}
    if (this.isChecked) {
      searchinput = {
        "RoutID": "",
        "FromDate": this.fromdate + ' 00:00:00',
        "ToDate": this.todate + ' 23:59:59',
        "Status": this.statustype,
        "Badmessage": this.isChecked,
        "Failurereson": this.reasontype,
        "SourceAgent": dataitem.id,
        "TransactionIdslist": this.reactiveFortransactionid.get("nametransactionid").value,
        "Messagestage": this.selectedmessagestage,
        "ProcessorAgent": this.selectedProcessorAgentguid
      }
    }
    else {
      searchinput = {
        "RoutID": dataitem.id,
        "FromDate": this.fromdate + ' 00:00:00',
        "ToDate": this.todate + ' 23:59:59',
        "Status": this.statustype,
        "Badmessage": this.isChecked,
        "Failurereson": this.reasontype,
        "SourceAgent": "",
        "TransactionIdslist": this.reactiveFortransactionid.get("nametransactionid").value,
        "Messagestage": this.selectedmessagestage,
        "ProcessorAgent": this.selectedProcessorAgentguid
      }
    }
    var apiname = 'api/HubMessage/GetFailedMessageDetails';
    this.ngxUiLoaderService.start();
    let response = await this.service.searchpostapi(apiname, searchinput);
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    }
    else {
      this.ngxUiLoaderService.stop();
      const res = JSON.parse(response.responsebody) as any[];
      if (res.length > 0) {
        this.exportreport(res)
      }
    }
  }

  keyword = 'name';
  public statustypelist = [
    {
      id: -1,
      name: 'Bad Messages',
    },
    {
      id: 10,
      name: 'Created',
    },
    {
      id: 12,
      name: 'Failed',
    },
    {
      id: 15,
      name: 'Auto Messages',
    }
  ];

  async selectEvent(item) {
    this.statustype = item.id;
    if (this.statustype == '-1') {
      this.isChecked = true;

    }
    else {
      this.isChecked = false;
    }

    this.reactiveFormsa.reset();
    this.reactiveFormroute.reset();
    this.selectedrouteId = "";
    this.selection.clear();
    this.messageList = [];
    this.fromdate = null;
    this.todate = null;
    this.reasontype = '';
  }

  clearedstatus(item) {
    this.reset();
    this.statustype = '';

  }
  reactiveFormstatustype: FormGroup;
  badmessageList:any=[];
  //   badmessageList = [
  //   {
  //     name: 'Initial File Upload Failed',
  //   },
  //   {
  //     name: 'ROUTE NOT RESOLVED',
  //   },
  //   {
  //     name: 'getProcessorurl FAILED',
  //   }
  // ];

  reactiveFormreason: FormGroup;
  selectbadmsgEvent(item) {
    this.reasontype = item.name;
  }

  clearedbadmsg(item) {
    this.reasontype = '';
  }
}


import { Component, Input, IterableDiffers, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/services/common.service';
declare const copyToClipboard: any;

import { MonacoEditorComponent, MonacoEditorConstructionOptions, MonacoEditorLoaderService, MonacoStandaloneCodeEditor } from "@materia-ui/ngx-monaco-editor";
import { filter, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { XmlmappingsComponent } from 'src/app/xmlmappings/xmlmappings.component';
import { ConfigComponent } from 'src/app/config/config.component';
declare const loadxml: any;
declare const loadmappedxml: any;
declare const createMappedxml: any;


@Component({
  selector: 'app-saveagents',
  templateUrl: './saveagents.component.html',
  styleUrls: ['./saveagents.component.scss'],
  providers: [MonacoEditorLoaderService]
})
export class SaveagentsComponent implements OnInit {

  @Input() inputDataInModalComponent: any;

  public reactiveFormsa: FormGroup;
  public reactiveFormta: FormGroup;
  UniqueId: any = '';
  notfounddata: boolean;


  inputtemplatexml: string = '';
  reactiveFormPipelineFilter: FormGroup;
  constructor(private router: Router, private service: CommonService, private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private _fb: FormBuilder,
    private monacoLoaderService: MonacoEditorLoaderService,
    private toastr: ToastrService
  ) {
    this.reactiveFormsa = this._fb.group({ saname: '' });
    this.reactiveFormta = this._fb.group({ taname: '' });
    this.reactiveFormmsgtype = this._fb.group({ msgname: '' });
    this.reactiveFormfileformat = this._fb.group({ fileformat: '' });
    this.reactiveForminterfacetype = this._fb.group({ fcinterfacetype: '' });
    this.reactiveFormprotype = this._fb.group({ protype: '' });
    this.monacoLoaderService.isMonacoLoaded$
      .pipe(
        filter(isLoaded => !!isLoaded),
        take(1)
      )
      .subscribe(() => {
        this.registerMonacoCustomTheme();
      });
    this.reactiveFormPipelineFilter = _fb.group({ filterpipeline: '' });
  }

  registerMonacoCustomTheme() {
    monaco.editor.defineTheme('myCustomTheme', {
      base: 'vs', // can also be vs or hc-black
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [
        {
          token: 'comment',
          foreground: 'ffa500',
          fontStyle: 'italic underline'
        },
        { token: 'comment.js', foreground: '008800', fontStyle: 'bold' },
        { token: 'comment.css', foreground: '0000ff' } // will inherit fontStyle from `comment` above
      ],
      colors: {}
    });
  }

  Interfacetypeslist = [
    { item_id: 1, item_text: "Inbound", checked: true, item_value: "Inbound" },
    { item_id: 2, item_text: "Outbound", checked: false, item_value: "Outbound" },
    { item_id: 3, item_text: "Ellkay Order Inbound", checked: false, item_value: "EllkayInbound" },
    { item_id: 4, item_text: "Ellkay Result Outbound", checked: false, item_value: "EllkayOutbound" }
  ];

  selectedinterfacetypetext: string = 'select'
  selectedinterfacetypevalue: string = 'select'

  messageTypeList: any = [];
  templatelist: any = [];
  myFlagForSlideToggle: boolean = false;

  title: string = "Add Processor";
  id: string = "";
  name: string = "";
  type: string = "select";
  displayname: string = "";
  apikey: string = "";
  isfileupload: boolean = false;
  isfiledownload: boolean = false;
  generatedxsl: string = "";
  template: string = "";
  inputtype: string = "";
  sourceagentid: string = '';
  destinationagentid: string = '';
  processorNotes: string = '';
  eventtype: number;
  checksourceboxchnage(item, i) {
    item.checked = !item.checked;
    item.guid = '';
    if (item.checked && item.item_text === 'VB') {
      this.isfileupload = true;
    }
    else {
      this.isfileupload = false;
    }
  }
  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]})`
    return value
  }
  checktargetboxchnage(item, i) {
    item.checked = !item.checked;
    item.guid = '';
    if (item.checked && item.item_text === 'FTP') {
      this.isfiledownload = true;
    }
    else {
      this.isfiledownload = false;
    }
  }
  pagerolepermisions: any = [];

  async ngOnInit() {

    if (await this.service.checksession() == true) {
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'agentlist').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });
      if (this.pagerolepermisions != undefined) {
        // await this.GetMessageTypes();
        await this.getMessagetypelist('msgtype');
        this.reactiveFormmsgtype.patchValue(this.messageTypeAllList);

        await this.Geteditinstance();
      }

      if (this.pagerolepermisions == undefined) {
        this.router.navigate(['/login']);
      }

    }
    else {
      this.router.navigate(['/login']);
    }
  }

  closetpopup() {
    this.activeModal.close();
    this.processorjsonconfig = '';
  }

  updateflag: boolean = false;
  processorjsonconfig: any;
  async Geteditinstance() {
    if (this.inputDataInModalComponent == '') {
      this.id = await (await this.getGuid()).toUpperCase();
      this.apikey = await (await this.getGuid()).toUpperCase();
      this.updateflag = false;
      this.reactiveFormprotype.patchValue({
        protype: 'General'
      });
      this.processortype = 'General';
    }
    else {
      this.updateflag = true;
      this.title = "Edit Processor";
      this.id = this.inputDataInModalComponent.id;
      this.UniqueId = this.inputDataInModalComponent.UniqueID;
      this.apikey = this.inputDataInModalComponent.apikey;
      this.name = this.inputDataInModalComponent.name;
      this.processorNotes = this.inputDataInModalComponent.description;
      this.myFlagForSlideToggle = this.inputDataInModalComponent.status == 'active' ? true : false;
      //this.processorjsonconfig = this.inputDataInModalComponent.jsonconfig;
      var jsonconfig = JSON.parse(this.inputDataInModalComponent.jsonconfig);

      //vaconfig
      var vaconfigdata = jsonconfig.processorconfig["vaconfig"];
      this.isfiledownload = vaconfigdata.isfiledownload == '0' ? false : true;
      this.isfileupload = vaconfigdata.isfileupload == '0' ? false : true;
      //  this.specialcharacter = vaconfigdata.specialcharacter;

      //xslconfig
      var xslconfigdata = jsonconfig.processorconfig["xslconfig"];
      this.generatedxsl = xslconfigdata.generatedxsl;
      this.selectedsuffix = xslconfigdata.suffix;
      this.mappedxmlhold = xslconfigdata.mappedxml;
      this.inputtemplateidhold = xslconfigdata.template;
      if (jsonconfig.processorconfig["interfacetype"] == "") {

      }
      else {
        this.selectedinterfacetypevalue = jsonconfig.processorconfig["interfacetype"];
      }

      this.reactiveForminterfacetype.patchValue({
        fcinterfacetype: this.selectedinterfacetypevalue
      });

      this.reactiveFormfileformat.patchValue({
        fileformat: xslconfigdata.suffix
      });

      //processoragentconfig
      var processoragentconfigdata = jsonconfig.processoragentconfig;
      this.selectedmessagetype = processoragentconfigdata.messagetype;
      this.sourceagentid = processoragentconfigdata.sourceagentid;
      this.destinationagentid = processoragentconfigdata.destinationagentid;
      if (processoragentconfigdata.eventtype) {
        this.eventtype = processoragentconfigdata.eventtype;
      }
      this.selectedmessagetype = processoragentconfigdata.messagetype;
      this.reactiveFormmsgtype.patchValue({
        msgname: processoragentconfigdata.messagetype
      });

      this.reactiveFormsa.patchValue({
        saname: this.inputDataInModalComponent.sourceagentname
      });

      this.reactiveFormta.patchValue({
        taname: this.inputDataInModalComponent.destinationagentname
      });

      this.processorurl = jsonconfig.processorurl;

      if (this.inputDataInModalComponent.processortype && this.inputDataInModalComponent.processortype != '') {
        this.reactiveFormprotype.patchValue({
          protype: this.inputDataInModalComponent.processortype
        });
        this.processortype = this.inputDataInModalComponent.processortype;
      }
      else {
        this.processortype = 'General';
        this.reactiveFormprotype.patchValue({
          protype: 'General'
        });
      }
      //for audit purpose
      const saveagententity = {
        "id": this.id,
        "name": this.name,
        "displayname": this.name,
        "type": "Processor",
        "apikey": this.apikey,
        "modifierid": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
        "status": this.myFlagForSlideToggle == true ? 'active' : 'passive',
        "jsonconfig": JSON.stringify(this.inputDataInModalComponent.jsonconfig),
        "description": this.processorNotes
      };
      this.oldvalue = JSON.stringify(saveagententity);

      await this.getcoreconfigforagent();
    }
  }

  async s4() {
    return await Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }
  async getGuid() {

    return await this.s4() + await this.s4() + '-' + await this.s4() + '-' + await this.s4() + '-' + await this.s4() + '-' + await this.s4() + await this.s4() + await this.s4();
  }

  configjson: any = {
    "processorconfig": {
      "vaconfig": {
        "isfileupload": 0,
        "fileuploadurl": "",
        "isfiledownload": 0,
        "filedownloadurl": "",
        "lisfiledownloadurl": ""
      },
      "xslconfig": {
        "mappedxml": "",
        "generatedxsl": "",
        "template": "",
        "suffix": ""
      },
      "interfacetype": ""
    },
    "processorurl": "",
    "processoragentconfig": {
      "messagetype": "",
      "sourceagentid": "",
      "destinationagentid": "",
      "eventtype": 102
    }
  };

  oldvalue: any = '';
  newvalue: any = '';

  async saveagent() {

    //validations
    var returnvalue = await this.validationchecks();
    if (returnvalue == true) {
      //xslconfig
      this.configjson.processorconfig.xslconfig["suffix"] = this.selectedsuffix;


      //vaconfig
      this.configjson.processorconfig.vaconfig["isfileupload"] = this.isfileupload == false ? 0 : 1;
      this.configjson.processorconfig.vaconfig["isfiledownload"] = this.isfiledownload == false ? 0 : 1;

      //placeholders values replace in api
      this.configjson.processorconfig.vaconfig["fileuploadurl"] = "@fileuploadurl";
      this.configjson.processorconfig.vaconfig["filedownloadurl"] = "@filedownloadurl";

      this.configjson.processorurl = this.processorurl;

      if (this.processorurl !== '' && this.processorurl) {
        if (this.UniqueId == '' && this.templatelist.length == 0) {

          this.ngxUiLoaderService.start();
          this.toastr.warning('Mappings are not updated. Processor saved successfully with default mappings', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          this.templatelist = await this.getalltemplates();
          this.ngxUiLoaderService.stop();

          this.configjson.processorconfig.xslconfig["template"] = this.templatelist.filter(c => c.Type == 'Input')[0].id;
          this.configjson.processorconfig.xslconfig["mappedxml"] = this.templatelist.filter(c => c.inputFormat != ".xsl" && c.Type == 'Output')[0].templatexml;
          this.configjson.processorconfig.xslconfig["generatedxsl"] = this.templatelist.filter(c => c.inputFormat == ".xsl")[0].templatexml;
        }
        else {
          if (this.service.isprocessEditedtemplatedId !== 0) {
            this.configjson.processorconfig.xslconfig["template"] = this.service.isprocessEditedtemplatedId.toString();
          }else{
            this.configjson.processorconfig.xslconfig["template"] = this.inputtemplateidhold.toString();
          }

          if (this.service.isprocessEditedMappingData !== "") {
            this.configjson.processorconfig.xslconfig["mappedxml"] = this.service.isprocessEditedMappingData
            this.mappedxmlhold = this.service.isprocessEditedMappingData
          } else if (this.mappedxmlhold !== "") {
            this.configjson.processorconfig.xslconfig["mappedxml"] = this.mappedxmlhold;
          }
          if (this.UniqueId == '' && this.templatelist.length != 0) {
            this.configjson.processorconfig.xslconfig["generatedxsl"] = this.templatelist.filter(c => c.inputFormat == ".xsl")[0].templatexml;
          }
          else {
            this.configjson.processorconfig.xslconfig["generatedxsl"] = this.generatedxsl;
          }
        }
      }

      //processoragentconfig
      this.configjson.processoragentconfig.messagetype = this.selectedmessagetype;
      this.configjson.processoragentconfig.sourceagentid = this.sourceagentid;
      this.configjson.processoragentconfig.destinationagentid = this.destinationagentid;
      this.configjson.processoragentconfig.eventtype = this.eventtype;
      this.configjson.processorconfig.interfacetype = this.selectedinterfacetypevalue;

      if (this.processortype == "Splitter" && this.selectedsuffix == '.csv') {
        this.configjson["csvsplitterconfig"] = this.csvsplitterconfig;
        this.configjson.csvsplitterconfig.vacsvsplitterconfig["isfileupload"] = this.isfileupload == false ? 0 : 1;
        this.configjson.csvsplitterconfig.vacsvsplitterconfig["isfiledownload"] = this.isfiledownload == false ? 0 : 1;
        this.configjson.csvsplitterconfig.vacsvsplitterconfig["messagetype"] = this.selectedmessagetype;
      }
      else if (this.processortype == "Splitter" && this.selectedsuffix == '.xml') {
        this.configjson["xmlsplitterconfig"] = this.xmlsplitterconfig;
        this.configjson.xmlsplitterconfig.vaxmlsplitterconfig["isfileupload"] = this.isfileupload == false ? 0 : 1;
        this.configjson.xmlsplitterconfig.vaxmlsplitterconfig["isfiledownload"] = this.isfiledownload == false ? 0 : 1;
        this.configjson.xmlsplitterconfig.vaxmlsplitterconfig["messagetype"] = this.selectedmessagetype;
      }

      const saveagententity = {
        "id": this.id,
        "name": this.name.trim(),
        "displayname": this.name.trim(),
        "type": "Processor",
        "apikey": this.apikey,
        "modifierid": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
        "status": this.myFlagForSlideToggle == true ? 'active' : 'passive',
        "jsonconfig": JSON.stringify(this.configjson),
        "description": this.processorNotes,
        "processortype": this.processortype
      };
      this.ngxUiLoaderService.start();
      var responseroutedata = await this.service.postapi('api/Agents/SaveAgentAndConfig', saveagententity);
      if (responseroutedata['status'] && responseroutedata['statusText']) {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Failed to save agent ' + responseroutedata['statusText'], "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {

          this.toastr.success("Processor Saved Successfully", "",
            { timeOut: 4000, positionClass: 'toast-bottom-right' });

        if (this.oldvalue != '') {
          this.newvalue = JSON.stringify(responseroutedata);
        }
        if (this.UniqueId == undefined || this.UniqueId == '') {
          this.UniqueId = responseroutedata["UniqueID"];
        }
        if (this.oldvalue == '' && this.newvalue == '') {
          this.oldvalue = "Processor saved successfully,Processor GUID is " + this.id;
        }

        var responseaudit = await this.service.auditsave("Save", this.oldvalue, this.newvalue, "Processor", this.UniqueId,'Agent Saved Successfully')
        if (responseaudit['status'] && responseaudit['statusText']) {
          this.toastr.error('Unable to Audit the transaction', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }

        this.ngxUiLoaderService.stop();

        this.autoclosetemplate("save");
      }
    }
  }

  autoclosetemplate(cnt) {
    setTimeout(() => {
      this.activeModal.close(cnt);
    }, 3000);
  }


  isUUID(uuid) {
    let s;
    s = uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }

  async validationchecks() {

    if (this.name == "") {
      this.toastr.warning('Agent Name is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }
    else if (this.selectedmessagetype == "" || this.selectedmessagetype == "select") {
      this.toastr.warning('Message type is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }
    // else if (this.sourceagentid == "" && this.destinationagentid == "") {
    //   this.toastr.warning('Source or Target agent is mandatory', "", {
    //     timeOut: 4000,
    //     positionClass: 'toast-bottom-right'
    //   });
    //   return false;
    // }
    else if (this.selectedinterfacetypevalue == "" || this.selectedinterfacetypevalue == "select") {
      this.toastr.warning('Interface type is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }
    else if (this.selectedsuffix == "" || this.selectedsuffix == "select") {
      this.toastr.warning('File Format is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }
    else if (this.processortype == "") {
      this.toastr.warning('Processor type is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }
    else if ((this.processortype == "General" || this.processortype == "Splitter") && this.processorurl == "") {
      this.toastr.warning('Processor URL is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }
    else {
      return true;
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  copyToClip(event: any) {
    if (event != '') {

      copyToClipboard(event);

      this.toastr.success('Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  keywordsearch: string = 'displayname';
  keywordsearchsa: string = 'displayname';
  SourceagentNameList: any = [];
  isLoadingsa: boolean = false;
  getSourceagentNames(event: any) {
    var val = event;
    if (val != '') {
      this.isLoadingsa = true;
      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=agent&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.SourceagentNameList = data as any[];
          this.SourceagentNameList.map(obj => obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}`);
          this.isLoadingsa = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingsa = false;
          this.notfounddata = true;
        }
      });
    }
  }

  selectedSourceagent(item) {
    this.sourceagentid = item.id;
  }

  searchSourceagnetCleared(item) {
    this.isLoadingsa = false;
    this.SourceagentNameList = [];
    this.sourceagentid = '';
    this.notfounddata = false;
  }


  keywordsearchta: string = 'displayname';
  TargetagentNameList: any = [];
  isLoadingta: boolean = false;
  getTargetagentNames(event: any) {
    var val = event;
    if (val != '') {
      this.isLoadingta = true;
      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=agent&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.TargetagentNameList = data as any[];
          this.TargetagentNameList.map(obj => obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}`);
          this.isLoadingta = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingta = false;
          this.notfounddata = true;
        }
      });
    }
  }

  selectedTargetagent(item) {
    this.destinationagentid = item.id;
  }

  searchTargetagnetCleared(item) {
    this.isLoadingta = false;
    this.TargetagentNameList = [];
    this.destinationagentid = '';
    this.notfounddata = false;
  }

  formatXml(xml: any) {
    var formatted = '';
    var reg = /(>)(<)(\/*)/g;
    xml = xml.replace(reg, '$1\r\n$2$3');
    var pad = 0;
    xml.split('\r\n').map((node: any, index: number) => {
      var indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad != 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      var padding = '';
      for (var i = 0; i < pad; i++) {
        padding += '  ';
      }

      formatted += padding + node + '\r\n';
      pad += indent;
    });
    this.inputtemplatexml = formatted;
    return this.inputtemplatexml;
  }

  processorurl: any = '';

  //mapping popup

  closemapping(d) {
    d('close modal');
  }
  inputtemplates: any = [];
  mappedtemplates: any = [];
  inputtemp: any = '';
  mappedtemp: any = '';

  gettemplates() {

    this.ngxUiLoaderService.start();
    var apiname = "api/GetMappings/GetTemplateXML";
    this.service.getapiurl(apiname).subscribe(data => {
      if (this.service.isprocessEditedMappingData !== "") {
        this.mappedxmlhold = this.service.isprocessEditedMappingData
      }
      this.templatelist = data
      let id = parseInt(this.inputtemplateidhold)
      let dataToModal = {
        templateid:id,
        templatelist: this.templatelist,
        //MappedXml: this.mappedxmlhold,
        MappedXml: this.mappedxmlhold,
        isprocessorEditmapping: true,
      };
      const modalRef = this.modalService.open(XmlmappingsComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = dataToModal;
      modalRef.result.then(() => { });

    })


  }

  oninputtemplateChange(value) {
    this.inputtemp = value;
    this.ngxUiLoaderService.start();

    const inputTemplate = this.inputtemplates.find(x => x.id == value).templatexml;
    const mappedTemplate = this.mappedtemplates.find(x => x.id == this.mappedtemp).templatexml;

    loadxml(inputTemplate, mappedTemplate, '');
    this.ngxUiLoaderService.stop();
  }

  onmappedtemplateChange(id) {
    this.mappedtemp = id;
    this.ngxUiLoaderService.start();

    const inputTemplate = this.inputtemplates.find(x => x.id == this.inputtemp).templatexml;
    const mappedTemplate = this.mappedtemplates.find(x => x.id == id).templatexml;

    loadxml(inputTemplate, mappedTemplate, '');
    this.ngxUiLoaderService.stop();
  }

  autoclosetemplatepopup(c) {
    setTimeout(() => {
      c('close modal');
    }, 3000);
  }

  mappedxmlhold: any = '';
  inputtemplateidhold: any = '';
  outputtemplateidhold: any = '';

  async savemappeddataDB(d) {
    var xml = await createMappedxml();

    this.mappedxmlhold = xml;
    this.inputtemplateidhold = this.inputtemp;
    this.outputtemplateidhold = this.mappedtemp;
    this.closemapping(d);
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.service.isprocessEditedMappingData = ""
  }

  async getalltemplates() {
    return await this.service.getapi("api/GetMappings/GetTemplateXML");
  }

  isLoadingstage: boolean = false;
  reactiveFormmsgtype: FormGroup;
  messageTypeAllList: any = [];
  async getMessagetypelist(event: any) {

    this.isLoadingstage = true;
    if (event !== 'msgtype') {
      if (event == undefined) {
        this.messageTypeList = this.messageTypeAllList;
      }
      else {
        if (this.messageTypeList.length == 0) {
          this.messageTypeList = this.messageTypeAllList;
        }
        this.messageTypeList = this.messageTypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageTypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingstage = false;
    }
    else {

      await this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: async data => {
          data.forEach(element => {
            this.messageTypeAllList.push({ displayname: element });
          });
          this.messageTypeList = this.messageTypeAllList;

          this.isLoadingstage = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingstage = false;
          this.notfounddata = true;
        }
      });
    }

  }

  selectedmessagetype: string = '';
  selectedmessagetypeevent(item) {
    this.selectedmessagetype = item.displayname;
  }

  Clearemessagetype(event) {
    this.selectedmessagetype = '';
    this.messageTypeList = this.messageTypeAllList;
  }

  reactiveFormfileformat: FormGroup;
  selectedsuffix: string = '';
  keyword: string = 'name';
  public dropdownList: any = [];
  // = [
  //   {
  //     name: '.hl7',
  //   },
  //   {
  //     name: '.xml',
  //   },
  //   {
  //     name: '.json',
  //   },
  //   {
  //     name: '.dot',
  //   },
  //   {
  //     name: '.pdf',
  //   },
  //   {
  //     name: '.csv',
  //   }
  // ];

  onFocused(e) {
    this.dropdownList = [
      {
        name: '.hl7',
      },
      {
        name: '.xml',
      },
      {
        name: '.json',
      },
      {
        name: '.dot',
      },
      {
        name: '.pdf',
      }, {
        name: '.csv',
      }
    ];
  }

  async selectEvent(item) {
    this.selectedsuffix = item.name;
  }

  clearedstatus(item) {
    this.selectedsuffix = '';
  }

  reactiveForminterfacetype: FormGroup;
  keywordvalue: string = 'item_text';
  selectinterfacetypeEvent(item) {
    this.selectedinterfacetypevalue = item.item_value;
  }
  oninterfacetypeFocused(item) {
    this.Interfacetypeslist = [
      { item_id: 1, item_text: "Inbound", checked: true, item_value: "Inbound" },
      { item_id: 2, item_text: "Outbound", checked: false, item_value: "Outbound" },
      { item_id: 3, item_text: "Ellkay Order Inbound", checked: false, item_value: "EllkayOrderInbound" },
      { item_id: 4, item_text: "Ellkay Result Outbound", checked: false, item_value: "EllkayResultOutbound" }
    ];
  }
  clearedinterfacetype(item) {
    this.selectedinterfacetypevalue = '';
  }

  reactiveFormprotype: FormGroup;
  public dropdownprotypeList: any = [];
  processortype: string = '';
  onProtypeFocused(e) {
    this.dropdownprotypeList = [
      {
        name: 'General',
      },
      {
        name: 'L2L',
      },
      {
        name: 'Splitter',
      },
      {
        name: 'Batch',
      }
    ];
  }
  configdiv: boolean = false;
  async selectProtypeEvent(item) {
    this.processortype = item.name;
    // if (this.processortype !== 'General') {
    //   this.configdiv = true;
    // }
    // else {
    //   this.configdiv = false;
    // }
  }

  clearedprotype(item) {
    this.processortype = '';
  }


  editorOptionsproconfig: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: "json",
    roundedSelection: true,
    autoIndent: "full",
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };
  editorproconfig: MonacoStandaloneCodeEditor;

  editorInitproconfig(editorproconfig: MonacoStandaloneCodeEditor) {
    this.editorproconfig = editorproconfig;
    editorproconfig.onDidChangeCursorPosition(() => {
      this.editorOptionsproconfig = { ...this.editorOptionsproconfig, language: "json" }
    });
  }





  csvsplitterconfig: any = {

    "vacsvsplitterconfig": {
      "isfiledownload": 0,
      "filedownloadurl": "",
      "isfileupload": 0,
      "fileuploadurl": "",
      "splitteragentid": "",
      "messagetype": ""
    },
    "csvresultsplitterconfig": {
      "validateinput": {
        "startswith": "Accession Number",
        "headerstartswith": "Accession Number",
        "validateheader": "Accession Number,Test Name"
      },
      "Multipurpose": 1,
      "ConfigDetails": {
        "Combinationrowcols": [
          null,
          1
        ],
        "TestName_SARS-CoV-2(COVID-19)": {
          "headercolumns": [
            {
              "type": "accessionnumber",
              "id": "Accession Number",
              "value": "0"
            },
            {
              "type": "Test Name",
              "id": "Test Name",
              "value": "0"
            },
            {
              "type": "nCoV_N1",
              "id": "nCoV_N1",
              "value": "0"
            },
            {
              "type": "nCoV_N2",
              "id": "nCoV_N2",
              "value": "0"
            },
            {
              "type": "nCoV_RP",
              "id": "nCoV_RP",
              "value": "0"
            },
            {
              "type": "Result",
              "id": "Result",
              "value": "0"
            },
            {
              "type": "Resulted At",
              "id": "Resulted At",
              "value": "0"
            }
          ],
          "addheaders": "Accession Number,Test Name,nCoV_N1,nCoV_N2,nCoV_RP,Result,Resulted At",
          "headerstartswith": "Accession Number",
          "splitcolumn": ",",
          "includeanalytesname": [],
          "ignoreSampleName": "blank",
          "ignoreSampleNameSatrtsWith": "QC,OF QC,ET QC,DB"
        },
        "TestName_BDPanel": {
          "headercolumns": [
            {
              "type": "accessionnumber",
              "id": "Accession Number",
              "value": "0"
            },
            {
              "type": "Test Name",
              "id": "Test Name",
              "value": "0"
            },
            {
              "type": "Result",
              "id": "Result",
              "value": "0"
            }
          ],
          "addheaders": "Accession Number,Test Name,Result",
          "headerstartswith": "Accession Number",
          "splitcolumn": ",",
          "includeanalytesname": [],
          "ignoreSampleName": "blank",
          "ignoreSampleNameSatrtsWith": "QC,OF QC,ET QC,DB"
        },
        "TestName_CareStartPanel": {
          "headercolumns": [
            {
              "type": "accessionnumber",
              "id": "Accession Number",
              "value": "0"
            },
            {
              "type": "Test Name",
              "id": "Test Name",
              "value": "0"
            },
            {
              "type": "Result",
              "id": "Result",
              "value": "0"
            }
          ],
          "addheaders": "Accession Number,Test Name,Result",
          "headerstartswith": "Accession Number",
          "splitcolumn": ",",
          "includeanalytesname": [],
          "ignoreSampleName": "blank",
          "ignoreSampleNameSatrtsWith": "QC,OF QC,ET QC,DB"
        }
      }
    }

  };

  xmlsplitterconfig: any = {

    "vaxmlsplitterconfig": {
      "isfileupload": 0,
      "fileuploadurl": "https://file.vitalaxis.net/api/v2/upload",
      "isfiledownload": 1,
      "filedownloadurl": "https://file.vitalaxis.net/api/v2/download/",
      "splitteragentid": "core.api.message.splitter",
      "messagetype": "order",
      "specialcharacter": {}
    },
    "xmlresultsplitterconfig": {
      "validateinput": {},
      "extendedattributes": {},
      "resultxmlmappings": {
        "XmlRecordMapping": {
          "keypath": "//CaseDetails/records",
          "mappings": {
            "CaseNumber": "//records/CaseNumber/text()"
          }
        }
      },
      "xmlfragment": {
        "CaseJson": ""
      },
      "mappinglist": []
    }
  };
  screeHeightforinput = window.innerHeight - 405;
  AgentConfigList: any[];
  AgentConfigListBackUp: any[];
  isselectedpipeline: boolean = false;
async  getcoreconfigforagent() {
    this.ngxUiLoaderService.start();
    var dataPost = {
      agentid: this.id,
      filtertype: 'Agent'
    };
   await this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost).then(Response => {
      if (Response) {
        if ((Response['status'] && Response['statusText']) || Response['statusText'] === "Unknown Error") {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        }
        else {
          this.AgentConfigList = Response as any[];
          this.AgentConfigListBackUp = this.AgentConfigList;
          this.ngxUiLoaderService.stop();
          if (this.AgentConfigList.length > 0) {
            this.AgentConfigList.forEach(ele => {
              if (ele.pipelinedisplayname) {
                let obj = {
                  name: ele.pipelinedisplayname,
                  displayname: `${ele.pipelinedisplayname}(${ele.guids})`,
                  id: ele.guids
                }
                this.filterpipelinelist.push(obj);
              }
            });
            if (this.filterpipelinelist.length > 0) {
              this.filterpipelinelist = [...new Map(this.filterpipelinelist.map(item =>
                [item["id"], item])).values()];
              this.filterpipelinebackuplist = this.filterpipelinelist;
            }
            this.reactiveFormPipelineFilter.setValue({ "filterpipeline": "" });
            this.ngxUiLoaderService.stop();
          }
        }
      }
    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    })
  }

  configstatus: any;
  configpipelinedisplayname: string = '';
  configentitydisplayname: string = '';
  configid: any = "";
  configguids: string = '';
  configagentid: string = '';
  configagentapikey: string = "";
  editconfigjson(data, array) {
    array.forEach(ele => {
      ele["active"] = false;
    })
    if (data.json != "{}" && data.json) {
      data["active"] = true;
      this.processorjsonconfig = JSON.stringify(JSON.parse(data.json), null, 2);
    }
    this.configagentid = data.agentid;
    this.configagentapikey = data.agentapikey;
    this.configid = data.configid;
    this.configguids = data.guids;
    this.configpipelinedisplayname = data.pipelinedisplayname;
    this.configentitydisplayname = data.entitydisplayname;
    this.configstatus = data.status;
  }

  openconfigpopup(isflag: string, type: string, item) {
    this.ngxUiLoaderService.start();
    if (isflag == "small") {
      let data = {};
      if (type == "create") {
        data = {
          pipelineid: '',
          pipelinedisplayname: '',
          entitydisplayname: '',
          editjson: "small",
          type: "create",
          configlist: this.AgentConfigList,
          disablepipeline: false,
          agentdisplayname: this.name,
          agentid: this.id,
          disableagent: true,
          guidtype: '',
          agentuniqueid: this.UniqueId
        }
      }
      else {
        data["configid"] = item.configid;
        data["agentid"] = item.agentid;
        data["json"] = item.json;
        if (item["guids"] && item["pipelinedisplayname"]) {
          data["pipelineid"] = item.guids;
          data["pipelinedisplayname"] = item.pipelinedisplayname;
          data["guidtype"] = "Pipeline";
        }
        else if (item["guids"] && item["entitydisplayname"]) {
          data["guids"] = item["guids"];
          data["entitydisplayname"] = item["entitydisplayname"];
          data["guidtype"] = "Entity";
          data["entitytype"] = item["entitytype"];
          data["entityaccounttype"] = item["entityaccounttype"];
          data["entitydeploymentkey"] = item["entitydeploymentkey"];
          data["entityaddress1"] = item["entityaddress1"];
          data["entityaddress2"] = item["entityaddress2"];
          data["entitycity"] = item["entitycity"];
          data["entitystate"] = item["entitystate"];
          data["entityzip"] = item["entityzip"];
        }
        else if (item["guids"]) {
          data["guids"] = item["guids"];
          data["guidtype"] = "Custom";
        }
        else {
          data["guids"] = '';
        }
        data["agentdisplayname"] = item.agentdisplayname;
        data["disableagent"] = true;
        data["editjson"] = "small";
        data["type"] = "Edit";
        data["disablepipeline"] = false;
        data["status"] = item.status;
        data["agentuniqueid"] = this.UniqueId;
        data["agentapikey"] = item.agentapikey
      }

      const modalRef = this.modalService.open(ConfigComponent,
        { windowClass: "modalConfig", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = data;
      modalRef.result.then((data) => {
        if (data == "refresh") {
          this.ngxUiLoaderService.start();
          this.getcoreconfigforagent();
        }
      });
    } else {
      let data = {
        editjson: "big",
        type: type
      }
      if (type != "create" && item == '') {
        data["configid"] = this.configid;
        data["agentid"] = this.configagentid;
        data["json"] = this.processorjsonconfig;
        data["guids"] = this.configguids ? this.configguids : ''
        data["status"] = this.configstatus;
      }
      else if (type != "create" && item != '') {
        data["configid"] = item.configid;
        data["agentid"] = item.agentid;
        data["json"] = item.json;
        data["guids"] = item.guids;
        data["status"] = item.status;
      }
      data["agentuniqueid"] = this.UniqueId;
      data["agentapikey"] = item.agentapikey ? item.agentapikey : this.configagentapikey
      const modalRef = this.modalService.open(ConfigComponent,
        { windowClass: "modalConfigJSON", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = data;
      modalRef.result.then((data) => {
        if (data == "refresh") {
          this.ngxUiLoaderService.start();
          this.configagentid = "";
          this.configid = "";
          this.processorjsonconfig = "";
          this.configstatus = "";
          this.configagentapikey = "";
          this.getcoreconfigforagent();
          this.ngxUiLoaderService.stop();
        }
      });
    }
    this.ngxUiLoaderService.stop();
  }

  filterpipelinelist = <any>[];
  filterpipelinebackuplist = <any>[];
  selectedFilteredValues(item) {
    if (item) {
      this.isselectedpipeline = true;
      this.AgentConfigList = this.AgentConfigList.filter(x => (x.guids != "") && (x.guids == item.id));
    }
  }
  searchFilteredValuesCleared(item) {
    if (this.AgentConfigList.length != this.AgentConfigListBackUp.length) {
      this.configagentid = "";
      this.configguids = "";
      this.configid = "";
      this.processorjsonconfig = "";
      this.configstatus = '';
      this.isselectedpipeline = false;
      this.AgentConfigList = this.AgentConfigListBackUp;
    }
    this.filterpipelinelist = this.filterpipelinebackuplist;
    this.notfounddata = false;
    this.reactiveFormPipelineFilter.reset();
    this.reactiveFormPipelineFilter.setValue({ "filterpipeline": "" })
  }
  isLoadingFilteredValue: boolean;
  getFilteredValues(event) {
    if (event) {
      this.isLoadingFilteredValue = true;
      this.ngxUiLoaderService.start();
      if (this.filterpipelinelist.length == 0) {
        this.filterpipelinelist = this.filterpipelinebackuplist;
      }
      let isfound = false;
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      if (regexExp.test(event)) {
        isfound = true;
        this.filterpipelinelist = this.filterpipelinelist.filter(x => x.id == event);
      }
      else {
        isfound = true;
        this.filterpipelinelist = this.filterpipelinelist.filter(x => x.name.toLowerCase().includes(event.toLowerCase()));
      }
      if (isfound) {
        this.configagentid = "";
        this.configguids = "";
        this.configid = "";
        this.processorjsonconfig = "";
        this.configstatus = '';
        if (this.filterpipelinelist.length == 0) {
          this.notfounddata = true;
        }
        this.isLoadingFilteredValue = false;
      }
      this.ngxUiLoaderService.stop();
    }
  }
}

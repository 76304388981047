import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { v4 as uuidv4 } from 'uuid';

export class FileNode {
  id: string;
  children: FileNode[];
  filename: string;
  type: any;

}



/** Flat node with expandable and level information */
export class FileFlatNode {
  constructor(
    public expandable: boolean,
    public filename: string,
    public level: number,
    public type: any,
    public id: string,
    public GUID:any,
    public isNode:string,
    public color:boolean
  ) { }
}

/**
 * The file structure tree data in string. The data could be parsed into a Json object
 */
const TREE_DATA :any =[]


@Injectable({
  providedIn: "root"
})
export class FileDatabase {
  xlmdata;
  uniqidentifier: any;
  dataChange = new BehaviorSubject<FileNode[]>([]);
  // get data(): FileNode[] { return this.dataChange.value; }
  constructor() {
    // this.initialize(TREE_DATA, '')
  }
  initialize(dat, key) {
    // if (dat.length > 0) {
      let dataObject
      if(typeof dat=== 'string'){
        dataObject = JSON.parse(dat)
      }else{
        dataObject = dat
      }

      const datas = this.buildFileTree(dataObject, 0);
      if (key === "xml") {
        this.xlmdata = datas
      }
      this.dataChange.next(datas);
    // }
  }
  buildFileTree(obj: { [key: string]: any }, level: number, parentId: string = '0'): FileNode[] {
    return Object.keys(obj).reduce<FileNode[]>((accumulator, key, idx) => {

      if (key !== "attr" && typeof obj === "object") {
        const value = obj[key];
        if (value != null) {
          if (typeof value === 'object') {
            let node
            if (value.length > 0) {
              let arrayvalus = []
              for (let i = 0; i < value.length; i++) {
                // node.filename = key;
                node = new FileNode();
                if (key.includes("_")) {

                  key = this.findGuid(key);
                }
                node.filename = key;
                node.id = `${parentId}/${idx}`;
                if (value[i].attr ? value[i].attr._NodeIdentifier !== undefined : false) {
                  node['GUID'] = value[i].attr._NodeIdentifier;
                }
                node['isNode'] = "Parent";

                if (typeof value[i] === "string") {
                  if(obj.attr != undefined){
                    if (value.attr ? value.attr._NodeIdentifier !== undefined : false) {
                      this.uniqidentifier = value.attr._NodeIdentifier;
                      node['GUID'] = value.attr._NodeIdentifier;
                    }else{

                      this.uniqidentifier = obj.attr._NodeIdentifier
                    }
                  }
                }
                node.children = this.buildFileTree(value[i], level + 1, node.id);
                arrayvalus.push(node)
              }
              return accumulator.concat(arrayvalus)
            }else{
              const node = new FileNode();
              if(key.includes("_")){

                key =this.findGuid(key);
              }
              node.filename = key;
              node.id = `${parentId}/${idx}`;
              node['isNode']="Parent";
              node.children = this.buildFileTree(value, level + 1, node.id);
              if(value.attr?value.attr._NodeIdentifier !== undefined:false){
                node['GUID'] = value.attr._NodeIdentifier;
              }
              return accumulator.concat(node);
            }
          } else {
            const node = new FileNode();
            if(key !== "_"){
              if(key.includes("_")){

                key =this.findGuid(key);
              }
              node.filename = key;
              node.id = `${parentId}/${idx}`;
              node.type = value;
              node['isNode']="Child";

              if(value.attr?value.attr._NodeIdentifier !== undefined:false){
                node['GUID'] = value.attr._NodeIdentifier;
              }else if(obj.attr?obj.attr._NodeIdentifier !== undefined:false){
                node['GUID'] = obj.attr._NodeIdentifier;
              }

              return accumulator.concat(node);
            }else{
              const node = new FileNode();
              node.filename = "Node_Text";
              node.id = `${parentId}/${idx}`;
              node.type = value;
              node['isNode'] = "innerChild";
              if (this.uniqidentifier !== undefined) {
                node['GUID'] = this.uniqidentifier;
              }
              return accumulator.concat(node);

            }

          }
        }
      } else if (typeof obj === "object") {
        let nodes
        for (let [keys, values] of Object.entries(obj[key])) {
          const value = values;
	  //  if (keys.includes("_")) {
    //             keys = keys.split("_")[0]
    //   }
          nodes = new FileNode();
          nodes.filename = keys;
          nodes.id = `${parentId}/${idx}`;
          if (value != null) {
            if (typeof value === 'object') {

              nodes.children = this.buildFileTree(value, level + 1, nodes.id);
              nodes['isNode'] = "Parent";
              if (obj[key] ? obj[key]._NodeIdentifier !== undefined : false) {
                nodes['GUID'] = obj[key]._NodeIdentifier
              }
            } else {
              nodes.type = values;
              nodes['isNode'] = "Child";
              if (obj[key] ? obj[key]._NodeIdentifier !== undefined : false) {
                nodes['GUID'] = obj[key]._NodeIdentifier;
              }
            }
          }
          accumulator.push(nodes);
        }
      } else if (typeof obj === "string" && idx === 0) {

        if(obj === 'Case/OrderDetails/Order/@ICDCodes'){

        }

        const node = new FileNode();
        node.filename = "Node_Text";
        node.id = `${parentId}/${idx}`;
        node.type = obj;
        node['isNode'] = "innerChild";
        if (this.uniqidentifier !== undefined) {
          node['GUID'] = this.uniqidentifier;
        }
        return accumulator.concat(node);
      }
      return accumulator;
    }, []);
  }
  findGuid(input){
    let inputArray=input.split('_');
    for(let i=0;i<inputArray.length;i++){
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      if (regexExp.test(inputArray[i])) {
        inputArray.pop();
        i--;
      }
    }
    if(inputArray[0] !== ''){
    return inputArray.join('_');
    }
  }
}

@Injectable({
  providedIn: "root"
})
export class CounterService {
  public count = 0;
  public instanceList =[]
  public backupdatainput={}
  public position=""
}

import { Component, OnInit } from "@angular/core";
import { CommonService } from "../services/common.service";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { P, V } from "@angular/cdk/keycodes";
import { string32 } from "pdfjs-dist/types/src/shared/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FtpfilesComponent } from "../ftpfiles/ftpfiles.component";
import {
  MonacoEditorConstructionOptions,
  MonacoStandaloneCodeEditor,
} from "@materia-ui/ngx-monaco-editor";
import { HubmessagesComponent } from "../hubmessages/hubmessages.component";
declare const copyToClipboard: any;

@Component({
  selector: "app-caseflow",
  templateUrl: "./caseflow.component.html",
  styleUrls: ["./caseflow.component.scss"],
})
export class CaseflowComponent implements OnInit {
  LogLabOrgList: any[];
  isReGenerate: boolean;
  searchcaseText: any = "";
  caseFailureList: any[] = [];
  caseFailureListBackup: any[] = [];
  pagerolepermisions: any = [];
  viewHL7Files: any;
  viewfileHandlerName: any;
  viewfileRoutguid: any;
  EventList: any[] = [];
  maincard: boolean = false;
  Case360FormGroup: FormGroup = this._formBuilder.group({
    searchvalue: ["", Validators.required],
  });
  formatType: string = "plaintext";
  eventlist = {};
  selectedDeployement = "";
  LabOrgList = <any>[];
  LabOrgLists = <any>[];
  isLoadingEventLabOrg: boolean;
  notfounddata: boolean;
  selectedEvent: any;

  searchParameterInfo = true;
  caseDataList = [];
  caseDataListBackup = {};
  caseDataListCard: unknown = [];
  // deploymentInfo = [
  //   {
  //     depKeys: [
  //       "BCM",
  //       "LIS",
  //       "MYLIS",
  //       "QUEST",
  //       "SAGIS",
  //       "THX",
  //       "THXVAR",
  //       "TLA",
  //       "UPM",
  //       "VPLABS",
  //     ],
  //     depURLs: [
  //       "https://bcm.vitalaxis.com/",
  //       "https://lis.vitalaxis.com/",
  //       "https://mylis.vitalaxis.com/",
  //       "https://quest.vitalpath.net/",
  //       "https://sagis.vitalaxis.com/",
  //       "https://thx.vitalaxis.com/",
  //       "https://thxvar.vitalaxis.com/",
  //       "https://thxvar.vitalaxis.com/",
  //       "https://tla.vitalaxis.com/",
  //       "https://upm.vitalaxis.com/",
  //       "https://labs.vitalpath.net/",
  //     ],
  //   },
  // ];

  nameList = [
    { iskey: 102, isname: "Result Outbound" },
    { iskey: 156, isname: "Result Outbound" },
    { iskey: 205, isname: "Result Outbound" },
    { iskey: 217, isname: "Result Outbound" },
    { iskey: 103, isname: "DFT" },
    { iskey: 173, isname: "BAR" },
    { iskey: 51, isname: " Manual Fax" },
    { iskey: 1, isname: " Auto Fax" },
    { iskey: 7, isname: "Network Printing" },
    { iskey: 57, isname: " Network Printing" },
    { iskey: 125, isname: " Order Outbound" },
    { iskey: 127, isname: " Order Outbound" },
    { iskey: 131, isname: " Order Outbound" },
    { iskey: 133, isname: " Order Outbound" },
    { iskey: 135, isname: "Order Outbound" },
    { iskey: 3001, isname: "Order Outbound" },
    { iskey: 130, isname: "Order Outbound" },
    { iskey: 170, isname: "Order Outbound" },
    { iskey: 202, isname: "Order Outbound" },
    { iskey: 204, isname: "Order Outbound" },
    { iskey: 101, isname: "Order Outbound" },
  ];
  selectedmesssage: any;
  dxLogStatus = [];
  mediatypedisplay: boolean;
  selectedfilecontent: ArrayBufferLike;
  searchDeployement: any;
  constructor(
    public service: CommonService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.selectedEvent = "Accession Number";
    const menuid = JSON.parse(sessionStorage.getItem("rolewithmenus"))
      .find((x) => x.RoleName == sessionStorage.getItem("SelectedUserRole"))
      .menuslist.find((y) => y.MenuDisplayName == "Case 360").MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem("rolewithmenus"))
      .find((x) => x.RoleName == sessionStorage.getItem("SelectedUserRole"))
      .menuslist.filter((y) => y.ParentMenuId == menuid);
    actionitems.forEach((element) => {
      this.pagerolepermisions[element.MenuName] = true;
    });

    this.service.getlookupInfo("EventGroups").subscribe(
      (data) => {
        if (data) {
          this.nameList = data;
        }
      },
      (error) => {
        this.toastr.error(error.statusText, "Failed", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
    );
    this.service.getlookupInfo("DxLogStatus").subscribe(
      (data) => {
        if (data) {
          this.dxLogStatus = data;
          ////console.log(data);
        }
      },
      (error) => {
        this.toastr.error(error.statusText, "Failed", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
    );
  }
  copymessage(value, message) {
    copyToClipboard(value);

    this.toastr.success(message + " Copied", "", {
      timeOut: 4000,
      positionClass: "toast-bottom-right",
    });
  }

  //  Search Methods starts
  searchCase360() {
    if (this.searchcaseText !== "" && this.Case360FormGroup.valid) {
      let str = this.searchcaseText.split(":");
      if (str.length > 3 || str.length == 1) {
        if (str.length == 1) {
          this.toastr.warning(
            "Invalid Search",
            "Search values should be minimum two parameters.",
            {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            }
          );
        } else {
          this.toastr.warning(
            "Invalid Search",
            "Search values shouldn't be more than three parameters.",
            {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            }
          );
        }
      } else {
        this.ngxUiLoaderService.start();
        this.service.case360(this.searchcaseText).subscribe(
          (data) => {
            this.ngxUiLoaderService.stop();
            if (data.length > 0) {
              this.searchParameterInfo = false;
              this.searchDeployement =this.searchcaseText.split(":")[0],
              // this.caseDataListCard =  JSON.parse(JSON.stringify(data))
              this.caseDataList =JSON.parse(JSON.stringify(data))
              data;
              for (let i = 0; i < this.caseDataList.length; i++) {
                this.caseJson(this.caseDataList[i]);
                this.ChangeStatus(this.caseDataList[i].hl7logs);
                this.caseDataList[i]["allEvents"] = JSON.parse(
                  JSON.stringify(this.caseDataList[i].events)
                );
                this.caseDataList[i]["failedEvents"] = this.failureJson(
                 JSON.parse(JSON.stringify(this.caseDataList[i]))
                );
                if (this.caseDataList[i].hubMessages.hubmessagedata !== null) {
                  let obj = {
                    eventdata: "",
                    eventid: 37225077,
                    eventoccuredat: "",
                    eventstatus: "",
                    eventtype: "In Bound",
                    objectid: "",
                    objecttype: 1,
                    status: 0,
                    messages: this.caseDataList[i].hubMessages,
                    hl7logs: [],
                  };
                  this.caseDataList[i]["allEvents"].unshift(obj)
                }
              }
              // this.caseDataList =[ this.caseDataList[this.caseDataList.length -1]]
              this.caseDataListBackup = JSON.parse(
                JSON.stringify(this.caseDataList)
              );
              ////console.log(this.caseDataList)
              // if(caseinformation !== undefined){
              //   this.caseDataList = JSON.parse(JSON.stringify(caseinformation));
              //   this.caseDataListBackup = JSON.parse(
              //     JSON.stringify(caseinformation)
              //   );
              // }
              // if (
              //   this.caseDataList !== undefined &&
              //   this.caseDataList !== null
              // ) {

              // if(failuredate !== undefined){
              //   this.caseFailureList = JSON.parse(JSON.stringify(failuredate));
              //   this.caseFailureListBackup = JSON.parse(
              //     JSON.stringify(failuredate)
              //   );
              // }
              // }

              this.caseDataList;
            } else {
              this.toastr.warning("Case data could not be found. Please try again with different input.", "Alert", {
                positionClass: "toast-bottom-right",
                timeOut: 4000,
              });
              this.cleardata();
              this.searchDeployement ='';
            }
          },
          (error) => {
            this.ngxUiLoaderService.stop();
            this.toastr.error(error.statusText, "Failed", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
            this.cleardata();
            this.searchDeployement ='';
          }
        );
      }
      // this.ngxUiLoaderService.stop()
    } else {
      this.toastr.warning("required fields are empty", "Search", {
        positionClass: "toast-bottom-right",
        timeOut: 4000,
      });
    }
  }

  selectDetails() {}
  searchHandler(data, caseindex, index, indentify, event) {
    if (indentify == "all") {
      if (event.target.value !== "") {
        this.caseDataList[caseindex]["allEvents"][index].hl7logs =
          this.caseDataListBackup[caseindex]["allEvents"][index].hl7logs.filter(
            (va) =>
              va.handler
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
          );
      } else {
        this.caseDataList[caseindex]["allEvents"][index].hl7logs =
          this.caseDataListBackup[caseindex]["allEvents"][index].hl7logs;
      }
    } else {
      if (event.target.value !== "") {
        this.caseDataList[caseindex]["failedEvents"][index].hl7logs =
          this.caseDataListBackup[caseindex]["failedEvents"][
            index
          ].hl7logs.filter((va) =>
            va.handler.toLowerCase().includes(event.target.value.toLowerCase())
          );
      } else {
        this.caseDataList[caseindex]["failedEvents"][index].hl7logs =
          this.caseDataListBackup[caseindex]["failedEvents"][index].hl7logs;
      }
    }
  }

  // Search Methods Ends

  // Reusable Methods starts Here
  ChangeStatus(value) {
    if (value.length > 0) {
      value.forEach((element) => {
        let uniqvalue = this.dxLogStatus.find(
          (va) => va.lookupid == element.logstatus
        );
        if (uniqvalue !== undefined) {
          element["status"] = uniqvalue.lookupvalue;
        } else {
          element["status"] = element.logstatus;
        }
      });
    }
  }
  eventborderstatus(value) {
    if (value == 1) {
      return "eventNew pl-0";
    } else if (value == 2) {
      return "eventSuccess pl-0'";
    } else if (value == 3) {
      return "eventfailed pl-0";
    } else {
      return "pl-0";
    }
  }

  caseJson(casevalue) {
    if (casevalue.events !== null && casevalue.events.length > 0) {
      let dataarrya = [
        ...new Set(casevalue.events.map((item) => item.eventtype)),
      ];
      if (dataarrya.length > 0) {
        let results,
          dataresults = [];
        this.isReGenerate = false;
        for (let k = 0; k < dataarrya.length; k++) {
          if (dataarrya[k] == 102) {
            this.isReGenerate = true;
          }
          results = [
            casevalue.events
              .filter((va) => va.eventtype == dataarrya[k])
              .sort((a: any, b: any) => b.eventid - a.eventid)[0],
          ];
          dataresults = dataresults.concat(results);
        }
        casevalue.events = dataresults;
      }
      casevalue.events.forEach((element) => {
        let versionid;
        if (element.eventtype == 1 || element.eventtype == 51) {
          versionid = casevalue["hl7logs"].filter((va) => va.handler == "2");
        } else if (element.eventtype == 7 || element.eventtype == 57) {
          versionid = casevalue["hl7logs"].filter((va) => va.handler == "6");
        } else {
          versionid = casevalue["hl7logs"].filter(
            (va) => va.entityname == element.eventtype.toString()
          );
        }

        if (versionid.length > 0) {
          element["hl7logs"];
          this.getlogsuniq(versionid);
          versionid.map((va) => (va["reprocess"] = false));
          let uniq;
          if (
            element.eventtype !== 57 &&
            element.eventtype !== 7 &&
            element.eventtype !== 1 &&
            element.eventtype !== 51
          ) {
            uniq = versionid.filter(
              (va) => va.handlerdata == element.eventdata
            );
          } else {
            uniq = versionid;
          }
          if (uniq.length > 0) {
            uniq.forEach((va) => {
              let uniqarr = [];
              if (
                element.eventtype !== 57 &&
                element.eventtype !== 7 &&
                element.eventtype !== 1 &&
                element.eventtype !== 51
              ) {
                uniqarr = uniq.filter((vab) => vab.routeguid == va.routeguid);
              } else {
                uniqarr = uniq;
              }
              if (uniqarr.length > 0) {
                uniqarr = uniqarr.sort((a: any, b: any) => b.logid - a.logid);
                if (element.eventtype !== 103) {
                  if (uniqarr[0].logstatus.toLowerCase() !== "new") {
                    uniqarr[0]["reprocess"] = true;
                  }
                } else if (element.eventtype == 103) {
                  if (
                    uniqarr[0].logstatus.toLowerCase() !== "new" &&
                    uniqarr[0].logstatus.toLowerCase() !== "success"
                  ) {
                    uniqarr[0]["reprocess"] = true;
                  }
                }
              }
            });
          }
          versionid = versionid.sort((a: any, b: any) => b.logid - a.logid);
          if (versionid.length > 0) {
            element["hl7logs"] = versionid;
          } else {
            element["hl7logs"] = [];
          }
        } else {
          element["hl7logs"] = [];
        }
      });
    }
  }

  failureJson(caseData: object) {
    if (caseData["events"]) {
      let dataarrya = [
        ...new Set(caseData["events"].map((item) => item.eventtype)),
      ];
      if (dataarrya.length > 0) {
        let results,
          dataresults = [];
        for (let k = 0; k < dataarrya.length; k++) {
          results = [
            caseData["events"]
              .filter((va) => va.eventtype == dataarrya[k])
              .sort((a: any, b: any) => b.eventid - a.eventid)[0],
          ];
          dataresults = dataresults.concat(results);
        }
        caseData["events"] = dataresults;
      }
      let failureEvents = [];
      caseData["events"].forEach((element) => {
        let versionid;
        if (element.eventtype == 1 || element.eventtype == 51) {
          versionid = caseData["hl7logs"].filter(
            (va) =>
              (va.handler == "2" && va.logstatus.toLowerCase() == "failed") ||
              (va.entityname == element.eventtype.toString() &&
                va.logstatus.toLowerCase() == "failure") ||
              (va.entityname == element.eventtype.toString() &&
                va.logstatus.toLowerCase() == "failed fax")
          );
        } else if (element.eventtype == 7 || element.eventtype == 57) {
          versionid = caseData["hl7logs"].filter(
            (va) =>
              (va.handler == "6" && va.logstatus.toLowerCase() == "failed") ||
              (va.entityname == element.eventtype.toString() &&
                va.logstatus.toLowerCase() == "failure")
          );
        } else {
          versionid = caseData["hl7logs"].filter(
            (va) =>
              (va.entityname == element.eventtype.toString() &&
                va.logstatus.toLowerCase() == "failed") ||
              (va.entityname == element.eventtype.toString() &&
                va.logstatus.toLowerCase() == "failure")
          );
        }

        if (versionid.length > 0) {
          element["hl7logs"];
          this.getlogsuniq(versionid);
          versionid.map((va) => (va["reprocess"] = false));
          let uniq;
          if (
            element.eventtype !== 57 &&
            element.eventtype !== 7 &&
            element.eventtype !== 1 &&
            element.eventtype !== 51
          ) {
            uniq = versionid.filter(
              (va) => va.handlerdata == element.eventdata
            );
          } else {
            uniq = versionid;
          }
          if (uniq.length > 0) {
            uniq.forEach((va) => {
              let uniqarr = [];
              if (
                element.eventtype !== 57 &&
                element.eventtype !== 7 &&
                element.eventtype !== 1 &&
                element.eventtype !== 51
              ) {
                uniqarr = uniq.filter((vab) => vab.routeguid == va.routeguid);
              } else {
                uniqarr = uniq;
              }
              if (uniqarr.length > 0) {
                uniqarr = uniqarr.sort((a: any, b: any) => b.logid - a.logid);
                if (element.eventtype !== 103) {
                  if (uniqarr[0].logstatus.toLowerCase() !== "new") {
                    uniqarr[0]["reprocess"] = true;
                  }
                } else if (element.eventtype == 103) {
                  if (
                    uniqarr[0].logstatus.toLowerCase() !== "new" &&
                    uniqarr[0].logstatus.toLowerCase() !== "success"
                  ) {
                    uniqarr[0]["reprocess"] = true;
                  }
                }
              }
            });
          }
          versionid = versionid.sort((a: any, b: any) => b.logid - a.logid);
          if (versionid.length > 0) {
            element["hl7logs"] = versionid;
            failureEvents.push(element);
          } else {
            if (element.status == 3) {
              element["hl7logs"] = [];
              failureEvents.push(element);
            }
          }
        } else {
          if (element.status == 3) {
            element["hl7logs"] = [];
            failureEvents.push(element);
          }
        }
      });
      return failureEvents;
    }
  }

  getlogsuniq(value) {
    if (value.length > 0) {
      value.forEach((va) => {
        va;
        let uniqarr = value.filter((vab) => vab.routeguid == va.routeguid);
        if (uniqarr.length > 0) {
          uniqarr = uniqarr.sort((a: any, b: any) => b.logid - a.logid);
          if (uniqarr[0].logstatus.toLowerCase() !== "new") {
            uniqarr[0]["reprocess"] = true;
          }
        }
      });
    }
  }
  createhandlerTitle(handler:any,interfacename:any,groupid:any){
   return `
   Handler :  ${handler !== null? handler:'N/A' || handler !== '' ? handler:'N/A'  || handler == null ? 'N/A':handler}
   Interface Name :  ${interfacename !== null ? interfacename:'N/A' || interfacename !== '' ? interfacename:'N/A' ||  interfacename == null ? 'N/A': interfacename}
   Groupid :  ${groupid !== null? groupid:'N/A' || groupid !== '' ? groupid:'N/A'  || groupid == null ? 'N/A':groupid}
     `
  }
  copymessageHandler(handler:any,interfacename:any,groupid:any){
    let value =  `
    Handler :  ${handler !== null? handler:'N/A' || handler !== '' ? handler:'N/A' || handler == null ? 'N/A':handler}
    Interface Name :  ${interfacename !== null ? interfacename:'N/A' || interfacename !== '' ? interfacename:'N/A' ||  interfacename == null ? 'N/A': interfacename}
    Groupid :  ${groupid !== null? groupid:'N/A' || groupid !== '' ? groupid:'N/A'  || groupid == null ? 'N/A':groupid}`
      copyToClipboard(value);

      this.toastr.success('Handler' + " Copied", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
  }
  isNamSet(isvalue: unknown) {
    let name = this.nameList.find((va) => parseInt(va["lookupid"]) == isvalue);
    if (name != undefined) {
      return name["lookupvalue"];
    } else {
      return isvalue;
    }
  }
  //  Reusable Methods END Here

  logReprocess(item: any) {
    if (item) {
      let obj = {
        EventId: 0,
        RouteUniqueId: item.routeuniqueid,
        RouteId: item.routeguid,
        LogId: item.logid,
        Deploymentkey: this.searchDeployement,
        NewEventId: 0,
        NewHl7LogId: 0,
        tabletype: item.tabletype,
        batchId: item.batchId,
        Modifiedby: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
          .userdata["userid"],
      };
      this.ngxUiLoaderService.start();
      item.disabled = false;
      this.service.CaseEventAndLogReprocess(obj).subscribe(
        (data) => {
          this.ngxUiLoaderService.stop();
          if (data) {
            this.toastr.warning("Log Reprocess Initiated", "Initiated", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
            item.disabled = true;
            setTimeout(() => {
              this.searchCase360();
            }, 5000);
          }
        },
        (error) => {
          item.disabled = false;
          this.ngxUiLoaderService.stop();
          this.toastr.error("Handler Reprocess Failed", "Failed", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
      );
    }
  }
  Eventregenerate(accnumber: string) {
    let obj = {
      Requestdata: this.searchcaseText.split(":")[0] + ":" + accnumber,
    };

    this.ngxUiLoaderService.start();
    this.service.CaseEventAndLogRegenerate(obj).subscribe((data) => {
      this.ngxUiLoaderService.stop();
      if (data.status == "Success") {
        this.toastr.success("Successful", "Regeneration", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
    });
  }
  EventReprocess(item: any) {
    if (item) {
      let obj = {
        EventId: item.eventid,
        RouteUniqueId: 0,
        RouteId: 0,
        LogId: 0,
        Deploymentkey: this.searchDeployement,
        NewEventId: 0,
        NewHl7LogId: 0,
        tabletype: "",
        Modifiedby: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
          .userdata["userid"],
      };
      item.isdisabled = false;
      this.ngxUiLoaderService.start();
      this.service.CaseEventAndLogReprocess(obj).subscribe(
        (data) => {
          this.ngxUiLoaderService.stop();
          if (data) {
            this.toastr.warning("Event Reprocess Initiated", "Initiated", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
            item.isdisabled = true;
          }
        },
        (error) => {
          item.isdisabled = false;
          this.ngxUiLoaderService.stop();
          this.toastr.error("Event Reprocess Failed", "Failed", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
      );
    }
  }

  openFTPfilespopup(item) {
    const dataToFTPFilesModal = {
      routeId: item.routeguid,
      sourceagent: item.sourceagent,
      name: item.handler,
      uniqid: item.routeuniqueid,
      selectflag: false,
    };
    const modalRef = this.modalService.open(FtpfilesComponent, {
      windowClass: "myCustomModalClassFTP",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.inputDataInModalComponent = dataToFTPFilesModal;
    modalRef.result.then(() => {});
  }
  openMessage(item, modalrefid) {
    if (item.routeguid != null) {
      this.viewfileHandlerName = item.handler;
      this.viewfileRoutguid = item.routeguid;
      this.selectedmesssage = {
        routeid: item.routeguid,
        transaction: item.logid,
      };
      this.modalService
        .open(modalrefid, {
          windowClass: "viewfiles",
          backdrop: "static",
          keyboard: false,
        })
        .result.then((result) => {});
    } else {
      this.toastr.warning("", "No data Found", {
        positionClass: "toast-bottom-right",
        timeOut: 4000,
      });
    }
  }

  properDateFormat(value) {
    let date, time;
    if (value !== null && value !== undefined) {
      if (value.includes("T")) {
        date = value.split("T")[0];
        if (date == "1900-01-01") {
          return "N/A";
        }
        time = value.split("T")[1].split("Z")[0];
        return date + " " + time;
      } else {
        return value;
      }
    }
  }
  properdate(value) {
    let date, time;
    if (value !== null && value !== undefined) {
      if (value.includes("T")) {
        date = value.split("T")[0];
        time = value.split("T")[1];
        return date + " " + time;
      } else {
        return value;
      }
    }
  }

  clearCaseFlow() {
    this.Case360FormGroup.patchValue({
      searchvalue: "",
    });
  }

  locationString(item, item1) {
    return "(" + item + ")" + " " + item1;
  }
  resetform() {
    this.searchcaseText = "";
    this.Case360FormGroup.reset();
    this.cleardata();
  }
  cleardata() {
    this.LogLabOrgList = [];
    this.EventList = [];
    this.selectedDeployement = "";
    this.maincard = false;
    this.caseDataList = [];
    this.caseFailureList = [];
    this.caseDataList = [];
    this.searchParameterInfo = true;
    this.caseDataListBackup = [];
    this.caseFailureListBackup = [];
  }
  editorOptions1: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: true,
    wordWrap: "on",
    minimap: {
      enabled: false,
    },
  };
  closemessageiotpopup(c) {
    this.editorOptions1.readOnly = true;
    // this.messageiotreprocess = false;
    this.viewHL7Files = "";
    c("close modal");
  }
  ViewFileData(item: any, modalmessageiot) {
    this.ngxUiLoaderService.start();
    this.service.viewFileCase360(item.logid, item.routeguid).subscribe(
      (data) => {
        if (data.status == "failure") {
          this.ngxUiLoaderService.stop();
          this.toastr.warning("File not available", "", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        } else {
          let modalsize = "";
          this.ngxUiLoaderService.stop();
          data.responsebody = JSON.parse(data.responsebody);
          if (data.responsebody.fileformat == "pdf") {
            modalsize = "viewpdffile";
            this.mediatypedisplay = false;
            this.selectedfilecontent = this._base64ToArrayBuffer(
              data.responsebody.filecontent
            );
          } else {
            this.mediatypedisplay = true;
            modalsize = "viewfiles";
            this.viewHL7Files = data.responsebody.filecontent;
          }
          this.viewfileHandlerName = item.RouteName;
          this.viewfileRoutguid = item.routeguid;
          this.modalService
            .open(modalmessageiot, {
              windowClass: modalsize,
              backdrop: "static",
              keyboard: false,
            })
            .result.then((result) => {});
        }
      },
      (error) => {
        this.ngxUiLoaderService.stop();
        this.toastr.error(error.statusText, "Failed", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
    );
  }




  _base64ToArrayBuffer(base64) {
    var binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  changeLabel(value) {
    if (value.includes("(")) {
      return (value = value.split("(")[0]);
    } else {
      return value;
    }
  }
  editor: MonacoStandaloneCodeEditor;
  editorInit(editor: MonacoStandaloneCodeEditor, type) {
    this.editor = editor;
    let formatType;
    editor.onDidChangeCursorPosition(() => {
      if (type == "input") {
        this.editorOptions1 = { ...this.editorOptions1, language: "hl7" };
      }
    });
  }
}

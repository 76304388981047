import { CommonService } from 'src/app/services/common.service';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigService } from '../configurations/app-config.service';
import {  ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoginService } from '../login/login.service';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  urlSafe: SafeResourceUrl;
  classObjalertmain: string;
  StatusMessagedisplay: string;
  isSessionOver: boolean = false;
  protected apiURL: string = AppConfigService.settings.WebApiURL;
  protected UserLogin: string = AppConfigService.settings.UserLogin;

  constructor(private service: CommonService, private sanitizer: DomSanitizer,
    private loginService: LoginService, private toastr: ToastrService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        if (error.status == 401 && error.statusText.toLowerCase() == "unauthorized") {

       //   this.toastr.toasts = [];
         // this.toastr.currentlyActive=1;
         // this.toastr.toastrConfig.maxOpened=1;
          this.toastr.toastrConfig.preventDuplicates=true;

          this.toastr.error('Session expired...', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });


          var redirecturldomain = '';
          if (this.UserLogin['client'].includes("localhost")) {
            redirecturldomain = 'localhost';
          }
          
          if(sessionStorage.length>1)
          {
          var UserName = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
          if (UserName.includes('starmark.com')) {
            UserName = UserName.replace('starmark.com', 'starmarkit.com')
          }
          }
          // var url = this.UserLogin['fusionauth'] + 'authorize?client_id=' + this.UserLogin['clientid'] + '&response_type=code&redirect_uri=' + this.apiURL + 'api%2Foauth-callback&scope=offline_access&login_hint=' + UserName + '&state=' + redirecturldomain;
          //window.location.href=this.UserLogin['client'] + "%23/login"

          sessionStorage.removeItem('rolewithmenus');
          sessionStorage.removeItem('selectedRole');
          setTimeout(() => {
            this.loginService.logout();
          }, 3000);

          // window.location.href = url;

          //   this.service.changeMessage(true);
          return
        }
        else{
        return throwError(error);
        }
      }),
      tap((httpEvent: HttpEvent<any>) => {
        if (httpEvent.type === 0) {
          return;
        }

        let refreshtokenValue: string;
        if (httpEvent instanceof HttpResponse) {
          if (httpEvent.url.includes(this.apiURL)) {

            if (httpEvent.headers.has('RefreshToken')) {
              refreshtokenValue = httpEvent.headers.get('RefreshToken');
              if (refreshtokenValue == this.service.usersessiontoken || this.service.usersessiontoken == "2" || refreshtokenValue == "4") {
                this.service.usersessiontoken = refreshtokenValue;
              }
              else {
                if (!this.service.invalidrefreshtoken) {
                  this.toastr.error('A newer version of VitalHub is available. Auto Refresh will be initiated in 5 seconds.', "", {
                    timeOut: 5000,
                    positionClass: 'toast-bottom-right'
                  });
                  this.service.invalidrefreshtoken = true;
                  this.router.navigate(['/login']);
                  window.setTimeout(() => {

                    window.location.reload(true);
                  }, 5000);
                }
              }

            }
          }

        }
      })

    ) as Observable<HttpEvent<any>>;
  }
}

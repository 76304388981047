import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';

import { AppConfigService } from '../configurations/app-config.service';
import { FileToUpload } from '../interfacemapping/interfacemapping.component';
import { BehaviorSubject, Observable, pipe, Subject } from 'rxjs';
import { LoginService } from '../login/login.service';
import { url } from 'inspector';


@Injectable({
  providedIn: 'root'
})

export class CommonService {
  config: NgxUiLoaderConfig;
  isprocessEditedMappingData = "";
  isprocessEditedtemplatedId = 0;
  lasturl=''
  protected apiURL: string = AppConfigService.settings.WebApiURL;
  protected UserLogin: string = AppConfigService.settings.UserLogin;
  islock: boolean = false;
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  private configdatarefresh = new BehaviorSubject(false);
  dataRefreshConfig = this.configdatarefresh.asObservable();
  private viewFilecase = new BehaviorSubject(false);
  viewfileview = this.viewFilecase.asObservable();

  private vendoreCreate = new BehaviorSubject(false);
  vendorUpdate = this.vendoreCreate.asObservable();

  private updatessostatus = new BehaviorSubject(false);
  servicessostatus = this.updatessostatus.asObservable();

  private messageSourceInterface = new Subject();
  currentMessageinterface = this.messageSourceInterface.asObservable();
  private configJson = new Subject();
  ConfigJsonFlag = this.configJson.asObservable();
  configDetails: any;
  usersessiontoken: any = "2";
  invalidrefreshtoken: boolean = false;
  messagesData = "";
  openedModalConfig =""
  isopenAlready = false
  header = { withCredentials: true }
  headers = { 'Content-Type': 'application/json charset = utf-8', withCredentials: true };


  constructor(private httpService: HttpClient,
    private loginService: LoginService,
    private ngxUiLoaderService: NgxUiLoaderService) {
    this.config = this.ngxUiLoaderService.getDefaultConfig();
  }

  getapiurl(urlterm) {

    var listOfNames = this.httpService.get(this.apiURL + urlterm, this.header)
      .pipe(
        debounceTime(500),  // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map(
          (data: any) => {
            return (
              data as any[]
            );
          }
        ));

    return listOfNames;
  }

  async postapi(urlterm: any, createSearch: any) {

    return await this.httpService.post<any>(this.apiURL + urlterm, createSearch, this.header)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }

  async searchpostapi(urlterm, createSearch: any) {
    const headers = {
      'Content-Type': 'application/json charset = utf-8'
    };


    return await this.httpService.post<any>(this.apiURL + urlterm, JSON.stringify(createSearch), { headers, withCredentials: true })
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);

  }

  response: any[];
   savemappings(mappingentity, apiname) {
    const headers = {
      'Content-Type': 'application/json',
    };
    return  this.httpService.post<any>(this.apiURL + apiname, mappingentity, { headers, withCredentials: true, responseType: 'text' as 'json' })
      // .pipe().toPromise()
      // .then(result => result)
      // .catch(err => err);

  }


  async deletemapping(apinamevalue) {

    return await this.httpService.delete(this.apiURL + apinamevalue, this.header)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }


  async filedownloadblob(urlterm, header: any) {
    const headers = header;
    return await this.httpService.get<any>(this.apiURL +urlterm, { headers, withCredentials: true, responseType: 'blob' as 'json' });
  }

  async getapi(urlterm) {

    return await this.httpService.get<any>(this.apiURL + urlterm, this.header)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);

  }

 dataValidationapi(parameter:any){
   return this.httpService.post<any>( `${this.apiURL}api/DataValidation/ValidateCaseData`, parameter,{withCredentials: true})
}
  async reprocessStageapi(urlterm, createSearch: any) {
    return await this.httpService.post<any>(this.apiURL + urlterm, createSearch, { withCredentials: true, responseType: 'text' as 'json' })
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);

  }

  async dxpostapi(urlterm, entity) {
    return await this.httpService.post<any>(this.apiURL + urlterm, entity, this.header)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);

  }
  dxgetapi(urlterm, deploymentkey) {
    const headers = { 'Conn': deploymentkey };
    return this.httpService.get(this.apiURL +urlterm, { headers ,withCredentials: true,})
      .pipe(map(result => result));

  }
  getapiurlcontenttext(urlterm) {
    return this.httpService.get<any>(this.apiURL + urlterm, { withCredentials: true, responseType: 'json' });
  }
  getapiurlcontent(urlterm) {

    return this.httpService.get<any>(this.apiURL + urlterm, this.header);
  }

  // for entity api
  async entitygetapi(createSearch: any) {

    return await this.httpService.post<any>(this.apiURL, createSearch, this.headers)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }


  //audit save
  async auditsave(actiontype: string, oldvalue: any, newvalue: any, context: any, contextid: any,description:any) {
    var apiname = 'api/Audit/SaveAudit';
    var auditobj = {
      "UserName": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
      "ActionType": actiontype,
      "OldValue": oldvalue,
      "NewValue": newvalue,
      "Context": context,
      "ContextId": contextid,
      "Description":description
    }
    return await this.httpService.post<any>(this.apiURL + apiname, auditobj, this.header)
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }


  async uploadExcel(urlterm, file: any) {
    // const headers = new HttpHeaders().append('Content-Type', 'Plain/Text');
    var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
    const headers = {
      'Content-Type': 'Plain/Text'
    };
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.httpService.post<any>(this.apiURL + urlterm, formData, { headers, withCredentials: true })
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }

  checksession() {
    // var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
    // if (sessionObject != null) {
    //   return true;
    // }
    // else {
    //   sessionStorage.removeItem('sessionObjectlogin');
    //   return false;
    // }
    return true;
  }
  checkserversession() {
    return this.loginService.getUserInfo()

  }


  async postapiwithurl(fullurlterm: any, inputentity: any, apiheaders: any) {

    apiheaders["Content-Type"] = "application/json";
    apiheaders["Access-Control-Allow-Origin"] = "*";
    apiheaders["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
    apiheaders["Access-Control-Allow-Methods"] = "GET,POST,OPTIONS,DELETE,PUT";
    return await this.httpService.post<any>(fullurlterm, JSON.stringify(inputentity), { headers: apiheaders })
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }

  uploadFile(urlterm, theFile: FileToUpload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), withCredentials: true
    };
    return this.httpService.post<FileToUpload>(this.apiURL + urlterm, theFile, httpOptions);
  }
  changeMessage(ischeck: boolean) {
    this.messageSource.next(ischeck)
  }
  changeConfigmodal(ischeck: boolean) {
    this.configdatarefresh.next(ischeck)
  }
  closeviewFile(ischeck: boolean) {
    this.viewFilecase.next(ischeck)
  }
  changeModal(isSize: object) {
    this.configJson.next(isSize)
  }
  changeJson(isSize: object) {
    this.configJson.next(isSize)
  }

  vendorStatus(update: boolean) {

    this.vendoreCreate.next(update)
  }

  XmlModification(xml) {
    return this.httpService.post<any>(`${this.apiURL}api/GetMappings/ConvertXML`, xml, this.headers)
  }

  message_IOTData(value, Archive) {
    let createdby=JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
    return this.httpService.get(`${this.apiURL}api/HubMessage/GetInputTransformOutputData?messageRefId=${value}&archivedbcheck=${Archive}&createdby=${createdby}`, this.header)
  }
  downloadfile(urlterm): Observable<HttpResponse<Blob>> {

    return this.httpService.get(`${this.apiURL}${urlterm}`, { withCredentials: true, responseType: 'blob', observe: 'response' })
  }

  getmetricapiurl(urlterm) {
    var listOfNames = this.httpService.get(this.apiURL + urlterm, this.header)
      .pipe(
        debounceTime(500),
        map(
          (data: any) => {
            return (
              data as any[]
            );
          }
        ));

    return listOfNames;
  }
  transformAlphabetically(array: any[], field: string): any[] {
    return array.sort((a, b) => a[field].toLowerCase() !== b[field].toLowerCase() ? a[field].toLowerCase() < b[field].toLowerCase() ? -1 : 1 : 0);
  }

  clearCache() {
    var id=JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    return this.httpService.post<any>(`${this.apiURL}api/CoreCache/DeleteCache?userid=${id}`,'', this.header)
  }

  getJobSearch(job) {
    return this.httpService.post<any>(`${this.apiURL}api/Jobs/GetJobSearch`, job, this.headers);
  }

  createJob(job) {
    return this.httpService.post<any>(`${this.apiURL}api/Jobs/SaveJob`, job, this.headers);
  }

  getJobLogs(jobid) {
    return this.httpService.get<any>(`${this.apiURL}api/Jobs/GetJobLogs?jobid=${jobid}`, this.headers);
  }

  IsJsonString(str) {
    let isvalid = false;
    try {
      JSON.parse(str);
      isvalid = true;
    } catch (e) {
      isvalid = false;
    }
    return isvalid
  }



  // InterfaceCIIS service will start from here

  getInterfaces() {
    return this.httpService.get<any>(`${this.apiURL}api/GetInterfaceList/GetInterfacescon `)
  }
  getInterfaceFilters(interfacename: any, engine: any, vendor: any) {
    return this.httpService.get<any>(`${this.apiURL}api/VAInterfaces/GetVAInterfacesbysearch?interfacename=${interfacename}&Engine=${engine}&Vendor=${vendor}`)
  }
  getfilterdata(column: any, userinput: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceFieldFilter/GetInterfacesfieldsearch?column=${column}&userinput=${userinput}`)
  }
  getSearchFilterData(body: any) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfacebyInput/GetVAInterfaces`, body);
  }

  changeMessageinterface(message: string) {
    this.messageSourceInterface.next(message)
  }
  getDescription() {
    return this.httpService.get<any>(`${this.apiURL}api/ConnectivityTypes/vaConnectivityTypes`)
  }
  HubMapping(Interfacegroupid: any, Processorid: any) {
    var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
    return this.httpService.get<any>(`${this.apiURL}api/InterfacePipelineConfig/pipelineconfigdata?token=${sessionObject.userdata['usertokenString']}&interfacegrpid=${Interfacegroupid}&processorid=${Processorid}`)
  }
  getAudit(interfaceidentifier: any, engine: any, deployment: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceAudit/getAuditsData?InterfaceName=${interfaceidentifier}&InterfaceType=${engine}&Connection=${deployment}`)
  }

  getHubAudit(id: any) {
    return this.httpService.get<any>(`${this.apiURL}api/Audit/GetAuditDetails?id=${id}&context=Pipeline`, this.header)
  }
  getHubView(Interfacegroupid: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceHub/getHubViewData?Interfacegroupid=${Interfacegroupid}`)
  }
  getFolders(interfaceid: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceFiles/getFilesData?InterfaceId=${interfaceid}`, this.headers)
  }
  getTransactions(interfacename: any, Engine: any, Deployment: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceTransactions/getTransactionData?interfacename=${interfacename}&InterfaceType=${Engine}&Connection=${Deployment}`)
  }
  getHandlesData(interfacename: any) {
    return this.httpService.get<any>(`${this.apiURL}api/IntergrationMapping/getIntegrationData/${interfacename}`)
  }
  getRulesData(interfacename: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceRuleData/getRuleData/${interfacename}`)
  }
  viewFile(body: any) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceViewFile/FileData`, body)
  }
  PostFormData(obj: any) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceForm/InterfaceFormAdd`, obj)
  }
  insertInterfacefromFile(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceForm/InsertInterfacefromFile`, body)
  }
  EntityInsertion(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceForm/Insertentity`, body);
  }
  updateformdata(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceForm/IntFormUpdate`, body);
  }
  createpipeline(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Routes/SavePipeline`, body, this.header)
  }
  insertNewPipeline(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InsertPipeline/InsertNewPipeline`, body);
  }
  getformdata(value: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceForm/InterfaceFormView?userinput=${value}`);
  }
  finalizeformdata(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceForm/InterfaceFormSubmit`, body);
  }
  getproccessorapidata(value: any) {
    return this.httpService.get<any>(`${this.apiURL}api/Agents/GetByDisplayName?name=${value}&agentid=0&type=Processor&filterflag=true`, this.header)
  }
  getagents(body) {

    return this.httpService.post<any>(`${this.apiURL}api/Agents/GetSearchDetails`, body, this.header)
  }
  getproccessordata(obj: any) {
    return this.httpService.get<any>(`${this.apiURL}api/Interfacesenderandprocessoragent/Processorandsenderagents?contenttype=${obj.contenttype}&msgtype=${obj.msgtype}&direction=${obj.direction}`)
  }
  getEntityfilterdata(userinput: any) {
    return this.httpService.get<any>(`${this.apiURL}api/SearchAccounts/GetAccountNamesListAsync?lSearch=${userinput}`, this.header)
  }
  getMsgtypedata() {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetMessageType?lookuptype=messagetype`, this.header)
  }
  getrole(roleid: any, rolename: any) {
    if (roleid == null && rolename == null) {
      return this.httpService.get<any>(`${this.apiURL}api/UserRoleConfig/GetRoles`, this.header);
    }
    // return this.httpService.get<any>(`${this.apiURL}api/RoleMenu/GetRoles?RoleId=0`, this.header);
  }
  getRoleandMenu(rid: number) {
    return this.httpService.get<any>(`${this.apiURL}api/RoleMenu/GetRoleMenus?RoleId=${rid}`, this.header);
  }
  postRoleMenu(body) {
    return this.httpService.post<any>(`${this.apiURL}api/RoleMenu/SaveRoleMenu`, body, this.header)
  }
  getMenus(rid: number) {
    return this.httpService.get<any>(`${this.apiURL}api/RoleMenu/GetMenus`, this.header);
  }
  GetActionbyId(mid: number) {
    return this.httpService.get<any>(`${this.apiURL}api/RoleMenu/GetMenuActionsbyId?MenuId=${mid}`, this.header);
  }
  ManageActions(body) {
    return this.httpService.post<any>(`${this.apiURL}api/RoleMenu/SaveAction`, body, this.header)
  }

  AddRoles(body) {
    return this.httpService.post<any>(`${this.apiURL}api/RoleMenu/SaveRole`, body, this.header)
  }
  eventReprocesses(body, deployement) {
    const header = {
      'Content-Type': 'application/json charset = utf-8',
      'deploymentkey': deployement
    };
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/updateEventstatus`, body, { headers: header, withCredentials: true })
  }
  getMirthFilters(column: any, userinput: any) {
    return this.httpService.get<any>(`${this.apiURL}api/GetMirthLog/GetMirthFilters?column=${column}&userinput=${userinput}&ConnNew=THX`);
  }

  getEventsList(body, deployement) {
    const header = {
      'Content-Type': 'application/json charset = utf-8',
      'deploymentkey': deployement
    };

    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/searcheventdata`, body, { headers: header, withCredentials: true })
  }
  getEventType() {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=Eventtype`, this.header)
  }
  addIntegrationMapping(body) {
    return this.httpService.post<any>(`${this.apiURL}api/IntergrationMapping/addIntergration`, body)
  }
  updateIntegrationMapping(body) {
    return this.httpService.post<any>(`${this.apiURL}api/IntergrationMapping/updateIntergration`, body)
  }
  hubmessageResolve(id: any, modifiedby: any) {

    return this.httpService.put<any>(`${this.apiURL}api/HubMessage/HubMessageStatusUpdate?id=${id}&modifiedby=${modifiedby}&stageid=-1`, '', { withCredentials: true })
  }
  reprocessapi(body: any) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceHL7Update/UpdateHL7Integration`, body)
  }
  getMirthSearch(body: any) {
    return this.httpService.post<any>(`${this.apiURL}api/GetMirthLog/GetMirthLog`, body);
  }
  requestUnlock(UniqueID: any, userid: any, isUnlockRequest: any, isApproveUnlock: any, description: any) {
    let obj = {
      "routeUniqueID": UniqueID,
      "user": userid,
      "isUnlockRequest": isUnlockRequest,
      "isApproveUnlock": isApproveUnlock,
      "description": description
    }
    return this.httpService.post<any>(`${this.apiURL}api/Routes/InterfaceLock`, obj, this.header)
  }
  getContactNames(column: any, userinput: any) {
    return this.httpService.get<any>(`${this.apiURL}api/ContactModule/GetContactNames?column=${column}&userinput=${userinput}`, this.header)
  }
  getContacts(userinput) {
    return this.httpService.post<any>(`${this.apiURL}api/ContactModule/GetContacts`, userinput, this.header)
  }
  updateContactModule(body) {
    return this.httpService.post<any>(`${this.apiURL}api/ContactModule/UpdateContact`, body, this.header)
  }
  addNewContact(body) {
    return this.httpService.post<any>(`${this.apiURL}api/ContactModule/AddContact`, body, this.header)
  }

  createContact(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceVendor/VendorContacts?userinput=create`, body);
  }

  updateContact(body) {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceVendor/VendorContacts?userinput=update`, body);
  }

  viewContact(item: any) {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceVendor/VendorContactsView?Vendorid=${item}`)
  }

  contactStatusUpdate() {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceVendor/VendorContactsstatusupdate?Contactid=1&Status=0`)
  }

  Deletecontact(userinput: any) {
    return this.httpService.delete<any>(`${this.apiURL}api/ContactModule/DeleteContact?userinput=${userinput}`)
  }

  hl7logsreprocess(body, deployement) {
    // var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
    const header = {
      'Content-Type': 'application/json charset = utf-8',
      'deploymentkey': deployement
    };
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXApi/UpdateIntegrationLogdata`, body, { headers: header, withCredentials: true })
  }
  logreprocessStatus() {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=Hl7reprocesslogstatus`, this.header)
  }
  getHl7LogsList(body, deployment) {
    var sessionObject = JSON.parse(sessionStorage.getItem('sessionObjectlogin'));
    const header = {
      'Content-Type': 'application/json charset = utf-8',
      'deploymentkey': deployment
    };
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXApi/GetHl7IntegrationLogdata`, body, { headers: header, withCredentials: true })
  }
  searchlogstatus() {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=Hl7logstatus`, this.header)
  }


  getEventList(body) {
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/GetCaseData `, body, this.header);
  }
  async searchduplgettapi(urlterm) {
    const headers = {
      'Content-Type': 'application/json charset = utf-8'
    };


    return await this.httpService.get<any>(this.apiURL + urlterm, { headers, withCredentials: true })
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);

  }



  // this.vbjobapiURL
  getPatienthistory(body) {
    return this.httpService.post<any>(`${this.apiURL}api/PatientHistoryImport/CheckFTPGetFiles`, body, this.header);
  }
  submitPatienthistory(body) {
    return this.httpService.post<any>(`${this.apiURL}api/PatientHistoryImport/SaveProjectInfo`, body, this.header);
  }

  submitImportPatient(body){
    return this.httpService.post<any>(`${this.apiURL}api/PatientHistoryImport/SaveHeaderTemplateInfo`,body,this.header);
  }

  Patienthistorysummary(user: string) {
    return this.httpService.get<any>(`${this.apiURL}api/PatientHistoryImport/GetProjectInfo?user=${user}`, this.header);
  }
  previewfile(body) {
    return this.httpService.post<any>(`${this.apiURL}api/PatientHistoryImport/GetFilePreview`, body, this.header);
  }

  case360(searchText) {
    return this.httpService.get<any>(`${this.apiURL}api/Case360/GetEventLogDetails?lSearch=${searchText}`, this.header);
  }

  case360info(searchText) {
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetCaseInfoByAccession`, searchText,this.header);
  }
  CaseEventAndLogReprocess(body) {
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/ReprocessEventsorhl7logs`, body, this.header);
  }

  viewFileCase360(logid, routeguid) {
    return this.httpService.get<any>(`${this.apiURL}api/Case360/GetEventLogFileData?logid=${logid}&routeid=${routeguid}`, this.header);
  }
  CaseEventAndLogRegenerate(body) {
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/CaseXmlRegeneration`, body, this.header);
  }

  exportpatienthistory(body) {
    return this.httpService.post<any>(`${this.apiURL}api/CaseHistoryExport/SaveProjectInfo`, body, this.header);
  }
  getexportpatienthistory(user: string) {
    return this.httpService.get<any>(`${this.apiURL}api/CaseHistoryExport/GetProjectInfo?user=${user}`, this.header);
  }
  getlookupInfo(param) {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=${param}`, this.header)
  }
  getDeployments(param) {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookUpId?lookuptype=${param}`, this.header)
  }

  saveInterfaceForm(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Projects/Save`, body,this.header);
  }

  submitInterfaceForm(body){
    return this.httpService.post<any>(`${this.apiURL}api/Projects/Submit`,body,this.header);
  }

  InterfaceFormList(body){
    return this.httpService.post<any>(`${this.apiURL}api/Projects/GetList`,body,this.header);
  }

  ProjectFormEdit(projid,projtype){
    return this.httpService.get<any>(`${this.apiURL}api/Projects/GetProjDetailsById?Projectid=${projid}&type=${projtype}`, this.header);
  }

  getIntContType(looktype) {
    return this.httpService.get<any>(`${this.apiURL}api/Lookup/GetLookupTypelist?lookuptype=${looktype}`, this.header)
  }

  getInterfaceDetails360(body){
    return this.httpService.post<any>(`${this.apiURL}api/case360/GetInterfaceDetailsByCaseId`,body,this.header)
  }
  getInterfaceDetailsRule(body){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetRuleAndCaseInfo`,body, this.header)
  }
  getInterfaceDetailsMessages360(body){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetHubMessages`,body,this.header)
  }
  getInterfaceDetailsMessages360Stage(body){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetHubMessageLogs`,body,this.header)
  }
 reprocessMessages360Stage(body){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetHubMessageStageReprocess`,body,this.header)
  }

  getProjList(projid){
    return this.httpService.get<any>(`${this.apiURL}api/Projects/GetProjectNames?searchStr=${projid}`,this.header)
  }
  pdf64(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Projects/CreatePdf`,body,this.header)
  }
  getprojects(obj) {
    return this.httpService.get<any>(`${this.apiURL}api/Projects/GetProjectNames?vendor=${obj.vendorId}&labGuid=${obj.labGuid}`,this.header)
  }
  saveprojftpdetails(body)
  {
    return this.httpService.post<any>(`${this.apiURL}api/Pipeline/SavePipelineFtpDetails`,body,this.header)
  }
  excel64(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Projects/CreateExcel`,body,this.header)
  }
  getcompendium(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Compendium/GetCompendiumResult`,body,this.header)
  }
  mailapi(body) {
    return this.httpService.post<any>(`${this.apiURL}api/Projects/sendmail`,body,this.header)
  }

  getUpdateFTPInternal() {
    return this.httpService.get<any>(`${this.apiURL}api/PatientHistoryImport/ReVerifyFTPFiles`,this.header)
  }

  getProjInterfaces(body){
    return this.httpService.post<any>(`${this.apiURL}api/Routes/GetSearchDetails`,body, this.header)
  }
  refreshpatientImport(PID){
    return this.httpService.get<any>(`${this.apiURL}api/PatientHistoryImport/GetTransactionBasedDetails?PID=${PID}`,this.header)
  }

  viewFilesCase_hanlder(body){
    return this.httpService.post<any>(`${this.apiURL}api/VitalHubDXAPI/GetStorageFileId`,body,this.header)
  }


  changeImportProjectStatus(projectid,status){
    let obj={
      PID:projectid.startsWith("0")? projectid.substring(1):projectid,
      Status:status
    }
    return this.httpService.post<any>(`${this.apiURL}api/PatientHistoryImport/ChangeProjectStatus`,obj,this.header)
  }

  viewCases360DXReport(body){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetFileContent360`,body,this.header)
  }

  getDaignostics(routeGuid:string){
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceStatistics/GetStatistics?RouteGuid=${routeGuid}`,this.header)
  }

  GetPipelineList(body){
    return this.httpService.post<any>(`${this.apiURL}api/CIISV2/GetPipelineList`,body,this.header)
  }
  GetPipeleDetailsByUniqueID(uniqueID){
    return this.httpService.get<any>(`${this.apiURL}api/CIISV2/GetPipeleDetailsByUniqueID?uniqueID=${uniqueID}`,this.header)
  }
  GetDatewiseFailureReasonList(body){
    return this.httpService.post<any>(`${this.apiURL}api/CIISV2/GetMessageFailureCounts`,body,this.header)
  }
  ciisreportexport(body){
    return this.httpService.post<any>(`${this.apiURL}api/CIISV2/GenerateExcel`,body,this.header)
  }
  GenerateMappingPDF(input:any){

    return this.httpService.post<any>(`${this.apiURL}api/MappingPDF/Generate`,input,this.header)
  }

  GenerateMappingPDFLive(input:any){

    return this.httpService.post<any>(`${this.apiURL}api/MappingPDF/GetFileInfo`,input)
  }
  GetFileContentByFileID(input:any){

    return this.httpService.post<any>(`${this.apiURL}api/FileServices/GetFileContentByFileID`,input,this.header)
  }
  reprocessBymsgID(msgID){
    return this.httpService.get<any>(`${this.apiURL}API/HubMessage/GetMessageDetailById?messageReferenceIds=${msgID}`,this.header)
  }
  pendingfiles(routeid){
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceStatistics/GetStatistics?RouteGuid=${routeid}`,this.header)
  }

  updatessoStatus(update: boolean) {
    this.updatessostatus.next(update)
  }
  createTemplatelhs(body){
    return this.httpService.post<any>(`${this.apiURL}api/GetMappings/createTemplate`, body, this.header)
  }
  CreateDeviceConnectivity(body){
    return this.httpService.post<any>(`${this.apiURL}api/VBJobs/GetInstrumentCodes`, body, this.header)
  }
  GetJobSchema(name,dkey){
    return this.httpService.get<any>(`${this.apiURL}api/VBJobs/GetJobSchema?deviceName=${name}&deployment=${dkey}`,this.header)
  }
   CreateVitalhubJob(body){
    return this.httpService.post<any>(`${this.apiURL}api/VBJobs/CreateVitalhubJob`, body, this.header)
  }

  caseAuditDetails(body){
    return this.httpService.post<any>(`${this.apiURL}api/Case360/GetCaseAuditData`,body,this.header)
  }
  GetJobDetails(dname,rid){
    return this.httpService.get<any>(`${this.apiURL}api/VBJobs/GetJobDetails?agentid=${dname}&routeid=${rid}`,this.header)
  }

  //Data Validation API Calls
  sendToTopic(requestBody: any) {
    return this.httpService.post<any>(`${this.apiURL}api/DataValidation/SendToDataValidationTopic`, requestBody,this.header);
  }

  GetInterfaceValidationHistory()
  {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceValidation/GetValidationMasterDetails`,this.header);
  }
  InsertInterfaceValidationHistory(requestBody:any)
  {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceValidation/InsertValidationMaster`, requestBody,this.header);
  }

 GetDatabyMessageIdInfo(piplineguid,fromdate,todate)
  {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceValidation/GetValidatedMessagesList?pipelineguid=${piplineguid}&fromdate=${fromdate}&todate=${todate}`,this.header);
  }
  GetMessageDataByMessageId(messageid)
  {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceValidation/GetMessageValidationDataByMessageID?messageid=${messageid}`,this.header);
  }

  UpdateValidationMaster(requestBody:any)
  {
    return this.httpService.post<any>(`${this.apiURL}api/InterfaceValidation/UpdateValidationMaster`, requestBody,this.header);
  }

  DeleteInterfaceValidatedMessages(PipelineGUID, fromDate, toDate)
  {
    return this.httpService.get<any>(`${this.apiURL}api/InterfaceValidation/DeleteValidatedMessages?pipelineguid=${PipelineGUID}&fromDate=${fromDate}&toDate=${toDate}`,this.header);
  }
  GetMessageDetailsByID(messageid){
    return this.httpService.get<any>(`${this.apiURL}api/DataValidation/GetMessageDetailsByID?messageid=${messageid}`,this.header) 
  }

}




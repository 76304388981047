import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from "../services/common.service";
import { ToastrService } from "ngx-toastr";
import {
  MatSort,
  MatTreeFlatDataSource,
  MatTreeFlattener,
  Sort,
} from "@angular/material";
import { FlatTreeControl } from "@angular/cdk/tree";
import { of as observableOf } from "rxjs";
import {
  MonacoEditorConstructionOptions,
  MonacoStandaloneCodeEditor,
} from "@materia-ui/ngx-monaco-editor";
import { v4 as uuidv4 } from "uuid";
import { FileViewExplorerComponent } from "src/app/file-view-explorer/file-view-explorer.component";

declare var $;

@Component({
  selector: "app-ftpfiles",
  templateUrl: "./ftpfiles.component.html",
  styleUrls: ["./ftpfiles.component.scss"],
})
export class FtpfilesComponent implements OnInit {
  @Input() inputDataInModalComponent: any;
  formatType: string = "plaintext";
  filehistory: any[] = [];
  leftNav:boolean= true
  folderempty:boolean;
  childfiles: any = [];
  backupfolders: any;
  fileexplorerheight: number = 0;
  searchForm: FormGroup = this._formBuilder.group({
    search: "",
  });
  interfaceDetails: any;
  pdfSrc: string;
  isRead: boolean = false;
  editorOptions: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: true,
    wordWrap: "on",
    minimap: {
      enabled: false,
    },
  };
  editor: MonacoStandaloneCodeEditor;
  DisplaynameRoute: any;
  uniqfilesdata=[];
  selectedFileItem: any;
  filecountdisplay: unknown;
  DestfolList:any
  editorInit(editor: MonacoStandaloneCodeEditor) {
    this.editor = editor;
    editor.onDidChangeCursorPosition(() => {
      if (
        this.selectedfilecontent !== "" &&
        this.selectedfilecontent !== undefined
      ) {
        if (this.selectedfilecontent.startsWith("<")) {
          this.formatType = "xml";
        } else if (this.selectedfilecontent.startsWith("{")) {
          this.formatType = "json";
        }
      }

      this.editorOptions = { ...this.editorOptions, language: this.formatType };
      // this.formatXml(this.inputtemplatexml);
    });
  }

  screenHeight: number;
  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    public service: CommonService,
    private activeModal: NgbActiveModal,
    private _fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TreeNode>(
      this.getLevel,
      this.isExpandable
    );
    this.FTPFilesList = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    this.DestfolList = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    // this.FTPFilesList.data = files;
  }

  ngOnChanges() {

this.inputDataInModalComponent.case360 !== undefined ? this.leftNav = false:true;
this.getFTPfiles();
  }
  pagerolepermisions: any = [];
  async ngOnInit() {
    this.fileexplorerheight = window.innerHeight - 40;
    this.screenHeight = window.innerHeight - 229;
    if ((await this.service.checksession()) == true) {
      const menuid = JSON.parse(sessionStorage.getItem("rolewithmenus"))
        .find((x) => x.RoleName == sessionStorage.getItem("SelectedUserRole"))
        .menuslist.find((y) => y.MenuName == "createpipeline").MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem("rolewithmenus"))
        .find((x) => x.RoleName == sessionStorage.getItem("SelectedUserRole"))
        .menuslist.filter((y) => y.ParentMenuId == menuid);
      actionitems.forEach((element) => {
        this.pagerolepermisions[element.MenuName] = true;
      });
      if (this.pagerolepermisions != undefined) {
        // this.FTPFilesList.data=[]
        this.childfiles = [];
        this.filenamesearch = "";
        await this.getFTPfiles();
      }
    }
  }

  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<TreeNode>;
  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<FileNode, TreeNode>;
  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  FTPFilesList: MatTreeFlatDataSource<FileNode, TreeNode>;

  /** Transform the data to something the tree can read. */
  transformer(node: FileNode, level: number) {
    return {
      foldername: node.foldername,
      type: node.type,
      level: level,
      expandable: !!node.subfolders,
      size: node.size,
      lastmodifieddate: node.lastmodifieddate,
      subfolders: node.subfolders,
      filelist: node.filelist,
      fullpath: node.folderpath,
      guid: node.guid,
    };
  }

  /** Get the level of the node */
  getLevel(node: TreeNode) {
    return node.level;
  }

  /** Return whether the node is expanded or not. */
  isExpandable(node: TreeNode) {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: FileNode) {
    return observableOf(node.subfolders);
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: TreeNode) {
    return node.expandable;
  }

  createGuid(data: any) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].foldername !== undefined) {
        data[i]["guid"] = uuidv4();
        data[i].expanded = false;
        if (data[i].subfolders !== undefined && data[i].subfolders.length > 0) {
          this.createGuid(data[i].subfolders);
        }
      }
    }
    return data;
  }

  numberoffilesshow: number;
  selectcontentflag: boolean = false;
  getFTPfiles() {
    this.ngxUiLoaderService.start();
    if (this.inputDataInModalComponent.selectflag) {
      this.selectcontentflag = this.inputDataInModalComponent.selectflag;
    }

    this.DisplaynameRoute = this.inputDataInModalComponent.name;
    var apiname =
      "api/Routes/GetFTPFilesExplorer?RouteId=" +
      this.inputDataInModalComponent.routeId +
      "&SourceagentID=" +
      this.inputDataInModalComponent.sourceagent +
      "&Filename=" +
      this.filenamesearch;
    // var apiname = "api/Routes/GetFTPFilesExplorer?RouteId=43BEE9EB-421D-4B94-A1DD-A9F623F22E8C&SourceagentID=F3ECBF3D-FA0D-AFD5-BF3E-7AE5BB6EADC4&Filename=";

    this.service.getapiurl(apiname).subscribe({
      next: (response) => {
        if (response.length > 0) {
          this.filecountdisplay = response[0].filecountshow
          response[0] = this.deleteproperty(response[0] as any[]);
          this.FTPFilesList.data = this.createGuid(response);
          this.DestfolList.data=this.FTPFilesList.data;
          this.getFolderdata();
        }
        this.ngxUiLoaderService.stop();
      },
      error: (error) => {
        this.ngxUiLoaderService.stop();

        this.toastr.error(
          "Failed"+" "+ error["statusText"],
          "",
          {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          }
        );
      },
    });
  }
  getFolderdata() {
    // this.modalService.open(exampleModalRight)
    // $("#exampleModalRight").modal('show');
    this.filediv(this.FTPFilesList.data[0]);
  }

  filediv(filesData: any) {

    if(filesData.type !== "file" ){
    this.folderempty = true;
    this.isRead = false;
    }

    this.filenamesearch = "";
    if (filesData.type == "file") {
      this.childfiles.map(da => da.isRead = false)
      this.getfilecontent(filesData);
    } else {
      this.isRead = false;
      this.selectedftpfilesList=[];
      let paths = this.getAncestors(this.FTPFilesList.data, filesData.guid);
      // //console.log(paths);
      // if(filesData.filename)
      // {
      //   this.src=filesData.fullname;
      //   //console.log(this.src);
      //   this.fileview();
      // }
      if (paths.length > 0) {
        let files = paths[paths.length - 1];
        this.treeControl.collapseAll();
        for (let k = 0; k < paths.length; k++) {
          let indd = this.treeControl.dataNodes.findIndex(
            (va) => va.guid === paths[k].guid
          );
          this.treeControl.expand(this.treeControl.dataNodes[indd]);
        }

        let index = files.level;
        this.filehistory.splice(index, this.filehistory.length - index);
        this.filehistory = paths;
        if (files.subfolders !== undefined && files.filelist.length > 0) {
          this.childfiles = [...files.subfolders, ...files.filelist];
        } else if (files.subfolders == undefined && files.filelist.length > 0) {
          this.childfiles = [...files.filelist];
        } else if (
          files.subfolders !== undefined &&
          files.filelist.length == 0
        ) {
          this.childfiles = [...files.subfolders];
        } else {
          this.childfiles = [];
        }
        if (this.childfiles !== undefined) {
          this.backupfolders = JSON.parse(JSON.stringify(this.childfiles));
        }
      }
    }
    this.childfiles.map(va => va.isRead = false)

  }

  getAncestors(array: any, name: any) {
    if (typeof array != "undefined") {
      for (let i = 0; i < array.length; i++) {
        if (array[i].guid === name) {
          return [array[i]];
        }
        let a: any = this.getAncestors(array[i].subfolders, name);
        if (a !== null) {
          a.unshift(array[i]);
          return a;
        }
      }
    }
    return null;
  }

  deleteproperty(dataitem) {
    dataitem.subfolders.forEach((element) => {
      if (element.subfolders.length == 0) {
        delete element.subfolders;
      } else {
        element = this.deleteproperty(element);
      }
    });
    return dataitem;
  }

  displayedColumns: string[] = [
    "checkbox",
    "icon",
    "name",
    "lastmodifieddate",
    "size",
  ];
  selectedfolderfilelist: any = [];
  filesListShow(s) {
    this.selectedfolderfilelist = [];
    s.filelist.forEach((element) => {
      element.foldername = s.foldername;
      element.level = s.level;
      this.selectedfolderfilelist.push(element);
    });
  }
  selectedestfolpath:string=""
  selectedestfolname:string=""
  selectedestfol(node)
  {

    this.selectedestfolpath=""
    this.selectedestfolname=""
    this.selectedestfolpath=node.fullpath
    this.selectedestfolname=node.foldername

  }

  selectedfilecontent: any;
  mediatypedisplay: boolean = true;
  async getfilecontent(event) {
    if(event.size !== "0"){
      this.selectedFileItem = event;
    const datainput = {
      filename: event.name,
      filepath: event.fullName,
      routeid: this.inputDataInModalComponent.routeId,
      agentid: this.inputDataInModalComponent.sourceagent,
      createdby:JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    };
    // const datainput = {
    //   "filename": event.name,
    //   "filepath": event.fullName,
    //   "routeid": "43BEE9EB-421D-4B94-A1DD-A9F623F22E8C",
    //   "agentid": "F3ECBF3D-FA0D-AFD5-BF3E-7AE5BB6EADC4"
    // }
    this.ngxUiLoaderService.start();
    var response = await this.service.postapi(
      "api/Routes/GetFTPFileContent",
      datainput
    );
    if (response["status"] && response["statusText"]) {
      this.ngxUiLoaderService.stop();
      this.toastr.error(
        "Failure to get file content. error " + response["statusText"],
        "",
        {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        }
      );
    } else {
      this.ngxUiLoaderService.stop();
      const result = response as any[];
      //
      event['isRead']=true
      if (result["status"] == ".pdf") {
        this.isRead=true;
        this.mediatypedisplay = false;
        this.selectedfilecontent = this._base64ToArrayBuffer(result["responsebody"]);
      //   let namemodal = {
      //     name: event.name,
      //     fullfile: result["responsebody"],
      //   };
      //   const modalRef = this.modalService.open(FileViewExplorerComponent, {
      //     windowClass: "myCustomModalViewfile",
      //     backdrop: "static",
      //     keyboard: false,
      //   });
      //   modalRef.componentInstance.fileData = namemodal;
      //   modalRef.result.then(() => {});
      } else {
        this.isRead = true;
        this.mediatypedisplay = true;
        this.selectedfilecontent = result["responsebody"];
      }
    }
  }else{
          this.toastr.warning(" No file content", '0 Bytes ',{
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        }
      );
  }
  }
  units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  niceBytes(x: any) {
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + this.units[l];
  }
  // _base64ToArrayBuffer(base64) {
  //   const binary_string = window.atob(base64);
  //   const len = binary_string.length;
  //   const bytes = new Uint8Array(len);
  //   for (let i = 0; i < len; i++) {
  //     bytes[i] = binary_string.charCodeAt(i);
  //   }
  //   return bytes.buffer;
  // }
  _base64ToArrayBuffer(base64) {
    var binary_string = base64.replace(/\\n/g, '');
    binary_string =  window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  filenamesearch: string = "";
  refresh() {
    this.filenamesearch = "";
    this.selectedftpfilesList=[];
    this.getFTPfiles();
  }
  closetpopup() {
    this.childfiles = [];
    this.service.closeviewFile(true);
    this.activeModal.close();

  }

  closetpopupRead() {
    this.isRead = false;
    this.selectedFileItem.isRead = false;
  }

  activeNode;
  searchftpfiles() {
    if (this.filenamesearch !== "") {
      this.ngxUiLoaderService.start();
      if (this.inputDataInModalComponent.selectflag) {
        this.selectcontentflag = this.inputDataInModalComponent.selectflag;
      }
      var apiname =
        "api/Routes/GetFTPFilesExplorer?RouteId=" +
        this.inputDataInModalComponent.routeId +
        "&SourceagentID=" +
        this.inputDataInModalComponent.sourceagent +
        "&Filename=" +
        this.filenamesearch;

      this.service.getapiurl(apiname).subscribe(response => {
          ;
          if (response.length > 0) {
            this.ngxUiLoaderService.stop();
            response[0] = this.deleteproperty(response[0] as any[]);
            // this.FTPFilesList.data = response as any[];
            this.treeControl.expand(this.treeControl.dataNodes[0]);

            let pathshistorydata =this.createGuid(response);
            let paths = this.getAncestors(pathshistorydata, pathshistorydata[0].guid);
            this.selectedfolderfilelist = [];
            this.uniqfilesdata =[]
            this.isRead=false;
            this.filehistory = paths.length>0?paths:[];
            this.childfiles = this.uniqFiles(response);
            if(this.childfiles.length ==0){
              this.folderempty = false;
              this.toastr.warning(
                "Files Not Found",
                "",
                {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                }
              );
            }
            this.numberoffilesshow = response[0].filecountshow;
          }

        },error => {
          this.ngxUiLoaderService.stop();
        },
      );
    }
  }

  uniqFiles(files) {
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
      //  if( files[i].foldername == this.filehistory[this.filehistory.length -1].foldername){
        this.uniqfilesdata = this.uniqfilesdata.concat(files[i].filelist);
      //  }
        if (files[i].subfolders !== undefined && files[i].subfolders.length > 0) {
          this.uniqFiles(files[i].subfolders);
        }
      }
    }
    return this.uniqfilesdata;
  }

  selectedftpfilesList: any[] = [];
  async Selectedftpfiles(item, checkvalue) {
    if (checkvalue) {
      this.selectedftpfilesList.push(item);
    } else {
      this.selectedftpfilesList = this.selectedftpfilesList.filter(
        (element) => element.fullName !== item.fullName
      );
      // this.selectedftpfilesList.forEach((element) => {
      //   if (element.fullName === item.fullName) {
      //     this.selectedftpfilesList.splice(
      //       this.selectedftpfilesList.indexOf(item.fullName),
      //       1
      //     );
      //   }
      // });
    }
  }
sourcepathfile:unknown
selectdestinationfolderpop(modalrefid)
{
  this.selectedestfolpath="";
  if(this.selectedftpfilesList.length>0){
    this.selectedftpfilesList.forEach((element) => {
      this.sourcepathfile= element.fullName;
    })
  }

  this.modalService
  .open(modalrefid, {
    windowClass: "viewfiles",
    backdrop: "static",
    keyboard: false,
  })
  .result.then((result) => {});
}

closedestfolpopup(c) {
  this.sourcepathfile=""
  this.selectedestfolpath=""
  c("close modal");
}

  async SelectedftpfilesMove(c) {
if(this.selectedestfolpath != ''){
    this.ngxUiLoaderService.start();
    const fileinfo = [];
    if(this.selectedftpfilesList.length>0){
      this.selectedftpfilesList.forEach((element) => {
        fileinfo.push({
          name: element.name,
          fullName: element.fullName,
          lastmodifieddate: element.lastmodifieddate,
          type: element.type,
          size: element.size,
          destinationfolder:this.selectedestfolpath
        });
      });
    }

    const filesmove = {
      routeUniqueID: this.inputDataInModalComponent.uniqid,
      RouteId: this.inputDataInModalComponent.routeId,
      SourceAgentId: this.inputDataInModalComponent.sourceagent,
      FTPFilesInfo: fileinfo,
      CreatedBy: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    };
    let responseroutedata = await this.service.postapi(
      "api/Routes/FTPFilesMoveToRootFolder",
      filesmove
    );

    // if ( (responseroutedata["status"] && responseroutedata["statusText"]) || responseroutedata["statusText"] === "Unknown Error" ) {
    //   this.ngxUiLoaderService.stop();

    //   this.toastr.error(
    //     "Failure to move FTP files to root folder or File already exists in root folder",
    //     responseroutedata["statusmessage"],
    //     {
    //       timeOut: 4000,
    //       positionClass: "toast-bottom-right",
    //     }
    //   );
    // } else {
      let lastpath
      if(this.selectedestfolpath.includes("/")){

        lastpath = this.selectedestfolpath.split("/")
        if(lastpath.length){
          lastpath = lastpath[lastpath.length-1]
        }
      }
      if(responseroutedata.status!=200){
        this.toastr.error(
          `Error moving files`,
          "",
          {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          }
        );
        c("close modal");
        this.ngxUiLoaderService.stop();
}
else{
  this.toastr.success(
    `Selected FTP files moved to ${lastpath} folder Successfully`,
    "",
    {
      timeOut: 4000,
      positionClass: "toast-bottom-right",
    }
  );
  c("close modal");
  this.ngxUiLoaderService.stop();
}
this.selectedftpfilesList = [];
      this.getFTPfiles();
    }else{
      this.toastr.warning("Destination Path is Mandatory", 'Warning ',{
        timeOut: 4000,
        positionClass: "toast-bottom-right",

    })
  }
  }

  clearsearchbox() {
    this.filenamesearch = "";
    this.folderempty = true;
  }

  selectcontentandclose(content) {
    this.activeModal.close(content);
  }

  searchfile(arr: any, searchinput: any) {
    for (var i of arr) {
      if (i.foldername.includes(searchinput)) {
        return this.filediv(i);
      }
    }
    if (i) {
      this.searchfile(i.subfolders, searchinput);
    }
  }

  // onSubmit() {
  //   this.searchfile(this.FTPFilesList.data, this.searchForm.value.search);
  // }

  // downloadFile(value:any){

  //   let path = value.fullName.split("/");
  //   if(path.length>0){
  //     let index = path.length-1
  //     path.splice(index,1)
  //    path= path.join("/")
  //   }
  //   let obj ={
  //      "path":path+"/",
  //      "interfaceid":this.interfaceDetails.InterfaceId,
  //      "filename":value.filename
  //     }

  //     this.ngxUiLoaderService.start()
  //   this.service.viewFile(obj).subscribe(data =>{
  //     if(data.fullfile !== null){

  // this.pdfSrc = data.fullfile;
  // let namemodal={
  //   name:data.filename,
  //   fullfile:data.fullfile
  // }
  // const modalRef = this.modalService.open(FileViewExplorerComponent
  //   , {
  //   windowClass: "myCustomModalViewfile",
  //   backdrop: "static",
  //   keyboard: false
  // });
  // modalRef.componentInstance.fileData =namemodal ;
  // modalRef.result.then(() => { });
  // this.ngxUiLoaderService.stop()

  //     }else{
  //       this.ngxUiLoaderService.stop()

  //     }
  //   },error=>{
  //     this.ngxUiLoaderService.stop()
  //   })
  // }
  checkboxEnable(history){

    if(history.length>0){
      for (let i = 0; i < history.length; i++) {
        const element = history[i];
        if( history[i].foldername.toLowerCase() == 'error' ||  history[i].foldername.toLowerCase()  == 'progress'  ||  history[i].foldername.toLowerCase()  == 'archive'  ){
          return true;
        }
      }
    }

  }
  showimg(value) {
    if (value !== undefined) {
      return value.split(".")[1];
    }
  }

  ngOnDestory() {
    this.childfiles = [];
    this.treeControl.dataNodes = [];
    this.FTPFilesList.data = [];
    this.DestfolList.data = [];

    this.filenamesearch = "";
  }
}
/** File node data with nested structure. */
export interface FileNode {
  foldername: string;
  folderpath: string;
  level: number;
  type: string;
  size: any;
  lastmodifieddate: any;
  subfolders?: FileNode[];
  filelist?: FileTreeNode[];
  guid: string;
}

/** Flat node with expandable and level information */
export interface TreeNode {
  foldername: string;
  level: number;
  expandable: boolean;
  filelist?: FileTreeNode[];
  subfolders?: FileNode[];
  guid: string;
}

/** Flat node with expandable and level information */
export interface FileTreeNode {
  name: string;
  fullName: string;
  type: number;
  size: string;
  lastmodifieddate: string;
}

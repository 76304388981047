import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileViewExplorerComponent } from 'src/app/file-view-explorer/file-view-explorer.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-otherinfo',
  templateUrl: './otherinfo.component.html',
  styleUrls: ['./otherinfo.component.scss']
})
export class OtherinfoComponent implements OnInit {
@Input() CaseDetails={}
  constructor(
    private service: CommonService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal
  ) { }
ngOnChanges(){
console.log(this.CaseDetails)
}
  ngOnInit() {
  }

  viewCaseFile(report){
    if(report.File !== undefined){
      report = report.File
    }
    let location
    if(this.CaseDetails['ServiceProvider'].length>0){
      location= this.CaseDetails['ServiceProvider'].find(value => value.Location.AccountType=="Laboratory")
        if(location !== undefined){
          location = location.Location
        }
      }else{
      location= this.CaseDetails['ServiceProvider'].Location
    }

    let obj=
      {
        "RequestID": report.PresentationData,
        "Type": report.Category,
        "RequestGUID": report.FileGUID,
        "LabOrgGUID":location.OrganizationGUID,
        "CaseGUID": this.CaseDetails['CaseGUID'],
        "DeploymentsKey":this.CaseDetails['DeploymentKey']
      }
this.ngxUiLoaderService.start()
    this.service.viewCases360DXReport(obj).subscribe(data=>{
      this.ngxUiLoaderService.stop()
      if(data.StatusMessage.toLowerCase() !== "failed"){
        if(data['FileContent'] !== null){

          'data:application/pdf;base64,'+data['FileContent']
          let namemodal={
            name:report.ReportName,
            fullfile:data['FileContent']
          }
          const modalRef = this.modalService.open(FileViewExplorerComponent
            , {
            windowClass: "myCustomModalViewfile",
            backdrop: "static",
            keyboard: false
          });
          modalRef.componentInstance.fileData =namemodal ;
          modalRef.result.then(() => { });
              }else{
                this.ngxUiLoaderService.stop();
                this.toastr.warning('Data not Found', "Alert", {
                  positionClass: "toast-bottom-right",
                  timeOut: 4000,
                });
              }
      }else{
        this.ngxUiLoaderService.stop();
        this.toastr.error(data.FileContent, "Failed", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }

    },error=>{
      this.ngxUiLoaderService.stop()
    })
  }

}

import { Component } from '@angular/core';
import { CommonService } from '../services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cache',
  templateUrl: './cache.component.html',
  styleUrls: ['./cache.component.scss']
})
export class CacheComponent  {

  constructor(private service:CommonService, private ngxUiLoaderService: NgxUiLoaderService,private toastr: ToastrService) { }

  ngOnInit() {
  }

  clearCache(){
    this.ngxUiLoaderService.start();
    this.service.clearCache().subscribe(data=>{
      if(data['status'] =='success'){
        this.ngxUiLoaderService.stop();
        this.toastr.success(data.statusmessage, "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
       });
       }
      else{
        this.ngxUiLoaderService.stop();
        this.toastr.error(data.statusmessage, "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
       });
      }
     });
   }

}

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { COMMA, ENTER, V, X } from "@angular/cdk/keycodes";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from "src/app/services/common.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";

import { MonacoEditorComponent, MonacoEditorConstructionOptions, MonacoEditorLoaderService, MonacoStandaloneCodeEditor } from "@materia-ui/ngx-monaco-editor";
declare const copyToClipboard: any;
export interface DiffContent {
  leftContent: string;
  rightContent: string;
}
@Component({
  selector: 'app-newaudit',
  templateUrl: './newaudit.component.html',
  styleUrls: ['./newaudit.component.scss']
})

export class NewauditComponent implements OnInit {
  classObjalertmain: string;
  StatusMessagedisplay: string;
  statusmessageMainflg: boolean;
  auditmodalheight: Number = 400;
  AuditDifference: any[] = [];
  @Input() inputDataInModalComponent: any;
  id: string = '';
  guid: string;
  context: string = '';
  usernamedisplay: string;
  type: string = '';
  auditdetaillist: any = [];
  title: string;
  audit: AuditValue[] = [];
  auditNew: AuditValue[] = [];
  editor: MonacoStandaloneCodeEditor;
  inputdata: string = "";
  OutputData: string = "";
  formatType: string = "plaintext";
  key: string = ''
  value: string = ''
  showeditor: boolean
  @ViewChild(MonacoEditorComponent, { static: false })
  Olddata: any = ''
  Newdata: any = ''
  formatType1: string = ''
  left: any = ''
  auditdetaillistbackup: any[] = []
  right: any = ''
  contentObservable: Subject<DiffContent> = new Subject<DiffContent>();
  contentObservable$: Observable<DiffContent> = this.contentObservable.asObservable();
  contextlist: any[] = ['All'];
  description: string = 'Pipeline Config deactivated Successfully with configId 57540F20-1140-4BD6-81B4-6B9CE7B067A6_6F94F6E0-7C29-42DA-8C4B-A7E87BCEAE8B_Config'
  constructor(
    private router: Router,
    private service: CommonService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private activeModal: NgbActiveModal,
    public ref: ChangeDetectorRef,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.id = this.inputDataInModalComponent.id;
    this.title = this.inputDataInModalComponent.title;
    this.guid = this.inputDataInModalComponent.guid;
    this.context = this.inputDataInModalComponent.context;
    this.type = this.inputDataInModalComponent.type;
    this.getAuditDetails(this.id);
  }
  async getAuditDetails(id: any) {
    var apiname = "api/Audit/GetAuditDetails?id=" + id + "&context=" + this.context;
    this.ngxUiLoaderService.start();
    this.auditdetaillist = await this.service.getapi(apiname);

    const resultset = this.auditdetaillist as any[];
    if (resultset.length == 0) {

      this.ngxUiLoaderService.stop();
    }
    else {

      resultset.forEach(data => {
        if (this.isvalidjson(data.OldValue) && this.isvalidjson(data.NewValue)) {
          let jsonOldValue = JSON.parse(data.OldValue);
         
          this.getkeyvalue(jsonOldValue, 'audit');
          data.OldValue = [];
          data.OldValue = this.audit;

          let jsonNewValue = JSON.parse(data.NewValue);
         
          this.getkeyvalue(jsonNewValue, 'auditnew');
          data.NewValue = [];
          data.NewValue = this.auditNew;
        }
        else {
          let auditOld: AuditValue[] = [];
          if(data.OldValue.startsWith("{"))
          {
            data.OldValue=JSON.parse(data.OldValue);
            data.OldValue['datatype']='object';
            data.OldValue=JSON.stringify(data.OldValue);
          }
          else{
            data.OldValue=data.OldValue.toString();
          }
          let auditOldValue: AuditValue = {
            Key: '',
            Value: data.OldValue
          }
          auditOld.push(auditOldValue);
          data.OldValue = auditOld;

          let auditNew: AuditValue[] = [];
          if(data.NewValue.startsWith("{"))
          {
            data.NewValue=JSON.parse(data.NewValue);
            data.NewValue['datatype']='object';
            data.NewValue=JSON.stringify(data.NewValue);
          }
          else{
            data.NewValue=data.NewValue.toString();
          }
          let auditNewValue: AuditValue = {
            Key: '',
            Value: data.NewValue
          }
          auditNew.push(auditNewValue);
          data.NewValue = auditNew;
        }
      })

      this.ngxUiLoaderService.stop();
      this.auditdetaillist = resultset;
      this.auditdetaillistbackup = resultset
      const distinctThings = resultset.filter(
        (thing, i, arr) => arr.findIndex(t => t.context.toLowerCase() === thing.context.toLowerCase()) === i
      )
      distinctThings.forEach(element => {
        if (element.context != '' || element.context != null) {
          this.contextlist.push(element.context);
        }
      })
      this.getauditdiff(this.auditdetaillist[0], 0);
    }
  }
  getkeyvalue(data, type) {
    this.auditNew = [];
    this.audit = [];
    if (typeof data === "object") {
      for (let [key, value] of Object.entries(data)) {
        if (value != null) {
          if (typeof value === "object") {
            this.getkeyvalue(value, type);
          }
        }
        if (typeof value === 'string' || value == null) {
          let auditValue: AuditValue = {
            Key: [key].toString(),
            Value: [value].toString()
          }
          if (type == 'auditnew') {
            this.auditNew.push(auditValue);
          }
          else {
            this.audit.push(auditValue);
          }
        }
      }
    }
    if (typeof data === "object") {

    }
  }

  isvalidjson(stringvalue) {
    try {
      let output = JSON.parse(stringvalue);
      return true;

    } catch (error) {
      return false;
    }
  }
  copyToClip(event: any, message: any) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " copied", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  close() {
    this.activeModal.close();
  }
  isselect: any = ''
  getauditdiff(audobj, i) {
    this.isselect = i
    this.ngxUiLoaderService.start();
    this.AuditDifference = audobj
    this.Olddata = ''
    this.Newdata = ''

    if (typeof this.AuditDifference['OldValue'] == 'string' || this.AuditDifference['OldValue'] == '') {
      this.Olddata = this.AuditDifference['OldValue'];
    } else {
      let obj = {}
      this.AuditDifference['OldValue'].forEach(element => {
        if (element['Key'] == '' && element['Value'] == '') {

          this.Olddata = '';
        }
        else if (element['Key'] == '' && element['Value'] != '') {
          if (element['Value'].startsWith('{')) {
            this.constructobj(JSON.parse(element['Value']), 'old')
          }
          else {
            this.Olddata = element['Value'];
          }
        }
        else {
          for (let [key, value] of Object.entries(element)) {
            if ([key].toString() == 'Key') {
              this.key = [value].toString();
            }
            else {
              obj[this.key] = [value].toString();
            }
          }
          this.constructobj(obj, 'old');
          // this.Olddata=JSON.stringify(obj);
          // this.left=this.Olddata
        }
      });
    }
    if (typeof this.AuditDifference['NewValue'] == 'string' || this.AuditDifference['NewValue'] == '') {
      this.Newdata = this.AuditDifference['NewValue'];
    } else {
      let obj = {}
      this.AuditDifference['NewValue'].forEach(element => {
        if (element['Key'] == '' && element['Value'] == '') {

          this.Newdata = '';
        }
        else if (element['Key'] == '' && element['Value'] != '') {
          if (element['Value'].startsWith('{')) {
            this.constructobj(JSON.parse(element['Value']), 'new')
          }
          else {
            this.Newdata = element['Value'];
          }
        }
        else {
          for (let [key, value] of Object.entries(element)) {
            if ([key].toString() == 'Key') {
              this.key = [value].toString()
            }
            else {
              obj[this.key] = [value].toString();
            }
          }
          this.constructobj(obj, 'new');
          // this.Newdata=JSON.stringify(obj);
          // this.right=this.Newdata
        }
      });
    }
    // setTimeout(()=>{
    this.Olddata = this.Olddata;
    this.Newdata = this.Newdata;
    // })
    this.editorDIffData()
    this.showeditor = true
    this.ngxUiLoaderService.stop();
  }

  onCompareResults(diffResults: DiffResults) {
    // //console.log('diffResults', diffResults);
    this.Olddata = this.Olddata;
    this.Newdata = this.Newdata;
  }
  editorDIffData() {
    this.ngxUiLoaderService.start()
    const newContent: DiffContent = {
      leftContent: this.Olddata !== null || this.Olddata !== '' ? this.Olddata : '',
      rightContent: this.Newdata !== null || this.Newdata !== '' ? this.Newdata : '',
    };
    this.contentObservable.next(newContent);
    this.ngxUiLoaderService.stop()
  }
  constructobj(datastring, flag) {
    if(datastring['datatype']==undefined){
    if (typeof datastring == "object") {
      let obj='' ;
      for (let [key, value] of Object.entries(datastring)) {
        obj += `${value},
`
      }
      if (flag == 'old') {
        this.Olddata = obj
      }
      else {
        this.Newdata = obj
      }
    } else {
      if (flag == 'old') {
        this.Olddata =datastring
      }
      else {
        this.Newdata =datastring
      }
    }
  }
  else{
    let obj='' ;
      for (let [key, value] of Object.entries(datastring)) {
        obj += `${key} : ${value},
`
      }
      if (flag == 'old') {
        this.Olddata = obj
      }
      else {
        this.Newdata = obj
      }
  }
  }
  selectedIndexTab: any
  onTabChanged(value) {
    if (value.index !== undefined) {
      this.selectedIndexTab = value.index;
      let selectedcontextname = this.contextlist[this.selectedIndexTab];
      if (selectedcontextname != 'All') {
        this.auditdetaillist = this.auditdetaillist.filter(V =>
          V.context.toLowerCase() === selectedcontextname.toLowerCase()
        )
        this.getauditdiff(this.auditdetaillist[0], 0);
      }
      else {
        this.auditdetaillist = this.auditdetaillistbackup
        this.getauditdiff(this.auditdetaillist[0], 0);
      }
    }
  }
}
export class AuditValue {
  Key: string;
  Value: string;
}
export interface DiffResults {
  hasDiff: boolean;
  diffsCount: number;
  rowsWithDiff: {
    leftLineNumber?: number;
    rightLineNumber?: number;
    numDiffs: number;
  }[];
}

import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { MatMenuTrigger, PageEvent } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TryitoutComponent } from '../tryitout/tryitout.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { XmlmappingsComponent } from '../xmlmappings/xmlmappings.component';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { async } from '@angular/core/testing';
import { Routeversion1Component } from '../routeversion1/routeversion1.component';
import { debug } from 'console';
import { SelectionModel } from '@angular/cdk/collections';
import * as XLSX from 'xlsx';



declare const copyToClipboard: any;
declare var $;


@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})

export class RoutesComponent implements OnInit {
  config = {
    displayKey: 'lookupvalue', //if objects array passed which key to be displayed defaults to description
    search: true,
    height: '300px',
    width: '1000',
  }

  //  customFilter = function(RouteNameList: any[], query: string): any[] {
  //   return RouteNameList.filter(val=>val.name.toLowerCase().includes(query))
  // };

  languageMenuTrigger: MatMenuTrigger;
  cardHeight: Number = window.innerHeight - 235;
  keywordsearch = "displayname";
  keywordsearchProj = "projectName";
  keywordsearcha = "LocationName";

  RouteNameList: any = [];
  ProcessorAgentNameList: any = [];
  SourceAgentNameList: any = [];
  SourceEntityNameList: any = [];
  EntityNameList: any = [];
  TargetAgentNameList: any = [];
  TargetEntityNameList: any = [];
  SearchDetailsList: any = [];
  messageTypeList: any = [];
  reactiveFormmsgtype: FormGroup;
  isLoadingpa: boolean = false;
  isLoadingse: boolean = false;
  isLoadingde: boolean = false;
  isLoadingroutes: boolean = false;
  currendate: Date = new Date();
  selectedProcessorAgentguid: string = "";
  selectedProcessorAgentName: string = "";
  selectedSourceAgentguid: string = "";
  selectedSourceAgentName: string = "";
  selectedSourceEntityguid: string = "";
  selectedSourceEntityName: string = "";
  selectedEntityguid: string = "";
  selectedEntityName: string = "";
  selectedTargetEntityguid: string = "";
  selectedTargetEntityName: string = "";
  selectedTargetAgentguid: string = "";
  selectedTargetAgentName: string = "";
  selectedrouteId: string = "";
  selectedrouteDisplayName: string = "";
  messagetype: string = "select";
  classObjalertmain: string = "";
  StatusMessagedisplay: string = "";
  statusmessageMainflg: boolean = false;
  pageSize = 10;
  pageEvent: PageEvent;
  emptyString = "";
  reactiveFormroute: FormGroup;
  reactiveFormProject: FormGroup;
  reactiveFormpa: FormGroup;
  reactiveFormsa: FormGroup;
  reactiveFormda: FormGroup;
  reactiveFormde: FormGroup;
  reactiveFormse: FormGroup;
  reactiveFormvendor: FormGroup;
  reactiveFormentity: FormGroup;
  UniqueID: any;
  inputData: any;
  fileUploadFTP: boolean;
  enableAccessionSearch: boolean;
  notfounddata: boolean;
  vendorname: string = "";
  vendorid: string="";
  Vendornamelist: any[];
  VendornameBackuplist: any[];
  isLoadingvendor: boolean;
  keywordsearchvendar = "name";
  pagenumber: number = 0;
  busyGettingData: boolean = false;
  totalrecords = 0;
  showSourceTarget: boolean = false;
  isLoadingentity: boolean = false;
  pipelineObj: any;
  userlist = <any>[];
  keywordsearchuser = 'UserName';
  reactiveFormuser: FormGroup;
  selecteduserid: any;
  isftpuser: any;
  userlistbackup: any[];
  isLoadinguser: boolean = false;
  selectedusername: any;
  isftpavailable: Boolean;
  isChecked: Boolean;
  isLockedVer1: any;

  constructor(private _fb: FormBuilder,
    private service: CommonService,
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.getProcessorAgentNames('pipeline');
    var inputdata = JSON.parse(sessionStorage.getItem('VendorData'));
    if (inputdata != null) {
      sessionStorage.removeItem('VendorData');
      this.vendorname = inputdata.vendorname;
      this.vendorid=inputdata.vendorid;
      this.selectedEntityguid = inputdata.entityid;
      this.search(true, true);
    }
    this.reactiveFormroute = _fb.group({ routename: '' });
    this.reactiveFormProject = _fb.group({ projectName: '' });
    this.reactiveFormpa = _fb.group({ paname: '' });
    this.reactiveFormsa = _fb.group({ saname: '' });
    this.reactiveFormda = _fb.group({ daname: '' });
    this.reactiveFormde = _fb.group({ dename: '' });
    this.reactiveFormse = _fb.group({ sename: '' });
    this.reactiveFormmsgtype = this._fb.group({ msgname: '' });
    this.reactiveFormvendor = _fb.group({ vendorname: '' });
    this.reactiveFormentity = _fb.group({ entityname: '' });
    this.reactiveFormuser = _fb.group({ user: '' });
    if (this.vendorname) {
      this.reactiveFormvendor.patchValue({
        vendorname: this.vendorname
      })
    }
  }

  usernamedisplay: string;
  userrole: string = '';
  pagerolepermisions: any = [];
  async ngOnInit() {
    if (await this.service.checksession() == true) {
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'pipelinelist').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
       // //console.log(this.pagerolepermisions)
      });

      this.getMessagetypelist('msgtype');
      this.reactiveFormmsgtype.patchValue(this.messageTypeAllList);
      this.getVendorname();
      this.showSourceTarget = true;
      await this.getProcessorAgentNames('pipeline');
      this.getuserdetails();
      if (this.vendorname === '') {
        this.isChecked = true;
        this.search(false, true);
      }

    }
  }

  onCheck(event) {
    this.isChecked = event.checked;
  }

  isLoadingProjects : boolean
  ProjectNames = [];
  notfoundProj : boolean
  getProjectNames(event : any){

    var val=event;
    if(val){
      this.isLoadingProjects = true;
      // var apiname = "api/Projects/GetProjectNames?searchStr="+ val ;
      this.service.getProjList(val).subscribe(data=>{
        if(data.statusmessage.length == 0 || data.statusmessage == "No data found"){
          this.notfoundProj = true;
          this.isLoadingProjects =false;
        }else if(data.statusmessage.length>0){
          this.ProjectNames = JSON.parse(data.statusmessage);
          this.ProjectNames.filter(obj=> obj.projectName = `${obj.projectName} ${'(' + obj.projectId + ')'}`);
          this.isLoadingProjects =false;
        //   if(filterd.length>0){
        //   for(let i=0;i<filterd.length;i++){
        //   this.reactiveFormProject.patchValue({projectName:filterd[i].projectName})
        //   }
        // }
        }
        else{
          this.notfoundProj = false;
          this.isLoadingProjects =false;
        }
    },error=>{
      this.isLoadingProjects = false;
      this.notfoundProj = true;
    })
  }
  }

  getRouteName(event: any) {

    var val = event;
    if (val) {
      this.isLoadingroutes = true;
      var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=0";
      this.service.getapiurl(apiname).subscribe({
        next: data => {

          // if(data !== null){
          // this.reactiveFormroute.patchValue({routename : ""})
          this.RouteNameList = data as any[];
          this.RouteNameList.map(obj => obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}`);
          this.isLoadingroutes = false;
          // this.reactiveFormroute.patchValue({routename:val})
          // }
         if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingroutes = false;
          this.notfounddata = true;
        }
      });
    }
  }

  selectedprojId : any
  selectedProjName : string = ""
  selectedProject(item){
    this.selectedprojId = item.id,
    this.selectedProjName = item.projectName
    this.pagenumber = 1;
    this.totalrecords = 0;
    this.search(false, true);
  }


  selectedRoute(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
    // let string_value = item.displayname.replace(/\<br\>/g," ");
    // this.reactiveFormroute.patchValue({ routename:string_value });
    this.pagenumber = 1;
    this.totalrecords = 0;
    this.search(false, true);
  }

  getstringProject(value1,val2) {
    let names = val2.split("(")
    val2 = `${names[0]} <br>(${names[1]}`
    return value1 + '(' + val2 + ')'
  }

  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]}`
    return value
  }
  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }
  ProcessorList: any = [];

  async getProcessorAgentNames(event: any) {

    this.isLoadingpa = true;
    var apiname = '';
    if (event !== 'pipeline') {
      this.ProcessorAgentNameList = this.ProcessorList.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
      if (this.ProcessorAgentNameList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingpa = false;
    }
    else {
      apiname = "api/Agents/GetByDisplayName?name=" + '&agentid=0&type=Processor&filterflag=false';

      await this.service.getapiurl(apiname).subscribe({
        next: async data => {
          this.ProcessorAgentNameList = data as any[];
          this.ProcessorAgentNameList.map(obj => { obj.name = obj.displayname, obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}` });
          this.ProcessorList = this.ProcessorAgentNameList;
          this.isLoadingpa = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            if (this.ProcessorList.length > 0) {
              this.ProcessorList = this.service.transformAlphabetically(this.ProcessorList, 'displayname');
            }
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingpa = false;
          this.notfounddata = true;
        }
      });
    }
  }

  selectedProcessorAgents(item) {
    this.selectedProcessorAgentguid = item.id;
    this.selectedProcessorAgentName = item.displayname;
  }

  async getSourceEntityNames(event: any) {

    var valuesa = event;
    if (valuesa != '') {

      this.isLoadingse = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingse = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.SourceEntityNameList = response;
        this.SourceEntityNameList = this.service.transformAlphabetically(this.SourceEntityNameList, 'LocationName');
        this.SourceEntityNameList.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedSourceEntityguid = '';
        this.isLoadingse = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      }
    }

  }

  selectedSourceEntity(item) {
    this.selectedSourceEntityguid = item.LocationGUID;
    this.selectedSourceEntityName = item.LocationName;
  }


  async getTargetEntityNames(event: any) {
    var valueta = event;
    if (valueta != '') {

      this.isLoadingde = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueta;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingde = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.TargetEntityNameList = response as any[];
        this.TargetEntityNameList = this.service.transformAlphabetically(this.TargetEntityNameList, 'LocationName');
        this.TargetEntityNameList.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedTargetEntityguid = '';
        this.isLoadingde = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }

      }
    }
  }

  selectedTargetEntity(item) {
    this.selectedTargetEntityguid = item.LocationGUID;
    this.selectedTargetEntityName = item.LocationName;
  }

  isUUID(uuid) {
    let s;
    s = uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }


  async search(vendorflag, loaddata) {

    if (!vendorflag) {
      if (this.selectedrouteId == '' && this.reactiveFormroute.get('routename').value != '' && this.reactiveFormroute.get('routename').value != null) {
        let returnvalue = this.isUUID(this.reactiveFormroute.get('routename').value);
        if (returnvalue == true) {
          this.selectedrouteId = this.reactiveFormroute.get('routename').value;
        }
        else {
          this.toastr.warning("No results found!!", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          return;
        }
      }


      if (this.showSourceTarget) {
        if (this.selectedEntityguid == '' && this.reactiveFormentity.get('entityname').value != '' && this.reactiveFormentity.get('entityname').value != null) {
          let returnvalue = this.isUUID(this.reactiveFormentity.get('entityname').value);
          if (returnvalue == true) {
            this.selectedEntityguid = this.reactiveFormentity.get('entityname').value;
          }
          else {
            this.toastr.warning("No results found!!", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            return;
          }
        }
      }
      else {
        if (this.selectedSourceEntityguid == '' && this.reactiveFormse.get('sename').value != '' && this.reactiveFormse.get('sename').value != null) {
          let returnvalue = this.isUUID(this.reactiveFormse.get('sename').value);
          if (returnvalue == true) {
            this.selectedSourceEntityguid = this.reactiveFormse.get('sename').value;
          }
          else {
            this.toastr.warning("No results found!!", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            return;
          }
        }

        if (this.selectedTargetEntityguid == '' && this.reactiveFormde.get('dename').value != '' && this.reactiveFormde.get('dename').value != null) {
          let returnvalue = this.isUUID(this.reactiveFormde.get('dename').value);
          if (returnvalue == true) {
            this.selectedTargetEntityguid = this.reactiveFormde.get('dename').value;
          }
          else {
            this.toastr.warning("No results found!!", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            return;
          }
        }
      }


      if (this.selectedProcessorAgentguid == '' && this.reactiveFormpa.get('paname').value != '' && this.reactiveFormpa.get('paname').value != null) {
        let returnvalue = this.isUUID(this.reactiveFormpa.get('paname').value);
        if (returnvalue == true) {
          this.selectedProcessorAgentguid = this.reactiveFormpa.get('paname').value;
        }
        else {
          this.toastr.warning("No results found!!", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          return;
        }
      }
    }
    if (loaddata) {
      this.SearchDetailsList = [];
      this.pagenumber = 0;
    }
    if (this.pagenumber == 0) {
      this.pagenumber = 1;
    }
    var searchData = {
      "messagetype": this.selectedmessagetype,
      "id": this.selectedrouteId,
      "PAID": this.selectedProcessorAgentguid,
      "sourceagentid": this.selectedSourceAgentguid,
      "SEGUID": this.selectedSourceEntityguid,
      "destinationagentid": this.selectedTargetAgentguid,
      "DEGUID": this.selectedTargetEntityguid,
      "vendor": this.vendorid,
      "entityid": this.selectedEntityguid,
      "pagenumber": this.pagenumber,
      "userType": (this.isChecked == true) ? 2 : '',
      "type":'not',
      "projectId":this.selectedprojId,
      "assignedUserId": (this.isChecked == true) ? JSON.parse(sessionStorage.getItem('rolewithmenus'))[0].uniqueId : ''
    };
    var apiname = 'api/Routes/GetSearchDetails';
    this.ngxUiLoaderService.start();
    let response = await this.service.searchpostapi(apiname, searchData);

    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.busyGettingData = false
      this.toastr.error("Something Went Wrong!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });

    } else {
      this.ngxUiLoaderService.stop();
      if (this.pagenumber > 1 && (Array.isArray(response) && response.length > 0)) {
        this.totalrecords = parseInt(response[0].totalpipelinecount.toString());
        this.busyGettingData = false;
        this.SearchDetailsList = this.SearchDetailsList.concat(response as any[]);
      }
      else {
        this.busyGettingData = false;
        this.SearchDetailsList = response as any[];
        if (Array.isArray(response) && response.length > 0) {
          this.totalrecords = parseInt(response[0].totalpipelinecount.toString());
        }
        else {
          this.totalrecords = 0;
        }
      }
      if (this.SearchDetailsList.length === 0) {
        this.busyGettingData = false
        this.toastr.warning("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }
    if(this.SearchDetailsList.length>0){
      this.SearchDetailsList.forEach(va => va['description'] = "")
    }
  }

  //Tryitout modal start
  public dataToModal: any;
  async opentryitout(dataitem) {

    if (dataitem.id != '' && dataitem.PAID != '') {
      this.ngxUiLoaderService.start();
      var response = await this.getFileConfig(dataitem.id, dataitem.PAID);
      if (response != "") {
        var jsonData = JSON.parse(response[0]["json"]);
        var vaconfigdata = jsonData.processorconfig["vaconfig"];
        var interfacetype = jsonData.processorconfig["interfacetype"];

        if (dataitem.messagetype === "order") {
          this.fileUploadFTP = true;
          this.enableAccessionSearch = false;
        } else if (dataitem.messagetype === "result") {
          this.fileUploadFTP = false;
          this.enableAccessionSearch = true;
        }
        else {
          this.fileUploadFTP = false;
          this.enableAccessionSearch = false;
        }

        this.dataToModal = {
          RouteIdtry: dataitem.id,
          ProcessorIdtry: dataitem.PAID,
          ProcessorDisplayNametry: dataitem.PANAME,
          RouteDisplayNametry: dataitem.displayname,
          Processorapikey: dataitem.PAAPIKEY,
          Popupflag: true,
          MappedXmlTemp: '',
          isFileUpload: vaconfigdata.isfileupload == 0 ? false : true,
          isfiledownload: vaconfigdata.isfiledownload == 0 ? false : true,
          interfacetype: interfacetype,
          enableAccessionSearch: this.enableAccessionSearch,
          fileUploadFTP: this.fileUploadFTP,
          sourcedeploymentkey: dataitem.SEDEPLOYMENTKEY,
          targetdeploymentkey: dataitem.DEDEPLOYMENTKEY,
          sourceaccounttype: dataitem.sourcetype,
          targetaccounttype: dataitem.targettype,
          seguid: dataitem.SEGUID,
          deguid: dataitem.DEGUID,
          sourceagent: dataitem.sourceagentid
        };



      }
      this.ngxUiLoaderService.stop();
      const modalRef = this.modalService.open(TryitoutComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });

      modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
      modalRef.result.then(() => { });
    }
    else {
      this.toastr.warning("Pipeline and Processor are mandatory", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }

  }
  //Tryitout modal end

  //mapping start
  Templatearray: any[];
  open(dataitem) {
    if (dataitem.id != '' && dataitem.PAID != '') {

      this.ngxUiLoaderService.start();
      var url = "api/GetMappings/Get?routeid=" + dataitem.id + "&processorid=" + dataitem.PAID + "&processorapikey=" + dataitem.PAAPIKEY;
      this.service.getapiurl(url).subscribe({
        next: responce => {

          this.Templatearray = responce as any[];
          this.dataToModal = {
            templateid: this.Templatearray["template"],
            templatelist: this.Templatearray["templatelist"],
            MappedXml: this.Templatearray["mappedxml"],
            resultJson: this.Templatearray["resultjson"],
            routeid: dataitem.id,
            processorid: dataitem.PAID,
            processorapikey: dataitem.PAAPIKEY,
            routeName: dataitem.displayname,
            ProcessorName: dataitem.PANAME,
            uniqueid: dataitem.UniqueID,
            routedetails: this.Templatearray["routeDetails"],
            CoreConfigVersionList: this.Templatearray["CoreConfigVersionList"],
            lockXMLmap: dataitem.IsLocked !== undefined && dataitem.IsLocked == "True" ? dataitem.IsLocked : false
          };

          if (this.Templatearray["mappedxml"] != null) {
            const modalRef = this.modalService.open(XmlmappingsComponent,
              { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
            modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
            modalRef.result.then(() => { });
          }
          else {
            this.modalService.dismissAll();
            this.toastr.error("Template configurations not found", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
        },
        error: error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Unable to fetch the Mappings", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      });
    }
    else {
      this.toastr.error("Pipeline and Processor are mandatory", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  //end mapping

  editPipeline(item) {
    if (item.Version == '2') {
      sessionStorage.setItem('pipelineid', JSON.stringify(item.id));
      this.router.navigate(['/home/createpipeline']);
    }
    else {
      const modalRef = this.modalService.open(Routeversion1Component,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = item;
      modalRef.result.then((data) => {
        this.isLockedVer1 = item.IsLocked;
        this.isChecked = false;
        if (data == 'save') { this.search(false, true); }
      });
    }
  }

  searchProjectCleared(event) {
    this.isLoadingProjects = false;
    this.ProjectNames = [];
    this.selectedProjName = '';
    this.selectedprojId = '';
    this.notfoundProj = false;
  }

  searchRouteNameCleared(event) {
    this.isLoadingroutes = false;
    this.RouteNameList = [];
    this.selectedrouteId = '';
    this.selectedrouteDisplayName = '';
    this.notfounddata = false;
  }
  searchProcessorAgentCleared(event) {
    this.isLoadingpa = false;
    this.ProcessorAgentNameList = this.ProcessorList;
    this.selectedProcessorAgentguid = '';
    this.notfounddata = false;
  }

  searchTargetEntityCleared(event) {
    this.isLoadingde = false;
    this.TargetEntityNameList = [];
    this.selectedTargetEntityguid = '';
    this.notfounddata = false;

  }
  searchSourceEntityCleared(event) {
    this.isLoadingse = false;
    this.SourceEntityNameList = [];
    this.selectedSourceEntityguid = '';
    this.notfounddata = false;
  }

  reset() {
    this.reactiveFormProject.reset();
    this.reactiveFormroute.reset();
    this.reactiveFormpa.reset();
    this.reactiveFormsa.reset();
    this.reactiveFormda.reset();
    this.reactiveFormde.reset();
    this.reactiveFormse.reset();
    this.SearchDetailsList = [];
    this.selectedSourceAgentguid = "";
    this.selectedTargetAgentguid = "";
    this.selectedProcessorAgentguid = "";
    this.selectedrouteId = "";
    this.selectedmessagetype = '';
    this.selectedSourceEntityguid = "";
    this.selectedTargetEntityguid = "";
    this.selectedEntityguid = "";
    this.vendorname = "";
    this.reactiveFormvendor.reset();
    this.reactiveFormvendor.setValue({ "vendorname": '' });
    this.Vendornamelist = this.VendornameBackuplist;
    this.ProcessorAgentNameList = this.ProcessorList;
    this.reactiveFormpa.setValue({ "paname": '' });
    this.reactiveFormmsgtype.reset();
    this.reactiveFormmsgtype.setValue({ "msgname": '' });
    this.pagenumber = 0;
    this.busyGettingData = false;
    if (!this.showSourceTarget) {
      this.showSourceTarget = true;
    }
    this.messageTypeList = this.messageTypeAllList;
    this.reactiveFormentity.reset();
    this.selectedEntityName = '';
    this.totalrecords = 0;
    this.selectedprojId ='';
  }




  GetMessageTypes() {
    var apiname = "api/Lookup/GetMessageType?lookuptype=messagetype";
    this.service.getapiurl(apiname).subscribe({
      next: data => {
        this.messageTypeList = data as any[];
      },
    });
  }


  getRouteDetails() {

    if (this.selectedrouteId != '') {
      var url = "api/Routes/GetRouteList?id=" + this.selectedrouteId;
      this.ngxUiLoaderService.start();
      this.service.getapiurl(url).subscribe({
        next: data => {

          if (this.selectedrouteId != "") {
            this.selectedrouteDisplayName = data['displayname'];
            this.reactiveFormroute.patchValue({
              routename: this.selectedrouteDisplayName
            });
          }

          this.ngxUiLoaderService.stop();

          if (data['SENAME'] != '') {
            this.selectedSourceEntityguid = data['SEGUID'];
            this.selectedSourceEntityName = data['SENAME'];

            this.reactiveFormse.patchValue({
              sename: this.selectedSourceEntityName
            });
          }

          if (data['DENAME'] != '') {
            this.selectedTargetEntityguid = data['DEGUID'];
            this.selectedTargetEntityName = data['DENAME'];
            this.reactiveFormde.patchValue({
              dename: this.selectedTargetEntityName
            });
          }

          if (data['PANAME'] != '') {
            this.selectedProcessorAgentguid = data['PAID'];
            this.selectedProcessorAgentName = data['PANAME'];

            this.reactiveFormpa.patchValue({
              paname: this.selectedProcessorAgentName
            });
          }

          this.selectedmessagetype = data['messagetype'] as any;
          this.reactiveFormmsgtype.patchValue({
            msgname: this.selectedmessagetype
          });


          if (data["sourceagentid"] != '') {
            this.selectedSourceAgentguid = data["sourceagentid"];
          }

          if (data["destinationagentid"] != '') {
            this.selectedTargetAgentguid = data["destinationagentid"];
          }
          if (data["UniqueID"] != '') {
            this.UniqueID = data["UniqueID"];
          }

        },
        error: error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error('Unable to fetch Pipeline details with ID:' + this.selectedrouteId, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      });
    }

  }


  copyToClip(event: any) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success('Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  copyToClips(event: any, message: string) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
  }

  viewmessages(item, inputstatus, msgcnttoday: number, msgcntyes: number) {
    if (msgcnttoday + msgcntyes > 0) {
      var data = {
        "id": item.id,
        "status": inputstatus,
      };
      sessionStorage.setItem('hubmessagedata', JSON.stringify(data));
      this.router.navigate(['/home/messages']);
    }
    else {
      this.toastr.warning('There are no messages for selected Pipeline and status', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  async getFileConfig(routeid: string, ProcessorId: string) {
    var dataPost = {
      agentid: ProcessorId,
      guids: routeid,
    };
    return await this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost);

  }
  buildtitle(Obj: any) {
    if (typeof (Obj) == "object" && Obj != null) {
      let name = `(${Obj.Type}):${Obj.LocationName}
      ${Obj.Address1}
      `
      return name;
    }
  }
  getVendorname() {
    this.ngxUiLoaderService.start();
    var url = "api/Vendor/GetVendorName";
    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        this.Vendornamelist = data as any[];
        this.Vendornamelist = this.service.transformAlphabetically(this.Vendornamelist, 'name');
        this.VendornameBackuplist = this.Vendornamelist;
        this.ngxUiLoaderService.stop();
      }
      else {
        this.ngxUiLoaderService.stop();
      }
    })
  }
  selectedVendor(item) {
    this.vendorname = item.name;
    this.vendorid = item.Id;
  }
  searchVendorCleared() {
    this.isLoadingvendor = false;
    this.vendorname = "";
    this.Vendornamelist = this.VendornameBackuplist;
    this.reactiveFormvendor.setValue({ "vendorname": '' });
  }
  getVendorName(event) {
    this.isLoadingvendor = false;
    if (this.Vendornamelist.length > 0) {
      this.filtervendorlist(event);
    }
    else {
      this.Vendornamelist = this.VendornameBackuplist;
      this.filtervendorlist(event);
    }
  }
  filtervendorlist(event) {
    this.Vendornamelist = this.Vendornamelist.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
    if (this.Vendornamelist.length == 0) {
      this.notfounddata = true;

    } else {
      this.notfounddata = false;
    }
    this.isLoadingvendor = false;
  }

  isLoadingmessagetype: boolean = false;
  messageTypeAllList: any = [];
  getMessagetypelist(event) {
    this.isLoadingmessagetype = true;
    if (event !== 'msgtype') {
      if (event == undefined) {
        this.messageTypeList = this.messageTypeAllList;
      }
      else {
        if (this.messageTypeList.length == 0) {
          this.messageTypeList = this.messageTypeAllList;
        }
        this.messageTypeList = this.messageTypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageTypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingmessagetype = false;
    }
    else {

      this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: data => {
          data.forEach(element => {
            this.messageTypeAllList.push({ displayname: element });
          });
          this.messageTypeList = this.messageTypeAllList;

          this.isLoadingmessagetype = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingmessagetype = false;
          this.notfounddata = true;
        }
      });
    }

  }
  selectedmessagetype: string = '';
  selectedmessagetypeevent(item) {
    this.selectedmessagetype = item.displayname;
  }

  Clearemessagetype(event) {
    this.selectedmessagetype = '';
    this.messageTypeList = this.messageTypeAllList;
  }

  onScroll($event) {
    let count = 0;
    let isvalid = true;
    count = (parseInt(this.totalrecords.toString()) / 15);
    let exactvalue = Math.floor(count);
    let result = (count - exactvalue) !== 0;
    if (result && ((exactvalue + 1) == this.pagenumber)) {
      isvalid = false;
    }
    else if (this.pagenumber == count) {
      isvalid = false;
    }

    if (isvalid) {
      if (this.busyGettingData) {
        return
      }
      this.busyGettingData = true
      this.ngxUiLoaderService.start();
      this.pagenumber += 1;
      this.search(false, false);
    }
  }
  onUp() {

  }
  async getEntityNames(event: any) {

    var valuesa = event;
    if (valuesa != '') {

      this.isLoadingentity = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingentity = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.EntityNameList = response;
        this.EntityNameList = this.service.transformAlphabetically(this.EntityNameList, 'LocationName');
        this.EntityNameList.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedEntityguid = '';
        this.isLoadingentity = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      }
    }

  }
  selectedEntity(item) {
    this.selectedEntityguid = item.LocationGUID;
    this.selectedEntityName = item.LocationName;
  }
  searchEntityCleared() {
    this.isLoadingentity = false;
    this.EntityNameList = [];
    this.selectedEntityguid = '';
    this.notfounddata = false;
  }
  openassignusermodal(modal, obj) {
    if (this.pagerolepermisions.assignuser) {
      this.pipelineObj = '';
      this.pipelineObj = obj;
      if (this.pipelineObj.assignedUserId) {
        this.selecteduserid = this.pipelineObj.assignedUserId;
        this.isftpuser = true;

        this.selectedusername = this.pipelineObj.assignedUserName;
        this.reactiveFormuser.patchValue({
          user: this.selectedusername
        })
      }

    }
    const modalRef = this.modalService.open(modal,
      { windowClass: "assignuser", backdrop: 'static', keyboard: false });
    modalRef.result.then(() => { });
  }
  getuserdetails() {
    this.ngxUiLoaderService.start();
    var url = "api/UserRoleConfig/GetInterfaceAssignUsers?userid=" + JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        this.ngxUiLoaderService.stop();
        this.userlist = data as any[];
        this.userlistbackup = this.userlist;
      }
    });
  }
  assignuser(c) {

    let interfacearray = <any>[];
    interfacearray.push(this.pipelineObj.displayname);
    if (this.selecteduserid) {
      if (this.pipelineObj.assignedUserId != this.selecteduserid) {
        this.ngxUiLoaderService.start();
        let obj = {
          userid: this.selecteduserid,
          username: this.selectedusername,
          modifiedby: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
          routeuniqueid: this.pipelineObj.UniqueID,
          pipelines: interfacearray,
          isFtpAvailableForUser: this.isftpuser
        }
        let url = "api/Routes/AssignUserToInterface";
        this.service.postapi(url, obj).then(data => {
          if (data) {
            this.ngxUiLoaderService.stop();
            if ((data['status'] && data['statusText']) || data['statusText'] === "Unknown Error") {
              this.ngxUiLoaderService.stop();
              this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
            }
            else {
              if (data.status.toLowerCase() == "success") {
                let displaymessage = '';
                displaymessage = 'Pipeline has been assign to ' + this.selectedusername;

                this.service.auditsave("Add", '', displaymessage, "Pipeline", this.pipelineObj.UniqueID,'Pipeline has been assigned Successfully').then(data => {
                  if (data['status'] && data['statusText']) {
                    this.toastr.error("Unable to Audit the transaction", "", {
                      timeOut: 4000,
                      positionClass: 'toast-bottom-right'
                    });
                  }
                })

                this.toastr.success(data.statusmessage, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
                this.search(false, true);
                this.closeusermodal(c);
              }
              else {
                this.toastr.error(data.statusmessage, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              }
            }
          }
        })
      }
      else {
        this.toastr.warning("User already assigned", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }
    else {
      this.toastr.warning("Please select the valid user", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }

  }
  closeusermodal(c) {
    this.resetuserdetails();
    c('close modal');
  }
  resetuserdetails() {
    this.userlist = this.userlistbackup;
    this.reactiveFormuser.reset();
    this.selecteduserid = '';
    this.selectedusername = '';
    this.isftpuser = '';
    this.isLoadinguser = false;
  }
  selecteduser(event) {
    this.selecteduserid = event.UserId;
    this.selectedusername = event.UserName;
    this.isftpuser = event.IsFtpAvailableForUser;
  }
  searchuserCleared() {
    this.selecteduserid = '';
    this.isftpuser = '';
    this.userlist = this.userlistbackup;
    this.reactiveFormuser.setValue({ "user": '' });
  }

  getuserName(event) {
    this.isLoadinguser = false;
    this.selecteduserid = '';
    if (this.userlist.length > 0) {
      this.filteruserlist(event);
    }
    else {
      this.userlist = this.userlistbackup;
      this.filteruserlist(event);
    }
  }
  filteruserlist(event) {
    this.userlist = this.userlist.filter(t => t.UserName.toLowerCase().includes(event.toLowerCase()));
    if (this.userlist.length == 0) {

      this.notfounddata = true;

    } else {
      this.notfounddata = false;
    }
    this.isLoadinguser = false;
  }
  closematmenu(){
    this.languageMenuTrigger.closeMenu();
  }


  selection = new SelectionModel<any>(true, []);
  checkeddata: any = [];
  async exportreport()
  {
        var searchData = {
      "messagetype": this.selectedmessagetype,
      "id": this.selectedrouteId,
      "PAID": this.selectedProcessorAgentguid,
      "sourceagentid": this.selectedSourceAgentguid,
      "SEGUID": this.selectedSourceEntityguid,
      "destinationagentid": this.selectedTargetAgentguid,
      "DEGUID": this.selectedTargetEntityguid,
      "vendor": this.vendorid,
      "entityid": this.selectedEntityguid,
      "pagenumber":0,
      "PageSize":0,
      "userType": (this.isChecked == true) ? 2 : '',
      "type":'not',
      "projectId":this.selectedprojId,
      "assignedUserId": (this.isChecked == true) ? JSON.parse(sessionStorage.getItem('rolewithmenus'))[0].uniqueId : ''
    };
    var apiname = 'api/Routes/GetSearchDetails';
    this.ngxUiLoaderService.start();

    let response = await this.service.searchpostapi(apiname, searchData);

    const fileName = 'PipelineList.xlsx';
    this.exportToExcel(response, fileName);
    this.ngxUiLoaderService.stop();

  }
  exportToExcel(items: any[], fileName: string): void {

    const selectedItems = items.map(item => {
      return {
        "Pipeline Name": item.displayname,
        "GUID": item.id,
        "Source Entity": item.SENAME,
        "Destination Entity": item.DENAME,
        "Source Agent": item.SourceAgent,
        "Destination Agent": item.DestinationAgent,
        "Processor Type": item.PANAME,
        "Message Type": item.messagetype,
        "Direction": item.Direction,
       "Mode": item.mode,
        "Connectivity": item.Connectivity,
        "Vendor": item.vendor,
        "Created Date ": item.CreatedDate,
        "Go-Live Date": item.GoLiveDate,
        "Last Transaction Date": item.LastTransactionDate,
        "Status": item.status
      };
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedItems);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob: Blob = new Blob([excelBuffer], { type: 'application/xml' });
    //saveAs(blob, fileName);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

  }
}





import { Router } from '@angular/router';
import { CommonService } from './../services/common.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { W700servicesService } from '../services/w700services.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import * as FileSaver from 'file-saver';
import { MatRadioModule } from '@angular/material/radio';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ToastrService } from 'ngx-toastr';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map, startWith } from 'rxjs/operators';
declare var $
@Component({
  selector: "app-compendium",
  templateUrl: "./compendium.component.html",
  styleUrls: ["./compendium.component.scss"],
})
export class CompendiumComponent implements OnInit {
  deploymentlist = [];
  verdorlist = [
    'Compendium',
    'Raw',
    'AllScripts',
    'eCW',
    'GMed',
    'Vanadis'
  ];
  public reactiveOrganization: FormGroup;
  public reactiveAccount: FormGroup;
  userActivity;
  userInactive: Subject<any> = new Subject();
  Organizations = <any>[];
  Accounts = <any>[];
  CaseTypes = [];
  keywordsearch_Organization = "OrganizationName";
  keywordsearch_Account = "Account";
  keywordsearch_CaseType = "CaseType";
  keywordsearch_Deployment = "Deployment";
  OrganizationID: number = 0;
  AccountID: number = 0;
  CaseType: string = "";
  dropdownList = [];
  selectedItems = [];
  dropdownSettingsforcasetype = {};
  dropdownSettingsforaoe = {};
  OrderCode: string = "";
  selectedCaseType = [];
  selectedAoeType: any[] = [];
  OptionalAOE: boolean = true;

  Deployment: string = "";
  ctrlAccounts = new FormControl();
  isVendor = "Compendium";
  AOE = "";
  ctrlOrganization: string = "";
  usernamedisplay: any;
  userrole: any;
  aoelist: any[] = [
    "Jars",
    "Services",
    "Collection Method",
    "Sites",
    "Clinical History",
    "Bill To",
    "Rule Outs",
  ];
  pagerolepermisions: any;
  isLoadingResult: boolean = false;
  isLoadingResultAccount: boolean = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  caseTypeCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = ["Lemon"];
  allFruits: string[] = ["Apple", "Lemon", "Lime", "Orange", "Strawberry"];

  @ViewChild("caseInput", { static: false })
  caseInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;
  CompendiumAccount: string;
  compendiumaoe: string;
  filteredCasetypes: Observable<any[]>;

  constructor(private ngxUiLoaderService: NgxUiLoaderService, public service: CommonService, public router: Router, private toastr: ToastrService,
    private _fb: FormBuilder,) {
    this.reactiveOrganization = this._fb.group({ orgname: '' });
    this.reactiveAccount = this._fb.group({ accname: '' });
  }

  async ngOnInit() {
    this.loadPrerequisites();
    this.filtercasetypeMethod();
    this.getDeployments();
  }
  getDeployments() {
    this.service.getDeployments("Deployments").subscribe(
      (data) => {
        if (data.length > 0) {
          this.deploymentlist = data;
        } else if (data.length === 0) {
          this.deploymentlist = [];
        }
      },
      (error) => {
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }

  filtercasetypeMethod() {
    this.filteredCasetypes = this.caseTypeCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.CaseTypes.slice()
      )
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.CaseTypes.filter((fruit) =>
      fruit.CaseType.toLowerCase().includes(filterValue)
    );
  }

  loadPrerequisites() {
    this.dropdownSettingsforcasetype = {
      singleSelection: false,
      idField: "CaseType",
      textField: "CaseTypeDisplayName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 6,
      allowSearchFilter: true,
    };

    this.dropdownSettingsforaoe = {
      singleSelection: false,
      idField: "AoeDetails",
      textField: "AoeName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 6,
      allowSearchFilter: true,
    };
  }

  onCaseTypeSelect(item: any) {
    this.selectedCaseType.push(item.CaseType);
  }
  onCaseTypeSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.selectedCaseType.push(items[i].CaseType);
    }
  }
  onaoeSelect(item: any) {
    this.selectedAoeType.push(item.AoeDetails);
  }
  onaoeSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.selectedAoeType.push(items[i].AoeDetails);
    }
  }

  onCaseTypeDeSelect(item: any) {
    this.selectedCaseType = this.selectedCaseType.filter((x) => x != item);
  }
  onaoeDeSelect(item: any) {
    this.selectedAoeType = this.selectedAoeType.filter((x) => x != item);
  }
  onCaseTypeDeSelectAll(items: any) {
    this.selectedCaseType = [];
  }
  onaoeDeSelectAll(items: any) {
    this.selectedAoeType = [];
  }

  Compendium: ICompendium | undefined;

  async getOrganization(input: any) {
    this.Accounts = [];
    this.CaseTypes = [];
    if (input.length < 3) {
      return;
    }

    if (this.Validate() == false) {
      return;
    }
    var urlterm = "api/Compendium/GetOrganizations?OrganizationName=" + input + "&deploymentkey=" + this.Deployment.toLowerCase();
    this.isLoadingResult = true;
    var response = await this.service.getapi(urlterm);
    if (response['status'] && response['statusText']) {
      this.isLoadingResult = false;
      return '';
    } else {
      if (response.length > 0) {
        this.isLoadingResult = false;
        this.Organizations = response as any[];
      }
    }


  }

  getOrganizationName(input: any) { }

  async getAccounts(input: any) {
    this.OrganizationID = input.OrganizationID;
    this.AccountID = 0;
    this.CaseType = "";

    this.CaseTypes = [];
    if (this.Validate() == false) {
      return;
    }
    var urlterm =
      "api/Compendium/GetAccounts?OrganizationID=" + this.OrganizationID + "&deploymentkey=" + this.Deployment.toLowerCase();
    this.isLoadingResultAccount = true;
    var response = await this.service.getapi(urlterm);
    if (response['status'] && response['statusText']) {
      this.isLoadingResultAccount = false;
      return '';
    } else {
      if (response.length > 0) {
        this.isLoadingResultAccount = false;
        this.Accounts = response as any[];
      }
    }

  }


  async getCaseTypes(input: any) {
    this.AccountID = input.value == undefined ? input.AccountID : input.value;
    var urlterm = "api/Compendium/GetCaseTypes?LabOrgID=" + this.OrganizationID + "&LabAccountID=" + this.AccountID + "&deploymentkey=" + this.Deployment.toLowerCase();;

    if (this.Validate() == false) {
      return;
    }
    var data = await this.service.getapi(urlterm);
    if (data['status'] && data['statusText']) {
      return '';
    } else {
      if (data) {
        let obj = {
          CaseType: "All",
          OrderCode: "All",
          CaseTypeDisplayName: "All"
        }
        data.length > 0 ? data.unshift(obj) : data;
        this.CaseTypes = data as any[];
        this.filtercasetypeMethod()
      }
    }
  }


  searchOrganizationCleared(input: any) {
    this.Accounts = [];
    this.CaseTypes = [];
  }
  searchDeployementCleared(input: any) {
    this.ctrlAccounts.setValue("");
    this.Deployment = "";
    this.Organizations = [];
    this.Accounts = [];
    this.CaseTypes = [];
    this.selectedCaseType = [];
    this.CompendiumAccount = "";
    this.reactiveOrganization.patchValue({ orgname: "" })
    this.isVendor = "";
    this.compendiumaoe = ""
  }

  searchAccountsCleared(input: any) {
    this.CaseTypes = [];
    this.CompendiumAccount = "";
  }
  searchVendorCleared(input: any) {
    this.isVendor = "";
  }

  GetOptionalAOE(input: any) {
    this.OptionalAOE = input.value;
  }

  Validate() {
    if (this.Deployment == "" || this.Deployment == "Deployment") {
      this.toastr.warning("Choose a deployment ", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
      return false;
    }
    return true;
  }

  async GetCompendium() {
    let caseTypesValue = "";
    if (this.selectedCaseType.length > 0) {
      let exists = this.selectedCaseType.filter((va) => va === "All");
      if (exists.length > 0 && this.CaseTypes.length > 0) {
        for (let i = 0; i < this.CaseTypes.length; i++) {
          if (this.CaseTypes[i].CaseType !== 'All' && i > 0 && i < this.CaseTypes.length - 1) {
            caseTypesValue += this.CaseTypes[i].CaseType + ",";
          } else if (this.CaseTypes[i].CaseType !== 'All') {
            caseTypesValue += this.CaseTypes[i].CaseType;
          }
        }
      } else {
        caseTypesValue = this.selectedCaseType.join(",");
      }
    }

    this.Compendium = {
      OrganizationID: this.OrganizationID,
      AccountID: this.AccountID,
      CaseType: caseTypesValue,
      OptionalAOE: this.OptionalAOE,
      OrganizationName: "",
      Vendor: this.isVendor,
      AOEs: this.selectedAoeType.join(","),
    };

    if (this.Validate() == false) {
      return;
    }
    var urlterm =
      "api/Compendium/GetCompendium?OrganizationID=" +
      this.OrganizationID +
      "&AccountID=" +
      this.AccountID +
      "&CaseType=" +
      caseTypesValue +
      "&OptionalAOE=" +
      this.OptionalAOE +
      "&Vendor=" +
      this.isVendor  +
      "&deploymentkey=" +
      this.Deployment.toLowerCase();

    if (this.Validate() == false) {
      return;
    }
    this.ngxUiLoaderService.start();

    var response = await this.service.getapi(urlterm);
    if (response['status'] && response['statusText']) {
      return '';
    } else {
      const source = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response["FileContents"]}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = "Compendium_" + Date.now().toString() + ".xlsx";
      link.click();
      this.ngxUiLoaderService.stop();
      this.toastr.success("Compedium downloaded successfully", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }

  }

  OnDeploymentChange(input: any) {
    if (this.Deployment == input) {
      return;
    }
    this.ctrlAccounts.setValue("");
    this.Deployment = input;
    this.Organizations = [];
    this.Accounts = [];
    this.CaseTypes = [];
    this.selectedCaseType = [];
    this.CompendiumAccount = "";
    this.reactiveOrganization.patchValue({ orgname: "" })
    this.isVendor = "";
    this.compendiumaoe = ""
  }
  OnisVendorChange(input: any) {
    this.isVendor = input;
  }
  OnAccountChange(input: any) {
    this.CompendiumAccount = input;
    this.getCaseTypes(input)
  }

  OnAOEChange(input: any) {
    this.AOE = input.target.value;
  }

  getDeployment(input: any) { }

  openCompendium() {
    this.clear();
    $("#exampleModal").appendTo("body").modal("show", {
      backdrop: "static",
      keyboard: false,
    });
  }
  clear() {
    this.Deployment = "";
    this.CaseTypes = [];
    this.selectedCaseType = [];
    this.CompendiumAccount = "";
    this.compendiumaoe = "";
    this.caseTypeCtrl.setValue("");
    this.Accounts = [];
    this.Organizations = [];
    this.isVendor = "";
    this.reactiveOrganization.reset();
    this.reactiveAccount.reset();
  }

  remove(casetype: string): void {
    const index = this.selectedCaseType.indexOf(casetype);
    if (index >= 0) {
      this.selectedCaseType.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.viewValue == "All") {
      this.selectedCaseType = [];
    }
    if (this.selectedCaseType.length > 0) {
      let exists = this.selectedCaseType.filter((va) => va === "All");
      if (exists.length > 0) {
        this.toastr.warning(
          exists[0] +
          " " +
          "option includes every casetype for the lab account",
          "",
          {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          }
        );
      } else {
        let exist = this.selectedCaseType.filter(
          (va) => va === event.option.viewValue
        );
        if (exist.length > 0) {
          this.toastr.warning(exist[0] + " " + "Already Exist", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          this.selectedCaseType.push(event.option.viewValue);
        }
      }
    } else {
      this.selectedCaseType.push(event.option.viewValue);
    }
    this.caseInput.nativeElement.value = "";
    this.caseTypeCtrl.setValue("");
    let ele = document.getElementById("CaseType");
    ele.innerText = "";
    ele.focus();
    ele.blur();
  }
}

export interface ICompendium {
  OrganizationID: Number,
  CaseType: String,
  AccountID: Number,

  OptionalAOE: boolean,

  Vendor: String,
  OrganizationName: String,
  AOEs: String
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeAgoPipe } from 'time-ago-pipe';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JasperoAlertsModule } from '@jaspero/ng-alerts';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule, MatProgressSpinnerModule, MatStepperModule, MatTableModule, MatTooltipModule, MatTreeModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule,MatChipRemove } from '@angular/material/chips';
import { SearchPipelineComponent } from './search-pipeline/search-pipeline.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { TryitoutComponent } from './tryitout/tryitout.component';
import { XmlmappingsComponent } from './xmlmappings/xmlmappings.component';
import { InterfacemappingComponent } from './interfacemapping/interfacemapping.component';
import { HomepageComponent } from './homepage/homepage.component';
import { RoutesComponent } from './routes/routes.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { MatExpansionModule, MatPaginatorModule, MatListModule } from '@angular/material';
import { HubmessagesComponent } from './hubmessages/hubmessages.component';
import { MatMenuModule } from '@angular/material/menu';
import { TryitoutmainComponent } from './tryitoutmain/tryitoutmain.component';
import { AppConfigService } from './configurations/app-config.service';
import { SaveagentsComponent } from './agents/saveagents/saveagents.component';
import { AgentlistComponent } from './agents/agentlist/agentlist.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RouteNotesComponent } from './route-notes/route-notes.component';
import { MonacoEditorComponent, MonacoEditorLoaderService, MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { AuditdetailsComponent } from './auditdetails/auditdetails.component';
import { W700jobslistComponent } from './w700jobslist/w700jobslist.component';
import { SearchFilterPipe } from './w700jobslist/search.pipes';
import { Pagination } from './w700jobslist/pagination.pipes';
import { CreatejobComponent } from './createjob/createjob.component';
import { JobpropertiesComponent } from './jobproperties/jobproperties.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpErrorInterceptor } from './services/HttpInterceptor';
import { VarulesComponent } from './varules/varules.component';
import { FtpfilesComponent } from './ftpfiles/ftpfiles.component';
import { NgDragDropModule } from 'ng-drag-drop';
import { MatRadioModule } from '@angular/material'
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CustomMaterialModule } from './confirm-dialog/custom-material.module';
import { FilescompareComponent } from './filescompare/filescompare.component';
import { ConditionComponent } from './condition/condition.component';
import { CompendiumComponent } from './compendium/compendium.component';
import { BulkmessagereprocessComponent } from './bulkmessagereprocess/bulkmessagereprocess.component';
import { MappingversionapproveComponent } from './mappingversionapprove/mappingversionapprove.component';
import { MatBadgeModule } from '@angular/material/badge';
import { CompareComponent } from './compare/compare.component';
import { NgxTextDiffModule } from 'ngx-text-diff';
import { AuthGuard } from './services/auth.guard';
import { TemplateComponent } from './template/template.component';
import { UserroleComponent } from './userrole/userrole.component';
import { AgentComponent } from './agents/agent/agent.component';
import { VbjobsdisplayComponent } from './vbjobsdisplay/vbjobsdisplay.component';
import { VendorlistComponent } from './vendor/vendorlist/vendorlist.component';
import { SavevendorComponent } from './vendor/Savevendor/savevendor.component';
import { MetricshighchartComponent } from './metricshighchart/metricshighchart.component';

import { HighchartsChartModule } from 'highcharts-angular';
import { MetricpipelinelistComponent } from './metricpipelinelist/metricpipelinelist.component';
import { ErrormessagelistComponent } from './errormessage/errormessagelist.component';
import { ConfigComponent } from './config/config.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { BadmessageComponent } from './badmessage/badmessage.component';
import { ProductagentComponent } from './productagent/productagent.component';
import { DashboardComponent } from './CIIS/dashboard/dashboard.component';
import { ViewComponent } from './CIIS/view/view.component';
import { MessageComponent } from './message/message.component';
import { RequestFormComponent } from './CIIS/request-form/request-form.component';
import { FileViewExplorerComponent } from './file-view-explorer/file-view-explorer.component';
import { StylePaginatorDirective } from './services/paginatorStyleDirective';
import { ContactComponent } from './vendor/contact/contact.component';
import { CacheComponent } from './cache/cache.component';
import { JobComponent } from './job/job.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Routeversion1Component } from './routeversion1/routeversion1.component';
import { MessageattributesComponent } from './messageattributes/messageattributes.component';
import { ContactmoduleComponent } from './contactmodule/contactmodule.component';
import { ManageMenusComponent } from './CIIS/manage-menus/manage-menus.component';
import { LogsComponent } from './logs/logs.component';
import { CaseflowComponent } from './caseflow/caseflow.component';
import { PatienthistoryComponent } from './patienthistory/patienthistory.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Case360Component } from './case360/case360.component';
import { CaseinfoComponent } from './case360/caseinfo/caseinfo.component';
import { CasemessageComponent } from './case360/casemessage/casemessage.component';
import { CasespecimenComponent } from './case360/casespecimen/casespecimen.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { OtherinfoComponent } from './case360/otherinfo/otherinfo.component';
import { MailComponent } from './mail/mail.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { InterfaceComponent } from './CIIS/interface/interface.component';
import { CaseauditComponent } from './case360/caseaudit/caseaudit.component';
import { DataValidationComponent } from './Model/data-validation/data-validation.component';
import { TripFilterPipe } from '../Utility/tripfilter.pipe';
import { NewauditComponent } from './newaudit/newaudit.component';
export function initializeApp(appConfigService: AppConfigService) {
  return () => appConfigService.load()
}
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  //bgsColor: 'red',
  //bgsPosition: POSITION.bottomCenter,
  bgsSize: 50,
  bgsType: SPINNER.threeStrings,
  fgsType: SPINNER.threeStrings,
  fgsSize: 80,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar  direction
  pbThickness: 5, // progress bar thicknesss
  overlayColor: 'rgba(20,20,20,.6)'
};

@NgModule({
  declarations: [
    AppComponent,
    // TimeAgoPipe,
    SearchPipelineComponent,
    LoginComponent,
    TryitoutComponent,
    XmlmappingsComponent,
    InterfacemappingComponent,
    HomepageComponent,
    RoutesComponent,
    HubmessagesComponent,
    TryitoutmainComponent,
    SaveagentsComponent,
    AgentlistComponent,
    RouteNotesComponent,
    AuditdetailsComponent,
    W700jobslistComponent,
    SearchFilterPipe,
    Pagination,
    CreatejobComponent,
    JobpropertiesComponent,
    VarulesComponent,
    FtpfilesComponent,
    ConfirmDialogComponent,
    FilescompareComponent,
    ConditionComponent,
    CompendiumComponent,
    BulkmessagereprocessComponent,
    MappingversionapproveComponent,
    CompareComponent,
    TemplateComponent,
    UserroleComponent,
    AgentComponent,
    VbjobsdisplayComponent,
    VendorlistComponent,
    SavevendorComponent,
    MetricshighchartComponent,
    MetricpipelinelistComponent,
    ErrormessagelistComponent,
    ConfigComponent,
    BadmessageComponent,
    ProductagentComponent,
    CacheComponent,
    JobComponent,
    DashboardComponent,
    ViewComponent,
    RequestFormComponent,
    MessageComponent,
    FileViewExplorerComponent,
    ContactComponent,
    StylePaginatorDirective,
    Routeversion1Component,
    MessageattributesComponent,
    ContactmoduleComponent,
    ManageMenusComponent,
    LogsComponent,
    CaseflowComponent,
    PatienthistoryComponent,
    Case360Component,
    CaseinfoComponent,
    CasemessageComponent,
    CasespecimenComponent,
    OtherinfoComponent,
    MailComponent,
    DiagnosisComponent,
    InterfaceComponent,
    CaseauditComponent,
    DataValidationComponent,
    TripFilterPipe,
    NewauditComponent
  ],

  imports: [
    RouterModule,
    DragDropModule,
    NgxTextDiffModule,
    AngularEditorModule,
    BrowserModule,
    FormsModule,
    JasperoAlertsModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatTabsModule,
    MatBadgeModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgbModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatInputModule,
    MatCheckboxModule,
    MatChipsModule,
    HttpClientModule,
    AutocompleteLibModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    MatTableModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule, // import this module for showing loader automatically when navigating between app routes
    MatListModule,
    MatMenuModule,
    NgMultiSelectDropDownModule.forRoot(),
    MonacoEditorModule,
    ToastrModule.forRoot({
      timeOut: 4000, // 4 seconds
      closeButton: true,
      progressBar: true,
      countDuplicates:true
    }),
    MatExpansionModule,
    MatTreeModule,
    PdfViewerModule,
    NgDragDropModule.forRoot(),
    MatRadioModule,
    CustomMaterialModule,
    HighchartsChartModule,
    InfiniteScrollModule
  ],
  providers: [AuthGuard, DatePipe, MatNativeDateModule, MatDatepickerModule, NgbActiveModal, NgbModal, MonacoEditorModule, MonacoEditorLoaderService,
    AppConfigService,
    TripFilterPipe,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  entryComponents: [DiagnosisComponent,FileViewExplorerComponent, ContactComponent, MessageComponent, CompareComponent, SearchPipelineComponent, TryitoutComponent, XmlmappingsComponent, InterfacemappingComponent,
    RouteNotesComponent, MonacoEditorComponent, AuditdetailsComponent, CreatejobComponent, JobpropertiesComponent, FtpfilesComponent,
    VarulesComponent, ConfirmDialogComponent, ConditionComponent, VbjobsdisplayComponent, SavevendorComponent,
    ConfigComponent, Routeversion1Component, MessageattributesComponent,MailComponent,DataValidationComponent,NewauditComponent]
})
export class AppModule {
  constructor(private injector: Injector) { }
  ngDoBootstrap() {

  }
}

export interface AlertSettings {
  overlay?: boolean;
  overlayClickToClose?: boolean;
  showCloseButton?: boolean;
  duration?: number;
}

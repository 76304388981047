import { Component, OnInit, Input, Output ,ViewChild,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup ,FormControl} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import beautify from "xml-beautifier";
import { COMMA, ENTER, V, X } from "@angular/cdk/keycodes";

declare const copyToClipboard: any;
declare var $

import { SelectionModel } from '@angular/cdk/collections';
import { AppConfigService } from '../configurations/app-config.service';
import { ToastrService } from 'ngx-toastr';
import { MonacoEditorConstructionOptions, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
  selector: 'app-errormessagelist',
  templateUrl: './errormessagelist.component.html',
  styleUrls: ['./errormessagelist.component.scss']
})

export class ErrormessagelistComponent implements OnInit {
  msgfieldlist: any = [];
  @ViewChild('msgfieldInput', { static: true }) msgfieldInput: ElementRef<HTMLInputElement>;
  msgfieldctrl = new FormControl('');
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  archivedbcheck: boolean = false;
  usernamedisplay: string;
  isLoadingroutes: boolean = false;
  isLoadingTarget: boolean = false;
  isLoadingProcesor: boolean = false;
  isLoadingsource: boolean = false;
  cardHeight: Number = window.innerHeight - 253;
  stagemodalheight: Number = window.innerHeight - 52;
  stagemodalheighttextarea: Number = window.innerHeight - 183;

  tablediv: boolean = false;
  statusmessageMainflg: boolean = false;
  keywordsearch = "displayname";

  RouteNameList: any = [];
  SourceAccountList: any = [];
  TargetAccountList = <any>[];
  ProcessorList = <any>[];
  messageList = <any>[];
  messagestageList = <any>[];
  messageTypeList: any = [];
  messagetype: string = "select";

  selectedrouteId: string = "";
  selectedrouteDisplayName: string = "";
  selectedsourceaccountid: string = '';
  selectedsourceaccountName: string = '';
  selectedtargetaccountid = "";
  selectedtargetaccountName = "";
  selectedprocessagentid = "";
  selectedprocessdisplayname = "";

  reactiveFormtarget: FormGroup;
  reactiveFormprocess: FormGroup;
  reactiveFormsource: FormGroup;
  reactiveFormroute: FormGroup;

  reactiveFormmessageid: FormGroup;
  reactiveFortransactionid: FormGroup;
  reactiveForentityfield: FormGroup;
  reactiveFormmsgtype: FormGroup;
  reactiveFormentity:FormGroup;

  isAddedForReprocess: boolean;
  screenHeight: number;
  notfounddata: boolean;
  message_iot_data = {};
  arhivecheckbackup: boolean;
  fileidarray: any[] = [];
  isContainsMultiplefileid: boolean;
  messageiotreprocess: boolean;
  backupinputdata: any;
  msgObjforiot: any;
  messageErrorList: any[] = [];
  errorCode: any;
  today = new Date();
  showSourceTarget:boolean=true;
  isLoadingentity: boolean=false;
  EntityNameList= <any>[];
  selectedEntityguid: string='';
   selectedEntityName: string='';
   reactiveFormparentmessageid:FormGroup;
  constructor(private router: Router,
    private service: CommonService,
    private _fb: FormBuilder,
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal,
  ) {
    this.getProcessorName('pipeline');
    this.reactiveFormsource = _fb.group({ name: '' });
    this.reactiveFormroute = _fb.group({ routename: '' });
    this.reactiveFormtarget = _fb.group({ namet: '' });
    this.reactiveFormprocess = _fb.group({ namep: '' });
    this.reactiveFormmessageid = _fb.group({ namemessageid: '' });
    this.reactiveFortransactionid = _fb.group({ nametransactionid: '' });
    this.reactiveForentityfield = _fb.group({ nameentityfieldid: '' });
    this.reactiveFormmsgtype = this._fb.group({ msgname: '' });
    this.reactiveFormentity=this._fb.group({entityname:''})
    this.reactiveFormparentmessageid=this._fb.group({parentmsgid:''});
    var inputdata = JSON.parse(sessionStorage.getItem('hubmessagedata'));
    if (inputdata != null) {
      sessionStorage.removeItem('hubmessagedata');
      this.selectedrouteId = inputdata.id;
      this.getRouteDetails();
      this.SearchMessages();
    }
  }
  userrole: string = '';
  pagerolepermisions: any = [];
  async ngOnInit() {
    this.setDateFilter()
    this.screenHeight = window.innerHeight
    if (await this.service.checksession() == true) {
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'messages').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });

      this.isAddedForReprocess = false;
      this.getMessagetypelist('msgtype');
      this.reactiveFormmsgtype.patchValue(this.messageTypeAllList);

    }
  }
  setDateFilter() {
    var curr = new Date; // get current date
    var first = curr.getDay(); // First day is the day of the month - the day of the week
    var last = curr.getDate(); // last day is the first day + 6
    first = last - curr.getDay();
    ////console.log(curr, first, last);
      first = last - 2;
      let firstday = new Date(curr.setDate(first));
    let lastd = new Date()
    let lastday = new Date(lastd.setDate(last));
    let firstdate,lastdate,firstmonth,lastmonth
    firstmonth = firstday.getMonth() + 1 < 10 ? "0"+(firstday.getMonth() + 1):firstday.getMonth() + 1;
    lastmonth = lastday.getMonth() + 1 < 10 ? "0"+(lastday.getMonth() + 1):lastday.getMonth() + 1;
    firstdate = firstday.getDate() < 10 ? "0"+firstday.getDate():firstday.getDate();
    lastdate = lastday.getDate() < 10 ? "0"+lastday.getDate():lastday.getDate();

    this.fromdate = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    // this.todate = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    this.todate = `${lastday.getFullYear()}-${lastmonth}-${lastdate}`;

    ////console.log(this.datefilter,this.enddtfilter)
  }

  getmessage_iot(value, modalmessageiot) {
    this.ngxUiLoaderService.start();
    this.msgObjforiot = value;
    this.service.message_IOTData(this.msgObjforiot["id"], this.arhivecheckbackup).subscribe(data => {
      if (data) {
        this.message_iot_data = data
        this.message_iot_data['title'] = this.msgObjforiot["messageid"]
        if(this.message_iot_data["InputData"]){
          if(this.service.IsJsonString(this.message_iot_data["InputData"])){
            this.message_iot_data["InputData"]=JSON.stringify(JSON.parse(this.message_iot_data["InputData"]),null,2);
          }
          else if(this.IsValidXML(this.message_iot_data["InputData"])){
            this.message_iot_data["InputData"]=beautify(this.message_iot_data["InputData"]);
          }
        }
        if(this.message_iot_data["TransformedData"]){
          if(this.service.IsJsonString(this.message_iot_data["TransformedData"])){
            this.message_iot_data["TransformedData"]=JSON.stringify(JSON.parse(this.message_iot_data["TransformedData"]),null,2);
          }
          else if(this.IsValidXML(this.message_iot_data["TransformedData"])){
            this.message_iot_data["TransformedData"]=beautify(this.message_iot_data["TransformedData"]);
          }
        }

        this.ngxUiLoaderService.stop()
        this.modalService.open(modalmessageiot, { windowClass: "messageiot", backdrop: 'static', keyboard: false }).result.then((result) => { });
      }
    }, error => {
      this.ngxUiLoaderService.stop()
      this.toastr.error(error.statusText, "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    })
  }
  selectedRoute(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
    this.SearchMessages();
  }


  getRouteNames(event: any) {
    var val = event;
    if (val) {
      this.isLoadingroutes = true;
      var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=0";
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.RouteNameList = data as any[];
          this.RouteNameList.map(obj => obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}`);
          this.isLoadingroutes = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingroutes = false;
          this.notfounddata = true;
        }
      });
    }
  }
  searchRouteNameCleared(event) {
    this.isLoadingroutes = false;
    this.RouteNameList = [];
    this.selectedrouteId = '';
    this.selectedrouteDisplayName = '';
    this.notfounddata = false;
  }

  keywordaccount = "LocationName";

  async getSourceAccountName(event: any) {
    var valuesa = event;
    this.selectedsourceaccountid = '';
    if (valuesa != '') {

      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;

      this.isLoadingsource = true;
      let response = await this.service.getapi(createSearch);

      if (response['status'] && response['statusText']) {
        this.isLoadingsource = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.notfounddata = true;
      } else {
        this.SourceAccountList = response as any[];
        this.SourceAccountList.map(obj => obj.LocationName = `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
        this.selectedsourceaccountid = '';
        this.isLoadingsource = false;
        if (response.length == 0) {
          this.notfounddata = true;
        }
        else {
          this.notfounddata = false;
        }
      }
    }
  }
  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]}`
    return value
  }
  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }

  selectedsourceagentEvent(item) //source agent selected Event
  {
    this.selectedsourceaccountid = item.LocationGUID;
    this.selectedsourceaccountName = item.LocationName;
  }

  searchSourceNameCleared(event) {
    this.isLoadingsource = false;
    this.SourceAccountList = [];
    this.selectedsourceaccountid = '';
    this.selectedsourceaccountName = '';
    this.notfounddata = false;
  }

  searchTargetNameCleared(event) {
    this.isLoadingTarget = false;
    this.TargetAccountList = [];
    this.selectedtargetaccountid = '';
    this.selectedtargetaccountName = '';
    this.notfounddata = false;
  }
  searchProcesorNameCleared(event) {
    this.isLoadingProcesor = false;
    this.ProcessorList = this.ProcessorAllList;
    this.selectedprocessagentid = '';
    this.selectedprocessdisplayname = '';
    this.notfounddata = false;

  }

  async getTargetAccountName(event: any) {
    var valueta = event;
    this.selectedtargetaccountid = '';
    if (valueta != '') {

      this.isLoadingTarget = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueta;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingTarget = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.notfounddata = true;
      } else {
        this.TargetAccountList = response as any[];
        this.TargetAccountList.map(obj => obj.LocationName = `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
        this.selectedtargetaccountid = '';
        this.isLoadingTarget = false;
        if (response.length == 0) {
          this.notfounddata = true;
        }
        else {
          this.notfounddata = false;
        }
      }
    }
  }

  ProcessorAllList = <any>[];
  async getProcessorName(event: any) {

    this.selectedprocessagentid = '';
    this.isLoadingProcesor = true;
    var apiname = '';
    if (event !== 'pipeline') {
      apiname = "api/Agents/GetByDisplayName?name=" + event + '&agentid=0&type=Processor&filterflag=true';
      this.ProcessorList = this.ProcessorAllList.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
      if (this.ProcessorList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingProcesor = false;
    }
    else {
      apiname = "api/Agents/GetByDisplayName?name=" + '&agentid=0&type=Processor&filterflag=false';

      await this.service.getapiurl(apiname).subscribe({
        next: async data => {
          this.ProcessorList = data as any[];
          this.ProcessorList.map(obj => { obj.name = obj.displayname, obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}` });
          if(this.ProcessorList.length>0){
            this.ProcessorList=this.service.transformAlphabetically(this.ProcessorList,'displayname');
          }
          this.ProcessorAllList = this.ProcessorList;
          this.selectedprocessagentid = '';
          this.isLoadingProcesor = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingProcesor = false;
          this.notfounddata = true;
        }
      });
    }
  }

  selectedtargetagentEvent(item) //target agent selected Event
  {
    this.selectedtargetaccountid = item.LocationGUID;
    this.selectedtargetaccountName = item.locationName;

  }

  selectedProcessEvent(item) //Processor selected Event
  {
    this.selectedprocessagentid = item.id;
    this.selectedprocessdisplayname = item.displayname;

  }

  isUUID(uuid) {
    let s;

    s = uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
  }

  fromdate: any;
  todate: any;
  displayedColumns: string[] = ['messagetype', 'messageid', 'transactionid', 'createddate', 'options'];
  dataSource = this.messageList;
  totalmessagecount: number = 0;
  SearchMessages() {

    this.messageErrorList = [];
    this.messagestageList = [];
    this.tablediv = false;
    this.errorCode = "";

    if (this.selectedrouteId == '' && this.reactiveFormroute.get('routename').value != '' && this.reactiveFormroute.get('routename').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormroute.get('routename').value);
      if (returnvalue == true) {
        this.selectedrouteId = this.reactiveFormroute.get('routename').value;
      }
      else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return;
      }
    }

    if (this.selectedsourceaccountid == '' && this.reactiveFormsource.get('name').value != '' && this.reactiveFormsource.get('name').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormsource.get('name').value);
      if (returnvalue == true) {
        this.selectedsourceaccountid = this.reactiveFormsource.get('name').value;
      }
      else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return;
      }
    }

    if (this.selectedtargetaccountid == '' && this.reactiveFormtarget.get('namet').value != '' && this.reactiveFormtarget.get('namet').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormtarget.get('namet').value);
      if (returnvalue == true) {
        this.selectedtargetaccountid = this.reactiveFormtarget.get('namet').value;
      }
      else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return;
      }
    }

    if (this.selectedprocessagentid == '' && this.reactiveFormprocess.get('namep').value != '' && this.reactiveFormprocess.get('namep').value != null) {
      let returnvalue = this.isUUID(this.reactiveFormprocess.get('namep').value);
      if (returnvalue == true) {
        this.selectedprocessagentid = this.reactiveFormprocess.get('namep').value;
      }
      else {
        this.toastr.error("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return;
      }
    }
    if(this.fromdate=='' && this.todate=='')
    {
      this.fromdate=undefined;
      this.todate=undefined;
    }

    if ((this.fromdate && !this.todate) || (!this.fromdate && this.todate) || (this.fromdate=='' && this.todate=='')) {
      this.toastr.error("Select From date & To date ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    if (this.fromdate > this.todate) {
      this.toastr.error("From date should be  equal  or  less than To date ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }
    let entitykeyvalue=''
    if(this.msgfieldlist.length>0)
    {
      entitykeyvalue= this.msgfieldlist.reduce((acc,current)=>{
        acc= acc+current.msgvalue+','
        return acc
             },'');
      entitykeyvalue=entitykeyvalue.slice(0, -1);
    }
    else if(this.msgfieldctrl.value!='' && this.msgfieldctrl.value!=null){
      this.toastr.warning("Invalid Value in Message Field", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }
    var searchdata = {
      "routedefinitionid": this.selectedrouteId,
      "sourceentityid": this.selectedsourceaccountid,
      "destinationentityid": this.selectedtargetaccountid,
      "messagetype": this.messagetype == 'select' ? '' : this.messagetype,
      "processoragentid": this.selectedprocessagentid,
      "messageid": this.reactiveFormmessageid.get('namemessageid').value,
      "transactionid": this.reactiveFortransactionid.get("nametransactionid").value,
      "entitykeyvalue": entitykeyvalue,
      "filtermessagestage": this.filtermsgtaggel == true ? "ALL" : "HOLD",
      "status": 'failed',
      "archivedbcheck": this.archivedbcheck,
      "FromDate": this.fromdate == undefined ? '' : this.fromdate + ' 00:00:00',
      "ToDate": this.todate == undefined ? '' : this.todate + ' 23:59:59',
      "entityid":this.selectedEntityguid,
      "parentmessageid":this.reactiveFormparentmessageid.get('parentmsgid').value
    }

    var apiname = 'api/HubMessage/GetFailedHubMessages';
    this.ngxUiLoaderService.start();
    this.service.searchpostapi(apiname, searchdata).then(response => {

      if (response['status'] && response['statusText']) {
        this.ngxUiLoaderService.stop();
        this.arhivecheckbackup = false;
        this.tablediv = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      }
      else {
        this.ngxUiLoaderService.stop();
        // this.arhivecheckbackup = searchdata.archivedbcheck;
        this.messageErrorList = response;

        // this.messagestageList = response.hubmessagestage as any[];
        // let messagesublist = [];
        // messagesublist = this.messagestageList;
        // this.messagestageList = [];
        if (this.messageErrorList.length > 0) {
          this.messageErrorList.forEach((ele) => {
            ele.selector = new SelectionModel<any>(true, []);
          });
          let messagesublist = [];
          this.messageErrorList[0].hubmessagedata.forEach(data => {
            this.messageErrorList[0].hubmessagestage.forEach(ele => {
              if (ele.stagedisplay != '' && ele.messagereferenceid === data.id && ele.stagedisplay != 'A') {
                messagesublist.push(ele);
              }
            })
          });
          this.messageErrorList[0].hubmessagestage = messagesublist;
        }
        else {
          this.tablediv = false;
          this.toastr.error("No results found!!", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      }
    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    })

  }



  InputData: Object;
  OutputData: Object;

  OutputJson: string;
  OutputJsonResponsebody: string;
  fileidValue: string;
  filecontents: any;
  InputDataJson: any[];
  transId: string;
  hash: string;
  senderid: string;
  filecheck: boolean = false;
  displaystage: string = '';
  fileidslist: any = [];
  stageid: string = '';
  inputmediatypedisplay: boolean = true;
  outputmediatypedisplay: boolean = true;
  messageuniqueid:number=0;
  openstagereprocess(content, dataitem, messageidcurrent, sync) {

    if ((dataitem.stagedisplay == 'T' || dataitem.stagedisplay == 'D' || dataitem.stagedisplay == 'V') && (sync == "false" || sync == "0")) {
      this.reprocessbtn = true;
    }
    else {
      this.reprocessbtn = false;
    }

    this.stageid = dataitem.id;
    this.displaystage = dataitem.stagename;
    this.displaymessageid = messageidcurrent;
    this.ngxUiLoaderService.start();
    var apiname = "api/HubMessage/GetMessageLogDataById?messagelogid=" + dataitem.id + "&archiveflag=" + this.archivedbcheck;
    this.service.getapiurl(apiname).subscribe({
      next: async data => {
        this.messageuniqueid=data['id'];
        try {
          this.InputData = JSON.parse(data['stageinput']);
        } catch (e) {
          this.InputData = null;
        }

        try {
          this.OutputData = JSON.parse(data['outputdata']);
        } catch (e) {
          this.OutputData = null;
        }


        this.filecontents = null;

        if ((this.OutputData !== null) && this.OutputData['responsebody']) {
          this.fileidarray = [];
          this.isContainsMultiplefileid = false;
          var fileobj = ""
          if (this.OutputData['responsebody'].includes('fileid')) {
            fileobj = JSON.parse(this.OutputData['responsebody']);
          }

          if (fileobj != "" && Array.isArray(JSON.parse(this.OutputData['responsebody']))) {
            if (this.OutputData['responsebody'].length > 0) {
              this.fileOutputcheck = true;
              this.isContainsMultiplefileid = true;
              this.fileidarray = JSON.parse(this.OutputData["responsebody"].toString());
            }
          }
          else if (this.OutputData['responsebody'].includes('fileid')) {
            this.OutputJsonResponsebody = JSON.parse(this.OutputData['responsebody']);
            this.fileidOutputValue = this.OutputJsonResponsebody['fileid'];
            if (this.fileidOutputValue != null) {
              this.fileOutputcheck = true;
            }

          }
          else {
            this.fileOutputcheck = false;
          }
        }
        else if (this.OutputData !== null && this.OutputData['fileid']) {
          this.fileidOutputValue = this.OutputData['fileid'];
          this.fileOutputcheck = true;
        }
        else {
          this.fileOutputcheck = false;
        }

        if (this.InputData != null) {
          this.transId = this.InputData['transactionid'];
          this.hash = this.InputData['hash'];
          this.senderid = this.InputData['senderagentid'];

          this.fileidInputValue = this.InputData["fileid"];
          if (this.fileidInputValue != null) {
            this.fileInputcheck = true;
          }
        }
        else {
          this.fileInputcheck = false;
        }

        if (this.InputData == null && this.OutputData == null) {
          this.ngxUiLoaderService.stop();
          this.toastr.warning("There is no input and response for " + dataitem.stagename + " stage ", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          if (dataitem.stagedisplay == 'H' && Number(data["releaseflag"]) < 1) {
            this.releasebtn = true;
            this.dataview = true;
          }
          else if (dataitem.stagedisplay == 'H') {
            this.dataview = true;
            this.releasebtn = false;
          }
          else {
            this.releasebtn = false;
            this.dataview = false;
          }
          if (this.fileInputcheck && this.fileOutputcheck && dataitem.stagedisplay == 'H') {
            this.fileidslist = [];
            //     this.fileidInputValue="MjAyMS8xMC82L3NhbXBsZXBkZjEucGRmXzFmZjEyYjU0LTdlNzAtNGI4MC04ODgzLTVjYWZlMDVhOTdmMA==";
            //   this.fileidOutputValue="MjAyMS8xMC82L3NhbXBsZXBkZjMucGRmXzQ0ODZmZmY4LTMyYTYtNGI0Ni1hZTJlLTgwMzNjMmE4N2Q1NQ=="
            this.fileidslist.push(this.fileidInputValue);
            this.fileidslist.push(this.fileidOutputValue);
            let createdby= JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
            let response = await this.service.getapi("api/Routes/GetFileDataMulti?fileID=" + this.fileidslist+"&contextId="+this.messageuniqueid+"&createdBy="+createdby);
            if (response['status'] && response['statusText']) {
              this.ngxUiLoaderService.stop();
              this.toastr.error('Failure to download file', "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
            }
            else {
              this.ngxUiLoaderService.stop();
              var res = response as any[];
              res.forEach(element => {
                if (element["fileformat"] == "pdf") {
                  this.ngxUiLoaderService.stop();
                  if (element["fileid"] == this.fileidInputValue) {
                    this.inputfilecontent = this._base64ToArrayBuffer(element["filecontent"]);
                    this.inputmediatypedisplay = false;
                  }
                  else {
                    this.outputfilecontent = this._base64ToArrayBuffer(element["filecontent"]);
                    this.outputmediatypedisplay = false;
                  }
                }
                else {
                  var myblob = new Blob([element["filecontent"]], { type: 'text/plain' });
                  const outurl = URL.createObjectURL(myblob);
                  fetch(outurl)
                    .then(res => res.text())
                    .then(datatext => {
                      this.ngxUiLoaderService.stop();
                      if (element["fileid"] == this.fileidInputValue) {
                        this.inputfilecontent = datatext;
                        this.inputmediatypedisplay = true;
                      }
                      else {
                        this.outputfilecontent = datatext;
                        this.outputmediatypedisplay = true;
                      }
                    })
                }
              });
              this.modalService.open(content, { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false }).result.then((result) => { });
            }
          }
          else {
            this.ngxUiLoaderService.stop();
            this.modalService.open(content, { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false }).result.then((result) => { });
          }
        }

      },
      error: error => {
        this.ngxUiLoaderService.stop();

      }
    });
  }


  closemessageiotpopup(c) {
    this.editorOptions1.readOnly = true;
    this.messageiotreprocess = false;
    this.backupinputdata = "";
    c('close modal');
  }
  closestagepopup(c) {
    c('close modal');
  }


  async reprocesstage() {
    this.ngxUiLoaderService.start();
    var postinput = {
      "objStageContent": this.InputData,
      "modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
      "stageid": this.stageid
    }

    let response = await this.service.reprocessStageapi("api/HubMessage/StageReprocess", postinput)
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Failed to perform stage reprocess", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      this.onTabChanged('', this.messageErrorList.findIndex(x => x.errorcode == this.errorCode))
      this.ngxUiLoaderService.stop();
      this.toastr.success("Stage reprocess performed successfully", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.autoclosetemplate();
    }
  }


  autoclosetemplate() {
    setTimeout(() => {
      this.modalService.dismissAll();
    }, 3000);
  }

  copyToClip(event: any, message: string) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
  }

  GetMessageTypes() {
    var apiname = "api/Lookup/GetMessageType?lookuptype=messagetype";
    this.service.getapiurl(apiname).subscribe({
      next: data => {
        this.messageTypeList = data as any[];
      },
    });
  }

  messageStatusList: any = ["failed"];
  // GetMessageStatus() {
  //   var apiname = "api/Lookup/GetMessageType?lookuptype=messagestatus";
  //   this.service.getapiurl(apiname).subscribe({
  //     next: data => {
  //       this.messageStatusList = data as any[];
  //     },
  //   });
  // }



  reset() {
    this.msgfieldInput.nativeElement.value=null
    this.msgfieldctrl.setValue('');
    this.msgfieldlist=[];
    this.reactiveFormroute.reset();
    this.reactiveFormsource.reset();
    this.reactiveFormroute.reset();
    this.reactiveFormtarget.reset();
    this.reactiveFormprocess.reset();
    this.selectedrouteId = "";
    this.selectedrouteDisplayName = '';
    this.messagetype = 'select';
    this.selectedsourceaccountid = '';
    this.stageid = '';
    this.selectedsourceaccountName = '';
    this.selectedtargetaccountid = '';
    this.selectedtargetaccountName = '';
    this.selectedprocessagentid = '';
    this.selectedprocessdisplayname = '';
    this.reactiveFormmessageid.reset();
    this.messageErrorList = [];
    this.tablediv = false;
    this.reactiveForentityfield.reset();
    this.reactiveFortransactionid.reset();
    this.filtermsgtaggel = true;
    this.archivedbcheck = false;
    this.errorCode = "";
    this.ProcessorList = this.ProcessorAllList;
    this.reactiveFormprocess.setValue({ "namep": '' });
    this.messageTypeList = this.messageTypeAllList;
    this.reactiveFormmsgtype.setValue({ "msgname": '' });
    this.fromdate = null;
    this.todate = null;
    this.reactiveFormprocess.patchValue(this.ProcessorList);
    this.reactiveFormmsgtype.patchValue(this.messageTypeList);
    this.EntityNameList=[];
    this.selectedEntityguid='';
    this.selectedEntityName='';
    this.reactiveFormentity.reset();
    this.showSourceTarget=true;
    this.reactiveFormparentmessageid.reset();
  }

  sourcearray: any[]; destinationarray: any[]; processorarray: any[];

  getRouteDetails() {

    if (this.selectedrouteId != '') {
      var url = "api/Routes/GetRouteList?id=" + this.selectedrouteId;
      this.ngxUiLoaderService.start();
      this.service.getapiurl(url).subscribe({
        next: data => {
          this.ngxUiLoaderService.stop();
          if (this.selectedrouteId != "") {
            this.selectedrouteDisplayName = data['displayname'];
            this.reactiveFormroute.patchValue({
              routename: this.selectedrouteDisplayName
            });
          }


          if (data['SENAME'] != '') {
            this.selectedsourceaccountid = data['SEGUID'];
            this.selectedsourceaccountName = data['SENAME'];

            this.reactiveFormsource.patchValue({
              name: this.selectedsourceaccountName
            });
          }

          if (data['DENAME'] != '') {
            this.selectedtargetaccountid = data['DEGUID'];
            this.selectedtargetaccountName = data['DENAME'];
            this.reactiveFormtarget.patchValue({
              namet: this.selectedtargetaccountName
            });
          }

          if (data['PANAME'] != '') {
            this.selectedprocessagentid = data['PAID'];
            this.selectedprocessdisplayname = data['PANAME'];
            this.reactiveFormprocess.patchValue({
              namep: this.selectedprocessdisplayname
            });

          }
          this.messagetype = data['messagetype'];

        },
        error: error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error('Unable to fetch Pipeline details with ID:' + this.selectedrouteId, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      });
    }

  }
  fileOutputcheck: boolean = false;

  downloadfile(fileId, iscontainsmultiplefileid) {
    this.ngxUiLoaderService.start();
    if (iscontainsmultiplefileid && this.fileidarray.length > 0) {
      for (var i = 0; i < this.fileidarray.length; i++) {
        this.downloadcontent(this.fileidarray[i].fileid)
      }
    }
    else {
      this.downloadcontent(fileId);
    }
    this.ngxUiLoaderService.stop();
  }
  downloadcontent(fileid) {
    var apiname = "api/Routes/GetFileData?fileID=" + fileid+"&contextId="+this.messageuniqueid+"&createdby="+JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];

    (this.service.getapiurlcontent(apiname)).subscribe({
      next: data123 => {
        this.ngxUiLoaderService.stop();
        if (data123["statusmessage"] != "") {
          var fileDetails = JSON.parse(data123["statusmessage"]);
          let filename = "";
          if (fileDetails.hasOwnProperty("filename")) {
            filename = fileDetails.filename;
          }
          if (filename == "") {
            filename = atob(fileid);
          }

          if (typeof (fileDetails) == "object" && fileDetails.hasOwnProperty("contenttype")) {
            let contentType = fileDetails.contenttype;

            switch (contentType) {
              case "application/xml": filename += ".xml";
                break;
              case "application/json": filename += ".js";
                break;
              case "application/pdf": filename += ".pdf";
                break;
              default: filename += ".txt";
                break;
            }
          }
          if (filename != "" && filename.indexOf('.') == -1) {
            filename += '.txt';
          }
          var xmlDocument;
          try {
            xmlDocument = (new DOMParser()).parseFromString(data123.responsebody, 'text/xml');
          }
          catch {
            xmlDocument = "";
          }
          if (xmlDocument) {
            data123.responsebody = beautify(data123.responsebody);
          }
          const file = new window.Blob([data123.responsebody], { type: 'application/octet-stream' });
          const downloadAncher = document.createElement("a");
          const fileURL = URL.createObjectURL(file);
          downloadAncher.href = fileURL;
          downloadAncher.download = filename;
          downloadAncher.click();

        }
      },
      error: error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Failure to download file', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    });
  }

  MessageTraceData = <any>[];
  displaymessageid: string = '';
  GetTraceData(mymodalstagelogs, transactionid, traceid, messageid) {
    this.displaymessageid = messageid;
    this.ngxUiLoaderService.start();
    var url = "api/HubMessage/GetTraceData?txnid=" + transactionid + "&traceid=" + traceid;
    this.service.getapiurl(url).subscribe({
      next: data => {
        if (data.length > 0) {
          this.MessageTraceData = data as any;
          if (mymodalstagelogs != '') {
            this.modalService.open(mymodalstagelogs, { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false }).result.then((result) => { });
          }
          else {
            this.downloadFile(this.MessageTraceData, this.MessageTraceData[0]["traceid"]);
          }
        } else {
          this.toastr.warning('There are no logs for this Message ID :' + " " + this.displaymessageid, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        this.ngxUiLoaderService.stop();
      },
      error: error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Failed to download logs', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    });



  }



  ConvertToCSV(objArray, headerList) {

    let items = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let separator = ',';
    const columns = Object.keys(items[0]).join(separator);
    const body = items.map(item =>
      Object.values(item).join(separator)
    ).join('\n');

    return columns + '\n' + body;
  }
  copyToClips(event: any, message: string) {

    copyToClipboard(event);
    this.toastr.success(message + " " + "Copied", "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    })

  }

  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, ['agentid', 'messageid', 'method', 'routedefinitionid', 'messagetype', 'senderagentid', 'sourceentityid', 'destinationentityid',
      'destinationagentid', 'response', 'createddate', 'fileid', 'invokedurl', 'stage', 'hash', 'response', 'processingparam', 'status', 'processingtime']);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  download() {

    this.ngxUiLoaderService.start();
    this.downloadFile(this.MessageTraceData, this.MessageTraceData[0]["traceid"]);
    this.ngxUiLoaderService.stop();
  }

  reprocessbtn: boolean;
  releasebtn: boolean = false;
  dataview: boolean = false;

  selection = new SelectionModel<any>(true, []);
  checkeddata: any = [];
  messagereprocessdata: any = [];

  transactionid: any = [];
  senderagentid: any = [];

  reprocessSelected(selector, errorcode) {
    if (selector.selected.length > 0) {
      this.checkeddata = selector.selected;
      this.messagereprocessdata = [];

      this.checkeddata.forEach(element => {
        var dataCons = {
          "id":element.id,
          "transactionid": element.transactionid,
          "sourceagentapikey": element.sourceagentapikey,
          "sourceagentid": element.sourceagentid,
          "sourceentityid": element.sourceentityid,
          "destinationentityid": element.destinationentityid,
          "messagetype": element.messagetype,
          "mode": element.mode,
          "messageid": element.messageid,
          "parentmessageid": element.parentmessageid,
          "format": element.format,
          "fileid": element.messagepayloadreference,
          "routedefinitionid": element.routedefinitionid,
          "verifyroute": "true",
          "modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
        }
        this.messagereprocessdata.push(dataCons);
      });

      this.ngxUiLoaderService.start();
      var apiname = "api/HubMessage/BatchMessageReprocess"
      this.service.searchpostapi(apiname, this.messagereprocessdata).then(response => {

        if (response['status'] && response['statusText']) {
          this.ngxUiLoaderService.stop();
          this.toastr.error('Reprocess failed', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          this.ngxUiLoaderService.stop();
          if (response['status'] == "failure") {
            this.toastr.error(response['statusmessage'], "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
          else {
            selector.clear();
            this.onTabChanged('', this.messageErrorList.findIndex(x => x.errorcode == errorcode))
            this.toastr.success('Reprocessed successfully', "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            // this.autoclosetemplate();
          }
        }
      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Something Went Wrong!', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      });
      // this.selection.clear();
      // this.SearchMessages();
    }
  }



  isAllSelected(selector, array) {
    const numSelected = selector.selected.length;
    const numRows = array.length;
    return numSelected === numRows;
  }


  masterToggle(selector, array) {
    this.isAllSelected(selector, array) ?
      selector.clear() :
      array.forEach(row => selector.select(row));
    this.selecteditemscount = selector.selected.length;
  }

  onSelectAll(event) {
    this.isAddedForReprocess = true;
    const checked = event.checked;
    this.messageList.forEach(item => item.selected = checked);
  }

  fileidOutputValue: any;
  fileidInputValue: any;
  fileInputcheck: boolean;
  selecteditemscount: number = 0;
  checkboxLabel(selector, array, row?: any) {

    if (!row) {

      let ui = `${this.isAllSelected(selector, array) ? 'deselect' : 'select'} all`;
      this.selecteditemscount = selector.selected.length;
      return ui;
    }
    let ui = `${selector.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    this.selecteditemscount = selector.selected.length;
    return ui
  }

  stagestitle(value) {
    return `${value.stagename}
${value.createddate}`
  }
  async releasemessage() {
    this.ngxUiLoaderService.start();
    let response = await this.service.getapi("api/HubMessage/ReleaseHoldMessage?messageid=" + this.displaymessageid)
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Unable to release the Message", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    }
    else {
      this.autoclosetemplate();
      this.SearchMessages();
      this.ngxUiLoaderService.stopAll();
      this.toastr.success("Message released successfully", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

    }
  }


  outputfilecontent: any;
  inputfilecontent: any;
  mediatypedisplay: boolean = true;
  formatType: string = "plaintext";

  editorOptions: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }

  };
  editor: MonacoStandaloneCodeEditor;

  editorInit(data, editor: MonacoStandaloneCodeEditor, type) {
    this.editor = editor;
    let formatType;
    editor.onDidChangeCursorPosition(() => {
      if (this.IsValidXML(data)) {
        formatType = "xml";
      }
      else if (this.service.IsJsonString(data)) {
        formatType = "json";
      }
      else {
        formatType = "hl7";
      }
      if (type == "input") {
        this.editorOptions1 = { ...this.editorOptions1, language: formatType }
      }
      else {
        this.editorOptions = { ...this.editorOptions, language: formatType }
      }
    });
  }

  editorOptions1: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };


  _base64ToArrayBuffer(base64) {

    var binary_string = base64.replace(/\\n/g, '');
    binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }


  filtermsgtaggel: boolean = true;

  editPipeline(item) {
    sessionStorage.setItem('pipelineid', JSON.stringify(item.routedefinitionid));
    this.router.navigate(['/home/createpipeline']);
  }
  buildtitle(Obj: any) {
    if (typeof (Obj) == "object" && Obj != null) {
      let name = `(${Obj.Type}):${Obj.LocationName}
      ${Obj.Address1}
      `
      return name;
    }
  }
  formatXml(xml: any) {
    var formatted = "";
    var reg = /(>)(<)(\/*)/g;
    xml = xml.replace(reg, "$1\r\n$2$3");
    var pad = 0;
    xml.split("\r\n").map((node: any, index: number) => {
      var indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad != 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      var padding = "";
      for (var i = 0; i < pad; i++) {
        padding += "  ";
      }

      formatted += padding + node + "\r\n";
      pad += indent;
    });
    return formatted;
  }
  editmessageiot(data) {
    this.messageiotreprocess = true;
    this.backupinputdata = data;
    this.editorOptions1.readOnly = false;
  }
  resetmessageiot() {
    this.messageiotreprocess = false;
    this.message_iot_data["InputData"] = this.backupinputdata;
    this.editorOptions1.readOnly = true;
  }

  reprocessmessageiot(c) {
    var dataCons = {
      "id":this.msgObjforiot.id,
      "transactionid": this.msgObjforiot.transactionid,
      "sourceagentapikey": this.msgObjforiot.sourceagentapikey,
      "sourceagentid": this.msgObjforiot.sourceagentid,
      "sourceentityid": this.msgObjforiot.sourceentityid,
      "destinationentityid": this.msgObjforiot.destinationentityid,
      "messagetype": this.msgObjforiot.messagetype,
      "mode": this.msgObjforiot.mode,
      "messageid": this.msgObjforiot.messageid,
      "parentmessageid": this.msgObjforiot.parentmessageid,
      "format": this.msgObjforiot.format,
      "fileid": this.msgObjforiot.messagepayloadreference,
      "routedefinitionid": this.msgObjforiot.routedefinitionid,
      "verifyroute": "true",
      "filecontent": this.message_iot_data["InputData"],
      "modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    }
    this.ngxUiLoaderService.start();
    var apiname = "api/HubMessage/ReprocessFileContent"
    this.service.postapi(apiname, dataCons).then(response => {
      if (response['status'] && response['statusText']) {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Reprocess failed', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        this.ngxUiLoaderService.stop();
        if (response['status'] == "failure") {
          this.toastr.error(response['statusmessage'], "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          this.SearchMessages();
          this.closemessageiotpopup(c);
          this.toastr.success('Reprocessed successfully', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          // this.autoclosetemplate();
        }
      }
    }), error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error('Reprocess failed', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    };
  }
  onTabChanged($event, index) {
    this.selecteditemscount = 0;
    let clickedIndex;
    if ($event) {
      clickedIndex = $event.index;
    }
    else {
      clickedIndex = index;
    }
    this.errorCode = this.messageErrorList[clickedIndex].errorcode;

    if (clickedIndex > 0 || !$event) {
      this.ngxUiLoaderService.start();
      let obj = {
        "routedefinitionid": this.selectedrouteId,
        "sourceentityid": this.selectedsourceaccountid,
        "destinationentityid": this.selectedtargetaccountid,
        "messagetype": this.selectedmessagetype,
        "processoragentid": this.selectedprocessagentid,
        "messageid": this.reactiveFormmessageid.get('namemessageid').value,
        "transactionid": this.reactiveFortransactionid.get("nametransactionid").value,
        "entitykeyvalue": this.reactiveForentityfield.get('nameentityfieldid').value,
        "filtermessagestage": this.filtermsgtaggel == true ? "ALL" : "HOLD",
        "status": 'failed',
        "archivedbcheck": this.archivedbcheck,
        "ErrorCode": this.messageErrorList[clickedIndex].errorcode,
        "FromDate": this.fromdate == undefined ? '' : this.fromdate + ' 00:00:00',
        "ToDate": this.todate == undefined ? '' : this.todate + ' 23:59:59',
        "parentmessageid":this.reactiveFormparentmessageid.get('parentmsgid').value
      }
      var apiname = 'api/HubMessage/GetFailedHubMessages';
      this.service.searchpostapi(apiname, obj).then(response => {


        if (response['status'] && response['statusText']) {
          this.ngxUiLoaderService.stop();
          this.arhivecheckbackup = false;
          this.tablediv = false;
          this.toastr.error("Something Went Wrong!", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          let messagesublist = [];
          if (response.length > 0) {
            this.messageErrorList[clickedIndex].selector.clear();
            this.messageErrorList[clickedIndex].hubmessagedata = response[0].hubmessagedata;
            this.messageErrorList[clickedIndex].hubmessagestage = response[0].hubmessagestage;
            this.messageErrorList[clickedIndex].hubmessagedata.forEach(data => {
              this.messageErrorList[clickedIndex].hubmessagestage.forEach(ele => {
                if (ele.stagedisplay != '' && ele.messagereferenceid === data.id && ele.stagedisplay != 'A') {
                  messagesublist.push(ele);
                }
              })
            });
            this.messageErrorList[clickedIndex].hubmessagestage = messagesublist;
          }
          if(response[0] !==undefined)
          {
            this.totalmessagecount = response[0].messagecount;
          }

          this.ngxUiLoaderService.stop();
        }
      });
    }
  }

  selectedmessagetype: string = '';
  isLoadingstage: boolean = false;
  selectedmessagetypeevent(item) {
    this.selectedmessagetype = item.displayname;
  }

  Clearemessagetype(event) {
    this.selectedmessagetype = '';
    this.messageTypeList = this.messageTypeAllList;
  }
  messageTypeAllList: any = [];
  async getMessagetypelist(event: any) {

    this.isLoadingstage = true;
    if (event !== 'msgtype') {
      if (event == undefined) {
        this.messageTypeList = this.messageTypeAllList;
      }
      else {
        if( this.messageTypeList.length==0){
          this.messageTypeList = this.messageTypeAllList;
        }
        this.messageTypeList = this.messageTypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageTypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingstage = false;
    }
    else {

      await this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: async data => {
          data.forEach(element => {
            this.messageTypeAllList.push({ displayname: element });
          });
          this.messageTypeAllList.unshift({ displayname: "ALL" });
          // this.messageTypeAllList.push({ displayname: "ALL" });
          this.messageTypeList = this.messageTypeAllList;

          this.isLoadingstage = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingstage = false;
          this.notfounddata = true;
        }
      });
    }

  }
  sortArraybyObjValue(arr, value) {
    return arr.sort((a, b) => {
      return b[value] - a[value];
    })
  }
  exportreport(selector) {
    let csvData;
    let filename = 'data.csv';
    const data = [];
    if (selector != '') {
      if (selector.selected.length > 0) {
        let consdata = this.messageErrorList.filter(c => c.errorcode == this.errorCode)[0].hubmessagedata;
        if (consdata.length > 0) {
          selector.selected.forEach(element => {
            selector.selected.forEach(ele => {
              if (ele.id == element.id) {
                data.push({
                  Messageid: element.messageid,
                  Transactionid: element.transactionid,
                  RoutedefinitionId: element.routedefinitionid,
                  Routename: element.routename,
                  Sourceagentid: element.sourceagentid,
                  Destinationagentid: element.destinationagentid,
                  Sourceentityid: element.sourceentityid,
                  Destinationentityid: element.destinationentityid,
                  MessageType: element.messagetype,
                  Createddate: element.createddate,
                  Mode: element.mode,
                  StageName: this.sortArraybyObjValue(this.messageErrorList.filter(c => c.errorcode == this.errorCode)[0].hubmessagestage
                    .filter(x => x.messagereferenceid == element.id && x.status == 23), 'createddate')[0].stagename
                })
              }
            });
          });
        }
      }
      else {
        this.messageErrorList.filter(c => c.errorcode == this.errorCode)[0].hubmessagedata.forEach(element => {
          data.push({
            Messageid: element.messageid,
            Transactionid: element.transactionid,
            RoutedefinitionId: element.routedefinitionid,
            Routename: element.routename,
            Sourceagentid: element.sourceagentid,
            Destinationagentid: element.destinationagentid,
            Sourceentityid: element.sourceentityid,
            Destinationentityid: element.destinationentityid,
            MessageType: element.messagetype,
            Createddate: element.createddate,
            Mode: element.mode,
            StageName: this.sortArraybyObjValue(this.messageErrorList.filter(c => c.errorcode == this.errorCode)[0].hubmessagestage
              .filter(x => x.messagereferenceid == element.id && x.status == 23), 'createddate')[0].stagename
          })
        });
      }
    }
    else {
      this.messageErrorList.filter(c => c.errorcode == this.errorCode)[0].hubmessagedata.forEach(element => {
        data.push({
          Messageid: element.messageid,
          Transactionid: element.transactionid,
          RoutedefinitionId: element.routedefinitionid,
          Routename: element.routename,
          Sourceagentid: element.sourceagentid,
          Destinationagentid: element.destinationagentid,
          Sourceentityid: element.sourceentityid,
          Destinationentityid: element.destinationentityid,
          MessageType: element.messagetype,
          Createddate: element.createddate,
          Mode: element.mode,
          StageName: this.sortArraybyObjValue(this.messageErrorList.filter(c => c.errorcode == this.errorCode)[0].hubmessagestage
            .filter(x => x.messagereferenceid == element.id && x.status == 23), 'createddate')[0].stagename
        })
      });
    }

    if (data.length > 0) {
      csvData = this.ConvertToCSVmessagelist(
        data,
        ['Messageid', 'Transactionid', 'RoutedefinitionId', 'Routename', 'Sourceagentid', 'Destinationagentid', 'Sourceentityid', 'Destinationentityid', 'MessageType', 'Createddate', 'Mode','Stage']);

      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);

      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename);
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
    else {
      this.toastr.warning("There is no data!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  ConvertToCSVmessagelist(objArray, headerscus) {

    let items = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let separator = ',';
    const columns = Object.keys(items[0]).join(separator);
    const body = items.map(item =>
      Object.values(item).join(separator)
    ).join('\n');
    if (headerscus == '') {
      return columns + '\n' + body;
    }
    else {
      return headerscus + '\n' + body;
    }

  }

  IsValidXML(data){
    let xmlDocument:any;
    let isValid=false;
    try {
      xmlDocument = (new DOMParser()).parseFromString(data, 'text/xml');
      isValid=true;
    }
  catch {
      xmlDocument = "";
      isValid=false;
  }
  return isValid;
  }

  async getEntityNames(event: any) {

    var valuesa = event;
    if (valuesa != '') {

      this.isLoadingentity = true;
      var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valuesa;

      let response = await this.service.getapi(createSearch);
      if (response['status'] && response['statusText']) {
        this.isLoadingentity = false;
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      } else {
        this.EntityNameList = response;
        this.EntityNameList=this.service.transformAlphabetically(this.EntityNameList,'LocationName');
        this.EntityNameList.map(obj => obj.LocationName = `${obj.LocationName}  ${'(' + obj.LocationGUID + ')'}`)
        this.selectedEntityguid = '';
        this.isLoadingentity = false;
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
      }
    }

  }
  selectedEntity(item){
    this.selectedEntityguid = item.LocationGUID;
    this.selectedEntityName = item.LocationName;
  }
  searchEntityCleared(){
    this.isLoadingentity = false;
    this.EntityNameList = [];
    this.selectedEntityguid = '';
    this.notfounddata = false;
  }

  removemsgfieldvalue(tolist: any): void {
    const index = this.msgfieldlist.indexOf(tolist);

    if (index >= 0) {
      this.msgfieldlist.splice(index, 1);
    }
  }
  addmsgvalue(event: MatChipInputEvent): void {
    if(event.value !==''){
      const input = event.input;
      const value = event.value;
      let valuelist=[]
        if ((value || '').trim()) {
          if(value.includes('|')){
            valuelist=value.split('|')
            if(valuelist.length<=5)
            {
              valuelist.forEach(X=>{
                if(X!=''){
                  this.msgfieldlist.push({ msgvalue: X.trim() });
                }
              })
          }else{
            this.toastr.warning("Message Field accepts max 5 values", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
            return;
          }
            this.msgfieldctrl.setValue('')
            this.msgfieldInput.nativeElement.value=null
          }
          else{
            this.msgfieldlist.push({ msgvalue: value.trim() });
            this.msgfieldctrl.setValue('')
            this.msgfieldInput.nativeElement.value=null
          }
        }
    }
  }
}

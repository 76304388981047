import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


declare const copyToClipboard: any;
@Component({
  selector: 'app-auditdetails',
  templateUrl: './auditdetails.component.html',
  styleUrls: ['./auditdetails.component.scss']
})
export class AuditdetailsComponent implements OnInit {
  classObjalertmain: string;
  StatusMessagedisplay: string;
  statusmessageMainflg: boolean;
  auditmodalheight: Number = 400;

  @Input() inputDataInModalComponent: any;
  id: string = '';
  guid: string;
  context: string='';
  constructor(
    private router: Router,
    private service: CommonService,
    private _fb: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService, private activeModal: NgbActiveModal, private toastr: ToastrService) {

  }
  usernamedisplay: string;
  type:string='';
  auditdetaillist: any = [];
  title: string;

  displayedColumns: string[] = ['AuditDate', 'OldValue', 'New Value', 'UserName'];
  resultsLength = 0;
  async ngOnInit() {
    this.id = this.inputDataInModalComponent.id;
    this.title = this.inputDataInModalComponent.title;
    this.guid = this.inputDataInModalComponent.guid;
    this.context= this.inputDataInModalComponent.context;
    this.type=this.inputDataInModalComponent.type;
    await this.getAuditDetails(this.id);
  }
   audit: AuditValue[] = [];
   auditNew: AuditValue[] = [];
  async getAuditDetails(id: any) {
    var apiname = "api/Audit/GetAuditDetails?id=" + id+"&context="+this.context;
    this.ngxUiLoaderService.start();
    this.auditdetaillist = await this.service.getapi(apiname);

    const resultset = this.auditdetaillist as any[];
    if (resultset.length == 0) {
      
      this.ngxUiLoaderService.stop();
    }
    else {
      this.ngxUiLoaderService.stop();
      resultset.forEach(element => {
        if (element.AuditDifference.length > 0) {
          const resultdiff = element.AuditDifference as any[];
          resultdiff.forEach(data => {
           

            if (this.isvalidjson(data.OldValue) && this.isvalidjson(data.NewValue)) {
              let jsonOldValue = JSON.parse(data.OldValue);
              this.getkeyvalue(jsonOldValue,'audit');
              data.OldValue=[];
              data.OldValue = this.audit;
              

              let jsonNewValue = JSON.parse(data.NewValue);
              this.getkeyvalue(jsonNewValue,'auditnew');
              data.NewValue = [];
              data.NewValue = this.auditNew;

            }
            else {
              let auditOld: AuditValue[] = [];
                let auditOldValue: AuditValue = {
                  Key: '',
                  Value: data.OldValue.toString()
                }
                auditOld.push(auditOldValue);
                data.OldValue = auditOld;
              let auditNew: AuditValue[] = [];
              let auditNewValue: AuditValue = {
                Key: '',
                Value:  data.NewValue.toString()
              }
              auditNew.push(auditNewValue);
              data.NewValue = auditNew;
            }
          })
          element.AuditDifference = resultdiff as any[];
        }
      }
      )
      this.auditdetaillist = resultset;
    }
  }
  getkeyvalue(data,type)
  {
    this.auditNew=[];
    this.audit=[];
    if (typeof data === "object") {
      for (let [key, value] of Object.entries(data)) {
         if(value!=null){
        if(typeof value ==="object")
        {
          this.getkeyvalue(value,type);
        }
       }
       if(typeof value ==='string' || value==null)
        {
          let auditValue: AuditValue = {
            Key: [key].toString(),
            Value:[value].toString()
          }
          if(type=='auditnew')
          {
            this.auditNew.push(auditValue);
          }
          else{
            this.audit.push(auditValue);
          }
        }
      }
    } 

  }

  isvalidjson(stringvalue) {
    try {
      let output = JSON.parse(stringvalue);
      return true;

    } catch (error) {
      return false;
    }
  }


  closetpopup() {
    this.activeModal.close();
  }
  copyToClip(event: any, message: any) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " copied", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

}

export class AuditValue {
  Key: string;
  Value: string;
}

import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'filter',
    pure: false
})
export class TripFilterPipe implements PipeTransform {
  transform(items: any[], filter: any): any[] {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter((item: any) => this.applyFilter(item, filter));
  }
  

  applyFilter(trip: any, filter: any): boolean {

    let flg = [];
    for (let field in filter) {
      if (filter[field] && filter[field]!=="" && trip[field]!== null && trip[field]!=="" && trip[field]!==undefined ) {
        if (typeof filter[field] === 'string') {
          try {
            
            if (trip[field].toLowerCase().indexOf(filter[field].toLowerCase()) === -1) {
              flg.push(false);
            }else{
              flg.push(true);
            }
          } catch (error) {
            if (trip[field].indexOf(filter[field]) === -1) {
              flg.push(false);
            }else{
              flg.push(true);
            }
          }
          
        } else if (typeof filter[field] === 'number') {
          if (trip[field] !== filter[field]) {
            flg.push(false);
          }else{
            flg.push(true);
          }
        }
      }
    }
    
    if(flg.length===0) return true;
    let fltr = flg.filter(rval=>rval===true);
    if(fltr.length>0){
      return true;
    }else{
      return false;
    }
  }
}



@Pipe({
  name: 'filters',
  pure: false
})
export class FilterPipe implements PipeTransform {
  // transform(value: any, args?: any): any {
  //     if(!value)return null;
  //     if(!args)return value;
  //     args = args.toLowerCase();
  //     return value.filter(function(item){
  //         return JSON.stringify(item).toLowerCase().includes(args);
  //     });
  // }
  transform(items: any[], filter: any): any[] {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter((item: any) => this.applyFilter(item, filter));
  }
  

  applyFilter(trip: any, filter: any): boolean {

    let flg = [];
    for (let field in filter) {
      if (filter[field] && filter[field]!=="" && trip[field]!== null && trip[field]!=="" && trip[field]!==undefined ) {
        if (typeof filter[field] === 'string') {
          try {
            
            if (trip[field].toLowerCase().indexOf(filter[field].toLowerCase()) === -1) {
              flg.push(false);
            }else{
              flg.push(true);
            }
          } catch (error) {
            if (trip[field].indexOf(filter[field]) === -1) {
              flg.push(false);
            }else{
              flg.push(true);
            }
          }
          
        } else if (typeof filter[field] === 'number') {
          if (trip[field] !== filter[field]) {
            flg.push(false);
          }else{
            flg.push(true);
          }
        }
      }
    }
    
    if(flg.length===0) return true;
    let fltr = flg.filter(rval=>rval===true);
    if(fltr.length>0){
      return true;
    }else{
      return false;
    }
  }
}
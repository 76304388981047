import { ChangeDetectorRef, Component, ElementRef, Optional, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import {FormBuilder,FormControl, Validators,FormGroup} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MatDatepicker } from '@angular/material/datepicker';
import { UserSession } from 'src/app/services/user.session';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { XmlmappingsComponent } from 'src/app/xmlmappings/xmlmappings.component';
import { CommonService } from 'src/app/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TryitoutComponent } from 'src/app/tryitout/tryitout.component';

declare const copyToClipboard: any;
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],

})
export class DashboardComponent {
  separatorKeysCodeslabs: number[] = [ENTER, COMMA];
  xpandStatus:boolean= false;
  Templatearray:any[];
  hubconfig: any[] = [];
  pagerolepermisions: any = [];
  userList: any[] = [];
  selectedOpt: string = "Interface"
  panelColor = new FormControl('All');
  isPagesize: number = 8;
  connDescription: string[] = [];
  screenHeight: number = 0;
  auditinterface: any;
  fieldstoshow:any;
  fromdate: any;
  todate: any;
  today= new Date();
  myFlagForSlideToggle: boolean = true;
  routeconfigflag: boolean = true;
  searchForm: FormGroup = this._formBuilder.group({
    search: '',
  })
  FilterForm: FormGroup = this._formBuilder.group({
    interfacename: [''],
    interfaceGroupId: [''],
    vendor: [''],
    engine: ['All'],
    createdDate: [''],
    goLiveDate: [''],
    mgsType: [''],
    status: [true],
    sourceAgent: [''],
    destinationAgent: [''],
    processorAgentid: [''],
    SourceagentName: [''],
    DestinationAgentName:[''],
    processoragentName:[''],
    SourceEntity : [''],
    DestinationEntity : ['']
  })
  // range = new FormGroup({
  //   start: new FormControl<Date | null>(null),
  //   end: new FormControl<Date | null>(null),
  // });
  Auditarr =[]
    // [
    //   {
    //     "Date": Date.now(),
    //     "ModifiedBy": "Pavan potadar",
    //     "Changes": " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
    //   },
    //   {
    //     "Date": Date.now(),
    //     "ModifiedBy": "Aleem Nasiyathulla",
    //     "Changes": " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
    //   },
    //   {
    //     "Date": Date.now(),
    //     "ModifiedBy": "Aishwarya Narayanan",
    //     "Changes": " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
    //   },
    //   {
    //     "Date": Date.now(),
    //     "ModifiedBy": "Jyothiswaroop",
    //     "Changes": " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
    //   },
    // ]
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  obs!: Observable<any>;
  dataSource = new MatTableDataSource<any[]>([]);
  users$!: Observable<any>;
  descriptionArrayList = [];
  modalHeight: number = 0;
  isSeacrhed: boolean = false;

  InterfaceNameList=<any>[];
  InterfaceGroupId=<any>[];
  Vendor=<any>[];
  MgsType=<any>[];
  SourceAgent=<any>[];
  DestinationAgent=<any>[];
  ProcessorAgentid=<any>[];
  SourceagentName=<any>[];
  DestinationAgentName=<any>[];
  ProcessoragentName=<any>[];
  SourceEntity=<any>[];
  DestinationEntity=<any>[];

  keywordsearch = "displayname";
  keywordsearch1 = "displayname1";
  keywordsearch2 = "displayname2";
  keywordsearch3= "displayname3";
  keywordsearch4 = "displayname4";
  keywordsearch5 = "displayname5";
  keywordsearch6 = "displayname6";
  keywordsearch7 = "displayname7";
  keywordsearch8 = "displayname8";
  keywordsearch9 = "displayname9";
  isLoadingroutes: boolean=false;
  isLoadingroutes1: boolean=false;
  isLoadingroutes2: boolean=false;
  isLoadingroutes3: boolean=false;
  isLoadingroutes4: boolean=false;
  isLoadingroutes5: boolean=false;
  isLoadingroutes6: boolean=false;
  isLoadingroutes7: boolean=false;
  isLoadingroutes8: boolean=false;
  isLoadingroutes9: boolean=false;

  notfounddata: boolean;
  notfounddata1: boolean;
  notfounddata2: boolean;
  notfounddata3: boolean;
  notfounddata4: boolean;
  notfounddata5: boolean;
  notfounddata6: boolean;
  notfounddata7: boolean;
  notfounddata8: boolean;
  notfounddata9: boolean;
  fileUploadFTP: boolean;
  enableAccessionSearch: boolean;



  constructor(public aroute: ActivatedRoute, public ref: ChangeDetectorRef, private _formBuilder: FormBuilder,
     private toastr: ToastrService, private usersession: UserSession,
      private ngxuiloader: NgxUiLoaderService, private router: Router,private ngxUiLoaderService: NgxUiLoaderService,
      private service: CommonService,    private modalService: NgbModal,


      ) {

  }
///////////
  getForm() {
    $("#ModalForm").modal('show').appendTo('body');
  }
  ngOnInit() {


    this.searchForm.controls['search'].valueChanges.pipe(
      // map(event => event),
      debounceTime(1000),
      tap(() => {
        // this.autocompLoading = true;
        // this.ref.markForCheck()
      }),
      distinctUntilChanged(),
      switchMap((value: string): any => {
        if (value !== undefined && value !== null && value !== '') {

          // this.autocompLoading = true;
          this.ref.markForCheck()

          if (this.selectedOpt !== "All") {
            let interfacename = "";
            let vendorname = "";
            let connectName = "0";
            switch (this.selectedOpt) {
              case "Interface":
                interfacename = value;
                vendorname = "0";
                break;
              case "Vendor":
                interfacename = "0";
                vendorname = value;
                break;

              default:
                break;
            }
            this.ngxuiloader.start();

            return this.service.getInterfaceFilters(interfacename, connectName, vendorname)
          }

        } else {
          this.emptytext()
          return of(null);
        }
        // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
      }),
    ).subscribe((data) => {
      this.isSeacrhed = true;
      this.ngxuiloader.stop();
      if(data !== null) {
        this.getsaved(data);
      }
      this.ref.markForCheck()
      if (data) {
        // this.payorList = data;
      }
    }, error => {
      // this.autocompLoading = false;
      console.error()
    }
    );


    this.screenHeight = window.innerHeight - 135;
    this.isPagesize = Math.round(this.screenHeight / 69);
    this.modalHeight = window.innerHeight - 83;

    if (this.usersession.backInterface.length > 0 && this.usersession.clicked == "View") {
      setTimeout(() => {
        this.descriptionArrayList = this.usersession.description;
        this.usersession.searchText['engine'] = "All"
        this.FilterForm.patchValue(this.usersession.searchText)
        this.isSeacrhed = this.usersession.searchText !== "" ? true : false;
        this.getsaved(this.usersession.backInterface);
      })
    } else {
      this.getuser()
      this.getdescriptions()

    }
  }
screenheight(xpandStatus:any)
{
  if(xpandStatus)
  {
    this.screenHeight = window.innerHeight - 135;
    this.isPagesize = Math.round(this.screenHeight / 69);
  }
  else{
    this.screenHeight =window.innerHeight - 135;
    this.isPagesize = Math.round(this.screenHeight / 69);
  }
}
getfilterdata(event: any,column:any) {
  // this.ngxuiloader.start()
  this.isLoadingroutes = false;
  this.isLoadingroutes1 = false;
  this.isLoadingroutes2= false;
  this.isLoadingroutes3 = false;
  this.isLoadingroutes4 = false;
  this.isLoadingroutes5 = false;
  this.isLoadingroutes6 = false;
  this.isLoadingroutes7 = false;
  this.isLoadingroutes8 = false;
  this.isLoadingroutes9 = false;
  this.service.getfilterdata(column,event).subscribe(data => {
    // this.ngxuiloader.stop()

if(column=='Interfacename')
{this.isLoadingroutes = true;
    if (data.length == 0) {
      this.InterfaceNameList =[];
      this.notfounddata = true;
    } else {
      this.notfounddata = false;
      this.isLoadingroutes = false;
      this.InterfaceNameList=[]
      //  data.forEach(element => {
      //   let objs={
      //     displayname:element
      //   }

      // });
      this.InterfaceNameList= data
    }
  }
  else if(column=='InterfaceGroupId')
  {
    this.isLoadingroutes1 = true;
    if (data.length == 0) {
      this.InterfaceGroupId =[];
      this.notfounddata1 = true;
    } else {
      this.notfounddata1 = false;
      this.isLoadingroutes1 = false;
      this.InterfaceGroupId=[]
      this.InterfaceGroupId=data

    }
  }
  else if(column=='Vendor')
  {
    this.isLoadingroutes2 = true;
    if (data.length == 0) {
      this.Vendor =[];
      this.notfounddata2 = true;
    } else {
      this.notfounddata2 = false;
      this.isLoadingroutes2 = true;
      this.Vendor=[]
      this.Vendor = data

    }
  }
  else if(column=='MgsType')
  {
    this.isLoadingroutes3 = true;
    if (data.length == 0) {
      this.MgsType =[];
      this.notfounddata3 = true;
    } else {
      this.notfounddata3 = false;
      this.isLoadingroutes3 = true;
      this.MgsType=[]
      this.MgsType=data
      //  data.forEach(element => {
      //   let objs={
      //     displayname3:element
      //   }

      // });
    }
  }
  else if(column=='SourceAgent')
  {
    this.isLoadingroutes4 = true;
    if (data.length == 0) {
      this.SourceAgent =[];
      this.notfounddata4 = true;
    } else {
      this.notfounddata4 = false;
      this.isLoadingroutes4 = true;
      this.SourceAgent=[];
      this.SourceAgent=data

    }
  }
  else if(column=='DestinationAgent')
  {
    this.isLoadingroutes5 = true;
    if (data.length == 0) {
      this.DestinationAgent =[];
      this.notfounddata5 = true;
    } else {
      this.notfounddata5 = false;
      this.isLoadingroutes5 = true;
      this.DestinationAgent =[]
      this.DestinationAgent=data;
    }
  }
  else if(column=='ProcessorAgentid')
  {
    this.isLoadingroutes6 = true;
    if (data.length == 0) {
      this.ProcessorAgentid =[];
      this.notfounddata6 = true;
    } else {
      this.notfounddata6 = false;
      this.isLoadingroutes6 = true;
      this.ProcessorAgentid=[]
      this.ProcessorAgentid=data
    }
  }
  else if(column=='SourceagentName')
  {
    this.isLoadingroutes7 = true;
    if (data.length == 0) {
      this.SourceagentName =[];
      this.notfounddata7 = true;
    } else {
      this.notfounddata7 = false;
      this.isLoadingroutes7 = true;
      this.SourceagentName=[]
      this.SourceagentName=data
    }
  }
  else if(column=='DestinationAgentName')
  {
    this.isLoadingroutes8 = true;
    if (data.length == 0) {
      this.DestinationAgentName =[];
      this.notfounddata8 = true;
    } else {
      this.notfounddata8 = false;
      this.isLoadingroutes8 = true;
      this.DestinationAgentName=[]
      this.DestinationAgentName=data
    }
  }
  else if(column=='ProcessoragentName')
  {
    this.isLoadingroutes9 = true;
    if (data.length == 0) {
      this.ProcessoragentName =[];
      this.notfounddata9 = true;
    } else {
      this.notfounddata9 = false;
      this.isLoadingroutes9 = true;
      this.ProcessoragentName=[]
      this.ProcessoragentName=data
    }
  }
  }, error => {
    // this.ngxuiloader.stop()
      this.isLoadingroutes = false;
      this.notfounddata = true;
      this.notfounddata1 = true;
      this.notfounddata2 = true;
      this.notfounddata3 = true;
      this.notfounddata4 = true;
      this.notfounddata5 = true;
      this.notfounddata6 = true;
      this.notfounddata7 = true;
      this.notfounddata8 = true;
      this.notfounddata9 = true;
  })
}
selectedInterfaceName(field:any){

}

searchInterfaceCleared(event) {
  this.isLoadingroutes = false;
  this.InterfaceNameList = [];
  this.notfounddata = false;
}
searchInterfaceCleared1(event) {
  this.isLoadingroutes = false;
  this.InterfaceGroupId = [];
  this.notfounddata1 = false;
}
searchInterfaceCleared2(event) {
  this.isLoadingroutes = false;
  this.Vendor = [];
  this.notfounddata2 = false;
}
searchInterfaceCleared3(event) {
  this.isLoadingroutes = false;
  this.MgsType = [];
  this.notfounddata3 = false;
}
searchInterfaceCleared4(event) {
  this.isLoadingroutes = false;
  this.SourceAgent = [];
  this.notfounddata4 = false;
}
searchInterfaceCleared5(event) {
  this.isLoadingroutes = false;
  this.DestinationAgent = [];
  this.notfounddata5 = false;
}
searchInterfaceCleared6(event) {
  this.isLoadingroutes = false;
  this.ProcessorAgentid = [];
  this.notfounddata6 = false;
}
searchInterfaceCleared7(event) {
  this.isLoadingroutes = false;
  this.SourceagentName = [];
  this.notfounddata7 = false;
}
searchInterfaceCleared8(event) {
  this.isLoadingroutes = false;
  this.DestinationAgentName = [];
  this.notfounddata8 = false;
}
searchInterfaceCleared9(event) {
  this.isLoadingroutes = false;
  this.ProcessoragentName = [];
  this.notfounddata9 = false;
}

  copyToClip(event: any, id) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success('Copied To ClipBoard', id, { positionClass: 'toast-bottom-right', timeOut: 4000 });
    }
  }

  showSuccess(id: string) {
    this.toastr.success('Copied To ClipBoard', id, { positionClass: 'toast-bottom-right', timeOut: 4000 });
  }

  SelectVendor(value: string) {
    this.selectedOpt = value;
    if (this.searchForm.value !== "") {
      this.onSubmit();
    }
  }
  onSubmitSearch()
  {

    this.myFlagForSlideToggle = this.FilterForm.value.status;
    this.FilterForm.value.status = this.FilterForm.value.status?"active":"passive"
    this.FilterForm.value.createdDate= this.FilterForm.value.createdDate==undefined?"":this.FilterForm.value.createdDate
    this.FilterForm.value.goLiveDate= this.FilterForm.value.goLiveDate==undefined? "":this.FilterForm.value.goLiveDate
    this.FilterForm.value.engine=this.FilterForm.value.engine=="All"?'':this.FilterForm.value.engine;
    let obj=this.FilterForm.value;

    this.ngxuiloader.start();

    this.service.getSearchFilterData(obj).subscribe(data => {

        this.ngxuiloader.stop();
        this.getsaved(data);


    },error=>{
      this.ngxuiloader.stop();

    })

  }
  onSubmit() {

    this.isSeacrhed = true;
    if (this.selectedOpt !== "All" && this.searchForm.value.search !=="") {
      let interfacename = "";
      let vendorname = "";
      let connectName = "0";
      switch (this.selectedOpt) {
        case "Interface":
          interfacename = this.searchForm.value.search;
          vendorname = "0";
          break;
        case "Vendor":
          interfacename = "0";
          vendorname = this.searchForm.value.search;
          break;

        default:
          break;
      }
      this.ngxuiloader.start();

      this.service.getInterfaceFilters(interfacename, connectName, vendorname).subscribe(data => {
        this.ngxuiloader.stop();
        this.getsaved(data);
      },error=>{
        this.ngxuiloader.stop();
      })
    }
  }

  emptytext() {
    if (this.searchForm.value.search == '' && this.isSeacrhed) {
      this.ngxuiloader.start();
      this.getuser()
      this.isSeacrhed = false;
    }
  }
  getsaved(data: any) {
    if (data?data.length > 0: false) {
      this.userList = data
      this.dataSource = new MatTableDataSource(data);
      if (this.usersession.pageIndexNumber !== 0) {
        this.paginator.pageIndex = this.usersession.pageIndexNumber
      }
      this.dataSource.paginator = this.paginator;
      this.obs = this.dataSource.connect();
      this.usersession.totalLength = this.dataSource.data.length;
      this.usersession.backInterface = [];
      this.service.changeMessageinterface("true")
    }else{
      this.userList =[]
      this.dataSource = new MatTableDataSource(data);
      setTimeout(()=>{
        this.paginator.pageIndex = 0;
        this.dataSource.paginator = this.paginator;
        this.dataSource.data.length=0;
      })

      this.obs = this.dataSource.connect();
    }
  }
  getuser() {
    this.ngxuiloader.start()
    this.service.getInterfaces().subscribe(data => {
      this.ngxuiloader.stop()
      if (data.length > 0) {
        this.getsaved(data)
      }
    }, error => {
      this.ngxuiloader.stop()
    })
  }
  filtersbyvalue(value:any)
  {
    this.fieldstoshow=value;
  }
  getdescriptions() {
    this.ngxuiloader.start()
    this.service.getDescription().subscribe(data => {
      this.ngxuiloader.stop()
      if (data) {
        this.descriptionArrayList = data.ConnectivityTypes;
      }
    }, error => {
      this.ngxuiloader.stop()
    })
  }
  conndesc(check: string) {
    if (this.descriptionArrayList.length > 0) {
      let values = this.descriptionArrayList.find((va: any) => va.TYPENAME === check);
      if (values !== undefined) {
        return ` Connectivity :  ${check}`
        // Description : ${values['Description']}`
      }
      else {
        return `${check}`
      }
    } else {
      return "";
    }
  }
  guidCopy(guid: string) {
    if (guid == "") {
      return "NoGuid";
    }
    else {
      return guid;
    }
  }
  disableGuid(val: Object) {
    if (val) {
      let result = false;
      for (let [key, value] of Object.entries(val)) {
        if (value !== '') {
          return result = false;
        } else {
          result = true;
        }
      }
      return result;
    } else {
      return false;
    }
  }
  getColor(status: string) {
    switch (status) {
      case 'New':
        return 'blue';
      case 'Dev':
        return 'gold';
      case 'Live':
        return 'green';
      case 'Qc':
        return 'brown';
      default:
        return 'gold';
    }
  }
  getLabel(value: any, label: any) {
    return `${value} : ${label} `
  }




  public dataToModal: any;
  getHubConfig(Interfacegroupid,processorid){
    this.ngxuiloader.start()
    // let obj = {
    //   "loginid": "jyothiswaroop.r@starmark.com",
    //   "password": "Star@1234",
    //   "appversion": "0.1",
    //   "appname": "vitalhub",
    //   "ip": "192.0.0.0"
    // }


    this.service.HubMapping(Interfacegroupid,processorid).subscribe(data => {
      this.ngxuiloader.stop()
      if (data){
        this.hubconfig = data
        this.Templatearray = data as any[];

        this.dataToModal = {
          templateid: this.Templatearray["template"],
          templatelist: this.Templatearray["templatelist"],
          MappedXml: this.Templatearray["mappedxml"],
          resultJson: this.Templatearray["resultjson"],
          routeid: this.Templatearray['routeid'],
          processorid: this.Templatearray['processorid'],
          processorapikey: this.Templatearray['processorapikey'],
          routeName: this.Templatearray['displayname'],
          ProcessorName: this.Templatearray['processorname'],
          uniqueid: this.Templatearray['UniqueID'],
          routedetails: this.Templatearray["routeDetails"],
          CoreConfigVersionList: this.Templatearray["CoreConfigVersionList"]
        };
        if (this.Templatearray["mappedxml"] != null) {
          const modalRef = this.modalService.open(XmlmappingsComponent,
            { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
          modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
          modalRef.result.then(() => { });
        }
        else {
          this.modalService.dismissAll();
          this.toastr.error("Template configuration not available", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      }
      }, error => {
      this.ngxuiloader.stop()
    })
  }




  viewData(value: any) {
    // this.store.dispatch(new UserActions.AddUser(this.userList) );
    this.usersession.backInterface = this.userList;
    this.usersession.searchText = this.FilterForm.value;
    this.usersession.description = this.descriptionArrayList;
    this.usersession.pageIndexNumber = this.paginator.pageIndex
    this.usersession.totalLength = this.paginator['_length'];
    this.router.navigate(['/home/view', value])
  }
  auditpopup(obj: any) {
    this.ngxuiloader.start()
    this.auditinterface = obj.InterfaceName;
//     this.service.getAudit(obj.InterfaceIdentifier,obj.Engine,obj.Deployment).subscribe(audit => {
//       this.ngxuiloader.stop()
//       if(audit.length>0){
//         $("#exampleModalRight").modal('show').appendTo('body');
// this.Auditarr = audit
//       }else{
//         this.toastr.warning('No data Found', 'Alert', { positionClass: 'toast-bottom-right', timeOut: 4000 });
//       }
//     }
    this.service.getHubAudit(obj.InterfaceId).subscribe(audit => {
      this.ngxuiloader.stop()
      if(audit.length>0){
        $("#exampleModalRight").modal('show').appendTo('body');
this.Auditarr = audit
      }else{
        this.toastr.warning('No data Found', 'Alert', { positionClass: 'toast-bottom-right', timeOut: 4000 });
      }
    },err => {
      this.ngxuiloader.stop()
    })
  }
  //   })
  // }



  openHUBConfig(dataitem) {
    this.service.getHubView(dataitem.InterfaceGroupId).subscribe(data =>{
  if(data.PAAPIKEY!=='' || dataitem !== ''){

    // if(dataitem !== ''){
    this.ngxUiLoaderService.start();
    var url = "api/GetMappings/Get?routeid=" + dataitem.InterfaceGroupId + "&processorid=" + dataitem.ProcessorAgentid + "&processorapikey=" + data.PAAPIKEY;
    this.service.getapiurl(url).subscribe({
      next: responce => {

        this.Templatearray = responce as any[];
        this.dataToModal = {
          templateid: this.Templatearray["template"],
          templatelist: this.Templatearray["templatelist"],
          MappedXml: this.Templatearray["mappedxml"],
          resultJson: this.Templatearray["resultjson"],
          routeid: dataitem.id,
          processorid: dataitem.PAID,
          processorapikey: dataitem.PAAPIKEY,
          routeName: dataitem.displayname,
          ProcessorName: dataitem.PANAME,
          uniqueid: dataitem.UniqueID,
          routedetails: this.Templatearray["routeDetails"],
          CoreConfigVersionList: this.Templatearray["CoreConfigVersionList"],
          lockXMLmap: dataitem.IsLocked !== undefined && dataitem.IsLocked == "True" ? dataitem.IsLocked : false
        };

        if (this.Templatearray["mappedxml"] != null) {
          const modalRef = this.modalService.open(XmlmappingsComponent,
            { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
          modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
          modalRef.result.then(() => { });
        }
        else {
          this.modalService.dismissAll();
          this.toastr.error("Template configurations not found", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          this.ngxUiLoaderService.stop();
        }
      },
      error: error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Unable to fetch the Mappings", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    });
  }
  else {
    this.toastr.error("Pipeline and Processor are mandatory", "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    });
  }
  }

    )}


    async getFileConfig(routeid: string, ProcessorId: string) {
      var dataPost = {
        agentid: ProcessorId,
        guids: routeid,
      };
      return await this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost);

    }

   opentryitout(dataitem) {
    this.service.getHubView(dataitem.InterfaceGroupId).subscribe(async data =>{
      if(data !== '' || dataitem !== ''){
    if (data.id != '' && data.PAID != '' ) {
      this.ngxUiLoaderService.start();
      var dataPost = {
        agentid:  data.PAID,
        guids: data.id,
      };
       let response = await this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost);
      // var response = await this.getFileConfig(, dataitem.ProcessorAgentid);
      if (response != "") {
        var jsonData = JSON.parse(response[0]["json"]);
        var vaconfigdata = jsonData.processorconfig["vaconfig"];
        var interfacetype = jsonData.processorconfig["interfacetype"];

        if (dataitem.messagetype === "order") {
          this.fileUploadFTP = true;
          this.enableAccessionSearch = false;
        } else if (dataitem.messagetype === "result") {
          this.fileUploadFTP = false;
          this.enableAccessionSearch = true;
        }
        else {
          this.fileUploadFTP = false;
          this.enableAccessionSearch = false;
        }

        this.dataToModal = {
          RouteIdtry: data.id,
          ProcessorIdtry: data.PAID,
          ProcessorDisplayNametry: dataitem.ProcessoragentName,
          RouteDisplayNametry: dataitem.InterfaceName,
          Processorapikey: data.PAAPIKEY,
          Popupflag: true,
          MappedXmlTemp: '',
          isFileUpload: vaconfigdata.isfileupload == 0 ? false : true,
          isfiledownload: vaconfigdata.isfiledownload == 0 ? false : true,
          interfacetype: data.INTERFACETYPE,
          enableAccessionSearch: this.enableAccessionSearch,
          fileUploadFTP: this.fileUploadFTP,
          sourcedeploymentkey: data.SEDEPLOYMENTKEY,
          targetdeploymentkey: data.DEDEPLOYMENTKEY,
          sourceaccounttype: data.sourcetype,
          targetaccounttype: data.targettype,
          seguid: data.SEGUID,
          deguid: data.DEGUID,
          sourceagent: dataitem.SourceAgent
        };



      }
      this.ngxUiLoaderService.stop();
      const modalRef = this.modalService.open(TryitoutComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });

      modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
      modalRef.result.then(() => { });
    }
    else {
      this.toastr.warning("Pipeline and Processor are mandatory", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }



    }
  }
    )}
}





import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import beautify from "xml-beautifier"; 
import { MonacoEditorConstructionOptions, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
import { ActivatedRoute } from '@angular/router';
declare const copyToClipboard: any;
declare var $
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
@Input() messageData;
msgObjforiot: any;
message_iot_data = {};
formatType: string = "plaintext";
stagemodalheighttextarea: Number = window.innerHeight - 183;
messageList =[]
  messageiotreprocess: boolean;
  interfacenmae: any;
  isEngine: any;
  constructor(   private toastr: ToastrService,
    private router :Router,
    private service: CommonService,
    private _fb: FormBuilder,
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private activeModal: NgbActiveModal,
    private actr: ActivatedRoute,
    ) {

  }


ngOnChanges() {
this.messageList = this.messageData
}

closetpopup() {
  this.activeModal.close();
}

ngOnInit() {
this.messageList = this.messageData.data;
this.isEngine = this.messageData.isEngine;
 this.messageData.interfacename
}
editorOptions1: MonacoEditorConstructionOptions = {
  theme: "myCustomTheme",
  language: this.formatType,
  roundedSelection: true,
  autoIndent: "full",
  readOnly: true,
  wordWrap: 'on',
  minimap: {
    enabled: false
  }
};
editorOptions: MonacoEditorConstructionOptions = {
  theme: "myCustomTheme",
  language: this.formatType,
  roundedSelection: true,
  autoIndent: "full",
  readOnly: true,
  wordWrap: 'on',
  minimap: {
    enabled: false
  }

};
editor: MonacoStandaloneCodeEditor;
editorInit(data, editor: MonacoStandaloneCodeEditor, type) {
  this.editor = editor;
  let formatType;
  editor.onDidChangeCursorPosition(() => {
    if (this.IsValidXML(data)) {
      formatType = "xml";
    }
    else if (this.service.IsJsonString(data)) {
      formatType = "json";
    }
    else {
      formatType = "hl7";
    }
    if (type == "input") {
      this.editorOptions1 = { ...this.editorOptions1, language: formatType }
    }
    else {
      this.editorOptions = { ...this.editorOptions, language: formatType }
    }
  });
}
copyToClips(event: any, message: string) {

  copyToClipboard(event);
  this.toastr.success(message + " " + "Copied", "", {
    timeOut: 4000,
    positionClass: 'toast-bottom-right'
  })

}

copyToClip(event: any, message: string) {
  if (event != '') {
    copyToClipboard(event);
    this.toastr.success(message + " " + "Copied", "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    })
  }
}

editPipeline(item) {
  sessionStorage.setItem('pipelineid', JSON.stringify(item.routedefinitionid));
  this.router.navigate(['/home/createpipeline']);
}
IsValidXML(data){
  let xmlDocument:any;
  let isValid=false;
  try {
    xmlDocument = (new DOMParser()).parseFromString(data, 'text/xml');
    isValid=true;
  }
catch {
    xmlDocument = "";
    isValid=false;
}
return isValid;
}

getmessage_iot(value, modalmessageiot) {
  this.ngxUiLoaderService.start();
  this.msgObjforiot = value;
  this.service.message_IOTData(this.msgObjforiot["id"], false).subscribe(data => {
    if (data) {
      this.message_iot_data = data
      this.message_iot_data['title'] = this.msgObjforiot["messageid"]
      if(this.message_iot_data["InputData"]){
        if(this.service.IsJsonString(this.message_iot_data["InputData"])){
          this.message_iot_data["InputData"]=JSON.stringify(JSON.parse(this.message_iot_data["InputData"]),null,2);
        }
        else if(this.IsValidXML(this.message_iot_data["InputData"])){
          this.message_iot_data["InputData"]=beautify(this.message_iot_data["InputData"]);
        }
      }
      if(this.message_iot_data["TransformedData"]){
        if(this.service.IsJsonString(this.message_iot_data["TransformedData"])){
          this.message_iot_data["TransformedData"]=JSON.stringify(JSON.parse(this.message_iot_data["TransformedData"]),null,2);
        }
        else if(this.IsValidXML(this.message_iot_data["TransformedData"])){
          this.message_iot_data["TransformedData"]=beautify(this.message_iot_data["TransformedData"]);
        }
      }
      this.ngxUiLoaderService.stop()
      this.modalService.open(modalmessageiot, { windowClass: "messageiot", backdrop: 'static', keyboard: false }).result.then((result) => { });
    }
  }, error => {
    this.ngxUiLoaderService.stop()
    this.toastr.error(error.statusText, "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    });
  })
}

editmessageiot(data) {
  this.messageiotreprocess = true;
  // this.backupinputdata = data;
  this.editorOptions1.readOnly = false;
}
resetmessageiot() {
  this.messageiotreprocess = false;
  // this.message_iot_data["InputData"] = this.backupinputdata;
  this.editorOptions1.readOnly = true;
}
reprocessmessageiot(c) {
  var dataCons = {
    "id":this.msgObjforiot.id,
    "transactionid": this.msgObjforiot.transactionid,
    "sourceagentapikey": this.msgObjforiot.sourceagentapikey,
    "sourceagentid": this.msgObjforiot.sourceagentid,
    "sourceentityid": this.msgObjforiot.sourceentityid,
    "destinationentityid": this.msgObjforiot.destinationentityid,
    "messagetype": this.msgObjforiot.messagetype,
    "mode": this.msgObjforiot.mode,
    "messageid": this.msgObjforiot.messageid,
    "parentmessageid": this.msgObjforiot.parentmessageid,
    "format": this.msgObjforiot.format,
    "fileid": this.msgObjforiot.messagepayloadreference,
    "routedefinitionid": this.msgObjforiot.routedefinitionid,
    "verifyroute": "true",
    "filecontent": this.message_iot_data["InputData"],
    "modifiedby": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
  }
  this.ngxUiLoaderService.start();
  var apiname = "api/HubMessage/ReprocessFileContent"
  this.service.postapi(apiname, dataCons).then(response => {
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error('Reprocess failed', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      this.ngxUiLoaderService.stop();
      if (response['status'] == "failure") {
        this.toastr.error(response['statusmessage'], "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        // this.SearchMessages();
        this.closemessageiotpopup(c);
        this.toastr.success('Reprocessed successfully', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        // this.autoclosetemplate();
      }
    }
  }), error => {
    this.ngxUiLoaderService.stop();
    this.toastr.error('Reprocess failed', "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    });
  };
}
closemessageiotpopup(c) {
  this.editorOptions1.readOnly = true;
  // this.messageiotreprocess = false;
  // this.backupinputdata = "";
  c('close modal');
}


stagestitle(value) {
  return `${value.stagename}
${value.createddate}`
}

}

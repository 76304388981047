// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sessionTimeout: 1800000,
  HandlerandRuleCreate: {
    HandlerPostJson: {
        handler:"",
        description:"",
        direction:"",
        fileTypes:"",
        isActive:0,
        groupID:"",
        vendorName:"",
        message:"",
        type:"VitalCore",
        system:"Lab External",
        testMode:0,
        localOutboundFolder:"",
        localOutboundBckFolder:"",
        localSourceFolder:"",
        localProcessingFolder:"",
        localFailedFolder:"",
        localSuccessFolder:"",
        localIgnoredFolder:"",
        messageSubtype:"",
        ownerLabOrgID:"",
        deploymentkey:""
    },
    RulePostJson: {
      RuleName: "",
      RuleDesc: "",
      CaseType: "*",
      Service: -1,
      RuleType: 1,
      Status: 1,
      EffectiveDate: "1/19/2021 10:30:09 AM",
      LabId: "",
      CaseId: -1,
      LabOrgId: "",
      OrdFacilityId: "",
      OrdFacOrgId: "",
      PathologistId: -1,
      PathOrgId: -1,
      PathAccountId: -1,
      OrdPhysician: -1,
      RefPhysician: -1,
      EntityName: "Hl7Integration",
      Mode: 102,
      SurgicalCenter: -1,
      parentruleid: 0,
      ExOrdFacilityId: "",
      CaseStatus: "*",
      ExServices: "",
      ExCaseTypes: "",
      PrimaryPGP: -1,
      VaRuleGUID: "",
      ModifiedBy: "",
      ModifiedDate: "",
      CreatedBy: -1,
      CreatedDate: "1/19/2021 10:30:00 AM",
      RuleConfig: "",
      TestMode: 0,
      deploymentkey: ""
    },
    InterfacePostJson: {
      Handler: "",
      MasterGUID: "",
      InterFaceName: "",
      Description: "",
      ShowToCustomer: 0,
      LastUpdatedDate: "",
      LastUpdatedBy: "",
      LabOrgID: "",
      LabAccountID: "",
      OFAccountID: "",
      OFOrgID: "",
      LabType: "",
      InterfaceType: "",
      deploymentkey: ""
    }
  },
  RouteNameSuggest: [
    {
      "name": "prefix",
      "value": ""
    },
    {
      "name": "SE",
      "value": ""
    },
    {
      "name": "DE",
      "value": ""
    },
    {
      "name": "Vendor",
      "value": ""
    },
    {
      "name": "MT",
      "value": ""
    },
    {
      "name": "Direction",
      "value": ""
    }
  ]
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatTreeControl } from "@angular/cdk/tree";
import { SelectionModel } from "@angular/cdk/collections";
import { CdkDragDrop, CdkDragEnd, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { CounterService, FileDatabase, FileFlatNode } from "../services/FileDatabaseService";
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from "@angular/material/tree";
import { FileNode, TryitoutComponent } from "../tryitout/tryitout.component";
import { MatCheckboxChange, MatDialog, MatDialogRef } from "@angular/material";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';
import { v4 as uuidv4 } from "uuid";
import * as xml2js from 'xml2js';
import beautify from "xml-beautifier";
import { MonacoEditorConstructionOptions, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
// import {  DiffResults } from 'ngx-text-diff/lib/ngx-text-diff.model';
export interface DiffContent {
  leftContent: string;
  rightContent: string;
}
export interface DiffResults {
  hasDiff: boolean;
  diffsCount: number;
  rowsWithDiff: {
    leftLineNumber?: number;
    rightLineNumber?: number;
    numDiffs: number;
  }[];
}
declare var $;
@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompareComponent {
isTransformed :boolean = false;
  @ViewChild("fileInput", { static: false }) myFileInput;
  @ViewChild('widgetsContent', { read: ElementRef,static: true }) public widgetsContent: ElementRef<any>;

  editorOptions1: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: "xml",
    roundedSelection: true,
    autoIndent: "full",
    wordWrap: "on",
    minimap:{
      enabled:false
    }
  };
  contentObservable: Subject<DiffContent> = new Subject<DiffContent>();
  contentObservable$: Observable<DiffContent> = this.contentObservable.asObservable();
  left :any;
  right:any;
  compareflag: boolean = true;
  editorOptions = { theme: 'vs-dark', language: 'javascript' };
  modifiedCode: string = '';
  originalCode: string = '';
  screenHeight: number;
  VersionLeft = []
  VersionRight = []
  xmlkey: string;
  LeftVersion: boolean;
  @Input() inputDataInModalComponent: any;
  VersionRightValue: any;
  VersionLeftValue: any;
  isFresh: boolean = false;
  ShowMapp: boolean = true;
  originalCodebackup: string;
  modifiedCodebackup: string;
  fileToUpload: any;
  editor: MonacoStandaloneCodeEditor;
  inputdata: string = "";
  formatType: string;
  inputdataXml = ""
  fileOutput: any;
  routedetails: any;
  versionData = [];
  parentXML: any;
  childXML: any;
  selectcontentflag: any;
  FTPFilesDataLive: any;
  constructor(private activeModal: NgbActiveModal, private database: FileDatabase, private ngxUiLoaderService: NgxUiLoaderService, private toastr: ToastrService, public service: CommonService,) {
  }

  ngOnInit() {
    this.screenHeight = window.innerHeight - 163;
    this.toastr.warning("Scroll to the left to provide input data and transform", "", {
      timeOut: 4000,
      positionClass: "toast-bottom-right"
    });
    this.database.dataChange.subscribe((data) => {
      if (data.length > 0) {
        if (this.xmlkey === "parent") {
          this.valuestring = "";
          this.left = this.createstringParent(data, 0);
          setTimeout(() => {
            this.modifiedCode = this.modifiedCodebackup;
            this.originalCode = this.originalCodebackup;
          })
        } else {
          this.childvalueString = "";
          this.right = this.createstringChild(data);
          this.originalCode = this.originalCodebackup;
          this.modifiedCode = this.modifiedCodebackup;
        }
        this.editorDIffData()
      }
    });
    if (this.inputDataInModalComponent) {
      this.originalCode = "";
      this.modifiedCode = "";
      this.isFresh = true;
      this.getFTPfiles()
      this.versionData = this.inputDataInModalComponent.versionDetails
      this.VersionLeft = JSON.parse(JSON.stringify(this.versionData))
      this.VersionRight = JSON.parse(JSON.stringify(this.versionData))
      this.routedetails = this.inputDataInModalComponent.versionDetails
      if (this.versionData.length == 1) {
        this.ChangeVersion(this.VersionLeft[0].version, "parent");
      }
      if (this.versionData.length > 1) {
        let live = this.VersionLeft.filter(va => va.islive === true)
        if (live.length > 0) {
          this.ChangeVersion(live[0].version, "parent");
        }
        setTimeout(() => {
        })
      }
    }

  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // this.ShowMapp = false;
    this.scrollRight();

  }
  onCompareResults(diffResults: DiffResults) {
    // //console.log('diffResults', diffResults);
  }

  ChangeVersion(eventvalue, value) {
    let objs;
    if (eventvalue.value) {
      objs = eventvalue.value
    } else {
      objs = eventvalue
    }
    this.ngxUiLoaderService.start();
    let uniobj = []
    if (value === "parent") {
      uniobj = this.VersionLeft.filter(va => va.version === objs);
      this.VersionLeftValue = objs;

    } else {
      uniobj = this.VersionRight.filter(va => va.version === objs);
      this.VersionRightValue = objs;
    }
    if (uniobj.length > 0) {
      let obj = {
        xml: uniobj[0].json.processorconfig.xslconfig.mappedxml,
        operation: "add"
      }
      if (value === "parent") {
        this.parentXML = uniobj[0].json.processorconfig.xslconfig.mappedxml;

      } else {
        this.childXML = uniobj[0].json.processorconfig.xslconfig.mappedxml;
      }
      if(this.inputdata !== "" && this.isTransformed){
        this.Transformdata(this.inputdata,value)
      }else{
        this.service.XmlModification(obj).subscribe(async data => {
          if (data) {
            let parsers = new xml2js.Parser({ strict: true, trim: true, pretty: true, attrkey: "attr" });
            parsers.parseString(data.responsebody, (err, results) => {
              if (results) {
                let parseData = JSON.stringify(results);
                this.xmlkey = value;
                this.database.initialize(parseData, this.xmlkey);
                if (this.isFresh) {
                  this.isFresh = false;
                  if (this.VersionRight.length == 1) {
                    this.ChangeVersion(this.VersionRight[0].version, "child");
                  } else {
                    this.ChangeVersion(this.VersionRight[this.VersionRight.length - 1].version, "child");
                  }
                }
                // this.ngxUiLoaderService.stop();
              }
            })
          }
        })
      }
    }
  }

  // ChangeVersionChild(eventvalue, value) {
  //   let objs;
  //   if (eventvalue.value) {
  //     objs = eventvalue.value
  //   } else {
  //     objs = eventvalue
  //   }
  //   this.ngxUiLoaderService.start();
  //   let uniModified = this.VersionRight.filter(va => va.version === objs)
  //   this.VersionRightValue = objs;
  //   if (uniModified.length > 0) {
  //     let obj = {
  //       xml: uniModified[0].json.processorconfig.xslconfig.mappedxml,
  //       operation: "add"
  //     }
  //     this.service.XmlModification(obj).subscribe(async data => {
  //       if (data) {
  //         let parsers = new xml2js.Parser({ strict: true, trim: true, pretty: true, attrkey: "attr" });
  //         parsers.parseString(data.responsebody, (err, results) => {
  //           if (results) {
  //             let parseData = JSON.stringify(results);
  //             this.xmlkey = value;
  //             this.database.initialize(parseData, this.xmlkey);
  //             this.ngxUiLoaderService.stop();
  //           }
  //         })
  //       }
  //     })
  //   }

  // }

  closetpopup() {
    this.activeModal.close();
  }


  editorInit(editor: MonacoStandaloneCodeEditor) {
    this.editor = editor;
    this.inputdata = this.inputdataXml
    if (editor !== undefined) {
      editor.onDidChangeCursorPosition(() => {
        if (this.inputdata) {
          if (this.inputdata.startsWith("<") ) {
            this.formatType = "xml";
          } else if (this.inputdata.startsWith("{") ) {
            this.formatType = "json";
          }
        }
        this.editorOptions1 = {
          ...this.editorOptions1,
          language: this.formatType
        };
      });
    }

  }




  removeUniq(obj) {
    if (typeof (obj) === "object") {
      for (let [key, value] of Object.entries(obj)) {
        if (key.includes("_")) {
          key = key.split("_")[0];
        }
        if (typeof value === "object") {
          this.removeUniq(value)
        }
      }
    }
  }

  valuestring = "";
  createstringParent(value, counter) {
    for (let i = 0; i < value.length; i++) {
      if (value[i].isNode == "Parent") {
        if (value[i].children.length > 0) {
          if (value[i].children[0].isNode == "Child" && value[i].children[0].filename !== "_NodeIdentifier") {
            this.valuestring = this.valuestring + `
             ${value[i].filename}`
          } else {
            this.valuestring = this.valuestring + `
        ${value[i].filename}`
          }
        }
      }
      if (value[i].isNode == "Child") {
        if (value[i].filename !== "_NodeIdentifier") {
          this.valuestring = this.valuestring + `
                ${value[i].filename} =  ${value[i].type}`
        }
      }
      if (value[i].children != undefined) {
        if (value[i].children.length > 0) {
          this.createstringParent(value[i].children, counter)
        }
      }
    }
    return this.valuestring
  }

  childvalueString = ""
  createstringChild(value) {
    for (let i = 0; i < value.length; i++) {
      if (value[i].isNode == "Parent") {
        if (value[i].children.length > 0) {
          if (value[i].children[0].isNode == "Child" && value[i].children[0].filename !== "_NodeIdentifier") {
            this.childvalueString = this.childvalueString + `
             ${value[i].filename}`
          } else {
            this.childvalueString = this.childvalueString + `
        ${value[i].filename}`
          }
        }
      }
      if (value[i].isNode == "Child") {
        if (value[i].filename !== "_NodeIdentifier") {
          this.childvalueString = this.childvalueString + `
                ${value[i].filename} =  ${value[i].type}`
        }
      }
      if (value[i].children != undefined) {
        if (value[i].children.length > 0) {
          this.createstringChild(value[i].children)
        }
      }
    }
    return this.childvalueString
  }
  async addFile(e): Promise<void> {
    if (e.target.files.length === 0) {
      return;
    }
    this.fileToUpload = e.target.files[0];
    let fileContent;
    if (this.fileToUpload) {
      this.ngxUiLoaderService.start();
      const filename = e.target.files[0].name;

      if (filename.endsWith(".xml") ||filename.endsWith(".txt") || filename.endsWith(".json") || filename.endsWith(".hl7") ) {
        fileContent = await this.readFileContent(this.fileToUpload);
        fileContent = fileContent.replace(/\n/g, "  ");
        fileContent = fileContent.replace(/\r/g, "  ");
        this.inputdataXml = fileContent;
        if(filename.endsWith(".xml")){
          this.formatType = "xml";
        }else if(filename.endsWith(".txt")){
          this.formatType = "txt";
        }else if(filename.endsWith(".json")){
          this.formatType = "json";
        }else if(filename.endsWith(".hl7")){
          this.inputdataXml= fileContent.split("  ").join("\n")
          this.formatType = "hl7";
        }
        this.editorOptions1 = {
          theme: "ligth",
          language:   this.formatType ,
          roundedSelection: true,
          autoIndent: "full",
          wordWrap: "on"
        };
        this.ShowMapp = true;
        this.editorInit(this.editor)
        this.ngxUiLoaderService.stop();
        this.myFileInput.nativeElement.value = "";
        let inputElement: HTMLElement = this.myFileInput.nativeElement as HTMLElement;
        inputElement.click();
        this.editorInit(this.editor)
        this.Transformdata(this.inputdata,"")
      } else {
        this.ngxUiLoaderService.stop();
        this.myFileInput.nativeElement.value = "";
        // this.StatusMessagedisplaymapping = "Please select xml type";
        // this.classObjalertmap = "btn alert-danger";
        // this.FadeOutmappingpageMsg();
        this.toastr.warning("Please select a file of type  XML or TXT or JSON", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
      }
    }
  }



  async readFileContent(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!file) {
        resolve("");
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = reader.result.toString();
        resolve(text);
      };
      reader.readAsText(file);
    });
  }

  Transformdata(InputDatacontentvalue,side) {
    this.ngxUiLoaderService.start();
    if (InputDatacontentvalue != "") {
      let inputArray=[];
      inputArray.push(InputDatacontentvalue);
      const jsonpost = {
        "InputData": inputArray,
        "RouteId": this.inputDataInModalComponent != "" ? this.inputDataInModalComponent.RouteIdtry : this.routedetails.RouteIdtry,
        "ProcessorId": this.inputDataInModalComponent != "" ? this.inputDataInModalComponent.ProcessorIdtry : this.routedetails.ProcessorIdtry,
        "ApiKey": this.inputDataInModalComponent != "" ? this.inputDataInModalComponent.Processorapikey : this.routedetails.Processorapikey,
        "IsFileUpload": this.inputDataInModalComponent != "" ? this.inputDataInModalComponent.isFileUpload : this.routedetails.isFileUpload,
        "IsFileDownload": this.inputDataInModalComponent != "" ? this.inputDataInModalComponent.isfiledownload : this.routedetails.isfiledownload,
        "MappedXml": "",
        "Version":side== "parent"?this.VersionLeftValue:this.VersionRightValue
      };
      $('#myTab a[href="#results"]').tab('show')
      if(side === "parent"){
        this.parentData(jsonpost)
      }else if(side === "child"){
      this.ChildData(jsonpost)
      }else{
        this.parentData(jsonpost)
        this.ChildData(jsonpost)
      }
      this.isTransformed = true;
    }
    // this.ngxUiLoaderService.stop()
    this.scrollRight();
  }

  parentData(inputValue) {
    var apiname = "api/TransformXml/Post";
    this.ngxUiLoaderService.start();

    inputValue.MappedXml=this.parentXML;
    this.service.postapi(apiname, inputValue).then(data => {
      if(data && Array.isArray(data)){
          for(var i=0;i<data.length;i++){
            if (data[i].status === "success") {
              // this.ngxUiLoaderService.stop()
              this.toastr.success(this.VersionLeftValue+" Version Transformed Successfully", "", {
                timeOut: 6000,
                positionClass: 'toast-bottom-right'
              })
              var response="";
              if(data[i].responsebody.includes('fileid')){
                response= JSON.parse(data[i].responsebody);
              }
              if(response!=""&& Array.isArray(response)){
                var result=response;
                for(var i=0;i<result.length;i++){
                  this.downloadfile(result[i].fileid,this.VersionLeftValue,"left");
                }
              }
              else if(data[i].responsebody.includes("fileid")){
                this.downloadfile(JSON.parse(data[i].responsebody).fileid,this.VersionLeftValue,"left")
              }
              else{
                if(this.IsValidXML(data[i].responsebody)){

                  this.left =beautify(data[i].responsebody);
                }else{
                   this.left = data[i].responsebody
                }
              }
            this.editorDIffData()
            }else{
            this.ngxUiLoaderService.stop();
            this.toastr.error(this.VersionLeftValue+" Version Transform Failed", "", {
              timeOut: 6000,
              positionClass: 'toast-bottom-right'
            })
            this.left =""
            this.editorDIffData()
            }
          }
      }


    }), error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error(this.VersionLeftValue+" Version Transform Failed", "", {
        timeOut: 6000,
        positionClass: 'toast-bottom-right'
      })
      this.left =""
      this.editorDIffData()
    }
  }

  ChildData(inputValue) {
    var apiname = "api/TransformXml/Post";
    this.ngxUiLoaderService.start();
    inputValue.MappedXml = this.childXML;
    this.service.postapi(apiname, inputValue).then(data => {
      if (data["status"] && data["statusText"]) {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Transform Failed", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
        });
    }
    else if(data && Array.isArray(data)){
        for(var i=0;i<data.length;i++){
          if (data[i].status === "success") {
            this.toastr.success(this.VersionRightValue+" Version Transformed Successfully", "", {
              timeOut: 6000,
              positionClass: 'toast-bottom-right'
            })
            var response="";
            if(data[i].responsebody.includes('fileid')){
              response= JSON.parse(data[i].responsebody);
            }
            if(response!="" && Array.isArray(response)){
              var result=response;
              for(var i=0;i<result.length;i++){
                this.downloadfile(result[i].fileid,this.VersionLeftValue,"right");
              }
            }
            else if(data[i].responsebody.includes("fileid")){
                this.downloadfile(JSON.parse(data[i].responsebody).fileid,this.VersionRightValue,"right")
            }else{
              if(this.IsValidXML(data[i].responsebody)){
                this.right =beautify(data[i].responsebody)
              }else{
              this.right = data[i].responsebody
              }
            }
            // this.ngxUiLoaderService.stop();
            this.editorDIffData()
          }else{
          this.ngxUiLoaderService.stop();
          this.toastr.error(this.VersionRightValue+" Version Transform Failed", "", {
            timeOut: 6000,
            positionClass: 'toast-bottom-right'
          })
          this.right =""
          this.editorDIffData()
          }
        }
      }

    }), error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error(this.VersionRightValue+" Version Transform Failed", "", {
        timeOut: 6000,
        positionClass: 'toast-bottom-right'
      })
      this.right =""
      this.editorDIffData()
    }
    // if (response["status"] && response["statusText"]) {
    //   this.ngxUiLoaderService.stop();
    //   this.toastr.error("Transform Failed", "", {
    //     timeOut: 4000,
    //     positionClass: 'toast-bottom-right'
    //   })
    // } else {

    //
    //   this.ngxUiLoaderService.stop();
    // }
  }
  downloadfile(value,version,pos) {
    var apiname = "api/Routes/GetFileData?fileID=" + value;
    this.ngxUiLoaderService.start();
    this.service.getapiurlcontent(apiname).subscribe(response => {
      if (response) {
        if(response.responsebody !== undefined) {
          if(pos === "left"){
          this.left =  response.responsebody
          }else {
          this.right =  response.responsebody
          }
        }else{
          if(pos === "left"){
            this.left = response
            }else {
            this.right = response
            }
        }
        this.editorDIffData()
      }else{
    this.ngxUiLoaderService.stop();
    this.toastr.error(version+" Version Transform Failed", "", {
      timeOut: 6000,
      positionClass: 'toast-bottom-right'
    })
      }
    }, error=>{
    this.ngxUiLoaderService.stop();
    this.toastr.error(version+" Version Transform Failed", "", {
      timeOut: 6000,
      positionClass: 'toast-bottom-right'
    })
    })
  }
  cancelTransfrom(){
    this.isTransformed = false;
    this.left ="";
    this.right ="";
    this.editorDIffData()
    this.ChangeVersion(this.VersionLeftValue,"parent");
    setTimeout(() =>{
    this.ChangeVersion(  this.VersionRightValue,"child");
    })
  }
  editorDIffData(){
    this.ngxUiLoaderService.start()
    const newContent: DiffContent = {
      leftContent: this.left !== null || this.left !=='' ? this.left : '',
      rightContent: this.right !== null   || this.right !==''? this.right : '',
    };
    this.contentObservable.next(newContent);
    this.ngxUiLoaderService.stop()
  }
  getFTPfiles() {
    this.ngxUiLoaderService.start();
    if (this.inputDataInModalComponent.selectflag) {
      this.selectcontentflag = this.inputDataInModalComponent.selectflag
    }
    var apiname = 'api/Routes/GetListFTPFiles?RouteId=' + this.inputDataInModalComponent.RouteIdtry + '&SourceagentID=' + this.inputDataInModalComponent.sendAgentid;

    this.service.getapiurl(apiname).subscribe({
      next: response => {
        this.FTPFilesDataLive.data = response as any[];
        this.ngxUiLoaderService.stop();
      },
      error: error => {
        this.ngxUiLoaderService.stop();
      }
    });


  }
  scrollRight() {
    setTimeout(() =>{
      this.widgetsContent.nativeElement.scrollLeft = 550;
    })
    }

    formatXml(xml, tab) { // tab = optional indent value, default is tab (\t)
      var formatted = '', indent= '';
      tab = tab || '\t';
      xml.split(/>\s*</).forEach(function(node) {
          if (node.match( /^\/\w/ )) indent = indent.substring(tab.length); // decrease indent by one 'tab'
          formatted += indent + '<' + node + '>\r\n';
          if (node.match( /^<?\w[^>]*[^\/]$/ )) indent += tab;              // increase indent
      });
      return formatted.substring(1, formatted.length-3);
  }
  IsValidXML(data) {
    let xmlDocument: any;
    let isValid = false;
    try {
        xmlDocument = (new DOMParser()).parseFromString(data, 'text/xml');
        isValid = true;
    }
    catch {
        xmlDocument = "";
        isValid = false;
    }
    return isValid;
}
}

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
  FormArray,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { COMMA, ENTER, V } from "@angular/cdk/keycodes";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, of } from "rxjs";
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { NgxUiLoaderService } from "ngx-ui-loader";
import * as XLSX from "xlsx";
import { MatSelect } from "@angular/material/select";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { ActivatedRoute } from "@angular/router";
import { MatCheckbox, MatOption } from "@angular/material";
import { each } from "highcharts";
import { CommonService } from "src/app/services/common.service";
declare var $;

@Component({
  selector: 'app-manage-menus',
  templateUrl: './manage-menus.component.html',
  styleUrls: ['./manage-menus.component.scss']
})
export class ManageMenusComponent implements OnInit {

  constructor(
    private _formBuilder: FormBuilder,
    public ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private commonService: CommonService,
    private ngxuiloader: NgxUiLoaderService,
    private actr: ActivatedRoute
  )
  {
    this.RoleMenuFormGroup.controls.IsActive.valueChanges.pipe(
      debounceTime(1000),
      tap(() => {

      }),
      distinctUntilChanged(),
      switchMap((value: boolean): any => {

        if (!value) {
     this.IsDefaultToggle=false
        } else {
          return of(null);
        }
      })
    )
    .subscribe(
      (data) => {
        this.ngxuiloader.stop();
        if (data !== null) {
        }
        this.ref.markForCheck();
        if (data) {
        }
      },
      (error) => {
        console.error();
      }
    );

    this.RoleMenuFormGroup.controls.IsDefaultMenu.valueChanges.pipe(
      debounceTime(1000),
      tap(() => {

      }),
      distinctUntilChanged(),
      switchMap((value: boolean): any => {

        if (!this.IsActiveToggle) {
     this.IsDefaultToggle=false
        } else {
          return of(null);
        }
      })
    )
    .subscribe(
      (data) => {
        this.ngxuiloader.stop();
        if (data !== null) {
        }
        this.ref.markForCheck();
        if (data) {
        }
      },
      (error) => {
        console.error();
      }
    );
  }
  ngOnInit() {
   this.getrolelist('initial');
  }
  roleslist:any=[]
  RoleMenuFormGroup: FormGroup = this._formBuilder.group({
    MenuName: [""],
    MenuOrder:[""],
    IsActive:[true],
    IsDefaultMenu:[""],
    Actionitems:[""],
    RoleName:[""],
    DisplayName:[""],
    ExistRole:[""]
  });
  @ViewChild("select", { static: false }) select: MatSelect;
  allSelected=false
  ActionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getrolelist(flag:string)
  {
    this.ngxuiloader.start();

    this.commonService.getrole(null,null).subscribe(
      (data) => {
        this.ngxuiloader.stop();
        if (data.length > 0)
        {
          this.roleslist=data;
          for (let i = 0; i < this.roleslist.length; i++) {
            this.roleslist[i]['active']=false

          }
if(flag=='initial')
{
  this.displaymenurole(this.roleslist[0].RoleId,0)
}
        }
      })
  }
  rolemenulist:any[]=[]
  rolebg:boolean
  mlist:any[]=[]


  roleid:number

  displaymenurole(id:number,bg)
  {
    this.roleid=id;
this.rolebg=bg;
    for (let i = 0; i <  this.roleslist.length; i++) {
      this.roleslist[i]['active']=false;

    }
    this.roleslist[bg].active=true;
    this.ngxuiloader.start();

    this.commonService.getRoleandMenu(id).subscribe(
      (data) => {

        this.ngxuiloader.stop();
        if (data)
        {
            this.rolemenulist=JSON.parse(JSON.stringify([data])) ;
            this.rolemenulist[0].MenuList=this.rolemenulist[0].MenuList.filter(V => V.MenuGroup==='Main');
            for(let i=0;i<this.rolemenulist[0].MenuList.length;i++)
            {
              this.rolemenulist[0].MenuList[i].submenulist=data.MenuList.filter(V => V.ParentMenuId == this.rolemenulist[0].MenuList[i].MenuId && V.MenuGroup === 'SubMain');
            }
            for(let i=0;i<this.rolemenulist[0].MenuList.length;i++)
            {
              this.rolemenulist[0].MenuList[i].actionlist=data.MenuList.filter(x => x.ParentMenuId == this.rolemenulist[0].MenuList[i].MenuId && x.MenuGroup === "Main.ActionItem");
              for(let j=0;j<this.rolemenulist[0].MenuList[i].submenulist.length;j++)
              {
                this.rolemenulist[0].MenuList[i].submenulist[j].actionlist=data.MenuList.filter(x => x.ParentMenuId == this.rolemenulist[0].MenuList[i].submenulist[j].MenuId && x.MenuGroup === "Main.ActionItem");
              }
            }

            if(this.rolemenulist[0].MenuList.length>0){
              for (let i = 0; i < this.rolemenulist[0].MenuList.length; i++) {
                if(this.rolemenulist[0].MenuList[i].submenulist.length>0){
                  let isdefault = this.rolemenulist[0].MenuList[i].submenulist.filter(va =>va.IsDefaultMenu == 1)
                  if(isdefault.length){
                    this.rolemenulist[0].MenuList[i]['IsDefaultMenu'] =1
                  }else{
                    this.rolemenulist[0].MenuList[i]['IsDefaultMenu'] = 0
                  }
                }
              }
            }
        }
      });
      this.commonService.getMenus(id).subscribe(
        (data) => {

          this.ngxuiloader.stop();
          if (data)
          {
            this.mlist=data;
          }
        })
  }

  id:number
  optionclick(mid:number)
  {
this.id=mid
  }

  IsActiveToggle:boolean=true
  IsDefaultToggle:boolean
 postrolemenu()
 {

  let obj=
  {
    RoleId:this.roleid,
    MenuOrder:this.rolemenulist[0].MenuList.length+1,
    IsActive:this.RoleMenuFormGroup.get("IsActive").value?1:0,
    MenuId:this.id,
    IsDefaultMenu:this.RoleMenuFormGroup.get("IsDefaultMenu").value?1:0
  }

  this.commonService.postRoleMenu(obj).subscribe(
    (data) => {

      this.ngxuiloader.stop();
      if (data.status=="Success")
      {
        // if(data.statusmessage.includes('1'))
        // {
        //   this.toastr.warning('Already Existed', 'Menu', { positionClass: 'toast-bottom-right', timeOut: 4000 });
        //  this.modalhide();
        // }
        // else{
        this.toastr.success('Menu Assigned To Role Successfully', 'Success', { positionClass: 'toast-bottom-right', timeOut: 4000 });
        this.modalhide();
        this.displaymenurole(this.roleid,this.rolebg);
        // }
      }
      else{
        this.toastr.error('Failed to Assign Menu', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
        this.modalhide();
      }
    })
 }
actionlist:any[]=[]

 getactions(mid:object)
 {
  this.actionlist =[];
  this.commonService.GetActionbyId(mid['MenuId']).subscribe(
    (data) => {
      this.ngxuiloader.stop();
      if (data.length>0)
      {
        this.actionlist=data;
        this.actionlist.map(ma =>ma['isactive'] = false)
      }
      else
      {
        this.toastr.warning('NOT FOUND', 'Actions', { positionClass: 'toast-bottom-right', timeOut: 4000 });
      }
        if(mid['actionlist'].length>0)
        {
          for (let i = 0; i < mid['actionlist'].length; i++) {
        let exist=  this.actionlist.filter(V=> V.MenuId=== mid['actionlist'][i]['MenuId'] && mid['actionlist'][i]['IsActive'] ===1 ) ;
            if(exist.length>0){
              exist[0].isactive= true;
            }
          }
        }
    });
 }

ActionControl = new FormControl("", Validators.required);


 isedit=false
 isexp=false
 emenuid:number
 menuord:number
 emenuname:unknown
 popeditmenu(obj,exp:boolean)
 {
  this.isedit=true
  this.isexp=exp
  this.emenuid=obj.MenuId
  this.emenuname=obj.MenuDisplayName
  this.menuord=obj.MenuOrder
  this.IsDefaultToggle=false
  this.RoleMenuFormGroup.patchValue({
    MenuName: obj.MenuDisplayName,
    MenuOrder:obj.MenuOrder,
    IsActive:obj.IsActive?true:false,
    IsDefaultMenu:obj.IsDefaultMenu?true:false,
  })
  $("#RMModalForm").appendTo("body").modal("show");
 }
 updatemenu()
 {
  let obj={
    MenuId:this.emenuid,
    RoleId:this.roleid,
    MenuOrder:this.menuord,
    IsActive:this.RoleMenuFormGroup.get("IsActive").value?1:0,
    IsDefaultMenu:this.RoleMenuFormGroup.get("IsDefaultMenu").value?1:0
  }

  this.commonService.postRoleMenu(obj).subscribe(
    (data) => {

      this.ngxuiloader.stop();
      if (data.status=="Success")
      {
        if(data.statusmessage.includes('-100'))
        {
          this.toastr.warning('Cannot be Updated', 'Default Menu', { positionClass: 'toast-bottom-right', timeOut: 4000 });
         this.modalhide();
        }
        else{
        this.toastr.success('Updated Successfully', 'Success', { positionClass: 'toast-bottom-right', timeOut: 4000 });
       this.modalhide();
        this.displaymenurole(this.roleid,this.rolebg);
        }
      }
      else{
          this.toastr.error('Update Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
          this.modalhide();
      }
    })
 }
modalhide()
{
  $("#RMModalForm").modal("hide");
  this.resetForm();
}


 Action(id:number,event:MatCheckbox)
 {

  let obj={
    IsActive:event.checked,
    MenuId:id,
    Roleid:this.roleid,
  }
  this.commonService.ManageActions(obj).subscribe(
    (data) => {

      this.ngxuiloader.stop();
      if (data.status=="Success")
      {
        if(event.checked)
        {
          this.displaymenurole(this.roleid,this.rolebg);
          this.toastr.success('Action Added Successfully', 'Success', { positionClass: 'toast-bottom-right', timeOut: 4000 });
        }
        else{
          this.displaymenurole(this.roleid,this.rolebg);
          this.toastr.success('Action Removed Successfully', 'Success', { positionClass: 'toast-bottom-right', timeOut: 4000 });
        }
      }
      else{
        this.toastr.error('Adding Action Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
      }

    })
 }
copyroleid:number
 selectrole(id:number)
 {
  this.copyroleid=id;
 }

 Addrole()
 {
  let obj={
     EXISTING_ROLEId :this.copyroleid?this.copyroleid:0,
     DisplayName:this.RoleMenuFormGroup.get('DisplayName').value,
     RoleName :this.RoleMenuFormGroup.get('RoleName').value,
     Createdby :JSON.parse(sessionStorage.getItem("sessionObjectlogin")).userdata["userid"]
  }
  this.commonService.AddRoles(obj).subscribe(
    (data) => {

      this.ngxuiloader.stop();
      if (data.status=="Success")
      {
       this.toastr.success('Role Created Successfully', 'Success', { positionClass: 'toast-bottom-right', timeOut: 4000 });
       this.modalhide();
       this.getrolelist('add');
      }
      else{
        this.toastr.error('Role Creation Failed', 'Failed', { positionClass: 'toast-bottom-right', timeOut: 4000 });
        this.modalhide();
      }
    })
 }
 isrole=false
 roleformpop()
 {
this.isrole=true;
  $("#RMModalForm").appendTo("body").modal("show");
 }

  newformpop() {
    this.isedit=false;
    this.isrole=false;
    this.IsActiveToggle=true
    $("#RMModalForm").appendTo("body").modal("show");
  }
  resetForm()
  {
    this.isedit=false;
    this.isrole=false
    this.isexp=false
    this.RoleMenuFormGroup.reset();
  }
}

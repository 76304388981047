import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { Resolve,ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { Observable, of as observableOf } from "rxjs";
import { environment } from 'src/environments/environment';
import { AppConfigService } from "../configurations/app-config.service";

@Injectable({
    providedIn:'root'
})

export class RouteService implements Resolve<any> {

    header = { withCredentials: true }
      // BaseUrl = environment.ApiURL
      // BaseUrlVA = 'https://interfaceadmin-qc.vitalaxis.net/api/'
      protected BaseUrlVA: string = AppConfigService.settings.WebApiURL;
  constructor(private http:HttpClient)
  {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{

           return this.http.get<any>(`${this.BaseUrlVA}api/InterfaceAllDetails/GetIntDetails?InterfaceID=${route.params['id']}`,this.header);
      }
  }

export class RouteServiceform implements Resolve<any> {
    // BaseUrl = environment.ApiURL
    headers = { withCredentials: true }
    protected BaseUrl: string = AppConfigService.settings.WebApiURL;
constructor(private http:HttpClient)
{
}
resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{
         return this.http.get<any>(`${this.BaseUrl}VAInterfaces/InterfaceFormView?userinput=${route.params['formid']}`,this.headers);
    }
}


// export class RouteServiceImport implements Resolve<any> {
//     // BaseUrl = environment.ApiURL
//     headers = { withCredentials: true }
//     protected BaseUrl: string = AppConfigService.settings.WebApiURL;
// constructor(private http:HttpClient)
// {
// }
// resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{
//          return this.http.get<any>(`${this.BaseUrl}PatientHistoryImport/CheckFTPGetFilesV2?projectID=${route.params['id']}`,this.headers);
//     }
// }

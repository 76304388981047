import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
// import { contentHeaders } from './headers';
// import 'rxjs/add/operator/map';
// import 'rxjs/operator/delay';
// import 'rxjs/operator/mergeMap';
// import 'rxjs/operator/switchMap';
import 'rxjs';
import { environment } from 'src/environments/environment';


// import { NullAstVisitor } from '@angular/compiler';

@Injectable({
  providedIn: 'root',
})
export class UserSession {
  remotePosts = new BehaviorSubject([]);
  _router;
  public backInterface: any[] = []
  public searchText = {}
  public clicked = ""
  public description: any;
  public pageIndexNumber =0;
  public totalLength =0;


  constructor(public http: HttpClient, router: Router) {
    this._router = router;
  }

}

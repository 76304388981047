import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';

import { AppConfigService } from '../configurations/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class W700servicesService {
  config: NgxUiLoaderConfig;
  protected apiURL: string = AppConfigService.settings.WebApiURL;

  constructor(private httpService: HttpClient, private ngxUiLoaderService: NgxUiLoaderService) {
    this.config = this.ngxUiLoaderService.getDefaultConfig();
  }


  getapiurl(urlterm,deploymentkey) {

  const headers = { 'Conn': deploymentkey};
  headers["Content-Type"] = "application/json";
  headers["Access-Control-Allow-Origin"] = "*";
  headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
    return  this.httpService.get<any>(this.apiURL + urlterm,{headers})
  }

  getapi(urlterm,deploymentkey) {
    const headers = { 'Conn': deploymentkey};
    headers["Content-Type"] = "application/json";
    headers["Access-Control-Allow-Origin"] = "*";
    headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
    var listOfNames = this.httpService.get(this.apiURL + urlterm,{headers})
      .pipe(
        debounceTime(500),  // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map(
          (data: any) => {
            return (
              data as any[]
            );
          }
        ));

    return listOfNames;
  }

  async postapi(urlterm: any, createSearch: any,deploymentkey) {
    const headers = { 'Conn': deploymentkey};
    headers["Content-Type"] = "application/json";
  headers["Access-Control-Allow-Origin"] = "*";
  headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
    return await this.httpService.post<any>(this.apiURL + urlterm, createSearch,{headers})
      .pipe().toPromise()
      .then(result => result)
      .catch(err => err);
  }
  async getapicompendium(urlterm,createSearch:any,deploymentkey){
    const headers = { 'Conn': deploymentkey};
    headers["Content-Type"] = "application/json";
    headers["Access-Control-Allow-Origin"] = "*";
    headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
    return this.httpService.post<any>(this.apiURL + urlterm, createSearch,{headers:headers,observe:'response',reportProgress: true,responseType:'blob' as 'json'})
    .pipe().toPromise()
      .then(result => result)
      .catch(err => err);;
  }

  getCompendiumExcel(urlterm,deploymentkey){
    const headers = { 'Conn': deploymentkey};
    headers["Content-Type"] = "application/json";
    headers["Access-Control-Allow-Origin"] = "*";
    headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
     return this.httpService.get((this.apiURL+urlterm), { headers:headers, observe: 'response' });
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-caseaudit",
  templateUrl: "./caseaudit.component.html",
  styleUrls: ["./caseaudit.component.scss"],
})
export class CaseauditComponent implements OnInit {
  @Input() caseData: any;
  auditListData = [];
  auditData: any = [];
  Addtion_Details: string = "";
  Action_Details: string = "";
  auditDetials: any;
  constructor(private service: CommonService) {}
  ngOnChanges() {
    ;
    if (
      this.caseData.CaseGUID !== null &&
      this.caseData.CaseGUID !== undefined
    ) {
      let obj = {
        // CaseGUID: "412A5121-4E6C-4D5E-9BF1-21C5BBE7EA70",
        CaseGUID: this.caseData.CaseGUID,
        DeploymentKey:"TitanSprint" + this.caseData.deploymentKey.toUpperCase(),
      };
      this.service.caseAuditDetails(obj).subscribe({
        next: (data) => {
          if (data.responsebody !== "") {
            this.auditData = JSON.parse(data.responsebody);
            if (this.auditData !== undefined) {
              let audit = [];
              this.auditData = JSON.parse(this.auditData);
              this.auditData.map((va) => {
                (va["date"] = va.AuditDate.split(" ")[0]),
                  (va["time"] = va.AuditDate.split(" ")[1]);
              });
              // if(Array.isArray(this.auditData)){
              //   for (let i = 0; i < this.auditData.length; i++) {
              //     audit.push(this.auditData[i]['Entry']);
              //   }
              // }else{
              //   audit= [this.auditData['Entry']]
              // }
              let dataAudit = this.groupBy(this.auditData, "date");
              if (dataAudit !== undefined) {
                let Audt = [];
                for (let [key, value] of Object.entries(dataAudit)) {
                  // let count=0
                  // if(Array.isArray(value)){
                  //  value.forEach((va,index)=>{
                  //     va['id'] = count++;
                  //   })
                  // }
                  let obj = {
                    LabelDate: key,
                    Auditlist: value,
                  };
                  Audt.push(obj);
                }
                this.auditListData = Audt;
              }

              console.log(this.auditListData);
            }
          }
        },
        error: (error) => {
          console.error();
        },
      });
    }
  }
  ngOnInit() {}
  SelectAudit(item) {
    this.auditDetials = item;
    (this.Addtion_Details = item.AdditionalDetails),
      (this.Action_Details = item.ActionDetails);
  }

  groupBy(objectArray: any, property: any) {
    return objectArray.reduce(function (
      acc: { [x: string]: any[] },
      obj: { [x: string]: any }
    ) {
      if (obj !== undefined) {
        var key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }
    },
    {});
  }
}

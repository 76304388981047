import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { LoginService } from '../login/login.service'
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  UserName: string;
  errorMessage: string;
  useridtoken: string;
  screenHeigth: number;

  constructor(private http: HttpClient,
    private loginService: LoginService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxUiLoaderService: NgxUiLoaderService,
    private service: CommonService,
    public dialog: MatDialog
  ) {

  }

  ngOnInit() {
    if (this.route.snapshot.queryParams.redirectauthurl) {
      this.Authorize();
    }
    this.screenHeigth = window.innerHeight - 80
  }

  // async CheckUserToken() {
  //   var UserName = '';
  //   this.LoginService.getUserInfo().subscribe({
  //     next: (data) => {

  //       UserName = data.email;
  //       if (UserName.includes('starmarkit.com')) {
  //         UserName = UserName.replace('starmarkit.com', 'starmark.com')
  //       }
  //       var sessionObject1 = {
  //         userdata: {
  //           FormattedDisplayName: UserName,
  //           userid: UserName
  //         }
  //       }

  //       this.ngxUiLoaderService.stopAll()
  //     },
  //     error: error => {
  //       this.router.navigate(['/login']);
  //       this.ngxUiLoaderService.stopAll();
  //     }
  //   });

  // }

  // Authorize() {
  // this.LoginService.Login();

  // };


  Authorize() {
    this.errorMessage = '';

    if (this.UserName != '' && this.UserName != 'undefined' && this.UserName != undefined && this.UserName.length > 3) {
      var LoginUserName = this.UserName;
      if (this.UserName.includes('starmarkit.com')) {
        this.UserName = this.UserName.replace('starmarkit.com', 'starmark.com')
      }
      else if (this.UserName.includes('starmark.com')) {
        LoginUserName = this.UserName.replace('starmark.com', 'starmarkit.com')
      }

      this.ngxUiLoaderService.start()
      this.loginService.getUserInfo().subscribe(data => {
        this.ngxUiLoaderService.stopAll();
        if (data == null) {
          var sessionObject = {
            userdata: {
              FormattedDisplayName: this.UserName,
              userid: this.UserName
            }
          }
          sessionStorage.setItem('sessionObjectlogin', JSON.stringify(sessionObject));
          this.loginService.Login(this.UserName);
        }
        else if (data.email !== LoginUserName) {
          // this.toastr.warning("Different user already logined in same broswer logout and try", "", {
          //   timeOut: 4000,
          //   positionClass: 'toast-bottom-right'
          // });

          const message = `Another user is already logged in to this application. Proceeding further will disembark the current user session. Would you like to continue?`;
          const dialogData = new ConfirmDialogModel("Confirm", message, 'confirm', '');
          const dialogRef = this.dialog.open(ConfirmDialogComponent, { width: "500px", data: dialogData, disableClose: true });

            dialogRef.afterClosed().subscribe(async dialogResult => {
            if (dialogResult === false) {
              return;
            }
            else  if (dialogResult === true) {
              this.loginService.logout();
            }
          });

          // //this.router.navigate(['/home']);
          //setTimeout(() => { this.loginService.logout(); }, 3000);
        }
        else if (data.email == LoginUserName) {
          this.toastr.warning("User session available ", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

          this.router.navigate(['/home']);
        }
      }, error => {
        this.ngxUiLoaderService.stopAll();

      })

    }
    else {
      // this.ngxUiLoaderService.stop();
      this.errorMessage = 'Please fill the required feilds ';
    }

  };


}

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { Location } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
  FormArray,
  AbstractControl
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { COMMA, ENTER, V, X } from "@angular/cdk/keycodes";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, of } from "rxjs";
// import {
//   setInterval,
//   clearInterval
// } from 'timers';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { NgxUiLoaderService } from "ngx-ui-loader";
import * as XLSX from "xlsx";
import { MatSelect } from "@angular/material/select";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MatCheckbox, MatOption } from "@angular/material";
import { SearchPipelineComponent } from "src/app/search-pipeline/search-pipeline.component";
import { CommonService } from "src/app/services/common.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SavevendorComponent } from "src/app/vendor/Savevendor/savevendor.component";
import { debug, error } from "console";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImportService } from "src/app/services/importResolver.service";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Routeversion1Component } from "src/app/routeversion1/routeversion1.component";

const { parse, stringify } = require('flatted/cjs');
declare var $;
declare const copyToClipboard: any;
@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})

export class MailComponent implements OnInit {
  @Input() inputDataInModalComponent: any;
  base64:any
  isftpmail:boolean
  selectedIndexTab=0;
  filenamedisplay:string='result3.pdf'
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['link'],
      ['unlink'],
      ['insertImage'],
      ['insertVideo'],
      ['insertHorizontalRule'],
      ['removeFormat'],
    ]
  };
    _file: SafeUrl;
    html=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,</span></span></span></span></p>
    <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Please find the attached file for FTP Information. </span></span></span></span></p>
    <p>&nbsp;</p>
    <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:10.0000pt"><span style="font-family:Calibri">Note: Attached file is password protected for the security purposes. Please refer to the following email to locate the password.</span></span></span></span></p>
    <p>&nbsp;</p>
    <p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>Thanks &amp; Regards</strong></span></span></strong></span></span></p>
    <p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>VitalAxis</strong></span></span></strong></span></span></p>`
  
    @ViewChild("fileInput", { static: false }) myFileInput;
    @ViewChild("select", { static: false }) select: MatSelect;
    @ViewChild('tomailInput', { static: true }) tomailInput: ElementRef<HTMLInputElement>;
    @ViewChild('CcInput', { static: true }) CcInput: ElementRef<HTMLInputElement>;

  constructor(
    private _location: Location,
    public sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder,
    public ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private commonService: CommonService,
    private ngxuiloader: NgxUiLoaderService,
    private actr: ActivatedRoute,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private router: Router,
    private improute: ImportService
  ){ 

  }

  ngOnInit() {
    
    this.inputDataInModalComponent
    this.Subject.setValue(this.inputDataInModalComponent['Subject']);
    this.mainSubject.setValue(this.inputDataInModalComponent['mainSubject'])
    this.responsebody=this.inputDataInModalComponent['responsebody']
    this.base64=this.inputDataInModalComponent['base64']
    this.filenamedisplay=this.inputDataInModalComponent['filenamedisplay']
    this.key=this.inputDataInModalComponent['key']
    this.htmlContent=this.inputDataInModalComponent['htmlContent']
    this.authhtml=this.inputDataInModalComponent['authhtml']
    this.attachmentobj=this.inputDataInModalComponent['attachmentobj']
    this.allCcid=this.getUniqueListBy(this.inputDataInModalComponent['Cclist'],'Ccid');
    this.alltoaddressid=this.getUniqueListBy(this.inputDataInModalComponent['toaddresslist'],'toAddressid');
    this.filteredtoaddressid = this.toaddressctrl.valueChanges.pipe(
      startWith(null),
      map((id: string | null) => (id ? this._filtertoaddress(id) : this.alltoaddressid.slice())),
    );

    this.filteredCcid = this.Cctrl.valueChanges.pipe(
      startWith(null),
      map((id: string | null) => (id ? this._filterCc(id) : this.allCcid.slice())),
    );
  }
  getUniqueListBy(arr, key) {
    
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  Subject = new FormControl('');
  /////////////////////////////////////////tomatchip
  addOnBlur = true
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  authhtml:any
  attachmentobj
  toaddresslist: any = [];
  fromaddress: string = 'noreply@vitalaxis.com'

  toaddressctrl = new FormControl('');
  filteredtoaddressid: Observable<string[]>;
  alltoaddressid: any = []


  addtoAddress(event: MatChipInputEvent): void {
    if(event.value !==''){
      const input = event.input;
      const value = event.value;
  
      if (this.isEmail(value.trim()) ) {
        if ((value || '').trim()) {
          this.toaddresslist.push({ toAddressid: value.trim() });
          this.toaddressctrl.setValue('')
          this.tomailInput.nativeElement.value=null
        }
      }
      else{
        this.toastr.warning("Invalid EmailId", event.value, {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      this.toaddressctrl.setValue(null);
    }
  }

  isEmail(search: string) {
    let serchfind;
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    serchfind = regexp.test(search);
    return serchfind
  }

  removetoAddress(tolist: any): void {
    const index = this.toaddresslist.indexOf(tolist);

    if (index >= 0) {
      this.toaddresslist.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    let obj = {
      toAddressid: event.option.viewValue
    }
    let isccid=this.Cclist.find(X=>X.Ccid===event.option.viewValue);
    if(this.toaddresslist.length>0){
      let isexist=this.toaddresslist.filter(V=>V.toAddressid===event.option.viewValue)
      if(isexist.length==0 &&(isccid==undefined || isccid==null))
    {
      this.toaddresslist.push(obj);
    }
  }
  else{
    if(isccid==undefined || isccid==null)
    {
      this.toaddresslist.push(obj);
    }
  }
    if (this.tomailInput !== undefined) {
      this.tomailInput.nativeElement.value = '';
    }
    this.toaddressctrl.setValue(null);
    this.toaddressctrl.reset(); // resets the control
this.toaddressctrl.markAsPristine();
this.toaddressctrl.markAsUntouched();
  }

  private _filtertoaddress(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.alltoaddressid.filter(V => V.toAddressid.toLowerCase().includes(filterValue));

  }
  /////////////////////////////////////////Ccmatchip


  // Cclist: any = [
  //   { Ccid: 'aleem.nasiyathulla@starmarkit.com' }
  // ];
  Cclist: any = [
  ];
  Cctrl = new FormControl('');
  filteredCcid: Observable<string[]>;
  // allCcid: any = [
  //   { Ccid: 'aishwarya.narayanan@starmarkit.com' },
  //   { Ccid: 'jyothiswaroop.r@starmarkit.com' },
  //   { Ccid: 'manu.talwar@starmarkit.com' },
  //   { Ccid: 'vidyashree.garagad@starmarkit.com' },
  //   { Ccid: 'kalpana.thammineni@starmarkit.com' },
  //   { Ccid: 'nithin.tl@starmarkit.com' }
  // ];
  allCcid: any = [
  ];



  addCc(event: MatChipInputEvent): void {
    if(event.value !==''){
    const input = event.input;
    const value = event.value;
    if (this.isEmail(value.trim())) {
      if ((value || '').trim()) {
        this.Cclist.push({ Ccid: value.trim() });
        this.Cctrl.setValue('')
        this.CcInput.nativeElement.value=null
      }
    }
    else{
      this.toastr.warning("Invalid EmailId", event.value, {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    this.Cctrl.setValue(null);
  }
  }

  removeCc(tolist: any): void {
    const index = this.Cclist.indexOf(tolist);

    if (index >= 0) {
      this.Cclist.splice(index, 1);
    }
  }

  selectedCc(event: MatAutocompleteSelectedEvent): void {
    let obj = {
      Ccid: event.option.viewValue
    }
    let istoid=this.toaddresslist.find(V=>V.toAddressid===event.option.viewValue);
    if(this.Cclist.length>0){
      let isexist=this.Cclist.filter(V=>V.Ccid===event.option.viewValue)
      if(isexist.length==0 &&(istoid==undefined || istoid==null))
    {
      this.Cclist.push(obj);
    }
  }else{
    if(istoid==undefined || istoid==null)
    {
      this.Cclist.push(obj);
    }
  }
    // this.alltoaddressid =this.alltoaddressid.filter(X=>X.toAddressid.toLowerCase()!=event.option.viewValue.toLowerCase());
    // this.allCcid=this.allCcid.filter(X=>X.Ccid.toLowerCase()!=event.option.viewValue.toLowerCase());
    if (this.CcInput !== undefined) {
      this.CcInput.nativeElement.value = '';
    }
    this.Cctrl.setValue(null);
    if(this.CcInput){
      this.CcInput.nativeElement.blur();
    }
  }

  private _filterCc(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCcid.filter(V => V.Ccid.toLowerCase().includes(filterValue));
    // return this.allCcid.filter(V => V.emailid.toLowerCase().includes(filterValue));

  }
  downloadfile()
  {
const source = this.base64;
      const link = document.createElement("a");
      link.href = source;
      link.download = this.filenamedisplay;
      link.click();
  }
  htmlContent:any

sourcedetails:any
isimport:boolean

base64content:any
key:any
mainSubject = new FormControl('');
responsebody:any

sendmail()
{
  this.ngxuiloader.start();
  let obj
  let toaddressobj={}
  let ccobj={}
  this.toaddresslist.forEach(X=>{
    toaddressobj[X.toAddressid]=X.toAddressid.split('@')[0]
  })
  this.Cclist.forEach(X=>{
    ccobj[X.Ccid]=X.Ccid.split('@')[0]
  })
     obj={
      "to":toaddressobj,
      "cc":ccobj,
      "from_email":this.fromaddress,
      "attachment":this.inputDataInModalComponent.attachmentobj,
      "html":this.htmlContent,
      "subject":this.mainSubject.value
     }

    this.commonService.mailapi(obj).subscribe((data) =>{
      if(data['status'].toLowerCase()=='success')
      {
        this.sendpassword(toaddressobj)
      }
      else{
        // $("#duplicateModalRight").modal('hide');
        this.ngxuiloader.stop();
          this.toastr.error("Failed",'Sending mail',{
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
    })
      }
    },error=>{
      this.ngxuiloader.stop();
    })
  }

  sendpassword(toaddressobj){
  let authmail={
      "to":toaddressobj,
      "from_email":this.fromaddress,
      "subject":this.Subject.value,
    "html":this.authhtml
  }
  this.commonService.mailapi(authmail).subscribe((data) =>{
      if(data['status'].toLowerCase()=='success')
    {
        this.ngxuiloader.stop();
         this.closeEmail();
        this.toastr.success('Mail sent successfully','',{
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
    }
      else{
        this.toastr.error("Error occured",'',{
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
  })
        this.ngxuiloader.stop();
}
    },error=>{
      this.ngxuiloader.stop();
    })
  }
  closeEmail(){
    this.activeModal.close();
    this.toaddresslist=[];
    this.Cclist=[];
    this.tomailInput.nativeElement.value = '';
    this.CcInput.nativeElement.value = '';
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import { W700servicesService } from '../services/w700services.service';

@Component({
  selector: 'app-jobproperties',
  templateUrl: './jobproperties.component.html',
  styleUrls: ['./jobproperties.component.scss']
})
export class JobpropertiesComponent implements OnInit {

  @Input() inputDataInModalComponent: any;
  screenHeight: number;

  constructor(private activeModal: NgbActiveModal,    private toastr: ToastrService,
    private w700service: W700servicesService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private service: CommonService
  ) { }
  Name: string = '';
  deploymentkey: string = '';
  JobName: string = '';
  JobId: number = 0;
  pagerolepermisions: any = [];
  Operationname: string = '';

  async ngOnInit() {
    this.screenHeight  = window.innerHeight -163
    if (await this.service.checksession() == true) {
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'w700').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });
      if (this.pagerolepermisions != undefined) {
        this.JobId = this.inputDataInModalComponent.dataitem.JobId;
        this.JobName = this.inputDataInModalComponent.dataitem.JobName;
        this.deploymentkey = this.inputDataInModalComponent.deploymentkey;
        this.Name = this.inputDataInModalComponent.dataitem.Name;
        this.Operationname = this.inputDataInModalComponent.dataitem.OperationValue;
        await this.getjobproperties(this.inputDataInModalComponent.dataitem.ClassName, this.JobId);
      }
    }
  }


  closetpopup() {
    this.activeModal.close();
  }

  jobpropertieslist: any = [];
  async getjobproperties(operationname, jobid) {

    var apiname = "api/W700Jobs/GetW700Properties?jobid=" + jobid + "&name=" + operationname ;
    this.w700service.getapi(apiname,this.deploymentkey).subscribe({
      next: data => {
        const arraydata = data as any[];
        const resw700OperProperties = data["w700OperProperties"];
        if (resw700OperProperties == null) {
          this.toastr.warning('Properties are not mapped to the  operation: ' + this.Operationname, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          // this.displaymessageclass = "btn alert-warning "
          // this.displaymessage = ;
          // this.FadeOutmainpageMsg();
        }
        else {
          resw700OperProperties.forEach(element => {
            var e = JSON.parse(element.lookupId);
            element.displayname = e["displayname"] == undefined ? '' : e["displayname"];
            element.propertyvalue = e["default"] == undefined ? '' : e["default"];
            element.OwnerType = e["OwnerType"] == undefined ? '' : e["OwnerType"];
            element.PrefixType = e["PrefixType"] == undefined ? '' : e["PrefixType"];
            element.datatype = e["datatype"] == undefined ? '' : e["datatype"];
            element.mandatory = e["mandatory"] == undefined ? '' : e["mandatory"];
            //  element.tooltipcus=e["datatype"] == undefined ? '' : e["datatype"];
          });

          const resw700jobPropertieslist = data["w700jobPropertieslist"];
          if (resw700jobPropertieslist.length > 0) {
            resw700OperProperties.forEach(elementop => {
              resw700jobPropertieslist.forEach(element => {
                if (elementop.lookupValue == element.PropertyKey) {
                  elementop.propertyvalue = element["PropertyValue"];
                  elementop.PropertyId = element["PropertyId"];
                  //  elementop.tooltipcus=element["tooltipcus"];
                }
              });
            });
          }

          this.jobpropertieslist = resw700OperProperties;
        }

      },
      error: error => {

      }
    });

  }


  async getlabeldisplaytext(datajson) {

    return JSON.parse(datajson.lookupId)["displayname"];

  }

  savew700jobproperties: any = [];
  async SaveJobProperties() {
    var returnvalue = await this.validationchecks();
    if (returnvalue == true) {

      this.jobpropertieslist.forEach(element => {
        this.savew700jobproperties.push(
          {
            PropertyId: element.PropertyId,
            OwnerId: this.JobId,
            OwnerType: element.OwnerType,
            PrefixType: element.PrefixType,
            PropertyKey: element.lookupValue,
            PropertyValue: element.propertyvalue
          });
      });

      const savejobprop = {
        "w700jobPropertieslist": this.savew700jobproperties,
        "DeploymentKey": this.deploymentkey
      };

      this.ngxUiLoaderService.start();
      var responseroutedata = await this.w700service.postapi('api/W700Jobs/SaveW700JobsProperties', savejobprop,this.deploymentkey);
      if (responseroutedata['status'] && responseroutedata['statusText']) {
        this.ngxUiLoaderService.stop();
        this.toastr.error( 'Failed to save job properties ' + responseroutedata['statusText'], "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

        // this.displaymessageclass = "btn alert-danger "
        // this.displaymessage = 'Failure to save job properties ' + responseroutedata['statusText'];
        // this.FadeOutmainpageMsg();
      }
      else {
        this.toastr.success(  "Job properties saved successfully ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

        // this.displaymessageclass = "btn alert-success "
        // this.displaymessage = ' Job properties saved successfully';
        // this.FadeOutmainpageMsg();
        this.ngxUiLoaderService.stop();
        this.autoclosetemplate()
      }
    }
    else {
      // this.FadeOutmainpageMsg();
    }
  }


  displaymessageclass: string = '';
  displaymessageflag = false;
  displaymessage: string = '';

  async validationchecks() {
    let flag = true;
    for (let index = 0; index < this.jobpropertieslist.length; index++) {
      const element = this.jobpropertieslist[index];
      if (element.mandatory) {
        if (element.propertyvalue == '') {
          this.toastr.error(  'Please enter ' + element.displayname, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

          // this.displaymessageclass = "btn alert-danger "
          // this.displaymessage = 'Please enter ' + element.displayname;
          flag = false;
          break;
        }
        else {
          if (element.datatype == 'Integer') {
            let val = element.propertyvalue;
            val = val.replace(/[^0-9+#]/g, "");
            if (val.length == 0) {
          this.toastr.error( 'Please enter ' + element.datatype + ' only on ' + element.displayname, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });

              // this.displaymessageclass = "btn alert-danger "
              // this.displaymessage = 'Please enter ' + element.datatype + ' only on ' + element.displayname;
              flag = false;
              break;
            }
          }
          // else if (element.datatype == 'String') {
          //   let val = element.propertyvalue;
          //   val = val.replace(/[^0-9+#]/g, "");
          //   if (val.length !== 0) {
          //     this.displaymessageclass = "btn alert-danger "
          //     this.displaymessage = 'Please enter valid ' + element.datatype + 'value on ' + element.displayname;
          //     flag = false;
          //     break;
          //   }
          // }
        }
      }
    }
    return flag;
    // this.jobpropertieslist.forEach(element => {
    //   if (element.mandatory) {
    //     if (element.propertyvalue == '') {
    //       this.displaymessageclass = "btn alert-danger "
    //       this.displaymessage = 'Please Enter' + element.displayname;
    //       return false;
    //     }
    //   }
    // });
    // return true;

  }

  FadeOutmainpageMsg() {
    this.displaymessageflag = true;
    setTimeout(() => {
      this.displaymessageflag = false;
    }, 20000);
  }

  autoclosetemplate() {
    setTimeout(() => {
      this.activeModal.close();
    }, 3000);
  }


}

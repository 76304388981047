import {
    Component,
    OnInit,
    Input,
    ViewChild,
    SimpleChanges
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from "../services/common.service";
import { MonacoEditorComponent, MonacoEditorConstructionOptions, MonacoEditorLoaderService, MonacoStandaloneCodeEditor } from "@materia-ui/ngx-monaco-editor";
import { filter, take } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material";
import { FtpfilesComponent } from "../ftpfiles/ftpfiles.component";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup } from "@angular/forms";
import beautify from "xml-beautifier";
import JSZip from "jszip";
declare var $

declare const copyToClipboard: any;

@Component({ selector: "app-tryitout", templateUrl: "./tryitout.component.html", styleUrls: ["./tryitout.component.scss"], providers: [MonacoEditorLoaderService] })
export class TryitoutComponent implements OnInit {
    @Input() inputDataInModalComponent: any;

    inputdata: string = "";
    OutputData: string = "";
    formatType: string = "plaintext";
    previewDataXMl: boolean = false;
    nestedTreeControl: NestedTreeControl<FileNode>;
    nestedDataSource: MatTreeNestedDataSource<FileNode>;
    private _getChildren = (node: FileNode) => node.children;
    hasNestedChild = (_: number, nodeData: FileNode) => nodeData.children;
    database: FileDatabase;

    @ViewChild(MonacoEditorComponent, { static: false })
    monacoComponent: MonacoEditorComponent;
    editorOptions: MonacoEditorConstructionOptions = {
        theme: "myCustomTheme",
        language: this.formatType,
        roundedSelection: true,
        autoIndent: "full",
        wordWrap: "on",
        minimap: {
            enabled: false
        }
    };

    editorOptions1: MonacoEditorConstructionOptions = {
        theme: "myCustomTheme",
        language: this.formatType,
        roundedSelection: true,
        autoIndent: "full",
        readOnly: true,
        wordWrap: "on",
        minimap: {
            enabled: false
        }
    };

    editor: MonacoStandaloneCodeEditor;
    screeHeightforoutput: number;
    screeHeightforinput: number;
    enableAccessionSearch: boolean = false;
    fileUploadFTP: boolean;
    routedetails: {
        RouteIdtry: any;
        ProcessorIdtry: any;
        ProcessorDisplayNametry: any;
        RouteDisplayNametry: any;
        Processorapikey: any;
        Popupflag: boolean;
        MappedXmlTemp: string;
        isFileUpload: boolean;
        isfiledownload: boolean;
        interfacetype: any;
        enableAccessionSearch: boolean;
        fileUploadFTP: boolean;
        sourcedeploymentkey: any;
        targetdeploymentkey: any;
        sourceaccounttype: any;
        targetaccounttype: any;
        seguid: any;
        deguid: any;
        sourceagent: any
    };
    versionDetails: any[];
    versionList: string[] = [];
    version: any;
    notfounddata: boolean;
    dynamicJson: routeprocessordata[] = [];
    fileidarray: any[];
    uniqueid: any;
    editorInit(editor: MonacoStandaloneCodeEditor) {
        this.editor = editor;
        editor.onDidChangeCursorPosition(() => {
            if (this.inputdata) {
                if (this.inputdata.startsWith("<") || this.OutputData.startsWith("<")) {
                    this.formatType = "xml";
                } else if (this.inputdata.startsWith("{") || this.OutputData.startsWith("{")) {
                    this.formatType = "json";
                }
            }
            this.editorOptions = {
                ...this.editorOptions,
                language: this.formatType
            };
            this.editorOptions1 = {
                ...this.editorOptions1,
                language: this.formatType
            };
        });
    }
    reactiveFormroutegbl: FormGroup;
    constructor(private ngxUiLoaderService: NgxUiLoaderService, public service: CommonService, private _fb: FormBuilder, public activeModal: NgbActiveModal, private modalService: NgbModal, private monacoLoaderService: MonacoEditorLoaderService, private toastr: ToastrService) {
        this.monacoLoaderService.isMonacoLoaded$.pipe(filter((isLoaded) => !!isLoaded), take(1)).subscribe(() => {
            this.registerMonacoCustomTheme();
        });
        this.nestedTreeControl = new NestedTreeControl<FileNode>(this._getChildren);
        this.nestedDataSource = new MatTreeNestedDataSource();
        this.reactiveFormroutegbl = _fb.group({ routenamegbl: '' });
    }
    registerMonacoCustomTheme() {
        monaco.editor.defineTheme("myCustomTheme", {
            base: "vs", // can also be vs or hc-black
            inherit: true, // can also be false to completely replace the builtin rules
            rules: [
                {
                    token: "comment",
                    foreground: "ffa500",
                    fontStyle: "italic underline"
                }, {
                    token: "comment.js",
                    foreground: "008800",
                    fontStyle: "bold"
                }, {
                    token: "comment.css",
                    foreground: "0000ff"
                }, // will inherit fontStyle from `comment` above
            ],
            colors: {}
        });
    }

    RouteIdtry: string;
    ProcessorIdtry: string;
    classObjalert: string;
    StatusMessage: string;
    transformapiresponse: any[];
    statusmessagetryitout: boolean = false;
    Processorapikey: string;
    IsFileUpload: boolean = false;
    IsFileDownload: boolean = false;
    popupflag: boolean = true;
    MappedXmlTemp: string;
    filecheck: boolean;
    senderid: any;
    hash: any;
    transId: any;
    fileidValue: string;
    sourceaccounttype: string = "";
    targetaccounttype: string = "";
    seguid: string = "";
    deguid: string = "";
    ngOnInit() {
        this.screeHeightforoutput = window.innerHeight - 250;
        this.screeHeightforinput = window.innerHeight - 281;
        if (this.inputDataInModalComponent != undefined) {
            if (this.inputDataInModalComponent) {
                this.RouteIdtry = this.inputDataInModalComponent.RouteIdtry;
                this.ProcessorIdtry = this.inputDataInModalComponent.ProcessorIdtry;
                this.Processorapikey = this.inputDataInModalComponent.Processorapikey;
                this.popupflag = this.inputDataInModalComponent.Popupflag;
                this.MappedXmlTemp = this.inputDataInModalComponent.MappedXmlTemp;
                this.IsFileDownload = this.inputDataInModalComponent.isfiledownload;
                this.IsFileUpload = this.inputDataInModalComponent.isFileUpload;
                this.fileUploadFTP = this.inputDataInModalComponent.fileUploadFTP;
                this.uniqueid = this.inputDataInModalComponent.Uniqueid;
                this.enableAccessionSearch = this.inputDataInModalComponent.enableAccessionSearch;
                if (this.enableAccessionSearch || this.fileUploadFTP || (this.inputDataInModalComponent.showVersionDetails != undefined && this.inputDataInModalComponent.showVersionDetails)) {
                    this.screeHeightforinput = window.innerHeight - 289;
                }
                this.sourceaccounttype = this.inputDataInModalComponent.sourceaccounttype;
                this.targetaccounttype = this.inputDataInModalComponent.targetaccounttype;
                this.seguid = this.inputDataInModalComponent.seguid;
                this.deguid = this.inputDataInModalComponent.deguid;
                if (this.inputDataInModalComponent.showVersionDetails) {
                    this.version = this.inputDataInModalComponent.version;
                    if (this.inputDataInModalComponent.RouteIdtry && this.inputDataInModalComponent.ProcessorIdtry) {
                        this.ngxUiLoaderService.start();
                        this.getVersionDetails(this.inputDataInModalComponent.RouteIdtry, this.inputDataInModalComponent.ProcessorIdtry)
                        this.ngxUiLoaderService.stop();
                    }


                }
            }
        } else {
            this.fileUploadFTP = false;
            this.enableAccessionSearch = false;
            this.inputDataInModalComponent = <any>[];
            this.inputDataInModalComponent.RouteDisplayNametry = "";
            this.inputDataInModalComponent.ProcessorDisplayNametry = "";
            this.inputDataInModalComponent.RouteIdtry = "";
            this.inputDataInModalComponent.ProcessorIdtry = "";
            this.popupflag = false;
            this.MappedXmlTemp = "";
        }
    }
    isValuefromInput: boolean = false;
    ngOnChanges() {
        if (!this.inputDataInModalComponent) {
            this.isValuefromInput = true;
        } else {
            this.isValuefromInput = false;
        }
    }
    getstringRoute(value) {
        let names = value.split("(")
        value = `${names[0]} <br>(${names[1]}`
        return value
    }
    searchkey: string = "";
    GetxmlByAccessionNumber() {
        if (this.searchkey) {

            let isvalid = false;
            if (this.searchkey.indexOf(',') == -1) {
                isvalid = true;
            }
            else if (this.searchkey.split(',').length <= 5) {
                isvalid = true;
            }
            if (isvalid) {
                let dpkey = "";
                if ((this.inputDataInModalComponent != undefined && (this.inputDataInModalComponent.interfacetype === "Outbound" || this.inputDataInModalComponent.interfacetype === "EllkayResultOutbound")) || (this.routedetails != undefined && (this.routedetails.interfacetype === "Outbound" || this.routedetails.interfacetype === "EllkayResultOutbound"))) {
                    dpkey = this.inputDataInModalComponent == "" ? this.routedetails.sourcedeploymentkey : this.inputDataInModalComponent.sourcedeploymentkey;
                }
                else if(this.inputDataInModalComponent.length==0)
                {
                    dpkey = this.inputDataInModalComponent == "" ? this.routedetails.sourcedeploymentkey : this.inputDataInModalComponent.sourcedeploymentkey;
                }

                this.ngxUiLoaderService.start();
                var apiname = "api/CoreConfig/GetProcessorUIConfig?agentid=&apikey=";
                this.service.getapi(apiname).then(responseconfig => {
                    ;
                    var configs = JSON.parse(responseconfig.responsebody);
                    var dpconfig = configs[dpkey];
                    //  this.getdeploymentconfiguration().then(response=>{
                    /* Get the message type from processor  */
                    var searchData = {
                        "id": this.inputDataInModalComponent == "" ? this.routedetails.ProcessorIdtry : this.inputDataInModalComponent.ProcessorIdtry,
                        "type": "Processor"
                    };
                    var messagetype = "";
                    var apiname = 'api/Agents/GetSearchDetails';
                    this.service.searchpostapi(apiname, searchData).then(responseagent => {
                        const resultset = responseagent as any[];
                        var processoragentconfigdata;
                        if (resultset[0]["jsonconfig"]) {
                            var jsonconfig = JSON.parse(resultset[0]["jsonconfig"]);
                            processoragentconfigdata = jsonconfig.processoragentconfig;
                            if (processoragentconfigdata != undefined) {
                                if (processoragentconfigdata.messagetype) {
                                    messagetype = processoragentconfigdata.messagetype;
                                }
                            }
                        }
                        if (messagetype.toLocaleLowerCase() == 'result') {
                            messagetype = "Results";
                        } else if (messagetype.toLocaleLowerCase() == 'order') {
                            messagetype = "Orders";
                        }
                        var EntityType = '';
                        var EntityValue = '';
                        let isEmptyAccounttype = false;
                        if (this.inputDataInModalComponent && this.inputDataInModalComponent.targetaccounttype == "") {
                            this.ngxUiLoaderService.stop();
                            this.toastr.error("Account type of the Source entity is not available" , "", {
                                timeOut: 4000,
                                positionClass: 'toast-bottom-right'
                            });
                            isEmptyAccounttype = true;
                        }
                        else if (this.routedetails && this.routedetails.targetaccounttype == "") {
                            this.ngxUiLoaderService.stop();
                            this.toastr.error("Account type of the Destination entity is not available", "", {
                                timeOut: 4000,
                                positionClass: 'toast-bottom-right'
                            });
                            isEmptyAccounttype = true;
                        }
                        if (!isEmptyAccounttype) {
                          this.ngxUiLoaderService.start();
                            if ((this.inputDataInModalComponent != undefined && (this.inputDataInModalComponent.interfacetype === "Outbound" || this.inputDataInModalComponent.interfacetype === "EllkayResultOutbound")) || (this.routedetails != undefined && (this.routedetails.interfacetype === "Outbound" || this.routedetails.interfacetype === "EllkayResultOutbound"))) {
                                EntityType = this.inputDataInModalComponent == "" ? this.routedetails.targetaccounttype : this.inputDataInModalComponent.targetaccounttype;
                                EntityValue = this.inputDataInModalComponent == "" ? this.routedetails.seguid : this.inputDataInModalComponent.seguid;
                            } else {
                                EntityType = this.inputDataInModalComponent == "" ? this.routedetails.targetaccounttype : this.inputDataInModalComponent.targetaccounttype;
                                EntityValue = this.inputDataInModalComponent == "" ? this.routedetails.seguid : this.inputDataInModalComponent.seguid;
                            }
                            var inputsearch = {
                                "AccessionnumberList": this.searchkey.split(','),
                                "EntityType": EntityType,
                                "EntityValue": EntityValue,
                                "MessageType": messagetype,
                                "Headers": JSON.stringify(dpconfig.apidetails["headers"]),
                                "Url": dpconfig.apidetails["inputxmlapiurl"]
                            }
                            this.getcasexml(inputsearch);

                        }
                    });
                });

            }
            else{
                this.toastr.warning("Please provide upto 5 accession number!!", "", {
                    timeOut: 4000,
                    positionClass: 'toast-bottom-right'
                });
            }
        }
        else {
            this.toastr.warning("Please enter the accession number!!", "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
            });
        }

    }
    inputxml = [];
    getcasexml(input) {
        this.inputxml = [];
        let counter = 0;
        for (let i = 0; i < input.AccessionnumberList.length; i++) {

            let obj = {
                "Accessionnumber": input.AccessionnumberList[i],
                "EntityType": input.EntityType,
                "EntityValue": input.EntityValue,
                "MessageType": input.MessageType,
                "Headers": input.Headers,
                "Url": input.Url
            }
            this.service.postapi("api/TransformXml/GetInputXML", obj).then(dxresponse => {
                if (dxresponse['responsemessage'] && dxresponse['xml']) {

                    if (this.searchkey.indexOf(',') > -1) {
                        this.inputxml.push(dxresponse["xml"]);
                        counter++;
                        if (input.AccessionnumberList.length == counter) {
                            this.searchkey = "";
                            this.Transformdata(this.inputxml);
                        }
                    }
                    else {
                        this.inputdata = dxresponse["xml"];
                        this.searchkey = "";
                        this.ngxUiLoaderService.stop();
                    }
                } else {
                    this.ngxUiLoaderService.stop();
                    this.searchkey = "";
                }
            });

        }

    }
    async getdeploymentconfiguration() {
        var dpkey = '';
        // if ((this.inputDataInModalComponent != undefined && this.inputDataInModalComponent.interfacetype === "Inbound") || (this.routedetails != undefined && this.routedetails.interfacetype === "Inbound")) {
        //     dpkey = this.inputDataInModalComponent == "" ? this.routedetails.targetdeploymentkey : this.inputDataInModalComponent.targetdeploymentkey;
        // } else


    }


    async validatedata() {
        if (this.RouteIdtry == "") {
            this.toastr.warning("Pipeline is mandatory", "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
            });
            return false;
        } else if (this.ProcessorIdtry == "") {

            this.toastr.warning("Processor is mandatory", "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
            });
            return false;
        } else {
            return true;
        }
    }
    async Transformdata(InputDatacontentvalue) {
        var apiname = "api/TransformXml/Post";
        let inputarray = [];
        if (InputDatacontentvalue) {
            if (Array.isArray(InputDatacontentvalue) && InputDatacontentvalue.length > 0) {
                inputarray = InputDatacontentvalue;
            }
            else {
                inputarray.push(InputDatacontentvalue);
            }
        }
        if (inputarray.length > 0) {
            if (this.validatexml(inputarray)) {
                const jsonpost = {
                    "InputData": inputarray,
                    "RouteId": this.inputDataInModalComponent != "" ? this.inputDataInModalComponent.RouteIdtry : this.routedetails.RouteIdtry,
                    "ProcessorId": this.inputDataInModalComponent != "" ? this.inputDataInModalComponent.ProcessorIdtry : this.routedetails.ProcessorIdtry,
                    "ApiKey": this.inputDataInModalComponent != "" ? this.inputDataInModalComponent.Processorapikey : this.routedetails.Processorapikey,
                    "IsFileUpload": this.inputDataInModalComponent != "" ? this.inputDataInModalComponent.isFileUpload : this.routedetails.isFileUpload,
                    "IsFileDownload": this.inputDataInModalComponent != "" ? this.inputDataInModalComponent.isfiledownload : this.routedetails.isfiledownload,
                    "MappedXml": this.inputDataInModalComponent != "" ? this.inputDataInModalComponent.MappedXmlTemp : this.routedetails.MappedXmlTemp,
                    "Version": this.version == '' || this.version == undefined ? '' : this.version
                };
                this.ngxUiLoaderService.start();
                this.service.postapi(apiname, jsonpost).then(response => {
                    ;
                    if (response["status"] && response["statusText"]) {
                        this.ngxUiLoaderService.stop();
                        this.toastr.error("Failed to Transform", "", {
                            timeOut: 4000,
                            positionClass: 'toast-bottom-right'
                        });
                        this.FadeOuttryitoutpageMsg();
                    } else {

                        this.transformapiresponse = response as any[];
                        for (let i = 0; i < this.transformapiresponse.length; i++) {
                            if (this.transformapiresponse != null && this.transformapiresponse[i]["status"].toLocaleLowerCase() != "failure") { // this.StatusMessage = this.transformapiresponse["statusmessage"];
                                if (this.transformapiresponse.length <= 1) {
                                    this.ngxUiLoaderService.stop();
                                    this.OutputData = this.transformapiresponse[i]["responsebody"];

                                    this.formatXml(this.OutputData);
                                    // this.classObjalert = "btn alert-success ";
                                    // this.FadeOuttryitoutpageMsg();
                                    this.toastr.success(this.transformapiresponse[i]["statusmessage"], "", {
                                        timeOut: 4000,
                                        positionClass: 'toast-bottom-right'
                                    });

                                    if (this.OutputData != null) {
                                        try {
                                            const previewJson = this.transformapiresponse[i]["extendedattributes"]["previewdatajson"];
                                            if (previewJson != "") {
                                                this.constructxmltree(previewJson);
                                                this.previewDataXMl = true;
                                            }
                                            const OutputJsonResponsebody = JSON.parse(this.OutputData);
                                            if (Array.isArray(OutputJsonResponsebody)) {
                                                if (OutputJsonResponsebody.length > 0) {
                                                    this.filecheck = true;
                                                    this.fileidarray = OutputJsonResponsebody;
                                                }
                                            }
                                            else {
                                                this.fileidValue = OutputJsonResponsebody["fileid"];
                                                if (this.fileidValue != null) {
                                                    this.filecheck = true;
                                                    this.senderid = this.transformapiresponse[i]["extendedattributes"]["senderagentid"];
                                                    this.hash = this.transformapiresponse[i]["extendedattributes"]["hash"];
                                                    this.transId = this.transformapiresponse[i]["extendedattributes"]["transactionid"];
                                                }
                                            }

                                        } catch { }
                                    } else {
                                        this.filecheck = false;
                                    }
                                }
                                else {
                                    if (i == (this.transformapiresponse.length - 1)) {
                                        this.downloadzipfile(this.transformapiresponse);
                                    }
                                }

                            } else {
                                this.ngxUiLoaderService.stop();
                                this.toastr.error(this.transformapiresponse[i]["statusmessage"], "", {
                                    timeOut: 4000,
                                    positionClass: 'toast-bottom-right'
                                });
                            }
                        }
                    }
                });
            }
        } else {
            this.toastr.error("Input is mandatory", "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
            });
        }
    }
    downloadzipfile(transformapiresponse: any[]) {
        let array = [];
        for (let i = 0; i < transformapiresponse.length; i++) {
            let output = transformapiresponse[i]["responsebody"];
            if (output.includes('fileid')) {

                let fileobj;
                if (transformapiresponse[i]["responsebody"].includes('fileid')) {
                    fileobj = JSON.parse(transformapiresponse[i]["responsebody"]);
                }
                if (fileobj != "" && Array.isArray(JSON.parse(transformapiresponse[i]["responsebody"]))) {
                    if (transformapiresponse[i]["responsebody"].length > 0) {
                        let fileidarray = JSON.parse(transformapiresponse[i]["responsebody"].toString());
                        for (let i = 0; i < fileidarray.length; i++) {
                            let fileidObj = {
                                fileid: fileidarray[i].fileid,
                                filename: fileidarray[i].filename
                            }
                            array.push(fileidObj);
                        }
                    }
                }
                else if (transformapiresponse[i]["responsebody"].includes('fileid')) {
                    let fileobjresponse = JSON.parse(transformapiresponse[i]["responsebody"]);
                    if (fileobjresponse['fileid']) {
                        array.push(fileobjresponse['fileid']);
                    }
                }
                if (i == (transformapiresponse.length - 1)) {
                    var apiname = "api/Routes/GetFileContent";
                    this.service.postapi(apiname, array).then(response => {
                        let data = response as any[];
                        let fileArray = [];
                        for (let k = 0; k < data.length; k++) {
                            if (array[k].filename && array[k].filename.indexOf('.') == "-1") {
                                array[k].filename += ".txt";
                            }
                            let obj = {
                                filename: array[k].filename,
                                filecontent: data[k]["responsebody"],
                                type: 'application/octet-stream'
                            };
                            fileArray.push(obj);
                            if (k == (data.length - 1)) {
                                this.createZip(fileArray);
                            }
                        }
                    });
                }
            }
            else {
                let obj = {
                    filename: '',
                    filecontent: '',
                    type: ''
                };
                let fileArray = [];
                fileArray.push(obj);
                if (i == (transformapiresponse.length - 1)) {
                    this.createZip(fileArray);
                }
            }
        }
    }
    async createZip(files: any[]) {
        var zip = new JSZip();
        var today = new Date();

        var date = today.getFullYear()+''+(today.getMonth()+1)+''+today.getDate();

        var time = today.getHours() + "" + today.getMinutes() + "" + today.getSeconds()+today.getMilliseconds();

        var dateTime = date+time;

        const name = 'Output_' + dateTime + '.zip';
        for (let counter = 0; counter < files.length; counter++) {
            zip.file(files[counter].filename, files[counter].filecontent, { base64: true });
        };
        this.ngxUiLoaderService.stop();
        zip.generateAsync({ type: 'blob' }).then((content) => {
            if (content) {
                var FileSaver = require("file-saver");
                FileSaver.saveAs(content, name);
                this.ngxUiLoaderService.stop();
            }
        });
    }
    FadeOuttryitoutpageMsg() {
        this.statusmessagetryitout = true;
        setTimeout(() => {
            this.statusmessagetryitout = false;
        }, 20000);
    }
    clearAll() {
        this.classObjalert = "col-sm-5";
        this.OutputData = "";
        this.StatusMessage = "";
        // InputDatacontentvalue.value = '';
        this.inputdata = "";
        this.previewDataXMl = false;
        this.fileidValue = "";
        this.nestedTreeControl = new NestedTreeControl<FileNode>(this._getChildren);
        this.nestedDataSource = new MatTreeNestedDataSource();
        this.filecheck = false;
        this.notfounddata = false;
        if (!this.popupflag) {
            this.inputDataInModalComponent = [];
        }
        this.searchkey='';
    }

    copyToClip(event: any, message: any) {
        if (event != "") {
            var xmlDocument;
            try {
                xmlDocument = (new DOMParser()).parseFromString(event, 'text/xml');
            }
            catch {
                xmlDocument = "";
            }
            if (xmlDocument) {
                event = beautify(event);
            }
            copyToClipboard(event);
            // this.StatusMessage = "copied";
            // this.classObjalert = "btn alert-success ";
            // this.FadeOuttryitoutpageMsg();
            this.toastr.success(message + " Copied", "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
            });
        }
    }

    checkedevent(e) {
        if (e.target.checked) {
            this.IsFileUpload = true;
        } else {
            this.IsFileUpload = true;
        }
    }
    checkedeventdownload(e) {
        if (e.target.checked) {
            this.IsFileDownload = true;
        } else {
            this.IsFileDownload = true;
        }
    }

    downloadfile() {
        this.ngxUiLoaderService.start();
        if (this.fileidarray.length > 0) {
            for (var i = 0; i < this.fileidarray.length; i++) {
                this.downloadcontent(this.fileidarray[i].fileid)
            }
        }
        else {
            this.downloadcontent(this.fileidValue)
        }
        this.ngxUiLoaderService.stop();

    }
    downloadcontent(fileid) {
     let createdBy= JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
        let apiname = "api/Routes/GetFileData?fileID=" + fileid+"&contextId=" + this.uniqueid+"&createdBy=" + createdBy;
        (this.service.getapiurlcontent(apiname)).subscribe({
            next: data123 => {
                if (data123["statusmessage"] != "") {
                    var fileDetails = JSON.parse(data123["statusmessage"]);
                    let filename = "";
                    if (fileDetails.hasOwnProperty("filename")) {
                        filename = fileDetails.filename;
                    }
                    if (filename == "") {
                        filename = fileid;
                    }

                    if (typeof (fileDetails) == "object" && fileDetails.hasOwnProperty("contenttype")) {
                        let contentType = fileDetails.contenttype;

                        switch (contentType) {
                            case "application/xml": filename += ".xml";
                                break;
                            case "application/json": filename += ".js";
                                break;
                            case "application/pdf": filename += ".pdf";
                                break;
                            default: filename += ".txt";
                                break;
                        }
                    }
                    if (filename != "" && filename.indexOf('.') == -1) {
                        filename += '.txt';
                    }
                    var xmlDocument;
                    try {
                        xmlDocument = (new DOMParser()).parseFromString(data123.responsebody, 'text/xml');
                    }
                    catch {
                        xmlDocument = "";
                    }
                    if (xmlDocument) {
                        data123.responsebody = beautify(data123.responsebody);
                    }
                    const file = new window.Blob([data123.responsebody], { type: 'application/octet-stream' });
                    const downloadAncher = document.createElement("a");
                    const fileURL = URL.createObjectURL(file);
                    downloadAncher.href = fileURL;
                    downloadAncher.download = filename;
                    downloadAncher.click();
                }
            },
            error: error => {
                this.ngxUiLoaderService.stop();
                this.toastr.error('Failed to download file', "", {
                    timeOut: 4000,
                    positionClass: 'toast-bottom-right'
                });
            }
        });
    }
    formatXml(xml: any) {
        var formatted = "";
        var reg = /(>)(<)(\/*)/g;
        xml = xml.replace(reg, "$1\r\n$2$3");
        var pad = 0;
        xml.split("\r\n").map((node: any, index: number) => {
            var indent = 0;
            if (node.match(/.+<\/\w[^>]*>$/)) {
                indent = 0;
            } else if (node.match(/^<\/\w/)) {
                if (pad != 0) {
                    pad -= 1;
                }
            } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
                indent = 1;
            } else {
                indent = 0;
            }

            var padding = "";
            for (var i = 0; i < pad; i++) {
                padding += "  ";
            }

            formatted += padding + node + "\r\n";
            pad += indent;
        });
        this.OutputData = formatted;
        return this.OutputData;
    }
    constructxmltree(json) {
        this.database = new FileDatabase(json);
        this.database.dataChange.subscribe((data) => (this.nestedDataSource.data = data));
    }
    isLoadingroutesgbl: boolean = false;
    RouteNameListgbl: any = [];
    selectedrouteIdgbl: string = '';
    selectedrouteDisplayNamegbl: string = '';
    keywordsearchgbl: string = 'displayname';

    getRouteNamegbl(event: any) {
        var val = event;
        if (val) {
            this.isLoadingroutesgbl = true;
            var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=0";
            this.service.getapiurl(apiname).subscribe({
                next: data => {
                    this.RouteNameListgbl = data as any[];
                    this.RouteNameListgbl.map(
                        obj => obj.displayname = `${obj.displayname
                            } ${'(' + obj.id + ')'
                            }`
                    );
                    this.isLoadingroutesgbl = false;
                    if (data.length == 0) {
                        this.notfounddata = true;
                    }
                    else {
                        this.notfounddata = false;
                    }
                },
                error: error => {
                    this.isLoadingroutesgbl = false;
                    this.notfounddata = true;
                }
            });
        }

    }

    selectedRoutegbl(item) {
        this.selectedrouteIdgbl = item.id;
        this.selectedrouteDisplayNamegbl = item.displayname;
        this.getRouteDetails();
    }
    searchRouteNameClearedgbl(event) {
        this.isLoadingroutesgbl = false;
        this.RouteNameListgbl = [];
        this.selectedrouteIdgbl = '';
        this.selectedrouteDisplayNamegbl = '';
        this.fileUploadFTP = false;
        this.enableAccessionSearch = false;
        this.notfounddata = false;
        this.filecheck = false;
        this.fileidValue = "";
        this.notfounddata = false;
        this.routedetails = {
            RouteIdtry: "",
            ProcessorIdtry: "",
            ProcessorDisplayNametry: "",
            RouteDisplayNametry: "",
            Processorapikey: "",
            Popupflag: false,
            MappedXmlTemp: "",
            isFileUpload: false,
            isfiledownload: false,
            interfacetype: "",
            enableAccessionSearch: false,
            fileUploadFTP: false,
            sourcedeploymentkey: "",
            targetdeploymentkey: "",
            sourceaccounttype: "",
            targetaccounttype: "",
            seguid: "",
            deguid: "",
            sourceagent: ""
        }
    }


    async getRouteDetails() {
        if (this.selectedrouteIdgbl != "") {
            var url = "api/Routes/GetRouteList?id=" + this.selectedrouteIdgbl;
            this.ngxUiLoaderService.start();
            this.service.getapiurl(url).subscribe({
                next: async (data) => {
                    let response = await this.getFileConfig(data["id"], data["PAID"]);
                    if (response[0]["json"] != '' && response[0]["json"] != null) {
                        var jsonData;
                        var vaconfigdata;
                        var interfacetype = "";
                        if (response[0]["json"] != '' && response[0]["json"] != null) {
                            jsonData = JSON.parse(response[0]["json"]);

                            if (jsonData.processorconfig != undefined && jsonData.processorconfig["vaconfig"] != undefined) {
                                vaconfigdata = jsonData.processorconfig["vaconfig"];
                            }
                            if (jsonData.processorconfig != undefined && jsonData.processorconfig["interfacetype"] != undefined) {
                                interfacetype = jsonData.processorconfig["interfacetype"];
                            }
                        }
                        this.isValuefromInput = false;
                        if (data["messagetype"] === "order") {
                            this.fileUploadFTP = true;
                            this.enableAccessionSearch = false;
                        } else if (data["messagetype"] === "result") {
                            this.fileUploadFTP = false;
                            this.enableAccessionSearch = true;
                        }
                        else {
                            this.fileUploadFTP = false;
                            this.enableAccessionSearch = false;
                        }
                        if (this.enableAccessionSearch || this.fileUploadFTP) {
                            this.screeHeightforinput = window.innerHeight - 288;
                        }

                        let isfileupload = 0;
                        let isfiledownload = 0;
                        if (vaconfigdata != undefined) {
                            isfileupload = vaconfigdata.isfileupload;
                        }

                        if (vaconfigdata != undefined) {
                            isfiledownload = vaconfigdata.isfiledownload;
                        }

                        this.routedetails = {
                            RouteIdtry: data["id"],
                            ProcessorIdtry: data["PAID"],
                            ProcessorDisplayNametry: data["PANAME"],
                            RouteDisplayNametry: data["displayname"],
                            Processorapikey: data["PAAPIKEY"],
                            Popupflag: true,
                            MappedXmlTemp: "",
                            isFileUpload: isfileupload == 0 ? false : true,
                            isfiledownload: isfiledownload == 0 ? false : true,
                            interfacetype: interfacetype,
                            enableAccessionSearch: this.enableAccessionSearch,
                            fileUploadFTP: this.fileUploadFTP,
                            sourcedeploymentkey: data["SEDEPLOYMENTKEY"],
                            targetdeploymentkey: data["DEDEPLOYMENTKEY"],
                            sourceaccounttype: data["sourcetype"],
                            targetaccounttype: data["targettype"],
                            seguid: data["SEGUID"],
                            deguid: data["DEGUID"],
                            sourceagent: data["sourceagentid"]
                        };
                        this.ngxUiLoaderService.stop();
                    }
                    else {

                        this.ngxUiLoaderService.stop();
                        this.toastr.warning("Please check the Pipeline configurations", "", {
                            timeOut: 4000,
                            positionClass: 'toast-bottom-right'
                        });
                    }

                },
                error: error => {
                    this.ngxUiLoaderService.stop();
                }
            });
        }
    }
    async getFileConfig(routeid: string, ProcessorId: string) {
        var dataPost = {
            agentid: ProcessorId,
            guids: routeid
        };

        return await this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost);

        //
    }


    openFTPfilespopup() {
        const dataToFTPFilesModal = {
            routeId: this.inputDataInModalComponent == "" ? this.routedetails.RouteIdtry : this.inputDataInModalComponent.RouteIdtry,
            sourceagent: this.inputDataInModalComponent == "" ? this.routedetails.sourceagent : this.inputDataInModalComponent.sourceagent,
            selectflag: true
        };
        const modalRef = this.modalService.open(FtpfilesComponent, {
            windowClass: "myCustomModalClassFTP",
            backdrop: 'static',
            keyboard: false
        });
        modalRef.componentInstance.inputDataInModalComponent = dataToFTPFilesModal;
        modalRef.result.then((data) => {
            this.inputdata = data;
        });
    }

    getVersionDetails(routeId: any, processorId: any) {
        var url = "api/CoreConfig/GetCoreconfigVersion?routeid=" + routeId + "&agentid=" + processorId + "&version=";
        this.service.getapiurl(url).subscribe(data => {
            if (data) {
                let response = data as any[];
                this.versionDetails = response;
                response.forEach((ele) => {
                    if (ele.version) {
                        this.versionList.push(ele.version);
                    }
                })
                this.version = this.inputDataInModalComponent.version;
            }
        }),
            error => { };

    }

    onVersionChange(eventvalue) {
        this.versionDetails.forEach((ele) => {
            if (ele.version == eventvalue.value) {
                this.inputDataInModalComponent.MappedXmlTemp = ele.json.processorconfig.xslconfig.mappedxml;
            }
        })
    }
    openpushmessagemodel(modal,c) {
        this.dynamicJson = [];
        var url = "api/Routes/get?id=" + this.inputDataInModalComponent.RouteIdtry;
        this.ngxUiLoaderService.start();
        this.service.getapiurl(url).subscribe(data => {
            if (data) {
                if (data["RouteProcessorData"].length > 1) {
                    // $('#pushMessage').modal({ backdrop: 'static', keyboard: false });
                    const modalRef = this.modalService.open(modal,
                        { windowClass: "pushmodal", backdrop: 'static', keyboard: false });
                      modalRef.result.then(() => { });
                    data["RouteProcessorData"].forEach(element => {

                        var url = "api/CoreConfig/GetCoreconfigVersion?routeid=" + this.inputDataInModalComponent.RouteIdtry + "&agentid=" + element.id + "&version=";
                        this.service.getapiurl(url).subscribe(response => {
                            if (response) {
                                let version = '';
                                response.forEach((ele) => {
                                    if (ele.version) {
                                        if (ele.islive) {
                                            version = ele.version;
                                        }
                                    }
                                });
                                if (!version) {
                                    version = response[response.length - 1].version;
                                }
                                let routeprocessordata: routeprocessordata = {
                                    data: response,
                                    displayname: element.displayname,
                                    version: version,
                                    sequence: element.Sequence,
                                    processorid: element.id
                                }
                                this.dynamicJson.push(routeprocessordata);
                                if (this.dynamicJson.length > 0) {
                                    this.dynamicJson = this.sortByKey(this.dynamicJson, 'sequence')
                                }
                            }
                        }
                        )
                    }

                    );

                    this.ngxUiLoaderService.stop();
                }
                else {
                    this.pushmessage(false,c);
                    this.ngxUiLoaderService.stop();
                }
            }
        });



    }
    sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    pushmessage(isroutecontainsmultipleprocessor,c) {
        this.ngxUiLoaderService.start();
        let routeprocessordata = [];
        let messageObj;
        if (isroutecontainsmultipleprocessor && this.dynamicJson.length > 0) {
            this.dynamicJson.forEach(ele => {
                let obj = {
                    processorAgentId: ele.processorid,
                    Sequence: ele.sequence.toString(),
                    coreConfigVersion: ele.version
                }
                routeprocessordata.push(obj);
            });
            messageObj = {
                filecontent: this.inputdata,
                routedefinitionid: this.inputDataInModalComponent.RouteIdtry,
                routeProcessors: this.sortByKey(routeprocessordata, 'Sequence')
            }
        }
        else {
            let obj = {
                processorAgentId: this.inputDataInModalComponent.ProcessorIdtry,
                Sequence: "1",
                coreConfigVersion: this.version
            }
            routeprocessordata.push(obj);
            messageObj = {
                filecontent: this.inputdata,
                routedefinitionid: this.inputDataInModalComponent.RouteIdtry,
                routeProcessors: this.sortByKey(routeprocessordata, 'Sequence')
            }
        }

        this.service.postapi('api/HubMessage/ReleaseMessage', messageObj).then(response => {
            if (response) {
                    this.OutputData = response.responsebody;
                    var obj = this.getxmldata(response.responsebody);
                    this.service.auditsave("Add", "", ("Message Pushed   Successfully with the transactionid " + obj["transactionid"] + " and messageid " + obj["messageid"]), "Pipeline", this.uniqueid,'Message Pushed   Successfully').then(response => {
                    this.toastr.success("Message pushed successfully", "", {
                        timeOut: 4000,
                        positionClass: 'toast-bottom-right'
                    });
                            if (isroutecontainsmultipleprocessor && this.dynamicJson.length > 0) {
                                this.closepopup(c);
                            }
                    });
            }
            this.ngxUiLoaderService.stop();
        });
    }
    getxmldata(data: any) {
        var obj = {}
        if (data) {
            var xmlDocument = (new DOMParser()).parseFromString(data, 'text/xml');
            var xmlnodes = xmlDocument.childNodes[0];
            if (xmlnodes["attributes"].length > 0) {
                for (var i = 0; i < xmlnodes["attributes"].length; i++) {
                    if (xmlnodes["attributes"][i].nodeName == "transactionid") {
                        obj["transactionid"] = xmlnodes["attributes"][i].nodeValue
                    }
                    if (xmlnodes["attributes"][i].nodeName == "messageid") {
                        obj["messageid"] = xmlnodes["attributes"][i].nodeValue
                    }
                }
            }

        }
        return obj;
    }
    closepopup(c) {
        c('close modal');
        this.dynamicJson = [];
    }
    validatexml(input) {
        let isvalidxml = false;
        for (let i = 0; i < input.length; i++) {
            if (input[i].startsWith("<")) {

                if (this.IsValidXML(input[i])) {
                    isvalidxml = true;
                } else {
                    isvalidxml = false;
                }
            }
            else {
                isvalidxml = true;
            }
        }
        if (!isvalidxml) {
            this.toastr.warning("Invalid Input Xml", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right"
            });
        }
        return isvalidxml;
    }
    IsValidXML(data) {
        let xmlDocument: any;
        let isValid = false;
        try {
            xmlDocument = (new DOMParser()).parseFromString(data, 'text/xml');
            isValid = true;
        }
        catch {
            xmlDocument = "";
            isValid = false;
        }
        return isValid;
    }
}

export class FileDatabase {
    dataChange = new BehaviorSubject<FileNode[]>([]);

    get data(): FileNode[] {
        return this.dataChange.value;
    }

    constructor(private previewDataJson: string) {
        this.initialize(this.previewDataJson);
    }

    initialize(json: string) { // Parse the string to json object.
        const dataObject = JSON.parse(json);
        delete dataObject["?xml"];
        // Build the tree nodes from Json object. The result is a list of `FileNode` with nested
        //     file node as children.
        const data = this.buildFileTree(dataObject, 0);

        // Notify the change.
        this.dataChange.next(data);
    }

    /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `FileNode`.
   */
    buildFileTree(obj: {
        [key: string]: any
    }, level: number): FileNode[] {

        return Object.keys(obj).reduce<FileNode[]>((accumulator, key) => {
            const value = obj[key];
            const node = new FileNode();
            node.filename = key;

            if (value != null) {
                if (typeof value === "object" && value != "") {
                    node.children = this.buildFileTree(value, level + 1);
                } else {
                    node.type = value;
                }
            }

            return accumulator.concat(node);
        }, []);
    }

}

export class FileNode {
    children: FileNode[];
    filename: string;
    type: any;
}

export interface routeprocessordata {
    data: any;
    displayname: string;
    sequence: Number;
    version: string;
    processorid: string;
}

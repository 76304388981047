import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import { TryitoutComponent } from '../tryitout/tryitout.component';
import { XmlmappingsComponent } from '../xmlmappings/xmlmappings.component';
declare const copyToClipboard: any;
declare var $;

@Component({
  selector: 'app-metricpipelinelist',
  templateUrl: './metricpipelinelist.component.html',
  styleUrls: ['./metricpipelinelist.component.scss']
})
export class MetricpipelinelistComponent implements OnInit {

  cardHeight: Number = window.innerHeight - 222;
  keywordsearch = "displayname";
  MessagetypeList: any = [];
  isLoadingpa: boolean = false;
  currendate: Date = new Date();
  selectedmessagetype: string = "";
  reactiveFormpa: FormGroup;
  notfounddata: boolean;
  today = new Date();
  constructor(private _fb: FormBuilder,
    private service: CommonService,
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.getMessagetypelist('msgtype');

    this.reactiveFormpa = _fb.group({ paname: '' });
    this.getmetricsreport();
  }
  screenHeight: number;
  userrole: string = '';
  pagerolepermisions: any = [];
  async ngOnInit() {
    this.screenHeight = window.innerHeight - 229;
    if (await this.service.checksession() == true) {
      this.getmetricsreport();
    }
  }




  MessageTypeListAll: any = [];

  async getMessagetypelist(event: any) {

    this.isLoadingpa = true;
    if (event !== 'msgtype') {

      if (event == undefined) {
        this.MessagetypeList = this.MessageTypeListAll;
      }
      else {
        if(this.MessagetypeList.length==0){
          this.MessagetypeList = this.MessageTypeListAll;
        }
        this.MessagetypeList = this.MessagetypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.MessagetypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingpa = false;
    }
    else {

      await this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: async data => {
          data.forEach(element => {
            this.MessagetypeList.push({ displayname: element });
          });
          this.MessageTypeListAll = this.MessagetypeList;
          this.isLoadingpa = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingpa = false;
          this.notfounddata = true;
        }
      });
    }

  }

  selectedmessagetypeevent(item) {
    this.selectedmessagetype = item.displayname;
  }



  Clearemessagetype(event) {
    this.isLoadingpa = false;
    this.MessagetypeList = this.MessageTypeListAll;
    this.notfounddata = false;
  }


  reset() {
    this.reactiveFormpa.reset();
    this.MessagetypeList = this.MessageTypeListAll;
    this.reactiveFormpa.setValue({ "paname": '' });
    this.notfounddata = false;
    this.fromdate = null;
    this.todate = null;
    this.selectedmessagetype = ''
    this.getmetricsreport();
  }


  copyToClip(event: any) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success('Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  copyToClips(event: any, message: string) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
  }

  fromdate: any;
  todate: any;
  metricspipelineList: any = [];
  async getmetricsreport() {
    this.metricspipelineList = [];
    if(this.fromdate=='' && this.todate=='')
    {
      this.fromdate=undefined;
      this.todate=undefined;
    }

    if ((this.fromdate && !this.todate) || (!this.fromdate && this.todate) || (this.fromdate=='' && this.todate=='')) {
      this.toastr.error("Select From date & To date ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    if (this.fromdate > this.todate) {
      this.toastr.error("From date should be  equal  or  less than To date ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    var searchinput = {
      "Messagetype": this.selectedmessagetype,
      "FromDate": (this.fromdate == undefined || this.fromdate == null) ? '' : this.fromdate + ' 00:00:00',
      "ToDate": (this.todate == undefined || this.todate == null) ? '' : this.todate + ' 23:59:59'
    }

    this.ngxUiLoaderService.start();
    let response = await this.service.searchpostapi("api/Metrics/GetTransactionsPipelineList", searchinput);
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    }
    else {
      this.ngxUiLoaderService.stop();
      // this.metricspipelineList = response as any[];
      this.metricspipelineList = this.sortArraybyObjValue(response, 'Total');
    }
  }

  sortArraybyObjValue(arr, value) {
    return arr.sort((a, b) => {
      return b[value] - a[value];
    })
  }


  exportmetricsreport() {
    let csvData = this.ConvertToCSV(this.metricspipelineList);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download",  "data.csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  ConvertToCSV(objArray) {

    let items = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let separator = ',';
    const columns = Object.keys(items[0]).join(separator);
    const body = items.map(item =>
      Object.values(item).join(separator)
    ).join('\n');

    return columns + '\n' + body;
  }
}

import { F } from "@angular/cdk/keycodes";
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  MatButtonToggleGroup,
  MatTable,
  MatTableDataSource,
} from "@angular/material";
import {
  MonacoEditorConstructionOptions,
  MonacoStandaloneCodeEditor,
} from "@materia-ui/ngx-monaco-editor";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { error } from "console";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { DiagnosisComponent } from "src/app/diagnosis/diagnosis.component";
import { CommonService } from "src/app/services/common.service";
import beautify from "xml-beautifier";
import { DataValidationComponent } from '../../Model/data-validation/data-validation.component';
import { data } from 'highcharts';

declare const copyToClipboard: any;
declare var $;

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
// const DEFINITIONS: ColumnDefintion[] = [
//   // width => width of the column
//   // left => width of the previous column
//   {label: 'Handler Status', },
//   {label: 'Rule Status', },
//   {label: 'CaseType'},
//   {label: 'Service'},
//   {label: 'LabId'},
//   {label: 'LabOrgId'},
//   {label: 'OrdFacilityId'},
//   {label: 'OrdFacOrgId'},
//   {label: 'OrdPhysician'},
//   {label: 'RefPhysician'},
//   {label: 'PathologistId'},
//   {label: 'PathAccountId'},
//   {label: 'CaseStatus'},
//   {label: 'ExOrdFacilityId'},
//   {label: 'ExServices'},
//   {label: 'ExCaseTypes'},

//   {label: 'EffectiveDate'},
//   {label: 'ExpiryDate'},
//   {label: 'TestMode'},
// ]

export interface ColumnDefintion {
  label: string;
  sticky?: boolean;
  style?: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
  { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
  { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
  { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
  { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
  { position: 6, name: "Carbon", weight: 12.0107, symbol: "C" },
  { position: 7, name: "Nitrogen", weight: 14.0067, symbol: "N" },
  { position: 8, name: "Oxygen", weight: 15.9994, symbol: "O" },
  { position: 9, name: "Fluorine", weight: 18.9984, symbol: "F" },
  { position: 10, name: "Neon", weight: 20.1797, symbol: "Ne" },
];
@Component({
  selector: "app-casemessage",
  templateUrl: "./casemessage.component.html",
  styleUrls: ["./casemessage.component.scss"],
})
export class CasemessageComponent implements OnInit {
  filterHandlerSearch: string = "";
  @Input() requisitionNumber: any = "";
  ftpfiledata: any = {};
  isFolderView: boolean = false;
  eventLogListBackup: any;
  MessagesList: any;
  selectedHandler: any = "";
  selectedInput: any;
  selectedOutput: any;
  selectedMessage: any;
  message_iot_data: Object;
  pagerolepermisions: any;
  formatType: string = "plaintext";
  MessageTraceData: any;
  messagereprocessdata: any[];
  IneventLogListBackup: any;
  selectedDirection: any;
  displaymessageid: any;
  selectedTabIndex = 0;
  searchTextValue: any = "";
  AfterChangeInlogsbackup: any[];
  AfterChangelogsbackup: any[];
  mediatypedisplay: boolean;
  selectedfilecontent: ArrayBufferLike;
  viewHL7Files: any;
  viewfileHandlerName: any;
  viewfileRoutguid: any;
  selectedMessageType: any;
  selectedStageData: any;
  msgObjforiot: any;
  casesruleinfo: any[] = [];
  varuleinfo: any[] = [];
  fixedcolomun = [
    "Indentifier",
    "DisplayHandlerStatus",
    "DisplayRuleStatus",
    "EffectiveDate",
    "ExpiryDate",
    "TestMode",
  ];
  ngZone: any;
  @ViewChild("MatTable", { static: false }) Table: MatTable<any>;

  // @ViewChild('matTable', { static: false }) set table(matTable: MatTable<any>) {
  //   if (matTable) {
  //     this.ngZone.onMicrotaskEmpty
  //       .pipe(take(3))
  //       .subscribe(() => matTable.updateStickyColumnStyles())
  //   }
  // }
  isTables: boolean = true;

  differenceColumns = [
    // { name: "DisplayHandlerStatus", label: "Handler Status" },
    // { name: "DisplayRuleStatus", label: "Rule Status" },
    // { name: "CaseType", label: "Case Type" },
    // { name: "Service", label: "Service" },
    // { name: "CaseId", label: "Case Id" },
    // { name: "LabAccountName", label: "Lab Name" },
    // { name: "LabOrgName", label: "Lab Org Name" },
  ];
  displayedColumns = this.differenceColumns;
  AllColumns = [
    { name: "Indentifier", label: "" },
    { name: "DisplayHandlerStatus", label: "Handler Status" },
    { name: "DisplayRuleStatus", label: "Rule Status" },
    { name: "CaseType", label: "Case Type" },
    { name: "Service", label: "Service" },
    { name: "CaseId", label: "Case Id" },
    { name: "LabAccountName", label: "Lab Name" },
    { name: "LabOrgName", label: "Lab Org Name" },
    { name: "OFAccountName", label: "Facility Name" },
    { name: "OrdFacOrgName", label: "Facility Org Name" },
    { name: "PhysicianUserName", label: "Ordering Physician" },
    { name: "RefPhysician", label: "Referring Physician" },
    { name: "PathologistUserName", label: "Pathologist" },
    { name: "PathOrgName", label: "Pathologist Org Name" },
    { name: "PathAccountName", label: "Pathologist Account Name" },
    { name: "CaseStatus", label: "Case Status" },
    { name: "ExOrdFacilityId", label: "Excluded Facilities" },
    { name: "ExServices", label: "Excluded Services" },
    { name: "ExCaseTypes", label: "Excluded CaseTypes" },
    { name: "EffectiveDate", label: "Effective Date" },
    { name: "ExpiryDate", label: "Expiry Date" },
    { name: "TestMode", label: "Test Mode " },
  ];
  // dataSource = ELEMENT_DATA;
  // COL_DEFINITIONS = DEFINITIONS;
  displayedColumnstab = this.displayedColumns.map((def) => def.name);
  // dataSource = ELEMENT_DATA;
  // displayedColumns: string[] = [
  //   'col1',
  //   'col2',
  //   'col3',
  //   'col4',
  //   'col5',
  //   'col6',
  //   'col7',
  //   'col8',
  //   'col9',
  //   'col10',
  //   'col11',
  //   'col12',
  //   'col13',
  //   'col14',
  //   'col15',
  //   'col16',
  //   'col17',
  //   'col18',
  // ];
  dataSource = new MatTableDataSource<TableData>(TABLE_DATA);
  Alldata: any[];

  // tables = [0];
  constructor(
    private service: CommonService,
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastr: ToastrService
  ) {
    // this.displayedColumns.length = 24;
    // this.displayedColumns.fill('filler');
    // // The first two columns should be position and name; the last two columns: weight, symbol
    // this.displayedColumns[0] = 'position';
    // this.displayedColumns[1] = 'name';
    // this.displayedColumns[22] = 'weight';
    // this.displayedColumns[23] = 'symbol';
  }

  // isSticky(buttonToggleGroup: MatButtonToggleGroup, id: string) {
  //
  //   if(buttonToggleGroup !== undefined) {
  //     return (buttonToggleGroup.value || []).indexOf(id) !== -1;
  //   }else{return true}

  // }

  isSticky(column: string): boolean {
    let wdir = this.fixedcolomun.filter((va) => va == column);
    if (wdir.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  isStickyEnd(column: string): boolean {
    let wdir = this.fixedcolomun.filter((va) => va == column);
    if (wdir.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  EventList = [];
  InEventList = [];
  IneventLogList = [];
  eventLogList = [];
  InboundeventLogList = [
    {
      eventName: "",
      logName: "test_fileid_SPC_TEST_DALLAS.LIS.result.Outbound",
      messageid: "a68f004c-e04f-4905-8f09-6fdbc2ebc155",
      messageStatus: "success",
      messageDate: "01/27/2023 10:34:21",
      messageMode: "LIVE",
      isActive: true,
    },
  ];
  iconsList = [];
  editorOptions: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: true,
    wordWrap: "on",
    minimap: {
      enabled: false,
    },
  };
  messageiotreprocess: boolean;
  backupinputdata: any;
  folderviewSubscribe: Subscription;

  ngOnChanges(changes: SimpleChanges) {
    this.EventList = [];
    this.InEventList = [];
    this.IneventLogList = [];
    this.eventLogList = [];
    if (this.requisitionNumber.ReqNumber !== undefined) {
      this.isFolderView = false;
      this.selectedHandler = "";
      this.selectedTabIndex = 0;
      setTimeout(() => {
        this.onTabChanged(0);
      });
      // this.InBoundList();
      // this.OutBoundList()
    } else {
      this.selectedTabIndex = 0;
    }
  }
  ngAfterViewInit() {
    this.selectedTabIndex = 0;
  }
  // ngAfterViewInit(): void {

  // }
  ngAfterViewChecked() {
    if (this.Table) {
      this.Table.updateStickyColumnStyles();
    }
    // this.selectedTabIndex = 0;
  }
  InBoundList() {
    this.requisitionNumber = {
      ...this.requisitionNumber,
      Direction: "Inbound",
    };
    this.ngxUiLoaderService.start();
    this.service.getInterfaceDetails360(this.requisitionNumber).subscribe(
      (data) => {
        this.ngxUiLoaderService.stop();
        if (data.MessageTypes !== null) {
          this.InEventList = [];
          data.MessageTypes.forEach((element, index) => {
            let obj = { name: element, isactive: index == 0 ? true : false };
            this.InEventList.push(obj);
          });
          this.IneventLogListBackup = JSON.parse(
            JSON.stringify(data.Pipelines)
          );
          this.IneventLogList = JSON.parse(JSON.stringify(data.Pipelines));
          this.InselectedEvent(0);
        }
      },
      (error) => {
        this.ngxUiLoaderService.stop();
        //console.error();
      }
    );
  }
  OutBoundList() {
    this.requisitionNumber = {
      ...this.requisitionNumber,
      Direction: "Outbound",
    };
    this.ngxUiLoaderService.start();
    this.service.getInterfaceDetails360(this.requisitionNumber).subscribe(
      (data) => {
        this.ngxUiLoaderService.stop();

        if (data.MessageTypes !== null) {
          this.EventList = [];
          data.MessageTypes.forEach((element, index) => {
            let obj = { name: element, isactive: index == 0 ? true : false };
            this.EventList.push(obj);
          });
          this.eventLogListBackup = JSON.parse(JSON.stringify(data.Pipelines));
          this.eventLogList = JSON.parse(JSON.stringify(data.Pipelines));
          this.selectedEvent(0);
        }
      },
      (error) => {
        // console.error(error);
        this.ngxUiLoaderService.stop();
      }
    );
  }

  ngOnInit() {
    // this.rolepermission()
    this.folderviewSubscribe = this.service.viewfileview.subscribe((data) => {
      if (data) {
        this.isFolderView = false;
      } else {
        this.isFolderView = true;
      }
    });
  }

  getMatValue(column, i) {
    ;
    if (this.dataSource.data.length - 1 !== i) {
      switch (column) {
        case "LabAccountName":
          return this.dataSource.data[i]["LabId"];
          break;
        case "LabOrgName":
          return this.dataSource.data[i]["LabOrgId"];
          break;
        case "OFAccountName":
          return this.dataSource.data[i]["OrdFacilityId"];
          break;

        default:
          break;
      }
    } else {
      return this.dataSource.data[i][column];
    }
  }

  ChangesTable(event: any) {
    if (this.isTables) {
      this.displayedColumns = this.differenceColumns;
      this.dataSource.data = this.Alldata;
    } else {
      this.displayedColumns = this.AllColumns;
      this.dataSource.data = this.Alldata;
    }
    this.displayedColumnstab = this.displayedColumns.map((def) => def.name);
  }

  openDiagnostics() {
    this.ngxUiLoaderService.start();
    const modalRef = this.modalService.open(DiagnosisComponent, {
      windowClass: "myCustomModalClassFTP",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.routeGuid = this.selectedHandler.RouteGuid;
    modalRef.componentInstance.DisplaynameRoute =
      this.selectedHandler.RouteName;
    modalRef.result.then((result) => { });
  }
  openFolder(item) {
    this.isFolderView = true;
    this.ftpfiledata = {
      // routeId: '4C962871-95B9-47A6-9BAF-5AF5832E8BFA',
      // routeId: 'F763DDC4-3E39-4CB7-973D-2AFF4C626BB2',
      // sourceagent: '9598900F-6681-4BDB-9B3B-CE6CA49C2D57',
      routeId: this.selectedHandler.RouteGuid,
      sourceagent: this.selectedHandler.SourceAgentId,
      name: this.selectedHandler.RouteName,
      uniqid: this.selectedHandler.RouteUniqueId,
      selectflag: false,
      case360: true,
    };
  }
  reprocessMessages360Stage(id) {
    let obj = {
      stageid: id,
      modifiedby: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
        .userdata.userid,
    };
    this.service.reprocessMessages360Stage(obj).subscribe(
      (response) => {
        if (response["status"] && response["statusText"]) {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Reprocess failed", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          this.ngxUiLoaderService.stop();
          if (response["status"] == "failure") {
            this.toastr.error(response["statusmessage"], "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          } else {
            if (this.selectedDirection == "in") {
              this.InBoundList();
              // let ins = this.IneventLogList.findIndex(
              //   (va) => va.RouteName == this.selectedHandler.RouteName
              // );
              // if (ins != -1) {
              //   this.selectedEventCard(ins, this.selectedDirection);
              // } else {
              //   this.selectedEventCard(0, this.selectedDirection);
              // }
            } else {
              this.OutBoundList();
              // let ins = this.eventLogList.findIndex(
              //   (va) => va.RouteName == this.selectedHandler.RouteName
              // );
              // if (ins != -1) {
              //   this.selectedEventCard(ins, this.selectedDirection);
              // } else {
              //   this.selectedEventCard(0, this.selectedDirection);
              // }
            }
            this.toastr.success("Redistributed successfully", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          }
        }
      },
      (error) => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }
  rolepermission() {
    const menuid = JSON.parse(sessionStorage.getItem("rolewithmenus"))
      .find((x) => x.RoleName == sessionStorage.getItem("SelectedUserRole"))
      .menuslist.find((y) => y.MenuDisplayName == "MESSAGES").MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem("rolewithmenus"))
      .find((x) => x.RoleName == sessionStorage.getItem("SelectedUserRole"))
      .menuslist.filter((y) => y.ParentMenuId == menuid);
    actionitems.forEach((element) => {
      this.pagerolepermisions[element.MenuName] = true;
    });
  }
  closemessageiotpopup(c) {
    // this.editorOptions1.readOnly = true;
    // this.messageiotreprocess = false;
    // this.viewHL7Files = "";
    c("close modal");
  }
  ViewFileData(item: any, modalmessageiot) {
    this.ngxUiLoaderService.start();
    let obj = {
      LogId: this.selectedHandler.LastMessage.TransactionId,
      Deploymentkey: this.requisitionNumber.DeploymentKey,
    };
    this.service.viewFilesCase_hanlder(obj).subscribe(
      (data) => {
        if (data.status == "failure") {
          this.ngxUiLoaderService.stop();
          this.toastr.warning("File not available", "", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        } else {
          let modalsize = "";
          this.ngxUiLoaderService.stop();
          data.responsebody = JSON.parse(data.responsebody);
          if (
            data.responsebody !== null &&
            data.responsebody.filecontent !== null
          ) {
            if (data.responsebody.fileformat == "pdf") {
              modalsize = "viewpdffile";
              this.mediatypedisplay = false;
              this.selectedfilecontent = this._base64ToArrayBuffer(
                data.responsebody.filecontent
              );
            } else {
              this.mediatypedisplay = true;
              modalsize = "viewfiles";
              this.viewHL7Files = data.responsebody.filecontent;
            }
            this.viewfileHandlerName = this.selectedHandler.RouteName;
            this.viewfileRoutguid = this.selectedHandler.RouteGuid;
            this.modalService
              .open(modalmessageiot, {
                windowClass: modalsize,
                backdrop: "static",
                keyboard: false,
              })
              .result.then((result) => { });
          } else {
            this.ngxUiLoaderService.stop();
            this.toastr.warning("Data not Found", "Alert", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
          }
        }
      },
      (error) => {
        this.ngxUiLoaderService.stop();
        this.toastr.error(error.statusText, "Failed", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
    );
  }
  _base64ToArrayBuffer(base64) {
    var binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  closemoreinfopopup(c) {
    c("close modal");
  }
  closeModal(c) {
    c("close modal");
  }
  selectedEvent(index: number) {
    if (this.EventList.length > 0) {
      this.EventList.map((va) => (va.isactive = false));
      this.EventList[index].isactive = true;
      let name =
        this.EventList[index] !== undefined
          ? this.EventList[index].name.toLowerCase()
          : "";
      this.selectedMessageType =
        this.EventList[index] !== undefined
          ? this.EventList[index].name.toLowerCase()
          : "";
      if (this.eventLogListBackup.length > 0) {
        switch (name) {
          case "all":
            if (this.searchTextValue === "") {
              this.eventLogList = this.eventLogListBackup;
            } else if (this.AfterChangelogsbackup !== undefined) {
              this.eventLogList = this.AfterChangelogsbackup;
            }
            break;
          case "failed":
            if (this.searchTextValue === "") {
              this.eventLogList = this.eventLogListBackup.filter(
                (log) => log.MessageFailedUniqueIds !== ""
              );
            } else if (this.AfterChangelogsbackup !== undefined) {
              this.eventLogList = this.AfterChangelogsbackup.filter(
                (log) => log.MessageFailedUniqueIds !== ""
              );
            }
            break;
          default:
            if (this.searchTextValue === "") {
              this.eventLogList = this.eventLogListBackup.filter(
                (va) =>
                  va.MessageType == this.EventList[index].name.toLowerCase()
              );
            } else if (this.AfterChangelogsbackup !== undefined) {
              this.eventLogList = this.AfterChangelogsbackup.filter(
                (va) =>
                  va.MessageType == this.EventList[index].name.toLowerCase()
              );
            }
            break;
        }
        this.selectedEventCard(0, "out");
      } else {
        this.selectedHandler = "";
        this.MessagesList = [];
      }
    }
  }
  InselectedEvent(index: number) {
    if (this.InEventList.length > 0) {
      this.InEventList.map((va) => (va.isactive = false));
      this.InEventList[index].isactive = true;
      let name =
        this.InEventList[index] !== undefined
          ? this.InEventList[index].name.toLowerCase()
          : "";
      this.selectedMessageType =
        this.InEventList[index] !== undefined
          ? this.InEventList[index].name.toLowerCase()
          : "";
      switch (name) {
        case "all":
          if (this.searchTextValue === "") {
            this.IneventLogList = this.IneventLogListBackup;
          } else if (this.AfterChangeInlogsbackup !== undefined) {
            this.IneventLogList = this.AfterChangeInlogsbackup;
          }
          break;
        case "failed":
          if (this.searchTextValue === "") {
            this.IneventLogList = this.IneventLogListBackup.filter(
              (log) => log.MessageFailedUniqueIds !== ""
            );
          } else if (this.AfterChangeInlogsbackup !== undefined) {
            this.IneventLogList = this.AfterChangeInlogsbackup.filter(
              (log) => log.MessageFailedUniqueIds !== ""
            );
          }
          break;
        default:
          if (this.searchTextValue === "") {
            this.IneventLogList = this.IneventLogListBackup.filter(
              (va) =>
                va.MessageType == this.InEventList[index].name.toLowerCase()
            );
          } else if (this.AfterChangeInlogsbackup !== undefined) {
            this.IneventLogList = this.AfterChangeInlogsbackup.filter(
              (va) =>
                va.MessageType == this.InEventList[index].name.toLowerCase()
            );
          }
          break;
      }
      this.selectedEventCard(0, "in");
    }
  }
  download() {
    this.ngxUiLoaderService.start();
    this.downloadFile(
      this.MessageTraceData,
      this.MessageTraceData[0]["traceid"]
    );
    this.ngxUiLoaderService.stop();
  }
  getmessages(obj) {
    if (obj.MessageUniqueIds != "") {
      this.ngxUiLoaderService.start();
      this.service.getInterfaceDetailsMessages360(obj).subscribe(
        (data) => {
          this.ngxUiLoaderService.stop();
          if (data.length > 0) {
            this.MessagesList = data;
          } else {
            this.MessagesList = [];
          }
        },
        (error) => {
          this.ngxUiLoaderService.stop();
          this.MessagesList = [];
        }
      );
    } else {
      this.MessagesList = [];
    }
  }
  selectedEventCard(index: number, direction) {
    this.selectedDirection = direction;
    this.isFolderView = false;
    this.selectedHandler = "";
    if (direction == "in") {
      if (this.IneventLogList !== undefined && this.IneventLogList.length > 0) {
        this.IneventLogList.map((va) => (va.isActive = false));
        this.IneventLogList[index].isActive = true;
        this.selectedHandler = this.IneventLogList[index];

        let obj = {
          MessageUniqueIds: "",
        };
        if (this.selectedMessageType == "failed") {
          obj.MessageUniqueIds = this.selectedHandler.MessageFailedUniqueIds;
        } else {
          obj.MessageUniqueIds = this.selectedHandler.MessageUniqueIds;
        }
        this.getmessages(obj);
      }
    } else {
      this.isTables = true;
      if (this.eventLogList !== undefined && this.eventLogList.length > 0) {
        this.eventLogList.map((va) => (va.isActive = false));
        this.eventLogList[index].isActive = true;
        this.selectedHandler = this.eventLogList[index];
      }
      if (
        this.eventLogList[index] !== undefined &&
        this.eventLogList[index].Qualified
      ) {
        let obj = {
          MessageUniqueIds: "",
        };
        if (this.selectedMessageType == "failed") {
          obj.MessageUniqueIds = this.selectedHandler.MessageFailedUniqueIds;
        } else {
          obj.MessageUniqueIds = this.selectedHandler.MessageUniqueIds;
        }
        this.getmessages(obj);
      } else {
        if (direction == "out") {
          this.getRules();
        }
      }

    }

  }
  checkvalue(value, index) {

    switch (value) {
      case "DisplayHandlerStatus":
        if (this.dataSource.data[index][value] == "Active") {
          return "text_green _600";
        } else {
          return "text-danger _600";
        }
        break;
      case "DisplayRuleStatus":
        if (this.dataSource.data[index][value] == "Active") {
          return "text_green _600";
        } else {
          return "text-danger _600";
        }
        break;
      case "Indentifier":
        return "_600";
        break;
      case "DisplayRuleStatus":
        if (this.dataSource.data[index][value] == "Active") {
          return "text_green _600";
        } else {
          return "text-danger _600";
        }
        break;

      default:
        if (
          this.dataSource.data[this.dataSource.data.length - 1][value] !==
          undefined && index !== this.dataSource.data.length - 1) {
          let lastvalue = ''

          if (this.dataSource.data[this.dataSource.data.length - 1][value].includes("(")) {
            let splitedData = this.dataSource.data[this.dataSource.data.length - 1][value].split("(")
            if (splitedData.length) {
              lastvalue = splitedData[1].split(")")[0]
            }
          }
          else {
            lastvalue = this.dataSource.data[this.dataSource.data.length - 1][value]
          }

          let objex = this.displayedColumns.find(va => va.name === value);
          let labelValue = objex !== undefined ? objex.label : value;
          const valuearray: string[] = this.dataSource.data[index][value].split(',');


          if (lastvalue !== '' && !labelValue.includes("Excluded") &&
            //lastvalue !== this.dataSource.data[index][value])
            !valuearray.includes(lastvalue)) {
            if (this.dataSource.data[index][value] !== "All") {
              return " mismatch text-danger _600";
            }
          }
          else {
            if (lastvalue !== '') {
              if (labelValue.includes("Excluded") &&
                //lastvalue == this.dataSource.data[index][value]
                valuearray.includes(lastvalue)
                || this.dataSource.data[index][value] === "All") {
                return " mismatch text-danger _600";
              }
            }
          }
        }

        break;
    }
  }
  getRules() {
    if (this.selectedHandler !== undefined) {
      let obj = {
        DeploymentKey: this.requisitionNumber.DeploymentKey,
        CaseId: this.requisitionNumber.caseid,
        RuleIds: this.selectedHandler.RuleInfo !== undefined ? this.selectedHandler.RuleInfo.map((a) => a.RuleId) : [],
      };
      this.dataSource.data = [];
      this.ngxUiLoaderService.start();
      this.service.getInterfaceDetailsRule(obj).subscribe((data) => {
        this.ngxUiLoaderService.stop();

        if (data.status == "success") {
          this.ngxUiLoaderService.stop();

          this.casesruleinfo = JSON.parse(data.responsebody).casesruleinfo;
          if (this.casesruleinfo.length > 0) {
            this.casesruleinfo.forEach((va) => {
              va['ExOrdFacilityId'] = va.OFAccountName,
                va['ExServices'] = va.Service,
                va['ExCaseTypes'] = va.CaseType
              va["Indentifier"] = "Case"
            });
          }
          this.varuleinfo = JSON.parse(data.responsebody).varuleinfo;
          if (this.varuleinfo.length > 0) {
            this.varuleinfo.forEach((va) => {
              va["Indentifier"] = "Rule";
              (va["LabAccountName"] = va["LabId"]),
                (va["LabOrgName"] = va["LabOrgId"]),
                (va["OFAccountName"] = va["OrdFacilityId"]),
                (va["OrdFacOrgName"] = va["OrdFacOrgId"]),
                (va["PhysicianUserName"] = va["OFUserId"]),
                (va["PathologistUserName"] = va["PathologistId"]),
                (va["PathOrgName"] = va["PathOrgId"]),
                (va["PathAccountName"] = va["PathAccountId"]);
            });
          }
          let ruleData = this.varuleinfo.concat(this.casesruleinfo);
          ruleData.map((va) => {
            (va.ExOrdFacilityId = va.ExOrdFacilityId == va.OrdFacilityId ? va.OFAccountName : va.ExOrdFacilityId),
              (va.ExServices = va.ExServices === va.Service ? va.Service : va.ExServices),
              (va.ExCaseTypes = va.ExCaseTypes === va.CaseType ? va.CaseType : va.ExCaseTypes);
            // (va.ExOrdFacilityId = va.OFAccountName),
            //   (va.ExServices = va.Service),
            //   (va.ExCaseTypes = va.CaseType);
          });

          this.Alldata = ruleData;
          this.displayedColumns = this.AllColumns;
          this.dataSource = new MatTableDataSource(ruleData);
          this.getunmatch(ruleData);
        }
      }, error => {
        this.ngxUiLoaderService.stop();

      });
    }
  }

  getunmatch(Casedata) {
    this.differenceColumns = [];
    for (let i = 0; i < this.AllColumns.length; i++) {
      let colName = this.AllColumns[i].name;
      for (let j = 0; j < Casedata.length; j++) {
        if (Casedata.length - 1 !== j) {
          let lastvalue = ''

          if (Casedata[Casedata.length - 1][colName] !== undefined &&
            Casedata[Casedata.length - 1][colName].includes("(")) {
            let splitedData = Casedata[Casedata.length - 1][colName].split("(")
            if (splitedData.length) {
              lastvalue = splitedData[1].split(")")[0]
            }
          }
          else {
            lastvalue = Casedata[Casedata.length - 1][colName]
          }
          if (Casedata[j][colName] !== undefined) {
            //console.log(Casedata[j][colName])
            const valuearray: string[] = (Casedata[j][colName] !== '' && Casedata[j][colName] !== undefined) ? Casedata[j][colName].split(',') : '';

            if (!this.AllColumns[i].label.includes("Excluded") &&
              Casedata[j][colName] !== "All" &&
              // lastvalue !== Casedata[j][colName]
              !valuearray.includes(lastvalue)
            ) {
              let exist = [];
              if (this.differenceColumns.length > 0) {
                exist = this.differenceColumns.filter(
                  (va) => va.name === this.AllColumns[i].name
                );
              }
              if (exist.length == 0) {
                this.differenceColumns.push(this.AllColumns[i]);
              }
            }
            else {
              if ((this.AllColumns[i].label.includes("Excluded") &&
                lastvalue == Casedata[j][colName]
                // valuearray.includes(lastvalue)
                && Casedata[j][colName] !== '') ||
                (this.AllColumns[i].label.includes("Excluded") && Casedata[j][colName] === "All")) {
                let exist = [];
                if (this.differenceColumns.length > 0) {
                  exist = this.differenceColumns.filter(
                    (va) => va.name === this.AllColumns[i].name
                  );
                }
                if (exist.length == 0) {
                  this.differenceColumns.push(this.AllColumns[i]);
                }
              }
            }
          }
        }
      }
    }
    ;
    if (this.differenceColumns.length > 0) {
      this.displayedColumnstab = this.differenceColumns.map((def) => def.name);
    }
  }

  CheckValidationStauts(item) {
    if (item.status == '11' || item.status == '13') {
      if(item.DeliveredStageOutputData.includes("Ignored") || item.DeliveredStageOutputData.includes("Fail")){
        return false;
      }
      else{
        return true;
      }
    }
    else {
      return false;
    }
  }

  OnValidation(uniq) {

    let DataValidationInputs: any = []
    let obj = {
      CaseId: this.requisitionNumber.caseid.toString(),
      MessageID: uniq.messageid,
      Deployment: this.requisitionNumber.DeploymentKey,
      Direction: this.requisitionNumber.Direction,
      MessageType: uniq.messagetype
   
    }
    DataValidationInputs.push(obj);
    //console.log(DataValidationInputs);
    this.ngxUiLoaderService.start();
    this.service.dataValidationapi(DataValidationInputs).subscribe((data: any) => {
     //console.log("output data from data validation api is :: " + JSON.stringify(data))
      this.ngxUiLoaderService.stop();
      const modalRef = this.modalService.open(DataValidationComponent, {
        windowClass: "Mode_Boot",
        backdrop: "static",
        keyboard: false,
      });
      modalRef.componentInstance.inputDataInModalComponent = data
      modalRef.result.then((result) => { });
    },
      (error) => {
     //   console.log("Error occured while calling data validation api. error is :: " + error)
        this.toastr.warning("Error occured while calling data validation api", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.ngxUiLoaderService.stop();
      }
    );
  }

  openMessage(modalrefid, uniq) {
    let obj = {
      MessageUniqueIds: uniq.id,
    };
    this.selectedMessage = uniq;
    this.ngxUiLoaderService.start();
    this.service.getInterfaceDetailsMessages360Stage(obj).subscribe(
      (data) => {
        this.ngxUiLoaderService.stop();
        if (data.length > 0) {
          data = data.filter((va) => va.stagename.toLowerCase() !== "accepted");
          this.iconsList = data.sort((a, b) => a.stage - b.stage);
          this.modalService
            .open(modalrefid, {
              windowClass: "viewstages",
              backdrop: "static",
              keyboard: false,
            })
            .result.then((result) => { });
          this.iconsList.map((icon, index) => {
            icon["isActive"] = index === 0 ? true : false;
          });
          this.transformedStage(0);
        } else {
          this.toastr.warning("Stage data not found", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      },
      (error) => {
        this.ngxUiLoaderService.stop();
      }
    );
  }
  downloadcontent(filenameatstage) {
    var apiname =
      "api/Routes/GetFileData?fileID=" +
      this.selectedStageData.fileid +
      "&contextId=" +
      this.selectedStageData.id +
      "&createdby=" +
      JSON.parse(sessionStorage.getItem("sessionObjectlogin")).userdata[
      "userid"
      ];
    this.service.getapiurlcontent(apiname).subscribe({
      next: (data123) => {
        this.ngxUiLoaderService.stop();
        if (data123["statusmessage"] != "") {
          var fileDetails = JSON.parse(data123["statusmessage"]);
          let filename = "";
          if (filenameatstage !== "") {
            filename = filenameatstage;
          } else if (fileDetails.hasOwnProperty("filename")) {
            filename = fileDetails.filename;
          }
          if (filename == "") {
            filename = atob(this.selectedStageData.fileid);
          }
          if (
            typeof fileDetails == "object" &&
            fileDetails.hasOwnProperty("contenttype")
          ) {
            let contentType = fileDetails.contenttype;
            switch (contentType) {
              case "application/xml":
                filename += ".xml";
                break;
              case "application/json":
                filename += ".js";
                break;
              case "application/pdf":
                filename += ".pdf";
                break;
              default:
                filename += ".txt";
                break;
            }
          }
          if (filename != "" && filename.indexOf(".") == -1) {
            filename += ".txt";
          }
          var xmlDocument;
          try {
            xmlDocument = new DOMParser().parseFromString(
              data123.responsebody,
              "text/xml"
            );
          } catch {
            xmlDocument = "";
          }
          if (xmlDocument) {
            data123.responsebody = beautify(data123.responsebody);
          }
          const file = new window.Blob([data123.responsebody], {
            type: "application/octet-stream",
          });
          const downloadAncher = document.createElement("a");
          const fileURL = URL.createObjectURL(file);
          downloadAncher.href = fileURL;
          downloadAncher.download = filename;
          downloadAncher.click();
        }
      },
      error: (error) => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Failure to download file", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      },
    });
  }
  openstagedata(item: any) { }
  transformedStage(index: number) {
    this.iconsList.map((va) => (va.isActive = false));
    this.iconsList[index].isActive = true;
    if (this.iconsList[index] != "") {
      this.selectedStageData = this.iconsList[index];
      this.selectedInput = this.iconsList[index].stageinput;
      this.selectedOutput = this.iconsList[index].outputdata;
    } else {
      this.selectedInput = "";
      this.selectedOutput = "";
    }
  }
  copyToClip(event: any, message: string) {
    if (event != "") {
      copyToClipboard(event);
      this.toastr.success(message, "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }
  GetTraceData(mymodalstagelogs, transactionid, traceid, messageid) {
    this.ngxUiLoaderService.start();
    var url =
      "api/HubMessage/GetTraceData?txnid=" +
      transactionid +
      "&traceid=" +
      traceid;
    this.service.getapiurl(url).subscribe({
      next: (data) => {
        if (data.length > 0) {
          this.MessageTraceData = data as any;
          this.displaymessageid = messageid;
          if (mymodalstagelogs != "") {
            this.modalService
              .open(mymodalstagelogs, {
                windowClass: "myCustomModalClass",
                backdrop: "static",
                keyboard: false,
              })
              .result.then((result) => { });
          } else {
            this.downloadFile(data, data[0]["traceid"]);
          }
        } else {
          this.toastr.warning(
            "There is no logs in this Message ID :" + " " + messageid,
            "",
            {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            }
          );
        }
        this.ngxUiLoaderService.stop();
      },
      error: (error) => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Failure to download logs", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      },
    });
  }
  copyToClips(event: any, message: string) {
    copyToClipboard(event);
    this.toastr.success(message + " " + "Copied", "", {
      timeOut: 4000,
      positionClass: "toast-bottom-right",
    });
  }
  closestagepopup(c) {
    c("close modal");
  }
  searchFilter(event) {
    if (event.target.value !== undefined) {
      this.searchTextValue = event.target.value;
      this.selectedHandler = "";
      this.MessagesList = [];
      if (this.selectedTabIndex == 0) {
        this.InEventList.map((va) => (va.isactive = false));
        this.InEventList[0].isactive = true;
        this.IneventLogListBackup.map((va) => (va.isActive = false));
        this.IneventLogList = this.IneventLogListBackup.filter((va) =>
          va.RouteName.toLowerCase().includes(
            this.searchTextValue.toLowerCase()
          )
        );
        this.AfterChangeInlogsbackup = JSON.parse(
          JSON.stringify(this.IneventLogList)
        );
      } else {
        this.EventList.map((va) => (va.isactive = false));
        this.EventList[0].isactive = true;
        this.eventLogListBackup.map((va) => (va.isActive = false));
        this.eventLogList = this.eventLogListBackup.filter((va) =>
          va.RouteName.toLowerCase().includes(
            this.searchTextValue.toLowerCase()
          )
        );
        this.AfterChangelogsbackup = JSON.parse(
          JSON.stringify(this.eventLogList)
        );
      }
    }
  }
  downloadFile(data, filename = "data") {
    let csvData = this.ConvertToCSV(data, [
      "agentid",
      "messageid",
      "method",
      "routedefinitionid",
      "messagetype",
      "senderagentid",
      "sourceentityid",
      "destinationentityid",
      "destinationagentid",
      "response",
      "createddate",
      "fileid",
      "invokedurl",
      "stage",
      "hash",
      "response",
      "processingparam",
      "status",
      "processingtime",
    ]);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  ConvertToCSV(objArray, headerList) {
    let items = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let separator = ",";
    const columns = Object.keys(items[0]).join(separator);
    const body = items
      .map((item) => Object.values(item).join(separator))
      .join("\n");
    return columns + "\n" + body;
  }
  getmessage_iot(value, modalmessageiot) {
    this.ngxUiLoaderService.start();
    this.msgObjforiot = value;
    this.service
      .message_IOTData(this.msgObjforiot["id"], this.msgObjforiot["id"])
      .subscribe(
        (data) => {
          if (data) {
            this.message_iot_data = data;
            this.message_iot_data["title"] = this.msgObjforiot["messageid"];
            if (this.message_iot_data["InputData"]) {
              if (
                this.service.IsJsonString(this.message_iot_data["InputData"])
              ) {
                this.message_iot_data["InputData"] = JSON.stringify(
                  JSON.parse(this.message_iot_data["InputData"]),
                  null,
                  2
                );
              } else if (this.IsValidXML(this.message_iot_data["InputData"])) {
                this.message_iot_data["InputData"] = beautify(
                  this.message_iot_data["InputData"]
                );
              }
            }
            if (this.message_iot_data["TransformedData"]) {
              if (
                this.service.IsJsonString(
                  this.message_iot_data["TransformedData"]
                )
              ) {
                this.message_iot_data["TransformedData"] = JSON.stringify(
                  JSON.parse(this.message_iot_data["TransformedData"]),
                  null,
                  2
                );
              } else if (
                this.IsValidXML(this.message_iot_data["TransformedData"])
              ) {
                this.message_iot_data["TransformedData"] = beautify(
                  this.message_iot_data["TransformedData"]
                );
              }
            }
            this.ngxUiLoaderService.stop();
            this.modalService
              .open(modalmessageiot, {
                windowClass: "messageiot",
                backdrop: "static",
                keyboard: false,
              })
              .result.then((result) => { });
          }
        },
        (error) => {
          this.ngxUiLoaderService.stop();
          this.toastr.error(error.statusText, "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      );
  }
  editorOptions1: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    readOnly: true,
    wordWrap: "on",
    minimap: {
      enabled: false,
    },
  };
  editor: MonacoStandaloneCodeEditor;
  editorInit(data, editor: MonacoStandaloneCodeEditor, type) {
    this.editor = editor;
    let formatType;
    editor.onDidChangeCursorPosition(() => {
      if (this.IsValidXML(data)) {
        formatType = "xml";
      } else if (this.service.IsJsonString(data)) {
        formatType = "json";
      } else {
        formatType = "hl7";
      }
      if (type == "input") {
        this.editorOptions1 = { ...this.editorOptions1, language: formatType };
      } else {
        this.editorOptions = { ...this.editorOptions, language: formatType };
      }
    });
  }
  editmessageiot(data) {
    this.messageiotreprocess = true;
    this.backupinputdata = data;
    this.editorOptions1.readOnly = false;
  }
  resetmessageiot() {
    this.messageiotreprocess = false;
    this.message_iot_data["InputData"] = this.backupinputdata;
    this.editorOptions1.readOnly = true;
  }
  IsValidXML(data) {
    let xmlDocument: any;
    let isValid = false;
    try {
      xmlDocument = new DOMParser().parseFromString(data, "text/xml");
      isValid = true;
    } catch {
      xmlDocument = "";
      isValid = false;
    }
    return isValid;
  }
  reprocessSelected(value) {
    // if (value.selected.length > 0) {
    //   this.checkeddata = value.selected;
    this.messagereprocessdata = [];
    // this.checkeddata.forEach(element => {
    var dataCons = {
      id: value.id,
      transactionid: value.transactionid,
      sourceagentapikey: value.sourceagentapikey,
      sourceagentid: value.sourceagentid,
      sourceentityid: value.sourceentityid,
      destinationentityid: value.destinationentityid,
      messagetype: value.messagetype,
      mode: value.mode,
      messageid: value.messageid,
      parentmessageid: value.parentmessageid,
      format: value.format,
      fileid: value.messagepayloadreference,
      routedefinitionid: value.routedefinitionid,
      verifyroute: "true",
      modifiedby: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
        .userdata["userid"],
      parentfileid: value.parentfileid,
      parentfilename: value.parentfilename,
    };
    this.messagereprocessdata.push(dataCons);
    // });
    this.ngxUiLoaderService.start();
    var apiname = "api/HubMessage/BatchMessageReprocess";
    this.service.searchpostapi(apiname, this.messagereprocessdata).then(
      (response) => {
        if (response["status"] && response["statusText"]) {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Reprocess failed", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          this.ngxUiLoaderService.stop();
          if (response["status"] == "failure") {
            this.toastr.error(response["statusmessage"], "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          } else {
            if (this.selectedDirection == "in") {
              this.InBoundList();
              // let ins = this.IneventLogList.findIndex(
              //   (va) => va.RouteName == this.selectedHandler.RouteName
              // );
              // if (ins != -1) {
              //   this.selectedEventCard(ins, this.selectedDirection);
              // } else {
              //   this.selectedEventCard(0, this.selectedDirection);
              // }
            } else {
              this.OutBoundList();
              // let ins = this.eventLogList.findIndex(
              //   (va) => va.RouteName == this.selectedHandler.RouteName
              // );
              // if (ins != -1) {
              //   this.selectedEventCard(ins, this.selectedDirection);
              // } else {
              //   this.selectedEventCard(0, this.selectedDirection);
              // }
            }
            this.toastr.success("Reprocessed successfully", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          }
        }
      },
      (error) => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
    // this.selection.clear();
    // this.SearchMessages();
    // }
  }
  onTabChanged(value) {
    this.selectedHandler = "";
    if (value.index !== undefined) {
      this.selectedTabIndex = value.index;
    } else {
      this.selectedTabIndex = value;
    }
    if (this.selectedTabIndex == 0) {
      this.InBoundList();
    } else {
      this.OutBoundList();
    }
  }
  borderClass(value) {
    switch (value) {
      case 11:
        return "active_card_bor messageInfo box m-0";
        break;
      case 12:
        return "failed_card_bor messageInfo box m-0";
        break;
      case 10:
        return "progress_card_bor messageInfo box m-0";
        break;
      default:
        return " messageInfo box m-0";
        break;
    }
  }
  ngOnDestory() {
    this.folderviewSubscribe.unsubscribe();
  }
}

// TODO: This data stuff should be a service
export interface TableData {
  col1: string;
  col2: string;
  col3: string;
  col4: string;
  col5: string;
  col6: string;
  col7: string;
  col8: string;
  col9: string;
  col10: string;
  col11: string;
  col12: string;
  col13: string;
  col14: string;
  col15: string;
  col16: string;
  col17: string;
  col18: string;
}

const TABLE_DATA: TableData[] = [
  {
    col1: "val",
    col2: "val",
    col3: "val",
    col4: "val",
    col5: "val",
    col6: "val",
    col7: "val",
    col8: "val",
    col9: "val",
    col10: "val",
    col11: "val",
    col12: "val",
    col13: "val",
    col14: "val",
    col15: "val",
    col16: "val",
    col17: "val",
    col18: "val",
  },
];

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CreatejobComponent } from '../createjob/createjob.component';
import { JobpropertiesComponent } from '../jobproperties/jobproperties.component';
import { CommonService } from '../services/common.service';
import { W700servicesService } from '../services/w700services.service';

@Component({
  selector: 'app-w700jobslist',
  templateUrl: './w700jobslist.component.html',
  styleUrls: ['./w700jobslist.component.scss']
})
export class W700jobslistComponent implements OnInit {

  screenHeight: number;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private service: CommonService,
    private w700service: W700servicesService,
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
  ) { }
  classw700: string = '';
  deploymentkey: string = '';
  searchText: string = '';
  usernamedisplay: string;
  userrole: string = '';
  isDeployement: string = "";
  pagerolepermisions: any = '';
  keywordsearch_deployment ="name";

  deployemntlist: any = [];
  ngOnInit() {
    this.screenHeight = window.innerHeight - 229;
    this.getdeploymentconfiguration()
    // this.classw700 = 'mat-list-item ng-star-inserted active';
    // if (await this.service.checksession() == true) {
    //   var rolepermissinslist = JSON.parse(sessionStorage.getItem('rolepermissions'));
    //   this.pagerolepermisions = rolepermissinslist.filter(c => c.module == 'w700')[0];
    // }

    // if (this.router.url.includes("w700jobslist")) {
    //   this.classw700 = 'mat-list-item ng-star-inserted active';
    // }
    // else {
    //   this.classw700 = '';
    // }

    // if (await this.service.checksession() == true) {
    //   this.usernamedisplay = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['FormattedDisplayName'];
    //   this.userrole = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['role'];
    //   var rolepermissinslist = JSON.parse(sessionStorage.getItem('rolepermissions'));
    //   this.pagerolepermisions = rolepermissinslist.filter(c => c.module == 'w700')[0];
    //   if (this.pagerolepermisions != undefined) {
    //     this.route.queryParams.subscribe(
    //       params => {
    //         this.deploymentkey = params['deploymentkey'];
    //       }
    //     )
    //   }
    // }
  }

  getdeploymentconfiguration() {
    this.ngxUiLoaderService.start()
    var apiname = "api/CoreConfig/GetProcessorUIConfig?agentid=&apikey=";
    this.service.getapi(apiname).then(data => {
      if (data) {
        this.ngxUiLoaderService.stop()
        var configs = JSON.parse(data['responsebody']);
        this.deployemntlist = configs["deployemntlistconfig"];
        this.deployemntlist=this.service.transformAlphabetically(  this.deployemntlist,'name');
        if (this.deployemntlist.length > 0) {

          this.isDeployement = this.deployemntlist[0].name;
          this.ChangeDeployment (this.deployemntlist[0])
        }
      }
    },error=>{
      this.ngxUiLoaderService.stop()
    })
  }
  isLoadingResult: boolean = false;
  ChangeDeployment(event) {
 
    if ( event !== "") {
      this.isDeployement = event.name
      this.onKeyUpSearchJobslist(this.searchtext)
    }
  }

  searchDeplyoementCleared(){
    this.isDeployement ="";
    // this.w700jobslist =[];
    this.searchText ="";
  }

  onKeyUpSearchJobslist(event: any) {

    var val = event;
    this.searchtext = val;
    if (Number(val.length) + Number(1) > 3) {
      this.ngxUiLoaderService.start()
      this.w700jobslist=[]
      let deployement =  typeof(this.isDeployement)=="string"||typeof(this.isDeployement)==undefined? this.isDeployement:this.isDeployement['name'];
      this.w700service.getapiurl("api/W700Jobs/GetW700ApplicationJobs?searchvalue=" + val, deployement).subscribe(response => {
        if (response['status'] && response['statusText']) {
        }
        else {
          this.ngxUiLoaderService.stop()
          this.w700jobslist = response as any[];
          if (this.w700jobslist.length > 0) {
            this.datashow = true;
          }
          else {
            this.datashow = false;
          }
        }
      },error=>{
        this.ngxUiLoaderService.stop()
      })
    }
    else {
      this.w700jobslist = [];
      this.datashow = false;
    }
  }
  datashow: boolean = false;
  w700jobslist: any = [];


  // numbers = [];
  // noOfPages: number;
  // pageSize = 10;
  // currentPage = 0;
  // setPage(value) {
  //   this.currentPage = value;
  // }
  // setPagePrev() {
  //   if (this.currentPage != 0) {
  //     this.currentPage = this.currentPage - 1;
  //   }
  // }
  // setPageNext() {
  //   if (this.currentPage < this.noOfPages - 1) {
  //     this.currentPage = this.currentPage + 1;
  //   }
  // }

  public dataToModal: any;
  openCreateJobpopup(item) {
    
    this.dataToModal = {
      deploymentkey: typeof(this.isDeployement)=="string"||typeof(this.isDeployement)==undefined? this.isDeployement:this.isDeployement['name'],
      dataitem: item
    }
    const modalRef = this.modalService.open(CreatejobComponent,
      { windowClass: "myCustomModalClasscreatejob", backdrop: 'static', keyboard: false, size: 'lg', });
    modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
    modalRef.result.then(() => {
      // this.onKeyUpSearchJobslist(this.searchtext);
    });
  }
  searchtext: string = '';

  openpropertiesJobpopup(item) {
    
    this.dataToModal = {
      deploymentkey: this.isDeployement,
      dataitem: item
    }
    const modalRef = this.modalService.open(JobpropertiesComponent,
      { windowClass: "myCustomModalClassProperties", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
    modalRef.result.then(() => { });
  }


  getstatus(spKey: boolean): string {
    return spKey === true ? 'Active' : 'Inactive';
  }

}

import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { Resolve,ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { Observable, of as observableOf } from "rxjs";
import { environment } from 'src/environments/environment';
import { AppConfigService } from "../configurations/app-config.service";

@Injectable({
    providedIn:'root'
})

export class ImportService implements Resolve<any> {
    // BaseUrl = environment.ApiURL
    taskid : any
    projid : any
    ftpDetail = []
    headers = { withCredentials: true }
    protected BaseUrl: string = AppConfigService.settings.WebApiURL;
constructor(private http:HttpClient)
{
}
resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{

         return this.http.get<any>(`${this.BaseUrl}api/PatientHistoryImport/CheckFTPGetFilesV2?projectID=${route.params['id']}`,this.headers);
    }
}

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DataRowOutlet } from '@angular/cdk/table';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger, MatChipInputEvent, MatMenuTrigger } from '@angular/material';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { type } from 'os';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';
import { W700servicesService } from '../services/w700services.service';
declare var $
declare const copyToClipboard: any;
@Component({
  selector: 'app-varules',
  templateUrl: './varules.component.html',
  styleUrls: ['./varules.component.scss']
})

export class VarulesComponent implements OnInit {

  @Input() inputDataInModalComponent: any;
  @ViewChild('languageMenuTrigger', { static: false }) languageMenuTrigger: MatMenuTrigger;
  varuleObj={};
  isLoadingcase: boolean;
  rulename: string = '';
  LabId: any;
  isShown : boolean;
  LabName: any;
  LabOrgId: any;
  LabOrgName: any;
  OrdFacilityId: any;
  OrdFacilityName: any;
  OrdFacOrgId: any;
  OrdFacOrgName: any;
  InterfaceName: any;
  isflag: any;
  Islocked: any;
  constructor(private activeModal: NgbActiveModal,
    private w700service: W700servicesService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private service: CommonService, private toastr: ToastrService,
    private _fb: FormBuilder,
    private overlayContainer: OverlayContainer,
    private modalService: NgbModal
  ) {
    this.reactiveFormofname = _fb.group({ ofname: '' });
    this.reactiveFormoforgname = _fb.group({ oforgname: '' });
    this.reactiveFormlabname = _fb.group({ labname: '' });
  }

  Rulelist: any = [];
  routename: string = '';
  pagerolepermisions: any = '';
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  panelOpenState = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  varuleautocompletecontrol = {
    "LabUIControls": {
      "RuleName": {
        "Displayname": "Rule Name",
        "SearchKey": "",
        "Placeholder": "Search Rule Name",
        "ReferenceObjKey": "",
        "Type": "Text",
        "Required": true,
        "Sequence": 1,
        "isgroupend": true,
        "isdisabled":true
      },
      "TestMode": {
        "Displayname": "Test Mode",
        "SearchKey": "",
        "Placeholder": "Test Mode",
        "Type": "Checkbox",
        "Required": false,
        "Sequence": 2,
        "ReferenceObjKey": "",
        "isgroupend": true,
        "isdisabled":false
      },
      "LabOrgName": {
        "Displayname": "Lab Organization",
        "SearchKey": "LaboratoryOrganizationID",
        "Placeholder": "Search Lab Organization",
        "Type": "Autocomplete",
        "ReferenceObjKey": "LabOrgId",
        "Required": true,
        "Sequence": 3,
        "isgroupend": true,
        "isdisabled":false
      },
      "LabName": {
        "Displayname": "Lab Account",
        "SearchKey": "LaboratoryAccountID",
        "Placeholder": "Search Lab Account",
        "Type": "Autocomplete",
        "ReferenceObjKey": "LabId",
        "Required": true,
        "Sequence": 4,
        "isgroupend": true,
        "isdisabled":false
      },
      "OrdFacOrgName": {
        "Displayname": "Ordering Facility Organization",
        "SearchKey": "OrderingFacilityOrganizationID",
        "Placeholder": "Search Ordering Facility Organization",
        "Type": "Autocomplete",
        "ReferenceObjKey": "OrdFacOrgId",
        "Required": true,
        "Sequence": 5,
        "isgroupend": false,
        "isdisabled":false
      },
      "OrdFacilityName": {
        "Displayname": "Ordering Facility Account",
        "SearchKey": "OrderingFacilityID",
        "Placeholder": "Search Ordering Facility Account",
        "Type": "Autocomplete",
        "Required": true,
        "ReferenceObjKey": "OrdFacilityId",
        "Sequence": 6,
        "isgroupend": false,
        "isdisabled":false
      },
      "ExOrdFacilityName": {
        "Displayname": "Excluded OF Accounts",
        "SearchKey": "ExternalOrderingFacilityID",
        "Placeholder": "Search Excluded OF Accounts",
        "Type": "Multiselect",
        "Required": false,
        "Sequence": 7,
        "ReferenceObjKey": "ExOrdFacilityId",
        "isgroupend": true,
        "isdisabled":false
      },
      "Blank8": {
        "Displayname": "Excluded OF Accounts",
        "SearchKey": "ExternalOrderingFacilityID",
        "Placeholder": "Search Excluded OF Accounts",
        "Type": "Autocomplete",
        "Required": false,
        "Sequence": 8,
        "ReferenceObjKey": "Blank8",
        "isgroupend": true,
        "isdisabled":false
      },
      "OrdPhysicianName": {
        "Displayname": "Ordering Physician",
        "SearchKey": "OrderingPhysician",
        "Placeholder": "Search Ordering Physician",
        "Type": "Autocomplete",
        "ReferenceObjKey": "OrdPhysician",
        "Required": true,
        "Sequence": 9,
        "isgroupend": true,
        "isdisabled":false
      },
      "RefPhysicianName": {
        "Displayname": "Refering Physician",
        "SearchKey": "ReferingPhysician",
        "Placeholder": "Search Refering Physician",
        "Type": "Autocomplete",
        "ReferenceObjKey": "RefPhysician",
        "Required": false,
        "Sequence": 10,
        "isgroupend": true,
        "isdisabled":false
      },
      "PathOrgName": {
        "Displayname": "Pathologist Organization",
        "SearchKey": "PathologistOrganizationID",
        "Placeholder": "Search Pathologist Organization",
        "Type": "Autocomplete",
        "ReferenceObjKey": "PathOrgId",
        "Required": false,
        "Sequence": 11,
        "isgroupend": false,
        "isdisabled":false
      },
      "PathAccountName": {
        "Displayname": "Pathologist Account",
        "SearchKey": "PathologistAccountID",
        "Placeholder": "Search Pathologist Account",
        "Type": "Autocomplete",
        "ReferenceObjKey": "PathAccountId",
        "Required": false,
        "Sequence": 12,
        "isgroupend": false,
        "isdisabled":false
      },
      "PathologistName": {
        "Displayname": "Pathologist",
        "SearchKey": "PathologistID",
        "Placeholder": "Search Pathologist",
        "Type": "Autocomplete",
        "ReferenceObjKey": "PathologistId",
        "Required": false,
        "Sequence": 13,
        "isgroupend": true,
        "isdisabled":false
      },
      "Blank14": {
        "Displayname": "Excluded OF Accounts",
        "SearchKey": "ExternalOrderingFacilityID",
        "Placeholder": "Search Excluded OF Accounts",
        "Type": "Autocomplete",
        "Required": false,
        "Sequence": 14,
        "ReferenceObjKey": "Blank14",
        "isgroupend": true,
        "isdisabled":false
      },
      "CaseTypeDisplayName": {
        "Displayname": "Case Type",
        "SearchKey": "CaseType",
        "Placeholder": "Search Case Type",
        "Type": "Multiselect",
        "Required": false,
        "Sequence": 15,
        "ReferenceObjKey": "CaseType",
        "isgroupend": true,
        "isdisabled":false
      },
      "ExCaseTypesDisplayName": {
        "Displayname": "Excluded Case Types ",
        "SearchKey": "ExCaseType",
        "Placeholder": "Search Excluded Case Types",
        "Type": "Multiselect",
        "Required": false,
        "Sequence": 16,
        "ReferenceObjKey": "ExCaseTypes",
        "isgroupend": true,
        "isdisabled":false
      },
      "ServiceName": {
        "Displayname": "Service",
        "SearchKey": "Services",
        "Placeholder": "Search Service",
        "Type": "Autocomplete",
        "ReferenceObjKey": "Service",
        "Required": false,
        "Sequence": 17,
        "isgroupend": false,
        "isdisabled":false
      },
      "ExServiceName": {
        "Displayname": "Excluded Services",
        "SearchKey": "Exservices",
        "Placeholder": "Search Excluded Services",
        "Type": "Autocomplete",
        "ReferenceObjKey": "ExServices`",
        "Required": false,
        "Sequence": 18,
        "isgroupend": false,
        "isdisabled":false
      }
    },
    "LabIntegrationUIcontrol": {
      "RuleDesc": {
        "Displayname": "Rule Description",
        "SearchKey": "",
        "Placeholder": "Search Rule Desc",
        "Type": "Textarea",
        "Required": false,
        "Sequence": 1,
        "ReferenceObjKey": "",
        "isgroupend": false,
        "isdisabled":false
      },
      "ModeLabel": {
        "Displayname": "Mode",
        "SearchKey": "Mode",
        "Placeholder": "Search Mode",
        "Type": "Autocomplete",
        "ReferenceObjKey": "Mode",
        "Required": false,
        "Sequence": 3,
        "isgroupend": false,
        "isdisabled":false
      },
      "EntityName": {
        "Displayname": "Entity Name",
        "SearchKey": "EntityName",
        "Placeholder": "Search Entity Name",
        "Type": "Autocomplete",
        "ReferenceObjKey": "",
        "Required": false,
        "Sequence": 18,
        "isgroupend": false,
        "isdisabled":false
      },
      "CaseStatus": {
        "Displayname": "Case Status",
        "SearchKey": "Casestatus",
        "Placeholder": "Search Case Status",
        "Type": "Multiselect",
        "Required": false,
        "Sequence": 17,
        "ReferenceObjKey": "",
        "isgroupend": false,
        "isdisabled":false
      }
    },
    "IgnoredUicontrol": {
      "PrimaryPGPName": {
        "Displayname": "Primary PGP",
        "SearchKey": "PrimaryPGP",
        "Placeholder": "Search Primary PGP",
        "Type": "Autocomplete",
        "ReferenceObjKey": "PrimaryPGP",
        "Required": false,
        "Sequence": 13,
        "isgroupend": false,
        "isdisabled":false
      },
      "SurgicalCenter": {
        "Displayname": "Surgical Center",
        "SearchKey": "",
        "Placeholder": "Search Surgical Center",
        "Type": "Text",
        "Required": false,
        "Sequence": 14,
        "ReferenceObjKey": "",
        "isgroupend": false,
        "isdisabled":true
      },
      "RuleType": {
        "Displayname": "Rule Type",
        "SearchKey": "",
        "Placeholder": "Search Rule Type",
        "Type": "Text",
        "Required": false,
        "Sequence": 22,
        "ReferenceObjKey": "",
        "isgroupend": false,
        "isdisabled":false
      },
      "RuleConfig": {
        "Displayname": "Rule Config",
        "SearchKey": "",
        "Placeholder": "Search Rule Config",
        "Type": "Text",
        "Required": false,
        "Sequence": 23,
        "ReferenceObjKey": "",
        "isgroupend": false,
        "isdisabled":false
      }
    }
  }
  casestatus: any = ['All','Accession',
    'Accessioning QA',
    'Addendum - Pending Sign-Out',
    'Addendum - Pending Tests',
    'Amendment - Pending Sign-Out',
    'Amendment - Pending Tests',
    'Archived',
    'Archived Orders',
    'Assignment',
    'Assignment - Pending Tests',
    'Cancelled',
    'Cancelled Order',
    'Cancelled Requisition',
    'Correction - Pending Sign-Out',
    'Correction - Pending Tests',
    'Deleted',
    'Diagnosis',
    'Diagnosis - Pending Tests',
    'Diagnosis (Sent Out)',
    'Finalized',
    'Finish Case - Pending Tests',
    'Grossing',
    'Kit',
    'MultiTest',
    'New',
    'New Send Out',
    'Order',
    'Pending Finish Case',
    'Pending Rescreen',
    'Pending Rescreen - Pending Tests',
    'Pending Sign-Out',
    'Pending Tests',
    'Processing',
    'Rejected',
    'Rejected Order',
    'Report - Pending Tests',
    'Requisition',
    'Review',
    'Review - Pending Tests',
    'Screening',
    'Screening - Pending Tests',
    'Screening Review',
    'Screening Review - Pending Tests',
    'Send - Out',
    'Send For Consult',
    'Send For Consult - Pending Tests',
    'Sent To Ext Lab',
    'Sent To External System',
    'Sequestered',
    'Supplemental - Pending Acknowledgement']

  varulevaluelist: any = [];
  keywordsearch = "displayname";
  reactiveFormofname: FormGroup;
  reactiveFormoforgname: FormGroup;
  reactiveFormlabname: FormGroup;
  isLoadingofname: boolean = false;
  isLoadingoforgname: boolean = false;
  isLoadinglabname: boolean = false;
  notfounddata: boolean;
  dynamicJSON: inputField[] = [];
  dynamicJSON1: inputField[] = [];
  dynamicJSON2: inputField[] = [];
  varulemodalheight: Number = 600;

  ngOnInit() {


    if (this.service.checksession() == true) {
      if(this.inputDataInModalComponent.isflag){
        this.Rulelist  = this.inputDataInModalComponent.data;
        this.InterfaceName  = this.inputDataInModalComponent.name;

        this.Islocked = this.inputDataInModalComponent.islocked;
        this.isflag = this.inputDataInModalComponent.isflag;
        if(this.Rulelist.length > 0){
          for (let i = 0; i < this.Rulelist.length; i++) {
            this.Rulelist[i]["isEdit"] = false;
          }
        }
      }else{
        this.routename = this.inputDataInModalComponent.routename;
        this.deploymentkey = this.inputDataInModalComponent.deploymentkey;
        this.Islocked = this.inputDataInModalComponent.islocked;
        this.getvarules(this.routename);
        this.loadprerequisite();
      }
    }
  }
  loadprerequisite() {
    this.ngxUiLoaderService.start();
    if(this.inputDataInModalComponent.LabOrgId && this.inputDataInModalComponent.LabOrgId!="-1"){
      this.getvarulesbyfilter(this.inputDataInModalComponent.LabOrgId,'LaboratoryOrganizationID',[],true,false);
   }
  else  if(this.inputDataInModalComponent.LabId&&this.inputDataInModalComponent.LabId!="-1"){
     this.getvarulesbyfilter(this.inputDataInModalComponent.LabId,'LaboratoryAccountID',[],true,false);
   }
   if(this.inputDataInModalComponent.OrdFacilityId&&this.inputDataInModalComponent.OrdFacilityId!="-1"){
      this.getvarulesbyfilter(this.inputDataInModalComponent.OrdFacilityId,'OrderingFacilityID',[],true,true);
   }
   else if(this.inputDataInModalComponent.OrdFacOrgId&&this.inputDataInModalComponent.OrdFacOrgId!="-1"){
     this.getvarulesbyfilter(this.inputDataInModalComponent.OrdFacOrgId,'OrderingFacilityOrganizationID',[],true,true);
   }
  }

  savedata(c) {

    this.modifyobj(this.dynamicJSON, this.varuleObj);
    this.modifyobj(this.dynamicJSON1, this.varuleObj);
    this.modifyobj(this.dynamicJSON2, this.varuleObj);

    if (this.varuleObj["RuleId"] > 0 &&  !this.validatevaruledata()) {
      this.ngxUiLoaderService.start();
      var apiname = "api/VitalHubDXAPI/updatevarule";
      this.w700service.postapi(apiname, this.varuleObj, this.deploymentkey).then(response => {
        if((response['status'] && response['statusText']) || response['statusText'] === "Unknown Error"){
          this.ngxUiLoaderService.stop();
          this.toastr.error("Something Went Wrong!","",{ timeOut: 4000, positionClass: 'toast-bottom-right' });
        }
        else{
          this.ngxUiLoaderService.stop();
          if(response["status"].toLowerCase()=="success"){
            this.toastr.success(response["statusmessage"], "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
            this.service.auditsave("Update","", "Va rule Updated Successfully name :"+this.varuleObj['RuleName']+"Id :"+this.varuleObj['RuleId'],"Pipeline",this.inputDataInModalComponent.contextid,"Va rule Updated Successfully");
            this.getvarules(this.routename);
          }
          else{
            this.toastr.error(response["statusmessage"], "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }

        }

      })
    }
    else {
      let result = this.validatevaruledata();
      if (!result) {
        this.ngxUiLoaderService.start();
        this.service.postapi("api/VitalHubDXAPI/createvarule", this.varuleObj).then(
          response => {

            if (response["status"] == "success") {
              this.toastr.success("VA Rule created successfully", "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
              });
              this.getvarules(this.routename);
              this.closepopup(c);
            } else {
              this.toastr.error("Failed to create VA Rule", "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
              });
            }
            this.ngxUiLoaderService.stop();

          })
      }
      else {
        this.toastr.warning("Please enter the required fields", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }

    }
  }
  modifyobj(array, obj) {
    for (var i = 0; i < array.length; i++) {
      for (let [key, value] of Object.entries(obj)) {
        if (array[i].ObjKey == key) {
          if (array[i].multiplevalue != '' && array[i].multiplevalue.length > 0) {
            if (array[i].multiplevalue.length == 1) {
              if(array[i].referenceobjkey){
                obj[array[i].referenceobjkey]=array[i].multiplevalue[0].value=="All"?"*":array[i].multiplevalue[0].value;
              }
              else{
                obj[key] = array[i].multiplevalue[0].value=="All"?"*":array[i].multiplevalue[0].value;
              }
            }
            else {
              if(array[i].referenceobjkey){
                obj[array[i].referenceobjkey]= array[i].multiplevalue.map(ele=>ele.value).join(',');
              }
              else{
                obj[key] = array[i].multiplevalue.map(ele=>ele.value).join(',');
              }

            }
          }
          else {
            if (array[i].referenceobjkey) {
              if (parseInt(array[i].value) >= 0) {
                obj[array[i].referenceobjkey] = array[i].value;
              }
              else if(array[i].value=="-1"){
                obj[array[i].referenceobjkey]=array[i].value;
              }
            }
            else {
              obj[key] = array[i].value;
            }

          }
        }
      }
    }
  }
  validatevaruledata() {
    let result;
    for (var i = 0; i < this.dynamicJSON.length; i++) {
      if (this.dynamicJSON[i].required && this.dynamicJSON[i].value == '') {
        result = true;
        break;
      }
      else {
        result = false;
      }
    }
    return result;
  }
  // async getdeploymentconfiguration() {
  //   var apiname = "api/CoreConfig/GetProcessorUIConfig?agentid=&apikey=";
  //    this.service.getapi(apiname).then(response=>{
  //     var configs = JSON.parse(response.responsebody);
  //    return configs;
  //    });

  // }
  closetpopup() {
    this.activeModal.close();
    this.varuleObj={};
    this.LabId="";
    this.LabName="";
    this.LabOrgId="";
    this.LabOrgName="";
    this.OrdFacOrgId="";
    this.OrdFacOrgName="";
    this.OrdFacilityId="";
    this.OrdFacilityName="";
    this.dynamicJSON = [];
    this.dynamicJSON1=[];
    this.dynamicJSON2=[];
  }

  searchvalue: string = '';

  deploymentkey: string = ''
  getvarules(rulename) {
    this.ngxUiLoaderService.start();
    var apiname = "api/VitalHubDXAPI/getvarule?RuleName=" + rulename;
    this.w700service.getapi(apiname, this.deploymentkey).subscribe({
      next: data => {
        this.Rulelist = data as any[];
        if (data.length > 0) {
          for (let i = 0; i < this.Rulelist.length; i++) {
            this.Rulelist[i]["isEdit"] = false;
          }
        }
        this.ngxUiLoaderService.stop();
      },
      error: error => {

      }
    });

  }


  currentlyOpenedItemIndex = -1;

  setOpened(itemIndex) {
    this.currentlyOpenedItemIndex = itemIndex;
  }

  setClosed(itemIndex) {
    if (this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }

  openrule(data,modal) {
    const modalRef = this.modalService.open(modal,
      { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
    modalRef.result.then(() => { });
    this.varuleObj = {};
    if (data) {
      this.varuleObj = JSON.parse(JSON.stringify(data));
      this.rulename = this.varuleObj["RuleName"];
      this.dynamicJSON = [];
      this.dynamicJSON1 = [];
      this.dynamicJSON2 = [];
      this.setUicontrol('LabUIControls', this.dynamicJSON, this.varuleObj);
      this.setUicontrol('LabIntegrationUIcontrol', this.dynamicJSON1, this.varuleObj);
      this.setUicontrol('IgnoredUicontrol', this.dynamicJSON2, this.varuleObj);
    }
    else {

      let varuleobj: varule = {
        RuleName: this.routename,
        RuleDesc: '',
        CaseType: '-1',
        Service: '-1',
        RuleType: '1',
        Status: '-1',
        LabId: '',
        CaseId: '-1',
        LabOrgId:'',
        OrdFacilityId:'',
        OrdFacOrgId: '',
        PathologistId: '-1',
        PathOrgId: '-1',
        PathAccountId: '',
        OrdPhysician: '-1',
        RefPhysician: '-1',
        RefPhysicianName: 'All(-1)',
        EntityName: '',
        Mode: '',
        TestMode: 'False',
        SurgicalCenter: '-1',
        parentruleid: '',
        CaseStatus: 'All',
        ExServices: '',
        ExCaseTypes: '',
        CreatedBy: '',
        RuleConfig: '-1',
        RuleId: 0,
        ExOrdFacilityId: '',
        PrimaryPGP: '-1',
        PrimaryPGPName:'All(-1)',
        ModifiedBy: '',
        LabName:'',
        LabOrgName: '',
        OrdFacilityName: '',
        OrdFacOrgName: '',
        PathologistName: 'All(-1)',
        PathOrgName: 'All(-1)',
        PathAccountName: 'All(-1)',
        OrdPhysicianName: 'All(-1)',
        CaseTypeDisplayName: 'All',
        statusmessage: '',
        ModeLabel:'',
        ExCaseTypesDisplayName:'',
        ServiceName:'All(-1)',
        ExServiceName:'',
        ExOrdFacilityName:'',
        deploymentkey :this.deploymentkey
      };
      if(this.inputDataInModalComponent.LabOrgId && this.inputDataInModalComponent.LabOrgId!="-1"){
        varuleobj.LabId="-1";
        varuleobj.LabName="All(-1)"
        varuleobj.LabOrgId=this.LabOrgId;
        varuleobj.LabOrgName=this.LabOrgName;
     }
    else  if(this.inputDataInModalComponent.LabId&&this.inputDataInModalComponent.LabId!="-1"){
       varuleobj.LabOrgId="-1";
       varuleobj.LabOrgName="All(-1)";
       varuleobj.LabId=this.LabId;
       varuleobj.LabName=this.LabName;
     }
     if(this.inputDataInModalComponent.OrdFacilityId&&this.inputDataInModalComponent.OrdFacilityId!="-1"){
        varuleobj.OrdFacOrgId="-1";
        varuleobj.OrdFacOrgName="All(-1)";
        varuleobj.OrdFacilityId=this.OrdFacilityId;
        varuleobj.OrdFacilityName=this.OrdFacilityName;
     }
     else if(this.inputDataInModalComponent.OrdFacOrgId&&this.inputDataInModalComponent.OrdFacOrgId!="-1"){
        varuleobj.OrdFacilityId="-1";
        varuleobj.OrdFacilityName="All(-1)";
        varuleobj.OrdFacOrgId=this.OrdFacOrgId;
        varuleobj.OrdFacOrgName=this.OrdFacOrgName;
     }
      this.varuleObj=varuleobj;
      this.dynamicJSON = [];
      this.dynamicJSON1 = [];
      this.dynamicJSON2 = [];
      this.setUicontrol('LabUIControls', this.dynamicJSON, this.varuleObj);
      this.setUicontrol('LabIntegrationUIcontrol', this.dynamicJSON1, this.varuleObj);
      this.setUicontrol('IgnoredUicontrol', this.dynamicJSON2, this.varuleObj);
    }

  }

  getresultsData(result,iscompleted,filtertype){
    if(result.length>0 && filtertype){
      if(result[0].displayname=="All"){
        result[0].displayname+='(-1)';
      }
      if(filtertype=="LaboratoryAccountID"){
        this.LabId=result[0].value;
        this.LabName=result[0].displayname;
      }
      if(filtertype=="LaboratoryOrganizationID"){
        this.LabOrgId=result[0].value;
        this.LabOrgName=result[0].displayname;
      }
      if(filtertype=="OrderingFacilityID"){
        this.OrdFacilityId=result[0].value;
        this.OrdFacilityName=result[0].displayname;
      }
      if(filtertype=="OrderingFacilityOrganizationID"){
        this.OrdFacOrgId=result[0].value;
        this.OrdFacOrgName=result[0].displayname;
      }
      if(iscompleted){
        this.ngxUiLoaderService.stop();
      }
     }
  }
  setUicontrol(objkey, array, obj) {
    let count = 0;
    let formcontrolname = ''
    for (let [key, value] of Object.entries(obj)) {
      for (let [data1, objvalue] of Object.entries(this.varuleautocompletecontrol[objkey])) {
        if (data1 == key || (data1 == "Blank" + objvalue["Sequence"])) {
          let reactiveFormprocess: FormGroup;
          formcontrolname = 'prop' + count++;
          if (objvalue["Type"] == "Autocomplete" && value) {
            reactiveFormprocess = this._fb.group({ [formcontrolname]: value });
          }
          else {
            reactiveFormprocess = this._fb.group({ [formcontrolname]: '' });
          }

          if ("SurgicalCenter" == key) {
            value = -1;
          }

          let multivalue = [];
          if (objvalue["Type"] == 'Multiselect' && value) {
            if (value == "*") {
              value = "All";
              let obj={
                displayname:"All",
                value:"*"
              }
              multivalue.push(obj)
            }
            else if (value.toString().indexOf(",") > 0) {
              var arrvalue= value.toString().split(",");
              if(objvalue["ReferenceObjKey"]){
                  var objectvalue=obj[objvalue["ReferenceObjKey"]];
                  if(objectvalue.toString().indexOf(",")>0){
                    var arr= objectvalue.toString().split(",");
                    arrvalue.forEach(va=>{
                      if(va.trim()){
                        arr.forEach(data=>{
                          let obj={
                            displayname:va,
                            value:data
                          }
                          let existvalue=multivalue.filter(ele=>ele.value===data);
                          let existdisplayname=multivalue.filter(ele=>ele.displayname===va);
                          if(existvalue.length==0 && existdisplayname.length==0){
                            multivalue.push(obj)
                          }
                        })
                      }
                    })
                  }
                  else{
                    arrvalue.forEach(va=>{
                      if(va.trim()){
                        let obj={
                          displayname:va,
                          value:va
                        }
                        multivalue.push(obj)
                      }

                    })
                  }

              }
              else{
                arrvalue.forEach(va=>{
                  if(va.trim()){
                    let obj={
                      displayname:va,
                      value:va
                    }
                    multivalue.push(obj)
                  }
                })
              }

            }
            else{
              let object={
                displayname:value,
                value:objvalue["ReferenceObjKey"]?obj[objvalue["ReferenceObjKey"]]:value
              }
              multivalue.push(object);
            }

          }
          let inputValue: inputField = {
            data: objvalue["SearchKey"] == 'Casestatus' ? this.casestatus : '',
            formcontrolname: formcontrolname,
            formgroupname: reactiveFormprocess,
            isKey: objvalue["SearchKey"],
            label: objvalue["Displayname"],
            placeholder: objvalue["Placeholder"],
            isloading: false,
            type: objvalue["Type"],
            value: value ? value.toString() : '',
            multiplevalue: multivalue.length > 0 ? multivalue : '',
            formcontrol: new FormControl(),
            sequence: objvalue["Sequence"],
            ObjKey: key,
            required: objvalue["Required"],
            referenceobjkey: objvalue["ReferenceObjKey"],
            isgroupend: objvalue["isgroupend"],
            isdisabled:objvalue["isdisabled"]
          }
          if (data1 == ("Blank" + objvalue["Sequence"])) {
            if (!this.checkexistblankkey(array, data1)) {
              array.push(inputValue);
              reactiveFormprocess.patchValue({
                formcontrolname: value
              });
            }
          }
          else {
            array.push(inputValue);
            reactiveFormprocess.patchValue({
              formcontrolname: value
            });
          }

        }
      }
      if (array.length > 0) {
        array = this.sortByKey(array, 'sequence')
      }
    }
  }
  checkexistblankkey(array: any, key: string) {
    let isfoundkey = false;
    if (array.length > 0) {
      array.forEach(element => {
        if (element.referenceobjkey == key) {
          isfoundkey = true;
        }
      });
    }
    return isfoundkey;
  }
  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  setValue(data, Event) {
    data.RuleDesc = '';
    if(Event.checked){
      data.Status="1"
    }
    else{
      data.Status="2";
    }
  }
  getvarulesbyfilter(event, filtertype, array,findSpecificid,iscompleted) {
    var val = event;
    if(!findSpecificid){
      array.isloading = true;
    }
    else{
      this.ngxUiLoaderService.start();
    }
    if (val && val.length >= 3 && filtertype != 'Casestatus') {
      var apiname = `api/VitalHubDXAPI/getvarulesbyfilter?filtertype=${filtertype}&filtervalue=` + val;
      this.w700service.getapi(apiname, this.deploymentkey).subscribe(response => {
        if (response) {
          if(findSpecificid){
          this.getresultsData(response,iscompleted,filtertype);
          }
          else{
            array.data = response as any[];
            array.isloading = false;
            if(array.data.length>0){
              if(array.data[array.data.length-1].displayname.toLowerCase()=="all"){
                array.data.unshift(array.data.pop());
              }
            }
            if (array.length == 0) {
              this.notfounddata = true;
            }
            else {
              this.notfounddata = false;
            }
          }

        }
      })
    }
    if(findSpecificid){
      // return findSpecificArray;
    }
  }
  searchvarulefilterCleared(event, array) {
    array.data = [];
    array.isloading = false;
    this.notfounddata = false;
  }

  hideSuccessMessage = false;
  resetvarule(data) {
    data.isEdit = false;
  }

 async changeVaruleStatus(data) {
    this.ngxUiLoaderService.start();
    if (data && data.RuleDesc != '') {
      let apicntrl = '';
      let auditvalue='';
      if (data.Status=="1") {
        apicntrl = 'activatevarule';
        auditvalue='VA Rule Activated';
      }
      else {
        apicntrl = 'deactivatevarule';
        auditvalue='VA Rule Deactivated';
      }

      var validateresponse = await this.w700service.postapi(`api/VitalHubDXAPI/${apicntrl}`, data, this.deploymentkey);
      if (validateresponse['status'] && validateresponse['statusText']) {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Failed to update varule", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.languageMenuTrigger.closeMenu();
      }
      else {
        this.ngxUiLoaderService.stop();
        this.toastr.success("VA Rule saved successfully", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.service.auditsave("Update","",auditvalue,"Pipeline",this.inputDataInModalComponent.contextid,"VA Rule Status Updated successfully")
        this.languageMenuTrigger.closeMenu();
      }
    }
    else {
      this.ngxUiLoaderService.stop();
      this.toastr.warning('Please add the Reference!!', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  buildtitle(data) {
    return data.Status == '1' ? 'Active varule' : 'Passive varule';
  }
  addfiltervalue(event, data) {
    data.value = event.value;
  }
  closepopup(c) {
    c('close modal');
    this.varuleObj={};
    this.dynamicJSON = [];
    this.dynamicJSON1=[];
    this.dynamicJSON2=[];
  }
  remove(data: string, array): void {
    const index = array.findIndex(va=>va.value==data);
    if (index >= 0) {
      array.splice(index, 1);
    }
  }
  selected(event, datarow) {
    if (event.option.viewValue == 'All') {
      datarow.multiplevalue = [];
    }
    if (datarow.multiplevalue.length>0) {

      let exists = datarow.multiplevalue.filter(va => (va.value === 'All' || va.value==="*"));
      if (exists.length > 0) {
        this.toastr.warning((exists[0].value=="*"?"All": exists[0].value) + " " + "option includes every case status for the lab account", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        let exist = datarow.multiplevalue.filter(va => va.value === event.option.value);
        if (exist.length > 0) {
          this.toastr.warning(exist[0].value+ " " + 'Already Exist', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          var obj={
            displayname:event.option.viewValue,
            value:event.option.value
          }
          datarow.multiplevalue.push(obj);
          datarow.formcontrol.setValue(null);
        }
      }
    }
    else {
      datarow.multiplevalue = [];
      var obj={
        displayname:event.option.viewValue,
        value:event.option.value
      }
      datarow.multiplevalue.push(obj);
      datarow.formcontrol.setValue(null);
    }
    if(datarow.ObjKey!="CaseStatus"){
      datarow.data = [];
    }
    let ele = document.getElementById(datarow.formcontrolname);
    ele.innerText = '';
    ele.focus();
    ele.blur();

  }
  add(event: MatChipInputEvent, control): void {
    event.input.value = "";
  }
  setAll(ischecked, data) {
    if (ischecked) {
      data.value = "True"
    }
    else {
      data.value = "False"
    }
  }
  closematmenu(data){
    this.languageMenuTrigger.closeMenu();
    data.Status=data.Status=="1"?"2":"1";
  }
  copyToClip(event: any, message) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + 'Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
}

export interface inputField {
  data: any;
  formgroupname: FormGroup,
  formcontrolname: string;
  isKey: string,
  label: string,
  placeholder: string,
  isloading: boolean;
  type: string;
  value: string;
  multiplevalue: any;
  formcontrol: FormControl;
  sequence: Number;
  ObjKey: string;
  required: boolean;
  referenceobjkey: string;
  isgroupend: boolean;
  isdisabled:boolean;
}
export interface varule {
  RuleName: string;
  RuleDesc: string;
  CaseType: string;
  Service: string;
  RuleType: string;
  Status: string;
  LabId: string;
  CaseId: string;
  LabOrgId: string;
  OrdFacilityId: string;
  OrdFacOrgId: string;
  PathologistId: string;
  PathOrgId: string;
  PathAccountId: string;
  OrdPhysician: string;
  RefPhysician: string;
  RefPhysicianName: string;
  EntityName: string;
  Mode: string;
  TestMode: string;
  SurgicalCenter: string;
  parentruleid: string;
  CaseStatus: string;
  ExServices: string;
  ExCaseTypes: string;
  CreatedBy: string;
  RuleConfig: string;

  RuleId: Number;
  ExOrdFacilityId: string;
  PrimaryPGP: string;
  ModifiedBy: string;

  LabName: string;
  LabOrgName: string;
  OrdFacilityName: string;
  OrdFacOrgName: string;
  PathologistName: string;
  PathOrgName: string;
  PathAccountName: string;
  OrdPhysicianName: string;
  CaseTypeDisplayName: string;
  statusmessage: string;
  ModeLabel:string;
  ExServiceName:string;
  ServiceName:string;
  ExOrdFacilityName:string;
  ExCaseTypesDisplayName:string;
  PrimaryPGPName:string;
  deploymentkey?: string;
}






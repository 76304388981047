import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { positionElements } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/services/common.service';
import { MonacoEditorComponent, MonacoEditorConstructionOptions, MonacoEditorLoaderService, MonacoStandaloneCodeEditor } from "@materia-ui/ngx-monaco-editor";
import { filter, take } from 'rxjs/operators';
import { v4 as uuidv4 } from "uuid";
import { AuditdetailsComponent } from 'src/app/auditdetails/auditdetails.component';
import moment from 'moment';
import { ConfigComponent } from 'src/app/config/config.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewauditComponent } from '../../newaudit/newaudit.component';
declare const copyToClipboard: any;
@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']

})
export class AgentComponent implements OnInit {
  reactiveFormagent: FormGroup;
  reactiveFormagenttypedl: FormGroup;
  reactiveFormagentstatustype: FormGroup;
  reactiveFormmodetype: FormGroup;
  reactiveFormagentstagetype: FormGroup;
  reactiveFormmodeagent: FormGroup;
  reactiveFormtypeagent: FormGroup;
  reactiveFormtypepipeline: FormGroup;


  keywordsearch = "displayname";
  isLoadingagent: boolean = false;
  AgentNameList: any[];
  notfounddata: boolean;
  isLoadingpa: boolean;
  selectedAgentguid: string;
  selectedAgentName: any;
  agenttype = 'Select';
  agenttypelist: any = [];

  agentstatusvalue = 'Select';
  agentmodevalue = 'Select';
  AgentDetailsList: any[];
  agentmodalheight: Number;
  cardHeight: Number = window.innerHeight - 190;
  minDate = new Date(1900, 0, 1);
  maxDate = new Date();
  AgentConfigList: any[];
  formatType: string = "plaintext";
  @ViewChild(MonacoEditorComponent, { static: false })
  monacoComponent: MonacoEditorComponent;
  editorOptions: MonacoEditorConstructionOptions = {
    theme: "myCustomTheme",
    language: this.formatType,
    roundedSelection: true,
    autoIndent: "full",
    wordWrap: "on",
    minimap: {
      enabled: false
    },

  };
  screeHeightforinput = window.innerHeight - 379;
  editor: MonacoStandaloneCodeEditor;
  configjson: any = "";
  configid: any = "";
  agentupdate: boolean;
  AgentConfigListBackUp: any[];
  filterpipelinelist = <any>[];
  filterpipelinebackuplist = <any>[];
  isLoadingFilteredValue: boolean;
  reactiveFormPipelineFilter: FormGroup;
  configguids: string = '';
  configagentid: string = '';
  configpipelinedisplayname: string = '';
  configentitydisplayname: string = '';
  isselectedpipeline: boolean = false;
  configagentapikey: string = "";
  isLoadingroutes: boolean;
  RouteNameList: any[];
  selectedrouteId: any;
  selectedrouteDisplayName: any;
  selectedrouteuniqueid: any;
  configstatus: any;
  editConfigLockbtn: any;

  editorInit(editor: MonacoStandaloneCodeEditor) {
    this.editor = editor;
    editor.onDidChangeCursorPosition(() => {
      if (this.configjson) {
        if (this.configjson.startsWith("<")) {
          this.formatType = "xml";
        } else if (this.configjson.startsWith("{")) {
          this.formatType = "json";
        }
        this.editorOptions = {
          ...this.editorOptions,
          language: this.formatType
        };
      }

    });
  }


  constructor(private service: CommonService, private monacoLoaderService: MonacoEditorLoaderService, private _fb: FormBuilder, private ngxUiLoaderService: NgxUiLoaderService, private toastr: ToastrService, private modalService: NgbModal, private activeModal: NgbActiveModal, private router: Router) {
    this.reactiveFormagent = _fb.group({ agentname: '' });
    this.reactiveFormagenttypedl = _fb.group({ agentnamedl: '' });
    this.reactiveFormmodetype = _fb.group({ modename: '' });
    this.reactiveFormagentstatustype = _fb.group({ agentstatusname: '' });
    this.reactiveFormPipelineFilter = _fb.group({ filterpipeline: '' });
    this.reactiveFormagentstagetype = _fb.group({ stagename: '' });
    this.reactiveFormmodeagent = _fb.group({ agentmode: '' });
    this.reactiveFormtypeagent = _fb.group({ agenttype: '' });
    this.reactiveFormtypepipeline = _fb.group({ pipeline: '' });
    this.monacoLoaderService.isMonacoLoaded$.pipe(filter((isLoaded) => !!isLoaded), take(1)).subscribe(() => {
      this.registerMonacoCustomTheme();
    });
    this.agenttypelist = this.agenttypeAlllist;
  }

  registerMonacoCustomTheme() {
    monaco.editor.defineTheme("myCustomTheme", {
      base: "vs", // can also be vs or hc-black
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [
        {
          token: "comment",
          foreground: "ffa500",
          fontStyle: "italic underline"
        }, {
          token: "comment.js",
          foreground: "008800",
          fontStyle: "bold"
        }, {
          token: "comment.css",
          foreground: "0000ff"
        }, // will inherit fontStyle from `comment` above
      ],
      colors: {}
    });
  }
  pagerolepermisions: any = [];
  isflasgSize: Subscription;


  async ngOnInit() {
    this.isflasgSize = this.service.ConfigJsonFlag.subscribe(data => {
      const currentModule = this.router.url.split('/')[2];
      if (data["module"] == currentModule) {
        if (this.service.configDetails.operation == "Add") {
          this.openconfigpopup("big", 'create', '')
        }
        else {
          this.openconfigpopup("big", 'Edit', this.service.configDetails)
        }
      }
    })
    this.GetAgentTypes();
    const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'agents').MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
    actionitems.forEach(element => {
      this.pagerolepermisions[element.MenuName] = true;
    });
    this.getagenttypedllist('agenttype');
    this.reactiveFormagenttypedl.patchValue(this.agenttypeAlllist);
  }
  selectedAgent(data) {
    this.selectedAgentguid = data.id;
    this.selectedAgentName = data.name;
    this.GetAgentsDataSearch();
  }
  getAgentNames(event: any) {
    var val = event;
    if (val) {
      this.isLoadingagent = true;

      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          if ((data['status'] && data['statusText']) || data['statusText'] === "Unknown Error") {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          }
          else {
            this.AgentNameList = data as any[];
            this.AgentNameList = this.service.transformAlphabetically(this.AgentNameList, 'displayname');
            this.AgentNameList.map(obj => obj.displayname = `${obj.displayname} ${'('} ${obj.id} ${')'} ${'('} ${obj.apikey} ${')'}`);
            this.isLoadingagent = false;
            if (data.length == 0) {
              this.notfounddata = true;
            }
            else {
              this.notfounddata = false;
            }
          }
        },
        error: error => {
          this.isLoadingagent = false;
          this.notfounddata = true;
        }
      });
    }
  }
  searchAgentNameCleared() {
    this.isLoadingagent = false;
    this.AgentNameList = [];
    this.selectedAgentguid = '';
    this.notfounddata = false;
    this.AgentDetailsList = [];
  }

  GetAgentTypes() {
    var apiname = "api/Lookup/GetMessageType?lookuptype=agenttype";
    this.service.getapiurl(apiname).subscribe({
      next: data => {
        this.agenttypelistdropdown = data as any[];
        this.agenttypelistdropdown = this.agenttypelistdropdown.filter(ele => ele.toLowerCase() != "processor");
      },
    });
  }
  GetAgentsDataSearch() {
    this.ngxUiLoaderService.start();
    var agentObj = {
      id: this.selectedAgentguid ? this.selectedAgentguid : "",
      type: this.agenttype == "Select" ? "" : this.agenttype,
      status: this.agentstatusvalue == "Select" ? "" : this.agentstatusvalue.toLowerCase(),
      Mode: this.agentmodevalue == "Select" ? "" : this.agentmodevalue,
      pipelineuniqueid: this.selectedrouteuniqueid ? this.selectedrouteuniqueid : 0
    };
    var apiname = "api/Agents/GetAgentSearch";
    this.service.postapi(apiname, agentObj).then(data => {
      if (data) {
        this.AgentDetailsList = data as any[];
        if (this.AgentDetailsList.length == 0) {
          this.toastr.warning("No results found!!", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        if (this.AgentDetailsList.length > 0) {
          this.AgentDetailsList.forEach(ele => {
            if (ele["Mode"] !== "") {
              ele['Mode'] = this.agentmodelist.find(x => x.value.toLowerCase() == ele['Mode'].toLowerCase()).name;
            }
            if (ele["Stage"] !== "") {
              ele['Stage'] = this.agentstagelist.find(x => x.value.toLowerCase() == ele['Stage'].toLowerCase()).name;
            }
          })
        }

        this.ngxUiLoaderService.stop();
      }
    }
    ), error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' })
    };
  }
  reset() {
    this.reactiveFormagent.reset();
    this.reactiveFormagenttypedl.reset();
    this.reactiveFormagentstatustype.reset();
    this.reactiveFormmodetype.reset();

    this.agentmodevalue = "Select";
    this.agentstatusvalue = "Select";
    this.agenttype = "Select";
    this.AgentDetailsList = [];
    this.selectedAgentguid = "";
    this.reactiveFormagenttypedl.setValue({ agentnamedl: '' });
    this.reactiveFormagenttypedl.patchValue(this.agenttypeAlllist);
    this.reactiveFormagentstatustype.setValue({ agentstatusname: '' });
    this.reactiveFormagentstatustype.patchValue(this.agentstagelist);
    this.reactiveFormmodetype.setValue({ modename: '' });
    this.reactiveFormmodetype.patchValue(this.agentmodelist);
    this.reactiveFormtypepipeline.reset();
    this.selectedrouteId = '';
    this.selectedrouteDisplayName = '';
    this.selectedrouteuniqueid = '';
    this.RouteNameList = [];
  }
  agentObj: any;
  agenttitle: any;
  openaddoreditagent(data, modal) {
    this.ngxUiLoaderService.start();
    this.reactiveFormmodeagent.reset();
    this.getagenttypedllist('agenttype');
    this.reactiveFormagentstagetype.reset();
    this.reactiveFormmodeagent.setValue({ agentmode: '' });
    this.reactiveFormagentstagetype.setValue({ stagename: '' });
    this.reactiveFormtypeagent.reset();
    this.reactiveFormtypeagent.setValue({ agenttype: '' });
    if (data) {
      if (data.Stage) {
        data.Stage = this.agentstagelist.find(x => x.value.toLowerCase() == data.Stage.toLowerCase()).name;
        this.reactiveFormagentstagetype.patchValue({
          stagename: this.agentstagelist.find(x => x.value.toLowerCase() == data.Stage.toLowerCase()).name
        });
      }
      if (data.Mode) {
        data.Mode = this.agentmodelist.find(x => x.value.toLowerCase() == data.Mode.toLowerCase()).name;
        this.reactiveFormmodeagent.patchValue({
          agentmode: this.agentmodelist.find(x => x.value.toLowerCase() == data.Mode.toLowerCase()).name
        })
      }
      if (data.type) {
        this.reactiveFormtypeagent.patchValue({ agenttype: data.type });
      }
      this.agentObj = JSON.parse(JSON.stringify(data));

      // this.agentObj=data;
      this.agenttitle = "Edit Agent";
      this.agentmodalheight = window.innerHeight - 74;
      this.agentupdate = true;
      this.getcoreconfigforagent();
    }
    else {
      this.agenttitle = "Add Agent";
      this.agentmodalheight = window.innerHeight - 385;
      this.agentObj = {
        id: uuidv4().toUpperCase(),
        name: '',
        type: '',
        apikey: uuidv4().toUpperCase(),
        Version: '',
        HeartbeatUrl: '',
        Ownerentity: '',
        description: '',
        Stage: '',
        Mode: '',
        Releasedate: new Date(),
        Livedate: new Date(),
        modifierid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      }
    }
    if (this.agentupdate) {
      this.modalService.open(modal, { windowClass: "agentmodaledit", backdrop: 'static', keyboard: false }).result.then((result) => { });
    }
    else {
      this.modalService.open(modal, { windowClass: "agentmodaladd", backdrop: 'static', keyboard: false }).result.then((result) => { });
    }

    this.ngxUiLoaderService.stop();
  }
  closeagentpopup(c) {
    c('close modal');
    this.clearvalue();
    this.getagenttypedllist('agenttype');
  }

  clearvalue() {
    this.agentupdate = false;
    this.configid = "";
    this.configjson = "";
    this.AgentConfigList = [];
    this.agentObj = "";
    this.selectedAgentguid = '';
    this.AgentConfigListBackUp = [];
    this.filterpipelinebackuplist = [];
    this.filterpipelinelist = [];
    this.configguids = "";
    this.configagentid = "";
    this.configentitydisplayname = "";
    this.configpipelinedisplayname = "";
    this.isselectedpipeline = false;
    this.reactiveFormPipelineFilter.reset();
    this.configagentapikey = "";
    this.configstatus = "";
  }
  getcoreconfigforagent() {
    this.ngxUiLoaderService.start();
    var dataPost = {
      agentid: this.agentObj.id,
      filtertype: 'Agent'
    };
    this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost).then(Response => {
      if (Response) {
        if ((Response['status'] && Response['statusText']) || Response['statusText'] === "Unknown Error") {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        }
        else {
          this.AgentConfigList = Response as any[];
          this.AgentConfigListBackUp = this.AgentConfigList;
          if (this.AgentConfigList.length > 0) {
            this.AgentConfigList.forEach(ele => {
              if (ele.pipelinedisplayname) {
                let obj = {
                  name: ele.pipelinedisplayname,
                  displayname: `${ele.pipelinedisplayname}(${ele.guids})`,
                  id: ele.guids
                }
                this.filterpipelinelist.push(obj);
              }

            });
            if (this.filterpipelinelist.length > 0) {
              this.filterpipelinelist = [...new Map(this.filterpipelinelist.map(item =>
                [item["id"], item])).values()];
              this.filterpipelinebackuplist = this.filterpipelinelist;
            }
            this.reactiveFormPipelineFilter.setValue({ "filterpipeline": "" });
            this.ngxUiLoaderService.stop();
          }
        }
      }
    }, error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    })
  }

  editconfigjson(data, array) {
    array.forEach(ele => {
      ele["active"] = false;
    })
    if (data.json != "{}" && data.json) {
      data["active"] = true;
      this.configjson = JSON.stringify(JSON.parse(data.json), null, 2);
    }
    this.configagentid = data.agentid;
    this.configagentapikey = data.agentapikey;
    this.configid = data.configid;
    this.configguids = data.guids;
    this.editConfigLockbtn = data.isPipelineLocked;
    this.configpipelinedisplayname = data.pipelinedisplayname;
    this.configentitydisplayname = data.entitydisplayname;
    this.configstatus = data.status;
  }
  saveagent(modifystatus, modal) {
    this.agentObj.Stage = this.agentstagelist.find(x => x.value.toLowerCase() == this.agentObj.Stage.toLowerCase()).value;
    this.agentObj.Mode = this.agentmodelist.find(x => x.value.toLowerCase() == this.agentObj.Mode.toLowerCase()).value;

    if (modifystatus) {
      this.agentObj.status = this.agentObj.status == 'active' ? 'passive' : 'active';
    }
    if (this.agentObj.id && this.agentObj.apikey && this.agentObj.name && this.agentObj.type) {
      this.ngxUiLoaderService.start();
      this.agentObj["modifierid"] = JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
      this.service.postapi("api/Agents/SaveAgent", this.agentObj).then(response => {
        if (response) {
          if ((response['status'] && response['statusText']) || response['statusText'] === "Unknown Error") {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          }
          else {
            this.GetAgentsDataSearch();

            if (modifystatus) {
              if (this.agentObj.status == 'active') {
                this.ngxUiLoaderService.stop();
                this.toastr.success("Agent Activated Successfully", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              }
              else {
                this.ngxUiLoaderService.stop();
                this.toastr.success("Agent Disabled Successfully", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              }
            }
            else if (this.agentupdate) {

              this.ngxUiLoaderService.stop();
              this.toastr.success("Agent Updated Successfully", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              this.closeagentpopup(modal);
            }
            else {
              this.ngxUiLoaderService.stop();
              this.toastr.success("Agent Created Successfully with Agent ID " + this.agentObj.id.toUpperCase(), "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              this.closeagentpopup(modal);
            }
          }
        }
        else {
          this.ngxUiLoaderService.stop();
        }
      }), error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' })
      }
    }
    else {
      this.toastr.warning("Please enter the required fields", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    }
  }

  copyToClip(event: any, message) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + 'Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  buildtitleforagent() {
    if (this.agentupdate) {
      return 'Update Agent';
    }
    else {
      return 'Add Agent'
    }
  }
  openAuditPopUp(uniqueid: any, agentguid: any, agentname: any) {

    if (uniqueid != '' && agentguid != '') {
      const auditDataToModal = {
        id: uniqueid,
        title: agentname,
        context: "Agent",
        type: "ID",
        guid: agentguid,
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };

      const modalRef = this.modalService.open(NewauditComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(() => { });
    }

  }
  onDataChange(newdate, obj) {
    const currentTimeObj = moment();
    const date = moment(newdate).add({ hours: 5, minutes: 30, seconds: 0 });
    this.agentObj[obj] = date.toDate();
  }



  selectedagenttypedlevent(item) {
    this.agenttype = item.displayname;
  }
  CleareAgentdl(event) {
    this.agenttype = '';
  }
  agenttypelistdropdown: any = [];
  agenttypeAlllist: any = [];
  isLoadingagenttypedl: boolean = false;
  getagenttypedllist(event: any) {
    this.isLoadingagenttypedl = true;
    if (event !== 'agenttype') {
      if (event == undefined) {
        this.agenttypelist = this.agenttypeAlllist;
      }
      else {
        this.agenttypelist = this.agenttypeAlllist.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.agenttypelist.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingagenttypedl = false;
    }
    else {

      this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=agenttype").subscribe({
        next: data => {
          this.agenttypeAlllist = [];
          // this.agenttypelistdropdown = data.filter(ele => ele.toLowerCase() != "processor");
          data.forEach(element => {
            if (element.toLowerCase() != "processor".toLowerCase()) {
              this.agenttypeAlllist.push({ displayname: element });
            }
          });
          this.agenttypelist = this.agenttypeAlllist;
          this.isLoadingagenttypedl = false;
          if (this.agenttypelist.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingagenttypedl = false;
          this.notfounddata = true;
        }
      });
    }


  }


  keyword = "name";


  async selectagentstatusEvent(item) {
    this.agentstatusvalue = item.name;
  }

  clearedagentstatus(item) {
    this.agentstatusvalue = '';
  }

  public agentstagelist = [
    {
      name: 'Live',
      value: 'Live'.toLocaleLowerCase()
    },
    {
      name: 'Test',
      value: 'Test'.toLocaleLowerCase()
    }
  ];

  public agentstatus = [
    {
      name: 'Active',
    },
    {
      name: 'Passive',
    }
  ];
  onagentstatusFocused(e) {
    this.agentstatus = [
      {
        name: 'Active',
      },
      {
        name: 'Passive',
      }
    ];

  }



  async selectmodeEvent(item) {
    this.agentmodevalue = item.name;
  }

  clearednodestatus(item) {
    this.agentmodevalue = '';
  }

  public agentmodelist = [
    {
      name: 'Live',
      value: 'Live'.toLocaleLowerCase()
    },
    {
      name: 'Stage',
      value: 'Stage'.toLocaleLowerCase()
    },
    {
      name: 'Test',
      value: 'Test'.toLocaleLowerCase()
    }
  ];
  onmodeFocused(e) {
    this.agentmodelist = [
      {
        name: 'Live',
        value: 'Live'.toLocaleLowerCase()
      },
      {
        name: 'Stage',
        value: 'Stage'.toLocaleLowerCase()
      },
      {
        name: 'Test',
        value: 'Test'.toLocaleLowerCase()
      }
    ];

  }
  openconfigpopup(isflag: string, type: string, item) {
    this.ngxUiLoaderService.start();
    if (isflag == "small") {
      let data = {};
      if (type == "create") {
        data = {
          pipelineid: '',
          pipelinedisplayname: '',
          entitydisplayname: '',
          editjson: "small",
          type: "create",
          configlist: this.AgentConfigList,
          disablepipeline: false,
          agentdisplayname: this.agentObj.name,
          agentid: this.agentObj.id,
          disableagent: true,
          guidtype: '',
          agentuniqueid: this.agentObj.UniqueID
        }
      }
      else {
        data["configid"] = item.configid;
        data["agentid"] = item.agentid;
        data["json"] = item.json;
        if (item["guids"] && item["pipelinedisplayname"]) {
          data["pipelineid"] = item.guids;
          data["pipelinedisplayname"] = item.pipelinedisplayname;
          data["guidtype"] = "Pipeline";
        }
        else if (item["guids"] && item["entitydisplayname"]) {
          data["guids"] = item["guids"];
          data["entitydisplayname"] = item["entitydisplayname"];
          data["guidtype"] = "Entity";
          data["entitytype"] = item["entitytype"];
          data["entityaccounttype"] = item["entityaccounttype"];
          data["entitydeploymentkey"] = item["entitydeploymentkey"];
          data["entityaddress1"] = item["entityaddress1"];
          data["entityaddress2"] = item["entityaddress2"];
          data["entitycity"] = item["entitycity"];
          data["entitystate"] = item["entitystate"];
          data["entityzip"] = item["entityzip"];
        }
        else if (item["guids"]) {
          data["guids"] = item["guids"];
          data["guidtype"] = "Custom";
        }
        else {
          data["guids"] = '';
        }
        data["agentdisplayname"] = item.agentdisplayname;
        data["disableagent"] = true;
        data["editjson"] = "small";
        data["type"] = "Edit";
        data["disablepipeline"] = false;
        data["status"] = item.status;
        data["agentuniqueid"] = this.agentObj.UniqueID;
        data["agentapikey"] = item.agentapikey
      }

      const modalRef = this.modalService.open(ConfigComponent,
        { windowClass: "modalConfig", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = data;
      modalRef.result.then((data) => {
        if (data == "refresh") {
          this.ngxUiLoaderService.start();
          this.getcoreconfigforagent();
        }
      });
    } else {
      let data = {
        editjson: "big",
        type: type
      }
      if (type != "create" && item == '') {
        data["configid"] = this.configid;
        data["agentid"] = this.configagentid;
        data["json"] = this.configjson;
        data["guids"] = this.configguids ? this.configguids : ''
        data["status"] = this.configstatus;
      }
      else if (type != "create" && item != '') {
        data["configid"] = item.configid;
        data["agentid"] = item.agentid;
        data["json"] = item.json;
        data["guids"] = item.guids;
        data["status"] = item.status;
      }
      data["agentuniqueid"] = this.agentObj.UniqueID;
      data["agentapikey"] = item.agentapikey ? item.agentapikey : this.configagentapikey
      const modalRef = this.modalService.open(ConfigComponent,
        { windowClass: "modalConfigJSON", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = data;
      modalRef.result.then((data) => {
        if (data == "refresh") {
          this.ngxUiLoaderService.start();
          this.configagentid = "";
          this.configid = "";
          this.configjson = "";
          this.configstatus = "";
          this.configagentapikey = "";
          this.getcoreconfigforagent();
          this.ngxUiLoaderService.stop();
        }
      });
    }
    this.ngxUiLoaderService.stop();
  }

  getFilteredValues(event) {
    if (event) {
      this.isLoadingFilteredValue = true;
      this.ngxUiLoaderService.start();
      if (this.filterpipelinelist.length == 0) {
        this.filterpipelinelist = this.filterpipelinebackuplist;
      }
      let isfound = false;
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      if (regexExp.test(event)) {
        isfound = true;
        this.filterpipelinelist = this.filterpipelinelist.filter(x => x.id == event);
      }
      else {
        isfound = true;
        this.filterpipelinelist = this.filterpipelinelist.filter(x => x.name.toLowerCase().includes(event.toLowerCase()));
      }
      if (isfound) {
        this.configagentid = "";
        this.configguids = "";
        this.configid = "";
        this.configjson = "";
        this.configstatus = '';
        if (this.filterpipelinelist.length == 0) {
          this.notfounddata = true;
        }
        this.isLoadingFilteredValue = false;
      }
      this.ngxUiLoaderService.stop();
    }
  }
  selectedFilteredValues(item) {
    if (item) {
      this.isselectedpipeline = true;
      this.AgentConfigList = this.AgentConfigList.filter(x => (x.guids != "") && (x.guids == item.id));
    }
  }
  searchFilteredValuesCleared(item) {
    if (this.AgentConfigList.length != this.AgentConfigListBackUp.length) {
      this.configagentid = "";
      this.configguids = "";
      this.configid = "";
      this.configjson = "";
      this.configstatus = '';
      this.isselectedpipeline = false;
      this.AgentConfigList = this.AgentConfigListBackUp;
    }
    this.filterpipelinelist = this.filterpipelinebackuplist;
    this.notfounddata = false;
    this.reactiveFormPipelineFilter.reset();
    this.reactiveFormPipelineFilter.setValue({ "filterpipeline": "" })
  }
  selectstageEvent(event) {
    this.agentObj.Stage = event.name;
  }
  clearedstatge() {
    this.agentObj.Stage = "";
  }
  selectagentmode(event) {
    this.agentObj.Mode = event.name;
  }
  clearedagentmode() {
    this.agentObj.Mode = "";
  }
  selectedagenttypeevent(item) {
    this.agentObj.type = item.displayname;
  }
  CleareAgentType() {
    this.agentObj.type = "";
  }
  getRouteName(event: any) {
    var val = event;
    if (val) {
      this.isLoadingroutes = true;
      var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=0";
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.RouteNameList = data as any[];
          this.RouteNameList.map(obj => obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}`);
          this.isLoadingroutes = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingroutes = false;
          this.notfounddata = true;
        }
      });
    }
  }
  selectedRoute(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
    this.selectedrouteuniqueid = item.UniqueID;
    this.GetAgentsDataSearch();
  }
  searchRouteNameCleared(event) {
    this.isLoadingroutes = false;
    this.RouteNameList = [];
    this.selectedrouteId = '';
    this.selectedrouteDisplayName = '';
    this.notfounddata = false;
  }
  ngOnDestory() {
    this.isflasgSize.unsubscribe()
  }
  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]}`
    return value
  }

}




import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { Location } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
  FormArray,
  AbstractControl
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { COMMA, ENTER, V, X } from "@angular/cdk/keycodes";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, of } from "rxjs";
// import {
//   setInterval,
//   clearInterval
// } from 'timers';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { NgxUiLoaderService } from "ngx-ui-loader";
import * as XLSX from "xlsx";
import { MatSelect } from "@angular/material/select";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MatCheckbox, MatOption } from "@angular/material";
import { SearchPipelineComponent } from "src/app/search-pipeline/search-pipeline.component";
import { CommonService } from "src/app/services/common.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SavevendorComponent } from "src/app/vendor/Savevendor/savevendor.component";
import { debug, error } from "console";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImportService } from "src/app/services/importResolver.service";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Routeversion1Component } from "src/app/routeversion1/routeversion1.component";
import { MailComponent } from "src/app/mail/mail.component";
import { NewauditComponent } from '../../newaudit/newaudit.component';

// import { Editor, Toolbar } from 'ngx-editor';
const { parse, stringify } = require('flatted/cjs');
declare var $;
declare const copyToClipboard: any;
interface ConnectivityType {
  viewValue: string;
  value: string;
}
type AOA = any[][];
@Component({
  selector: "app-request-form",
  templateUrl: "./request-form.component.html",
  styleUrls: ["./request-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RequestFormComponent implements OnInit {
  ftpbase64:any
isftpmail:boolean
selectedIndexTab=0;
filenamedisplay:string='result3.pdf'
editorConfig: AngularEditorConfig = {
  editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ],
  uploadWithCredentials: false,
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    ['link'],
    ['unlink'],
    ['insertImage'],
    ['insertVideo'],
    ['insertHorizontalRule'],
    ['removeFormat'],
  ]
};
  _file: SafeUrl;
  html2='<h4>hi<h4>';
  html=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,</span></span></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Please find the attached file for FTP Information. </span></span></span></span></p>
  <p>&nbsp;</p>
  <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:10.0000pt"><span style="font-family:Calibri">Note: Attached file is password protected for the security purposes. Please refer to the following email to locate the password.</span></span></span></span></p>
  <p>&nbsp;</p>
  <p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>Thanks &amp; Regards</strong></span></span></strong></span></span></p>
  <p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>VitalAxis</strong></span></span></strong></span></span></p>`

  @ViewChild("fileInput", { static: false }) myFileInput;
  @ViewChild("select", { static: false }) select: MatSelect;
  @ViewChild('tomailInput', { static: true }) tomailInput: ElementRef<HTMLInputElement>;
  @ViewChild('CcInput', { static: true }) CcInput: ElementRef<HTMLInputElement>;
  @ViewChild('closebutton', { static: false }) closebutton;
  openvendorshow: boolean;
  separatorKeysCodeslabs: number[] = [ENTER, COMMA];
  LabCtrl = new FormControl("");
  projectNameGroup : FormGroup;
  filteredLabs: Observable<string[]>;
  Labs: string[] = [];
  projFiles: any[] = new Array();
  allFacility: string[] = [];
  payingLabs: string[] = [];
  allLabs: string[] = ["Lab1", "Lab2", "Lab3", "Lab4", "Lab5"];
  allLabsImp: string[] = []
  allLabsExp: string[] = []
  viewdata: any;
  ViewInterfaceData: any[] = [];
  importDataList: any[] = [];
  exportDataList: any[] = [];
  isTabName: any = '';
  @ViewChild("LabInput", { static: true }) LabInput: any;
  title = "InterfaceRequestForm";
  formdata: any;
  isLoadingVendorName: Boolean = false;
  isLoadingVendorNameImp: Boolean = false;
  display: any = "hi";
  vendarnamevalue: string = "";
  vendarnamevalueImp: string = "";
  vendarnamevalueExp: string = "";
  vendorid: any;
  vendoridImp: any;
  modelradio: any = {};
  VendorsList = [];
  VendorsListImp = [];
  VendorsListExp = [];
  VendornameBackuplist: any;
  VendornameBackuplistImp: any;
  VendornameBackuplistExp: any;
  keywordsearchvendor = "name";

  objvendorid: number;
  objid: number;
  interfaceFormIdResponse: any;

  selectedlabobj: any;
  selectedpaylabobj: any;
  selectedfacilityobj: any;
  deploy = new FormControl("");
  displaydeploy: any;
  displaydeploy2: any;
  isLabImportLoading: boolean;
  isLabLoading: boolean;
  isPLabLoading: boolean;
  isFacilityLoading: boolean;
  isMsginLoading: boolean;
  isProcessorinLoading: boolean;
  isMsgoutLoading: boolean;
  isProcessoroutLoading: boolean;

  isInbound: boolean;
  isOutbound: boolean;
  isjsonData = [];
  isEditLabGUID: any;
  isEditFaciltyGUID: any;
  isEditPayingGUID: any;
  isEditformsData: any;
  isShowCard = false;
  DocumentName: any[] = [];
  intervalim=null;
  selectedPID: any;
  selectedBatchID: any;
  vendorRefresh: any;
  selectedlabName() { }
  isLoadingroutes: boolean;
  notfounddata: boolean;
  isLoadingroutes1: boolean;
  notfounddata1: boolean;
  notfounddata2: boolean;
  FacilityNameList = <any>[];
  labNameList = <any>[];
  labNameImpList = <any>[];
  paylabNameList = <any>[];

  keywordsearch = "displayname";
  keywordsearch1 = "displayname1";
  filteredVendor: Observable<string[]>;
  filteredVendorImp: Observable<string[]>;
  filteredVendorExp: Observable<string[]>;
  filteredFacility: Observable<string[]>;
  filteredLabsImp: Observable<string[]>;
  filteredLabsExp: Observable<string[]>;

  isLinear = false;
  formid: any;
  filelab: any;
  data: AOA = [
    [1, 2],
    [3, 4],
  ];
  editdata: any;

  separatorKeysCodespayinglabs: number[] = [ENTER, COMMA];
  payingLabCtrl = new FormControl("");
  filteredpayingLabs: Observable<string[]>;
  allpayingLabs: string[] = ["Lab1", "Lab2", "Lab3", "Lab4", "Lab5"];
  @ViewChild("payingLabInput", { static: true }) payingLabInput: any;

  Ctypes: ConnectivityType[] = [
    { value: "API", viewValue: "API" },
    { value: "FTP", viewValue: "VA-FTP" },
    { value: "VPN", viewValue: "VPN" },
    { value: "External FTP", viewValue: "External FTP" },
    { value: "NONE", viewValue: "None" }

  ];
  allSelected = false;
  IType = [];
  LabFormGroup: FormGroup = this._formBuilder.group({
    lab: [this.Labs, Validators.required],
    payingLab: [this.payingLabs, Validators.required],
    interfaceBetween: ["", Validators.required],
    labContactdetails: this._formBuilder.array([]),
  });
  LabFormGroupImport: FormGroup = this._formBuilder.group({
    LabImport: [this.Labs, Validators.required],
    labContactdetailsImport: new FormArray([]),
  });
  LabFormGroupExport: FormGroup = this._formBuilder.group({
    LabExport: [this.Labs, Validators.required],
    labContactdetailsExport: new FormArray([]),
  });
  FacilityFormGroup: FormGroup = this._formBuilder.group({
    facilityName: ["", Validators.required],
    facilityaddress: [""],
    facilityContactdetails: this._formBuilder.array([]),
  });
  requesterFormGroup: FormGroup = this._formBuilder.group({
    requesterName: ["", Validators.required],
    requesterEmail: ["", Validators.required],
    requesterPhone: [""],
    // action:"add"
  });
  requesterFormGroupImport: FormGroup = this._formBuilder.group({
    requesterImpName: ["", Validators.required],
    requesterImpEmail: ["", Validators.required],
    requesterImpPhone: [""],
  });
  requesterFormGroupExport: FormGroup = this._formBuilder.group({
    requesterExpName: ["", Validators.required],
    requesterExpEmail: ["", Validators.required],
    requesterExpPhone: [""],
  });
  projectFormGroupImport: FormGroup = this._formBuilder.group({
    projectImpName: ["", Validators.required],
    projectImpScope: [""],
  });
  projectFormGroupExport: FormGroup = this._formBuilder.group({
    projectExpName: ["", Validators.required],
    projectExpScope: ["", Validators.required],
  });
  VendorFormGroup: FormGroup = this._formBuilder.group({
    vendorName: [""],
    ApplicationName: [""],
    ApplicationVersion: [""],
    vendorContactdetails: this._formBuilder.array([]),
  });
  VendorFormGroupImport: FormGroup = this._formBuilder.group({
    vendorNameImp: [""],
    ApplicationNameImp: [""],
    ApplicationVersionImp: [""],
    vendorImpContactdetails: this._formBuilder.array([]),
  });

  VendorFormGroupExport: FormGroup = this._formBuilder.group({
    vendorNameExp: [""],
    ApplicationNameExp: [""],
    ApplicationVersionExp: [""],
    vendorExpContactdetails: this._formBuilder.array([]),
  });
  DetailsFormGroup: FormGroup = this._formBuilder.group({
    projName: [""],
    ProjInterfaceType: "",
    projScopeRequirement: [""],
    specialityCaseType: [""],
    specificDocs: [""],
    kickOffDate: [""],
    goLiveDate: [""],
    otherComments: [""],
    projDocuments: [""],
  });

  ConnectivityFormGroup: FormGroup = this._formBuilder.group({
    // ConnectivityType: [""],
    // Description: "",
    // ContentType: [""],
    // vpnIp: "",
    // vpnHost: "",
    // vpnPort: "",
    // externalFtpUsername: "",
    // externalFtpPassowrd: "",
    // externalFtpHost: "",
    // externalFtpFolderPath: "", //For Inbound
    // externalFtpRemotePath: "",   // For Outbound
    // externalFtpArchivePath: "",
    // externalFtpPort: "",
    ProjInterfacecard: this._formBuilder.array([]),

    // ModeType: [""],
    // UserName: [""],
    // Password: [""],
    // Port: [""],
    // Host: [""],
    // Path: [""],
    // FacilityConn: [""],
    // LabConn: [""],
    // DeploymentConn: [""],
    // }
  });

  searchfacilityCleared(event) {
    this.isLoadingroutes = false;
    this.FacilityNameList = [];
    this.notfounddata = false;
  }

  ConnFormJson = {};

  constructor(
    private _location: Location,
    public sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder,
    public ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private commonService: CommonService,
    private ngxuiloader: NgxUiLoaderService,
    private actr: ActivatedRoute,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private router: Router,
    private improute: ImportService
  ) {
    this.projectNameGroup = this._formBuilder.group({
      projectName : "",
      type : "",
      vendorID : "",
      labGuid : ""
  })


    this.reactiveFormvendar = this._formBuilder.group({ namevendar: "" });
    this.filteredVendor = this.vendorNameControl.valueChanges.pipe(
      startWith(null),
      map((vendorName: string | null) =>
        vendorName ? this._filterVendor(vendorName) : this.VendorsList.slice()
      )
    );
    this.filteredLabs = this.labControl.valueChanges.pipe(
      startWith(null),
      map((lab: string | null) =>
        lab ? this._filterLab(lab) : this.allLabs.slice()
      )
    );
    this.filteredpayingLabs = this.payinglabControl.valueChanges.pipe(
      startWith(null),
      map((payinglab: string | null) =>
        payinglab
          ? this._filterpayingLab(payinglab)
          : this.allpayingLabs.slice()
      )
    );
    this.filteredFacility = this.facilityControl.valueChanges.pipe(
      startWith(null),
      map((facilityName: string | null) =>
        facilityName ? this._filterLab(facilityName) : this.allFacility.slice()
      )
    );
    this.filteredLabsImp = this.LabImportControl.valueChanges.pipe(
      startWith(null),
      map((labImport: string | null) =>
        labImport ? this._filterLabImp(labImport) : this.allLabsImp.slice()
      )
    );
    this.filteredLabsExp = this.LabExportControl.valueChanges.pipe(
      startWith(null),
      map((labExport: string | null) =>
        labExport ? this._filterLabExp(labExport) : this.allLabsExp.slice()
      )
    );
    this.filteredVendorImp = this.vendorNameImpControl.valueChanges.pipe(
      startWith(null),
      map((vendorNameImp: string | null) =>
        vendorNameImp ? this._filterVendorImp(vendorNameImp) : this.VendorsListImp.slice()
      )
    );
    this.filteredVendorExp = this.vendorNameExpControl.valueChanges.pipe(
      startWith(null),
      map((vendorNameExp: string | null) =>
        vendorNameExp ? this._filterVendorExp(vendorNameExp) : this.VendorsListExp.slice()
      )
    );
  }
  venMnemonic: string
  vendoName: string
  vendoID: any
  private _filterVendor(value: string): string[] {
    let exist
    // if(this.openvendorshow = false){
    const filterVendorValue = value.toLowerCase();
    if(this.VendorsList !== undefined && this.VendorsList.length>0){
      exist = this.VendorsList.filter((vendorName) =>
      vendorName.name.toLowerCase().includes(filterVendorValue)
    );
    }

    // }
    // else{
    //   this.openvendorshow = true;
    //   // this.openvendorModalpopup(value);
    // }
    if (exist.length > 0) {
      this.openvendorshow = false;
      // this.venMnemonic = exist[0].mnemonic
      // this.vendoName = exist[0].name
      // this.vendoID = exist[0].Id
      return exist;
    } else {
      this.openvendorshow = true;
      return [];
    }
  }

  openvendorshowImp: boolean
  venMnemonicImp: string
  vendoNameImp: string
  vendoIDImp: any
  private _filterVendorImp(value: string): string[] {
    let exist
    this.openvendorshowImp = false;
    // if(this.openvendorshowImp = false){
    const filterVendorValue = value.toLowerCase();
    if(this.VendorsListImp !== undefined &&this.VendorsListImp.length>0){
      exist = this.VendorsListImp.filter((vendorName) =>
      vendorName.name.toLowerCase().includes(filterVendorValue)
    );
    }

    if (exist.length > 0) {
      this.openvendorshowImp = false;
      // this.venMnemonic = exist[0].mnemonic
      // this.vendoName = exist[0].name
      // this.vendoID = exist[0].Id
      return exist;
    } else {
      this.openvendorshowImp = true;
      return [];
    }
    // }
    // else{
    //   this.openvendorshowImp = true;
    // }
    // if (exist.length > 0) {
    //   this.openvendorshowImp = false;
    //   // this.venMnemonicImp = exist[0].mnemonic
    //   // this.vendoNameImp = exist[0].name
    //   // this.vendoIDImp = exist[0].Id
    //   return exist;
    // } else {
    //   this.openvendorshowImp = true;
    // }
  }
  openvendorshowExp: boolean
  venMnemonicExp: string
  vendoNameExp: string
  vendoIDExp: any
  private _filterVendorExp(value: string): string[] {
    let exist

    // if(this.openvendorshowExp = false){
    const filterVendorValue = value.toLowerCase();
    if(this.VendorsListExp !== undefined &&this.VendorsListExp.length>0){
      exist= this.VendorsListExp.filter((vendorName) =>
      vendorName.name.toLowerCase().includes(filterVendorValue)
    );
    }

    // }
    // else{
    //   this.openvendorshowExp=true;
    // }
    if (exist.length > 0) {
      this.openvendorshowExp = false;
      // this.venMnemonicExp = exist[0].mnemonic
      // this.vendoNameExp = exist[0].name
      // this.vendoIDExp = exist[0].Id
      return exist;
    } else {
      this.openvendorshowExp = true;
      return [];
    }
  }

  private _filterLabImp(value: string): string[] {
    const filterlabImpValue = value.toLowerCase();
    return this.allLabsImp.filter((labImp) =>
      labImp.toLowerCase().includes(filterlabImpValue)
    );
  }
  private _filterLabExp(value: string): string[] {
    const filterlabExpValue = value.toLowerCase();
    return this.allLabsExp.filter((labExp) =>
      labExp.toLowerCase().includes(filterlabExpValue)
    );
  }

  venID: string
  venName: string
  openvendorModalpopup(event) {
    if (this.openvendorshow == true) {
      this.openVendorPopup();
    } else {
      var vid = this.VendorsList.find((va) => va.name == event.option.value);
      this.venMnemonic = vid.mnemonic
      this.vendoName = vid.name
      this.vendoID = vid.Id
    }
  }

  venImpID: string
  venImpName: string
  openvendorImpModalpopup(event) {
    if (this.openvendorshowImp) {
      this.openVendorImportPopup();
    } else {
      var vid = this.VendorsListImp.find((va) => va.name == event.option.value);
      this.venMnemonicImp = vid.mnemonic
      this.vendoNameImp = vid.name
      this.vendoIDImp = vid.Id
    }
  }

  venExpID: string
  venExpName: string
  openvendorExpModalpopup(event) {

    if (this.openvendorshowExp) {
      this.openVendorExportPopup();
    } else {
      var vid = this.VendorsListExp.find((va) => va.name == event.option.value);
      this.venMnemonicExp = vid.mnemonic
      this.vendoNameExp = vid.name
      this.vendoIDExp = vid.Id
    }
  }

  getNewConnCard(index) {

    let values = this.ConnectivityFormGroup.value.ProjInterfacecard
   let uniq = values.filter(va => va.interfaceType == index)
   if(uniq.length == 0 || uniq === undefined) {
    let forcon = this._formBuilder.group({
      interfaceType:index,
      ContentType: "",
      ConnectivityType: "",
      Description: "",
      vpnIp: "",
      vpnHost: "",
      vpnPort: "",
      externalFtpUsername: "",
      externalFtpPassowrd: "",
      externalFtpHost: "",
      externalFtpFolderPath: "", //For Inbound
      externalFtpRemotePath: "",   // For Outbound
      externalFtpArchivePath: "",
      externalFtpPort: "",

    });
    this.onAddNewCard().push(forcon);
  }
    // this.onAddNewCard[index] = forcon
    // this.onAddNewCard[index].InterfaceName = name
  }

  onAddNewCard(): FormArray {
    return this.ConnectivityFormGroup.get("ProjInterfacecard") as FormArray;
  }

  removeConnCard(i) {
    this.onAddNewCard().removeAt(i);
  }

  handleFileInput(evt: any) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = evt.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, {
        type: "binary",
        cellText: false,
        cellDates: true,
      });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet, {
          dateNF: "yyyy-mm-dd",
        });
        return initial;
      }, {});

      //////////
      this.isjsonData = jsonData.Sheet1;
    };
    setTimeout(() => {
      this.isjsonData;
      this.commonService.insertInterfacefromFile(this.isjsonData).subscribe(
        (data) => {
          if (data !== null) {
            this.ngxuiloader.stop();
            this.toastr.success("Uploaded Successfully", "Success", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
            this.getFormListdata(0);
          } else {
            this.ngxuiloader.stop();
            this.toastr.error("Failed to Upload", "Failed", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
          }
        },
        (error) => {
          this.ngxuiloader.stop();
          this.toastr.error("Failed to Upload", "Failed", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
      );
    });
    reader.readAsBinaryString(file);
    this.myFileInput.nativeElement.value = "";
  }

  projImpID: any
  ImpID: any
  patchformImpValue(value: any) {
    let isValue = JSON.parse(value);
    this.projImpID = isValue.projectID;
    this.ImpID = isValue.id
    this.labImportPatch(value);
    this.reqImportPatch(value);
    this.projImportPatch(value);
    this.venImportPatch(value);
  }

  patchformExpValue(value: any) {
    let isValue = JSON.parse(value);
    this.projExpID = isValue.projectID;
    this.ExpID = isValue.id
    this.labExportPatch(value);
    this.reqExportPatch(value);
    this.projExportPatch(value);
    this.venExportPatch(value);
  }


  // patchLabContact(){
  //
  //   let labContCntrl = this.LabFormGroup.get('labContactDetails') as FormArray;
  //   this.LabFormGroup.value.labContactDetails.forEach(x=>{
  //     labContCntrl.push(this._formBuilder.group({
  //       name:x.name,
  //       role:x.role,
  //       phone:x.phone,
  //       email:x.email
  //     }))
  //   })
  // }

  isEditLabExpGUID: string
  labExpContID: any
  labExpId: number
  labExportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.LabExportControl.patchValue(isValue.sourceName);
    this.isEditLabExpGUID = isValue.sourceGuid
    this.LabFormGroupExport.patchValue({
      LabExport: this.LabExportControl
    });
    if (isValue.contactDetails !== "") {
      let contExp = JSON.parse(isValue.contactDetails);
      let Expobj = contExp.filter((x) => x.ContactType === "lab")
      if (Expobj.length > 0) {
        for (let i = 0; i < Expobj.length; i++) {
          this.labExpContID = Expobj[i].contactid
          let expGroup = this._formBuilder.group({
            name: Expobj[i].ContactName,
            role: Expobj[i].role,
            phone: Expobj[i].phonenumber,
            email: Expobj[i].emailid,
            id: this.labExpContID !== undefined ? this.labExpContID : this.labExpId,
            action: "",
          });
          this.addlabcontactExportfield().push(expGroup)
        }
        this.LabFormGroupExport.patchValue({
          labContactdetailsExport: this.addlabcontactExportfield().value
        })
      }
    }
  }

  reqExpId: any
  reqExportPatch(value: any) {
    let isValue = JSON.parse(value);
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let reqobj = contlab.filter((x) => x.ContactType === "requester");
      if (reqobj.length > 0) {
        this.reqExpId = reqobj[0].contactid
        this.requesterFormGroupExport.patchValue({
          requesterExpName: reqobj[0].ContactName,
          requesterExpEmail: reqobj[0].emailid,
          requesterExpPhone: reqobj[0].phonenumber,
          id: this.reqExpId
        });
      }
    }
  }

  projExportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.projectFormGroupExport.patchValue({
      projectExpName: isValue.projectName,
      projectExpScope: isValue.projectScope
    })
  }

  venContExpId: any
  venExpId: number
  venExportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.vendorNameExpControl.patchValue(isValue.vendorName);
    this.VendorFormGroupExport.patchValue({
      vendorNameExp: this.vendorNameExpControl.value,
      ApplicationNameExp: isValue.appName,
      ApplicationVersionExp: isValue.appVersion,
    });
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let venobjExp = contlab.filter((x) => x.ContactType === "vendor");
      if (venobjExp.length > 0) {
        for (let m = 0; m < venobjExp.length; m++) {
          this.venContExpId = venobjExp[m].contactid
          let vengroup = this._formBuilder.group({
            name: venobjExp[m].ContactName,
            role: venobjExp[m].role,
            phone: venobjExp[m].phonenumber,
            email: venobjExp[m].emailid,
            id: this.venContExpId !== undefined ? this.venContExpId : this.venExpId,
            action: "",
          });
          this.addExpVendorContField().push(vengroup);
          this.VendorFormGroupExport.patchValue({
            vendorExpContactdetails: this.addExpVendorContField().value,
          });
        }
      }
    }
  }


  isEditLabImpGUID: string
  labimpContID: any
  labimpId: number
  labImportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.LabImportControl.patchValue(isValue.sourceName);
    this.isEditLabImpGUID = isValue.sourceGuid
    this.LabFormGroupImport.patchValue({
      labImport: this.LabImportControl
    });
    if (isValue.contactDetails !== "") {
      let contImp = JSON.parse(isValue.contactDetails);
      let Impobj = contImp.filter((x) => x.ContactType === "lab")
      if (Impobj.length > 0) {
        for (let i = 0; i < Impobj.length; i++) {
          this.labimpContID = Impobj[i].contactid
          let impGroup = this._formBuilder.group({
            name: Impobj[i].ContactName,
            role: Impobj[i].role,
            phone: Impobj[i].phonenumber,
            email: Impobj[i].emailid,
            id: this.labimpContID !== undefined ? this.labimpContID : this.labimpId,
            action: "",
          });
          this.addlabcontactImportfield().push(impGroup)
        }
        this.LabFormGroupImport.patchValue({
          labContactdetailsImport: this.addlabcontactImportfield().value
        })
      }
    }
  }

  reqImpId: any
  reqImportPatch(value: any) {
    let isValue = JSON.parse(value);
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let reqobj = contlab.filter((x) => x.ContactType === "requester");
      if (reqobj.length > 0) {
        this.reqImpId = reqobj[0].contactid
        this.requesterFormGroupImport.patchValue({
          requesterImpName: reqobj[0].ContactName,
          requesterImpEmail: reqobj[0].emailid,
          requesterImpPhone: reqobj[0].phonenumber,
          id: this.reqImpId
        });
      }
    }
  }

  projImportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.projectFormGroupImport.patchValue({
      projectImpName: isValue.projectName,
      projectImpScope: isValue.projectScope
    })
  }

  venContId: any
  vencontimpId: number
  venImportPatch(value: any) {
    let isValue = JSON.parse(value);
    this.vendorNameImpControl.patchValue(isValue.vendorName);
    this.VendorFormGroupImport.patchValue({
      vendorNameImp: this.vendorNameImpControl.value,
      ApplicationNameImp: isValue.appName,
      ApplicationVersionImp: isValue.appVersion,
    });
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let venobjImp = contlab.filter((x) => x.ContactType === "vendor");
      if (venobjImp.length > 0) {
        for (let m = 0; m < venobjImp.length; m++) {
          this.venContId = venobjImp[m].contactid
          let vengroup = this._formBuilder.group({
            name: venobjImp[m].ContactName,
            role: venobjImp[m].role,
            phone: venobjImp[m].phonenumber,
            email: venobjImp[m].emailid,
            id: this.venContId !== undefined ? this.venContId : this.vencontimpId,
            action: "",
          });
          this.addImpVendorContField().push(vengroup);
          this.VendorFormGroupImport.patchValue({
            vendorImpContactdetails: this.addImpVendorContField().value,
          });
        }
      }
    }
  }


  labIntFormPatch(value: any) {
    let isValue = JSON.parse(value);
    this.labControl.patchValue(isValue.sourceName);
    this.payinglabControl.patchValue(isValue.payingLabName);
    this.isEditLabGUID = isValue.sourceGuid
    this.isEditPayingGUID = isValue.payingLabGuid
    this.LabFormGroup.patchValue({
      lab: this.labControl,
      payingLab: this.payinglabControl,
      interfaceBetween: isValue.interfaceBetween,
    });
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let labobj = contlab.filter((x) => x.ContactType === "lab");

      if (labobj.length > 0) {
        for (let i = 0; i < labobj.length; i++) {
          let labgroup = this._formBuilder.group({
            name: labobj[i].ContactName,
            role: labobj[i].role,
            phone: labobj[i].phonenumber,
            email: labobj[i].emailid,
            id: labobj[i].contactid,
            action: "",
          });
          this.addlabcontactfield().push(labgroup);
          // this.addlabcontactfield().at(i).patchValue(labobj)
          this.LabFormGroup.patchValue({
            labContactDetials: this.addlabcontactfield().value,
          });
        }
      }
    }
  }

  facIntFormPatch(value: any) {

    let isValue = JSON.parse(value);
    this.facilityControl.patchValue(isValue.destinationName);
    this.facilityAddressControl.patchValue(isValue.destinationAddress1);
    this.isEditFaciltyGUID = isValue.destinationGUID;
    this.FacilityFormGroup.patchValue({
      facilityName: this.facilityControl.value,
      facilityaddress: this.facilityAddressControl.value,
    });

    this.facaddr1 = this.facilityAddressControl.value;

    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let facobj = contlab.filter((x) => x.ContactType === "facility");
      if (facobj.length > 0) {
        for (let n = 0; n < facobj.length; n++) {
          let facgroup = this._formBuilder.group({
            name: facobj[n].ContactName,
            role: facobj[n].role,
            phone: facobj[n].phonenumber,
            email: facobj[n].emailid,
            id: facobj[n].contactid,
            action: "",
          });
          this.addfacilitycontactfield().push(facgroup);
          this.FacilityFormGroup.patchValue({
            facilityContactDetails: this.addfacilitycontactfield().value,
          });
        }
      }
    }
  }

  venIntFormPatch(value: any) {
    let isValue = JSON.parse(value);
    this.vendorNameControl.patchValue(isValue.vendorName);
    this.VendorFormGroup.patchValue({
      vendorName: this.vendorNameControl.value,
      ApplicationName: isValue.appName,
      ApplicationVersion: isValue.appVersion,
    });
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let venobj = contlab.filter((x) => x.ContactType === "vendor");
      if (venobj.length > 0) {
        for (let m = 0; m < venobj.length; m++) {
          let vengroup = this._formBuilder.group({
            name: venobj[m].ContactName,
            role: venobj[m].role,
            phone: venobj[m].phonenumber,
            email: venobj[m].emailid,
            id: venobj[m].contactid,
            action: "",
          });
          this.addvendorcontactfield().push(vengroup);
          this.VendorFormGroup.patchValue({
            vendorContactdetails: this.addvendorcontactfield().value,
          });
        }
      }
    }
  }

  reqID: any
  reqNoID: number
  reqIntFormPatch(value: any) {
    let isValue = JSON.parse(value);
    if (isValue.contactDetails !== "") {
      let contlab = JSON.parse(isValue.contactDetails);
      let reqobj = contlab.filter((x) => x.ContactType === "requester");
      if (reqobj.length > 0) {
        this.reqID = reqobj[0].contactid
        this.requesterFormGroup.patchValue({
          requesterName: reqobj[0].ContactName,
          requesterEmail: reqobj[0].emailid,
          requesterPhone: reqobj[0].phonenumber,
          id: this.reqID
        });
      }
    }
  }

  patchIntType = [];
  projfileid: number
  detailIntFormPatch(value: any) {

    let isValue = JSON.parse(value);
    let arraytype = [];

    if (isValue.interfaces !== "") {
      let connectivityEdit = JSON.parse(isValue.interfaces);
      for (let i = 0; i < connectivityEdit.length; i++) {
        arraytype.push(connectivityEdit[i].interfaceType);
      }
    }
    this.patchIntType = arraytype;
    this.projFiles = isValue.projectDocs
    if (this.projFiles !== null) {

      for (let j = 0; j < this.projFiles.length; j++) {
        this.projfileid = this.projFiles[j].id
        this.fileid = this.projFiles[j].fileid
      }
    }
    else {
      this.projFiles = [];
    }

    this.DetailsFormGroup.patchValue({
      projName: isValue.projectName,
      projScopeRequirement: isValue.projectScope,
      specialityCaseType: isValue.specialityCaseType,
      specificDocs: '',
      kickOffDate: isValue.kickOffDate,
      goLiveDate: isValue.goLiveDate,
      otherComments: isValue.otherComments,
      ProjInterfaceType: this.patchIntType,
      projDocuments: ''
    });
  }

  connIntFormPatch(value: any) {


    let isValue = JSON.parse(value);
   this.getsourceInfo(isValue);
   this.getTargetInfo(isValue);

    let arraytype = []
    if (isValue.interfaces !== "") {
      let connectivityEdit = JSON.parse(isValue.interfaces);

      this.onAddNewCard().clear();
      for (let i = 0; i < connectivityEdit.length; i++) {
        arraytype.push(connectivityEdit[i].interfaceType);
        this.ConnectivityFormGroup.patchValue({
          ConnectivityType: connectivityEdit[i].ConnectivityType,
          Description: connectivityEdit[i].description,
          // ContentTypeInbound: connectivityEdit[i].contentType,
          // ContentTypeOutbound: connectivityEdit[i].contentType,
        });
        // if(this.ConnectivityFormGroup.value.ProjInterfacecard !== undefined){
        let forsgroup = this._formBuilder.group({
          interfaceType:connectivityEdit[i].interfaceType,
          ContentType: connectivityEdit[i].contentType,
          ConnectivityType: connectivityEdit[i].ConnectivityType,
          Description: connectivityEdit[i].description,
          vpnIp: connectivityEdit[i].connectivityDetails.vpnIp,
          vpnHost: connectivityEdit[i].connectivityDetails.vpnHost,
          vpnPort: connectivityEdit[i].connectivityDetails.vpnPort,
          externalFtpUsername: connectivityEdit[i].connectivityDetails.externalFtpUsername,
          externalFtpPassowrd: connectivityEdit[i].connectivityDetails.externalFtpPassowrd,
          externalFtpHost: connectivityEdit[i].connectivityDetails.externalFtpHost,
          externalFtpFolderPath: connectivityEdit[i].connectivityDetails.externalFtpFolderPath,
          externalFtpRemotePath: connectivityEdit[i].connectivityDetails.externalFtpRemotePath,
          externalFtpArchivePath: connectivityEdit[i].connectivityDetails.externalFtpArchivePath,
          externalFtpPort: connectivityEdit[i].connectivityDetails.externalFtpPort,
        });
        this.onAddNewCard().push(forsgroup);

        this.onAddNewCard().at(i).patchValue(connectivityEdit[i]);
        this.ConnectivityFormGroup.patchValue({
          ProjInterfacecard: this.onAddNewCard().value,
        });
        // this.ConnectivityFormGroup.value.ProjInterfacecard = connectivityEdit[i]
        // }
      }
    }
  }


  patchformvalue(value: any) {

    let isValue = JSON.parse(value);
    this.projvaid = isValue.projectID;
    this.projid = isValue.id;
    this.labIntFormPatch(value);
    this.reqIntFormPatch(value);
    this.venIntFormPatch(value);
    this.facIntFormPatch(value);
    this.detailIntFormPatch(value);
    this.connIntFormPatch(value);

  }



  getdirection(value: any) {
    this.isOutbound = false;
    // let dir = this.ConnectivityFormGroup.get("InterfaceType").value;
    let dir = this.DetailsFormGroup.get("ProjInterfaceType").value;
    if (value == "In" && dir != null) {
      for (let i = 0; i < dir.length; i++) {
        if (dir[i].includes("In")) {
          this.isInbound = true;
          return true;
        }
      }
    }
    if (value == "Out" && dir != null) {
      for (let i = 0; i < dir.length; i++) {
        if (dir[i].includes("Out")) {
          this.isOutbound = true;
          return true;
        }
      }
    }
  }

  //////////////////////adding and removing contacts
  //////lab
  createlabcontactImport() {
    let labimpg = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: this.labimpContID !== undefined ? this.labimpContID : 0,
      action: "active"
      // contactType: ''
    });
    this.addlabcontactImportfield().push(labimpg);
  }

  createlabcontactExport() {
    let labexpg = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active"
      // contactType: ''
    });
    this.addlabcontactExportfield().push(labexpg)
  }

  createlabcontact() {
    let labg = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active",
      // contactType: ''
    });
    this.addlabcontactfield().push(labg);
  }
  removelabcontact(i: number) {
   let arry = this.LabFormGroup.get("labContactdetails") as FormArray;
    arry.controls[i].patchValue({
      action: "passive",
    });

    // arry.removeAt(i);
  }

  addlabcontactfield(): FormArray {
    return this.LabFormGroup.get("labContactdetails") as FormArray;
  }

  addlabcontactImportfield(): FormArray {
    return this.LabFormGroupImport.get("labContactdetailsImport") as FormArray;
  }
  removelabcontactImport(i: number) {
    let arry = this.LabFormGroupImport.get("labContactdetailsImport") as FormArray;
    arry.controls[i].patchValue({
      action: "passive",
    });
  }
  addlabcontactExportfield(): FormArray {
    return this.LabFormGroupExport.get(
      "labContactdetailsExport"
    ) as FormArray;
  }
  removelabcontactExport(i: number) {
    let arry = this.LabFormGroupExport.get(
      "labContactdetailsExport"
    ) as FormArray;
    arry.controls[i].patchValue({
      action: "passive",
    });
  }
  /////////////facility
  createfacilitycontact() {
    let facg = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active",
      // contactType: ''
    });
    this.addfacilitycontactfield().push(facg);
  }
  removefacilitycontact(i: number) {
    let arry = this.FacilityFormGroup.get(
      "facilityContactdetails"
    ) as FormArray;
    // arry[i].action ="delete"
    arry.controls[i].patchValue({
      action: "passive",
    });
  }
  addfacilitycontactfield(): FormArray {
    return this.FacilityFormGroup.get("facilityContactdetails") as FormArray;
  }

  /////////////////vendor
  createvendorcontact() {
    let veng = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active",
      // contactType: ''
    });
    this.addvendorcontactfield().push(veng);
  }
  removevendorcontact(i: number) {
    let arry = this.VendorFormGroup.get("vendorContactdetails") as FormArray;
    // arry[i].action ="delete"
    arry.controls[i].patchValue({
      action: "passive",
    });
  }


  addvendorcontactfield(): FormArray {
    return this.VendorFormGroup.get("vendorContactdetails") as FormArray;
  }

  ///////////////////////////////////////////////
  createvendorImpcontact() {
    let veng = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active",
      // contactType: ''
    });
    this.addImpVendorContField().push(veng);
  }
  removeImpVendorCont(i: number) {
    let arry = this.VendorFormGroupImport.get("vendorImpContactdetails") as FormArray;
    // arry[i].action ="delete"
    arry.controls[i].patchValue({
      action: "passive",
    });
  }
  addImpVendorContField(): FormArray {
    return this.VendorFormGroupImport.get("vendorImpContactdetails") as FormArray;
  }

  /////////////////////////////////
  createvendorExpcontact() {
    let veng = this._formBuilder.group({
      name: "",
      role: "",
      phone: "",
      email: "",
      id: 0,
      action: "active",
      // contactType: ''
    });
    this.addExpVendorContField().push(veng);
  }
  removeExpVendorCont(i: number) {
    let arry = this.VendorFormGroupExport.get("vendorExpContactdetails") as FormArray;
    // arry[i].action ="delete"
    arry.controls[i].patchValue({
      action: "passive",
    });
  }
  addExpVendorContField(): FormArray {
    return this.VendorFormGroupExport.get("vendorExpContactdetails") as FormArray;
  }

  async selectedVendorEvent(event) {
    this.vendarnamevalue = event.name;
    this.vendorid = event.Id;
    // select connectivity based on the vendor mapping
    let vendorObj = {
      name: this.vendarnamevalue,
    };
    this.ngxuiloader.start();

    await this.commonService
      .postapi("api/Routes/GetLastRouteConnectivityByVendor", vendorObj)
      .then(
        (Response) => {
          if (Response) {
            if (
              (Response["status"] && Response["statusText"]) ||
              Response["statusText"] === "Unknown Error"
            ) {
              this.ngxuiloader.stop();
              this.toastr.error("Something Went Wrong!", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
              });
            } else {
              this.ngxuiloader.stop();
              let response = Response as any;
              if (response["status"] == "success") {
                if (
                  this.DetailsFormGroup.value.ProjInterfaceType ==
                  "EllkayInbound" ||
                  this.DetailsFormGroup.value.ProjInterfaceType == "Inbound"
                ) {
                  this.modelradio.option = response["responsebody"];
                }
                // if (this.interfacetype == "EllkayResultOutbound" || this.interfacetype == "Outbound") {
                //   this.modelradio.option =JSON.parse(vendordata[0].connectivity).connectivitytype.split(",")[0];
                // }
              }
            }
          } else {
            this.ngxuiloader.stop();
          }
        },
        (error) => {
          this.ngxuiloader.stop();
          this.toastr.error("Something Went Wrong!", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      );
  }

  async getVendorsName(event) {
    var vendarvalue = event;
    this.vendarnamevalue = "";
    if (vendarvalue != "") {
      this.isLoadingVendorName = false;
      if (this.VendorsList.length > 0) {
        this.filterVendorList(vendarvalue);
      } else {
        this.VendorsList = this.VendornameBackuplist;
        this.VendorsListExp = JSON.parse(JSON.stringify( this.VendornameBackuplist));
        this.filterVendorList(vendarvalue);
      }
    }
  }
  public reactiveFormvendar: FormGroup;
  public reactiveFormvendarImp: FormGroup;
  public reactiveFormvendarExp: FormGroup;
  getVendorname() {
    this.ngxuiloader.start();

    var url = "api/Vendor/GetVendorName";
    this.commonService.getapiurl(url).subscribe((data) => {
      if (data) {
        this.VendorsList = data as any[];
        this.VendorsList = this.commonService.transformAlphabetically(
          this.VendorsList,
          "name"
        );
        this.VendorsListImp = JSON.parse(JSON.stringify(this.VendorsList))
        this.VendorsListExp = JSON.parse(JSON.stringify(this.VendorsList))
        this.VendornameBackuplist = this.VendorsList;
        this.ngxuiloader.stop();
        this.reactiveFormvendar.setValue({ namevendar: "" });
      } else {
        this.ngxuiloader.stop();
      }
    });
  }



  notfounddata3: boolean;
  filterVendorList(event) {
    this.VendorsList = this.VendorsList.filter((t) =>
      t.name.toLowerCase().includes(event.toLowerCase())
    );
    if (this.VendorsList.length == 0) {
      this.VendorsList = [];
      this.notfounddata3 = true;
      this.isLoadingVendorName = false;
    } else {
      // this.VendorsList=[]
      this.notfounddata3 = false;
      this.isLoadingVendorName = true;
      // this.VendorsList = event
    }
    this.isLoadingVendorName = false;
  }

  notfounddataVenImp: boolean
  filterVendorListImp(event) {
    this.VendorsListImp = this.VendorsListImp.filter((t) =>
      t.name.toLowerCase().includes(event.toLowerCase())
    );
    if (this.VendorsListImp.length == 0) {
      this.VendorsListImp = [];
      this.notfounddataVenImp = true;
      this.isLoadingVendorNameImp = false;
    } else {
      // this.VendorsList=[]
      this.notfounddataVenImp = false;
      this.isLoadingVendorNameImp = true;
      // this.VendorsList = event
    }
    this.isLoadingVendorNameImp = false;
  }


  searchVendarNameCleared(event) {
    this.isLoadingVendorName = false;
    this.vendarnamevalue = "";
    this.vendorid = "";
    this.VendorsList = this.VendornameBackuplist;
    this.VendorFormGroup.setValue({ vendorName: "" });
  }

  searchVendarNameClearedImp(event) {
    this.isLoadingVendorNameImp = false;
    this.vendarnamevalueImp = "";
    this.vendoridImp = "";
    this.VendorsListImp = this.VendornameBackuplistImp;
    this.VendorFormGroupImport.setValue({ vendorNameImp: "" });
  }


  openVendorPopup() {
    let obj = {
      data: "",
      vendornamelist: this.VendornameBackuplist,
      component: "request-form",
      Type: "Add",
      vendorname: this.VendorFormGroup.get("vendorName").value,
    };
    const modalRef = this.modalService.open(SavevendorComponent, {
      windowClass: "saveVendormodal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.inputDataInModalComponent = obj;
    modalRef.result.then((data) => {
      if (data.dataflag) {
        this.getVendorname();
        this.reactiveFormvendar.patchValue({
          namevendar: data.vendorname,
        });
        this.vendarnamevalue = data.vendorname;
      }
    });
  }

  openVendorImportPopup() {
    let obj = {
      data: "",
      vendornamelist: this.VendornameBackuplistImp,
      component: "request-form",
      Type: "Add",
      vendorname: this.VendorFormGroupImport.get("vendorNameImp").value,
    };
    const modalRef = this.modalService.open(SavevendorComponent, {
      windowClass: "saveVendormodal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.inputDataInModalComponent = obj;
    modalRef.result.then((data) => {
      if (data.dataflag) {
        this.getVendorname();
        this.reactiveFormvendarImp.patchValue({
          namevendar: data.vendorname,
        });
        this.vendarnamevalueImp = data.vendorname;
      }
    });
  }

  openVendorExportPopup() {
    let obj = {
      data: "",
      vendornamelist: this.VendornameBackuplistExp,
      component: "request-form",
      Type: "Add",
      vendorname: this.VendorFormGroupExport.get("vendorNameExp").value,
    };
    const modalRef = this.modalService.open(SavevendorComponent, {
      windowClass: "saveVendormodal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.inputDataInModalComponent = obj;
    modalRef.result.then((data) => {
      if (data.dataflag) {
        this.getVendorname();
        this.reactiveFormvendarExp.patchValue({
          namevendar: data.vendorname,
        });
        this.vendarnamevalueExp = data.vendorname;
      }
    });
  }

  processorlist: any;
  labcnamelist: any = [];
  entitytable(obj) {
    let final = {
      ...obj,
      modifierid: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
        .userdata["userid"],
    };
    this.commonService.EntityInsertion(final).subscribe(
      (data) => {
        this.ngxuiloader.stop();
        var result = data;
      },
      (error) => {
        // this.autocompLoading = false;
        console.error();
      }
    );
  }



  filecontentastext: any = [];
  filecontent: string;
  fileName: string;
  onFileSelected(event) {
    let list = [];
    this.ngxuiloader.start();
    list = event.target.files;
    let f;
    for (let i = 0; i < list.length; i++) {
      f = list[i];
      this.DocumentName.push(f.name);
      var reader = new FileReader();
      reader.onload = (function (theFile) {
        return function (e) {
          var binaryData = e.target.result;
          var base64String = window.btoa(binaryData.toString());
          fobj.fileContent = base64String;
        };
      })(f);
      reader.readAsBinaryString(f);
      let fobj = {
        fileContent: "",
        filename: list[i].name,
        size: list[i].size,
      };
      // setTimeout(() => {
      if (fobj !== undefined) {
        this.projFiles.push(fobj);
        var pf = this.projFiles.find((va) => va.name == event.value);
        this.filecontent = pf.fileContent;
        this.fileName = pf.filename;
      }
      // });
    }
    this.ngxuiloader.stop();
  }

  deleteFile: string

  fileSelectedDelete(i) {
    this.removeFile(i)
    if (this.deleteFile == "passive") {
      this.toastr.warning("File will be deleted on Saving the Request Form.", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }

  removeFile(i) {

    let file = this.projFiles.find(v=> v.id == this.projFiles[i].id)
    file.action = "passive"
    this.deleteFile = file.action

    // this.projFiles.splice(i, 1);
    // this.DocumentName.splice(i, 1);
  }

  ItypedirectionIN: string
  ItypedirectionOUT: string
  ContType = [];
  contLookupid: string
  selectInttype() {
    this.commonService.getIntContType('interfacetype').subscribe((data) => {
      if (data) {
        this.IType = data

        // this.ItypeLookupid = data.lookupid;
      }
    })
    this.commonService.getIntContType('interfacecontenttype').subscribe((data) => {
      if (data) {
        this.ContType = data
        // this.contLookupid = data.lookupid;
      }
    })
  }



  oobj = [];
  config: string;
  PrFileid: number;
  fileid: string;
  vpnexftptype = {};
  apinonevaftpType = {};
  submitform(id: any, isSubmitForms: boolean) {
this.oobj=[]
    let pguid = "";
    if (this.selectedlabobj !== undefined) {
      this.LabFormGroup.patchValue({ lab: this.selectedlabobj.Displayname });
    }
    if (this.selectedpaylabobj !== undefined) {
      this.LabFormGroup.patchValue({
        payingLab: this.selectedpaylabobj.Displayname,
      });
    }
    if (this.selectedfacilityobj !== undefined) {
      this.FacilityFormGroup.patchValue({
        facilityName: this.selectedfacilityobj.Displayname,
      });
    }

this.ConnectivityFormGroup.value.ProjInterfacecard
    // let dir = this.ConnectivityFormGroup.get("InterfaceType").value;
    let dir = this.DetailsFormGroup.get("ProjInterfaceType").value;
    if (this.ConnectivityFormGroup.value.ProjInterfacecard !== null ) {

      for (let i = 0; i < this.ConnectivityFormGroup.value.ProjInterfacecard.length; i++) {
        let uniobjs = {
          interfaceType: this.ConnectivityFormGroup.value.ProjInterfacecard[i].interfaceType,
          direction:'',
          connectivityDetails: {},
          contentType: '',
          description:'',
          ConnectivityType:'',
        };

        let conndetail = {
          vpnIp: this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnIp !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnIp : "",
          vpnHost: this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnHost !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnHost : "",
          vpnPort: this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnPort !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].vpnPort : "",
          externalFtpUsername: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpUsername !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpUsername : "",
          externalFtpPassowrd: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpPassowrd !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpPassowrd : "",
          externalFtpHost: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpHost !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpHost : "",
          externalFtpFolderPath: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpFolderPath !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpFolderPath : "",
          externalFtpRemotePath: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpRemotePath !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpRemotePath : "",
          externalFtpArchivePath: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpArchivePath !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpArchivePath : "",
          externalFtpPort: this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpPort !== "" ? this.ConnectivityFormGroup.value.ProjInterfacecard[i].externalFtpPort : "",
        }

        //formsubmit
        uniobjs.interfaceType= this.ConnectivityFormGroup.value.ProjInterfacecard[i].interfaceType;
        uniobjs.direction=this.IType.find(va => this.ConnectivityFormGroup.value.ProjInterfacecard[i].interfaceType == va.lookupvalue).lookupid
        uniobjs.contentType=this.ConnectivityFormGroup.value.ProjInterfacecard[i].ContentType,
        uniobjs.description=  this.ConnectivityFormGroup.value.ProjInterfacecard[i].Description,
        uniobjs.ConnectivityType=this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType
        // if (this.ConnectivityFormGroup.value.ConnectivityType === 'vpn' && this.ConnectivityFormGroup.value.InterfaceType[0].includes('In')) {
        if (
          this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType === "VPN" ||
          this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType === "External FTP"
        ) {

          uniobjs.connectivityDetails= conndetail

        }



        if (
          this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType === "API" ||
          this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType === "NONE" ||
          this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType === "FTP"
        ) {
          // this.apinonevaftpType = {
            // uniobjs.interfaceType=this.ConnectivityFormGroup.value.ProjInterfacecard[i].interfaceType,
            // uniobjs.direction= this.IType.find(va => this.ConnectivityFormGroup.value.ProjInterfacecard[i].interfaceType == va.lookupvalue).lookupid,
            uniobjs.connectivityDetails= ""
            // uniobjs.contentType= this.ConnectivityFormGroup.value.ProjInterfacecard[i].ContentType,
            //   uniobjs.description=  this.ConnectivityFormGroup.value.ProjInterfacecard[i].Description,
            //   uniobjs.ConnectivityType= this.ConnectivityFormGroup.value.ProjInterfacecard[i].ConnectivityType
          // };

        }
        // if(this.oobj[i] !==  dir[i]){
        uniobjs.direction=uniobjs.direction=='EllkayOutbound'?'Outbound':uniobjs.direction;
          this.oobj.push(uniobjs);
        // }
      }
    }
    this.config = JSON.stringify(this.oobj);


    // if (
    //   this.DetailsFormGroup.value.ProjInterfaceType !== undefined &&
    //   this.DetailsFormGroup.value.ProjInterfaceType !== null
    // ) {
    //   this.config = JSON.stringify(this.oobj);
    // }
    // this.LabFormGroup.value.labContactdetails = JSON.stringify(this.LabFormGroup.value.labContactdetails);
    // this.FacilityFormGroup.value.facilityContactdetails = JSON.stringify(this.FacilityFormGroup.value.facilityContactdetails);
    // this.VendorFormGroup.value.vendorContactdetails = JSON.stringify(this.VendorFormGroup.value.vendorContactdetails);

    let contactDetails = [];
    if (this.LabFormGroup.value.labContactdetails.length > 0) {
      let obs = {
        lab: this.LabFormGroup.value.labContactdetails,
      };
      contactDetails.push(obs);
    }
    if (this.FacilityFormGroup.value.facilityContactdetails.length > 0) {
      let obs = {
        facility: this.FacilityFormGroup.value.facilityContactdetails,
      };
      contactDetails.push(obs);
    }
    if (this.VendorFormGroup.value.vendorContactdetails.length > 0) {
      let obs = {
        vendor: this.VendorFormGroup.value.vendorContactdetails,
      };
      contactDetails.push(obs);
    }
    let obs = {
      requester: [
        {
          name: this.requesterFormGroup.value.requesterName,
          email: this.requesterFormGroup.value.requesterEmail,
          phone: this.requesterFormGroup.value.requesterPhone,
          id: this.reqID !== undefined ? this.reqID : this.reqNoID
        },
      ],
    };
    contactDetails.push(obs);

    let detail = JSON.stringify(contactDetails);
    let files = [];
    if (this.projFiles !== null) {
      for (let i = 0; i < this.projFiles.length; i++) {
        let docs = {
          fileContent: this.projFiles[i].fileContent,
          fileName: this.projFiles[i].filename,
          fileid: this.projFiles[i].fileid !== undefined ? this.projFiles[i].fileid : "",
          action: this.projFiles[i].action == "passive" ? "passive" : "active",
          id: this.projFiles[i].id !== undefined ? this.projFiles[i].id  : 0,
        };
        files.push(docs);
      }
    }
    let obj = {
      isSubmit: false,
      userId: JSON.parse(sessionStorage.getItem("rolewithmenus"))[0].uniqueId,
      modifiedBy: JSON.parse(sessionStorage.getItem("rolewithmenus"))[0].UserID,
      projectID: this.projvaid !== undefined ? this.projvaid : "",
      id: this.projid !== undefined ? this.projid : 0,
      projectType: "Interface",
      sourceGuid:
        this.selectedlabobj !== undefined
          ? this.selectedlabobj.LocationGUID
          : this.isEditLabGUID,
      sourceDeploymentKey: this.selectedlabobj !== undefined ? this.selectedlabobj.deploymentkey : "",
      sourceOrgId: this.selectedlabobj
      !== undefined ? this.selectedlabobj.organizationid : "",
      sourceAccountId: this.selectedlabobj !== undefined ? this.selectedlabobj.entityid : "",
      sourceName: this.selectedlabobj !== undefined ? this.selectedlabobj.Displayname : "",
      sourceAccountType: this.selectedlabobj !== undefined ? this.selectedlabobj.EntityType : "",
      sourceAddress1: this.selectedlabobj !== undefined ? this.selectedlabobj.Address1 : "",
      sourceAddress2: this.selectedlabobj !== undefined ? this.selectedlabobj.Address2 : "",
      sourceCity: this.selectedlabobj !== undefined ? this.selectedlabobj.city : "",
      sourceState: this.selectedlabobj !== undefined ? this.selectedlabobj.state : "",
      sourceZip: this.selectedlabobj !== undefined ? this.selectedlabobj.zip : "",
      sourceType: this.selectedlabobj !== undefined ? this.selectedlabobj.TypeName : "",
      sourceMnemonic: this.selectedlabobj !== undefined ? this.selectedlabobj.mnemonic  : "",
      payingLabGuid:
        this.selectedpaylabobj !== undefined
          ? this.selectedpaylabobj.LocationGUID
          : this.isEditPayingGUID,
      payingLabName: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.Displayname : "",
      payingLabDeploymentKey: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.deploymentkey : "",
      payingLabAccountType: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.EntityType : "",
      payingLabAddress1: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.Address1 : "",
      payingLabAddress2: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.Address2 : "",
      payingLabCity: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.city : "",
      payingLabState: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.state : "",
      payingLabZip: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.zip : "",
      payingLabType: this.selectedpaylabobj !== undefined ? this.selectedpaylabobj.TypeName : "",
      contactDetails: detail,
      vendorID: this.vendoID,
      vendorName: "",
      appName: this.VendorFormGroup.value.ApplicationName,
      appVersion: this.VendorFormGroup.value.ApplicationVersion,
      vendorMnemonic: this.venMnemonic,
      requesterName: this.requesterFormGroup.value.requesterName,
      destinationGUID:
        this.selectedfacilityobj !== undefined
          ? this.selectedfacilityobj.LocationGUID
          : this.isEditFaciltyGUID,
      destinationDeploymentKey: this.selectedfacilityobj !== undefined  ? this.selectedfacilityobj.deploymentkey : "",
      destinationOrgId: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.organizationid : "",
      destinationAccountId: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.entityid : "",
      destinationName: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.Displayname: "",
      destinationAddress1: this.selectedfacilityobj !== undefined && this.selectedfacilityobj.Address1 !== ''? this.selectedfacilityobj.Address1 : this.FacilityFormGroup.value.facilityaddress,
      destinationAddress2: this.selectedfacilityobj !== undefined &&  this.selectedfacilityobj.Address2 !=='' ? this.selectedfacilityobj.Address2 : this.FacilityFormGroup.value.facilityaddress,
      destinationAccountType: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.EntityType : "",
      destinationCity: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.city : "",
      destinationState: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.state : "",
      destinationZip: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.zip : "",
      destinationType: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.TypeName : "",
      destinationMnemonic: this.selectedfacilityobj !== undefined ? this.selectedfacilityobj.mnemonic : "",
      projectName: this.DetailsFormGroup.value.projName,
      projectScope: this.DetailsFormGroup.value.projScopeRequirement,
      kickOffDate:
        (this.DetailsFormGroup.value.kickOffDate !== undefined && this.DetailsFormGroup.value.kickOffDate !== null)
          ? this.formatDate(this.DetailsFormGroup.value.kickOffDate)
          : "",
      goLiveDate:
        (this.DetailsFormGroup.value.goLiveDate !== undefined && this.DetailsFormGroup.value.goLiveDate !== null)
          ? this.formatDate(this.DetailsFormGroup.value.goLiveDate)
          : "",
      projectStatus: "",
      interfaceBetween: this.LabFormGroup.value.interfaceBetween,
      projectDocs: files,
      otherComments: this.DetailsFormGroup.value.otherComments,

      interfaces: this.config !== "" ? this.config : "",
    };
    // obj.interfaces = config;
    // let isflag

    if (isSubmitForms === true) {
      if (this.LabFormGroup.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.LabFormGroup.controls.labContactdetails.status == "INVALID") {
        this.toastr.warning("Invalid contact details", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.requesterFormGroup.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Requester Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }

      if (this.FacilityFormGroup.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Facility Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (
        this.FacilityFormGroup.controls.facilityContactdetails.status ==
        "INVALID"
      ) {
        this.toastr.warning("Invalid contact details", "Facility Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (
        this.VendorFormGroup.controls.vendorContactdetails.status == "INVALID"
      ) {
        this.toastr.warning("Invalid contact details", "Vendor Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.ConnectivityFormGroup.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Connectivity Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }

      if (
        this.ConnectivityFormGroup.status !== "INVALID" &&
        this.LabFormGroup.status != "INVALID" &&
        this.requesterFormGroup.status != "INVALID" &&
        this.FacilityFormGroup.status !== "INVALID" &&
        this.DetailsFormGroup.status !== "INVALID"
      ) {
          obj.isSubmit = true;
          // obj.sourceOrgId = this.selectedlabobj.organizationid

          // obj.sourceAccountId = this.selectedlabobj.entityid !== undefined ? this.selectedlabobj.entityid : "",
          // obj.destinationOrgId = this.selectedfacilityobj.organizationid !== undefined ? this.selectedfacilityobj.organizationid:"",
          // obj.destinationAccountId = this.selectedfacilityobj.entityid !== undefined ? this.selectedfacilityobj.entityid : "",
          obj.vendorName = this.vendoName
          this.ngxuiloader.start();         
        this.commonService.submitInterfaceForm(obj).subscribe((data) => {
          this.ngxuiloader.stop();

          if (data) {
            this.toastr.success("Submitted Successfully", data.ProjectID, {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
            // this.isShowCard = false;
            // this.getInterfaceFormList("Interface");
            this.closeCreateProject()
          }
        });
      }
    }

    else {
      this.ngxuiloader.start();
      this.commonService.saveInterfaceForm(obj).subscribe((data) => {
        this.ngxuiloader.stop();
        if (data.status == "Failure") {
          this.toastr.error("Failed to Save", data.statusmessage, {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
        else {
          this.toastr.success(data.statusmessage, "", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
          this.interfaceFormIdResponse = data.responsebody;
          // this.isShowCard = false;
          // this.resetRequestForm();
         this.closeCreateProject()
          // this.getInterfaceFormList("Interface");
        }
      });
    }
  }

  formatDate(date) {
    if (date !== '') {
      var last = new Date(date);
      var day = ("0" + last.getDate()).slice(-2);
      var month = ("0" + (last.getMonth() + 1)).slice(-2);
      var year = last.getFullYear();
      return year + "-" + month + "-" + day;
    }
  }
  showSuccessform() {
    this.toastr.success("Submitted Successfully", "Interface Request Form", {
      positionClass: "toast-bottom-right",
      timeOut: 4000,
    });
  }
  getFormListdata(value: any) {
    this.ngxuiloader.start();
    this.commonService.getformdata(value).subscribe(
      (data) => {
        this.ngxuiloader.stop();
        if (data.length > 0) {
          this.viewdata = data;
        }
      },
      (error) => {
        this.ngxuiloader.stop();
      }
    );
  }

  uniqdata =[]
  batchArray =[]

  getsourceInfo(value){
    if(value !== undefined){
      this.selectedlabobj={}
       this.selectedlabobj['LocationGUID'] = value.sourceGuid
       this.selectedlabobj['deploymentkey']=value.sourceDeploymentKey,
       this.selectedlabobj['organizationid '] = value.sourceOrgId,
       this.selectedlabobj['entityid'] =value.sourceAccountId
       this.selectedlabobj['Displayname'] =value.sourceName
       this.selectedlabobj['EntityType '] =value.sourceAccountType,
       this.selectedlabobj['Address1'] =value.sourceAddress1
       this.selectedlabobj['Address2'] =value.sourceAddress2
       this.selectedlabobj['city'] =value.sourceCity
       this.selectedlabobj['state'] =value.sourceState
       this.selectedlabobj['zip'] =value.sourceZip;
       this.selectedlabobj['TypeName'] =value.sourceType;
       this.selectedlabobj['mnemonic'] =value.sourceMnemonic;
       if (this.selectedlabobj) {
        this.displaydeploy = this.selectedlabobj.deploymentkey + "(L)";
      }
      if(this.selectedlabobj.deploymentkey !== '' && this.displaydeploy !== undefined) {
        this.deploy.setValue(this.displaydeploy + " , " + this.displaydeploy2);
      }

    }
  }
  getTargetInfo(value){
    if(value !== undefined){
      this.selectedfacilityobj={}
      this.selectedfacilityobj.LocationGUID=value.destinationGUID
       this.selectedfacilityobj.deploymentkey = value.destinationDeploymentKey
      this.selectedfacilityobj.organizationid=value.destinationOrgId
        this.selectedfacilityobj.entityid=value.destinationAccountId
        this.selectedfacilityobj.Displayname =value.destinationName
       this.selectedfacilityobj.Address1 = value.destinationAddress1
        this.selectedfacilityobj.Address2 = value.destinationAddress2
        this.selectedfacilityobj.EntityType=value.destinationAccountType
        this.selectedfacilityobj.city=value.destinationCity
        this.selectedfacilityobj.state = value.destinationState
       this.selectedfacilityobj.zip = value.destinationZip
        this.selectedfacilityobj.TypeName=value.destinationType
        this.selectedfacilityobj.mnemonic =value.destinationMnemonic

       if(this.selectedlabobj ) {
        this.displaydeploy2 = this.selectedfacilityobj.deploymentkey + "(F)";
      }
      if(this.selectedfacilityobj.deploymentkey !=='' && this.displaydeploy2 !== undefined){
        this.deploy.setValue(this.displaydeploy + " , " + this.displaydeploy2);
      }

    }
  }
  getFilesInfo(value: any, batch : any) {
    this.batchArray=[]
this.selectedPID = value;
this.selectedBatchID = batch;
    if(value!== null || value!==undefined && batch !== null || batch !== undefined){
     this.uniqdata = this.importDataList.find(va => va.id === value)

      this.filesList = [];
      this.batchIndiStatus = [];
      if(this.uniqdata['fileContentList'] !== null){
        let batchItemList = this.uniqueArr(this.uniqdata['fileContentList'] , this.filesList)
        this.uniqdata['fileList'] = batchItemList.sort((a,b) => a.BatchID - b.BatchID);
      for (let j = 0; j < this.uniqdata['fileContentList'].length; j++) {
        // let batchList = this.uniqueArr(this.ViewInterfaceData[i].fileContentList, this.filesList);

        // this.ViewInterfaceData[i]['fileList'] = batchList

        if (this.uniqdata["fileContentList"][j]["CurrentRecords"] > 0) {
          this.uniqdata["fileContentList"][j]["progress"] =
            Math.round(
              (this.uniqdata["fileContentList"][j]["CurrentRecords"] *
                100) /
              this.uniqdata["fileContentList"][j]["TotalRecords"]
            );
        } else {
          this.uniqdata["fileContentList"][j]["progress"] = 0;
        }
      }

      // this.batchStatus = this.batchIndiStatus[0]
    }
    this.batchArray = this.uniqdata['fileContentList'].filter(va=> va.BatchID === batch);
    // console.log(this.batchArray)
    }
  }



  checkeStatus(array,ref,flag,isdraft){
    if(array.length>0){
      let list = array.filter(va =>va.BatchID == ref);
      if(list.length>0){
        for (let k = 0; k < list.length; k++) {
          if (list[k]['Status'] == 'Failed' ||
            list[k]['Status'].toLowerCase() == 'Error') {
               let status = list.filter(va => va.Status == 'Failed')
              if(flag == 'status'){
                return 'Failed'
              }else{
                return status.length
              }

            // this.batchIndiStatus.push(statu)
          }

          if (
            list[k]['Status'] == 'InProgress' ||
            list[k]['Status'] == 'Initial' ||
            list[k]['Status'] == 'Waiting') {
              let statusPro = list.filter(va=> va.Status == 'InProgress')
              let statusIni = list.filter(va=> va.Status == 'Initial')
              let statusWai = list.filter(va=> va.Status == 'Waiting')
              if(flag == 'status'){
                if(isdraft.toLowerCase() === 'draft'){
                  return 'Initial'
                }else{
                return 'In Progress'
                }
              }else if(statusPro.length>0){
                return statusPro.length
              }
              else if(statusWai.length > 0){
                return statusWai.length
              }
              else{
                return statusIni.length
              }


            // this.batchIndiStatus.push(statu)
          }
          if (list[k]['Status'] == 'Success') {
              let statusSuc = list.filter(va=> va.Status == 'Success')
              if(flag == 'status'){
                return'Success'
              }else{
                return statusSuc.length
              }

            // this.batchIndiStatus.push(statu)
          }
          if (list[k]['Status'] == 'Completed') {
              let statusComp = list.filter(va=> va.Status == 'Completed')
              if(flag == 'status'){
                return 'Completed'
              }else{
                return statusComp.length
              }

            // this.batchIndiStatus.push(statu)
          }
          if (list[k]['Status'] == 'Processing') {
            let statusComp = list.filter(va=> va.Status == 'Processing')
            if(flag == 'status'){
              return 'Processing'
            }else{
              return statusComp.length
            }

          // this.batchIndiStatus.push(statu)
        }
        if(list[k]['Status']=='RequestReceived'){
          let statusReq = list.filter(va=> va.Status == 'RequestReceived')
          if(flag=='status'){
            return 'Request Received'
          }
          else{
            return 0
          }
        }
        }
      }

    }
  }
  batchCount: any
  fileList = []
  batchIndiStatus = []
  batchStatus: any
  getInterfaceFormList(value: any) {

    this.isTabName = value;
    let obj = {
      projecttype: value,
      createdfrom: "",
      createdto: "",
      createdBy: "",
      sourceGuid: "",
      destinationGuid: "",
      id: this.objid,
      projectID: "",
      requester: "",
      projectName: "",
    };
    this.ngxuiloader.start();
    this.commonService.InterfaceFormList(obj).subscribe(
      (data) => {
        this.ngxuiloader.stop();
        if (data.status === "success") {
          this.createFtpinter()
          switch (value) {
            case 'Interface':
          this.ViewInterfaceData = JSON.parse(data.statusmessage);
              break;
            case 'Import':
              this.importDataList = JSON.parse(data.statusmessage);
              for(let i=0;i<this.importDataList.length;i++){
                if(this.importDataList[i]['fileContentList']!== null){
                for(let j=0;j<this.importDataList[i]['fileContentList'].length;j++){
            // this.ViewInterfaceData.forEach(this.getFilesInfo);
                  this.getFilesInfo(this.importDataList[i].id,this.importDataList[i]['fileContentList'][j].BatchID)
              }
            }
            }
              break;
            case 'Export':
              this.exportDataList = JSON.parse(data.statusmessage)
              // this.getInterfaceFormList()
              break;
            default:
              this.ViewInterfaceData = JSON.parse(data.statusmessage);
              break;
            }

          // if (this.isTabName == 'Import') {

          //   }
          }

        else {
          this.toastr.error(data.statusmessage, "", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
      },
      (error) => {
        this.ngxuiloader.stop();
        this.toastr.error(error.statusText, "", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
    );
  }

  createFtpinter(){
    // this.intervalim = setInterval(() => {
    //   this.commonService.getUpdateFTPInternal().subscribe(data =>{})
    // },30000)
  }

  finaliseform(id: number) {
    let obj = {
      interfaceRequestID: id,
      edit_mode: "false",
      SubmitResult: "1",
    };
    this.ngxuiloader.start();
    this.commonService.finalizeformdata(obj).subscribe(
      (data) => {
        this.ngxuiloader.stop();
        if (data > 0) {
          this.getFormListdata(0);
        }
      },
      (error) => {
        this.ngxuiloader.stop();
      }
    );
  }


  viewIndi(id,batch) {


    $("#viewIndividualFilesList").appendTo("body").modal("show");
    this.getFilesInfo(id,batch);
  }

  issub: string
  viewform: any[] = []
  viewlabCont: any[] = []
  viewReqCont: any[] = []
  viewFacCont: any[] = []
  viewVenCont: any[] = []
  viewInterfaces: any[] = []
  viewConnDetails: any
  Ftpdetails: any[] = []
  viewformpop(id, type,isimport:boolean,modalrefid) {

    this.isimport=isimport;
    this.ngxuiloader.start();
    let arr = []

    this.viewlabCont = []
    this.viewReqCont = []
    this.viewFacCont = []
    this.viewVenCont = []
    // $("#exampleModalForm").appendTo("body").modal("show");
    this.modalService
    .open(modalrefid, {
      windowClass: "saveVendormodal",
      backdrop: "static",
      keyboard: false,
    })
    .result.then((result) => {});
    this.commonService.ProjectFormEdit(id, type).subscribe((data) => {
      if (data) {
        this.ngxuiloader.stop();
        this.viewform = JSON.parse(data.statusmessage)

        if (this.viewform['projectStatus'] == 'submit') {
          this.issub = 'Submit'
        }
        else {
          this.issub = 'Save'
        }

        if(this.viewform['contactDetails'] !== ''){

        arr = JSON.parse(this.viewform['contactDetails'])

        this.viewlabCont = arr.filter(x => x.ContactType == 'lab')
        this.viewReqCont = arr.filter(x => x.ContactType == 'requester')
        this.viewFacCont = arr.filter(x => x.ContactType == 'facility')
        this.viewVenCont = arr.filter(x => x.ContactType == 'vendor')
          this.listaddress('interface');

        }

        if(this.viewform['interfaces'] !== ''){
        this.viewInterfaces = JSON.parse(this.viewform['interfaces'])
        // this.Ftpdetails = JSON.parse(this.viewform['projectVaFtpDetails'])
        for (let i = 0; i < this.viewInterfaces.length; i++) {
          if (this.viewInterfaces[i].connectivityDetails !== '') {
            this.viewConnDetails = this.viewConnDetails[i].connectivityDetails
          }
        }

      }
      this.projInterfacesList(id);
      }
      else {
        this.toastr.error("Failed!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.ngxuiloader.stop();
      }
    })
    // this.viewform = this.ViewInterfaceData.find(x=> x.id == id)
  }

  copyFTP() {

  }


  viewImport: any[] = []
  viewImpLab: any[] = []
  viewImpReq: any[] = []
  viewVendorImp: any[] = []

  viewexport: any[] = []
  viewexpLab: any[] = []
  viewexpReq: any[] = []
  viewVendorexp: any[] = []
  viewImporExpForm(id, type,isimport:boolean,modalrefid) {
    this.isimport=isimport;
    this.ngxuiloader.start();
    let arr = []
    this.viewImport=[]
    this.viewImpLab =[]
    this.viewImpReq  =[]
    this.viewVendorImp =[]

    this.viewexport=[]
    this.viewexpLab =[]
    this.viewexpReq  =[]
    this.viewVendorexp =[]

    this.modalService
    .open(modalrefid, {
      windowClass: "saveVendormodal",
      backdrop: "static",
      keyboard: false,
    })
    .result.then((result) => {});
    this.commonService.ProjectFormEdit(id, type).subscribe((data) => {
      if (data) {

        if(isimport)
        {
        this.viewImport = JSON.parse(data.statusmessage)
        arr = JSON.parse(this.viewImport['contactDetails'])
        this.viewImpLab = arr.filter(x => x.ContactType == 'lab')
        this.viewImpReq = arr.filter(x => x.ContactType == 'requester')
        this.viewVendorImp = arr.filter(x => x.ContactType == 'vendor')
        }
        else{
          this.viewexport = JSON.parse(data.statusmessage)
        arr = JSON.parse(this.viewexport['contactDetails'])
        this.viewexpLab = arr.filter(x => x.ContactType == 'lab')
        this.viewexpReq = arr.filter(x => x.ContactType == 'requester')
        this.viewVendorexp = arr.filter(x => x.ContactType == 'vendor')
        }
        this.ngxuiloader.stop();
      }
      else {
        this.toastr.error("Failed!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.ngxuiloader.stop();
      }
    })
    // this.viewform = this.ViewInterfaceData.find(x=> x.id == id)
  }

  newformpop() {
    // $("#exampleModalForm").appendTo("body").modal("show");
    this.isShowCard = !this.isShowCard;
    this.resetRequestForm();
  }
  closeCreateProject() {
    this.isShowCard = false;
    this.resetRequestForm();
    this.onTabChanged(this.selectedIndexTab)
    // this.getInterfaceFormList('Interface')

  }

  closeInterfaceViewProject(ref) {
    ref("close modal");
    // this.activeModal.close('exampleModalForm');
    // this.viewImport = [];
    // this.viewlabCont = [];
    // this.viewFacCont = [];
    // this.viewReqCont = [];
    // this.viewVenCont = [];
    // this.viewInterfaces = [];
    // this.viewConnDetails = [];
    // this.getInterfaceFormList('Interface');
  }

  closeImportViewProject() {
    this.activeModal.close('ImportForm');
    // this.viewform = [];
    // this.viewImpLab = [];
    // this.viewImpReq = [];
    // this.viewReqCont = [];
    // this.viewVendorImp = [];
    // this.ViewInterfaceData = [];
    // // this.batchIndiStatus = [];
    // this.uniqdata=[];
    // this.batchArray = [];
    // this.getInterfaceFormList('Import');
  }
  refreshPatient(){
    this.ngxuiloader.start()
this.commonService.refreshpatientImport(this.selectedPID).subscribe(data =>{
  this.ngxuiloader.stop();
if(data.length>0){
  this.batchArray = data.filter(va=>va.BatchID == this.selectedBatchID);
  for (let j = 0; j < this.batchArray.length; j++) {
        // let batchList = this.uniqueArr(this.ViewInterfaceData[i].fileContentList, this.filesList);

        // this.ViewInterfaceData[i]['fileList'] = batchList

        if (this.batchArray[j]["CurrentRecords"] > 0) {
          this.batchArray[j]["progress"] =
            Math.round(
              (this.batchArray[j]["CurrentRecords"] *
                100) /
                this.batchArray[j]["TotalRecords"]
            );
        } else {
          this.batchArray[j]["progress"] = 0;
        }
      }
  // this.getFilesInfo(this.selectedPID,this.selectedBatchID);/
}
},error =>{
  this.ngxuiloader.stop();
})
  }
  closeViewFile() {
    this.activeModal.close('viewIndividualFilesList');
    // this.fileList = [];
    // this.filesList = [];
    // // this.batchIndiStatus = [];
    // this.ViewInterfaceData = [];
    // this.uniqdata=[];
    // this.batchArray=[];
    // this.getInterfaceFormList('Import');
  }

  isedit: boolean = false;
  // Formpop(valuepop: any) {
  //
  //   this.ngxuiloader.start();

  //   this.commonService.getformdata(valuepop).subscribe(
  //     (data) => {
  //       this.ngxuiloader.stop();
  //       if (data.length > 0) {
  //         this.isedit = true
  //         this.isEditformsData = data
  //         this.filter(data[0].lab, "lab", true);
  //         this.filter(data[0].facilityName, "facility", true);
  //         this.filter(data[0].payingLab, "payinglab", true);
  //         if (data[0].connectivityConfig !== "") {
  //           var con = JSON.parse(data[0].connectivityConfig);
  //           this.ConnectivityFormGroup.patchValue({
  //             isVAFTP: con.IsVAFTP ? true : false
  //           })
  //           this.deploy.setValue(con.Deployment);
  //         }
  //         // this.selectedlabobj = this.labNameList.filter(
  //         //   (V) => V.LocationGUID === data[0].labguid);
  //         //   if(this.selectedlabobj.length>0){
  //         //     this.displaydeploy = this.selectedlabobj[0].deploymentkey + "(L)";
  //         //   }

  //         this.selectedpaylabobj = this.paylabNameList.filter((V) => V.LocationGUID === data[0].payinglabguid);

  //         // setTimeout(()=>
  //         // {

  //         this.patchformvalue(data);
  //         this.isEditLabGUID = data[0].labguid
  //         this.isEditPayingGUID = data[0].payinglabguid
  //         this.isEditFaciltyGUID = data[0].facilityguid

  //         this.formid = valuepop;
  //         this.isShowCard;
  //         // setTimeout(()=>{
  //         // $("#exampleModalForm").appendTo("body").modal("show");
  //         // })
  //       }
  //     },
  //     (error) => {
  //       this.ngxuiloader.stop();
  //     }
  //   );
  // }

  projid: string;
  projvaid: string;
  projtype: string;

  clearFormArray = (formArray : FormArray) =>{
    formArray = this._formBuilder.array([]);
  }


  editProjForm(id, type) {
    this.ngxuiloader.start();
    // (this.ConnectivityFormGroup.value['ProjInterfacecard']).clear();
    this.clearFormArray(this.ConnectivityFormGroup.value.ProjInterfacecard)
    this.commonService.ProjectFormEdit(id, type).subscribe(
      (data) => {
        if (data.status.toLowerCase() === "failure") {
          this.isedit = false;
          this.ngxuiloader.stop();
          this.toastr.error("Failed!", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          let arr = JSON.parse(data.statusmessage);
          this.projid = arr.id;
          this.projvaid = arr.projectID;
          this.isedit = true;
          this.isShowCard = true;
          this.patchformvalue(data.statusmessage);
        }
        this.ngxuiloader.stop();
      },
      (error) => {
        this.ngxuiloader.stop();
      }
    );
  }


  editImportProjForm(id, type) {
    this.ngxuiloader.start();
    this.commonService.ProjectFormEdit(id, type).subscribe(
      (data) => {
        if (data.status.toLowerCase() === "failure") {
          this.isedit = false;
          this.ngxuiloader.stop();
          this.toastr.error("Failed!", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          let arr = JSON.parse(data.statusmessage);
          this.ImpID = arr.id;
          this.projImpID = arr.projectID;
          this.isedit = true;
          this.isShowCard = true;
          this.patchformImpValue(data.statusmessage);
        }
        this.ngxuiloader.stop();
      },
      (error) => {
        this.ngxuiloader.stop();
      }
    );
  }

  projExpID: any
  ExpID: any
  editExportProjForm(id, type) {
    this.ngxuiloader.start();
    this.commonService.ProjectFormEdit(id, type).subscribe(
      (data) => {
        if (data.status.toLowerCase() === "failure") {
          this.isedit = false;
          this.ngxuiloader.stop();
          this.toastr.error("Failed!", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          let arr = JSON.parse(data.statusmessage);
          this.ExpID = arr.id;
          this.projExpID = arr.projectID;
          this.isedit = true;
          this.isShowCard = true;
          this.patchformExpValue(data.statusmessage);
        }
        this.ngxuiloader.stop();
      },
      (error) => {
        this.ngxuiloader.stop();
      }
    );
  }

  addLab(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    if (value) {
      this.Labs.push(value);
    }
    // Clear the input value
    if (event.input) {
      event.input.value = "";
    }
    this.LabCtrl.setValue(null);
  }
  removeLab(Lab: string): void {
    const index = this.Labs.indexOf(Lab);
    if (index >= 0) {
      this.Labs.splice(index, 1);
    }
  }
  selectedLab(event: MatAutocompleteSelectedEvent): void {
    this.Labs.push(event.option.viewValue);
    this.LabInput.nativeElement.value = "";
    this.labControl.setValue(null);
  }
  private _filterLab(value: string): string[] {
    const filterLabValue = value.toLowerCase();
    return this.allLabs.filter((lab) =>
      lab.toLowerCase().includes(filterLabValue)
    );
  }
  ///////////
  ///////////

  addpayingLab(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    // Add our fruit
    if (value) {
      this.payingLabs.push(value);
    }
    // Clear the input value
    if (event.input) {
      event.input.value = "";
    }
    this.payinglabControl.setValue(null);
  }
  removepayingLab(payingLab: string): void {
    const index = this.payingLabs.indexOf(payingLab);
    if (index >= 0) {
      this.payingLabs.splice(index, 1);
    }
    this.LabFormGroup.patchValue({ payingLab: this.payingLabs });
  }
  selectedpayingLab(event: MatAutocompleteSelectedEvent): void {
    this.payingLabs.push(event.option.viewValue);
    this.payingLabInput.nativeElement.value = "";
    this.payinglabControl.setValue(null);
    this.LabFormGroup.patchValue({ payingLab: this.payingLabs });
  }
  private _filterpayingLab(payinglabvalue: string): string[] {
    const filterpayinglabValue = payinglabvalue.toLowerCase();
    return this.allpayingLabs.filter((payingLab) =>
      payingLab.toLowerCase().includes(filterpayinglabValue)
    );
  }
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  intTypeSelect: string[];
  optionClick(event, index) {

    let newStatus = event.checked;
    // this.onAddNewCard().clear();
    this.intTypeSelect = event.value;
    let uniqarrayform =JSON.parse(JSON.stringify(this.ConnectivityFormGroup.value.ProjInterfacecard))
    for (let k = 0; k <  this.intTypeSelect.length; k++) {
       uniqarrayform = uniqarrayform.filter(va => va.interfaceType !==  this.intTypeSelect[k])
    }
    if(uniqarrayform.length > 0) {
      for (let l = 0; l < uniqarrayform.length; l++) {
        let ind =  this.ConnectivityFormGroup.value.ProjInterfacecard.findIndex( va => va.interfaceType ==  uniqarrayform[l].interfaceType )
      this.removeConnCard(ind)

      }

    }

    // this.ConnectivityFormGroup.patchValue({
    //   ProjInterfacecard :uniqarrayform
    // })
    // this.select.options.forEach((item: MatOption) => {
    //   if (!item.selected) {
    //     newStatus = false;
    //   }
    // });
    if (this.intTypeSelect.length === 0) {
      newStatus = false;
    }

    for (let i = 0; i < this.intTypeSelect.length; i++) {
      this.getNewConnCard(this.intTypeSelect[i]);

      // this.ConnectivityFormGroup.value.ProjInterfacecard.filter(va =>va.InterfaceName == )

    }

    this.ConnectivityFormGroup;
    this.addvalidation();

    this.allSelected = newStatus;
  }

  addvalidation() {
    if (
      // this.ConnectivityFormGroup.value.InterfaceType.length > 0
      this.DetailsFormGroup.value.ProjInterfaceType.length > 0
    ) {
      let isallowin = false;
      this.isInbound = false;

      // for (let i = 0; i < this.ConnectivityFormGroup.value.InterfaceType.length; i++) {
      for (
        let i = 0;
        i < this.DetailsFormGroup.value.ProjInterfaceType.length;
        i++
      ) {
        if (this.DetailsFormGroup.value.ProjInterfaceType[i].includes("In")) {
          // if (this.ConnectivityFormGroup.value.InterfaceType[i].includes("In")) {
          isallowin = true;
          this.isInbound = true;
        }

        if (isallowin) {
          this.ConnectivityFormGroup.controls[
            "ContentType"
          ].setValidators(Validators.required);
          this.ConnectivityFormGroup.controls[
            "ContentType"
          ].updateValueAndValidity();

          // this.MsgTypeControlin.setValidators(Validators.required);
          // this.MsgTypeControlin.updateValueAndValidity();

          // this.processoragentControlin.setValidators(Validators.required);
          // this.processoragentControlin.updateValueAndValidity();

          // this.senderagentControlin.setValidators(Validators.required);
          // this.senderagentControlin.updateValueAndValidity();

          // this.destinationagentControlin.setValidators(Validators.required);
          // this.destinationagentControlin.updateValueAndValidity();
        } else {
          this.ConnectivityFormGroup.controls[
            "ContentType"
          ].clearValidators();
          this.ConnectivityFormGroup.controls[
            "ContentType"
          ].updateValueAndValidity();

          // this.MsgTypeControlin.clearValidators();
          // this.MsgTypeControlin.updateValueAndValidity();

          // this.processoragentControlin.clearValidators();
          // this.processoragentControlin.updateValueAndValidity();

          // this.senderagentControlin.clearValidators();
          // this.senderagentControlin.updateValueAndValidity();

          // this.destinationagentControlin.clearValidators();
          // this.destinationagentControlin.updateValueAndValidity();
        }
      }
    }

    if (
      // this.ConnectivityFormGroup.value.InterfaceType.length > 0
      this.DetailsFormGroup.value.ProjInterfaceType.length > 0
    ) {
      let isallow = false;
      this.isOutbound = false;
      // for (let i = 0; i < this.ConnectivityFormGroup.value.InterfaceType.length; i++) {
      for (
        let i = 0;
        i < this.DetailsFormGroup.value.ProjInterfaceType.length;
        i++
      ) {
        if (this.DetailsFormGroup.value.ProjInterfaceType[i].includes("Out")) {
          // if (this.ConnectivityFormGroup.value.InterfaceType[i].includes("Out")) {
          isallow = true;
          this.isOutbound = true;
        }
      }

      if (isallow) {
        this.ConnectivityFormGroup.controls[
          "ContentType"
        ].setValidators(Validators.required);
        this.ConnectivityFormGroup.controls[
          "ContentType"
        ].updateValueAndValidity();

        // this.MsgTypeControlout.setValidators(Validators.required);
        // this.MsgTypeControlout.updateValueAndValidity();

        // this.processoragentControlout.setValidators(Validators.required);
        // this.processoragentControlout.updateValueAndValidity();

        // this.senderagentControlout.setValidators(Validators.required);
        // this.senderagentControlout.updateValueAndValidity();

        // this.destinationagentControlin.setValidators(Validators.required);
        // this.destinationagentControlin.updateValueAndValidity();
      } else {
        this.ConnectivityFormGroup.controls[
          "ContentType"
        ].clearValidators();
        this.ConnectivityFormGroup.controls[
          "ContentType"
        ].updateValueAndValidity();

        // this.MsgTypeControlout.clearValidators();
        // this.MsgTypeControlout.updateValueAndValidity();

        // this.processoragentControlout.clearValidators();
        // this.processoragentControlout.updateValueAndValidity();

        // this.senderagentControlout.clearValidators();
        // this.senderagentControlout.updateValueAndValidity();

        // this.destinationagentControlout.clearValidators();
        // this.destinationagentControlout.updateValueAndValidity();
      }
    }

    // if (
    //   this.ConnectivityFormGroup.value.ConnectivityType === "api" &&
    //   // this.ConnectivityFormGroup.value.InterfaceType.includes("In") &&
    //   this.DetailsFormGroup.value.ProjInterfaceType.includes("In") &&
    //   !this.ConnectivityFormGroup.value.isVAAPI_Inbound
    // ) {
    //   this.ConnectivityFormGroup.controls["Endpoint_Inbound"].setValidators(
    //     Validators.required
    //   );
    //   this.ConnectivityFormGroup.controls[
    //     "Endpoint_Inbound"
    //   ].updateValueAndValidity();
    // } else {
    //   this.ConnectivityFormGroup.controls["Endpoint_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls[
    //     "Endpoint_Inbound"
    //   ].updateValueAndValidity();
    // }

    // if (
    //   this.ConnectivityFormGroup.value.ConnectivityType === "api" &&
    //   // this.ConnectivityFormGroup.value.InterfaceType.includes("Out") &&
    //   this.DetailsFormGroup.value.ProjInterfaceType.includes("Out") &&
    //   !this.ConnectivityFormGroup.value.isVAAPI_Outbound
    // ) {
    //   this.ConnectivityFormGroup.controls["Endpoint_Outbound"].setValidators(
    //     Validators.required
    //   );
    //   this.ConnectivityFormGroup.controls[
    //     "Endpoint_Outbound"
    //   ].updateValueAndValidity();
    // } else {
    //   this.ConnectivityFormGroup.controls[
    //     "Endpoint_Outbound"
    //   ].clearValidators();
    //   this.ConnectivityFormGroup.controls[
    //     "Endpoint_Outbound"
    //   ].updateValueAndValidity();
    // }

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.ConnectivityFormGroup.value.InterfaceType.includes("In")) {

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.DetailsFormGroup.value.ProjInterfaceType.includes("In")) {
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["IP_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Host_Inbound"].updateValueAndValidity();
    // } else {
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["IP_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Host_Inbound"].updateValueAndValidity();
    // }

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.ConnectivityFormGroup.value.InterfaceType.includes("Out")) {

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.DetailsFormGroup.value.ProjInterfaceType.includes("Out")) {
    //   this.ConnectivityFormGroup.controls["Port_Outbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Port_Outbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Outbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["IP_Outbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Outbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Host_Outbound"].updateValueAndValidity();
    // } else {
    //   this.ConnectivityFormGroup.controls["Port_Outbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Port_Outbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Outbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["IP_Outbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Outbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Host_Outbound"].updateValueAndValidity();
    // }

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.ConnectivityFormGroup.value.InterfaceType.includes("In")) {

    // if (this.ConnectivityFormGroup.value.ConnectivityType === "vpn" && this.DetailsFormGroup.value.ProjInterfaceType.includes("In")) {
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["IP_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Inbound"].setValidators(Validators.required);
    //   this.ConnectivityFormGroup.controls["Host_Inbound"].updateValueAndValidity();
    // } else {
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Port_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["IP_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["IP_Inbound"].updateValueAndValidity();

    //   this.ConnectivityFormGroup.controls["Host_Inbound"].clearValidators();
    //   this.ConnectivityFormGroup.controls["Host_Inbound"].updateValueAndValidity();
    // }
  }

  //////////
  ConnSelected() { }
  LabImportControl = new FormControl("", Validators.required);
  LabExportControl = new FormControl("", Validators.required);
  vendorNameImpControl = new FormControl("", Validators.required);
  vendorNameExpControl = new FormControl("", Validators.required);
  vendorNameControl = new FormControl("", Validators.required);
  // vendorNameSelect = new FormControl("");
  facilityControl = new FormControl("", Validators.required);
  facilityAddressControl = new FormControl("", Validators.required);
  labControl = new FormControl("", Validators.required);
  payinglabControl = new FormControl("", Validators.required);
  MsgTypeControlin = new FormControl("");
  MsgTypeControlout = new FormControl("");

  senderagentControlin = new FormControl("");
  senderagentControlout = new FormControl("");
  processoragentControlin = new FormControl("");
  processoragentControlout = new FormControl("");
  destinationagentControlin = new FormControl("");
  destinationagentControlout = new FormControl("");

  ngOnInit() {
    // this.alltoaddressid=[];
    // this.toaddresslist=[]
    // this.toaddressctrl.setValue(null);

    // this.allCcid=[];
    // this.Cclist=[]
    // this.Cctrl.setValue(null);
    this.vendorRefresh =  this.commonService.vendorUpdate.subscribe(data => {
      if(data){

       this.getVendorname()
      }
    })


    this.filteredtoaddressid = this.toaddressctrl.valueChanges.pipe(
      startWith(null),
      map((id: string | null) => (id ? this._filtertoaddress(id) : this.alltoaddressid.slice())),
    );

    this.filteredCcid = this.Cctrl.valueChanges.pipe(
      startWith(null),
      map((id: string | null) => (id ? this._filterCc(id) : this.allCcid.slice())),
    );
if(this.commonService.lasturl !== ''){
  this.onTabChanged(1)
  this.commonService.lasturl = '';
}
    this.getInterfaceFormList("Interface");
    this.getVendorname();
    this.getFormListdata(0);
    this.selectInttype();
    // this.addfacilitycontactfield();
    // this.addlabcontactfield();
    // this.addvendorcontactfield();
    this.MsgTypeControlout.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          // this.autocompLoading = true;
          // this.ref.markForCheck()
        }),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            this.ref.markForCheck();
            // this.isMsgoutLoading = true
            this.msgtypefunction(value, "Out");
          } else {
            this.msgtypelistout = [];
            return of(null);
          }
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
          }
          this.ref.markForCheck();
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );
    this.MsgTypeControlin.valueChanges
      .pipe(
        debounceTime(1000),
        // tap(() => {
        //   // this.autocompLoading = true;
        //   // this.ref.markForCheck()
        // }),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            this.ref.markForCheck();
            // this.isMsginLoading = true
            this.msgtypefunction(value, "In");
          } else {
            this.msgtypelistin = [];
            return of(null);
          }
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
          }
          this.ref.markForCheck();
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );
    this.facilityControl.valueChanges
      .pipe(
        debounceTime(1000),
        // tap(() => {
        //   // this.autocompLoading = true;
        //   // this.ref.markForCheck()
        // }),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            // this.isFacilityLoading = true

            this.ref.markForCheck();
            this.filter(value, "facility", false);
          } else {
            this.displaydeploy2 = "";
            this.FacilityNameList = [];
            return of(null);
          }
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
            this.isFacilityLoading = false;
          }
          this.ref.markForCheck();
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );
    this.payinglabControl.valueChanges
      .pipe(
        debounceTime(1000),
        // tap(() => {
        //   // this.autocompLoading = true;
        //   // this.ref.markForCheck()
        // }),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            this.ref.markForCheck();
            // this.isPLabLoading = true

            this.filter(value, "payinglab", false);
          } else {
            this.paylabNameList = [];
            return of(null);
          }
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
            this.isPLabLoading = false;
          }
          this.ref.markForCheck();
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );
    // this.vendorNameControl.valueChanges
    //   .pipe(
    //     debounceTime(1000),
    //     tap(() => {
    //       // this.autocompLoading = true;
    //       // this.ref.markForCheck()
    //     }),
    //     distinctUntilChanged(),
    //     switchMap((value: string): any => {
    //       if (value !== undefined && value !== null && value !== "") {
    //         // this.autocompLoading = true;
    //         this.ref.markForCheck();
    //         this.isLoadingVendorName = true;

    //         this.filter(value, "vendorName", false);
    //       } else {
    //         this.VendorsList = [];
    //         return of(null);
    //       }
    //       // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
    //     })
    //   )
    //   .subscribe(
    //     (data) => {
    //       this.ngxuiloader.stop();
    //       if (data !== null) {
    //       }
    //       this.ref.markForCheck();
    //       if (data) {
    //         // this.payorList = data;
    //       }
    //     },
    //     (error) => {
    //       // this.autocompLoading = false;
    //       console.error();
    //     }
    //   );
    this.labControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            // this.isLabLoading = false;
            this.ref.markForCheck();
            this.filter(value, "lab", false);
          } else {
            this.displaydeploy = "";
            this.labNameList = [];
            return of(null);
          }
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
            // this.isLabLoading = false;
          }
          this.ref.markForCheck();
          // this.isLabLoading = false;
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );
    this.LabImportControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            // this.isLabImpLoading = true;
            this.ref.markForCheck();
            this.filter(value, "labImport", false);
          } else {
            this.displaydeployImp = "";
            this.labNameImpList = [];
            return of(null);
          }
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
            // this.isLabImpLoading = false;
          }
          this.ref.markForCheck();
          this.isLabImpLoading = false;
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );

    this.LabExportControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            // this.isLabExpLoading = true;
            this.ref.markForCheck();
            this.filter(value, "labExport", false);
          } else {
            this.displaydeployExp = "";
            this.labNameExpList = [];
            return of(null);
          }
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
            this.isLabExpLoading = false;
          }
          this.ref.markForCheck();
          this.isLabExpLoading = false;
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );

    this.processoragentControlout.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          // this.autocompLoading = true;
          // this.ref.markForCheck()
        }),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            this.ref.markForCheck();
            this.listvalidateout = true;
            this.isProcessoroutLoading = true;
            this.processorapifunctionout(value);
          } else {
            this.listvalidateout = false;
            return of(null);
          }
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
          }
          this.ref.markForCheck();
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );

    this.processoragentControlin.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          // this.autocompLoading = true;
          // this.ref.markForCheck()
        }),
        distinctUntilChanged(),
        switchMap((value: string): any => {
          if (value !== undefined && value !== null && value !== "") {
            // this.autocompLoading = true;
            this.ref.markForCheck();
            this.isProcessorinLoading = true;
            this.listvalidatein = true;
            this.processorapifunction(value);
          } else {
            this.listvalidatein = false;
            return of(null);
          }
          // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
        })
      )
      .subscribe(
        (data) => {
          this.ngxuiloader.stop();
          if (data !== null) {
          }
          this.ref.markForCheck();
          if (data) {
            // this.payorList = data;
          }
        },
        (error) => {
          // this.autocompLoading = false;
          console.error();
        }
      );
  //   this.vendorNameImpControl.valueChanges
  //     .pipe(
  //       debounceTime(1000),
  //       tap(() => {
  //         // this.autocompLoading = true;
  //         // this.ref.markForCheck()
  //       }),
  //       distinctUntilChanged(),
  //       switchMap((value: string): any => {
  //         if (value !== undefined && value !== null && value !== "") {
  //           // this.autocompLoading = true;
  //           this.ref.markForCheck();
  //           this.isLoadingVendorNameImp = true;

  //           this.filter(value, "vendorName", false);
  //         } else {
  //           this.VendorsListImp = [];
  //           return of(null);
  //         }
  //         // return value !== undefined && value !== null && value !== '' && this.isLoading && !this.valueSelected && value.length>=this.minautocompchars? this.voservice.getpayors(0, value) : of([])
  //       })
  //     )
  //     .subscribe(
  //       (data) => {
  //         this.ngxuiloader.stop();
  //         if (data !== null) {
  //         }
  //         this.ref.markForCheck();
  //         if (data) {
  //           // this.payorList = data;
  //         }
  //       },
  //       (error) => {
  //         // this.autocompLoading = false;
  //         console.error();
  //       }
  //     );
  // }

      }

  processorapilistin: any;
  listvalidatein: boolean;
  processorapifunction(value: any) {
    this.listvalidatein = false;
    this.commonService.getproccessorapidata(value).subscribe((data) => {
      this.listvalidatein = true;
      this.isProcessorinLoading = false;
      this.processorapilistin = data;
    });
  }
  senderanddestinjson: any;
  senderanddestinjsonid: any;
  senderanddestinjsonpipelineuniqueid: any;
  processoridin: any;
  senderanddestin(id: any) {
    let obj = {
      id: id,
      type: "Processor",
    };
    this.processoridin = id;
    this.commonService.getagents(obj).subscribe((data) => {
      this.senderanddestinjsonid = data[0].id;
      this.senderanddestinjsonpipelineuniqueid = data[0].UniqueID;
      this.senderanddestinjson = JSON.parse(data[0].jsonconfig);
      this.isProcessorinLoading = false;
      this.senderagentControlin.setValue(
        this.senderanddestinjson.processoragentconfig.sourceagentid
      );
      this.destinationagentControlin.setValue(
        this.senderanddestinjson.processoragentconfig.destinationagentid
      );
    });
  }
  processoragentfunction() {
    let [contenttype, msgtype, direction] = [
      this.ConnectivityFormGroup.get("ContentType").value,
      this.MsgTypeControlin.value,
      // this.ConnectivityFormGroup.get("InterfaceType").value,
      this.ConnectivityFormGroup.get("InterfaceType").value,
    ];
    if (
      (contenttype != null || contenttype != undefined) &&
      (msgtype != null || msgtype != undefined) &&
      (direction != null || direction != undefined)
    ) {
      let obj = {
        contenttype: contenttype,
        msgtype: msgtype,
        direction: "Inbound",
      };
      this.commonService.getproccessordata(obj).subscribe((data) => {
        // this.isProcessorinLoading=false
        this.totalprocessdatain = data;
      });
    }
  }
  selectedvalue: string = "";
  selectedLabImpvalue: string = "";
  selectedLabExpvalue: string = "";
  Labselected = new FormControl("");
  LabImpselected = new FormControl("");
  LabExpselected = new FormControl("");
  selectedpaylabvalue: string = "";
  payLabselected = new FormControl("");
  Facilityselected = new FormControl("");
  notfounddataImp: boolean
  notfounddataExp: boolean
  filter(event: any, column: any, iseditform: boolean) {
    this.isLoadingroutes = false;
    if (column == "lab") {
      // this.isLabLoading = true;
    }
    this.commonService.getEntityfilterdata(event).subscribe((data) => {
      if (column == "facility") {
        this.isLoadingroutes = true;
        this.Facilityselected.setValue(event);
        if (data.length == 0) {
          this.FacilityNameList = [];
          this.isFacilityLoading = false;

          this.notfounddata = true;
        } else {
          this.FacilityNameList = [];
          this.isFacilityLoading = false;
          this.FacilityNameList = data;
          this.notfounddata = false;
          if (iseditform) {
            this.Assignlabdeloyment();
          }
        }
      }
      if (column == "lab") {
        this.selectedvalue = event;
        this.Labselected.setValue(event);
        if (data.length == 0) {
          this.labNameList = [];
          this.isLabLoading = false;
          this.notfounddata1 = true;
        } else {
          this.labNameList = [];
          this.isLabLoading = false;
          this.labNameList = data;
          this.notfounddata1 = false;
          if (iseditform) {
            this.Assignlabdeloyment();
          }
        }
        // return this.labNameList
      }
      if (column == "payinglab") {
        this.selectedpaylabvalue = event;
        this.payLabselected.setValue(event);
        if (data.length == 0) {
          this.paylabNameList = [];
          this.isPLabLoading = false;
          this.notfounddata2 = true;
        } else {
          this.paylabNameList = [];
          this.isPLabLoading = false;
          this.paylabNameList = data;
          this.notfounddata2 = false;
        }
      }
      if (column == "labImport") {
        this.selectedLabImpvalue = event;
        this.LabImpselected.setValue(event);
        if (data.length == 0) {
          this.labNameImpList = [];
          this.isLabImpLoading = false;
          this.notfounddataImp = true;
        } else {
          this.labNameImpList = [];
          this.isLabImpLoading = false;
          this.labNameImpList = data;
          this.notfounddataImp = false;
          if (iseditform) {
            this.Assignlabdeloyment();
          }
        }
        // return this.labNameList
      }
      if (column == "labExport") {
        this.selectedLabExpvalue = event;
        this.LabExpselected.setValue(event);
        if (data.length == 0) {
          this.labNameExpList = [];
          this.isLabExpLoading = false;
          this.notfounddataExp = true;
        } else {
          this.labNameExpList = [];
          this.isLabExpLoading = false;
          this.labNameExpList = data;
          this.notfounddataExp = false;
          if (iseditform) {
            this.Assignlabdeloyment();
          }
        }
        // return this.labNameList
      }

    });
  }

  Assignlabdeloyment() {
    if (this.isEditformsData.length > 0 && this.labNameList.length > 0) {
      this.selectedlabobj = this.labNameList.filter(
        (V) => V.LocationGUID === this.isEditformsData.labguid
      );
      if (this.selectedlabobj.length > 0) {
        this.displaydeploy = this.selectedlabobj.deploymentkey + "(L)";
      }
      this.deploy.setValue(this.displaydeploy + " , " + this.displaydeploy2);
    }
    if (this.isEditformsData.length > 0 && this.FacilityNameList.length > 0) {
      this.selectedfacilityobj = this.FacilityNameList.filter(
        (V) => V.LocationGUID === this.isEditformsData.facilityguid
      );
      if (this.selectedfacilityobj.length > 0) {
        this.displaydeploy2 = this.selectedfacilityobj.deploymentkey + "(F)";
      }
      this.deploy.setValue(this.displaydeploy + "  " + this.displaydeploy2);
    }
    if (this.isEditformsData.length > 0 && this.labNameImpList.length > 0) {
      this.selectedlabImpobj = this.labNameImpList.filter(
        (V) => V.LocationGUID === this.isEditformsData.labguid
      );
      if (this.selectedlabImpobj.length > 0) {
        this.displaydeployImp = this.selectedlabImpobj.deploymentkey + "(L)";
      }
      this.deploy.setValue(this.displaydeployImp);
    }
    if (this.isEditformsData.length > 0 && this.labNameExpList.length > 0) {
      this.selectedlabExpobj = this.labNameExpList.filter(
        (V) => V.LocationGUID === this.isEditformsData.labguid
      );
      if (this.selectedlabExpobj.length > 0) {
        this.displaydeployExp = this.selectedlabExpobj.deploymentkey + "(L)";
      }
      this.deploy.setValue(this.displaydeployExp);
    }
  }

  facaddr1: string
  facaddr2: string
  isLabImpLoading: boolean
  selectedlabImpobj: any
  selectedlabExpobj: any
  isLabExpLoading: boolean
  displaydeployImp: any
  displaydeployExp: any
  labNameExpList = <any>[];
  filterbyguid(guid: any, field: any) {
    let selecteguid,deployment
    if(guid !== ''){
       selecteguid= guid.split(":")[1]
      selecteguid = selecteguid.split(")")[0]
   deployment= guid.split("(")[2]
  deployment= deployment.split(")")[0]
    }


    if (field == "lab") {

      this.isLabLoading = false;

      this.selectedlabobj = this.labNameList.find( V=> V.LocationGUID === selecteguid && V.deploymentkey === deployment )
      let obj = {
        ...this.selectedlabobj,
        modifierid: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
          .userdata["userid"],
      };
      this.displaydeploy = this.selectedlabobj.deploymentkey + "(L)";
    }
    if (field == "payinglab") {

      this.isPLabLoading = false;
      this.selectedpaylabobj = this.paylabNameList.find( V=> V.LocationGUID === selecteguid  && V.deploymentkey === deployment )
      // this.displaydeploy = this.selectedpaylabobj[0].deploymentkey + "(PL)";
    }
    if (field == "facility") {

      this.isFacilityLoading = false;
      this.selectedfacilityobj = this.FacilityNameList.find( V=> V.LocationGUID === selecteguid  && V.deploymentkey === deployment )
      this.displaydeploy2 = this.selectedfacilityobj.deploymentkey + "(F)";
      this.facaddr1 = this.selectedfacilityobj.Address1 !== '' || this.selectedfacilityobj.Address1 !== null ? this.selectedfacilityobj.Address1 : this.selectedfacilityobj.Address2

      this.FacilityFormGroup.patchValue({
        facilityaddress:this.facaddr1,
      });
      // this.facaddr2 = this.selectedfacilityobj.Address2 !== '' || this.selectedfacilityobj.Address2 !== null ? this.selectedfacilityobj.Address2 : this.facilityAddressControl.value
    }

    this.deploy.setValue(this.displaydeploy + "  " + this.displaydeploy2);

    if (field == "labImport") {
      this.isLabImpLoading = false;
      this.selectedlabImpobj = this.labNameImpList.find(
        (V) => V.LocationGUID === selecteguid  && V.deploymentkey === deployment
      );
      let obj = {
        ...this.selectedlabImpobj,
        modifierid: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
          .userdata["userid"],
      };
      this.displaydeployImp = this.selectedlabImpobj.deploymentkey + "(L)";
    }

    if (field == "labExport") {
      this.isLabExpLoading = false;
      this.selectedlabExpobj = this.labNameExpList.find(
        (V) => V.LocationGUID === selecteguid && V.deploymentkey === deployment
      );
      let obj = {
        ...this.selectedlabExpobj,
        modifierid: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
          .userdata["userid"],
      };
      this.displaydeployExp = this.selectedlabExpobj.deploymentkey + "(L)";
    }
  }

  msgtypelistin: any;
  msgtypelistout: any;
  msgtypefunction(value: any, direction: any) {
    this.commonService.getMsgtypedata().subscribe((data) => {
      if (direction == "In") {
        this.isMsginLoading = false;
        this.msgtypelistin = data.filter((V) => V.includes(value));
        return this.msgtypelistin;
      }
      if (direction == "Out") {
        this.isMsgoutLoading = false;
        this.msgtypelistout = data.filter((V) => V.includes(value));
        return this.msgtypelistout;
      }
    });
  }
  totalprocessdatain: any;
  totalprocessdataout: any;
  listvalidateout: boolean;
  processorapilistout: any;
  processorapifunctionout(value: any) {
    this.listvalidateout = false;
    this.commonService.getproccessorapidata(value).subscribe((data) => {
      this.listvalidateout = true;
      this.isProcessoroutLoading = false;
      this.processorapilistout = data;
    });
  }
  senderanddestoutjson: any;
  senderanddestoutjsonid: any;
  senderanddestoutjsonpipelineuniqueid: any;
  processoridout: any;
  senderanddestout(id: any) {
    let obj = {
      id: id,
      type: "Processor",
    };
    this.processoridout = id;
    this.commonService.getagents(obj).subscribe((data) => {
      this.senderanddestoutjsonid = data[0].id;
      this.senderanddestoutjsonpipelineuniqueid = data[0].UniqueID;
      this.senderanddestoutjson = JSON.parse(data[0].jsonconfig);
      this.isProcessoroutLoading = false;
      this.senderagentControlout.setValue(
        this.senderanddestoutjson.processoragentconfig.sourceagentid
      );
      this.destinationagentControlout.setValue(
        this.senderanddestoutjson.processoragentconfig.destinationagentid
      );
    });
  }
  flag: boolean = false;
  flag2: boolean = false;

  interfacebtwselected() {
    if (this.LabFormGroup.get("interfaceBetween").value == "Custom") {
      this.flag = true;
      // this.LabFormGroup.patchValue({interfaceBetween:''})
    } else {
      this.flag = false;
    }
  }
  interfacescopeselected() {
    if (this.LabFormGroup.get("interfaceScope").value == "Other") {
      this.flag2 = true;
      // this.LabFormGroup.patchValue({interfaceScope:''})
    } else {
      this.flag2 = false;
    }
  }

  processoragentfunctionout() {
    let [contenttype, msgtype, direction] = [
      this.ConnectivityFormGroup.get("ContentTypeOutbound").value,
      this.MsgTypeControlout.value,
      this.ConnectivityFormGroup.get("InterfaceType").value,
    ];
    if (
      (contenttype != null || contenttype != undefined) &&
      (msgtype != null || msgtype != undefined) &&
      (direction != null || direction != undefined)
    ) {
      let obj = {
        contenttype: contenttype,
        msgtype: msgtype,
        direction: "Outbound",
      };
      this.commonService.getproccessordata(obj).subscribe((data) => {
        this.totalprocessdataout = data;
      });
    }
  }

  resetRequestForm() {
    this.projImpID = undefined;
    this.projExpID = undefined;
    this.projid = undefined;
    this.LabFormGroup.reset();
    this.payinglabControl.reset();
    this.FacilityFormGroup.reset();
    this.facaddr1 ='';
    this.requesterFormGroup.reset();
    this.VendorFormGroup.reset();
    this.DetailsFormGroup.reset();
    this.ConnectivityFormGroup.reset();
    this.labControl.reset();
    this.payingLabCtrl.reset();
    this.destinationagentControlout.reset();
    this.senderagentControlout.reset();
    this.processoragentControlout.reset();
    this.MsgTypeControlout.reset();
    this.destinationagentControlin.reset();
    this.senderagentControlin.reset();
    this.processoragentControlin.reset();
    this.MsgTypeControlin.reset();
    this.deploy.reset();
    this.Labselected.reset();
    this.Facilityselected.reset();
    this.facilityControl.reset();
    this.facilityAddressControl.reset();
    this.Labs = [];
    this.payingLabs = [];
    this.isedit = false;
    this.vendorNameControl.reset();
    this.vendorNameImpControl.reset();
    this.vendorNameExpControl.reset();
    this.resetExportForm();
    this.resetImportForm();
    this.projFiles=[];
    this.selectedfacilityobj='';
    this.selectedlabobj="";
    (this.LabFormGroup.get("labContactdetails") as FormArray).clear();
    (this.FacilityFormGroup.get("facilityContactdetails") as FormArray).clear();
    (this.VendorFormGroup.get("vendorContactdetails") as FormArray).clear();
    (this.ConnectivityFormGroup.get("ProjInterfacecard") as FormArray).clear();
  }

  resetImportForm() {
    this.LabFormGroupImport.reset();
    this.LabImportControl.reset();
    this.requesterFormGroupImport.reset();
    this.VendorFormGroupImport.reset();
    this.projectFormGroupImport.reset();
    (this.LabFormGroupImport.get("labContactdetailsImport") as FormArray).clear();
  }

  resetExportForm() {
    this.LabFormGroupExport.reset();
    this.LabExportControl.reset();
    this.requesterFormGroupExport.reset();
    this.VendorFormGroupExport.reset();
    this.projectFormGroupExport.reset();
    (
      this.LabFormGroupExport.get("labContactdetailsExport") as FormArray
    ).clear();
  }

  submitImportForm(id: any, isSubmitForms: boolean) {

    if (this.selectedlabImpobj !== undefined) {
      this.LabFormGroupImport.patchValue({ LabImport: this.selectedlabImpobj.Displayname });
    }

    let contactDetailsImp = [];
    if (this.LabFormGroupImport.value.labContactdetailsImport.length > 0) {
      let obs = {
        lab: this.LabFormGroupImport.value.labContactdetailsImport,
      };
      contactDetailsImp.push(obs);
    }
    if (this.VendorFormGroupImport.value.vendorImpContactdetails.length > 0) {
      let obs = {
        vendor: this.VendorFormGroupImport.value.vendorImpContactdetails,
      };
      contactDetailsImp.push(obs);
    }
    let obs = {
      requester: [
        {
          name: this.requesterFormGroupImport.value.requesterImpName,
          email: this.requesterFormGroupImport.value.requesterImpEmail,
          phone: this.requesterFormGroupImport.value.requesterImpPhone,
          id: this.reqImpId !== undefined ? this.reqImpId : this.reqNoID
        },
      ],
    };
    contactDetailsImp.push(obs);

    let detailImp = JSON.stringify(contactDetailsImp);

    let obj = {
      isSubmit: false,
      userId: JSON.parse(sessionStorage.getItem("rolewithmenus"))[0].uniqueId,
      projectID: this.projImpID !== undefined ? this.projImpID : "",
      id: this.ImpID !== undefined ? this.ImpID :0 ,
      // id: this.ImpID !== undefined ? this.ImpID : this.objid,
      projectType: "Import",
      sourceGuid:
        this.selectedlabImpobj !== undefined
          ? this.selectedlabImpobj.LocationGUID
          : this.isEditLabGUID,
      sourceDeploymentKey: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.deploymentkey : "",
      sourceOrgId: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.organizationid : "",
      sourceAccountId: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.entityid : "",
      sourceName: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.Displayname : this.LabFormGroupImport.value.lab,
      sourceOrgName: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.Displayname : "",
      sourceAccountType: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.EntityType : "",
      sourceAddress1: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.Address1 : "",
      sourceAddress2: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.Address2 : "",
      sourceCity: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.city : "",
      sourceState: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.state : "",
      sourceZip: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.zip : "",
      sourceType: this.selectedlabImpobj !== undefined ? this.selectedlabImpobj.Type : "",
      sourceMnemonic: this.selectedlabImpobj !== undefined && this.selectedlabImpobj.mnemonic !== undefined  ? this.selectedlabImpobj.mnemonic : "",
      requesterName: this.requesterFormGroupImport.value.requesterImpName,
      projectName: this.projectFormGroupImport.value.projectImpName,
      projectScope: this.projectFormGroupImport.value.projectImpScope,
      contactDetails: detailImp,
      vendorID: this.vendoIDImp,
      vendorName: "",
      vendorMnemonic: this.venMnemonicImp ,
      appName: this.VendorFormGroupImport.value.ApplicationNameImp,
      appVersion: this.VendorFormGroupImport.value.ApplicationVersionImp,
    };


    if (isSubmitForms === true) {
      if (this.LabFormGroupImport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.LabFormGroupImport.controls.labContactdetailsImport.status == "INVALID") {
        this.toastr.warning("Invalid contact details", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.requesterFormGroupImport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Requester Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.projectFormGroupImport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Project Details Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (
        this.LabFormGroupImport.status != "INVALID" &&
        this.requesterFormGroupImport.status != "INVALID" &&
        this.projectFormGroupImport.status !== "INVALID"
      ) {
        obj.isSubmit = true;
        obj.vendorName = this.vendoNameImp,
          // obj.interfaces = config
          this.ngxuiloader.start();         
        this.commonService.submitInterfaceForm(obj).subscribe((data) => {
          this.ngxuiloader.stop();

          if (data) {
            this.toastr.success("Submitted Successfully", data.statusmessage, {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
            // this.isShowCard = false;
            // this.getInterfaceFormList("Import");
              this.closeCreateProject()
          }
        });
      }
    }

    else {
      this.ngxuiloader.start();
      this.commonService.saveInterfaceForm(obj).subscribe((data) => {
        this.ngxuiloader.stop();
        if (data.status == "Failure") {
          this.toastr.error("Failed to Save", data.statusmessage, {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
        else {
          this.toastr.success(data.statusmessage, "", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
          this.interfaceFormIdResponse = data.responsebody;
          this.isShowCard = false;
          // this.resetRequestForm();
          // this.getInterfaceFormList("Import");
          this.closeCreateProject()
        }
      });
    }
  }

  submitExportForm(id: any, isSubmitForms: boolean) {

    if (this.selectedlabExpobj !== undefined) {
      this.LabFormGroupExport.patchValue({ LabExport: this.selectedlabExpobj.Displayname });
    }

    let contactDetailsExp = [];
    if (this.LabFormGroupExport.value.labContactdetailsExport.length > 0) {
      let obs = {
        lab: this.LabFormGroupExport.value.labContactdetailsExport,
      };
      contactDetailsExp.push(obs);
    }
    let obs = {
      requester: [
        {
          name: this.requesterFormGroupExport.value.requesterExpName,
          email: this.requesterFormGroupExport.value.requesterExpEmail,
          phone: this.requesterFormGroupExport.value.requesterExpPhone,
          id: this.reqID !== undefined ? this.reqID : this.reqNoID
        },
      ],
    };
    contactDetailsExp.push(obs);

    let detailExp = JSON.stringify(contactDetailsExp);

    let obj = {
      isSubmit: false,
      userId: JSON.parse(sessionStorage.getItem("rolewithmenus"))[0].uniqueId,
      projectID: this.projExpID !== undefined ? this.projExpID : "",
      id: this.ExpID !== undefined ? this.ExpID : 0,
      projectType: "Export",
      sourceGuid:
        this.selectedlabExpobj !== undefined
          ? this.selectedlabExpobj.LocationGUID
          : this.isEditLabGUID,
      sourceDeploymentKey: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.deploymentkey : "",
      sourceOrgId: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.organizationid : "",
      sourceAccountId: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.entityid : "",
      sourceName: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.Displayname  : this.LabFormGroupExport.value.labExport,
      sourceOrgName: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.Displayname : "",
      sourceAccountType: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.EntityType : "",
      sourceAddress1: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.Address1 : "",
      sourceAddress2: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.Address2 : "",
      sourceCity: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.city : "",
      sourceState: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.state : "",
      sourceZip: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.zip : "",
      sourceType: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.Type : "",
      sourceMnemonic: this.selectedlabExpobj !== undefined ? this.selectedlabExpobj.mnemonic  : "",
      requesterName: this.requesterFormGroupExport.value.requesterExpName,
      projectName: this.projectFormGroupExport.value.projectExpName,
      projectScope: this.projectFormGroupExport.value.projectExpScope,
      contactDetails: detailExp,
      vendorID: this.vendoIDExp,
      vendorName: "",
      vendorMnemonic: this.venMnemonicExp ,
      appName: this.VendorFormGroupExport.value.ApplicationNameExp,
      appVersion: this.VendorFormGroupExport.value.ApplicationVersionExp,
    };


    if (isSubmitForms === true) {
      if (this.LabFormGroupExport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.LabFormGroupExport.controls.labContactdetailsExport.status == "INVALID") {
        this.toastr.warning("Invalid contact details", "Lab Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.requesterFormGroupExport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Requester Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (this.projectFormGroupExport.status == "INVALID") {
        this.toastr.warning("required fields are empty:", "Project Details Form", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      if (
        this.LabFormGroupExport.status != "INVALID" &&
        this.requesterFormGroupExport.status != "INVALID" &&
        this.projectFormGroupExport.status !== "INVALID"
      ) {
        obj.isSubmit = true;
        obj.vendorName = this.vendoNameExp
        // obj.interfaces = config
        this.ngxuiloader.start();

       
        this.commonService.submitInterfaceForm(obj).subscribe((data) => {
          this.ngxuiloader.stop();

          if (data) {
            this.toastr.success("Submitted Successfully", data.statusmessage, {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
            this.closeCreateProject()
            // this.isShowCard = false;
            // this.getInterfaceFormList("Export");
          }
        });
      }
    }

    else {
      this.ngxuiloader.start();
      this.commonService.saveInterfaceForm(obj).subscribe((data) => {
        this.ngxuiloader.stop();
        if (data.status == "Failure") {
          this.toastr.error("Failed to Save", data.statusmessage, {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
        else {
          this.toastr.success(data.statusmessage, "", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
          this.interfaceFormIdResponse = data.responsebody;
          this.isShowCard = false;
          // this.resetRequestForm();
          // this.getInterfaceFormList("Export");
          this.closeCreateProject()
        }
      });
    }
  }

  Subject = new FormControl('');
  /////////////////////////////////////////tomatchip
  addOnBlur = true
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  toaddresslist: any = [
  ];
  fromaddress: string = 'noreply@starmarkit.com'

  toaddressctrl = new FormControl('');
  filteredtoaddressid: Observable<string[]>;

  alltoaddressid: any = []


  addtoAddress(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (this.isEmail(value.trim())) {
      if ((value || '').trim()) {
        this.toaddresslist.push({ toAddressid: value.trim() });
        this.toaddressctrl.setValue('')
        this.tomailInput.nativeElement.value=null
      }
    }
    this.toaddressctrl.setValue(null);
  }

  isEmail(search: string) {
    let serchfind;
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    serchfind = regexp.test(search);
    return serchfind
  }

  removetoAddress(tolist: any): void {
    const index = this.toaddresslist.indexOf(tolist);

    if (index >= 0) {
      this.toaddresslist.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    let obj = {
      toAddressid: event.option.viewValue
    }
    let isccid=this.Cclist.find(X=>X.Ccid===event.option.viewValue);
    if(this.toaddresslist.length>0){
      let isexist=this.toaddresslist.filter(V=>V.toAddressid===event.option.viewValue)
      if(isexist.length==0 &&(isccid==undefined || isccid==null))
    {
      this.toaddresslist.push(obj);
    }
  }
  else{
    if(isccid==undefined || isccid==null)
    {
      this.toaddresslist.push(obj);
    }
  }
    if (this.tomailInput !== undefined) {
      this.tomailInput.nativeElement.value = '';
    }
    this.toaddressctrl.setValue(null);
    // if(this.tomailInput){
    //   this.tomailInput.nativeElement.blur();
    // }
  }

  private _filtertoaddress(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.alltoaddressid.filter(V => V.toAddressid.toLowerCase().includes(filterValue));

  }
  /////////////////////////////////////////Ccmatchip


  // Cclist: any = [
  //   { Ccid: 'aleem.nasiyathulla@starmarkit.com' }
  // ];
  Cclist: any = [
  ];
  Cctrl = new FormControl('');
  filteredCcid: Observable<string[]>;
  // allCcid: any = [
  //   { Ccid: 'aishwarya.narayanan@starmarkit.com' },
  //   { Ccid: 'jyothiswaroop.r@starmarkit.com' },
  //   { Ccid: 'manu.talwar@starmarkit.com' },
  //   { Ccid: 'vidyashree.garagad@starmarkit.com' },
  //   { Ccid: 'kalpana.thammineni@starmarkit.com' },
  //   { Ccid: 'nithin.tl@starmarkit.com' }
  // ];
  allCcid: any = [
  ];



  addCc(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (this.isEmail(value.trim())) {
      if ((value || '').trim()) {
        this.Cclist.push({ Ccid: value.trim() });
        this.Cctrl.setValue('')
        this.CcInput.nativeElement.value=null
      }
    }
    this.Cctrl.setValue(null);
  }

  removeCc(tolist: any): void {
    const index = this.Cclist.indexOf(tolist);

    if (index >= 0) {
      this.Cclist.splice(index, 1);
    }
  }

  selectedCc(event: MatAutocompleteSelectedEvent): void {
    let obj = {
      Ccid: event.option.viewValue
    }
    let istoid=this.toaddresslist.find(V=>V.toAddressid===event.option.viewValue);
    if(this.Cclist.length>0){
      let isexist=this.Cclist.filter(V=>V.Ccid===event.option.viewValue)
      if(isexist.length==0 &&(istoid==undefined || istoid==null))
    {
      this.Cclist.push(obj);
    }
  }else{
    if(istoid==undefined || istoid==null)
    {
      this.Cclist.push(obj);
    }
  }
    // this.alltoaddressid =this.alltoaddressid.filter(X=>X.toAddressid.toLowerCase()!=event.option.viewValue.toLowerCase());
    // this.allCcid=this.allCcid.filter(X=>X.Ccid.toLowerCase()!=event.option.viewValue.toLowerCase());
    if (this.CcInput !== undefined) {
      this.CcInput.nativeElement.value = '';
    }
    this.Cctrl.setValue(null);
    if(this.CcInput){
      this.CcInput.nativeElement.blur();
    }
  }

  private _filterCc(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCcid.filter(V => V.Ccid.toLowerCase().includes(filterValue));
    // return this.allCcid.filter(V => V.emailid.toLowerCase().includes(filterValue));

  }
  downloadfile()
  {
    if(this.isftpmail)
    {
      const source = this.ftpbase64;
      const link = document.createElement("a");
      link.href = source;
      link.download = this.filenamedisplay;
      link.click();
    }
    else{
      const source = this.excelbase64;
          const link = document.createElement("a");
          link.href = source;
          link.download = this.base64content['FileDownloadName'];
          link.click();
    }
  }
  pdf64responsebody:any
  htmlContent:any
  listaddress(isimport:string){
    this.alltoaddressid=[];
    this.allCcid=[];
    if(isimport=='import')
    {
      this.viewVendorImp.forEach(X=>{
        if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
          let toobj={
            "toAddressid":X.emailid
          }
          this.alltoaddressid.push(toobj)
        }
        })

        this.viewImpReq.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
          {
            let toobj={
              "toAddressid":X.emailid
            }
            this.alltoaddressid.push(toobj)
          }})

          this.viewImpLab.forEach(X=>{
            if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
            {
              let toobj={
                "toAddressid":X.emailid
              }
              this.alltoaddressid.push(toobj)
            }})

        this.viewVendorImp.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
            {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}
        })
          this.viewImpReq.forEach(X=>{
            if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
            {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}})
            this.viewImpLab.forEach(X=>{
              if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
              {
            let ccobj={
              "Ccid":X.emailid
            }
            this.allCcid.push(ccobj)}})
    }
    else if(isimport=='interface'){
      this.viewlabCont.forEach(X=>{
        if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
        let toobj={
          "toAddressid":X.emailid
        }
        this.alltoaddressid.push(toobj)}})
        this.viewReqCont.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
          {
          let toobj={
            "toAddressid":X.emailid
          }
          this.alltoaddressid.push(toobj)}})
          this.viewFacCont.forEach(X=>{
            if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
            {
            let toobj={
              "toAddressid":X.emailid
            }
            this.alltoaddressid.push(toobj)}})
      this.viewVenCont.forEach(X=>{
        if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
        let toobj={
          "toAddressid":X.emailid
        }
        this.alltoaddressid.push(toobj)}})

        this.viewlabCont.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}})

          this.viewReqCont.forEach(X=>{
            if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
            let ccobj={
              "Ccid":X.emailid
            }
            this.allCcid.push(ccobj)}})

            this.viewFacCont.forEach(X=>{
              if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
              let ccobj={
                "Ccid":X.emailid
              }
              this.allCcid.push(ccobj)}})

        this.viewVenCont.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}})
    }
    else if(isimport=='export'){
      this.viewexpLab.forEach(X=>{
        if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
        let toobj={
          "toAddressid":X.emailid
        }
        this.alltoaddressid.push(toobj)}})
        this.viewexpReq.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
          {
          let toobj={
            "toAddressid":X.emailid
          }
          this.alltoaddressid.push(toobj)}})

      this.viewVendorexp.forEach(X=>{
        if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
        let toobj={
          "toAddressid":X.emailid
        }
        this.alltoaddressid.push(toobj)}})

        this.viewexpLab.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}})

          this.viewexpReq.forEach(X=>{
            if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
            let ccobj={
              "Ccid":X.emailid
            }
            this.allCcid.push(ccobj)}})

        this.viewVendorexp.forEach(X=>{
          if(X.emailid!=undefined&&(X.emailid!=''||X.emailid!=null))
        {
          let ccobj={
            "Ccid":X.emailid
          }
          this.allCcid.push(ccobj)}})
    }

  }
  ftpemailpop(value:boolean,isimport:string)
{
 this.listaddress(isimport);
  this.ngxuiloader.start();
this.isftpmail=value;
this.mainSubject.reset();
let name,id
if(isimport=='import'){
  id=this.viewImport['projectID']
  name=this.viewImport['projectName']
}
else if(isimport=='export'){
  id=this.viewexport['projectID']
  name=this.viewexport['projectName']
}
else{
id=this.viewform['projectID']
name=this.viewform['projectName']
}
  this.mainSubject.setValue('FTP Details for Project :'+name+"("+id+")");

this.htmlContent=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,</span></span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Please find the attached file for FTP Information. </span></span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:10.0000pt"><span style="font-family:Calibri">Note: Attached file is password protected for the security purposes. Please refer to the following email to locate the password.</span></span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>Thanks &amp; Regards</strong></span></span></strong></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>VitalAxis</strong></span></span></strong></span></span></p>`;
let obj
if(isimport=='import'){
if(this.viewImport['projectVaFtpDetails'] !== undefined){
  obj=
  {
    "projectID":this.viewImport['projectID'],
    "projectname":this.viewImport['projectName'],
    "interfaceID":"",
    "interfacename":"",
    "ftpdata":{
      "Host":this.viewImport['projectVaFtpDetails']['Host'],
      "Username":this.viewImport['projectVaFtpDetails']['Username'],
      "Password":this.viewImport['projectVaFtpDetails']['Password'],
      "Port":this.viewImport['projectVaFtpDetails']['Port']
    }
  }
}else{
  obj={}
}
}else if(isimport=='export'){
  if(this.viewexport['projectVaFtpDetails'] !== undefined){
    obj=
    {
      "projectID":this.viewexport['projectID'],
      "projectname":this.viewexport['projectName'],
      "interfaceID":"",
      "interfacename":"",
      "ftpdata":{
        "Host":this.viewexport['projectVaFtpDetails']['Host'],
        "Username":this.viewexport['projectVaFtpDetails']['Username'],
        "Password":this.viewexport['projectVaFtpDetails']['Password'],
        "Port":this.viewexport['projectVaFtpDetails']['Port']
      }
    }
  }else{
    obj={}
  }
}
else{
  if(this.viewform['projectVaFtpDetails'] !== undefined){
    obj=
    {
      "projectID":this.viewform['projectID'],
      "projectname":this.viewform['projectName'],
      "interfaceID":"",
      "interfacename":"",
      "ftpdata":{
        "Host":this.viewform['projectVaFtpDetails']['Host'],
        "Username":this.viewform['projectVaFtpDetails']['Username'],
        "Password":this.viewform['projectVaFtpDetails']['Password'],
        "Port":this.viewform['projectVaFtpDetails']['Port']
      }
    }
  }else{
    obj={}
  }
}
  this.commonService.pdf64(obj).subscribe((data) =>{
    if(data['status'].toLowerCase()=='success')
    {
      this.pdf64responsebody=data['responsebody']
      this.ftpbase64='data:application/pdf;base64,'+data['responsebody'];
      this.filenamedisplay=data['statusmessage'].split(':')[1];
      this.key=data['extendedattributes']['key'];
      this.ngxuiloader.stop();
      let authhtml
      if(this.isftpmail)
      {
        authhtml=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,<br />FTP file password enclosed below.</span></span></span></span></p>
        <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">`+this.key+`</span></span></span></span></p>
        <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Thanks &amp; Regards<br />
        VitalAxis</span></span></span></span></p>`
        this.Subject.setValue("Password to access FTP Details for "+name+"("+id+")")
      }
      else{
        authhtml=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,<br />Compendium file password enclosed below.</span></span></span></span></p>
        <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">`+this.key+`</span></span></span></span></p>
        <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Thanks &amp; Regards<br />
        VitalAxis</span></span></span></span></p>`
        this.Subject.setValue("Password to access compendium Details for "+name+"("+id+")")
      }
      let attachmentobjftp={}
      attachmentobjftp[this.filenamedisplay]=this.pdf64responsebody
      let pdfMailComponent={
        responsebody:this.pdf64responsebody,
        base64:this.ftpbase64,
        filenamedisplay:this.filenamedisplay,
        key:this.key,
        toaddresslist:this.alltoaddressid,
        Cclist:this.allCcid ,
        mainSubject:this.mainSubject.value,
        htmlContent:this.htmlContent,
        authhtml:authhtml,
        Subject:this.Subject.value,
        attachmentobj:attachmentobjftp
      }

      const modalRef = this.modalService.open(MailComponent,
        { windowClass: "myCustomModalClassFTP", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = pdfMailComponent;
      modalRef.result.then(() => { });
    }else if(data['status'].toLowerCase() ==="failure"){
      this.ngxuiloader.stop();
      this.toastr.error(data.statusmessage,'Failed', {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
  },error=>{
    this.ngxuiloader.stop();
  })
}
excelbase64:any
sourcedetails:any
isimport:boolean
getsourcedetails(value:boolean,isdownload:boolean,isimport:string){
  this.listaddress(isimport);
  let obj;
  let id,depkey;
  if(isimport=='import'){
    id=this.viewImport['sourceGuid']
    depkey=this.viewImport['sourceDeploymentKey']
  }
else if(isimport=='export'){
  id=this.viewexport['sourceGuid']
  depkey=this.viewexport['sourceDeploymentKey']
}else{
  id=this.viewform['sourceGuid']
  depkey=this.viewform['sourceDeploymentKey']
}
this.ngxuiloader.start();
  this.commonService.getEntityfilterdata(id).subscribe((data) => {
    if(data!=undefined||data!=null || data.length>0 ){
    this.sourcedetails=data.filter(X=> X.deploymentkey === depkey );
    if(this.sourcedetails.length>0)
    {
      obj={
        "GUID":id,
        "Type":this.sourcedetails[0]['TypeName'],
        "CaseType":"all",
        "OptionalAOE":"1",
        "Deploymentkey":depkey
      }
      this.compendiumfilecontent(value,isdownload,obj)
    }else{
      this.ngxuiloader.stop();
      this.toastr.error("Deployment key mismatch",'',{
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }

  }else{
    this.ngxuiloader.stop();
  }
  },error=>{
    this.ngxuiloader.stop();
  })
}
compendiumfilecontent(value:boolean,isdownload:boolean,obj)
{

  this.isftpmail=value;
 this.htmlContent=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,</span></span></span></span></p>
 <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Please find the attached file for compendium. </span></span></span></span></p>
 <p>&nbsp;</p>
 <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:10.0000pt"><span style="font-family:Calibri">Note: Attached file is password protected for the security purposes. Please refer to the following email to locate the password.</span></span></span></span></p>
 <p>&nbsp;</p>
 <p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>Thanks &amp; Regards</strong></span></span></strong></span></span></p>
 <p><span style="font-size:11pt"><span style="font-family:Calibri"><strong><span style="font-size:12.0000pt"><span style="font-family:Calibri"><strong>VitalAxis</strong></span></span></strong></span></span></p>
 <p>&nbsp;</p>`;

 this.commonService.getcompendium(obj).subscribe((data) =>{
   if(data['status'].toLowerCase()=='success')
   {
     this.base64content= JSON.parse(data['responsebody']);
     if(this.base64content['FileContents']!=''){
     if(isdownload)
     {
       this.excelbase64='data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+this.base64content['FileContents'];
       this.ngxuiloader.stop();
       this.downloadfile();
     }
     else{
       this.compendiumemailpop(value)
     }
    }else{
      this.ngxuiloader.stop();
      this.toastr.error("Error occured getting compendium details",'',{
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
       return ""
    }
   }
   else{
    this.ngxuiloader.stop();
    this.toastr.error("Error occured",'',{
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    })
     return ""
   }
 },error=>{
  this.ngxuiloader.stop();
 })
}
base64content:any
key:any
mainSubject = new FormControl('');
excel64responsebody:any
compendiumemailpop(value:boolean)
{
if(this.base64content!="")
{
  this.isftpmail=value;
  let id,pname
  if(this.isimport){
    id=this.viewImport['projectID']
    pname=this.viewImport['projectName']
  }
else{
  id=this.viewform['projectID']
  pname=this.viewform['projectName']
}
  let obj={
    "FileContents":this.base64content['FileContents'],
    "ContentType":this.base64content['ContentType'],
    "FileDownloadName":this.base64content['FileDownloadName'],
    "projectID":id
  }
  this.mainSubject.reset();
  this.mainSubject.setValue('Compendium Details for Project :'+pname+"("+id+")");

  this.commonService.excel64(obj).subscribe((data) =>{
    if(data['status'].toLowerCase()=='success')
    {
      this.excel64responsebody=data['responsebody'];
        this.excelbase64='data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+data['responsebody'];
      this.filenamedisplay=data['statusmessage'].split(':')[1];
       this.key=data['extendedattributes']['key'];
      let authhtml
       if(this.isftpmail)
       {
         authhtml=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,<br />FTP file password enclosed below.</span></span></span></span></p>
         <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">`+this.key+`</span></span></span></span></p>
         <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Thanks &amp; Regards<br />
         VitalAxis</span></span></span></span></p>`
         this.Subject.setValue("Password to access FTP Details for "+pname+"("+id+")")
       }
       else{
         authhtml=`<p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Hi,<br />Compendium file password enclosed below.</span></span></span></span></p>
         <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">`+this.key+`</span></span></span></span></p>
         <p><span style="font-size:11pt"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri">Thanks &amp; Regards<br />
         VitalAxis</span></span></span></span></p>`
         this.Subject.setValue("Password to access compendium Details for "+pname+"("+id+")")
       }
       let attachmentobjexcel={}
       attachmentobjexcel[this.filenamedisplay]=this.excel64responsebody
       let compMailComponent={
        responsebody:this.excel64responsebody,
        base64:this.excelbase64,
        filenamedisplay:this.filenamedisplay,
        key:this.key,
        toaddresslist:this.alltoaddressid,
        Cclist:this.allCcid ,
        mainSubject:this.mainSubject.value,
        htmlContent:this.htmlContent,
        id:id,
        name:pname,
        authhtml:authhtml,
        Subject:this.Subject.value,
        attachmentobj:attachmentobjexcel
      }

      const modalRef = this.modalService.open(MailComponent,
        { windowClass: "myCustomModalClassFTP", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = compMailComponent;
      modalRef.result.then(() => { });
      }
      else{
        this.toastr.error("Error occured",'',{
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
      })
        this.ngxuiloader.stop();
      }
      this.ngxuiloader.stop();
  },error=>{
    this.ngxuiloader.stop();
  })
}
}



  onTabChangedEdit(value){
    if(value.index !== undefined){
      this.selectedIndexTab= value.index;
    }
  }
  onTabChanged(value){
    let index
    if(value.index !== undefined){
      index = value.index;
      this.selectedIndexTab= value.index;
    }

    if(value.index == undefined){
      index = value
      this.selectedIndexTab = value
    }
    // this.selectedIndexTab= value.index;
    // index = value.index;
    switch (index) {
      case 0:
        this.getInterfaceFormList('Interface')
        break;
      case 1:
        this.getInterfaceFormList('Import')
        break;
      case 2:
        this.getInterfaceFormList('Export')
        break;
      default:
        this.getInterfaceFormList('Interface')
        break;
    }
  }
  units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  niceBytes(x: any) {
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + this.units[l];
  }

  changeStatusProject(projectID:string, status:string,ref){
    this.ngxuiloader.start();
this.commonService.changeImportProjectStatus(projectID.split("-")[1],status).subscribe(data => {
  this.ngxuiloader.stop();
if(data.status.toLowerCase() === "success"){
  ref("close modal");
  this.toastr.success("Changed status to Complete", "", {
    positionClass: "toast-bottom-right",
    timeOut: 4000,
  });
}
},error=>{
  this.ngxuiloader.stop();
  this.toastr.error("Error Occured", "", {
    positionClass: "toast-bottom-right",
    timeOut: 4000,
  });
})
  }

  ngOnDestroy()
  {
    if (this.intervalim) {
      clearInterval(this.intervalim);
   }
    // clearInterval(this.intervalim);
    this.vendorRefresh.unsubscribe();
  }


  // copyToClip(data: any, message: any) {

  //   if (data != '' && data != undefined) {
  //     // setTimeout(() => {
  //       copyToClipboard(data);
  //       this.toastr.success(message + ' copied', "", {
  //         timeOut: 4000,
  //         positionClass: 'toast-bottom-right'
  //       });
  //     // })
  //   }

  //   else {

  //     this.toastr.warning(message + 'Invalid', "", {
  //       timeOut: 4000,
  //       positionClass: 'toast-bottom-right'
  //     });
  //   }

  // }

  copyToClip(event: any, message: string) {
    if (event != "") {
      copyToClipboard(event);
      this.toastr.success(message, "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }
  mappingImport(value) {

    this.improute.projid = value.id
    this.improute.taskid = value.taskId
    this.improute.ftpDetail = value.FTPDetails
    this.router.navigate(['/home/patient', value.id])
  }


  filecontentList = [
    {
      "Status": "Failed",
      "TotalRecords": 10803,
      "CurrentRecords": 585,
      "FileName": "Patient_dta1.csv",
      "BatchID": 1,
      "FailureInfo": {
        "Level": -1,
        "ID": 0,
        "Reason": "",
        "TimeStamp": "",
        "TID": null,
        "FileName": null
      }
    },
    {
      "Status": "Failed",
      "TotalRecords": 10803,
      "CurrentRecords": 585,
      "FileName": "Patient_dta1.csv",
      "BatchID": 1,
      "FailureInfo": {
        "Level": -1,
        "ID": 0,
        "Reason": "",
        "TimeStamp": "",
        "TID": null,
        "FileName": null
      }
    },
    {
      "Status": "Failed",
      "TotalRecords": 10803,
      "CurrentRecords": 585,
      "FileName": "Patient_dta1.csv",
      "BatchID": 2,
      "FailureInfo": {
        "Level": -1,
        "ID": 0,
        "Reason": "",
        "TimeStamp": "",
        "TID": null,
        "FileName": null
      }
    }
  ]

  filesList = [];

  uniqueArr(arr, store) {

    let userArr = JSON.parse(JSON.stringify(arr))
    for (let i = 0; i < arr.length; i++) {
      let numbe= userArr.filter(va => va.BatchID == arr[i].BatchID)
      if (numbe.length > 0) {
        let obj = {
          BatchID: arr[i].BatchID,
          count: numbe.length,
          status: arr[i].Status,
          importedon:arr[i].ImportedOn
        }

        userArr = userArr.filter(va => va.BatchID !== arr[i].BatchID)
        store.push(obj)
      }


    }
    return store;
  }

  prNameList = []
  interlist=[]
  projInterfacesList(value : any){

    let prid = value

      let obj ={
        "messagetype": "",
        "id": "",
        "PAID": "",
        "sourceagentid": "",
        "SEGUID": "",
        "destinationagentid": "",
        "DEGUID": "",
        "vendor": "",
        "entityid": "",
        "pagenumber": 1,
        "userType": "",
        "type":'not',
        "projectId":prid,
        "assignedUserId": ""

      }

      this.commonService.getProjInterfaces(obj).subscribe((data)=>{

        if(data){
          this.interlist = data
        }
      })
  }

  isLockedVer1: any;
  isChecked: Boolean;


  editPipeline(item) {

    // this.activeModal.close('exampleModalForm');
    if (item.Version == '2') {
      sessionStorage.setItem('pipelineid', JSON.stringify(item.id));
      this.router.navigate(['/home/createpipeline']);
    }
    else {
      const modalRef = this.modalService.open(Routeversion1Component,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = item;
      modalRef.result.then((data) => {
        this.isLockedVer1 = item.IsLocked;
        this.isChecked = false;
        if (data == 'save') {
          this.projInterfacesList(item.projectId);
         }
      });
    }
  }
  openAuditPopUp(id:any,username:any) {

    if (id != '' && username!='') {
      const auditDataToModal = {
        id:id,
        title:username ,
        context: "Project",
        type: "ID",
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };

      const modalRef = this.modalService.open(NewauditComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(() => { });
    }

  }

}




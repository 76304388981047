import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-casespecimen',
  templateUrl: './casespecimen.component.html',
  styleUrls: ['./casespecimen.component.scss']
})
export class CasespecimenComponent {
@Input() caseInfoData;
specimenList = [];
testList=[1,2,4,43,434]
  micrographscount: any;
  constructor() { }

  ngOnChanges(){
    this.specimenList=[]
    if(this.caseInfoData !== undefined && this.caseInfoData !== ''&& this.caseInfoData.Case.ResultDetails !== undefined ){

      if(this.caseInfoData.Case.ResultDetails.Result.ObservationSet.length > 0){
        this.specimenList= this.caseInfoData.Case.ResultDetails.Result.ObservationSet
      }else{
        this.specimenList=[this.caseInfoData.Case.ResultDetails.Result.ObservationSet]

      }

      for (let i = 0; i < this.specimenList.length; i++) {
        if(Array.isArray(this.specimenList[i].TestDetails.Test)){
          this.specimenList[i].TestDetails.Test =  this.specimenList[i].TestDetails.Test
        }else{
          this.specimenList[i].TestDetails.Test = [this.specimenList[i].TestDetails.Test]
        }

      }


      console.log(this.specimenList);
    }else{
      this.specimenList=[]
    }
  }

  getPendingTest(testArray){
if(testArray.length>0){
 let pending= testArray.filter(va => va.Status.toLowerCase() !== 'fulfilled')
 this.micrographscount= pending.length;
 return pending.length;
}
  }

}
